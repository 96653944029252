import React from 'react';
import {Box, createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

interface Props {
  src: string;
  poster: string;
  height: number;
  width: number;
  autoPlay: boolean;
  showControls: boolean;
  loop: boolean;
  preload: string;
}

const Video: React.FC<Props> = ({
  src,
  height,
  width,
  poster,
  autoPlay,
  showControls,
  loop = true,
  preload = 'auto',
}) => {
  const classes = useStyles();
  return (
    <Box {...{width, height}}>
      <video
        className={classes.video}
        src={src}
        height={height}
        width={width}
        poster={poster}
        loop={loop}
        preload={preload}
        autoPlay={autoPlay}
        muted
        controls={showControls}
      />
    </Box>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    video: {
      width: '-webkit-fill-available',
    },
  })
);

export default Video;
