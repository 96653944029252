import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {HideInDemoMode} from 'components/Widgets';

import styles from './style.css';

const Internal = ({children, className}) => {
  const style = classNames({
    [styles.root]: true,
    [className]: Boolean(className),
  });

  return (
    <HideInDemoMode>
      <div className={style}>
        <div className={styles.header}>Internal only</div>
        {children}
      </div>
    </HideInDemoMode>
  );
};

Internal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Internal;
