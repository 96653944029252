import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';

const ResetButtonFilter = ({resetFilters}) => (
  <span className={styles.resetButton} onClick={resetFilters}>
    Reset filters
  </span>
);

ResetButtonFilter.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default ResetButtonFilter;
