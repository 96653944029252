import React from 'react';
import {gql} from '@apollo/client';

import {Gig} from 'services/fragments';

import ImgixImage from '../Imgix';
import ImageLoader from '../ImageLoader';
import Video from '../Video';
import Gallery from '../Gallery';
import TikTokEmbed from '../TikTokEmbed';

interface Props {
  media: Record<string, string>;
  height: number;
  width: number;
  autoPlay: boolean;
  showControls?: boolean;
  loop?: boolean;
  preload?: string;
  alt: string;
  galleryDotsStyle: any;
  galleryDotStyle: any;
  galleryActiveDotStyle: any;
}

const Media: React.FC<Props> = ({
  autoPlay,
  media,
  height,
  width,
  showControls = true,
  loop = false,
  preload = 'auto',
  galleryDotsStyle,
  galleryDotStyle,
  galleryActiveDotStyle,
  alt,
}) => {
  switch (media?.__typename) {
    case 'Image':
      if (media.url?.includes('imgix')) {
        return <ImgixImage src={media.url} height={height} width={width} alt={alt} />;
      }
      return <ImageLoader src={media.url} alt={alt} />;
    case 'Video':
      return (
        <Video
          src={media.transcoded ? media.previewUrl : media.url}
          poster={media.transcoded ? media.previewThumbnailUrl : media.thumbnail}
          height={height}
          width={width}
          autoPlay={autoPlay}
          showControls={showControls}
          loop={loop}
          preload={preload}
        />
      );
    case 'Gallery':
      return (
        <Gallery
          alt={alt}
          items={media.items}
          height={height}
          width={width}
          dotsStyle={galleryDotsStyle}
          dotStyle={galleryDotStyle}
          activeDotStyle={galleryActiveDotStyle}
        />
      );
    case 'TikTok':
      return <TikTokEmbed width={width} height={height} url={media.url} />;
    default:
      return null;
  }
};

export default Media;
