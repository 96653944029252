import React from 'react';

import {RequiresPermission, InfiniteScroll, Table} from 'components/Widgets';

import {useLocalStorage} from 'consts/hooks';

import {PaginatedQuery} from '../../../../../services/graphql';
import {EmailList, PromoteButton} from '..';

type Props = {
  query: Record<string, any>;
  variables: Record<string, any>;
  columns: Array<any>;
  brandMatch: boolean;
  filters: string[];
  extraRow: (offer: any) => React.JSX.Element | null;
};

const ListOffersWithSelection: React.FC<Props> = ({
  query,
  variables,
  columns,
  extraRow,
  filters,
  brandMatch,
}) => {
  const [showExtraRow] = useLocalStorage('showExtraRow', false);

  return (
    <PaginatedQuery query={query} variables={variables}>
      {({loading, data, loadMore}) => {
        const offers =
          data?.offers?.edges
            .map(edge => edge.node)
            .filter(offer => filters.includes(offer.state)) || [];
        const selectedOffers = offers.filter(offer => offer.isSelected);
        const emails = offers.map(o => o.influencer.email);

        return (
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={data?.offers?.pageInfo?.hasNextPage || false}
            skipLoading
          >
            <Table
              columns={columns}
              data={selectedOffers}
              extraRow={showExtraRow && extraRow}
              loading={loading}
              emptyMessage="No influencers."
            />

            <RequiresPermission permission="manage_influencers">
              <PromoteButton count={selectedOffers.length} brandMatch={brandMatch} />
            </RequiresPermission>

            <Table
              columns={columns}
              data={offers.filter(offer => !offer.isSelected)}
              extraRow={showExtraRow && extraRow}
              loading={loading}
              emptyMessage="No influencers."
            />
            <RequiresPermission permission="manage_influencers">
              {emails && emails.length > 0 && <EmailList title="Emails" emails={emails} />}
            </RequiresPermission>
          </InfiniteScroll>
        );
      }}
    </PaginatedQuery>
  );
};

export default ListOffersWithSelection;
