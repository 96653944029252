import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';

import {SearchInput} from '../../../../components/Widgets';
import {useDebounce} from 'use-debounce';

interface Props {
  initialValue?: string;
  onChange: (value: string) => void;
  onDebounced: (value: string) => void;
}

const Search: React.FC<Props> = ({initialValue = '', onChange, onDebounced}) => {
  const [searchString, setSearchString] = useState<string>(initialValue);
  const [debouncedSearchString] = useDebounce(searchString, 1000);

  useEffect(() => {
    if (searchString !== initialValue) {
      onChange(searchString);
    }
  }, [searchString]);

  useEffect(() => {
    if (debouncedSearchString.length) {
      onDebounced(debouncedSearchString);
    }
  }, [debouncedSearchString]);

  return (
    <>
      <SearchInput
        searchString={searchString}
        placeholder="Search Influencer"
        onChange={event => setSearchString(event.target.value || '')}
      />
      {searchString.length >= 1 ? (
        ''
      ) : (
        <Typography variant="body2" style={{padding: '20px 40px'}}>
          You can use the full URL to the influencer profile if there are issues finding an
          influencer by their username. Simply find them in the influencer search and copy/paste the
          URL to the profile in the search box here.
        </Typography>
      )}
    </>
  );
};

export default Search;
