export const GIG_HISTORY_EVENT: {[id: string]: {type: string; label: string}} = {
  ReserveEvent: {type: 'ReserveEvent', label: 'Reserved'},
  CreateSubmissionEvent: {type: 'CreateSubmissionEvent', label: 'Submitted'},
  GigReviewEvent: {type: 'GigReviewEvent', label: 'Reviewed'},
  GigApproveEvent: {type: 'GigApproveEvent', label: 'Approved'},
  RejectGigEvent: {type: 'RejectGigEvent', label: 'Rejected'},
  GigResubmissionRequestEvent: {type: 'GigResubmissionRequestEvent', label: 'Resubmit requested'},
  UpdateCaptionEvent: {type: 'UpdateCaptionEvent', label: 'Caption updated'},
  ReportEvent: {type: 'ReportEvent', label: 'Reported'},
  DismissReportEvent: {type: 'DismissReportEvent', label: 'Report dismissed'},
  GigPostedEvent: {type: 'GigPostedEvent', label: 'Posted'},
  OfferClaimedEvent: {type: 'OfferClaimedEvent', label: 'Claimed'},
};

export const getHistoryEvent = (item: HistoryInterface) =>
  GIG_HISTORY_EVENT[item.__typename] ?? null;

export const getHistoryEventLabel = (item: HistoryInterface) => {
  if (GIG_HISTORY_EVENT[item.__typename]) {
    return GIG_HISTORY_EVENT[item.__typename].label;
  }
  return item.__typename;
};

export const getHistoryEventType = (item: HistoryInterface) => item.__typename;
