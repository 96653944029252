import moment from 'moment';

export type RowData = {
  offerId: string;
  paymentId: string;
  bankAccount: UserBankAccount;
  influencer: Influencer;
  campaign: {
    url: string;
    advertiser: string;
    name: string;
  };
  reward: Currency;
  claimedDate: string;
};

export const TABLE_HEADERS = {
  influencer: 'influencer',
  campaign: 'campaign',
  account: 'account',
  reward: 'reward',
  claimed: 'claimed',
  approval: 'approval',
};

export const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
};

export const sortByOrder = (a: RowData, b: RowData, orderBy: string, order: string) => {
  const sortByName = (a: string, b: string) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const sortByValue = (a: number, b: number) => a - b;

  const sortByDate = (a: string, b: string) => (moment(b).isBefore(a) ? 1 : -1);

  switch (orderBy) {
    case TABLE_HEADERS.influencer: {
      const vA = a.influencer.fullName!;
      const vB = b.influencer.fullName!;
      return order === SORT_ORDER.asc ? sortByName(vA, vB) : sortByName(vB, vA);
    }
    case TABLE_HEADERS.campaign: {
      const vA = a.campaign.advertiser;
      const vB = b.campaign.advertiser;
      return order === SORT_ORDER.asc ? sortByName(vA, vB) : sortByName(vB, vA);
    }
    case TABLE_HEADERS.reward: {
      const vA = a.reward.value!;
      const vB = b.reward.value!;
      return order === SORT_ORDER.asc ? sortByValue(vA, vB) : sortByValue(vB, vA);
    }
    case TABLE_HEADERS.claimed: {
      return order === SORT_ORDER.asc
        ? sortByDate(a.claimedDate, b.claimedDate)
        : sortByDate(b.claimedDate, a.claimedDate);
    }
  }
  return 0;
};
