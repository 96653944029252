import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

import {LoaderDots} from 'components/Widgets';

import uniqueId from 'services/uniqueId';
import {LONG_DATE_TIME_FORMAT} from 'consts/variables';

import styles from './style.css';

require('!!style-loader!css-loader!react-datepicker/dist/react-datepicker.css'); // eslint-disable-line

class Picker extends React.PureComponent {
  static propTypes = {
    selected: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
    description: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    isClearable: PropTypes.bool,
    small: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    dateFormat: PropTypes.string,
    value: PropTypes.string,
    loading: PropTypes.bool,
    setTime: PropTypes.func,
    utcOffset: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {
      /* do nothing */
    },
    errorMessage: 'The selected date is not valid',
    dateFormat: LONG_DATE_TIME_FORMAT,
    placeholder: 'Click to select a date',
  };

  state = {
    error: false,
  };

  onChange = value => {
    if (value === null) {
      this.props.onChange(null);
      return;
    }

    if (this.props.setTime) {
      value = this.props.setTime(value);
    }

    this.props.onChange(value.format());
  };

  render() {
    const {
      loading,
      utcOffset,
      selected = this.props.value,
      label,
      id = uniqueId(),
      dateFormat,
      minDate,
      maxDate,
      errorMessage,
      isClearable,
      small,
      disabled,
      description,
      placeholder,
    } = this.props;

    if (loading) {
      return <LoaderDots />;
    }

    const locale = window.navigator.userLanguage || window.navigator.language;

    const minValue = minDate ? moment(minDate).utcOffset(utcOffset / 60) : null;
    const maxValue = maxDate ? moment(maxDate).utcOffset(utcOffset / 60) : null;
    const value = selected ? moment(selected).utcOffset(utcOffset / 60) : null; // Need to be able to set null to clear deadline

    const error =
      (value && minValue && value.isBefore(minValue)) ||
      (value && maxValue && value.isAfter(maxValue));

    return (
      <div className={styles.root}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <DatePicker
          minDate={minValue}
          maxDate={maxValue}
          selected={value}
          locale={locale}
          id={id}
          className={classNames(styles.datePicker, small && styles.small)}
          isClearable={isClearable}
          todayButton="Today"
          dateFormat={dateFormat}
          placeholderText={placeholder}
          disabled={disabled}
          onChange={this.onChange}
        />

        {description && <div className={styles.description}>{description}</div>}

        {error && (
          <p className={styles.error} aria-label="datepicker-error">
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
}

const DatePickerQuery = gql`
  query DatePickerQuery($timezone: String!) {
    tzone: timezone(zone: $timezone) {
      id
      utcOffset
    }
  }
`;

export default graphql(DatePickerQuery, {
  options: ({timezone}) => ({
    variables: {
      timezone,
      _key: Math.random(),
    },
  }),
  props: ({data: {loading, tzone}}) => ({
    loading,
    utcOffset: tzone?.utcOffset,
  }),
})(Picker);
