import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql, withApollo} from '@apollo/client/react/hoc';
import classNames from 'classnames';

import {
  DocumentTitle,
  AutosavingControl,
  Button,
  Heading,
  Loader,
  ProfilePicture,
  Select,
  TextInput,
  Toggle,
} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';
import {uploadImage} from 'services/api';
import ErrorReporting from 'services/error-reporting';

import {EditProfileQuery, EditProfileMutation} from './graphqlQuery';
import styles from './style.css';
import {removeStoredApiToken} from '../../services/auth';

const EMAIL_NOTIFICATION_PREFERENCES = [
  {
    name: 'Off',
    value: 'off',
  },
  {
    name: 'Hourly',
    value: 'hourly',
  },
  {
    name: 'Daily',
    value: 'daily',
  },
];

class EditProfile extends React.PureComponent {
  static propTypes = {
    updateCurrentUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool,
    client: PropTypes.shape({
      resetStore: PropTypes.func.isRequired,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  constructor(context, props) {
    super(context, props);
    this.state = {
      profilePicturePending: false,
      apiHost: localStorage.getItem('API_HOST'),
      demoMode: localStorage.getItem('DEMO_MODE') === 'true',
    };
  }

  setCustomApiHost = host => {
    localStorage.setItem('API_HOST', host);
    this.setState({
      apiHost: host,
    });
  };

  setDemoMode = demoMode => {
    localStorage.setItem('DEMO_MODE', demoMode);
    this.setState({
      demoMode,
    });
  };

  updateEmailNotificationPreference = emailNotificationPreference => {
    this.props.updateCurrentUser({emailNotificationPreference});
  };

  updateFullName = fullName => {
    this.props.updateCurrentUser({fullName});
  };

  async handleImage(image) {
    this.setState({profilePicturePending: true});

    try {
      const url = await uploadImage(image[0]);

      if (!url) {
        this.setState({profilePicturePending: false});
        return;
      }

      this.props.updateCurrentUser({profilePicture: url});
    } finally {
      this.setState({profilePicturePending: false});
    }
  }

  logout = async () => {
    await this.props.client.resetStore();
    ErrorReporting.setUserContext({});
    removeStoredApiToken();
    this.props.history.push('/login');
  };

  render() {
    const {profilePicturePending} = this.state;
    const {loading, user} = this.props;

    if (loading) {
      return null; // XXX: display something
    }

    const isDeveloper = user.roleName === 'developer';
    const isTeamMember = user.roleName !== 'advertiser';

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle(`${user.fullName} - Edit Profile`)} />

        <div className={styles.header}>
          <Heading title="Edit profile" />

          {isTeamMember && (
            <Toggle
              checked={this.state.demoMode}
              label="Demo mode"
              checkedDescription="Campaign price will be hidden"
              onChange={this.setDemoMode}
            />
          )}
        </div>

        <div className={styles.basics}>
          <div className={styles.basicsName}>
            <AutosavingControl
              label="Name"
              initialState={{value: user.fullName || ''}}
              debounceInterval={1000}
              onChange={this.updateFullName}
            >
              <TextInput />
            </AutosavingControl>
          </div>

          <div className={styles.profilePicture}>
            <ProfilePicture
              src={user.profilePicture}
              size={80}
              className={classNames([
                styles.profilePictureSource,
                profilePicturePending && styles.profilePictureSourcePending,
              ])}
            />
            <input
              type="file"
              className={styles.profilePictureInput}
              name="files[]"
              disabled={this.state.profilePicturePending}
              onChange={event => event.target.files && this.handleImage(event.target.files)}
            />
            <button
              className={classNames([
                styles.profilePictureButton,
                profilePicturePending && styles.profilePictureButtonDisabled,
              ])}
            >
              Edit
            </button>
            {profilePicturePending && (
              <div className={styles.profilePictureLoading}>
                <Loader className={styles.profielImageLoadingDot} />
              </div>
            )}
          </div>
        </div>

        {isDeveloper && (
          <div className={styles.basics}>
            <div className={styles.basicsName}>
              <AutosavingControl
                initialState={{value: this.state.apiHost}}
                label="Custom API host"
                debounceInterval={1000}
                onChange={this.setCustomApiHost}
              >
                <TextInput />
              </AutosavingControl>
            </div>
          </div>
        )}

        <div className={styles.basics}>
          <div className={styles.basicsName}>
            <AutosavingControl
              label="Email notification preferences"
              initialState={{value: user.emailNotificationPreference}}
              onChange={this.updateEmailNotificationPreference}
            >
              <Select
                options={EMAIL_NOTIFICATION_PREFERENCES}
                block
                onChange={() => {
                  /* ignore required prop */
                }}
              />
            </AutosavingControl>
          </div>
        </div>

        <div className={styles.footer}>
          <Button gray className={styles.logOut} text="Log out" onClick={this.logout} />
        </div>
      </div>
    );
  }
}

// prettier-ignore
export default compose(
  withApollo,
  graphql(EditProfileQuery, {
    props: ({data: {loading, user}}) => ({
      loading,
      user,
    }),
  }),
  graphql(EditProfileMutation, {
    props: ({mutate, ownProps: {user}}) => ({
      updateCurrentUser: ({fullName, profilePicture, emailNotificationPreference}) => mutate({
        variables: {fullName, profilePicture, emailNotificationPreference},
        optimisticResponse: {
          updateCurrentUser: {
            __typename: 'updateCurrentUser',
            ok: true,
            user: {
              __typename: 'User',
              id: user.id,
              fullName: fullName || user.fullName,
              profilePicture: profilePicture || user.profilePicture,
              emailNotificationPreference: emailNotificationPreference || user.emailNotificationPreference,
            }
          }
        }
      }),
    }),
  }),
)(EditProfile)
