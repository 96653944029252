import {createStyles, makeStyles} from '@material-ui/core/styles';

import {theme} from '../../../consts/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    chartContainer: {
      display: 'flex',
      padding: '14px 0 14px 14px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    composedChart: {
      backgroundColor: theme.palette['ui-02'],
    },
    chartInfo: {
      paddingLeft: '86px',
    },
    brush: {
      paddingTop: '20px',
    },
    bar: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);
