import {TextField} from '@material-ui/core';
import React from 'react';
import {Control} from 'react-redux-form';

import withCustomControl from '../../../../components/Widgets/Forms/CustomControl';
import {useStyles} from '../LoginForm/styles';

const CustomEmailInput = (props: any) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      id="email-input"
      name="email"
      inputProps={{
        'aria-labelledby': 'email-input-label',
      }}
      variant="outlined"
      placeholder="Email"
      className={classes.control}
      InputProps={{
        classes: {
          input: classes.inputText,
        },
      }}
      type="email"
      disabled={props.disabled}
      required={props.required}
      value={props.value}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onChange={props.onChange}
    />
  );
};

export const ControlEmailInput = withCustomControl(props => (
  <Control.text component={CustomEmailInput} {...props} />
));
