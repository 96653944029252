import React from 'react';
import {Parser} from 'json2csv';
import FileSaver from 'file-saver/FileSaver';

import styles from './style.css';

type DataResult = {
  fields?: string[];
  data: Record<string, string>[];
};

type Props = {
  text: string;
  dataFetcher: () => Promise<DataResult>;
  filename: string;
  className?: string;
};

const ExportCSV = ({dataFetcher, text, filename, className}: Props): JSX.Element => {
  const onClick = async (): Promise<void> => {
    const result = await dataFetcher();

    const parser = new Parser({fields: result.fields, flatten: true});
    const csv = parser.parse(result.data);

    const blob = new Blob([csv], {type: 'data:text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, filename);
  };

  return (
    <div className={className || styles.link} onClick={onClick}>
      {text}
    </div>
  );
};

export default ExportCSV;
