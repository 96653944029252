import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';

const Divider = ({style}) => <div style={style} className={styles.root} />;

Divider.propTypes = {
  style: PropTypes.shape({}),
};

export default Divider;
