import React from 'react';
import {ButtonGroup} from '@material-ui/core';

import {IFilters} from './types';
import {useStyles} from './styles';
import ItemFilter from '../../../components/Widgets/ItemFilter';
import {CAMPAIGN_FILTER_STATE} from '../../../consts/variables';

const FilterState: React.FC<IFilters> = ({filters, setFilters}) => {
  const classes = useStyles();
  return (
    <>
      <ButtonGroup>
        {[
          CAMPAIGN_FILTER_STATE.All.name,
          CAMPAIGN_FILTER_STATE.Draft.name,
          CAMPAIGN_FILTER_STATE.Launched.name,
          CAMPAIGN_FILTER_STATE.Completed.name,
        ].map((state, index) => {
          const checked =
            filters.state === state.toLowerCase() ||
            (state === CAMPAIGN_FILTER_STATE.All.name && typeof filters.state === 'undefined');
          return (
            <ItemFilter
              key={index}
              className={checked ? classes.checked : classes.button}
              title={state}
              handleClick={() =>
                setFilters({
                  ...filters,
                  state: state !== CAMPAIGN_FILTER_STATE.All.name ? state.toLowerCase() : undefined,
                })
              }
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default FilterState;
