import React from 'react';
import classNames from 'classnames';
import {createStyles, makeStyles} from '@material-ui/core';

import {MaterialDesignIcon} from '../../Icons';
import Tooltip from '../../Widgets/Tooltip';
import {black50, blue} from '../../../consts/brand.integration';

interface Props {
  approver: {
    id: string;
    fullName: string;
  };
}

const GigApprovedCheckbox: React.FC<Props> = ({approver}) => {
  const styles = useStyles();

  return (
    <div className={classNames([styles.container, styles.approved])}>
      {approver && (
        <div className={styles.buttonActive}>
          <Tooltip
            overlay={<span>{`Client approval by ${approver.fullName}`}</span>}
            placement="left"
            trigger="hover"
          >
            <MaterialDesignIcon icon={'check_circle'} className={styles.iconActive} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      visibility: 'hidden',
      transition: 'opacity 0.2s',
      opacity: 0,
    },
    approved: {
      visibility: 'visible',
      opacity: 1,
    },
    button: {
      width: '15px',
      height: '15px',
      padding: '6px',
      backgroundColor: 'white',
      position: 'absolute',
      top: '50px',
      right: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.6,
      '&:hover': {
        opacity: 1,
        cursor: 'not-allowed',
      },
    },
    buttonActive: {
      width: '15px',
      height: '15px',
      padding: '6px',
      backgroundColor: 'white',
      position: 'absolute',
      top: '50px',
      right: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 1,
      cursor: 'help',
    },

    icon: {
      color: black50,
      display: 'block',
      flex: 1,
      paddingLeft: '1px',
    },
    iconActive: {
      color: blue,
      display: 'block',
      flex: 1,
      paddingLeft: '1px',
    },
  })
);

export default GigApprovedCheckbox;
