import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Grid} from '@material-ui/core';

import {LoaderDots, Section} from '../../../../components/Widgets';
import CreateOffer, {useCreateOfferForm} from './CreateOffer';
import TargetingSection from './TargetingSection';

interface Props {
  campaign: Campaign;
  influencer: Influencer;
}

const NewOffer: React.FC<Props> = ({campaign, influencer}) => {
  const {loading, data} = useQuery(NewOfferQuery, {
    variables: {
      campaignId: campaign.id,
      influencerId: influencer.id,
    },
  });

  const createOfferForm = useCreateOfferForm({
    campaign,
    influencerId: influencer.id,
    suggestedReward: data?.suggestedReward,
  });

  if (loading) {
    return <LoaderDots />;
  }

  if (data) {
    return (
      <Grid item container xs={12} direction="row" justifyContent="space-between">
        <Grid item xs={12}>
          <Section title="Custom offer">
            <CreateOffer campaign={campaign} {...createOfferForm} />
          </Section>
        </Grid>
        <Grid item xs={12}>
          <TargetingSection targeting={data.targeting} />
        </Grid>
      </Grid>
    );
  }

  return <></>;
};

const NewOfferQuery = gql`
  query NewOfferQuery($campaignId: UUIDString!, $influencerId: UUIDString!) {
    suggestedReward: getInfluencerRewardSuggestionForCampaign(
      campaignId: $campaignId
      influencerId: $influencerId
    ) {
      value
      formattedValue
    }
    targeting: campaignInfluncerTargeting(campaignId: $campaignId, influencerId: $influencerId)
  }
`;

export default NewOffer;
