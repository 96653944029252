import {CUSTOM_THEME_STORAGE_KEY} from '../consts/variables';
import {purple} from '../consts/brand';
import * as localStorage from './local-storage';
import realmSlug from './realm';

export const themes = [
  {
    name: 'influenceM',
    value: 'influenceM',
  },
  {
    name: 'Takumi Sales',
    value: 'takumi-sales',
  },
  {
    name: 'Takumi',
    value: 'takumi',
  },
];

export const theme = (() => {
  const customTheme = localStorage.getItem(CUSTOM_THEME_STORAGE_KEY);
  if (themes.map(item => item.value).includes(customTheme)) {
    return customTheme;
  }

  switch (realmSlug) {
    case 'influencem':
      return 'influenceM';
    default:
      return 'takumi';
  }
})();

export const getThemeColor = () => {
  return purple;
};
