import {createStyles, makeStyles, Theme} from '@material-ui/core';

import {semiBold, roman} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      width: '100%',
      height: '48px',
      fontSize: '16px',
      '& .MuiInputBase-root': {
        height: '48px',
      },
    },
    inputText: {
      color: theme.palette['text-01'],
      fontSize: '14px',
      fontWeight: semiBold,
      '&::placeholder': {
        color: theme.palette['text-03'],
        fontWeight: roman,
      },
    },
    description: {
      padding: '8px 0',
    },
  })
);
