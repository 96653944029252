import React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';

import {theme} from '../../../consts/theme';

const TextLink: React.FC<NavLinkProps> = props => {
  const classes = useStyles();
  return <NavLink {...props} className={classes.link} />;
};

const useStyles = makeStyles({
  link: {
    color: theme.palette.secondary.main,
  },
});

export default TextLink;
