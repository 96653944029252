import {Grid} from '@material-ui/core';
import React from 'react';

import InfluencerCard from '../../../Influencer/Search/components/InfluencerCard/InfluencerCard';

interface Props {
  offer: Offer;
}

const CardReserved: React.FC<Props> = ({offer}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <InfluencerCard influencer={offer?.influencer} />
      </Grid>
    </Grid>
  );
};

export default CardReserved;
