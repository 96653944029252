import React from 'react';
import {ControlProps, Errors as ReduxErrors, actions} from 'react-redux-form';

import styles from './style.css';

interface Props {
  name: string;
  messages: Record<string, string>;
  wrapper: string;
  show: string;
  errorWrapper?: any;
}

const withCustomControl = <T,>(WrappedComponent: React.FC<Props>) => {
  const CustomControl: React.FC<T & Partial<Props>> = props => {
    const model = `.${props.name}`;

    const changeAndNotify: ControlProps<any>['changeAction'] = (mdl, value) => {
      return dispatch => {
        // Handle when the DOM coerces the value to string
        if (value === String(props.value)) {
          value = props.value;
        }

        dispatch(actions.change(mdl, value));
        if (props.onUpdate) {
          props.onUpdate(value);
        }
      };
    };

    function hasError({fieldValue}) {
      if (props.show) {
        return fieldValue[props.show] && !fieldValue.valid;
      }
      return !fieldValue.valid;
    }

    return (
      <div>
        <WrappedComponent
          model={model}
          mapProps={{
            haserror: hasError,
          }}
          changeAction={changeAndNotify}
          {...props}
        />
        <ReduxErrors
          model={model}
          show={props.show}
          wrapper={props.wrapper}
          className={styles.errors}
          messages={props.messages}
          component={props.errorWrapper}
        />
      </div>
    );
  };

  return CustomControl;
};

export default withCustomControl;
