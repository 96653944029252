import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '88px 0',
    },
    creators: {
      paddingTop: '32px',
    },
    topCreatorsHeader: {
      display: 'flex',
    },
    infoIcon: {
      paddingTop: 5,
      paddingLeft: 5,
    },
  })
);
