import React, {ReactNode} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {Box} from '@material-ui/core';

import SideBarMenu from '../components/SideBarMenu';
import {white} from '../../../consts/brand.integration';

interface Props {
  children: ReactNode | ReactNode[];
}

type Params = {
  params: {advertiser?: string; campaign?: string};
};

const AppWrapper = ({children}: Props): JSX.Element => {
  const advertiserMatch = useRouteMatch('/brands/:advertiser') as Params;
  const campaignMatch = useRouteMatch('/brands/:advertiser/:campaign') as Params;
  const campaignFromDashboardMatch = useRouteMatch('/dashboard/campaigns/:campaign') as Params;
  // TODO: temporary fix for this matching edgecase
  if (campaignMatch?.params) {
    const {campaign} = campaignMatch.params;
    if (
      campaign === 'campaigns' ||
      campaign === 'settings' ||
      campaign === 'new' ||
      campaign === 'members' ||
      campaign === 'socials' ||
      campaign === 'performance' ||
      campaign === 'campaign-aggregate' ||
      campaign === 'campaign-comparison' ||
      campaign === 'competitors' ||
      campaign === 'access-settings'
    ) {
      campaignMatch.params = {};
    }
  }

  return (
    <Box bgcolor={white} width={1}>
      <SideBarMenu
        hasAdvertiser={advertiserMatch?.params}
        hasCampaign={campaignMatch?.params || campaignFromDashboardMatch?.params}
      >
        {children}
      </SideBarMenu>
    </Box>
  );
};

export default AppWrapper;
