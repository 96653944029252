import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {debounce, omit} from 'lodash';
import moment from 'moment';
import {graphql} from '@apollo/client/react/hoc';
import {Box, Typography} from '@material-ui/core';
import useFeatureFlags from 'hooks/useFeatureFlags';

import {
  Section,
  Label,
  DatePicker,
  LoaderDots,
  Prompt,
  ScheduleVisualizer,
} from 'components/Widgets';
import {
  BriefEditorInput,
  Form,
  FormButton,
  FormCheckbox,
  FormRadio,
  FormSubmitButton,
  FormTextInput,
  FormTextInputWithImage,
} from 'components/Widgets/Forms';

import {isInstagramUsername} from 'services/validators';
import {currencyForMarket, singularOrPlural} from 'consts/utilities';

import styles from './style.css';
import {PostType} from 'consts/postTypes';
import {
  ArchivePostMutation,
  CampaignEditPostMutation,
  CampaignEditPostQuery,
  InstagramUserQuery,
} from './queries';

function getWithDefault(value, defaultValue) {
  return value !== undefined ? value : defaultValue;
}

function latest(first, second) {
  return moment(first).isAfter(second) ? first : second;
}

class CampaignEditPost extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      post: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.object,
      isDeveloper: PropTypes.bool,
    }),
    updatePost: PropTypes.func.isRequired,
    archivePost: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    instagramUser: PropTypes.object,
    instagramUserLoading: PropTypes.bool,
    getInstagramUser: PropTypes.func,
    history: PropTypes.object,
    useYoutubeIntegration: PropTypes.bool,
    useTwitchIntegration: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      postType: undefined,
      opened: undefined,
      deadline: undefined,
      plannedDeliveryDate: undefined,
    };
  }

  componentDidMount() {
    const {data} = this.props;
    if (data?.post) {
      this.handleMentionChange(data.post.mention);
    }
  }

  componentDidUpdate(oldProps) {
    const {data} = this.props;
    if (oldProps.data && !oldProps.data.post && data?.post) {
      this.handleMentionChange(data.post.mention);
    }
  }

  handleSubmit = async model => {
    let modelSanitized = null;
    if (model.brief && model.brief.length > 0 && !!model.brief[0].__typename) {
      // Workaround to not update if the brief hasn't changed
      modelSanitized = omit(model, ['brief']);
    } else {
      modelSanitized = model;
    }
    const data = {
      ...modelSanitized,
      ...this.state,
    };
    return this.props.updatePost(data);
  };

  handleChangedOpened = opened => {
    this.setState({opened});
  };

  handleChangedSubmissionDeadline = submissionDeadline => {
    this.setState({submissionDeadline});
  };

  handleChangedDeadline = deadline => {
    this.setState({deadline});
  };

  handleChangedPlannedDeliveryDate = plannedDeliveryDate => {
    this.setState({plannedDeliveryDate});
  };

  handleMentionChange = username => {
    this.props.getInstagramUser(username);
  };

  handleFormChange = data => {
    this.setState({postType: data.postType});
  };

  showGalleryPhotoCount = () => {
    const showIf = [
      PostType.InstagramStandard,
      PostType.InstagramVideo,
      PostType.InstagramStory,
      PostType.Content,
    ];
    return showIf.includes(this.state.postType) > 0 || this.state.postType === undefined;
  };

  render() {
    const {history, campaignId, domain, instagramUser, instagramUserLoading} = this.props;
    const {post, loading} = this.props.data;

    if (loading) {
      return <LoaderDots />;
    }

    const started = post.campaign.started || new Date();
    const opened = getWithDefault(this.state.opened, post.opened);
    const deadline = getWithDefault(this.state.deadline, post.deadline);
    const plannedDeliveryDate = getWithDefault(
      this.state.plannedDeliveryDate,
      post.schedule?.plannedDeliveryDate
    );

    // These are lazy because we can't evaluate them until we have a deadline.
    const getSubmissionDeadline = () =>
      getWithDefault(this.state.submissionDeadline, post.schedule?.submissionDeadline);

    const getDaysToCreateContent = () =>
      moment(getSubmissionDeadline()).diff(moment(started), 'days');

    const isStory = () => getWithDefault(this.state.postType, post.postType) == 'story';
    const initialState = {...post, price: post.price?.value};

    return (
      <Section
        title="← Back"
        onTitleClick={() => history.push(`/brands/${domain}/${campaignId}/posts`)}
      >
        <Box marginY="1.5rem">
          <Typography variant="h3">{post.archived ? `Post archived` : `Edit post`}</Typography>
        </Box>
        {post.requiresReviewBeforePosting && post.schedule && (
          <ScheduleVisualizer
            startDate={started}
            schedule={post.schedule}
            periods={[
              {
                start: started,
                end: post.schedule.submissionDeadline,
                title: 'Submit',
              },
              {
                start: post.schedule.submissionDeadline,
                end: post.schedule.internalReviewDeadline,
                title: 'Review',
              },
              {
                start: post.schedule.internalReviewDeadline,
                end: post.schedule.externalReviewDeadline,
                title: 'Approve',
              },
              {
                start: latest(post.opened, post.schedule.externalReviewDeadline),
                end: post.schedule.postToInstagramDeadline,
                title: 'Post',
              },
            ]}
          />
        )}
        {!post.archived && (
          <Form
            initialState={initialState}
            onSubmit={this.handleSubmit}
            onChange={this.handleFormChange}
          >
            <div className={styles.content}>
              <div className={styles.left}>
                <div className={styles.formSection}>
                  <Label label="Post Type" />
                  <div className={styles.choices}>
                    <div className={[styles.choices, {flexDirection: 'column'}]}>
                      <div className={styles.radioButton}>
                        <FormRadio
                          name="postType"
                          label="Instagram - Static *"
                          value={PostType.InstagramStandard}
                        />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio
                          name="postType"
                          label="Instagram - Video"
                          value={PostType.InstagramVideo}
                        />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio
                          name="postType"
                          label="Instagram - Story"
                          value={PostType.InstagramStory}
                        />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio
                          name="postType"
                          label="Instagram - Reels"
                          value={PostType.InstagramReel}
                        />
                      </div>
                    </div>

                    <div className={[styles.choices, {flexDirection: 'column'}]}>
                      <div className={styles.radioButton}>
                        <FormRadio name="postType" label="TikTok Post" value={PostType.Tiktok} />
                      </div>
                      {this.props.useYoutubeIntegration && (
                        <>
                          <div className={styles.radioButton}>
                            <FormRadio
                              name="postType"
                              label="YouTube - Video"
                              value={PostType.YoutubeVideo}
                            />
                          </div>
                          <div className={styles.radioButton}>
                            <FormRadio
                              name="postType"
                              label="YouTube - Short"
                              value={PostType.YoutubeShort}
                            />
                          </div>
                        </>
                      )}
                      {!this.props.useYoutubeIntegration && (
                        <div className={styles.radioButton}>
                          <FormRadio name="postType" label="YouTube" value={PostType.Youtube} />
                        </div>
                      )}
                      {this.props.useTwitchIntegration && (
                        <div className={styles.radioButton}>
                          <FormRadio name="postType" label="Twitch" value={PostType.Twitch} />
                        </div>
                      )}
                    </div>

                    <div className={styles.radioButton}>
                      <FormRadio name="postType" label="Content Only **" value={PostType.Content} />
                    </div>
                  </div>
                  <small className={styles.description}>
                    * The 'Instagram Static' type allows influencers to post either images or
                    videos, but the video type forces influencers to only post videos for the
                    required number of frames. Additional frames can be either image or video.
                  </small>
                  <small className={styles.description}>
                    ** 'Content Only' posts won't require the user to post to any social platform.
                    After the deliverable submission is approved, the offer is then claimable.
                  </small>
                </div>

                {this.showGalleryPhotoCount() && (
                  <div className={styles.formSection}>
                    <Label label="Carousel Items" />
                    <div className={[styles.choices, styles.galleryChoices]}>
                      <div className={styles.radioButton}>
                        <FormRadio name="galleryPhotoCount" value={1} label="1" />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio name="galleryPhotoCount" value={2} label="2" />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio name="galleryPhotoCount" value={3} label="3" />
                      </div>
                      <div className={styles.radioButton}>
                        <FormRadio name="galleryPhotoCount" value={4} label="4" />
                      </div>
                    </div>
                    <small className={styles.description}>
                      Instagram supports multiple photos per post, accessible to the user by swiping
                      through a slideshow. Useful for before-after posts.
                    </small>
                  </div>
                )}

                {!post.campaign.brandSafety && (
                  <div className={styles.formSection}>
                    <Label label="Review" />
                    <div className={styles.checkBox}>
                      <FormCheckbox
                        name="requiresReviewBeforePosting"
                        label="Requires review before posting"
                      />
                    </div>
                    <small className={styles.description}>
                      Submissions should be reviewed by TAKUMI before being posted to Instagram.
                    </small>
                  </div>
                )}

                {post.schedule && (
                  <div className={styles.formSection}>
                    <Label label="Submission Deadline" />
                    <DatePicker
                      selected={getSubmissionDeadline()}
                      timezone={post.timezone}
                      setTime={moment => moment.endOf('day')}
                      onChange={this.handleChangedSubmissionDeadline}
                    />
                    <small className={styles.description}>
                      Influencers should submit content for approval before this date.
                    </small>

                    {started && (
                      <small className={styles.description}>
                        {`Influencers will have ${getDaysToCreateContent()} ${singularOrPlural(
                          'day',
                          getDaysToCreateContent()
                        )} to create content`}
                      </small>
                    )}

                    {moment(getSubmissionDeadline()).isBefore(
                      post.schedule.suggestedEarliestSubmissionDeadline
                    ) && (
                      <div className={styles.scheduleWarning}>
                        This submission deadline might not give influencers enough time
                      </div>
                    )}

                    {moment(getSubmissionDeadline()).isAfter(
                      post.schedule.suggestedLatestSubmissionDeadline
                    ) && (
                      <div className={styles.scheduleWarning}>
                        This submission deadline might not leave enough time for review.
                      </div>
                    )}
                  </div>
                )}

                <div className={styles.formSection}>
                  <Label label="Posts Open" aria-label="opened-date" />
                  <DatePicker
                    selected={opened}
                    timezone={post.timezone}
                    id="opened-date"
                    onChange={this.handleChangedOpened}
                  />
                  <small className={styles.description}>
                    Influencers should post to Instagram after this date.
                  </small>
                </div>

                <div className={styles.formSection}>
                  <Label label="Deadline" />
                  <DatePicker
                    minDate={opened}
                    selected={deadline}
                    timezone={post.timezone}
                    setTime={moment => moment.endOf('day')}
                    errorMessage="Deadline must be after posts open"
                    id="post-deadline"
                    onChange={this.handleChangedDeadline}
                  />
                  <small className={styles.description}>
                    All submissions must be posted to Instagram by this date.
                  </small>
                </div>

                <div className={styles.formSection}>
                  <Label label="Planned Delivery Date" />
                  <DatePicker
                    minDate={opened}
                    maxDate={deadline}
                    selected={plannedDeliveryDate}
                    timezone={post.timezone}
                    setTime={moment => moment.endOf('day')}
                    errorMessage="Planned delivery date must be after posts open and before deadline"
                    id="planned-delivery-date"
                    onChange={this.handleChangedPlannedDeliveryDate}
                  />
                  <small className={styles.description}>
                    The date by which influencers are planning to deliver the content.
                  </small>
                </div>

                <div className={styles.briefWarning}>
                  Please note that if you are trying to paste a brief into the brief editor, there's
                  an issue with pasting from Microsoft Word into the editor using Google Chrome.
                  Either use Google Docs to copy the text or paste in the brief editor using Safari
                  for now.
                </div>
                <div className={styles.formSection}>
                  <BriefEditorInput
                    name="brief"
                    documentTitle={`Campaign brief - ${post.campaign.name}`}
                  />
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.formSection}>
                  <div className={styles.rowHeading}>Caption</div>
                  <FormTextInputWithImage
                    name="mention"
                    prefix="@"
                    label="Handle"
                    validators={{isInstagramUsername: val => !val || isInstagramUsername(val)}}
                    messages={{
                      isInstagramUsername: val => `"${val}" is not a valid Instagram username`,
                    }}
                    imageUrl={instagramUser?.profilePicture}
                    pending={instagramUserLoading}
                    onUpdate={debounce(value => this.handleMentionChange(value), 1000)}
                  />
                </div>

                {isStory() && (
                  <div>
                    <div className={styles.formSection}>
                      <FormTextInput label="Swipe up link" name="swipeUpLink" />
                    </div>
                  </div>
                )}

                <div className={styles.formSection}>
                  <Label label="Caption Requirements" />
                  <FormCheckbox
                    name="startFirstHashtag"
                    label="Tag requirements for the start of the caption"
                  />
                  <FormTextInput name="hashtags[0]" prefix="#" />
                  <FormTextInput name="hashtags[1]" prefix="#" />
                  <FormTextInput name="hashtags[2]" prefix="#" />
                  <FormTextInput name="hashtags[3]" prefix="#" />
                  <FormTextInput name="hashtags[4]" prefix="#" />
                </div>

                <div className={styles.price}>
                  <FormTextInput
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    description="The price of the post is only used in the reports to calculate the projected cost per engagement, cost per view, etc."
                    prefix={currencyForMarket(post.campaign.marketSlug)}
                    label="Price"
                    name="price"
                  />
                </div>
              </div>
            </div>

            <div className={styles.buttons}>
              <div className={styles.saveButton}>
                <FormSubmitButton text="Save" onSuccessText="Saved" />
              </div>
              <div>
                <Prompt
                  title="Archive post"
                  body={
                    <div>
                      <p>Are you sure you want to archive this post?</p>
                      <p>
                        Note that once the post has been archived, it won't be listed in campaign
                        posts
                      </p>
                    </div>
                  }
                  control={<FormButton text="Archive&hellip;" dangerOutline />}
                  onSubmit={this.props.archivePost}
                />
              </div>
            </div>
          </Form>
        )}
      </Section>
    );
  }
}

const options = ({match: {params}}) => ({
  variables: {
    postId: params.postId,
  },
  refetchQueries: ['CampaignEditPostQuery'],
});

const stateToProps = (state, {match: {params}}) => ({
  campaignId: params.campaign,
  domain: params.advertiser,
});

const CampaignEditPostComposed = compose(
  withRouter,
  graphql(CampaignEditPostQuery, {options}),
  graphql(CampaignEditPostMutation, {
    options,
    props: ({
      mutate,
      ownProps: {
        match: {params},
      },
    }) => ({
      updatePost: fields => mutate({variables: {postId: params.postId, ...fields}}),
    }),
  }),
  graphql(ArchivePostMutation, {
    options,
    props: ({
      mutate,
      ownProps: {
        match: {
          params: {postId},
        },
      },
    }) => ({
      archivePost: () => mutate({variables: {postId}}),
    }),
  }),
  graphql(InstagramUserQuery, {
    props: ({data: {instagramUser = {}, loading, refetch}}) => ({
      instagramUser,
      instagramUserLoading: loading,
      getInstagramUser: username => refetch({username}),
    }),
  }),
  connect(stateToProps, null)
)(CampaignEditPost);

const WrappedComponent = ({...props}) => {
  const {useYoutubeIntegration, useTwitchIntegration} = useFeatureFlags();
  return <CampaignEditPostComposed {...{useYoutubeIntegration, useTwitchIntegration, ...props}} />;
};

export default WrappedComponent;
