import React, {ReactNode, useEffect, useState} from 'react';
import {useHistory, useRouteMatch, useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import * as qs from 'query-string';
import {makeStyles, Grid, Typography, Container} from '@material-ui/core';

import {FilterItem, LoaderDots} from 'components/Widgets';

import {bold, sizeBody2} from '../../../../consts/brand.integration';
import {
  ActiveCampaignsQuery,
  CampaignCountQuery,
  CampaignHistoryQuery,
  ExpiredCampaignsQuery,
  RequestedCampaignsQuery,
  RevokedOrRejectedCampaignsQuery,
  TargetedCampaignsQuery,
} from './queries';
import {filters, filtersList} from './constants';
import CampaignsTable from './components/CampaignsTable';

const Offers: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const {
    params: {influencerId},
  }: {params: {influencerId: string}} = useRouteMatch();
  const {state}: {state?: string} = qs.parse(location.search);

  const [filter, setFilter] = useState<string>(state || 'active');

  const {loading, data: campaignCountData} = useQuery(CampaignCountQuery, {
    variables: {
      id: influencerId,
    },
  });

  useEffect(() => {
    if (state) {
      setFilter(state);
    }
  }, [state]);

  const getFilterItemCount = (filter: string): number | null => {
    const data = campaignCountData;
    switch (filter) {
      case filters.ACTIVE:
        return data.activeCampaignCount.count;
      case filters.REQUESTED:
        return data.requestedCampaignCount.count;
      case filters.AWAITING_RESPONSE:
        return data.targetedCampaignCount.count;
      case filters.REVOKED_OR_REJECTED:
        return data.revokedOrRejectedCampaignCount.count;
      case filters.HISTORY:
        return data.campaignHistoryCount.count;
      case filters.EXPIRED:
        return data.expiredCampaignCount.count;
      default:
        return null;
    }
  };

  const getCampaignsQuerySource = () => {
    if (filter === filters.ACTIVE) return ActiveCampaignsQuery;
    else if (filter === filters.REQUESTED) return RequestedCampaignsQuery;
    else if (filter === filters.AWAITING_RESPONSE) return TargetedCampaignsQuery;
    else if (filter === filters.REVOKED_OR_REJECTED) return RevokedOrRejectedCampaignsQuery;
    else if (filter === filters.HISTORY) return CampaignHistoryQuery;
    else if (filter === filters.EXPIRED) return ExpiredCampaignsQuery;
    return undefined;
  };

  const onFilterSelected = (value: string) =>
    history.replace({
      ...location,
      search: qs.stringify({
        state: value,
      }),
    });

  const renderFilters = (): ReactNode | null => {
    if (loading) {
      return (
        <Container>
          <LoaderDots />
        </Container>
      );
    }

    if (campaignCountData) {
      return (
        <Grid item container xs={12} spacing={2}>
          {filtersList.map(_filter => (
            <Grid key={_filter} item>
              <FilterItem
                small
                checked={_filter === filter}
                onChange={() => onFilterSelected(_filter)}
              >
                {_filter.split('-').join(' ')}
                {<Typography className={classes.count}>{getFilterItemCount(_filter)}</Typography>}
              </FilterItem>
            </Grid>
          ))}
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid container>
      {renderFilters()}
      <Grid item xs={12} className={classes.table}>
        {!loading && (
          <CampaignsTable {...{influencerId, filter}} querySource={getCampaignsQuerySource()} />
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  table: {
    marginTop: '20px',
  },
  count: {
    display: 'inline',
    fontSize: sizeBody2,
    fontWeight: bold,
    marginLeft: '5px',
  },
});

export default Offers;
