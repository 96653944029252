import {gql} from '@apollo/client';

const InfluencerCampaignFragment = gql`
  fragment InfluencerCampaignFragment on InfluencerCampaignAndOffer {
    reward {
      formattedValue
    }
    offer {
      id
      state
      accepted
      payable
      rejected
      revokeEvent {
        created
        creator {
          id
          fullName
          email
        }
      }
    }
    campaign {
      id
      name
      public
      created
      photos {
        coverPhoto {
          url
        }
      }
      rewardModel
      state
      advertiser {
        id
        name
        domain
      }
      posts {
        id
        deadline
      }
    }
  }
`;

export const CampaignCountQuery = gql`
  query CampaignCount($id: UUIDString!) {
    activeCampaignCount: activeCampaigns(id: $id) {
      count
    }
    targetedCampaignCount: targetedCampaigns(id: $id) {
      count
    }
    revokedOrRejectedCampaignCount: revokedOrRejectedCampaigns(id: $id) {
      count
    }
    campaignHistoryCount: campaignHistory(id: $id) {
      count
    }
    expiredCampaignCount: expiredCampaigns(id: $id) {
      count
    }
    requestedCampaignCount: requestedCampaigns(id: $id) {
      count
    }
  }
`;

export const ActiveCampaignsQuery = gql`
  query ActiveCampaigns($id: UUIDString!, $cursor: String) {
    influencerCampaigns: activeCampaigns(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;

export const RequestedCampaignsQuery = gql`
  query RequestedCampaigns($id: UUIDString!, $cursor: String) {
    influencerCampaigns: requestedCampaigns(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;

export const TargetedCampaignsQuery = gql`
  query TargetedCampaigns($id: UUIDString!, $cursor: String) {
    influencerCampaigns: targetedCampaigns(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;

export const RevokedOrRejectedCampaignsQuery = gql`
  query RevokedOrRejectedCampaigns($id: UUIDString!, $cursor: String) {
    influencerCampaigns: revokedOrRejectedCampaigns(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;

export const CampaignHistoryQuery = gql`
  query CampaignHistory($id: UUIDString!, $cursor: String) {
    influencerCampaigns: campaignHistory(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;

export const ExpiredCampaignsQuery = gql`
  query ExpiredCampaigns($id: UUIDString!, $cursor: String) {
    influencerCampaigns: expiredCampaigns(id: $id, after: $cursor, first: 20) {
      count
      edges {
        node {
          ...InfluencerCampaignFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerCampaignFragment}
`;
