import {Button, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Icon} from '@iconify/react';
import {Link} from 'react-router-dom';

import {socialIcons} from '../../../../../consts/icons';
import UserCard from './UserCard';

interface Props {
  historyItem: HistoryInterface;
  gig: Gig;
}

const CardPosted: React.FC<Props> = ({historyItem}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
      {historyItem.link && (
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Icon
              icon={socialIcons[historyItem.postType] || 'ic:baseline-photo'}
              width={32}
              className={classes.iconContainer}
            />
          </Grid>
          <Grid item xs>
            <Link to={{pathname: historyItem.link}} target="_blank" rel="noopener noreferrer">
              <Button variant="outlined" className={classes.button}>
                <Typography variant="button">Open in social network</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  iconContainer: {
    marginLeft: '.6rem',
  },
  button: {
    margin: '.2rem 0 0 .2rem',
  },
});

export default CardPosted;
