import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery, useLazyQuery, useMutation} from '@apollo/client';
import {debounce} from 'lodash';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {KeyboardArrowDown} from '@material-ui/icons';
import * as yup from 'yup';
import {useFormik} from 'formik';

import {DocumentTitle, LoaderDots} from 'components/Widgets';

import {advertiserUrl} from 'consts/urls';
import {getPageTitle} from 'consts/utilities';
import {IndustryTreeQuery} from 'scenes/Advertiser/AdvertiserSettings/graphqlQuery';

import {useStyles} from './styles';
import {ImageUploader} from '../AdvertiserSettings/components';
import {uploadImage} from '../../../services/api';
import {CountriesQuery, CreateAdvertiserMutation, InstagramUserQuery} from './graphqlQuery';
import IndustrySelector from '../AdvertiserSettings/components/IndustrySelector';
import {AdvertiserIndustryEntity, TreeViewEntity} from '../AdvertiserSettings/types';
import BackButton from '../../../components/BackButton';
import {theme} from '../../../consts/theme';
import {placeholderIndustryStyle} from '../AdvertiserSettings/components/IndustrySelector/styles';
import {inputPlaceholder} from '../../../consts/brand.integration';

let timeout: NodeJS.Timeout | undefined;

const validationSchema = yup.object({
  uniqueRef: yup
    .string()
    .required('The `Unique Reference` field is required')
    .matches(
      /^[a-zA-Z0-9_\-.\s]+$/,
      "Invalid Unique Reference (only special characters allowed  '-', '_' or '.')"
    ),
  name: yup
    .string()
    .required('The `Company Name` field is required')
    .matches(/^[A-Z0-9!&?]/i, 'Invalid Company name')
    .min(2, 'Minimal length 2 symbols')
    .max(255, 'Max length 255'),
  regionId: yup.string().required('Region is required'),
  industry: yup
    .array()
    .min(1, 'At least one industry must be selected')
    .of(
      yup.object().shape({
        id: yup.string().required('ID is required'),
      })
    ),
});

const AdvertiserCreate: React.FC = () => {
  const defaultProfileLogo = 'https://lemonade.imgix.net/anonymous.jpg';
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState<string>();
  const [uniqueRef, setUniqueRef] = useState<string>();
  /**
   * `setInstagramUser` is unused - but `instagramUser` is used. This seems odd
   * and should probably be documented if it's deliberate?
   */
  const [instagramUser, _setInstagramUser] = useState<string>();
  const [selectedIndustries, setSelectedIndustries] = useState<AdvertiserIndustryEntity[]>([]);

  const classes = useStyles();
  const history = useHistory();

  const countriesResult = useQuery(CountriesQuery);
  const {data: industriesData} = useQuery(IndustryTreeQuery);
  const [loadInstagramUser, instagramUserResult] = useLazyQuery(InstagramUserQuery);
  const [createAdvertiser, createAdvertiserResult] = useMutation(CreateAdvertiserMutation);

  useEffect(() => {
    setUniqueRef(instagramUser);
    if (instagramUser?.length) {
      clearTimeout(timeout);
      timeout = setTimeout(() => loadInstagramUser({variables: {username: instagramUser}}), 2000);
    } else {
      clearTimeout(timeout);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instagramUser]);

  useEffect(() => {
    setIsLoading(instagramUserResult?.loading);
    if (instagramUserResult.data?.instagramUser) {
      setLogo(instagramUserResult.data?.instagramUser?.profilePicture);
    }
  }, [instagramUserResult]);

  useEffect(() => {
    if (createAdvertiserResult.data) {
      const advertiser = createAdvertiserResult.data.createAdvertiser?.advertiser;
      if (advertiser) {
        history.push(advertiserUrl(advertiser));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAdvertiserResult]);

  const onUploadLogo = async (list: FileList | null) => {
    if (list?.length) {
      setIsLoading(true);
      const imageUrl = await uploadImage(list[0]);
      if (imageUrl) {
        setLogo(imageUrl);
      }
      setIsLoading(false);
    }
  };

  const onUpdateUniqueRef = (value: string) => {
    let final: string | undefined = undefined;
    if (!value.search(/^[a-zA-Z0-9_\-.\s]+$/)) {
      final = value.replace(/\s/g, '_').toLowerCase();
      formik.setFieldValue('uniqueRef', final);
    } else {
      final = undefined;
    }
    setUniqueRef(final);
  };

  const onLoadInstagramUser = debounce((username: string) => {
    loadInstagramUser({variables: {username}});
  }, 500);

  const onSubmitModel = async (model: Record<string, undefined>) => {
    const profilePicture = logo ?? defaultProfileLogo;

    createAdvertiser({
      variables: {
        ...model,
        profilePicture,
        uniqueRef,
        advertiserIndustriesIds: selectedIndustries.map(item => item.id),
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      uniqueRef: '',
      instagramUser: '',
      name: '',
      vatNumber: '',
      regionId: '',
      industry: selectedIndustries,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmitModel(values);
    },
  });

  return (
    <Fragment>
      <div className={classes.backButton}>
        <BackButton title="Create new brand" />
      </div>
      <div className={classes.root}>
        <DocumentTitle title={getPageTitle('Create Advertiser')} />
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.row}>
            <div className={classes.column}>
              <FormControl>
                <FormLabel
                  id="unique-ref-input-label"
                  htmlFor="unique-ref-input"
                  className={classes.label}
                >
                  <Typography component="span" variant="h4">
                    Unique Reference name
                  </Typography>
                </FormLabel>
                <TextField
                  fullWidth
                  id="unique-ref-input"
                  aria-labelledby="unique-ref-input-label"
                  name="uniqueRef"
                  variant="outlined"
                  value={formik.values.uniqueRef}
                  disabled={!!formik.values.instagramUser}
                  error={formik.touched.uniqueRef && Boolean(formik.errors.uniqueRef)}
                  helperText={formik.touched.uniqueRef && formik.errors.uniqueRef}
                  className={classes.control}
                  FormHelperTextProps={{classes: {root: classes.helperText}}}
                  placeholder="Add unique reference name"
                  InputProps={{
                    classes: {
                      input: classes.inputText,
                    },
                  }}
                  onChange={e => {
                    if (!instagramUser) {
                      formik.handleChange(e);
                      onUpdateUniqueRef(e.target.value);
                    }
                  }}
                />
                {instagramUserResult.loading && <CircularProgress />}
              </FormControl>
              <FormControl>
                <FormLabel
                  id="instagram-user-input-label"
                  htmlFor="instagram-user-input"
                  className={classes.label}
                  style={{marginTop: '32px'}}
                >
                  <Typography component="span" variant="h4">
                    Brand Instagram Username
                  </Typography>
                </FormLabel>
                <TextField
                  fullWidth
                  id="instagram-user-input"
                  inputProps={{
                    'aria-labelledby': 'instagram-user-input-label',
                  }}
                  name="instagramUser"
                  variant="outlined"
                  value={formik.values.instagramUser}
                  error={formik.touched.instagramUser && Boolean(formik.errors.instagramUser)}
                  helperText={formik.touched.instagramUser && formik.errors.instagramUser}
                  className={classes.control}
                  FormHelperTextProps={{classes: {root: classes.helperText}}}
                  placeholder="Add brand Instagram username"
                  InputProps={{
                    classes: {
                      input: classes.inputText,
                    },
                  }}
                  onChange={e => {
                    formik.handleChange(e);
                    onLoadInstagramUser(e.target.value);
                    formik.setFieldValue(
                      'uniqueRef',
                      e.target.value.replace(/\s/g, '_').toLowerCase()
                    );
                    onUpdateUniqueRef(e.target.value);
                  }}
                />
                {instagramUserResult.loading && <CircularProgress />}
              </FormControl>
              <FormControl>
                <FormLabel
                  id="name-input-label"
                  htmlFor="name-input"
                  className={classes.label}
                  style={{marginTop: '32px'}}
                >
                  <Typography component="span" variant="h4">
                    Company Name
                  </Typography>
                </FormLabel>
                <TextField
                  fullWidth
                  id="name-input"
                  name="name"
                  inputProps={{
                    'aria-labelledby': 'name-input-label',
                  }}
                  variant="outlined"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  className={classes.control}
                  FormHelperTextProps={{classes: {root: classes.helperText}}}
                  InputProps={{
                    classes: {
                      input: classes.inputText,
                    },
                  }}
                  placeholder="Add company name"
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  id="vat-number-input-label"
                  htmlFor="vat-number-input"
                  className={classes.label}
                  style={{marginTop: '32px'}}
                >
                  <Typography component="span" variant="h4">
                    VAT number
                  </Typography>
                </FormLabel>
                <TextField
                  fullWidth
                  id="vat-number-input"
                  name="vatNumber"
                  inputProps={{
                    'aria-labelledby': 'vat-number-input-label',
                  }}
                  variant="outlined"
                  placeholder="Add VAT number"
                  value={formik.values.vatNumber}
                  className={classes.control}
                  InputProps={{
                    classes: {
                      input: classes.inputText,
                    },
                  }}
                  onChange={formik.handleChange}
                />
              </FormControl>

              {countriesResult.loading ? (
                <LoaderDots />
              ) : (
                <Fragment>
                  <FormControl>
                    <FormLabel
                      id="region-select-label"
                      htmlFor="region-select"
                      className={classes.label}
                      style={{marginTop: '32px'}}
                    >
                      <Typography component="span" variant="h4">
                        Region
                      </Typography>
                    </FormLabel>
                    <Select
                      displayEmpty
                      id="region-select"
                      inputProps={{
                        id: 'region-select',
                        'aria-labelledby': 'region-select-label',
                      }}
                      variant="outlined"
                      value={formik.values.regionId}
                      error={formik.touched.regionId && Boolean(formik.errors.regionId)}
                      className={classes.control}
                      IconComponent={props => (
                        <KeyboardArrowDown
                          {...props}
                          style={{marginRight: '10px', color: theme.palette['ui-07']}}
                        />
                      )}
                      onChange={e => {
                        formik.setFieldValue('regionId', e.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <span className={classes.inactiveSelect}>Select region</span>
                      </MenuItem>
                      {countriesResult.data.countries.map(country => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.regionId && Boolean(formik.errors.regionId) && (
                      <FormHelperText className={classes.error}>
                        {formik.errors.regionId}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Fragment>
              )}

              <FormControl>
                <label
                  className={classes.label}
                  id="brand-industry-label"
                  htmlFor="brand-industry-select"
                  style={{marginTop: '32px'}}
                >
                  <Typography component="span" variant="h4">
                    Industry
                  </Typography>
                </label>

                <IndustrySelector
                  id="brand-industry-select"
                  labelId="brand-industry-label"
                  industries={industriesData?.industries ?? []}
                  selectedIndustries={selectedIndustries}
                  isSelectedItemVisible
                  styles={{
                    container: (base: any) => ({...base, marginRight: '16px', width: '450px'}),
                    placeholder: (base: any) => ({
                      ...base,
                      ...placeholderIndustryStyle,
                      fontWeight: 400,
                      marginLeft: '6px',
                      color: inputPlaceholder,
                    }),
                  }}
                  onChange={industryId => {
                    const changedItem = getNode(industriesData?.industries, industryId);
                    if (changedItem) {
                      let selected = [];
                      if (selectedIndustries.findIndex(item => item.id === changedItem.id) !== -1) {
                        selected = selectedIndustries.filter(item => item.id !== changedItem.id);
                      } else {
                        selected = [...selectedIndustries, changedItem];
                      }
                      setSelectedIndustries(selected);
                      formik.setFieldValue('industry', selected);
                    }
                  }}
                />
                {formik.touched.industry && Boolean(formik.errors.industry) && (
                  <FormHelperText className={classes.error}>
                    {formik.errors.industry}
                  </FormHelperText>
                )}
              </FormControl>

              <Button variant="outlined" className={classes.button} fullWidth type="submit">
                <AddIcon />
                <Typography component="span" variant="button">
                  Create brand
                </Typography>
              </Button>
            </div>

            <div className={classes.column}>
              <FormControl style={{alignSelf: 'flex-end'}}>
                <label id="brand-logo-label" htmlFor="brand-logo-image" className={classes.label}>
                  <Typography component="span" variant="h4">
                    Logo
                  </Typography>
                </label>
                <ImageUploader
                  id="brand-logo-image"
                  labelId="brand-logo-label"
                  pending={isLoading}
                  image={logo}
                  square
                  size={140}
                  onUpload={onUploadLogo}
                />
              </FormControl>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AdvertiserCreate;

function getNode(
  industries: ReadonlyArray<TreeViewEntity> | null,
  nodeId: string
): AdvertiserIndustryEntity | null {
  if (industries) {
    for (const item of industries) {
      const industry = item.children.find(x => x.id === nodeId);
      if (industry) {
        return industry;
      }
    }
  }
  return null;
}
