import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import uniqueId from 'services/uniqueId';

import style from './style.css';

const Checkbox = ({id = uniqueId(), checked, label, disabled, description, light, onChange}) => (
  <div className={style.wrapper}>
    <div className={style.container}>
      <div className={style.checkboxContainer}>
        <div
          className={classNames([
            style.checkbox,
            {[style.checked]: checked},
            {[style.disabled]: disabled},
          ])}
        >
          {checked && (
            <svg
              width="12px"
              height="9px"
              viewBox="0 0 12 9"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs />
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  id="Checkbox-checked"
                  transform="translate(-7.000000, -9.000000)"
                  fillRule="nonzero"
                  fill="#FFFFFF"
                >
                  <path
                    d="M18.75,10.2805317 L11.2835856,17.7469461 L7.25,13.7133605 L8.28406468,12.6792958 L11.2835856,15.6714829 L17.7153051,9.24583686 L18.75,10.2805317 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </svg>
          )}
        </div>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          className={style.hiddenButton}
          disabled={disabled}
          aria-checked={checked}
          aria-label={label}
          onChange={onChange}
        />
      </div>
      {label && (
        <label
          className={classNames([style.label, {[style.big]: description, [style.light]: light}])}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
    {description && <div className={style.description}>{description}</div>}
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
};

export default Checkbox;
