import {gql} from '@apollo/client';

export const CampaignEditPostQuery = gql`
  query CampaignEditPostQuery($postId: UUIDString!) {
    isDeveloper: hasPermission(permission: "developer")
    post(id: $postId) {
      id
      mention
      hashtags
      startFirstHashtag
      swipeUpLink
      postType
      galleryPhotoCount
      opened
      submissionDeadline
      deadline
      brief {
        __typename
        ... on BriefHeading {
          value
        }
        ... on BriefParagraph {
          value
        }
        ... on BriefSubHeading {
          value
        }
        ... on BriefDosAndDonts {
          dos
          donts
        }
        ... on BriefUnorderedList {
          items
        }
        ... on BriefOrderedList {
          items
        }
        ... on BriefImportant {
          value
        }
      }
      timezone
      archived
      requiresReviewBeforePosting
      price {
        value
        formattedValue
      }
      campaign {
        id
        brandSafety
        marketSlug
        started
        name
      }
      schedule {
        submissionDeadline
        internalReviewDeadline
        externalReviewDeadline
        postToInstagramDeadline
        suggestedEarliestSubmissionDeadline
        suggestedLatestSubmissionDeadline
        plannedDeliveryDate
      }
    }
  }
`;

export const CampaignEditPostMutation = gql`
  mutation CampaignEditPostMutation(
    $postId: UUIDString!
    $postType: PostType
    $mention: StrippedString
    $hashtags: [String]
    $startFirstHashtag: Boolean
    $swipeUpLink: String
    $opened: DateTime
    $submissionDeadline: DateTime
    $deadline: DateTime
    $plannedDeliveryDate: DateTime
    $instructions: String
    $galleryPhotoCount: Int
    $requiresReviewBeforePosting: Boolean
    $price: Int
    $brief: [BriefSection]
  ) {
    updatePost(
      id: $postId
      postType: $postType
      mention: $mention
      startFirstHashtag: $startFirstHashtag
      hashtags: $hashtags
      swipeUpLink: $swipeUpLink
      opened: $opened
      submissionDeadline: $submissionDeadline
      deadline: $deadline
      plannedDeliveryDate: $plannedDeliveryDate
      instructions: $instructions
      galleryPhotoCount: $galleryPhotoCount
      requiresReviewBeforePosting: $requiresReviewBeforePosting
      price: $price
      brief: $brief
    ) {
      ok
      post {
        id
        mention
        hashtags
        startFirstHashtag
        swipeUpLink
        opened
        submissionDeadline
        deadline
        galleryPhotoCount
        timezone
        postType
        requiresReviewBeforePosting
        schedule {
          plannedDeliveryDate
        }
        price {
          value
          formattedValue
        }
        report {
          ... on StandardPostReport {
            projectedCpe {
              value
              formattedValue
            }
          }
          ... on StoryPostReport {
            projectedCpe {
              value
              formattedValue
            }
          }
          ... on VideoPostReport {
            projectedCpv {
              value
              formattedValue
            }
          }
        }
        brief {
          __typename
          ... on BriefHeading {
            value
          }
          ... on BriefParagraph {
            value
          }
          ... on BriefSubHeading {
            value
          }
          ... on BriefDosAndDonts {
            dos
            donts
          }
          ... on BriefUnorderedList {
            items
          }
          ... on BriefOrderedList {
            items
          }
          ... on BriefImportant {
            value
          }
        }
        campaign {
          id
          brandSafety
        }
      }
    }
  }
`;

export const ArchivePostMutation = gql`
  mutation ArchivePostMutation($postId: UUIDString!) {
    archivePost(id: $postId) {
      ok
      post {
        id
        archived
        campaign {
          id
          posts {
            id
            archived
          }
        }
      }
    }
  }
`;

export const InstagramUserQuery = gql`
  query InstagramUserQuery($username: String) {
    instagramUser(username: $username) {
      id
      profilePicture
    }
  }
`;
