import {Grid} from '@material-ui/core';
import React from 'react';

import {
  InstagramPostTypes,
  TiktokPostTypes,
  TwitchPostTypes,
  YoutubePostTypes,
} from 'consts/campaignPostType';

import InstagramVideoPostStats from './components/InstagramVideoPostStats';
import TiktokPostStats from './components/TiktokPostStats';
import YoutubePostStats from './components/YoutubePostStats';
import TwitchPostStats from './components/TwitchPostStats';

interface Props {
  gig: Gig & {
    post: {
      postType: PostType;
    };
    instagramContent?: InstagramPost;
    tiktokPost?: TiktokPost;
    youtubeVideo?: YoutubeVideo;
    twitchVideo?: TwitchVideo;
  };
}

const PostStats: React.FC<Props> = ({gig}) => {
  return (
    <Grid container direction="row">
      {InstagramPostTypes.includes(gig.post.postType) && (
        <InstagramVideoPostStats
          data={gig.instagramContent as InstagramPost}
          igPostType={gig.post.postType}
        />
      )}
      {TiktokPostTypes.includes(gig.post.postType) && (
        <TiktokPostStats data={gig.tiktokPost as TiktokPost} />
      )}
      {YoutubePostTypes.includes(gig.post.postType) && (
        <YoutubePostStats data={gig.youtubeVideo as YoutubeVideo} />
      )}
      {TwitchPostTypes.includes(gig.post.postType) && (
        <TwitchPostStats data={gig.twitchVideo as TwitchVideo} />
      )}
    </Grid>
  );
};

export default PostStats;
