import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import uniqueId from 'services/uniqueId';

import {Label} from '../';
import styles from './style.css';

export default class Select extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    canBeEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    border: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    placeholder: 'Select an option',
    value: '',
    border: true,
  };

  componentDidUpdate() {
    if (this.props.options) {
      if (!this.props.canBeEmpty) {
        if (this.props.value === null || this.props.value == undefined || this.props.value == '') {
          this.props.onChange(this.props.options[0].value);
        }
      }
    }
  }

  getSelectedOption = value => {
    return this.props.options.filter(x => x.value.toString() === value.toString())[0];
  };

  getTextFromOption = value => {
    if (this.props.canBeEmpty && !this.props.value) {
      return this.props.placeholder;
    }

    const selectedOption = this.getSelectedOption(value);
    return selectedOption?.name;
  };

  change = event => {
    const selectedOption = this.getSelectedOption(event.target.value);
    this.props.onChange(selectedOption.value);
  };

  render() {
    const id = uniqueId('select');

    const options = this.props.options.map(option => {
      return (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      );
    });

    if (this.props.canBeEmpty) {
      options.unshift(
        <option key={id} value="" disabled>
          {this.props.placeholder}
        </option>
      );
    }

    return (
      <div>
        {this.props.label && <Label htmlFor={id} label={this.props.label} />}
        <span
          className={classNames(
            styles.root,
            this.props.border && styles.border,
            this.props.block && styles.block,
            this.props.disabled && styles.disabled,
            this.props.children && styles.noBorder,
            this.props.description && styles.withDescription
          )}
        >
          <select
            id={id}
            value={this.props.value || ''}
            // TO DO: temporary fix for selecting element when only one option
            className={classNames(
              styles.select,
              this.props.disabled && styles.disabledSelect,
              this.props.description && styles.big
            )}
            disabled={this.props.disabled}
            onChange={this.change}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBlur={() => {}}
          >
            {options}
          </select>
          <span
            className={classNames(
              !this.props.children && styles.current,
              this.props.description && styles.big
            )}
          >
            {this.props.children ?? this.getTextFromOption(this.props.value)}
          </span>
        </span>
        {this.props.description && (
          <div className={styles.description}>{this.props.description}</div>
        )}
      </div>
    );
  }
}
