import React from 'react';
import PropTypes from 'prop-types';
import {Parser as Json2csvParser} from 'json2csv';
import FileSaver from 'file-saver/FileSaver';

import styles from './style.css';

class DownloadCSV extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    filename: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string,
    className: PropTypes.object,
  };

  static defaultProps = {
    fields: null, // Auto-detect fields
    filename: 'data.csv',
    text: 'Download as CSV',
  };

  downloadCSV = () => {
    const {data, fields, filename} = this.props;

    const parser = new Json2csvParser({fields, flatten: true});
    const csv = parser.parse(data);

    var blob = new Blob([csv], {type: 'data:text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, filename);
  };

  render() {
    const className = this.props.className || styles.link;

    return (
      <div className={className} onClick={this.downloadCSV}>
        {this.props.text}
      </div>
    );
  }
}

export default DownloadCSV;
