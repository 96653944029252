import React from 'react';
import {useRouteMatch} from 'react-router';
import {useMutation, useQuery, gql} from '@apollo/client';

import {Campaign} from 'services/fragments';

import CampaignSetup from '../components/CampaignSetup';

const EditCampaign: React.FC = () => {
  const {params}: {params: {campaign: string}} = useRouteMatch();
  const {loading, data} = useQuery<{campaign: Campaign}>(CampaignSetupQuery, {
    variables: {
      campaignId: params.campaign,
    },
  });
  const [updateCampaign] = useMutation(UpdateCampaignMutation);

  return (
    <CampaignSetup
      loading={loading}
      campaign={data?.campaign}
      updateCampaign={(fields: Record<string, string>) => updateCampaign({variables: fields})}
    />
  );
};

const UserFragment = gql`
  fragment editCampaignUserFields on User {
    __typename
    id
    fullName
    email
    roleName
    profilePicture
  }
`;

const CampaignSetupQuery = gql`
  query EditCampaignSetupQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      owner {
        id
        ...editCampaignUserFields
      }
      advertiser {
        id
      }
      campaignManager {
        id
        ...editCampaignUserFields
      }
      secondaryCampaignManager {
        id
        ...editCampaignUserFields
      }
      archived
      price {
        formattedValue
        value
      }
      listPrice {
        formattedValue
        value
      }
      prompts {
        text
        choices
        type
        brandVisible
      }
      offPlatform
      offPlatformReason
      customRewardUnits
      hasCustomReward
      paymentTerms
      description
      hasNda
      brandSafety
      brandMatch
      proBono
      extendedReview
      industry
      hubspotDealId
      mondayBoardId
      marketSlug
      name
      photos {
        coverPhoto {
          url
        }
        secondaryPhotos {
          url
        }
      }
      rewardModel
      shippingRequired
      requireInsights
      state
      canBeStashed
      ...targetFields
      organicPerformanceMetrics
      paidPerformanceMetrics
      cpmBenchmark {
        formattedValue
        value
      }
      campaignKpis {
        metric
        value
      }
    }
  }
  ${UserFragment}
  ${Campaign.targetFragment}
`;

const UpdateCampaignMutation = gql`
  mutation UpdateCampaignMutation(
    $id: UUIDString!
    $owner: UUIDString
    $campaignManager: UUIDString
    $secondaryCampaignManager: UUIDString
    $description: String
    $hasNda: Boolean
    $brandSafety: Boolean
    $brandMatch: Boolean
    $proBono: Boolean
    $industry: String
    $hubspotDealId: String
    $mondayBoardId: String
    $name: String
    $pictures: [PictureInput]
    $shippingRequired: Boolean
    $requireInsights: Boolean
    $targetInstagram: Int
    $targetTiktok: Int
    $targetYoutube: Int
    $targetTwitch: Int
    $targetContent: Int
    $price: Int
    $listPrice: Int
    $customRewardUnits: Int
    $prompts: [Prompt]
    $paymentTerms: PaymentTerms
    $organicPerformanceMetrics: [String]
    $paidPerformanceMetrics: [String]
    $campaignKpis: [CampaignKpiInput!]
    $cpmBenchmark: Int
  ) {
    updateCampaign(
      id: $id
      owner: $owner
      campaignManager: $campaignManager
      secondaryCampaignManager: $secondaryCampaignManager
      description: $description
      hasNda: $hasNda
      brandSafety: $brandSafety
      brandMatch: $brandMatch
      proBono: $proBono
      industry: $industry
      hubspotDealId: $hubspotDealId
      mondayBoardId: $mondayBoardId
      name: $name
      pictures: $pictures
      shippingRequired: $shippingRequired
      requireInsights: $requireInsights
      targetInstagram: $targetInstagram
      targetTiktok: $targetTiktok
      targetYoutube: $targetYoutube
      targetTwitch: $targetTwitch
      targetContent: $targetContent
      price: $price
      listPrice: $listPrice
      customRewardUnits: $customRewardUnits
      prompts: $prompts
      paymentTerms: $paymentTerms
      organicPerformanceMetrics: $organicPerformanceMetrics
      paidPerformanceMetrics: $paidPerformanceMetrics
      campaignKpis: $campaignKpis
      cpmBenchmark: $cpmBenchmark
    ) {
      ok
      campaign {
        id
        archived
        offPlatform
        offPlatformReason
        owner {
          id
          ...editCampaignUserFields
        }
        campaignManager {
          id
          ...editCampaignUserFields
        }
        secondaryCampaignManager {
          id
          ...editCampaignUserFields
        }
        price {
          formattedValue
          value
        }
        listPrice {
          formattedValue
          value
        }
        customRewardUnits
        hasCustomReward
        description
        hasNda
        brandSafety
        brandMatch
        proBono
        extendedReview
        industry
        hubspotDealId
        mondayBoardId
        name
        paymentTerms
        photos {
          coverPhoto {
            url
          }
        }
        prompts {
          text
          choices
          type
        }
        ...targetFields
        shippingRequired
        requireInsights
        posts {
          id
          archived
        }
        organicPerformanceMetrics
        paidPerformanceMetrics
        campaignKpis {
          metric
          value
        }
        cpmBenchmark {
          formattedValue
          value
        }
      }
    }
  }
  ${UserFragment}
  ${Campaign.targetFragment}
`;

export default EditCampaign;
