import React from 'react';
import {withRouter} from 'react-router';
import {gql, useQuery} from '@apollo/client';
import {createStyles, Grid, makeStyles} from '@material-ui/core';

import {LoaderDots, Definition, InstagramMedia, KeyValueText, Media} from 'components/Widgets';

import {Gig} from 'services/fragments';

import {purple} from '../../../../consts/brand.integration';

interface Props {
  match: {
    params: {
      influencerId: string;
    };
  };
}

const InstagramPosts: React.FC<Props> = ({match: {params}}) => {
  const classes = useStyles();

  const {loading, data, error} = useQuery(InstagramPostsQuery, {
    variables: {id: params.influencerId},
  });

  if (loading) return <LoaderDots />;
  if (error) return <div>Unknown error encountered</div>;

  const {instagramPosts, influencer}: {instagramPosts: _Connection; influencer: Influencer} = data;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Definition
          title="Hashtags"
          definition={instagramPosts?.summary?.hashtags?.map((tag: string[]) => (
            <KeyValueText key={tag[0]} name={`#${tag[0]}`} value={tag[1]} big />
          ))}
        />
      </Grid>
      <Grid item container xs={12} alignItems="flex-start" spacing={2}>
        {instagramPosts &&
          instagramPosts.edges
            .map((edge: any) => edge.node)
            .map((post: InstagramPost) => (
              <Grid key={post.id} item xs={6} md={4}>
                <InstagramMedia influencer={influencer} instagramPost={post} />
              </Grid>
            ))}
      </Grid>
      <Grid item xs={12}>
        {instagramPosts && (
          <div className={classes.moreWrapper}>
            <a
              className={classes.viewMore}
              href={`https://www.instagram.com/${influencer.instagramAccount?.username}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View more on Instagram
            </a>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    hashtags: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '12px',
      justifyContent: 'flex-end',
    },
    viewMore: {
      margin: '20px 0',
      color: purple,
    },
    moreWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  })
);

const InstagramPostsQuery = gql`
  query InstagramPostsQuery($id: UUIDString!, $cursor: String) {
    influencer: profile(id: $id) {
      id
      ... on Influencer {
        id
        instagramAccount {
          id
          username
          followers
        }
      }
    }
    instagramPosts: instagramMediaByUsername(id: $id, after: $cursor) {
      edges {
        node {
          id
          caption
          mentions
          hashtags
          media {
            ...mediaResultFields
          }
          shortcode
          comments
          likes
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      summary {
        hashtags
      }
      count
    }
  }
  ${Gig.mediaPreview}
`;

export default withRouter(InstagramPosts);
