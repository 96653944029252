import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {withStyles} from '@material-ui/styles';

import {LoaderDots} from '../../../../../../components/Widgets';

type Funds = {
  currency: string;
  formattedValue: string;
};

const PrimaryCell = withStyles(() =>
  createStyles({
    root: {
      width: '16em',
    },
  })
)(TableCell);

const RecentlyPaidOut: React.FC = () => {
  const [period, setPeriod] = useState('24');
  const {loading, data, error} = useQuery(RecentlyPaidQuery, {
    variables: {
      pastHours: period,
    },
  });

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setPeriod(event.target.value as string);
  };

  const Body = () => {
    if (loading) {
      return <LoaderDots />;
    }

    if (error) {
      return (
        <div style={{display: 'inline-flex'}}>
          <span style={{marginRight: '5px'}}>
            <ErrorIcon color="error" />
          </span>
          <Typography variant="body2">Error loading recently paid out</Typography>
        </div>
      );
    }

    return (
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="Recently paid out">
          <TableBody>
            {data.recentlyPaid.map((funds: Funds, i: number) => (
              <TableRow key={`queuedFunds-row-${i}`}>
                <PrimaryCell key={`queuedFunds-row-cell-${i}-1`}>
                  <Typography variant="h5">{funds.currency}</Typography>
                </PrimaryCell>
                <TableCell key={`queuedFunds-row-cell-${i}-2`} align="right">
                  <Typography variant="body2">{funds.formattedValue}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl variant="outlined">
          <InputLabel id="time-period-label">Time period</InputLabel>
          <Select
            labelId="time-period-label"
            id="time-period"
            value={period}
            label="Time period"
            aria-label="Time period"
            onChange={event => handleChange(event)}
          >
            <MenuItem value={6}>Past 6 hours</MenuItem>
            <MenuItem value={12}>Past 12 hours</MenuItem>
            <MenuItem value={24}>Past 1 day</MenuItem>
            <MenuItem value={48}>Past 2 days</MenuItem>
            <MenuItem value={72}>Past 3 days</MenuItem>
            <MenuItem value={168}>Past 1 week</MenuItem>
            <MenuItem value={336}>Past 2 weeks</MenuItem>
            <MenuItem value={504}>Past 3 weeks</MenuItem>
            <MenuItem value={672}>Past 4 weeks</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Body />
      </Grid>
    </Grid>
  );
};

const RecentlyPaidQuery = gql`
  query RecentlyPaidQuery($pastHours: Int) {
    recentlyPaid(hours: $pastHours) {
      value
      formattedValue
      symbol
      currency
    }
  }
`;

export default RecentlyPaidOut;
