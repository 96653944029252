import React from 'react';
import {Divider, Grid, makeStyles, Typography} from '@material-ui/core';

import PaidAdvertTable from 'scenes/Campaign/components/CampaignPerformance/PaidAdvertData/PaidAdvertTable';
import type {CampaignPaidAdsPerformanceResponse} from 'hooks/useDataServiceApi/types';

type Props = {
  title: string;
  gigs: Gig[];
  adverts: CampaignPaidAdsPerformanceResponse;
};

const PaidAdvertMetrics: React.FC<Props> = ({title, gigs, adverts}) => {
  const classes = useStyles();

  const metrics = gigs
    .filter(gig => adverts.some(metric => metric.gig_id === gig?.id))
    .map(gig => {
      const paidAdvert = adverts.find(metric => metric.gig_id === gig?.id);
      return {...gig, ...paidAdvert};
    }) as Array<CampaignPaidAdsPerformanceResponse[number] & Gig>;

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3}>
        <PaidAdvertTable metrics={metrics} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default PaidAdvertMetrics;
