import React from 'react';
import {gql} from '@apollo/client';
import moment from 'moment';
import {Box, Grid, Typography} from '@material-ui/core';

import {ProfilePicture, RelativeDate, KeyValueText, RequiresPermission} from 'components/Widgets';
import SocialPlatformChip from 'components/Widgets/PlatformLinkedChip';
import PlatformWithLabel from 'components/Widgets/PlatformWithLabel/PlatformWithLabel';
import InfluenscoreChart from 'components/Widgets/InfluenscoreChart/InfluenscoreChart';

import {sanitizeWhitespace, capitalize} from 'consts/utilities';
import {getLocation} from 'consts/influencerHelper';
import {InfluencerState} from 'scenes/Admin/components';
import {Platforms} from 'consts/platforms';
import useResponsive from 'hooks/useResponsive';
import useFeatureFlags from 'hooks/useFeatureFlags';
import {Influencer} from 'services/fragments';
import {InfluencerInfluenscoreData} from 'hooks/useDataServiceApi/types';

import {MANAGE_INFLUENCERS} from '../../../../../consts/permissions';
import {useStyles} from './styles';
import CopyPasteable from '../../../../../components/Widgets/CopyPasteable/CopyPasteable';
import InfluencerInviteToCampaign from '../InfluencerInviteToCampaign';

interface Props {
  influencer: Influencer;
  influenscoreData?: InfluencerInfluenscoreData;
}

const AdminInfluencerProfile: React.FC<Props> = ({influencer, influenscoreData}) => {
  const classes = useStyles();
  const smDown = useResponsive('down', 'sm');
  const {useYoutubeIntegration, useTwitchIntegration} = useFeatureFlags();

  const renderInfluencerState = () => (
    <Grid
      item
      container
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
      style={{paddingRight: '1rem'}}
    >
      <Grid item>
        {influencer.lastActive && (
          <Typography className={classes.lastActive}>
            Last active
            <br />
            <b>
              <RelativeDate date={influencer.lastActive} />
            </b>
          </Typography>
        )}
      </Grid>
      <Grid item>
        <InfluencerState influencer={influencer} />
      </Grid>
    </Grid>
  );

  return (
    <Grid container style={{paddingTop: `${smDown ? '20px' : '0'}`}} direction="row" xs>
      <Grid container item direction="column" spacing={2} xs>
        {/* Profile picture and name */}
        <Grid container item xs spacing={2} direction="row" style={{maxHeight: 'fit-content'}}>
          <Grid item>
            <ProfilePicture src={influencer.profilePicture ?? ''} size={100} />
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '.5rem',
              justifyContent: 'center',
            }}
          >
            <Typography className={classes.influencerName}>
              {sanitizeWhitespace(influencer.fullName ?? '(no name)')}
            </Typography>
            {(influencer.state === 'reviewed' || influencer.state === 'verified') && (
              <InfluencerInviteToCampaign influencer={influencer} />
            )}
          </Grid>
        </Grid>

        {/* Linked social platforms */}
        <Grid container item xs spacing={2} style={{maxHeight: 'fit-content'}}>
          {influencer.instagramAccount?.username && (
            <Grid item>
              <SocialPlatformChip
                platform={Platforms.Instagram}
                handle={influencer.instagramAccount?.username ?? ''}
                isLinked={influencer.instagramAccount?.active ?? false}
              />
            </Grid>
          )}
          {influencer.tiktokAccount?.username && (
            <Grid item>
              <SocialPlatformChip
                platform={Platforms.TikTok}
                handle={influencer.tiktokAccount.username ?? ''}
                isLinked
              />
            </Grid>
          )}
          {useYoutubeIntegration && influencer.youtubeChannel && (
            <Grid item>
              <SocialPlatformChip
                platform={Platforms.YouTube}
                handle={`https://www.youtube.com/${influencer.youtubeChannel.customUrl}` ?? ''}
                isLinked
              />
            </Grid>
          )}
          {useTwitchIntegration && influencer.twitchChannel && (
            <Grid item>
              <SocialPlatformChip
                platform={Platforms.Twitch}
                handle={influencer.twitchChannel.url?.split('/').pop() ?? ''}
                isLinked
              />
            </Grid>
          )}
        </Grid>

        {/* Followers */}
        <Grid item className={classes.influencerNumbers} spacing={2}>
          <Grid item>
            <Typography variant="body2">Followers:</Typography>
            <Box paddingLeft={1}>
              {influencer.instagramAccount && (
                <PlatformWithLabel
                  platform="instagram"
                  label={influencer.instagramAccount.followers?.toLocaleString()}
                />
              )}
              {influencer.tiktokAccount && (
                <PlatformWithLabel
                  platform="tiktok"
                  label={influencer.tiktokAccount.followers?.toLocaleString()}
                />
              )}
              {useYoutubeIntegration && influencer.youtubeChannel && (
                <PlatformWithLabel
                  platform="youtube"
                  label={influencer.youtubeChannel.subscriberCount?.toLocaleString()}
                />
              )}
              {useTwitchIntegration && influencer.twitchChannel && (
                <PlatformWithLabel
                  platform="twitch"
                  label={influencer.twitchChannel.followers?.toLocaleString()}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* InfluenScore */}
      <Grid container item spacing={2} direction="column" xs={6} justifyContent="flex-start">
        <Grid item>{influencer.isSignedUp && renderInfluencerState()}</Grid>
        {influenscoreData && (
          <Grid item style={{alignSelf: 'flex-end'}}>
            <Typography variant="h5" align="center">
              InfluenScore
            </Typography>
            <InfluenscoreChart values={influenscoreData ?? {}} height={250} legend={false} />
          </Grid>
        )}
      </Grid>

      {/* Private profile, not signed up, boosted follower counts labels */}
      <Grid container item xs={12}>
        {/* <Grid item>
          {influencer.isPrivate && (
            <Typography className={classes.privateProfile}>Private Profile</Typography>
          )}
          {influencer.instagramAccount?.boosted && (
            <Typography className={classes.privateProfile}>
              Profile has boosted follower counts for demo purposes
            </Typography>
          )}
          {!influencer.isSignedUp && (
            <Typography className={classes.notSignedUp}>Not Signed Up</Typography>
          )}
        </Grid> */}

        {/* Biography */}
        <Grid container item>
          <Grid item md={8}>
            {influencer.biography ? (
              <Typography className={classes.influencerBio}>
                {sanitizeWhitespace(influencer.biography)}
              </Typography>
            ) : (
              <Typography className={classes.empty}>No biography</Typography>
            )}
          </Grid>
        </Grid>

        {/* Email, gender, age, region, VAT registered */}
        <Grid item container style={{marginTop: 20}}>
          <RequiresPermission permission={MANAGE_INFLUENCERS}>
            <Grid item xs={12}>
              <KeyValueText
                name="Email"
                value={<CopyPasteable>{influencer.email}</CopyPasteable>}
                placeholder="No email"
              />
            </Grid>
          </RequiresPermission>
          {influencer.isSignedUp && (
            <>
              <Grid item xs={12}>
                <KeyValueText name="Gender" value={capitalize(influencer.gender)} />
                <KeyValueText
                  name="Age"
                  value={influencer.birthday ? moment().diff(influencer.birthday, 'years') : null}
                />
                <KeyValueText
                  name="Region"
                  value={
                    influencer.targetRegion &&
                    getLocation(influencer.targetRegion.name, influencer.targetRegion.country)
                  }
                />
              </Grid>
              {influencer.targetRegion?.countryCode === 'GB' && (
                <Grid item>
                  <KeyValueText
                    name="VAT Registered"
                    value={influencer.isVatRegistered ? 'Yes' : 'No'}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AdminInfluencerProfile.fragments = {
  influencer: gql`
    fragment InfluencerProfileFields on InstagramUserInterface {
      __typename
      id
      biography
      email
      engagement {
        value
        formattedValue
      }
      followers
      fullName
      isPrivate
      isSignedUp
      profilePicture
      username
      ... on Influencer {
        isVatRegistered
        hasFacebookPage
        lastActive
        ...socialPlatformsFields
        device {
          id
          deviceModel
        }
        information {
          accountType
        }
        totalRewards {
          formattedValue
        }
        birthday
        gender
        gigInsightsBySocialMediaPlatform {
          socialMediaPlatform
          influencerAccountFollowerAvg
          influencerFollowerEngagementAvg {
            value
            formattedValue
          }
          influencerImpressionEngagementAvg {
            value
            formattedValue
          }
          influencerPostImpressionAvg
          influencerPostInteractionAvg
        }
        interests {
          id
        }
        state
        cooldownEnds
        targetRegion {
          id
          name
          country
          countryCode
        }
        information {
          accountType
          glasses
          eyeColour {
            id
            name
            hex
          }
          hairColour {
            id
            name
            hex
          }
          hairType {
            id
            name
          }
          children {
            id
            birthday
            gender
          }
          languages
          tags {
            id
            name
          }
        }
      }
    }
    ${Influencer.socialPlatformsFragment}
  `,
};

export default AdminInfluencerProfile;
