import React from 'react';
import uri from 'urijs';

import {ImageLoader} from '../';

type ImgixQuery = {
  h?: string;
  w?: string;
  dpr?: string;
  fit?: string;
  auto?: string;
  rect?: string;
  crop?: string;
};

export const constructImgixUrl = (
  src: string,
  width?: number,
  height?: number,
  fit = 'clip',
  dpr = 2,
  rect?: number[]
): string | undefined => {
  if (!src) {
    return undefined;
  }

  const highQuality = width ? width > 100 : true;

  // Query is based on the ImgIX API: https://docs.imgix.com/apis/url
  const query: ImgixQuery = {
    auto: highQuality ? 'format' : 'compress,format',
    dpr: dpr.toString(),
  };
  if (height) {
    query.h = height.toString();
  }
  if (width) {
    query.w = width.toString();
  }
  if (fit) {
    query.fit = fit;
    if (fit === 'crop') {
      query.crop = 'faces';
    }
  }
  if (rect) {
    query.rect = rect.join(',');
  }

  return uri(src)
    .query(query as uri.QueryDataMap)
    .toString();
};

type Props = {
  alt?: string;
  src: string;
  className?: string;
  height: number;
  width: number;
  rect?: number[];
  title?: string;
  fit?: 'crop' | 'fill';
};

const Imgix = ({alt, src, className, width, height, title, fit, rect}: Props): JSX.Element => (
  <ImageLoader
    alt={alt}
    src={constructImgixUrl(src, width, height, fit, 1, rect)}
    srcSet={`
      ${constructImgixUrl(src, width, height, fit, 1, rect)} 1x,
      ${constructImgixUrl(src, width, height, fit, 2, rect)} 2x
    `}
    className={className}
    style={fit === 'crop' ? {width, height} : {}}
    title={title}
  />
);

export default Imgix;
