import React, {useRef} from 'react';
import {gql, useQuery} from '@apollo/client';
import {TextField, makeStyles, Typography, FormLabel} from '@material-ui/core';
import {useFormik, FormikHandlers, FormikHelpers} from 'formik';
import {Autocomplete} from '@material-ui/lab';

import {Button, LoaderDots, Modal, Section} from 'components/Widgets';

import TargetingSection from 'scenes/Campaign/CampaignManageInfluencers/components/TargetingSection';
import {CreateOffer} from 'scenes/Campaign/CampaignManageInfluencers/components';

import {ModalElement} from '../../../../../components/Widgets/Modal';
import {useCreateOfferForm} from '../../../../Campaign/CampaignManageInfluencers/components/CreateOffer';

type Props = {
  influencer: Influencer;
};

type FormValues = {
  campaignId: null | Campaign['id'];
};

type FormProps = Props & {
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<FormValues>['setFieldValue'];
  values: FormValues;
  onSuccess: () => void;
};

const InfluencerInviteToCampaign: React.FC<Props> = ({influencer}) => {
  const modal = useRef<ModalElement>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      campaignId: null,
    },
    onSubmit: () => {
      /* do nothing */
    },
  });

  return (
    <Modal
      ref={modal}
      id="invite-influencer-to-campaign"
      title="Invite to Campaign"
      modalToggler={<Button small text="Invite to Campaign" />}
      fullWidth
      maxWidth="sm"
      showClose
      innerElement={'div'}
      showCancel={formik.values.campaignId == null}
      showSubmit={false}
      onClose={formik.resetForm}
    >
      <InfluencerInviteToCampaignForm
        influencer={influencer}
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        values={formik.values}
        onSuccess={() => {
          modal.current?.close();
          formik.resetForm();
        }}
      />
    </Modal>
  );
};

const InfluencerInviteToCampaignForm: React.FC<FormProps> = ({
  influencer,
  setFieldValue,
  values,
  onSuccess,
}) => {
  const classes = useStyles();
  const {data, loading} = useQuery(InfluencerInviteToCampaignQuery);
  const influencerCampaignResult = useQuery(InfluencerCampaignQuery, {
    skip: values.campaignId == null,
    fetchPolicy: 'network-only',
    variables: {
      id: values.campaignId,
      influencerId: influencer.id,
    },
  });

  const campaignOptions = (
    (data?.campaigns.edges.map(({node: {id, name, advertiser}}: {node: Campaign}) => ({
      id,
      label: `${advertiser?.name} - ${name}`,
    })) || []) as Array<{label: string; id: string}>
  ).sort((a, b) => a.label.localeCompare(b.label));

  const isInfluencerAlreadyInSelectedCampaign = [
    ...(influencerCampaignResult.data?.influencer?.invitedCampaignIds || []),
    ...(influencerCampaignResult.data?.influencer?.participatingCampaignIds || []),
  ].includes(values.campaignId);

  const createOfferForm = useCreateOfferForm({
    campaign: influencerCampaignResult.data?.campaign,
    suggestedReward: influencerCampaignResult.data?.suggestedReward,
    influencerId: influencer.id,
    onSuccess: () => {
      influencerCampaignResult.refetch();
      onSuccess();
    },
  });

  return (
    <>
      <FormLabel htmlFor="campaignId">
        <Typography variant="body2">Campaign</Typography>
      </FormLabel>
      <Autocomplete
        id="campaignId"
        openOnFocus
        loading={loading}
        loadingText={<LoaderDots />}
        options={campaignOptions}
        getOptionLabel={({label}) => label}
        getOptionSelected={(option, value) => option.id === value.id}
        value={campaignOptions.find(({id}) => id === values.campaignId) ?? null}
        disableClearable={true}
        classes={{
          root: classes.autocomplete,
          paper: classes.paper,
          listbox: classes.listbox,
        }}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              'aria-label': 'Search for an active campaign...',
            }}
            placeholder="Select an active campaign..."
            variant="outlined"
          />
        )}
        onChange={(_, {id: campaignId}) => setFieldValue('campaignId', campaignId)}
      />

      {(() => {
        if (values.campaignId == null) return null;

        if (influencerCampaignResult.loading) return <LoaderDots />;

        if (isInfluencerAlreadyInSelectedCampaign) {
          return (
            <Typography className={classes.message}>
              This influencer has already been added to this campaign.
            </Typography>
          );
        }

        if (influencerCampaignResult.error)
          return (
            <Typography className={classes.message}>
              There was an error loading the campaign details. Please try again.
            </Typography>
          );

        if (influencerCampaignResult.data.campaign.isFullyReserved) {
          return (
            <Typography className={classes.message}>
              This campaign is already fully reserved.
            </Typography>
          );
        }

        return (
          <>
            <TargetingSection targeting={influencerCampaignResult.data.targeting} />

            <Section title="Custom offer">
              <CreateOffer
                rightAlignSubmit={true}
                campaign={influencerCampaignResult.data.campaign}
                {...createOfferForm}
              />
            </Section>
          </>
        );
      })()}
    </>
  );
};

const InfluencerInviteToCampaignQuery = gql`
  query InfluencerInviteToCampaignQuery {
    campaigns(state: "launched", allResults: true) {
      edges {
        node {
          id
          name
          advertiser {
            id
            name
          }
        }
      }
    }
  }
`;

const InfluencerCampaignQuery = gql`
  query InfluencerCampaignQuery($id: UUIDString!, $influencerId: UUIDString!) {
    campaign(id: $id) {
      id
      name
      advertiser {
        id
        name
      }
      posts {
        id
        postType
        opened
        mention
        hashtags
        startFirstHashtag
        submissionDeadline
        instructions
        deadline
        requiresReviewBeforePosting
      }
      marketSlug
      isFullyReserved
      paymentTerms
    }
    suggestedReward: getInfluencerRewardSuggestionForCampaign(
      campaignId: $id
      influencerId: $influencerId
    ) {
      value
      formattedValue
    }
    targeting: campaignInfluncerTargeting(campaignId: $id, influencerId: $influencerId)
    influencer(id: $influencerId) {
      id
      invitedCampaignIds
      participatingCampaignIds
    }
  }
`;

const useStyles = makeStyles({
  autocomplete: {
    marginBottom: '25px',
  },
  paper: {
    height: '200px',
  },
  listbox: {
    maxHeight: '100%',
  },
  message: {
    marginTop: '12px',
    textAlign: 'center',
  },
});

export default InfluencerInviteToCampaign;
