import {createStyles, makeStyles} from '@material-ui/core/styles';

import {mainContentWidth} from '../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
      paddingTop: '40px',
    },
    title: {
      paddingBottom: '24px',
    },
    loaderWrapper: {
      height: 450,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    campaignsSwitch: {
      paddingTop: '24px',
    },
  })
);
