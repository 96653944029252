import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Form, FormFileInput, FormSubmitButton} from 'components/Widgets/Forms';

import {uploadMedia} from 'services/api';

import styles from './style.css';

class UploadMedia extends Component {
  static propTypes = {
    onUploadSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    progress: null,
  };

  toggleModal = () => {
    const {modalOpen} = this.state;
    this.setState({modalOpen: !modalOpen});
  };

  uploadFile = async formData => {
    const onProgress = e => {
      this.setState({progress: e});
    };

    const response = await uploadMedia(formData, onProgress);
    this.props.onUploadSuccess(response.data.urls[0]);
  };

  render() {
    const {progress} = this.state;

    return (
      <div>
        <Form encType="multipart/form-data" onSubmit={this.uploadFile}>
          <FormFileInput required />
          <div className={styles.progressBar}>
            <div
              className={styles.bar}
              style={{width: progress ? `${(progress.loaded / progress.total) * 100}%` : 0}}
            />
          </div>
          <FormSubmitButton text="Upload" block />
        </Form>
      </div>
    );
  }
}

export default UploadMedia;
