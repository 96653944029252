import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import classNames from 'classnames';
import {graphql} from '@apollo/client/react/hoc';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import * as qs from 'query-string';
import {FormControl, FormLabel, Typography, Grid, Button, Tooltip} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {Archive, Delete, Lock} from '@material-ui/icons';
import {isNaN} from 'lodash';
import AddIcon from '@material-ui/icons/Add';

import Prompts from './components/Prompts';
import {UserBox, ExampleReward, CampaignHeader} from '..';
import {ICampaignSetupProps, ICampaignSetupState, CampaignKpiMetric} from './types';
import {isRequired, isNumber, inRange} from '../../../../services/validators';
import uniqueId from '../../../../services/uniqueId';
import App404 from '../../../App404/App404';
import {capitalize, currencyForMarket} from '../../../../consts/utilities';
import {AdvertiserIndustryQuery} from '../../../Advertiser/AdvertiserSettings/graphqlQuery';
import {HideInDemoMode, LoaderDots} from '../../../../components/Widgets';
import {
  Form,
  FormImageUploader,
  FormRadio,
  FormSubmitButton,
} from '../../../../components/Widgets/Forms';
import {ControlFormInput} from './components/FormInput';
import {ControlCheckbox, CustomCheckbox} from './components/FormCheckbox';
import {useDefaultGlobalStyles} from '../../../App/App/style.integration';
import {ControlSelect} from './components/FormSelect';
import Section from './components/Section';
import {customStyles} from './styles';
import {ForceArchiveCampaignMutation, CampaignManagersQuery, PaymentTerms} from './graphqlQueries';
import {MARKETS, REWARD_MODELS} from '../../../../consts/variables';
import {Match, NewLock, Umbrella} from '../../../../components/Icons';
import Shipping from '../../../../components/Icons/Shipping';
import ProBono from '../../../../components/Icons/ProBono';
import Insights from '../../../../components/Icons/Insights';
import {ControlDialog} from '../../../../components/Widgets/ControlDialog';
import SetOffPlatform from './components/SetOffPlatformDialog/SetOffPlatformDialog';
import {
  CAMPAIGN_STATE_COMPLETED,
  CAMPAIGN_STATE_LAUNCHED,
  CAMPAIGN_STATE_NEW,
  CAMPAIGN_STATE_STASHED,
  DEFAULT_PAYMENT_TERMS,
} from './constants';
import {parseDealId} from '../../../../utils/campaignUtils';
import styles from './style.css';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import {DATA_SERVICE_MODEL_INFO, performanceSettingsValues} from '../CampaignPerformance/helper';

const ONE_MILLION = 1000000;
const ONE_HUNDRED = 100;
const MAX_PRICE = ONE_MILLION * ONE_MILLION;
const MAX_KPI_VALUE = ONE_MILLION * ONE_MILLION;

const campaignKpiMetricValues = Object.values(CampaignKpiMetric);

class CampaignSetup extends PureComponent<ICampaignSetupProps, ICampaignSetupState> {
  constructor(props: ICampaignSetupProps) {
    super(props);

    this.state = {
      shippingId: uniqueId(),
      hasNdaId: uniqueId(),
      brandSafetyId: uniqueId(),
      brandMatchId: uniqueId(),
      proBonoId: uniqueId(),
      withPremiumPriceId: uniqueId(),
      requireInsightId: uniqueId(),
      openArchiveModal: false,
      paymentTerms: DEFAULT_PAYMENT_TERMS,
      target: {
        targetInstagram: 0,
        targetTiktok: 0,
        targetYoutube: 0,
        targetTwitch: 0,
        targetContent: 0,
        targetOverall: 0,
      },
      organicPerformanceMetrics: undefined,
      paidPerformanceMetrics: undefined,
      campaignKpis: undefined,
      cpmBenchmark: undefined,
    };
  }

  isCampaignNew = () => this.props.campaign?.state === CAMPAIGN_STATE_NEW;

  isCampaignLaunched = () => this.props.campaign?.state === CAMPAIGN_STATE_LAUNCHED;

  isCampaignFinished = () =>
    this.props.campaign?.state === CAMPAIGN_STATE_COMPLETED ||
    this.props.campaign?.state === CAMPAIGN_STATE_STASHED;

  isCampaignLocked = () =>
    this.props.campaign?.state === CAMPAIGN_STATE_LAUNCHED || this.isCampaignFinished();

  setOwner = (userId: string) => {
    this.setState({
      owner: this.props.owners.edges.find(edge => edge.node.id === userId).node,
    });
  };

  setPaymentTerms = (value: string) => {
    this.setState({
      paymentTerms: value,
    });
  };

  setCampaignManager = (userId: string) => {
    this.setState({
      campaignManager: this.props.campaignManagers.edges.find(edge => edge.node.id === userId).node,
    });
  };

  setSecondaryCampaignManager = (userId: string) => {
    this.setState({
      secondaryCampaignManager: this.props.campaignManagers.edges.find(
        edge => edge.node.id === userId
      ).node,
    });
  };

  onPriceChange = (value: string) => {
    this.setState({
      price: {
        value: parseInt(value),
      },
    });
  };

  updateDealIdState = (value: string) => {
    this.setState({hubspotDealId: value});
  };

  /**
   * This is required as we want to use the `campaign` data as the initial state
   * when editing. However we cannot set this in the constructor as this component
   * may have already rendered before the data is loaded.
   *
   * We therefore have the internal state as undefined, and copy the campaign state
   * to it on change.
   */
  getPerformanceMetricsSourceData = (type: keyof typeof performanceSettingsValues): string[] => {
    const key = type === 'organic' ? 'organicPerformanceMetrics' : 'paidPerformanceMetrics';
    const stateData = this.state[key];
    return stateData == null ? (this.props.campaign[key] as string[]) : stateData;
  };

  isPerformanceMetricEnabledForCampaign = (
    type: keyof typeof performanceSettingsValues,
    metric:
      | (typeof performanceSettingsValues)['organic'][number]
      | (typeof performanceSettingsValues)['paid'][number]
  ): boolean => {
    const source = this.getPerformanceMetricsSourceData(type);

    if (source == null || (Array.isArray(source) && source.length === 0)) return true;
    return source.includes(metric);
  };

  isPerformanceMetricChangeDisabled = (
    type: keyof typeof performanceSettingsValues,
    metric:
      | (typeof performanceSettingsValues)['organic'][number]
      | (typeof performanceSettingsValues)['paid'][number]
  ) => {
    const source = this.getPerformanceMetricsSourceData(type);

    /**
     * An empty array = display all metrics = all boxes checked. We therefore disable unchecking
     * the last checkbox to avoid the UI looking strange when unchecking the last checkbox causes
     * all unchecked checkboxes to be rechecked.
     */
    if (Array.isArray(source) && source.length === 1 && source.includes(metric)) return true;
    return false;
  };

  onPerformanceMetricsChange = (
    type: keyof typeof performanceSettingsValues,
    metric:
      | (typeof performanceSettingsValues)['organic'][number]
      | (typeof performanceSettingsValues)['paid'][number],
    checked: boolean
  ): void => {
    const stateKey = type === 'organic' ? 'organicPerformanceMetrics' : 'paidPerformanceMetrics';
    const source = this.getPerformanceMetricsSourceData(type);
    const options = [...performanceSettingsValues[type]];

    /**
     * If these settings have never been set before, they will be an empty array
     * (which means they're all enabled). Therefore when unchecking one item, we
     * now need to add all the other items into the array.
     */
    if (!checked && !source?.length)
      return this.setState(state => ({
        ...state,
        [stateKey]: options.filter(value => value !== metric),
      }));

    if (checked) return this.setState(state => ({...state, [stateKey]: [...source, metric]}));

    return this.setState(state => ({
      ...state,
      [stateKey]: source.filter(value => value !== metric),
    }));
  };

  addCampaignKpi = (): void => {
    this.setState(state => ({
      ...state,
      campaignKpis: [
        ...(state.campaignKpis ?? []),
        {
          metric: CampaignKpiMetric.impressions,
          value: 0,
        },
      ],
    }));
  };

  removeCampaignKpi = (i: number): void => {
    this.setState(state => ({
      ...state,
      campaignKpis: state.campaignKpis?.filter((_, index) => index !== i),
    }));
  };

  setCampaignKpiValue = (i: number, metric: CampaignKpiMetric, value: number): void => {
    this.setState(state => ({
      ...state,
      campaignKpis: state.campaignKpis?.map((entry, index) => {
        if (index === i) {
          return {metric, value};
        }
        return entry;
      }),
    }));
  };

  onFormChange = (formData: ICampaignSetupState) => {
    this.setState({
      shippingRequired: formData.shippingRequired,
      brandMatch: formData.brandMatch,
      proBono: formData.proBono,
      rewardModel: formData.rewardModel,
      marketSlug: formData.marketSlug,
      customRewardUnits: formData.customRewardUnits,
      withPremiumPrice: formData.withPremiumPrice,
      hasCustomReward: formData.hasCustomReward,
      listPrice: {
        value: formData.listPrice,
      },
      cpmBenchmark: formData.cpmBenchmark,
      price: {
        value: formData.withPremiumPrice
          ? this.state.price
            ? this.state.price.value
            : formData.price
          : formData.listPrice,
      },
      target: {
        targetInstagram: formData.targetInstagram > 0 ? formData.targetInstagram : 0,
        targetTiktok: formData.targetTiktok > 0 ? formData.targetTiktok : 0,
        targetYoutube: formData.targetYoutube > 0 ? formData.targetYoutube : 0,
        targetTwitch: formData.targetTwitch > 0 ? formData.targetTwitch : 0,
        targetContent: formData.targetContent > 0 ? formData.targetContent : 0,
        targetOverall: formData.targetOverall > 0 ? formData.targetOverall : 0,
      },
    });
  };

  onFormSubmit = (formData: ICampaignSetupState) => {
    const {photos, ...data} = formData;

    data.prompts = (this.state.prompts || this.props.campaign.prompts).map(p =>
      Object.assign({}, p)
    );

    data.prompts.forEach((p: any) => {
      delete p.__typename;
    });

    if (photos) {
      data.pictures = photos.photos.map((x: any) => ({url: x.link}));

      if (photos.coverPhoto) {
        data.pictures.unshift({url: photos.coverPhoto});
      }
    }

    data.price = formData.withPremiumPrice
      ? this.state.price
        ? this.state.price.value
        : formData.price
      : formData.listPrice;

    if (!formData.hasCustomReward) {
      data.customRewardUnits = -1;
    }

    // this will look better when we dont have to support both legacy and new model
    delete data.target;
    delete data.targetOverall;

    data.targetInstagram = this.state.target.targetInstagram;
    data.targetTiktok = this.state.target.targetTiktok;
    if (this.props.useYoutubeIntegration) {
      data.targetYoutube = this.state.target.targetYoutube;
    }
    if (this.props.useTwitchIntegration) {
      data.targetTwitch = this.state.target.targetTwitch;
    }
    data.targetContent = this.state.target.targetContent;

    data.organicPerformanceMetrics = this.state.organicPerformanceMetrics;
    data.paidPerformanceMetrics = this.state.paidPerformanceMetrics;
    data.campaignKpis = this.state.campaignKpis;

    return this.props.updateCampaign(data);
  };

  handleEnterKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  UNSAFE_componentWillReceiveProps(props: ICampaignSetupProps) {
    if (props.campaign) {
      this.setState({
        campaignKpis: props.campaign.campaignKpis?.map((entry: CampaignKpi) => ({
          metric: entry.metric,
          value: entry.value,
        })),
      });
    }
  }

  render() {
    const {loading, campaign, campaignManagers, owners, advertiserIndustries, classes} = this.props;

    const toSelectOptions = (users: any) =>
      users.edges.map((edge: any) => edge.node).map(u => ({name: u.fullName, value: u.id}));

    const getFormValue = (key: string) => this.state[key] ?? campaign[key];

    if (loading) {
      return <LoaderDots />;
    }

    if (!campaign) {
      return <App404 />;
    }

    const rewardModel = getFormValue('rewardModel');
    const prompts = JSON.parse(JSON.stringify(getFormValue('prompts')));
    prompts.forEach((q: Record<string, any>) => delete q.__typename);

    const owner = getFormValue('owner');
    const price = getFormValue('price').value;
    const photos = getFormValue('photos');
    const listPrice = getFormValue('listPrice').value;
    const marketSlug = getFormValue('marketSlug');
    const isBrandMatch = getFormValue('brandMatch');
    const hasCustomReward = getFormValue('hasCustomReward');
    const shippingRequired = getFormValue('shippingRequired');
    const withPremiumPrice =
      this.state.withPremiumPrice === undefined ? price !== listPrice : this.state.withPremiumPrice;

    const targetOverall = this.state.target.targetOverall;
    const cpmBenchmark = getFormValue('cpmBenchmark').value;

    const initialState = {
      ...campaign,
      price,
      listPrice,
      withPremiumPrice,
      photos,
      brandSafety: true,
      cpmBenchmark,
      paymentTerms: campaign.paymentTerms ?? DEFAULT_PAYMENT_TERMS,
      targetInstagram: campaign.target?.targetInstagram ?? 0,
      targetTiktok: campaign.target?.targetTiktok ?? 0,
      targetYoutube: campaign.target?.targetYoutube ?? 0,
      targetTwitch: campaign.target?.targetTwitch ?? 0,
      targetContent: campaign.target?.targetContent ?? 0,
      targetOverall: campaign.target?.targetOverall ?? 0,
      owner: campaign.owner?.id ?? undefined,
      campaignManager: campaign.campaignManager?.id ?? undefined,
      secondaryCampaignManager: campaign.secondaryCampaignManager?.id ?? undefined,
    };

    const defaultOptions = [{name: 'No industries', value: 'No industry'}];
    const industryOptions = (advertiserIndustries ?? []).map(item => ({
      name: item.title,
      value: item.title,
    }));
    const options = defaultOptions.concat(industryOptions);

    const isCampaignKpiMetricUnique =
      (i: number) =>
      (metric: CampaignKpiMetric): boolean => {
        if (i === 0) return true;
        return (
          this.state.campaignKpis?.slice(0, i).every(entry => entry.metric !== metric) ?? false
        );
      };

    return (
      <div className={`${styles.root} ${!campaign?.id ? styles.containerMargin : ''}`}>
        <CampaignHeader title="Setup" campaignId={campaign.id} />
        <Form initialState={initialState} onChange={this.onFormChange} onSubmit={this.onFormSubmit}>
          <Section
            title="Market:"
            details={MARKETS.filter((market: any) => market.value === campaign.marketSlug)[0]}
            first
            disabled
            disabledReason="Cannot be changed after the campaign has been created"
          />

          <Section
            title="Model:"
            details={
              REWARD_MODELS.filter((market: any) => market.value === campaign.rewardModel)[0]
            }
            customClassName={classes.customSection}
            first
            disabled
            disabledReason="Cannot be changed after the campaign has been created"
          />

          <Section
            title="Campaign Handling"
            disabled={this.isCampaignNew() || this.isCampaignFinished()}
            disabledReason={
              this.isCampaignNew() ? 'Cannot be changed until the campaign has been created' : ''
            }
          >
            <SetOffPlatform
              campaign={campaign}
              disabled={this.isCampaignNew() || this.isCampaignFinished()}
            />
          </Section>

          <Section title="Price" disabled={this.isCampaignLocked()}>
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="list-price-label" htmlFor="list-price-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      List price
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    type="number"
                    name="listPrice"
                    prefix={currencyForMarket(marketSlug)}
                    id="list-price-input"
                    ariaLabelledby="list-price-label"
                    validators={{
                      isRequired,
                      isNumber,
                      inRange: inRange(0, MAX_PRICE),
                    }}
                    messages={{
                      isRequired: 'List price is required',
                      isNumber: 'Must be a valid number',
                      inRange: `The list price must be between 0 and ${MAX_PRICE}`,
                    }}
                    description="Price of the campaign according to the rate card"
                    parseValue={(value: string) => parseInt(value)}
                    disabled={this.isCampaignLocked()}
                  />
                </FormControl>

                {this.props.showCampaignSoldForDifferentPriceThenRateCardOption && (
                  <ControlCheckbox
                    id={this.state.withPremiumPriceId}
                    roman
                    name="withPremiumPrice"
                    label="Campaign was sold for a different price than on the rate card"
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                {withPremiumPrice && (
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id="price-label" htmlFor="price-input">
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        Price
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name="price"
                      prefix={currencyForMarket(marketSlug)}
                      id="price-input"
                      ariaLabelledby="price-label"
                      validators={{
                        isRequired,
                        isNumber,
                        inRange: inRange(0, MAX_PRICE),
                      }}
                      messages={{
                        isRequired: 'Price is required',
                        isNumber: 'Must be a valid number',
                        inRange: `The price must be between 0 and ${MAX_PRICE}`,
                      }}
                      description="Price that the client paid for the campaign. This amount is displayed on the campaign report."
                      value={price}
                      parseValue={(value: string) => parseInt(value)}
                      disabled={this.isCampaignLocked()}
                      onChange={this.onPriceChange}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Section>

          <Section title="Goal" disabled={this.isCampaignFinished()}>
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item xs={4}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="target-instagram-label" htmlFor="target-instagram-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      {`Instagram ${rewardModel === 'assets' ? 'creator count' : 'follower reach'}`}
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    type="number"
                    name="targetInstagram"
                    ariaLabelledby="target-instagram-label"
                    id="target-instagram-input"
                    placeholder={
                      rewardModel === 'assets'
                        ? 'Enter the total number of creators'
                        : 'Enter the total follower reach'
                    }
                    validators={{
                      inRange: (val: number) =>
                        inRange(
                          0,
                          rewardModel === 'assets' ? 1000 : 100000000
                        )(isNaN(val) ? 0 : val),
                    }}
                    messages={{
                      inRange: `${
                        rewardModel === 'assets'
                          ? '1,000 is the maximum creator count'
                          : 'Accepted range is 0 - 100,000,000 for follower reach'
                      }`,
                    }}
                    parseValue={(value: string) => parseInt(value)}
                    disabled={this.isCampaignFinished()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="target-tiktok-label" htmlFor="target-tiktok-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      {`TikTok ${rewardModel === 'assets' ? 'creator count' : 'follower reach'}`}
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    type="number"
                    name="targetTiktok"
                    ariaLabelledby="target-tiktok-label"
                    id="target-tiktok-input"
                    placeholder={
                      rewardModel === 'assets'
                        ? 'Enter the total number of creators'
                        : 'Enter the total follower reach'
                    }
                    validators={{
                      inRange: (val: number) =>
                        inRange(
                          0,
                          rewardModel === 'assets' ? 1000 : 100000000
                        )(isNaN(val) ? 0 : val),
                    }}
                    messages={{
                      inRange: `${
                        rewardModel === 'assets'
                          ? '1,000 is the maximum creator count'
                          : 'Accepted range is 0 - 100,000,000 for follower reach'
                      }`,
                    }}
                    parseValue={(value: string) => parseInt(value)}
                    disabled={this.isCampaignFinished()}
                  />
                </FormControl>
              </Grid>
              {this.props.useYoutubeIntegration && (
                <Grid item xs={4}>
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id="target-youtube-label" htmlFor="target-youtube-input">
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        {`YouTube ${
                          rewardModel === 'assets' ? 'creator count' : 'subscriber reach'
                        }`}
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name="targetYoutube"
                      ariaLabelledby="target-youtube-label"
                      id="target-youtube-input"
                      placeholder={
                        rewardModel === 'assets'
                          ? 'Enter the total number of creators'
                          : 'Enter the total subscriber reach'
                      }
                      validators={{
                        inRange: (val: number) =>
                          inRange(
                            0,
                            rewardModel === 'assets' ? 1000 : 100000000
                          )(isNaN(val) ? 0 : val),
                      }}
                      messages={{
                        inRange: `${
                          rewardModel === 'assets'
                            ? '1,000 is the maximum creator count'
                            : 'Accepted range is 0 - 100,000,000 for subscribers reach'
                        }`,
                      }}
                      parseValue={(value: string) => parseInt(value)}
                      disabled={this.isCampaignFinished()}
                    />
                  </FormControl>
                </Grid>
              )}
              {this.props.useTwitchIntegration && (
                <Grid item xs={4}>
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id="target-twitch-label" htmlFor="target-twitch-input">
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        {`Twitch ${rewardModel === 'assets' ? 'creator count' : 'follower reach'}`}
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name="targetTwitch"
                      ariaLabelledby="target-twitch-label"
                      id="target-twitch-input"
                      placeholder={
                        rewardModel === 'assets'
                          ? 'Enter the total number of creators'
                          : 'Enter the total follower reach'
                      }
                      validators={{
                        inRange: (val: number) =>
                          inRange(
                            0,
                            rewardModel === 'assets' ? 1000 : 100000000
                          )(isNaN(val) ? 0 : val),
                      }}
                      messages={{
                        inRange: `${
                          rewardModel === 'assets'
                            ? '1,000 is the maximum creator count'
                            : 'Accepted range is 0 - 100,000,000 for follower reach'
                        }`,
                      }}
                      parseValue={(value: string) => parseInt(value)}
                      disabled={this.isCampaignFinished()}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="target-content-label" htmlFor="target-content-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Content-only creator count
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    type="number"
                    name="targetContent"
                    ariaLabelledby="target-content-label"
                    id="target-content-input"
                    placeholder="Enter the total number of creators"
                    validators={{
                      inRange: (val: number) => inRange(0, 1000)(isNaN(val) ? 0 : val),
                    }}
                    messages={{
                      inRange: '1,000 is the maximum creator count',
                    }}
                    parseValue={(value: string) => parseInt(value)}
                    disabled={this.isCampaignFinished()}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                {/* LEGACY - DEPRECATED MODEL */}
                {rewardModel === 'engagement' && (
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id="engagements-label" htmlFor="engagements-input">
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        Number of engagements
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name="targetOverall"
                      ariaLabelledby="engagements-label"
                      id="engagements-input"
                      placeholder="Enter the total engagements"
                      disabled
                    />
                  </FormControl>
                )}
                {/* LEGACY - DEPRECATED MODEL */}
                {rewardModel === 'impressions' && (
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id="impressions-label" htmlFor="impressions-input">
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        Number of impressions
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name="targetOverall"
                      ariaLabelledby="impressions-label"
                      id="impressions-input"
                      placeholder="Enter the total impressions"
                      disabled
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Section>

          <HideInDemoMode>
            <Section title="Rewards" disabled={this.isCampaignLocked()}>
              <fieldset className={classes.rewardFieldset}>
                <legend className={classes.rewardLegend}>Reward scheme</legend>
                <Grid container style={{marginTop: 22}}>
                  <Grid item xs={6}>
                    <FormRadio
                      value={false}
                      name="hasCustomReward"
                      label="Use recommended rewards"
                      description="Rewards are calculated based on the platform margin"
                      radioButtonContainerClassName={classes.radioButtonContainer}
                      radioButtonClassName={classes.radioButton}
                      checkedRadioButtonClassName={classes.checkedRadioButton}
                      labelClassName={classes.label}
                      descriptionClassName={classes.description}
                      disabled={this.isCampaignLocked()}
                      onKeyDown={this.handleEnterKeyDown}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormRadio
                      value={true}
                      name="hasCustomReward"
                      label="Use custom rewards"
                      description=" "
                      radioButtonContainerClassName={classes.radioButtonContainer}
                      radioButtonClassName={classes.radioButton}
                      checkedRadioButtonClassName={classes.checkedRadioButton}
                      labelClassName={classes.label}
                      descriptionClassName={classes.description}
                      disabled={this.isCampaignLocked()}
                      onKeyDown={this.handleEnterKeyDown}
                    />
                  </Grid>
                </Grid>
              </fieldset>

              {hasCustomReward && (
                <Grid container style={{marginTop: 17}}>
                  <Grid item xs={6}>
                    <FormControl style={{width: '100%'}}>
                      <FormLabel id="custom-reward-label" htmlFor="custom-reward-input">
                        <Typography
                          variant="body2"
                          className={classNames(classes.body2SemiBold, classes.inputLabel)}
                        >
                          {rewardModel === 'assets'
                            ? 'Reward per influencer'
                            : 'Reward per 1000 followers'}
                        </Typography>
                      </FormLabel>
                      <ControlFormInput
                        type="number"
                        name="customRewardUnits"
                        id="custom-reward-input"
                        ariaLabelledby="custom-reward-label"
                        prefix={currencyForMarket(marketSlug)}
                        validators={{
                          isNumber,
                        }}
                        messages={{
                          isNumber: 'Must be a valid number',
                        }}
                        formatValue={(reward: number) => parseInt(reward / ONE_HUNDRED)}
                        parseValue={(reward: number) => parseInt(reward * ONE_HUNDRED)}
                        disabled={this.isCampaignLocked()}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {!hasCustomReward && (
                <>
                  <Typography
                    variant="body2"
                    className={classes.body2SemiBold}
                    style={{marginBottom: 7, marginTop: 17}}
                  >
                    Example rewards
                  </Typography>
                  <ExampleReward
                    definitionClassName={classes.definitionClassName}
                    definitionTitleClassName={classes.definitionTitleClassName}
                    campaignId={campaign.id}
                    marketSlug={marketSlug}
                    listPrice={parseInt(listPrice)}
                    rewardModel={rewardModel}
                    shippingRequired={shippingRequired}
                    targetInstagram={this.state.target.targetInstagram}
                    targetTiktok={this.state.target.targetTiktok}
                    targetYoutube={this.state.target.targetYoutube}
                    targetTwitch={this.state.target.targetTwitch}
                    targetContent={this.state.target.targetContent}
                  />
                </>
              )}
            </Section>
          </HideInDemoMode>

          <Grid container direction="column">
            <Grid item xs={4}>
              <fieldset className={classes.rewardFieldset}>
                <legend className={classes.rewardLegend}>Payment Terms</legend>
                <ControlSelect
                  id="paymentTerms-select"
                  name="paymentTerms"
                  canBeEmpty={false}
                  value={this.state.paymentTerms}
                  options={this.props.paymentTerms?.map((term: {name: string}) => ({
                    name: term.name.replace('_', ' '),
                    value: term.name,
                  }))}
                  disabled={this.isCampaignLocked()}
                  disabledReason="Cannot be changed after the campaign has been launched"
                  onChange={this.setPaymentTerms}
                >
                  {owner && <UserBox user={owner} />}
                </ControlSelect>
              </fieldset>
            </Grid>
            {!this.isCampaignLocked() && (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Lock className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Payment terms can only be changed whilst in draft mode, before campaign launch.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Section title="Add-ons" disabled={this.isCampaignLocked()}>
            <Grid container className={classes.gridContainer}>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.brandSafetyId}
                    icon={<Umbrella ariaLabel="Brand safety campaign" />}
                    name="brandSafety"
                    label={`Brand safety (should always be required) ${
                      campaign.extendedReview ? '(with extended review)' : ''
                    }`}
                    description="The brand has to approve content before it goes live."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.brandMatchId}
                    icon={<Match ariaLabel="Brand match campaign" />}
                    name="brandMatch"
                    label="Brand match"
                    description="The brand has to approve approve influencers before they are accepted to the campaign."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridContainer}>
                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.shippingId}
                    icon={<Shipping ariaLabel="Shipping campaign" />}
                    name="shippingRequired"
                    label="Shipping"
                    description="Client delivers product to TAKUMI who then ship the product to influencers. The Takumi app will prompt the influencer to enter their shipping address when they reserve."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.proBonoId}
                    icon={<ProBono ariaLabel="Pro Bono campaign" />}
                    name="proBono"
                    label="Pro Bono"
                    description="Whether this campaign is pro bono. Setting this true allows you to launch a campaign for 0 price and 0 reward. Influencer will not be able to claim any rewards in this campaign."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.gridContainer}>
                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.requireInsightId}
                    icon={<Insights ariaLabel="Insights campaign" />}
                    name="requireInsights"
                    label="Require insights"
                    description="Influencers with deliverables for Instagram or Tiktok, will be required to submit an insights screenshot for that deliverable, through the app before they can claim their reward."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlCheckbox
                    id={this.state.hasNdaId}
                    icon={<NewLock ariaLabel="Campaign under NDA" />}
                    name="hasNda"
                    label="NDA"
                    description="Campaign is covered by a non-disclosure agreement."
                    disabled={this.isCampaignLocked()}
                    onKeyDown={this.handleEnterKeyDown}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Section>

          <Section title="TAKUMI Influencer App" disabled={this.isCampaignFinished()}>
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="name-label" htmlFor="name-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Title
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    name="name"
                    id="name-input"
                    ariaLabelledby="name-label"
                    disabled={this.isCampaignFinished()}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormImageUploader name="photos" disabled={this.isCampaignFinished()} />
              </Grid>
            </Grid>
          </Section>

          <Section title="Influencer prompts" disabled={this.isCampaignFinished()}>
            <div className={classes.gridContainer}>
              <Prompts
                prompts={prompts}
                isBrandMatch={isBrandMatch}
                campaignState={campaign.state ?? ''}
                disabled={this.isCampaignFinished()}
                onSetPrompts={prompts => {
                  this.setState({prompts});
                }}
              />
            </div>
          </Section>

          <Section title="People">
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="owner-label" htmlFor="owner-select">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Owner
                    </Typography>
                  </FormLabel>
                  {owners && (
                    <ControlSelect
                      id="owner-select"
                      ariaLabelledby="owner-label"
                      name="owner"
                      canBeEmpty={true}
                      defaultOption="Select option"
                      options={
                        owner
                          ? toSelectOptions(owners).concat({
                              name: owner.fullName,
                              value: owner.id,
                            })
                          : toSelectOptions(owners)
                      }
                      onChange={(userId: string) => this.setOwner(userId)}
                    >
                      {owner && <UserBox user={owner} />}
                    </ControlSelect>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="campaign-manager-label" htmlFor="campaign-manager-select">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Campaign Manager
                    </Typography>
                  </FormLabel>
                  {campaignManagers && (
                    <ControlSelect
                      id="campaign-manager-select"
                      ariaLabelledby="campaign-manager-label"
                      name="campaignManager"
                      canBeEmpty={true}
                      defaultOption="Select option"
                      options={toSelectOptions(campaignManagers)}
                      onChange={(userId: string) => this.setCampaignManager(userId)}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="industry-label" htmlFor="industry-select">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Industry
                    </Typography>
                  </FormLabel>
                  <ControlSelect
                    id="industry-select"
                    ariaLabelledby="industry-label"
                    name="industry"
                    label="Industry"
                    canBeEmpty={true}
                    defaultOption="Select an industry"
                    options={options}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel
                    id="secondary-campaign-manager-label"
                    htmlFor="campaign-manager-select"
                  >
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Secondary Campaign Manager
                    </Typography>
                  </FormLabel>
                  {campaignManagers && (
                    <ControlSelect
                      id="secondary-campaign-manager-select"
                      ariaLabelledby="secondary-campaign-manager-label"
                      name="secondaryCampaignManager"
                      canBeEmpty={true}
                      defaultOption="Select option"
                      options={toSelectOptions(campaignManagers)}
                      onChange={(userId: string) => this.setSecondaryCampaignManager(userId)}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="hubspot-deal-id-label" htmlFor="hubspot-deal-id-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Hubspot deal ID
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    name="hubspotDealId"
                    id="hubspot-deal-id-input"
                    ariaLabelledby="hubspot-deal-id-label"
                    placeholder="Enter the Hubspot deal ID or URL"
                    validators={{isRequired}}
                    messages={{
                      isRequired: 'Hubspot Deal ID is required',
                    }}
                    description="e.g. https://app.hubspot.com/contacts/12345678/deal/[this number]"
                    parseValue={(value: string) => parseDealId(value)}
                    value={this.state.hubspotDealId}
                    onChange={(value: string) => {
                      const parsedValue = parseDealId(value);
                      this.setState({hubspotDealId: parsedValue});
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="monday-board-id-label" htmlFor="monday-board-id-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      Monday.com board ID
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    name="mondayBoardId"
                    id="monday-board-id-input"
                    ariaLabelledby="monday-board-id-label"
                    placeholder="Enter the Monday board ID"
                    description="e.g. https://takumihq.monday.com/boards/[this number]/views/123456"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Section>

          {this.props.showCampaignPerformanceSettings && (
            <Section
              title="Organic Performance Metrics"
              details={{
                description:
                  'Choose the organic performance metrics to be shown when viewing the Performance tab. This does not affect what performance data is collected.',
              }}
            >
              <Grid container className={classes.gridContainer}>
                {performanceSettingsValues.organic.map(name => (
                  <Grid key={name} item xs={6}>
                    <CustomCheckbox
                      id={`organic-metric-${name.replaceAll(' ', '_')}`}
                      data-testid={`organic-metric-${name.replaceAll(' ', '_')}`}
                      name={name}
                      checked={this.isPerformanceMetricEnabledForCampaign('organic', name)}
                      label={`${name.endsWith('_count') ? 'No. of ' : ''}${
                        DATA_SERVICE_MODEL_INFO['campaign_performance'][name]['name']
                      }`}
                      disabled={
                        this.isCampaignLocked() ||
                        this.isPerformanceMetricChangeDisabled('organic', name)
                      }
                      onChange={(_, checked) =>
                        this.onPerformanceMetricsChange('organic', name, checked)
                      }
                      onKeyDown={this.handleEnterKeyDown}
                    />
                  </Grid>
                ))}
              </Grid>
            </Section>
          )}

          {this.props.showCampaignPerformanceSettings && (
            <Section
              title="Paid Performance Metrics"
              details={{
                description:
                  'Choose the paid performance metrics to be shown when viewing the Performance tab. This does not affect what performance data is collected.',
              }}
            >
              <Grid container className={classes.gridContainer}>
                {performanceSettingsValues.paid.map(name => (
                  <Grid key={name} item xs={6}>
                    <CustomCheckbox
                      id={`performance-metric-${name.replaceAll(' ', '_')}`}
                      data-testid={`performance-metric-${name.replaceAll(' ', '_')}`}
                      name={name}
                      checked={this.isPerformanceMetricEnabledForCampaign('paid', name)}
                      label={DATA_SERVICE_MODEL_INFO['paid_performance'][name]['name']}
                      disabled={
                        this.isCampaignLocked() ||
                        this.isPerformanceMetricChangeDisabled('paid', name)
                      }
                      onChange={(_, checked) =>
                        this.onPerformanceMetricsChange('paid', name, checked)
                      }
                      onKeyDown={this.handleEnterKeyDown}
                    />
                  </Grid>
                ))}
              </Grid>
            </Section>
          )}

          <Section
            title={'CPM Benchmark'}
            details={{
              description: 'CPM benchmark for this campaign.  This used to estimate EMV.',
            }}
          >
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item xs={6}>
                <FormControl style={{width: '100%'}}>
                  <FormLabel id="cpm-benchmark-label" htmlFor="cpm-benchmark-input">
                    <Typography
                      variant="body2"
                      className={classNames(classes.body2SemiBold, classes.inputLabel)}
                    >
                      CPM Benchmark
                    </Typography>
                  </FormLabel>
                  <ControlFormInput
                    type="number"
                    name="cpmBenchmark"
                    prefix={currencyForMarket(marketSlug)}
                    id="cpm-benchmark-input"
                    ariaLabelledby="cpm-benchmark-label"
                    validators={{
                      isPositive: (v: any) => v === undefined || isNaN(v) || v >= 0,
                    }}
                    messages={{
                      isPositive: 'Must be positive',
                    }}
                    parseValue={(value: string) => parseInt(value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Section>

          <Section
            title={'Campaign KPIs'}
            details={{
              description:
                'Specify the Key Performance Indicators to be used for this campaign.  Campaign performance will be tracked against these targets.',
            }}
            data-testid={'kpi-section'}
          >
            {this.state.campaignKpis?.map((entry, i) => (
              <Grid
                key={`kpi-entry-${i}`}
                data-testid={`kpi-entry-${i}`}
                container
                className={classes.gridContainer}
                spacing={4}
              >
                <Grid item xs={5}>
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id={`kpi-label-${i}`} htmlFor={`kpi-select-${i}`}>
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        KPI
                      </Typography>
                    </FormLabel>
                    <ControlSelect
                      id={`kpi-select-${i}`}
                      ariaLabelledby={`kpi-label-${i}`}
                      name={`kpi-metric-${i}`}
                      canBeEmpty={false}
                      defaultOption="Select a KPI"
                      value={entry.metric}
                      options={campaignKpiMetricValues.map(v => ({
                        name: capitalize(v),
                        value: v,
                      }))}
                      asyncValidators={{
                        isCampaignKpiMetricUnique: isCampaignKpiMetricUnique(i),
                      }}
                      messages={{
                        isCampaignKpiMetricUnique: 'Each KPI can be specified once',
                      }}
                      onChange={(metric: CampaignKpiMetric) =>
                        this.setCampaignKpiValue(i, metric, entry.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl style={{width: '100%'}}>
                    <FormLabel id={`kpi-value-label-${i}`} htmlFor={`kpi-value-input-${i}`}>
                      <Typography
                        variant="body2"
                        className={classNames(classes.body2SemiBold, classes.inputLabel)}
                      >
                        Value
                      </Typography>
                    </FormLabel>
                    <ControlFormInput
                      type="number"
                      name={`campaignKpis[${i}].value`}
                      id={`kpi-value-input-${i}`}
                      aria-labelledby={`kpi-value-label-${i}`}
                      validators={{
                        isNumber,
                        inRange: inRange(1000, MAX_KPI_VALUE),
                      }}
                      messages={{
                        isNumber: 'Must be a valid number',
                        inRange: `The KPI value must be between 1000 and ${MAX_KPI_VALUE}`,
                      }}
                      description="Target value for this KPI"
                      parseValue={(value: string) => parseInt(value)}
                      value={entry.value}
                      onChange={(value: number) => this.setCampaignKpiValue(i, entry.metric, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    style={{marginTop: 30}}
                    data-testid={`kpi-delete-${i}`}
                    startIcon={<Delete />}
                    onClick={() => this.removeCampaignKpi(i)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Grid container>
              <Grid item>
                <Button
                  data-testid={'kpis-add-btn'}
                  variant={'outlined'}
                  startIcon={<AddIcon />}
                  size={'large'}
                  onClick={this.addCampaignKpi}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Section>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              &nbsp;
              <hr />
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <FormSubmitButton text="Save" size="large" noMargin />
              </Grid>
              {!campaign.archived && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<Archive />}
                    onClick={() => {
                      this.setState({openArchiveModal: true});
                    }}
                  >
                    Archive
                  </Button>
                  <ControlDialog
                    open={this.state.openArchiveModal}
                    handleClose={() => {
                      this.setState({openArchiveModal: false});
                    }}
                    handleSubmit={() => {
                      this.props.archiveCampaign();
                      this.setState({openArchiveModal: false});
                    }}
                    title={'Archive campaign'}
                    body={
                      <>
                        <Typography>Are you sure you want to archive this campaign?</Typography>
                        <br />
                        <Typography>
                          If the campaign contains offers, every offer will be revoked before the
                          campaign is archived.
                        </Typography>
                        <br />
                        <Typography variant="h6">
                          If an offer has any gigs, they will have to be manually revoked, to
                          prevent accidentally archiving a campaign that shouldn't be archived.
                        </Typography>
                      </>
                    }
                    buttonName={'Ok'}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

const ComposedCampaignSetup = compose(
  withRouter,
  withStyles(customStyles),
  withStyles(useDefaultGlobalStyles),
  graphql(CampaignManagersQuery, {
    props: ({data: {owners, campaignManagers}}) => ({
      owners,
      campaignManagers,
    }),
  }),
  graphql(AdvertiserIndustryQuery, {
    options: props => {
      const params = qs.parse(props.location.search);
      return {
        variables: {
          advertiserId: params.advertiserId ?? props.campaign?.advertiser.id,
        },
      };
    },
    skip: (props: ICampaignSetupProps & RouteComponentProps) => {
      const params = qs.parse(props.location.search);
      return !(params.advertiserId ?? props.campaign?.advertiser.id);
    },
    props: ({data: {advertiserIndustries}}) => ({
      advertiserIndustries,
    }),
  }),
  graphql(ForceArchiveCampaignMutation, {
    props: ({mutate, ownProps: {campaign}}) => ({
      archiveCampaign: () =>
        mutate({
          variables: {id: campaign.id},
          optimisticResponse: ({id}: {id: string}) => ({
            forceStashCampaign: {
              __typename: 'ForceStashCampaign',
              ok: true,
              campaign: {
                __typename: 'Campaign',
                id,
                archived: true,
                state: 'stashed',
              },
            },
          }),
          refetchQueries: ['AdminAdvertisersQuery', 'CampaignList'],
        }),
    }),
  }),
  graphql(PaymentTerms, {
    props: ({data: {__type}}) => ({
      paymentTerms: __type?.enumValues,
    }),
  })
)(CampaignSetup);

const WrappedComponent = (props: Record<string, any>) => {
  const {
    showCampaignSoldForDifferentPriceThenRateCardOption,
    showCampaignPerformanceSettings,
    useYoutubeIntegration,
    useTwitchIntegration,
  } = useFeatureFlags();
  return (
    <ComposedCampaignSetup
      {...{
        showCampaignSoldForDifferentPriceThenRateCardOption,
        showCampaignPerformanceSettings,
        useYoutubeIntegration,
        useTwitchIntegration,
        ...props,
      }}
    />
  );
};

export default WrappedComponent;
