import React from 'react';
import XLSX from 'xlsx';
import {Button} from '@material-ui/core';

import {performanceCoverSheet} from './coverSheet';

interface Props {
  apiData: Record<string, Array<Record<string, unknown>>>;
  brandData: {
    brand: string;
    campaign?: string;
    id?: string;
  };
  multipleCampaigns?: Campaign[];
  disabled: boolean;
}

const ExportJsonToExcel: React.FC<Props> = ({apiData, brandData, disabled, multipleCampaigns}) => {
  const handleConvertToExcel = () => {
    const campaignSection = multipleCampaigns
      ? [['Campaign Performance Metrics:'], ...multipleCampaigns.map(campaign => [campaign.name])]
      : [['Campaign:', brandData.campaign]];

    const dynamicCoverSheet = [...campaignSection, [''], ...performanceCoverSheet];

    const coverSheet = XLSX.utils.aoa_to_sheet(dynamicCoverSheet);
    coverSheet['!cols'] = [{wch: 30}, {wch: 100}, {wch: 30}];
    coverSheet['!rows'] = Array(dynamicCoverSheet.length).fill({hpx: 30});

    for (let row = 0; row < dynamicCoverSheet.length; row++) {
      const cellRef = XLSX.utils.encode_cell({c: 0, r: row});
      if (dynamicCoverSheet[row][2]) {
        XLSX.utils.cell_set_hyperlink(
          coverSheet[cellRef],
          dynamicCoverSheet[row][2],
          dynamicCoverSheet[row][0]
        );
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, coverSheet, 'Cover Sheet');

    const worksheets = Object.entries(apiData)
      .filter(([, value]) => value != null && value.length > 0)
      .map(([key, value]) => ({
        name: key,
        data: value!,
      }));

    worksheets.forEach(worksheet => {
      const worksheetData = worksheet.data.map(obj => Object.values(obj));
      const ws = XLSX.utils.aoa_to_sheet([Object.keys(worksheet.data[0]), ...worksheetData]);
      XLSX.utils.book_append_sheet(workbook, ws, worksheet.name);
    });

    XLSX.writeFile(workbook, `${brandData.brand} - ${brandData.campaign}.xlsx`);
  };

  return (
    <Button variant="contained" color="primary" disabled={disabled} onClick={handleConvertToExcel}>
      Export Excel
    </Button>
  );
};

export default ExportJsonToExcel;
