import React from 'react';
import {NavLink} from 'react-router-dom';

import styles from './style.css';

const Nav = () => (
  <div className={styles.container}>
    <div className={styles.a}>
      <ul className={styles.links}>
        <li>
          <a href="http://takumi.com">Creators</a>
        </li>
        <li>
          <NavLink activeClassName="active" to="/login" className={styles.forceActive}>
            Advertisers
          </NavLink>
        </li>
      </ul>
    </div>
    <div className={styles.b}>
      <ul className={styles.links}>
        <li>
          <NavLink activeClassName="active" to="/login">
            Log In
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
);

export default Nav;
