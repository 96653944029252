import React from 'react';

import {getThemeColor} from 'services/themer';

export default () => (
  <svg viewBox="0 0 30 30">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M19.4041637,21.4440862 C17.3647835,23.0451949 14.7938951,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,14.7522291 23.073461,17.2880534 21.5152871,19.3125689 L29.9800379,27.7773197 L27.8587175,29.89864 L19.4041637,21.4440862 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z"
        fill={getThemeColor()}
      />
    </g>
  </svg>
);
