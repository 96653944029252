import {createStyles, makeStyles} from '@material-ui/core/styles';

import {transparentBlack, white} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
      width: 320,
      marginRight: '32px',
      position: 'relative',
      padding: '12px',
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        boxShadow: `0px 2px 20px 0px ${transparentBlack}`,
      },
    },
    erWrapper: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      bottom: 20,
    },
    erBlock: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#9B51E0',
      padding: '4px 16px 4px 8px',
      color: white,
    },
    infoWrapper: {
      margin: '12px 0',
    },
    metrics: {
      display: 'flex',
      paddingTop: '8px',
    },
    metric: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '40px',
    },
  })
);
