import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {ordinalSuffixOf} from 'consts/utilities';

import {Label} from '../';
import styles from './style.css';

class ListOfTextsInput extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    parseValue: PropTypes.func,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: 'text',
    parseValue: e => e.target.value.toString(),
  };

  constructor(props) {
    super(props);

    this.state = {
      showSaved: false,
      showFailed: false,
      controls: {
        0: undefined,
      },
    };
  }

  getValue = () => {
    const {controls} = this.state;
    return Object.keys(controls).reduce((acc, next) => {
      if (!controls[next]) {
        return acc;
      } else {
        let prev = acc;
        if (prev === undefined) {
          prev = [];
        }
        return [...prev, controls[next]];
      }
    }, undefined);
  };

  onChange = async (index, e) => {
    const {controls} = this.state;
    await this.setState({
      controls: {
        ...controls,
        [index]: await this.props.parseValue(e),
      },
    });

    this.props.onChange(this.getValue());
  };

  addControl = () => {
    const {controls} = this.state;
    this.setState({
      controls: {
        ...controls,
        [Object.keys(controls).length]: undefined,
      },
    });
  };

  removeControl = async () => {
    const {controls} = this.state;
    const keys = Object.keys(controls);
    const filtered = keys.reduce((acc, k) => {
      if (parseInt(k) !== keys.length - 1) {
        return {...acc, [k]: controls[k]};
      }
      return acc;
    }, {});
    await this.setState({controls: filtered});

    this.props.onChange(this.getValue());
  };

  getPlaceHolder = idx => {
    const {label} = this.props;
    const labelLength = label.length;
    if (label[labelLength - 1] === 's') {
      return `${ordinalSuffixOf(parseInt(idx) + 1)} ${label.substring(0, labelLength - 1)}`;
    }

    return `${ordinalSuffixOf(parseInt(idx) + 1)} ${label}`;
  };

  render() {
    const {description, type, hasError, disabled, label} = this.props;
    const {controls} = this.state;

    return (
      <div className={styles.root}>
        {label && <Label label={label} />}
        {Object.keys(controls).map(i => (
          <input
            key={i}
            className={classNames(styles.input, {
              [styles.error]: hasError,
              [styles.disabled]: disabled,
            })}
            type={type}
            placeholder={this.getPlaceHolder(i)}
            disabled={disabled}
            onChange={e => this.onChange(i, e)}
          />
        ))}
        <div className={styles.controlButtons}>
          {Object.keys(controls).length > 1 && (
            <div className={styles.removeControl} onClick={this.removeControl}>
              -
            </div>
          )}
          <div className={styles.addControl} onClick={this.addControl}>
            +
          </div>
        </div>

        {description && <div className={styles.description}>{description}</div>}
      </div>
    );
  }
}

export default ListOfTextsInput;
