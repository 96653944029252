import React from 'react';
import {Icon as IconifyIcon} from '@iconify/react';
import {Box, Grid, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {isNumber} from 'lodash/fp';

import {numberMillified, numberWithCommas} from 'consts/utilities';
import {black50} from 'consts/brand.integration';

interface Props {
  data: TiktokPost;
}

const TiktokPostStats: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  return (
    <>
      {isNumber(data.videoViews) && (
        <Grid item>
          <Tooltip title={`Views - ${numberWithCommas(data.videoViews)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:remove-red-eye" />
              <span className={classes.value}>{numberMillified(data.videoViews)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.comments) && (
        <Grid item>
          <Tooltip title={`Comments - ${numberWithCommas(data.comments)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:chat" />
              <span className={classes.value}>{numberMillified(data.comments)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.likes) && (
        <Grid item>
          <Tooltip title={`Likes - ${numberWithCommas(data.likes)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:heart" />
              <span className={classes.value}>{numberMillified(data.likes)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.shares) && (
        <Grid item>
          <Tooltip title={`Shares - ${numberWithCommas(data.shares)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:share" />
              <span className={classes.value}>{numberMillified(data.shares)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.engagementByFollowers?.value) && (
        <Grid item>
          <Tooltip
            title={`Engagement rate by followers - ${data.engagementByFollowers.formattedValue}`}
            placement="bottom"
            arrow
          >
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:graph-line" />
              <span className={classes.value}>{data.engagementByFollowers.formattedValue}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

const Icon = ({icon}: {icon: string}): JSX.Element => <IconifyIcon icon={icon} color={black50} />;

const useStyles = makeStyles({
  iconWithValue: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '.6rem',
  },
  value: {
    marginLeft: '0.2rem',
    color: black50,
  },
});

export default TiktokPostStats;
