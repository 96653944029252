import React, {useRef} from 'react';
import {gql, useMutation} from '@apollo/client';
import {
  DialogContentText,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Button,
} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Icon} from '@iconify/react';

import {currencyForMarket} from 'consts/utilities';

import {Modal} from '../../../../../components/Widgets';
import {ModalElement} from '../../../../../components/Widgets/Modal';

interface Props {
  campaign: Campaign;
  offer: Offer;
  disabled?: boolean;
}

const UpdateRewardButton: React.FC<Props> = ({campaign, offer, disabled}) => {
  const [updateReward] = useMutation(UpdateRewardMutation);
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      reward: offer.reward?.value,
    },
    validationSchema: yup.object({
      reward: yup.number().integer('Please enter a whole number').required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    await updateReward({variables: {offerId: offer.id, reward: formik.values.reward}});
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="update-reward"
      title="Update reward"
      modalToggler={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="material-symbols:paid" />}
          disabled={disabled}
        >
          Update Reward
        </Button>
      }
      submitTitle="Update Reward"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
    >
      <DialogContentText>
        <FormControl fullWidth>
          <InputLabel htmlFor="reward">Reward</InputLabel>
          <Input
            id="reward"
            name="reward"
            type="number"
            startAdornment={
              <InputAdornment position="start">
                {currencyForMarket(campaign.marketSlug)}
              </InputAdornment>
            }
            value={formik.values.reward}
            aria-describedby="reward"
            error={formik.touched.reward}
            required
            onChange={formik.handleChange}
          />
          <FormHelperText error={formik.errors.reward?.length}>
            {formik.errors.reward}
          </FormHelperText>
        </FormControl>
      </DialogContentText>
    </Modal>
  );
};

const UpdateRewardMutation = gql`
  mutation UpdateRewardMutation($reward: Int!, $offerId: UUIDString!) {
    updateReward(id: $offerId, reward: $reward) {
      ok
      offer {
        id
        reward {
          value
          currency
          formattedValue
        }
      }
    }
  }
`;

export default UpdateRewardButton;
