import React from 'react';
import {Box, IconButton, TableCell, Tooltip, Typography} from '@material-ui/core';
import {DeleteOutline} from '@material-ui/icons';

import {
  InstagramLink,
  ProfilePicture,
  SortableTable,
  TableZebraRow,
  TikTokLink,
} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

interface Props {
  data: Array<InstagramAccount | TikTokAccount>;
  remove?: (id: string) => void;
}

const headCells = [
  {id: 'name', label: 'Name'},
  {id: 'followers', label: 'Followers', numeric: true},
  {id: 'actions', label: ''},
];

const getRowConfig = (account: InstagramAccount | TikTokAccount) => {
  const isInstagramAccount = (
    account: InstagramAccount | TikTokAccount
  ): account is InstagramAccount => {
    return account.__typename === 'InstagramAccount';
  };

  if (isInstagramAccount(account)) {
    return {
      name: account.username,
      username: account.username,
      photo: account.profilePicture,
      followers: account.followers,
      LinkComponent: InstagramLink,
    };
  }

  return {
    name: account.nickname,
    username: account.username,
    photo: account.cover,
    followers: account.followers,
    LinkComponent: TikTokLink,
  };
};

const AdvertiserSocialsTable: React.FC<Props> = ({data, remove}) => {
  return (
    <SortableTable
      rows={data}
      headCells={headCells}
      sortMetric="followers"
      emptyMessage="There are no accounts to show."
      renderRow={row => {
        const {LinkComponent, ...account} = getRowConfig(row);

        return (
          <TableZebraRow key={row.id}>
            <TableCell component="th" scope="row">
              <Box display="flex">
                <ProfilePicture src={account.photo!} />
                <Box sx={{marginLeft: 10}}>
                  <Typography variant="h6">{account.name}</Typography>
                  <LinkComponent username={row.username!} />
                </Box>
              </Box>
            </TableCell>

            <TableCell align="center">
              <Typography variant="body1">{numberWithCommas(row.followers)}</Typography>
            </TableCell>

            <TableCell align="center">
              {remove && (
                <Tooltip title="Remove" arrow>
                  <IconButton aria-label="Back" onClick={() => remove(row.id!)}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableZebraRow>
        );
      }}
    />
  );
};

export default AdvertiserSocialsTable;
