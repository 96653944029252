import React from 'react';

import {theme} from '../../../../services/themer';
import InfluenceMLogo from '../../../../components/Widgets/InfluenceMLogo';
import SalesLogo from '../../../../components/Widgets/SalesLogo';

type AdminLogoProps = {
  height: number;
};

const AdminLogo = ({height}: AdminLogoProps): JSX.Element => (
  <svg width={(height / 20) * 136} height={height} viewBox="0 0 136 22">
    <g fillRule="evenodd">
      <path d="M 19.217 0 L 19.217 3.185 L 11.7948 3.185 L 11.7948 19.4869 L 7.4225 19.4869 L 7.4225 3.185 L 0 3.185 L 0 0 L 19.217 0 z M 26.7484 0 L 32.2543 0 L 42.2136 19.4869 L 37.0316 19.4869 L 34.7914 15.1146 L 23.5637 15.1146 L 21.5125 19.4869 L 16.8161 19.4869 L 26.7484 0 z M 33.2261 11.9298 L 29.1776 3.4549 L 25.1287 11.9298 L 33.2261 11.9298 z M 48.3149 0 L 48.3149 8.5019 L 56.5469 0 L 61.9177 0 L 53.3352 8.7718 L 62.9434 19.4869 L 57.1676 19.4869 L 48.3149 9.2307 L 48.3149 19.4869 L 43.9424 19.4869 L 43.9424 0 L 48.3149 0 z M 64.5642 0 L 68.9365 0 L 68.9365 11.9029 C 68.9365 15.1413 71.0146 16.9768 74.3614 16.9768 C 77.6001 16.9768 79.7594 15.3845 79.7594 11.7945 L 79.7594 0 L 84.1317 0 L 84.1317 11.7136 C 84.1317 16.167 82.2695 20 73.6865 20 C 68.9632 20 64.5642 17.5975 64.5642 12.4964 L 64.5642 0 z M 94.9562 0 L 102.3242 15.8701 L 109.5577 0 L 116.8451 0 L 116.8451 19.4869 L 112.4725 19.4869 L 112.4725 2.7531 L 112.4186 2.7531 L 104.8074 19.4869 L 99.4636 19.4869 L 91.6636 2.7531 L 91.6096 2.7531 L 91.6096 19.4869 L 87.6691 19.4869 L 87.6691 0 L 94.9562 0 z M 121.3134 0 L 125.686 0 L 125.686 19.4869 L 121.3134 19.4869 L 121.3134 0" />
    </g>
  </svg>
);

type HeaderLogoProps = {
  height: number;
  logoUrl: string;
};

const HeaderLogo = ({height = 36, logoUrl}: HeaderLogoProps): JSX.Element => {
  if (logoUrl) return <img style={{maxHeight: height * 2}} src={logoUrl} alt="logo" />;
  switch (theme) {
    case 'influenceM':
      return <InfluenceMLogo height={height * 1.1} />;
    case 'takumi-sales':
      return <SalesLogo height={height} />;
    default:
      return <AdminLogo height={height} />;
  }
};

export default HeaderLogo;
