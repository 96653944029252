import React from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {App, AppWrapper} from '../scenes/App';
import App404 from '../scenes/App404/App404';
import {
  AdminWrapper,
  AdminCampaigns,
  AdminCampaignsLive,
  AdminUserManagement,
} from '../scenes/Admin';
import Insight from '../scenes/Admin/Insights/Insight';
import {
  AdminGigSubmissions,
  AdminGigSubmittedGig,
  AdminPickStoryFrames,
  GigHistory,
} from '../scenes/Admin/Gig';
import {Login, RecoverPassword, RecoverPasswordToken} from '../scenes/Login';
import {CampaignReport} from '../scenes/Campaign';
import EditProfile from '../scenes/EditProfile';
import Welcome from '../scenes/Welcome';
import SignupVerify from '../scenes/SignupVerify';
import LazyComponent from '../components/LazyComponent';
import MediaUploader from '../scenes/MediaUploader';
import {
  ACCESS_ALL_ADVERTISERS,
  ACCESS_ALL_GIGS,
  ACCESS_ALL_INFLUENCERS,
  DEVELOPER,
  MANAGE_INFLUENCERS,
  MANAGE_USERS,
  TEAM_MEMBER,
  CAMPAIGNS_PAGE_ACCESS,
  VIEW_GIG_HISTORY,
} from '../consts/permissions';
import {Signups} from './Signups';
import {Insights} from './Insights';
import {Brands} from './Brands';
import SecureRoute from './components/SecureRoute';
import {Influencers} from './Influencers';
import {DashboardRoute as Dashboard} from './Dashboard';
import {EditProfileQuery} from '../scenes/EditProfile/graphqlQuery';
import useHomePage from '../hooks/useHomePage';
import {UserRoles} from '../consts/roles';
import {Finances} from './Finances';
import Maintenance from '../scenes/Maintenance';
import useFeatureFlags from '../hooks/useFeatureFlags';

const Graphiql = LazyComponent({
  loader: () => import(/* webpackChunkName: "graphiql" */ '../scenes/Graphiql'),
});

const God = LazyComponent({
  loader: () => import(/* webpackChunkName: "god" */ '../scenes/God'),
});

const Tasktiger = LazyComponent({
  loader: () => import(/* webpackChunkName: "tasktiger" */ '../scenes/Tasktiger'),
});

const nonAuthPages = [
  '/password-recovery',
  '/enroll/verify/',
  '/login',
  '/404',
  '/graphql',
  '/maintenance',
];

const Routes = (): JSX.Element => {
  const {maintenanceMode} = useFeatureFlags();
  const history = useHistory();
  const checkRedirect =
    nonAuthPages.filter(url => history.location.pathname.includes(url)).length !== 0;
  const homePage = useHomePage(checkRedirect);
  const {data: userData} = useQuery(EditProfileQuery, {skip: checkRedirect});

  switch (history.location.pathname) {
    case '/':
      if (homePage) {
        history.replace(homePage);
      }
      break;
    case '/404':
      return (
        <App>
          <App404 />
        </App>
      );
    case '/maintenance':
      return (
        <App>
          <Maintenance />
        </App>
      );
  }

  if (maintenanceMode) {
    history.replace('/maintenance');
  }

  return (
    <Route path="/">
      <App>
        <Switch>
          <Route path="/login" component={Login} />

          <Route path="/graphql" component={Graphiql} />

          <Route exact path="/report/:reportTokenId" component={CampaignReport} />

          <SecureRoute path="/upload" permission={TEAM_MEMBER} component={MediaUploader} />

          <Route path="/password-recovery">
            <Switch>
              <Route exact path="/password-recovery" component={RecoverPassword} />
              <Route path="/password-recovery/:token" component={RecoverPasswordToken} />
            </Switch>
          </Route>

          <Route path="/enroll/verify/:token" component={SignupVerify} />

          <Route path="/welcome" component={Welcome} />

          <Route
            path="/faq"
            component={() => {
              const path = 'https://help.takumi.com/en/';
              window.open(path, '_blank');
              history.goBack(); // keep the history unchanged
              return null;
            }}
          />

          <Route>
            <AppWrapper>
              <Switch>
                <Route path="/brands">
                  <Brands />
                </Route>

                <Route path="/dashboard">
                  <Dashboard />
                </Route>

                <Route path="/user">
                  <Route path="/user/edit" component={EditProfile} />
                  <Redirect from="/user" to="/user/edit" />
                </Route>

                <Route>
                  <AdminWrapper>
                    <Switch>
                      <SecureRoute path="/influencers" permission={ACCESS_ALL_INFLUENCERS}>
                        <Influencers />
                      </SecureRoute>

                      <SecureRoute
                        path="/users"
                        permission={MANAGE_USERS}
                        component={AdminUserManagement}
                      />

                      <SecureRoute
                        path="/campaigns"
                        permission={
                          userData?.user.roleName === UserRoles.Advertiser
                            ? CAMPAIGNS_PAGE_ACCESS
                            : ACCESS_ALL_ADVERTISERS
                        }
                        component={AdminCampaigns}
                      />

                      <SecureRoute
                        path="/live"
                        permission={ACCESS_ALL_ADVERTISERS}
                        component={AdminCampaignsLive}
                      />

                      <Route path="/gigs">
                        <Switch>
                          <SecureRoute
                            exact
                            path="/gigs"
                            permission={ACCESS_ALL_GIGS}
                            component={AdminGigSubmissions}
                          />
                          <SecureRoute path="/gigs/:gigId" permission={ACCESS_ALL_GIGS}>
                            <AdminGigSubmissions>
                              <AdminGigSubmittedGig />
                            </AdminGigSubmissions>
                          </SecureRoute>
                        </Switch>
                      </Route>

                      <SecureRoute
                        path="/story/:gigId"
                        permission={MANAGE_INFLUENCERS}
                        component={AdminPickStoryFrames}
                      />

                      <SecureRoute
                        path="/history/:gigId"
                        permission={VIEW_GIG_HISTORY}
                        component={GigHistory}
                      />

                      <Route path="/finances">
                        <Finances />
                      </Route>

                      <SecureRoute path="/tasktiger" permission={DEVELOPER} component={Tasktiger} />

                      <Route path="/insights">
                        <Insights />
                      </Route>

                      <SecureRoute
                        path="/insight/:insightId"
                        permission={MANAGE_INFLUENCERS}
                        component={Insight}
                      />

                      <SecureRoute path="/god" permission={MANAGE_INFLUENCERS} component={God} />

                      <Route path="/signups">
                        <Signups />
                      </Route>
                    </Switch>
                  </AdminWrapper>
                </Route>
              </Switch>
            </AppWrapper>
          </Route>
        </Switch>
      </App>
    </Route>
  );
};

export default Routes;
