import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Grid} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {Definition, LoaderDots} from 'components/Widgets';

import {currencyForMarket, numberWithCommas} from 'consts/utilities';
import useFeatureFlags from 'hooks/useFeatureFlags';

const formattedCurrency = (marketSlug: string, value: number) =>
  `${currencyForMarket(marketSlug)}${numberWithCommas(value || 0)}`;

interface Props {
  definitionClassName: string;
  definitionTitleClassName: string;
  campaignId?: string;
  marketSlug: string;
  listPrice: number;
  rewardModel: string;
  shippingRequired: boolean;
  targetInstagram: number;
  targetTiktok: number;
  targetYoutube: number;
  targetTwitch: number;
  targetContent: number;
}

const ExampleReward: React.FC<Props> = ({
  definitionClassName,
  definitionTitleClassName,
  marketSlug,
  listPrice,
  rewardModel,
  shippingRequired,
  targetInstagram,
  targetTiktok,
  targetYoutube,
  targetTwitch,
  targetContent,
}) => {
  const {useNewRewardModel} = useFeatureFlags();

  const targetOverall =
    targetInstagram + targetTiktok + targetYoutube + targetTwitch + targetContent;
  const hasTargetValue = targetOverall > 0;

  const {loading, error, data} = useQuery(RewardSuggestionQuery, {
    variables: {
      marketSlug,
      listPrice,
      rewardModel,
      shippingRequired,
      targetInstagram,
      targetTiktok,
      targetYoutube,
      targetTwitch,
      targetContent,
      units: targetOverall,
    },
    skip: !hasTargetValue || !listPrice,
  });

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return <Alert severity="error">{`Error loading reward suggestion: ${error.message}`}</Alert>;
  }

  // TODO: `suggestedRewardLegacy` to be removed after adoption of new reward model
  const suggestedRewardUnits: number = useNewRewardModel
    ? data?.suggestedReward?.value
    : data?.suggestedRewardLegacy?.value;

  if (rewardModel === 'assets') {
    return (
      <Definition
        title="All influencers"
        definition={formattedCurrency(marketSlug, suggestedRewardUnits)}
        {...{definitionClassName, definitionTitleClassName}}
      />
    );
  }

  if (
    (data && rewardModel === 'reach') ||
    rewardModel === 'engagement' || // legacy model
    rewardModel === 'impressions' // legacy model
  ) {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Definition
            title="15k (min)"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 15)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
        <Grid item>
          <Definition
            title="25k"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 25)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
        <Grid item>
          <Definition
            title="50k"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 50)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
        <Grid item>
          <Definition
            title="100k"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 100)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
        <Grid item>
          <Definition
            title="200k"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 200)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
        <Grid item>
          <Definition
            title="500k"
            definition={formattedCurrency(marketSlug, suggestedRewardUnits * 500)}
            {...{definitionClassName, definitionTitleClassName}}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Definition
      title="(Please set the price and goal values for the campaign)"
      definition={formattedCurrency(marketSlug, 0)}
      {...{definitionClassName, definitionTitleClassName}}
    />
  );
};

// TODO: `suggestedRewardLegacy` to be removed after adoption of new reward model
const RewardSuggestionQuery = gql`
  query RewardSuggestionQuery(
    $campaignId: UUIDString
    $marketSlug: String!
    $listPrice: Int!
    $rewardModel: RewardModel!
    $shippingRequired: Boolean!
    $targetInstagram: Int!
    $targetTiktok: Int!
    $targetYoutube: Int!
    $targetTwitch: Int!
    $targetContent: Int!
    $units: Int!
  ) {
    suggestedReward: getRewardSuggestionMultiplatform(
      campaignId: $campaignId
      marketSlug: $marketSlug
      listPrice: $listPrice
      rewardModel: $rewardModel
      shippingRequired: $shippingRequired
      targetInstagram: $targetInstagram
      targetTiktok: $targetTiktok
      targetYoutube: $targetYoutube
      targetTwitch: $targetTwitch
      targetContent: $targetContent
    ) {
      value
      formattedValue
    }
    suggestedRewardLegacy: getRewardSuggestion(
      marketSlug: $marketSlug
      listPrice: $listPrice
      rewardModel: $rewardModel
      shippingRequired: $shippingRequired
      units: $units
    ) {
      value
      formattedValue
    }
  }
`;

export default ExampleReward;
