import React from 'react';
import {DragSource, DropTarget} from 'react-dnd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {DND_ITEM_TYPES} from 'consts/variables';

import styles from './style.css';

class FooterRow extends React.PureComponent {
  render() {
    const {connectDropTarget, isOver} = this.props;
    return connectDropTarget(
      <tr className={classNames([{[styles.hovering]: isOver}, styles.footerRow, styles.tableRow])}>
        <td colSpan={500} />
      </tr>
    );
  }
}

FooterRow.propTypes = {
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
};

class Row extends React.PureComponent {
  render() {
    const {onRowClick, columns, data, connectDragSource, connectDropTarget, isOver} = this.props;
    const row_el = (
      <tr
        className={classNames(
          isOver ? styles.hovering : '',
          styles.row,
          styles.tableRow,
          !onRowClick ? styles.row : styles.rowClickable
        )}
        onClick={onRowClick ? () => onRowClick(data) : null}
      >
        {columns.map(col => {
          const itemData = typeof col.data === 'function' ? col.data(data) : col.data;
          return (
            <td key={`${col.heading}`} className={styles.item}>
              {col.render ? col.render(itemData) : itemData}
            </td>
          );
        })}
      </tr>
    );
    return connectDropTarget(connectDragSource(row_el));
  }
}

Row.propTypes = {
  onRowClick: PropTypes.func,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.object,
};

const dropTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const dragIndex = item.index;
    const hoverIndex = props.index;
    if (dragIndex === undefined) {
      props.addItem(item, hoverIndex);
    } else {
      props.moveItem?.(dragIndex, hoverIndex);
    }
  },
};

const dragSource = {
  beginDrag(props) {
    return {
      index: props.index,
      id: props.data.id,
    };
  },
};

export default DropTarget(DND_ITEM_TYPES.TABLE_ROW, dropTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource(DND_ITEM_TYPES.TABLE_ROW, dragSource, connect => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  }))(Row)
);

const FooterRowTarget = DropTarget(DND_ITEM_TYPES.TABLE_ROW, dropTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(FooterRow);

export {FooterRowTarget as FooterRow};
