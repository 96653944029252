import {makeStyles} from '@material-ui/core/styles';

import {black} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      padding: '32px',
      width: '800px',
      maxWidth: '800px',
      minHeight: '440px',
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: '32px',
  },
  typography: {
    marginLeft: '18px',
  },
  closeButton: {
    position: 'absolute',
    right: '30px',
    top: '30px',
    color: black,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogContentText: {
    margin: 0,
    color: black,
  },
});
