import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import * as qs from 'query-string';

import SelectRegion, {ALL_SUPPORTED_REGIONS} from '../SelectRegion';

class DefaultRegionPreferences extends PureComponent {
  static propTypes = {
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        supported: PropTypes.bool,
      })
    ),
    loading: PropTypes.bool,
    showUnsupported: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    region: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showUnsupported: false,
  };

  handleCountryClick = region => {
    const {history, location} = this.props;
    const params = qs.parse(location.search);
    history.push({
      ...location,
      search: qs.stringify({
        ...params,
        region,
      }),
    });
  };

  render() {
    const {countries, region, loading, showUnsupported} = this.props;

    return (
      <SelectRegion
        pending={this.props.loading}
        countries={countries}
        region={region.id}
        regionName={loading ? 'Loading...' : region.name}
        showUnsupported={showUnsupported}
        onChange={this.handleCountryClick}
      />
    );
  }
}

const RegionQuery = gql`
  query RegionQuery($includeUnsupported: Boolean, $market: String) {
    countries(includeUnsupported: $includeUnsupported, market: $market) {
      id
      name
      supported
    }
  }
`;

export default compose(
  withRouter,
  graphql(RegionQuery, {
    props: ({ownProps: {location}, data: {countries, loading}}) => {
      const params = qs.parse(location.search);
      params.region = params.region ? params.region : ALL_SUPPORTED_REGIONS;
      return {
        countries,
        loading,
        region: countries?.find(r => r.id === params.region) || {
          id: params.region,
          name: params.region,
        },
      };
    },
    options: ({showUnsupported}) => ({includeUnsupported: showUnsupported}),
  })
)(DefaultRegionPreferences);
