import React from 'react';
import {Grid} from '@material-ui/core';

import Chart from '../PerformanceChart';
import type {DataPoint} from '../PerformanceChart/types';

interface Props {
  historicalData: DataPoint[] | null;
  loading?: boolean;
}

const SummaryChart: React.FC<Props> = ({historicalData, loading = false}) => {
  return (
    <Grid item xs={12} md={12}>
      <Chart data={historicalData} loading={loading} />
    </Grid>
  );
};

export default SummaryChart;
