import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Prompt} from 'components/Widgets';

interface Props {
  offer: Offer;
  disabled?: boolean;
}

const RevertRejectionButton: React.FC<Props> = ({offer, disabled}) => {
  const [revertRejection] = useMutation(RevertRejectionMutation);
  return (
    <Prompt
      title="Revert rejection"
      body={
        <div>
          <p>
            Are you sure you want to revert this offer back to "{offer.revertableState}
            "?
          </p>
          <p>Note that influencers are notified of being rejected from a campaign.</p>
        </div>
      }
      confirmText="Revert"
      control={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="mdi:file-revert" />}
          disabled={disabled}
        >
          Revert Rejection
        </Button>
      }
      onSubmit={() => revertRejection({variables: {id: offer.id}})}
    />
  );
};

const RevertRejectionMutation = gql`
  mutation revertRejection($id: UUIDString!) {
    revertRejection(offerId: $id) {
      ok
      offer {
        id
        state
        formattedState
        revertableState
      }
    }
  }
`;

export default RevertRejectionButton;
