import PropTypes from 'prop-types';
/* eslint-disable max-len */
import React from 'react';

import InfluenceMLogo from 'components/Widgets/InfluenceMLogo';

import {theme} from 'services/themer';

import styles from './style.css';

const ReportLogo = ({height = 36}) => {
  return (
    <div style={{height}} className={styles.root}>
      {theme === 'influenceM' ? (
        <InfluenceMLogo height={height * 1.1} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Untitled-2"
          x="0px"
          y="0px"
          width={`${225 * (height / 36)}px`}
          height={`${height}px`}
          viewBox="0 0 225 36"
          enableBackground="new 0 0 17 14"
        >
          <path
            d="M34.3906094,0 L34.3906094,5.625 L21.1288711,5.625 L21.1288711,34.875 L13.2617383,34.875 L13.2617383,5.625 L0,5.625 L0,0 L34.3906094,0 Z"
            id="Fill-1"
          />
          <path
            d="M59.3406593,21.15 L52.1478521,6.075 L44.955045,21.15 L59.3406593,21.15 Z M47.8771229,0 L57.7672328,0 L75.5244755,34.875 L66.3086913,34.875 L62.2627373,27 L42.2577423,27 L38.4365634,34.875 L30.1198801,34.875 L47.8771229,0 Z"
            id="Fill-3"
          />
          <path
            d="M86.5384615,0 L86.5384615,15.3 L101.148851,0 L110.814186,0 L95.5294705,15.75 L112.612388,34.875 L102.272727,34.875 L86.5384615,16.425 L86.5384615,34.875 L78.6713287,34.875 L78.6713287,0 L86.5384615,0 Z"
            id="Fill-4"
          />
          <path
            d="M115.534466,0 L123.401598,0 L123.401598,21.375 C123.369966,27.2539356 127.093504,30.5578291 133.066933,30.6 C138.894779,30.5578291 142.763901,27.6926344 142.732268,21.15 L142.732268,0 L150.599401,0 L150.599401,21.15 C150.599401,29.100354 147.261868,36 131.943057,36 C123.417688,36 115.534466,31.6755099 115.534466,22.5 L115.534466,0 Z"
            id="Fill-6"
          />
          <path
            d="M169.93007,0 L183.191808,28.35 L196.003996,0 L209.040959,0 L209.040959,34.875 L201.173826,34.875 L201.173826,4.95 L201.173826,4.95 L187.462537,34.875 L178.021978,34.875 L164.085914,4.95 L163.861139,4.95 L163.861139,34.875 L156.893107,34.875 L156.893107,0 L169.93007,0 Z"
            id="Fill-8"
          />
          <path
            d="M217.132867,0 L225,0 L225,34.875 L217.132867,34.875 L217.132867,0 Z"
            id="Fill-9"
          />
        </svg>
      )}

      {theme === 'takumi' && (
        <div>
          <div className={styles.contactInfo}>
            <p>hello@takumi.com</p>
          </div>
        </div>
      )}
    </div>
  );
};

ReportLogo.propTypes = {
  height: PropTypes.number,
};

export default ReportLogo;
