import {gql} from '@apollo/client';

export const InsightsQuery = gql`
  query InsightsQuery($mine: Boolean, $region: UUIDString, $offset: Int, $limit: Int) {
    insights(processed: false, mine: $mine, region: $region, offset: $offset, limit: $limit) {
      edges {
        node {
          id
          state
        }
      }
      insightsCount(processed: false, mine: $mine, region: $region)
    }
  }
`;
