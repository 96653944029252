import React from 'react';
import {Avatar, Grid, TableCell, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

import {SortableTable, TableZebraRow} from 'components/Widgets';
import {HeadCell} from 'components/Widgets/SortableTable';

import {formatPercentage, numberWithCommas} from 'consts/utilities';

import type {CampaignPerformanceResponse} from '../../../hooks/useDataServiceApi/types';

interface Props {
  brandName: string;
  data: Array<
    CampaignPerformanceResponse[number] & {campaign_name: string; photos: Campaign['photos']}
  >;
}

const headCells: HeadCell<Props['data'][number]>[] = [
  {id: 'campaign_name', label: 'Campaign'},
  {id: 'influencer_count', label: 'Creators', numeric: true},
  {id: 'digital_content_count', label: 'Assets', numeric: true},
  {id: 'impression_total', label: 'Impressions', numeric: true},
  {id: 'reach_total', label: 'Reach', numeric: true},
  {id: 'engagement_total', label: 'Engagements', numeric: true},
  {id: 'engagement_rate_by_impressions', label: 'Engagement Rate', numeric: true},
];

const CampaignComparisonTable: React.FC<Props> = ({brandName, data}: Props) => {
  return (
    <SortableTable<Props['data'][number]>
      ariaLabel="Campaign Performance"
      sortMetric="engagement_rate_by_impressions"
      rows={data}
      headCells={headCells}
      renderRow={(row, i) => (
        <TableZebraRow key={i} tabIndex={-1}>
          <TableCell component="th" scope="row">
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <Avatar>
                  <img src={row.photos?.coverPhoto?.url} alt="" />
                </Avatar>
              </Grid>
              <Grid item>
                <Link
                  to={`/brands/${brandName}/${row.campaign_id}/performance`}
                  aria-label="Campaign"
                >
                  <strong>{row.campaign_name}</strong>
                </Link>
              </Grid>
            </Grid>
          </TableCell>

          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.influencer_count)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.digital_content_count)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.impression_total)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.reach_total)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.engagement_total)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {formatPercentage(row.engagement_rate_by_impressions)}
            </Typography>
          </TableCell>
        </TableZebraRow>
      )}
    />
  );
};

export default CampaignComparisonTable;
