import React, {ReactNode} from 'react';
import {RedBoxError} from 'redbox-react';

import ErrorReporting from 'services/error-reporting';

interface Props {
  children: ReactNode | ReactNode[];
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {error: null};
  }

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error) {
    ErrorReporting.captureError(error, {}, 'ErrorHandler');
    this.setState({error});
  }

  render() {
    if (this.state.error) {
      if (__DEV__) {
        return (
          <RedBoxError error={this.state.error} editorScheme="atm" style={{...styles.redbox}} />
        );
      } else {
        return <pre>{this.state.error.message}</pre>;
      }
    }

    return this.props.children;
  }
}

const styles: {redbox: React.CSSProperties} = {
  redbox: {
    boxSizing: 'border-box',
    fontFamily: 'sans-serif',
    padding: 10,
    color: 'red',
    width: '100%',
    background: 'rgb(204, 0, 0)',
  },
};

export default ErrorBoundary;
