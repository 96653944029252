import {Platforms, PlatformsConfig} from './platforms';

export const PostType = {
  Content: 'content',
  InstagramStandard: 'standard',
  InstagramVideo: 'video',
  InstagramReel: 'reel',
  InstagramStory: 'story',
  Tiktok: 'tiktok',
  Youtube: 'youtube', //legacy
  YoutubeVideo: 'youtube_video',
  YoutubeShort: 'youtube_short',
  Twitch: 'twitch',
  Snapchat: 'snapchat',
};

export const PostTypeIcons = {
  [PostType.Content]: 'mdi:perm-media',
  [PostType.InstagramStandard]: PlatformsConfig[Platforms.Instagram].icon,
  [PostType.InstagramVideo]: PlatformsConfig[Platforms.Instagram].icon,
  [PostType.InstagramStory]: PlatformsConfig[Platforms.Instagram].icon,
  [PostType.InstagramReel]: PlatformsConfig[Platforms.Instagram].icon,
  [PostType.Tiktok]: PlatformsConfig[Platforms.TikTok].icon,
  [PostType.Youtube]: PlatformsConfig[Platforms.YouTube].icon,
  [PostType.YoutubeVideo]: PlatformsConfig[Platforms.YouTube].icon,
  [PostType.YoutubeShort]: PlatformsConfig[Platforms.YouTube].icon,
  [PostType.Twitch]: PlatformsConfig[Platforms.Twitch].icon,
  [PostType.Snapchat]: PlatformsConfig[Platforms.Snapchat].icon,
};
