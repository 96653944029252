import {RawData, Edge, VisibleMetrics, AccessibleData} from './types';
import {impressionsLegend, engagementRateLegend, campaignNameLimit, notApplicable} from './consts';

export const processChartData = (rawData: RawData): Array<AccessibleData> => {
  return (rawData?.campaigns?.edges ?? []).map((edge: Edge) => edge?.node?.accessibleData);
};

export const formatTickForXAxis = (id: string, chartData: Array<AccessibleData>) => {
  const found = chartData.find(item => item.id === id);
  if (!found?.name) return notApplicable;
  if (found?.name?.length < campaignNameLimit) return found.name;
  return `${found?.name.slice(0, campaignNameLimit)}...`;
};

export const formatTickForRightYAxis = (value: string) => `${value}%`;

export const generateLegendPayload = (visibleMetrics: VisibleMetrics) => {
  const payload = [];
  if (visibleMetrics?.impressions) payload.push(impressionsLegend);
  if (visibleMetrics?.engagementRate) payload.push(engagementRateLegend);

  return payload;
};

export const processCampaigns = (rawData: RawData) => {
  return (rawData?.campaigns?.edges ?? []).map((edge: Edge) => edge?.node);
};

export const getVisibleMetrics = (accessibleData: Array<AccessibleData> | null) => {
  const visibleMetrics = {budget: false, type: false, impressions: false, engagementRate: false};

  if (!Array.isArray(accessibleData) || accessibleData.length === 0) return visibleMetrics;

  if (accessibleData[0]?.budget !== null) {
    visibleMetrics.budget = true;
  }
  if (accessibleData[0]?.type !== null) {
    visibleMetrics.type = true;
  }
  if (accessibleData[0]?.impressions !== null) {
    visibleMetrics.impressions = true;
  }
  if (accessibleData[0]?.engagementRate !== null) {
    visibleMetrics.engagementRate = true;
  }

  return visibleMetrics;
};

export const formatEngagementRate = (engagementRate: number | null) =>
  engagementRate !== null ? `${Math.round(engagementRate * 100) / 100}%` : '';

export const roundRate = (rate: number | null) =>
  rate !== null ? Math.round(rate * 100) / 100 : 0;
