import React from 'react';
import {Avatar, Grid, TableCell, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

import {SortableTable, TableZebraRow} from 'components/Widgets';
import {HeadCell} from 'components/Widgets/SortableTable';

import {formatFraction, formatPercentage, numberWithCommas} from 'consts/utilities';

import type {CampaignPaidPerformanceResponse} from '../../../hooks/useDataServiceApi/types';

interface Props {
  brandName: string;
  data: Array<
    CampaignPaidPerformanceResponse[number] & {campaign_name: string; photos: Campaign['photos']}
  >;
}

const headCells: HeadCell<Props['data'][number]>[] = [
  {id: 'campaign_name', label: 'Campaign'},
  {id: 'impression_cumulative_total', numeric: true, label: 'Impressions'},
  {id: 'engagement_cumulative_total', numeric: true, label: 'Engagements'},
  {id: 'video_play_cumulative_total', numeric: true, label: 'Views'},
  {id: 'engagement_rate_by_impressions_average', numeric: true, label: 'ER%'},
  {id: 'click_through_rate_average', numeric: true, label: 'CTR%'},
  {id: 'view_through_rate_average', numeric: true, label: 'VTR%'},
];

const CampaignPaidPerformanceComparisonTable: React.FC<Props> = ({brandName, data}: Props) => {
  return (
    <SortableTable<Props['data'][number]>
      ariaLabel="Paid Performance"
      sortMetric="engagement_total"
      rows={data}
      headCells={headCells}
      renderRow={(row, i) => (
        <TableZebraRow key={i} tabIndex={-1}>
          <TableCell component="th" scope="row">
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <Avatar>
                  <img src={row.photos?.coverPhoto?.url} alt="" />
                </Avatar>
              </Grid>
              <Grid item>
                <Link
                  to={`/brands/${brandName}/${row.campaign_id}/performance`}
                  aria-label="Campaign"
                >
                  <strong>{row.campaign_name}</strong>
                </Link>
              </Grid>
            </Grid>
          </TableCell>

          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.impression_cumulative_total)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(row.engagement_cumulative_total)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {numberWithCommas(
                (row.video_view_2_second_cumulative_total ?? 0) +
                  (row.video_view_3_second_cumulative_total ?? 0)
              )}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {formatPercentage(row.engagement_rate_by_impressions_average)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {formatPercentage(row.click_through_rate_average)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1" component="span">
              {formatFraction(row.view_through_rate_average)}
            </Typography>
          </TableCell>
        </TableZebraRow>
      )}
    />
  );
};

export default CampaignPaidPerformanceComparisonTable;
