import React from 'react';
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Icon} from '@iconify/react';

import {TableZebraRow} from 'components/Widgets';

import {formatPercentage, numberWithCommas} from 'consts/utilities';
import {socialIcons} from 'consts/icons';

import type {CampaignPlatformPerformanceResponse} from '../../../hooks/useDataServiceApi/types';

type PlatformData = {
  influencer_count: CampaignPlatformPerformanceResponse[number]['influencer_count'];
  digital_content_count: CampaignPlatformPerformanceResponse[number]['digital_content_count'];
  impression_total: CampaignPlatformPerformanceResponse[number]['impression_total'];
  reach_total: CampaignPlatformPerformanceResponse[number]['reach_total'];
  engagement_total: CampaignPlatformPerformanceResponse[number]['engagement_total'];
  engagement_rate_by_impressions: CampaignPlatformPerformanceResponse[number]['engagement_rate_by_impressions'];
};

export type Props = {
  brandName: string;
  data: Record<
    Campaign['id'],
    {
      name: Campaign['name'];
      photos: Campaign['photos'];
      instagram: PlatformData | null;
      tiktok: PlatformData | null;
    }
  >;
};

const conditionallyFormat = (
  value: number | undefined,
  formatter: typeof formatPercentage | typeof numberWithCommas
): string => (!value ? '-' : formatter(value));

const CampaignPlatformComparisonTable: React.FC<Props> = ({brandName, data}) => (
  <TableContainer>
    <Table aria-label="Platform Performance">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <strong>Campaign</strong>
          </TableCell>
          <TableCell>
            <strong>Creators</strong>
          </TableCell>
          <TableCell>
            <strong>Assets</strong>
          </TableCell>
          <TableCell>
            <strong>Impressions</strong>
          </TableCell>
          <TableCell>
            <strong>Reach</strong>
          </TableCell>
          <TableCell>
            <strong>Engagements</strong>
          </TableCell>
          <TableCell>
            <strong>Engagement Rate</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(data).map(([campaign_id, {name, photos, ...metrics}]) => (
          <>
            <TableZebraRow alternateEvery={3}>
              <TableCell rowSpan={3}>
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Avatar>
                      <img src={photos?.coverPhoto?.url} alt="" />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Link
                      to={`/brands/${brandName}/${campaign_id}/performance`}
                      aria-label="Campaign"
                    >
                      <strong>{name}</strong>
                    </Link>
                  </Grid>
                </Grid>
              </TableCell>
            </TableZebraRow>
            {(['instagram', 'tiktok'] as const).map(platform => (
              <TableZebraRow key={platform} alternateEvery={3}>
                <TableCell>
                  <Icon icon={socialIcons[platform]} width={18} />
                </TableCell>

                <TableCell>
                  {conditionallyFormat(metrics[platform]?.influencer_count, numberWithCommas)}
                </TableCell>
                <TableCell>
                  {conditionallyFormat(metrics[platform]?.digital_content_count, numberWithCommas)}
                </TableCell>
                <TableCell>
                  {conditionallyFormat(metrics[platform]?.impression_total, numberWithCommas)}
                </TableCell>
                <TableCell>
                  {conditionallyFormat(metrics[platform]?.reach_total, numberWithCommas)}
                </TableCell>
                <TableCell>
                  {conditionallyFormat(metrics[platform]?.engagement_total, numberWithCommas)}
                </TableCell>
                <TableCell>
                  {conditionallyFormat(
                    metrics[platform]?.engagement_rate_by_impressions,
                    formatPercentage
                  )}
                </TableCell>
              </TableZebraRow>
            ))}
          </>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CampaignPlatformComparisonTable;
