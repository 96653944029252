import React from 'react';
import {Typography} from '@material-ui/core';

interface Props {
  title: string;
}

const DivHeading: React.FC<Props> = ({title}) => <Typography variant="h3">{title}</Typography>;

export default DivHeading;
