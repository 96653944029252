import React from 'react';

import {NavigationLink, SecondaryNavigation} from 'components/Widgets';

import {advertiserUrlFromParams} from 'consts/urls';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface Props {
  params: {
    advertiser: string;
  };
}

const AdvertiserTabs: React.FC<Props> = ({params}) => {
  const {showBrandPerformance, showBrandSocials, showBrandCompetitors} = useFeatureFlags();

  return (
    <SecondaryNavigation>
      <NavigationLink to={`${advertiserUrlFromParams(params)}/campaigns`}>Campaigns</NavigationLink>
      {showBrandPerformance && (
        <NavigationLink to={`${advertiserUrlFromParams(params)}/performance`}>
          Performance
        </NavigationLink>
      )}
      <NavigationLink to={`${advertiserUrlFromParams(params)}/settings`}>Settings</NavigationLink>
      <NavigationLink to={`${advertiserUrlFromParams(params)}/members`}>Members</NavigationLink>
      {showBrandSocials && (
        <NavigationLink to={`${advertiserUrlFromParams(params)}/socials`}>Socials</NavigationLink>
      )}
      {showBrandCompetitors && (
        <NavigationLink to={`${advertiserUrlFromParams(params)}/competitors`}>
          Competitors
        </NavigationLink>
      )}
      <NavigationLink to={`${advertiserUrlFromParams(params)}/access-settings`}>
        Access settings
      </NavigationLink>
    </SecondaryNavigation>
  );
};

export default AdvertiserTabs;
