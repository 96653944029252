import React from 'react';
import PropTypes from 'prop-types';
import {gql, useQuery} from '@apollo/client';

import {Table, LoaderDots, RelativeDate} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import styles from './style.css';

const InsightsQuery = gql`
  query InsightQuery($id: UUIDString) {
    influencer(id: $id) {
      id
      instagramAccount {
        id
        audienceInsight {
          id
          created
          regionInsights {
            id
            region {
              id
              name
            }
            followerCount
            followerPercentage {
              formattedValue
            }
          }
          genderAgeInsights {
            id
            gender
            followerCount
            followerPercentage {
              formattedValue
            }
            ageFrom
            ageTo
          }
        }
      }
    }
  }
`;

const Insights = ({match: {params}}) => {
  const {loading, error, data} = useQuery(InsightsQuery, {
    variables: {id: params.influencerId},
  });

  if (loading) return <LoaderDots />;
  if (error) return <div className={styles.root}>Unknown error occurred</div>;

  const audienceInsight = data?.influencer?.instagramAccount?.audienceInsight;

  if (!audienceInsight) return <div className={styles.root}>Missing audience insights</div>;

  return (
    <div className={styles.root}>
      <div className={styles.created}>
        Updated <RelativeDate date={audienceInsight.created} />
      </div>
      <Table
        columns={[
          {heading: 'Country', data: c => c.region.name},
          {
            heading: 'Followers',
            data: c => c.followerCount,
            render: d => numberWithCommas(d),
          },
          {
            heading: 'Percentage',
            data: c => c.followerPercentage.formattedValue,
          },
        ]}
        data={audienceInsight.regionInsights}
      />
      <Table
        columns={[
          {
            heading: 'Gender',
            data: c => c.gender.charAt(0).toUpperCase() + c.gender.slice(1),
          },
          {
            heading: 'Age',
            data: c => (c.ageTo ? `${c.ageFrom} -${c.ageTo} ` : `${c.ageFrom}+`),
          },
          {
            heading: 'Followers',
            data: c => c.followerCount,
            render: d => numberWithCommas(d),
          },
          {
            heading: 'Percentage',
            data: c => c.followerPercentage.formattedValue,
          },
        ]}
        data={audienceInsight.genderAgeInsights}
      />
    </div>
  );
};

Insights.propTypes = {
  params: PropTypes.shape({
    influencerId: PropTypes.string,
  }),
};

export default Insights;
