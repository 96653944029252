import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useMutation} from '@apollo/client';
import {useFormik} from 'formik';
import * as yup from 'yup';

import NotifyDialog from '../NotifyDialog';
import {Advertiser} from '../../../../types';
import {useStyles} from './styles';
import {SaveEmailMutation} from '../../graphqlQueries';
import {AlertTriangleIcon} from '../../icons';

interface Props {
  advertiser: Advertiser;
}

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email is required'),
});

const SaveEmailForm: React.FC<Props> = ({advertiser}: Props) => {
  const classes = useStyles();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [saveEmailMutation] = useMutation(SaveEmailMutation, {
    refetchQueries: ['BrandUserQuery'],
  });

  const saveEmail = async (advertiserId: string, email: string) => {
    const {data} = await saveEmailMutation({variables: {advertiserId, email}});
    const errorMessage = data?.enrollBrandProfileUser?.errorMessage;
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setIsDialogOpened(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpened(false);
  };

  const {handleSubmit, handleChange, handleBlur, values, errors, touched, isValid} = useFormik({
    validationSchema: validationSchema,
    initialValues: {email: ''},
    onSubmit: ({email}) => {
      saveEmail(advertiser.id, email);
    },
    validateOnMount: true,
  });

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <label
        id="save-email-label"
        htmlFor="save-email-input"
        aria-hidden="true"
        className={classes.hiddenLabel}
      >
        Email
      </label>
      <TextField
        id="save-email-input"
        aria-labelledby="save-email-label"
        className={classes.root}
        placeholder="email"
        variant="outlined"
        name="email"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={values.email}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        FormHelperTextProps={{classes: {root: classes.helperText}}}
        onChange={handleChange('email')}
        onBlur={handleBlur('email')}
      />
      {isValid && (
        <Button variant="outlined" type="submit" className={classes.button} disabled={!isValid}>
          Save
        </Button>
      )}
      <NotifyDialog
        isOpened={isDialogOpened}
        renderTitleIcon={() => <AlertTriangleIcon />}
        title={'This email can not be used'}
        contentText={errorMessage}
        onClose={handleDialogClose}
      />
    </form>
  );
};

export default SaveEmailForm;
