import React from 'react';
import PropTypes from 'prop-types';

import {Checkbox} from '../../';

const CheckboxFilter = ({filterName, filters, label, changeFilters}) => (
  <Checkbox
    checked={filters[filterName] === 'true'}
    label={label}
    onChange={() => {
      const value = filters[filterName] ? undefined : 'true';
      changeFilters({[filterName]: value});
    }}
  />
);

CheckboxFilter.propTypes = {
  filterName: PropTypes.string,
  filters: PropTypes.object,
  label: PropTypes.string,
  changeFilters: PropTypes.func,
};

export default CheckboxFilter;
