import {createStyles, makeStyles} from '@material-ui/core/styles';

import {white, black} from '../../../../../../consts/brand.integration';
import {theme} from '../../../../../../consts/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 480,
      padding: '24px 32px 32px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    input: {
      fontSize: '14px',
      borderBottom: '1px solid #D3D4D8',
      '&:focus': {
        borderBottomColor: '#D3D4D8',
      },
    },
    smallText: {
      fontSize: '12px',
    },
    title: {
      marginTop: '40px',
      marginBottom: '32px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '40px',
    },
    submitButton: {
      height: 40,
      borderRadius: 0,
      padding: '12px 16px',
      color: white,
      backgroundColor: black,
      marginRight: '24px',
      '&:hover': {
        backgroundColor: black,
      },
    },
    resubmitButton: {
      height: 40,
      borderRadius: 0,
      border: `1px solid ${black}`,
      padding: '12px 16px',
      color: black,
      backgroundColor: white,
    },
    textInput: {
      border: `1px solid ${theme.palette['ui-05']}`,
      borderRadius: '2px',
      margin: '8px 0 0',
      paddingLeft: '16px',
      height: '48px',
      fontSize: '14px',
      '&:focus': {
        borderBottomColor: theme.palette['ui-05'],
      },
    },
    modalBody: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: 25,
      marginBottom: 20,
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
);
