import React, {ReactNode} from 'react';
import classNames from 'classnames';

import {useStyles} from './styles';

interface Props {
  title: ReactNode | string;
  definition: Record<string, unknown> | ReactNode;
  placeholder?: string;
  right?: boolean;
  center?: boolean;
  red?: boolean;
  report?: boolean;
  large?: boolean;
  small?: boolean;
  extraMargin?: boolean;
  gold?: boolean;
  big?: boolean;
  definitionClassName?: string;
  definitionTitleClassName?: string;
}

const Definition: React.FC<Props> = ({
  title,
  definition,
  placeholder = 'No value',
  right,
  center,
  report,
  large,
  small,
  extraMargin,
  gold,
  red,
  big,
  definitionClassName,
  definitionTitleClassName,
}: Props): JSX.Element => {
  const classes = useStyles();

  const rootClass = classNames({
    [classes.root]: true,
    [classes.right]: right,
    [classes.center]: center,
  });

  const titleClass = classNames({
    [classes.title]: true,
    [classes.report]: report,
    [classes.extraMargin]: extraMargin,
  });

  const defClass = classNames({
    [classes.definition]: true,
    [classes.largeDefinition]: large,
    [classes.smallDefinition]: small,
    [classes.gold]: gold,
    [classes.red]: red,
    [classes.big]: big,
  });

  function showPlaceholder() {
    if (Array.isArray(definition)) {
      return definition.length === 0;
    }

    return definition === null || definition === undefined;
  }

  return (
    <div className={rootClass}>
      <dt className={classNames(titleClass, definitionTitleClassName)}>{title}</dt>
      <dd className={classNames(defClass, definitionClassName)}>{definition}</dd>
      {showPlaceholder() && <div className={classes.placeholder}>{placeholder}</div>}
    </div>
  );
};

export default Definition;
