const realmSlug = () => {
  switch (window.location.hostname) {
    case 'admin.takumi.com':
      return 'takumi';
    case 'influencem.takumi.com':
      return 'influencem';
    default: {
      const customRealm = window.localStorage.getItem('REALM');
      return customRealm ? customRealm : 'takumi';
    }
  }
};

export default realmSlug();
