import React from 'react';
import PropTypes from 'prop-types';

import {InputRange} from '../../';

const getValue = (value, minValue, maxValue) => {
  if (value >= maxValue) {
    return undefined;
  }

  if (value <= minValue) {
    return undefined;
  }

  return value;
};

const RangeFilter = ({filterName, minValue, maxValue, step, filters, changeFilters}) => (
  <div style={{marginBottom: '20px'}}>
    <InputRange
      minValue={minValue}
      maxValue={maxValue}
      step={step}
      value={filters[filterName]}
      formatValue={value => getValue(value, minValue, maxValue) || '-'}
      onChange={value => {
        changeFilters({[filterName]: getValue(value, minValue, maxValue)});
      }}
    />
  </div>
);

RangeFilter.propTypes = {
  filterName: PropTypes.string,
  filters: PropTypes.object,
  changeFilters: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
};

export default RangeFilter;
