import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import {withRouter, matchPath, useHistory} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import * as qs from 'query-string';

import {LoaderDots, EmptyMessage} from 'components/Widgets';

import {InfluencerRegionCursorHeader} from '../components';
import styles from './style.css';

const Signups = ({loading, influencerSignups, influencer, region, children}) => {
  const history = useHistory();

  // first run
  useEffect(() => {
    if (!loading && !influencer && influencerSignups.next) {
      moveNext();
    }
  }, [loading]);

  // region changed, reload
  useEffect(() => {
    if (region) {
      history.push({
        pathname: `/signups`,
        search: location.search,
      });
    }
  }, [region]);

  const moveNext = () => {
    history.push({
      pathname: `/signups/${influencerSignups.next}`,
      search: location.search,
    });
  };

  return (
    <div className={styles.root}>
      <InfluencerRegionCursorHeader
        count={influencerSignups.count}
        moveNext={moveNext}
        hasNextPage={influencerSignups.count > 1}
      />

      {loading ? (
        <LoaderDots />
      ) : influencerSignups.count > 0 ? (
        children
      ) : (
        <EmptyMessage title="No signups" />
      )}
    </div>
  );
};

Signups.propTypes = {
  loading: PropTypes.bool,
  influencerSignups: PropTypes.object,
  influencer: PropTypes.string,
  region: PropTypes.string,
  children: PropTypes.node,
};

const SignupsQuery = gql`
  query SignupsQuery($regionId: UUIDString, $id: UUIDString) {
    influencerSignups(regionId: $regionId, id: $id) {
      next
      count
    }
  }
`;

export default compose(
  withRouter,
  graphql(SignupsQuery, {
    props: ({ownProps: {location}, data: {influencerSignups, loading}}) => {
      const {params} = matchPath(location.pathname, {path: '/signups/:influencerId'}) || {
        params: {},
      };
      const searchParams = qs.parse(location.search) || {};
      return {
        loading,
        influencerSignups: influencerSignups ? influencerSignups : {},
        influencer: params.influencerId,
        region: searchParams.region,
      };
    },
    options: ({location}) => {
      const {params} = matchPath(location.pathname, {path: '/signups/:influencerId'}) || {
        params: {},
      };
      const searchParams = qs.parse(location.search) || {};
      return {
        variables: {
          regionId: searchParams.region,
          id: params.influencerId,
        },
      };
    },
  })
)(Signups);
