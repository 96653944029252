import {createStyles, makeStyles} from '@material-ui/core/styles';

import {
  bold,
  semiBold,
  roman,
  black40,
  inputPlaceholder,
} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '24px',
    },
    centered: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    noMargin: {
      margin: 0,
    },
    isSub: {
      marginTop: '16px',
    },
    withIcon: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    label: {
      fontWeight: semiBold,
      fontSize: '14px',
      lineHeight: '20px',
    },
    centeredLabel: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: roman,
      color: inputPlaceholder,
      paddingTop: '8px',
      order: 2,
      textAlign: 'center',
      margin: 0,
    },
    noValue: {
      color: black40,
      fontSize: '12px',
      fontWeight: 500,
    },
    centeredNoValue: {
      fontSize: '20px',
      lineHeight: '42px',
    },
    value: {
      fontSize: '16px',
      textAlign: 'right',
    },
    centeredValue: {
      fontWeight: bold,
      fontSize: '32px',
      lineHeight: '32px',
      order: 1,
      textAlign: 'center',
    },
  })
);
