import React from 'react';
import classNames from 'classnames';

import styles from './style.css';

type Props = {
  className?: string;
};

const RowWrap: React.FC<Props> = ({className, children}) => {
  const rootClassName = classNames({
    [styles.root]: true,
    ...(className ? {[className]: className} : {}),
  });

  const margin = 10;

  const style = {
    margin: `-${margin}px`,
  };

  return (
    <div className={rootClassName} style={style}>
      {React.Children.map(children, child => (
        <div style={{margin: `${margin}px`}}>{child}</div>
      ))}
    </div>
  );
};

export default RowWrap;
