import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {DropTarget} from 'react-dnd';

import {DND_ITEM_TYPES} from 'consts/variables';

import styles from './style.css';
import Row, {FooterRow} from './Row';
import {TableFooter} from '../Table';

const dropTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    const dragIndex = item.index;
    if ((dragIndex === undefined && props.data.length === 0) || props.data.size === 0) {
      component.addItem(item, 0);
    }
  },
};

class DraggableTable extends PureComponent {
  updateData(data) {
    this.props.updateItems(data);
  }

  moveItem = (fromIndex, toIndex) => {
    const row = this.props.data[fromIndex];
    const newData = update(this.props.data, {
      $splice: [
        [fromIndex, 1],
        [toIndex, 0, row],
      ],
    });
    this.updateData(newData);
  };

  addItem = (item, position) => {
    const newData = update(this.props.data, {
      $splice: [[position, 0, item]],
    });
    this.updateData(newData);
  };

  render() {
    const {
      columns,
      loading,
      emptyMessage,
      keySelector,
      onRowClick,
      data,
      setIsDragging,
      connectDropTarget,
      updateItems,
    } = this.props;
    return connectDropTarget(
      <div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headingRow}>
              {columns.map(col => (
                <th key={col.heading} className={styles.headingItem}>
                  {col.heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? null : data.size === 0 || data.length === 0 ? (
              <tr>
                <td className={styles.emptyMessage} colSpan={200}>
                  {emptyMessage}
                </td>
              </tr>
            ) : (
              data.map((d, i) => {
                const key = keySelector ? keySelector(d) : d.id;
                return (
                  <Row
                    key={key}
                    index={i}
                    data={d}
                    columns={columns}
                    moveItem={this.moveItem}
                    addItem={this.addItem}
                    updateItems={updateItems}
                    setIsDragging={setIsDragging}
                    exists={row => data.filter(r => keySelector(r) === keySelector(row)).length > 0}
                    onRowClick={onRowClick}
                  />
                );
              })
            )}
            <FooterRow index={data.length} moveItem={this.moveItem} addItem={this.addItem} />
          </tbody>
          <TableFooter columns={columns} data={data} />
        </table>
      </div>
    );
  }
}

DraggableTable.propTypes = {
  data: PropTypes.array,
  tempData: PropTypes.array,
  loading: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func,
      footer: PropTypes.shape({
        reducer: PropTypes.func,
      }),
    })
  ),
  emptyMessage: PropTypes.string,
  keySelector: PropTypes.func,
  setDraggingIndicatorText: PropTypes.func,
  onItemsUpdate: PropTypes.func,
  onRowClick: PropTypes.func,
  setIsDragging: PropTypes.func,
  onUpdate: PropTypes.func,
  isDragging: PropTypes.bool,
  updateItems: PropTypes.func,
};

/* TODO:
  - Add 'sortable' key
  - Add 'sortKey' key
  - Implement sorting
  - Add 'default' key for nulls
*/

export default DropTarget(DND_ITEM_TYPES.TABLE_ROW, dropTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(DraggableTable);

export {DraggableTable};
