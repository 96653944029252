import React from 'react';
import {useQuery} from '@apollo/client';
import {useRouteMatch} from 'react-router-dom';
import {Card, CardContent, CardHeader, Grid, Typography, makeStyles} from '@material-ui/core';
import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import {createStyles} from '@material-ui/styles';

import {FormattedDate, LoaderDots} from '../../../../components/Widgets';
import {GigQuery} from './queries';
import {GIG_HISTORY_EVENT, getHistoryEvent, getHistoryEventLabel} from './helpers';
import CardReserved from './components/CardReserved';
import CardSubmitted from './components/CardSubmitted';
import CardWithFeedback from './components/CardWithFeedback';
import CardSimple from './components/CardSimple';
import CardUpdateCaption from './components/CardUpdateCaption';
import CardPosted from './components/CardPosted';
import CardClaimed from './components/CardClaimed';

const GigHistory: React.FC = () => {
  const classes = useStyles();
  const {params}: {params: {gigId: string}} = useRouteMatch();
  const {loading, error, data} = useQuery<{gig: Gig}>(GigQuery, {
    variables: {
      gigId: params.gigId,
    },
  });

  const renderCardContent = (historyItem: HistoryInterface, gig: Gig) => {
    switch (getHistoryEvent(historyItem)) {
      case GIG_HISTORY_EVENT.ReserveEvent:
        return <CardReserved offer={gig.offer} />;

      case GIG_HISTORY_EVENT.CreateSubmissionEvent:
        return <CardSubmitted historyItem={historyItem} gig={gig} />;

      case GIG_HISTORY_EVENT.GigReviewEvent:
      case GIG_HISTORY_EVENT.GigApproveEvent:
        return (
          <CardWithFeedback historyItem={historyItem} gig={gig} feedback={historyItem.feedback} />
        );

      case GIG_HISTORY_EVENT.RejectGigEvent:
      case GIG_HISTORY_EVENT.ReportEvent:
      case GIG_HISTORY_EVENT.GigResubmissionRequestEvent:
        return (
          <CardWithFeedback
            historyItem={historyItem}
            gig={gig}
            reason={historyItem.reason}
            explanation={historyItem.explanation}
          />
        );

      case GIG_HISTORY_EVENT.UpdateCaptionEvent:
        return <CardUpdateCaption historyItem={historyItem} />;

      case GIG_HISTORY_EVENT.DismissReportEvent:
        return <CardSimple historyItem={historyItem} />;

      case GIG_HISTORY_EVENT.GigPostedEvent:
        return <CardPosted historyItem={historyItem} gig={gig} />;

      case GIG_HISTORY_EVENT.OfferClaimedEvent:
        return <CardClaimed historyItem={historyItem} />;
    }
  };

  const renderTimelineCard = (historyItem: HistoryInterface, gig: Gig) => {
    return (
      <Card data-testid="card">
        <CardHeader
          title={<Typography variant="h4">{getHistoryEventLabel(historyItem)}</Typography>}
          data-testid="card-title"
        />
        <CardContent data-testid="card-content">{renderCardContent(historyItem, gig)}</CardContent>
      </Card>
    );
  };

  const renderTimeline = (list: HistoryInterface[]) => {
    return (
      <Timeline style={{flexGrow: 1}}>
        {list.map((item: HistoryInterface, index) => (
          <TimelineItem key={`timeline-item-${index}`}>
            <TimelineOppositeContent className={classes.timelineOppositeContent}>
              <Typography variant="h5">
                <FormattedDate date={item.created} />
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              {renderTimelineCard(item, data?.gig)}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h2">Gig History</Typography>
      </Grid>
      <Grid item xs={12}>
        {loading && <LoaderDots />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {data?.gig?.history ? (
          renderTimeline(data.gig.history)
        ) : (
          <Alert severity="warning">No history for this gig was found.</Alert>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    timelineContent: {
      flexGrow: 1,
      minWidth: '28.75rem',
      margin: '0 0 1rem',
    },
    timelineOppositeContent: {
      padding: '0 1rem',
    },
  })
);

export default GigHistory;
