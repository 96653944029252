// Added to resolve material ui Theme import issue.
// TODO: should be investigated
// eslint-disable-next-line import/named
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {
  black,
  semiBold,
  sizeBody2,
  white,
  mainContentWidth,
} from '../../../../consts/brand.integration';
import {DESKTOP_DRAWER_WIDTH, TABLET_DRAWER_WIDTH} from './constants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      paddingBottom: 0,
      paddingTop: 0,
      justifyContent: 'center',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
      },
    },
    appBar: {
      display: 'none',
      backgroundColor: black,
      [theme.breakpoints.down('xs')]: {
        left: 0,
        display: 'block',
      },
    },
    menuHamburgerButton: {
      color: white,
      width: 44,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
      },
    },
    menuCloseButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    desktopDrawerPaper: {
      overflow: 'hidden',
      width: DESKTOP_DRAWER_WIDTH,
      backgroundColor: black,
      borderRight: 0,
    },
    tabletDrawerPaper: {
      width: TABLET_DRAWER_WIDTH,
      backgroundColor: black,
    },
    content: {
      flex: 1,
      padding: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        marginLeft: DESKTOP_DRAWER_WIDTH,
        maxWidth: `calc(100vw - ${DESKTOP_DRAWER_WIDTH}px)`,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: TABLET_DRAWER_WIDTH,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    sideBarLogo: {
      textAlign: 'center',
      height: 116,
      [theme.breakpoints.down('xs')]: {
        height: 0,
      },
    },
    topBarControlsWrapper: {
      display: 'flex',
      height: 40,
    },
    appHeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: mainContentWidth,
      margin: '0 auto',
      //TODO: Brand controls should be styled for mobile view
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    topBarControlsIconButton: {
      color: black,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      fontSize: sizeBody2,
      '&:first-child': {
        border: '1px solid black',
        borderRadius: 0,
      },
    },
    header: {
      display: 'flex',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    backButton: {
      color: black,
      marginRight: 12,
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    menuListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    link: {
      fontWeight: semiBold,
      fontSize: sizeBody2,
      display: 'inline-block',
      color: white,
      lineHeight: '38px',
      padding: '2px 0 0 38px',
      transition: 'all 0.2s',
      borderRight: '4px solid transparent',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette['ui-06'],
      },
    },
    activeLink: {
      borderRightColor: theme.palette['stats-insights'],
      fontWeight: semiBold,
      background: theme.palette['ui-06'],
    },
    menuHeaderLogo: {
      '& svg': {
        filter: 'invert(1)',
        marginTop: 55,
        marginLeft: 11,
        [theme.breakpoints.down('xs')]: {
          marginTop: 0,
          position: 'relative',
          top: '-10px',
        },
      },
    },
    listWrapper: {
      overflowY: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&::-webkit-scrollbar': {
        scrollbarColor: '#A1A4B1',
        width: 4,
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A1A4B1',
        width: 4,
        borderRadius: 2,
      },
    },
    staticHeader: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  })
);
