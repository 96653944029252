import PropTypes from 'prop-types';
import React from 'react';

const Lock = ({color = '#000000', small}) => {
  const size = {
    width: small ? '6.6px' : '10px',
    height: small ? '8.5px' : '13px',
  };
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 10 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="2_2.Campaign_Photos_Empty"
          transform="translate(-399.000000, -98.000000)"
          fill={color}
        >
          <g id="Nav" transform="translate(310.000000, 97.500000)">
            <g id="Group">
              <path
                d="M97.3127638,6.2367485 L97.3127638,4.26818596 C97.3127638,2.46342035 95.849589,1.0002455 94.04499,1.0002455 C94.022495,1.0002455 94.0061653,0.998912464 93.9840036,0.999578982 C93.9658409,0.999245723 93.9426794,1.00057876 93.9241835,1.00057876 C92.1192513,1.00057876 90.6567429,2.46392024 90.6567429,4.26851922 L90.6567429,6.2367485 L89.5,6.2367485 L89.5,12.9957465 L98.5,12.9957465 L98.5,6.2367485 L97.3127638,6.2367485 L97.3127638,6.2367485 Z M92.2913797,4.26851922 C92.2913797,3.36821928 93.0240502,2.63604866 93.9241835,2.63604866 C93.9498445,2.63604866 94.0039991,2.63471562 94.0039991,2.63471562 C94.0039991,2.63471562 94.0313264,2.6357154 94.04499,2.6357154 C94.9451233,2.6357154 95.6777938,3.36788603 95.6777938,4.26818596 L95.6777938,6.2367485 L92.2913797,6.2367485 L92.2913797,4.26851922 L92.2913797,4.26851922 Z"
                id="Fill-468"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Lock.propTypes = {
  color: PropTypes.string,
  small: PropTypes.bool,
};

export default Lock;
