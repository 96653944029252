import React, {ReactNode} from 'react';
import Tooltip from 'rc-tooltip';
import type {TooltipProps} from 'rc-tooltip/lib/Tooltip';
import './style.css';

type Props = {
  trigger?: TooltipProps['trigger'];
  placement?: TooltipProps['placement'];
  overlay: TooltipProps['overlay'];
  children: ReactNode | ReactNode[];
} & Omit<TooltipProps, 'overlay' | 'trigger' | 'placement'>;

const TTip: React.FC<Props> = ({
  children,
  overlay,
  trigger = 'hover',
  placement = 'left',
  ...rest
}) => {
  return (
    <Tooltip
      placement={placement}
      trigger={Array.isArray(trigger) ? trigger : [trigger]}
      overlay={overlay}
      {...rest}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default TTip;
