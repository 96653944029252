import compose from 'lodash/flowRight';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import classNames from 'classnames';
import moment from 'moment';
import * as qs from 'query-string';

import {PaperPlane, MaterialDesignIcon} from 'components/Icons';
import {Tooltip, LoaderDots} from 'components/Widgets';

import {ENTER_KEY_CODE, BLOCK_OPTIONS, COOLDOWN_OPTIONS} from 'consts/variables';

import {useStyles} from './styles';
import {black90} from '../../../../consts/brand.integration';

interface Props {
  influencer: Influencer;
  enableInfluencer: (id: string) => void;
  disableInfluencer: (id: string) => void;
  reviewInfluencer: (id: string) => void;
  verifyInfluencer: (id: string) => void;
  unverifyInfluencer: (id: string) => void;
  cooldownInfluencer: (id: string) => void;
  cancelInfluencerCooldown: (id: string) => void;
  loading: boolean;
}

const InfluencerState: React.FC<Props> = ({
  influencer,
  enableInfluencer,
  disableInfluencer,
  reviewInfluencer,
  verifyInfluencer,
  unverifyInfluencer,
  cooldownInfluencer,
  cancelInfluencerCooldown,
  loading,
}) => {
  const classes = useStyles();

  if (loading || !influencer) {
    return (
      <div className={classNames([classes.root, classes.loading])}>
        <LoaderDots />
      </div>
    );
  }

  const classnames = classNames({
    [classes.root]: true,
    [classes.verified]: influencer.state === 'verified',
    [classes.unverified]: influencer.state === 'unverified',
    [classes.cooldown]: influencer.state === 'cooldown',
    [classes.disabled]: influencer.state === 'disabled',
  });

  const handleInput = (cb: (value: string) => void) => {
    const {value} = document.getElementsByClassName(classes.input)[0];
    if (value) {
      cb(value);
      document.getElementsByClassName(classes.input)[0].value = '';
    }
  };

  const renderIcon = (influencerState: string) => {
    switch (influencerState) {
      case 'new':
        return <MaterialDesignIcon icon="help" />;
      case 'verified':
        return <MaterialDesignIcon icon="verified_user" />;
      case 'reviewed':
        return <MaterialDesignIcon icon="check_circle" />;
      case 'cooldown':
        return <MaterialDesignIcon icon="timer" />;
      case 'cancel_cooldown':
        return <MaterialDesignIcon icon="timer_off" />;
      case 'disabled':
        return <MaterialDesignIcon icon="block" />;
      default:
        return null;
    }
  };

  const getLine = (
    name: string,
    influencerState: any,
    details: any,
    click?: () => void,
    options?: any
  ) => {
    return (
      <li key={name} onClick={click}>
        <div className={`${classes[`${influencerState}__hover`]} ${classes.stateOptionWrapper}`}>
          <p className={classes.stateName}>
            {renderIcon(influencerState)} {name}
          </p>
          <div className={classes.stateDescription}>
            <p>{details}</p>
            {options}
          </div>
        </div>
      </li>
    );
  };

  const review = getLine('Review', 'reviewed', 'Accept influencer to the platform', () =>
    reviewInfluencer(influencer.id)
  );

  const verify = getLine('Verify', 'verified', 'This is a trusted influencer', () =>
    verifyInfluencer(influencer.id)
  );

  const unverify = getLine(
    'Unverify',
    'reviewed',
    'Limited to a campaign reward of £40 regardless of followers count',
    () => unverifyInfluencer(influencer.id)
  );

  const disableclick = reason => {
    disableInfluencer(influencer.id, reason);
  };

  const disable = getLine(
    'Disable',
    'disabled',
    "Can't see or reserve campaigns",
    () => document.getElementsByClassName(classes.input)[0].focus(),
    <div>
      <ul className={classes.blockOptionsWrapper}>
        {BLOCK_OPTIONS.map((option: string) => (
          <li key={option} className={classes.blockOption} onClick={() => disableclick(option)}>
            {option}
          </li>
        ))}
      </ul>
      <div className={classes.inputWrapper}>
        <input
          className={classes.input}
          type="text"
          placeholder="Reason for disabling"
          onKeyDown={e => e.key === ENTER_KEY_CODE && handleInput(disableclick)}
        />
        <div className={classes.inputButton} onClick={() => handleInput(disableclick)}>
          <PaperPlane color={black90} />
        </div>
      </div>
    </div>
  );

  const cooldown = getLine(
    'Cooldown',
    'cooldown',
    "Can't see or reserve campaigns for a limited amount of time.",
    undefined,
    <div>
      <ul className={classes.blockOptionsWrapper}>
        {COOLDOWN_OPTIONS.map((option: any) => (
          <li
            key={option.days}
            className={classes.blockOption}
            onClick={() => {
              cooldownInfluencer(influencer.id, option.days);
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );

  const cancelCooldown = getLine(
    'Cancel cooldown',
    'cancel_cooldown',
    'Set this influencer back to reviewed state and cancel the cooldown.',
    () => cancelInfluencerCooldown(influencer.id)
  );

  const enable = getLine('Enable', 'enabled', 'Can see and reserve campaigns.', () =>
    enableInfluencer(influencer.id)
  );

  function getOptions(influencerState: string) {
    switch (influencerState) {
      case 'new':
        return [review, disable];
      case 'disabled':
        return [enable];
      case 'reviewed':
        return [verify, disable, cooldown];
      case 'verified':
        return [unverify, disable, cooldown];
      case 'cooldown':
        return [cancelCooldown];
      default:
        return [];
    }
  }

  const options = (
    <div className={classes.optionsWrapper}>
      <ul>{getOptions(influencer.state!)}</ul>
    </div>
  );

  const formatState = (state: string) => {
    switch (state) {
      case 'cooldown':
        return `On cooldown (${moment(influencer.cooldownEnds).fromNow(true)} remaining)`;
      default:
        return state;
    }
  };

  return (
    <div>
      <Tooltip placement="bottom" trigger="click" overlay={options}>
        <div className={classnames}>
          {renderIcon(influencer.state!)}
          {formatState(influencer.state!)}
          {influencer.disabledReason && (
            <Tooltip overlay={<span>{influencer.disabledReason}</span>}>
              <p>: {influencer.disabledReason}</p>
            </Tooltip>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

const InfluencerStateQuery = gql`
  query InfluencerStateQuery($id: UUIDString!) {
    influencer(id: $id) {
      id
      state
      cooldownEnds
      disabledReason
    }
  }
`;

// prettier-ignore
export default compose(
  withRouter,
  graphql(InfluencerStateQuery, {
    options: ({ location }: {location: any}) => {
      const params = qs.parse(location.search);
      if (!params.influencerId) {
        const idFromPathname = location.pathname.split('/')[2];
        params.influencerId = idFromPathname ? idFromPathname : '';
      }
      return ({
      variables: {
          id: params.influencerId,
      },
    })},
    props: compose(
      ({data: {influencer, loading}}) => ({
        influencer, loading
      }),
    )
  }),
  graphql(gql`
    mutation verifyInfluencer($id: UUIDString!) {
      verifyInfluencer(id: $id) {
        ok
        influencer {
          id
          state
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      verifyInfluencer: (id: string) => mutate({
        variables: {
          id,
        },
        optimisticResponse: {
          verifyInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'verified'
            }
          }
        },
      }),
    }),
  }),
  graphql(gql`
    mutation unverifyInfluencer($id: UUIDString!) {
      unverifyInfluencer(id: $id) {
        ok
        influencer {
          id
          state
        }
      }
    }
  `, {
    props: ({ mutate }) => ({
      unverifyInfluencer: (id: string) => mutate({
        variables: {
          id,
        },
        optimisticResponse: {
          unverifyInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'reviewed'
            }
          }
        }
      }),
    }),
  }),
  graphql(gql`
    mutation reviewInfluencer($id: UUIDString!) {
      reviewInfluencer(id: $id) {
        ok
        influencer {
          id
          state
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      reviewInfluencer: (id: string) => mutate({
        variables: {
          id,
        },
        optimisticResponse: {
          reviewInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'reviewed'
            }
          }
        }
      }),
    }),
  }),
  graphql(gql`
    mutation enableInfluencer($id: UUIDString!) {
      enableInfluencer(id: $id) {
        ok
        influencer {
          id
          state
          disabledReason
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      enableInfluencer: (id: string) => mutate({
        variables: {
          id,
        },
        optimisticResponse: {
          enableInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'reviewed',
              disabledReason: '',
            }
          }
        }
      }),
    }),
  }),
  graphql(gql`
    mutation disableInfluencer($id: UUIDString!, $reason: String!) {
      disableInfluencer(id: $id, reason: $reason) {
        ok
        influencer {
          id
          state
          disabledReason
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      disableInfluencer: (id: string, reason: string) => mutate({
        variables: {
          id,
          reason,
        },
        optimisticResponse: {
          disableInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'disabled'
            }
          }
        }
      }),
    }),
  }),
  graphql(gql`
    mutation cooldownInfluencer($id: UUIDString!, $days: Int!) {
      cooldownInfluencer(id: $id, days: $days) {
        ok
        influencer {
          id
          state
          cooldownEnds
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      cooldownInfluencer: (id: string, days: string) => mutate({
        variables: {
          id,
          days,
        },
        optimisticResponse: {
          cooldownInfluencer: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'cooldown'
            }
          }
        }
      }),
    }),
  }),
  graphql(gql`
    mutation cancelInfluencerCooldown($id: UUIDString!) {
      cancelInfluencerCooldown(id: $id) {
        ok
        influencer {
          id
          state
          cooldownEnds
        }
      }
    }
  `, {
    props: ({mutate}) => ({
      cancelInfluencerCooldown: (id: string) => mutate({
        variables: {
          id,
        },
        optimisticResponse: {
          cancelInfluencerCooldown: {
            ok: true,
            influencer: {
              __typename: 'Influencer',
              id,
              state: 'reviewed'
            }
          }
        }
      }),
    }),
  }),
)(InfluencerState);
