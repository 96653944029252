import PropTypes from 'prop-types';
import React from 'react';

const ChatBubble = ({color = '#000000', size = 11}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 11 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Report-–-FrontPage" transform="translate(-79.000000, -752.000000)" fill={color}>
      <path
        d="M87.0745785,761.719449 C86.3064516,762.101831 85.4301928,762.318442 84.5,762.318442 C81.4624339,762.318442 79,760.00858 79,757.159221 C79,754.309862 81.4624339,752 84.5,752 C87.5375661,752 90,754.309862 90,757.159221 C90,758.121714 89.7190269,759.022647 89.2298237,759.793786 L89.96392,763 L87.0745785,761.719449 L87.0745785,761.719449 Z"
        id="Comment"
      />
    </g>
  </svg>
);

ChatBubble.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default ChatBubble;
