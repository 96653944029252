import {gql} from '@apollo/client';

const UserFragment = gql`
  fragment campaignSetupUserFields on User {
    __typename
    id
    fullName
    email
    roleName
    profilePicture
  }
`;

export const ForceArchiveCampaignMutation = gql`
  mutation ForceArchiveCampaignMutation($id: UUIDString!) {
    forceStashCampaign(campaignId: $id) {
      ok
      campaign {
        id
        archived
        state
      }
    }
  }
`;

export const CampaignManagersQuery = gql`
  query CampaignManagersQuery {
    owners: users(
      roleName: ["sales_director", "account_manager", "hybrid_account_campaign_manager"]
    ) {
      edges {
        node {
          id
          ...campaignSetupUserFields
        }
      }
    }
    campaignManagers: users(roleName: "campaign_manager") {
      edges {
        node {
          id
          ...campaignSetupUserFields
        }
      }
    }
  }
  ${UserFragment}
`;

// TODO: REMOVE
export const PaymentTerms = gql`
  query IntrospectionQuery {
    __type(name: "PaymentTerms") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GetRewardSuggestionMultiplatformQuery = gql`
  query SuggestedReward(
    $listPrice: Int!
    $marketSlug: String!
    $rewardModel: RewardModel!
    $shippingRequired: Boolean
    $targetInstagram: Int
    $targetTiktok: Int
    $targetYoutube: Int
    $targetTwitch: Int
    $targetContent: Int
  ) {
    suggestedReward: getRewardSuggestionMultiplatform(
      listPrice: $listPrice
      marketSlug: $marketSlug
      rewardModel: $rewardModel
      shippingRequired: $shippingRequired
      targetInstagram: $targetInstagram
      targetTiktok: $targetTiktok
      targetYoutube: $targetYoutube
      targetTwitch: $targetTwitch
      targetContent: $targetContent
    ) {
      value
      formattedValue
    }
  }
`;
