import PropTypes from 'prop-types';
import React from 'react';

const Bookmark = ({color = '#000000', size = 11}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Report-–-FrontPage" fill={color}>
      <path d="M19 24l-7-6-7 6v-24h14v24z" />
    </g>
  </svg>
);

Bookmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Bookmark;
