import {Grid, MenuItem, Select, Tooltip, withStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Control} from 'react-redux-form';
import {KeyboardArrowDown, Lock} from '@material-ui/icons';

import withCustomControl from '../../../../../../components/Widgets/Forms/CustomControl';
import {theme} from '../../../../../../consts/theme';
import {useStyles} from './styles';

const SelectMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  },
})(MenuItem);

type Value = string | number;
type Option = {name: string; value: Value};

interface Props {
  id: string;
  options: Option[];
  defaultOption: Option;
  value: Value;
  canBeEmpty: boolean;
  ariaLabelledby: string;
  onChange: (value: Value) => void;
  disabled: boolean;
  disabledReason: string;
}

const CustomSelect: React.FC<Props> = ({
  id,
  options,
  defaultOption,
  value,
  canBeEmpty,
  ariaLabelledby,
  onChange,
  disabled,
  disabledReason,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (options) {
      if (!canBeEmpty) {
        if (value === null || typeof value === 'undefined' || value === '') {
          onChange(options[0].value);
        }
      }
    }
  }, [options, canBeEmpty, value]);

  const getSelectedOption = (value: string) => {
    return options.filter((x: any) => x.value.toString() === value.toString())[0];
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
        <Select
          displayEmpty
          disabled={disabled}
          value={value || ''}
          variant="outlined"
          className={classes.select}
          inputProps={{
            className: classes.inputText,
            'aria-labelledby': ariaLabelledby,
            id: id,
          }}
          IconComponent={props => <KeyboardArrowDown {...props} className={classes.icon} />}
          onChange={(event: any) => {
            const selectedOption = getSelectedOption(event.target.value);
            onChange(selectedOption?.value || '');
          }}
        >
          <SelectMenuItem value="" disabled>
            {defaultOption}
          </SelectMenuItem>
          {(options ?? []).map((option: any) => (
            <SelectMenuItem key={option.value} value={option.value}>
              {option.name}
            </SelectMenuItem>
          ))}
        </Select>
      </Grid>
      {disabled && (
        <Grid item style={{alignSelf: 'center'}}>
          <Tooltip title={disabledReason ? disabledReason : ''} arrow>
            <Lock />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export const ControlSelect = withCustomControl(props => (
  <Control.select component={CustomSelect} {...props} />
));
