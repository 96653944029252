import {makeStyles} from '@material-ui/core/styles';

import {black, white} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles({
  chip: {
    borderRadius: 2,
    backgroundColor: black,
    display: 'flex',
    height: '26px',
    paddingLeft: '8px',
    margin: '7.5px 15px 7.5px 0',
  },
  chipLabel: {
    color: white,
    marginRight: '4px',
    paddingTop: 5,
  },
  crossButton: {
    color: white,
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
});
