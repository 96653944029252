import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

import {useStyles} from './style';
import {IFilters} from './types';

const BrandSearch: React.FC<IFilters> = ({advertiserNames = [], filters, setFilters}) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);

  const onChange = (event: any, newValue: string | null, reason: string): void => {
    setIsOpened(reason !== 'clear');
    setFilters({
      ...filters,
      searchAdvertiser: newValue,
    });
  };

  return (
    <Autocomplete
      id="brand-search-autocomplete"
      openOnFocus
      options={advertiserNames}
      value={filters.searchAdvertiser}
      closeIcon={
        <div role="button" tabIndex={0} className={classes.clearButton}>
          <CloseIcon fontSize="small" />
        </div>
      }
      classes={{
        inputRoot: classes.inputRoot,
        option: classes.option,
        paper: classes.paper,
        clearIndicator:
          isOpened && filters.searchAdvertiser !== null ? classes.clearIndicator : undefined,
      }}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'brand search input',
          }}
          placeholder="All brands"
          variant="outlined"
        />
      )}
      onChange={onChange}
    />
  );
};

export default BrandSearch;
