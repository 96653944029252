import React from 'react';
import {Link} from 'react-router-dom';
import * as qs from 'query-string';

import {FilterItem} from '../../../../../components/Widgets';
import styles from './style.css';

const nameForFilter = (filter: string): string => {
  switch (filter) {
    case 'requested':
      return 'Interested';
    case 'candidate':
      return 'Pending approval';
    case 'approved_by_brand':
      return 'Approved by brand';
    case 'invited':
    case 'accepted':
    case 'rejected':
      return filter;
    default:
      throw new Error(`Unknown filter ${filter}`);
  }
};

type FilterCount = {
  type: string;
  count: number;
};

type Props = {
  location: {
    search: string;
  };
  campaignFilters: string[];
  filterCounts: FilterCount[];
};

const Filters = ({campaignFilters, filterCounts, location}: Props): JSX.Element => {
  const params = qs.parse(location.search);
  const currentFilter = params.state || 'accepted';
  const rejectedFilters = ['rejected', 'revoked', 'rejected_by_brand'];
  const progressFilters = campaignFilters.filter(filter => !rejectedFilters.includes(filter));

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.left}>
          {progressFilters.map(filter => (
            <Link
              key={filter}
              to={location => ({
                ...location,
                search: qs.stringify({...params, state: filter}),
              })}
            >
              <FilterItem small checked={filter === currentFilter}>
                {nameForFilter(filter)}
                <span className={styles.count}>
                  {(filterCounts.find((p: FilterCount) => p.type === filter) || {count: 0}).count}
                </span>
              </FilterItem>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filters;
