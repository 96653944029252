import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {NavLink, useHistory} from 'react-router-dom';
import {Avatar, ClickAwayListener, ListItem} from '@material-ui/core';
import {useApolloClient, useReactiveVar} from '@apollo/client';
import List from '@material-ui/core/List';

import {endUserImpersonation, isImpersonating} from 'services/impersonation';

import {User} from '../types';
import defaultAvatar from '../img/defaultAvatar.png';
import ErrorReporting from '../../../../../services/error-reporting';
import {useStyles} from './styles';
import {removeStoredApiToken} from '../../../../../services/auth';

interface Props {
  user: User;
  mobileOpen: boolean;
}

export const ProfileMenu: React.FC<Props> = ({user, mobileOpen}: Props) => {
  const classes = useStyles({mobileOpen});
  const client = useApolloClient();
  const history = useHistory();
  const [isAccordionOpen, setAccordionOpen] = React.useState(false);

  const handleLogout = async () => {
    await client.clearStore();
    ErrorReporting.setUserContext({});
    removeStoredApiToken();
    history.push('/login');
  };

  const handleClickAway = () => {
    setAccordionOpen(false);
  };
  const handleAccordionClick = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const isCurrentlyImpersonating = useReactiveVar(isImpersonating);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Accordion expanded={isAccordionOpen} classes={{root: classes.profileAccordionRoot}}>
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
              expanded: classes.accordionSummaryContentExpanded,
            }}
            aria-controls="panel1a-content"
            aria-label="User menu"
            id="panel1a-header"
            onClick={handleAccordionClick}
          >
            <div className={classes.profileWrapper}>
              <Avatar
                alt="Profile Picture"
                src={user.profilePicture}
                style={{marginBottom: '16px'}}
              >
                <Avatar alt="Default Avatar" src={defaultAvatar} />
              </Avatar>
              <div className={classes.fullName}>
                {isCurrentlyImpersonating && (
                  <span className={classes.impersonationWarning}>Impersonating</span>
                )}
                {user.fullName}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.accordionDetails}}>
            <span className={classes.profileEmail}>{user.email}</span>
            <List component="nav" aria-label="profile menu">
              {isCurrentlyImpersonating && (
                <ListItem
                  disableGutters
                  classes={{root: classes.multiListItemRoot}}
                  onClick={endUserImpersonation}
                >
                  <button
                    className={`${classes.profileAccordionItem} ${classes.buttonListItemButton}`}
                  >
                    <span className={classes.buttonListItemLabel}>Stop impersonating</span>
                  </button>
                </ListItem>
              )}
              <ListItem
                disableGutters
                classes={{root: classes.multiListItemRoot}}
                onClick={handleClickAway}
              >
                <NavLink
                  className={classes.profileAccordionItem}
                  activeClassName={classes.activeLink}
                  to="/user/edit"
                >
                  Manage account
                </NavLink>
              </ListItem>
              <ListItem
                disableGutters
                classes={{root: classes.multiListItemRoot}}
                onClick={handleClickAway}
              >
                <NavLink
                  className={classes.profileAccordionItem}
                  activeClassName={classes.activeLink}
                  to="/user/help"
                >
                  Get help
                </NavLink>
              </ListItem>
              <ListItem
                classes={{root: classes.multiListItemRoot}}
                disableGutters
                className={classes.buttonListItem}
                onClick={handleLogout}
              >
                <button
                  className={`${classes.profileAccordionItem} ${classes.buttonListItemButton}`}
                >
                  <span className={classes.buttonListItemLabel}>Sign out</span>
                </button>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
    </ClickAwayListener>
  );
};
