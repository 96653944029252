import {makeStyles} from '@material-ui/styles';

import {black} from '../../../../../consts/brand.integration';
import {theme} from '../../../../../consts/theme';

export const useStyles = makeStyles({
  root: {
    display: 'inline-block;',
    width: '100%',
    color: black,
    '&$checked': {
      color: black,
    },
  },
  formControls: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    color: black,
    '&$checked': {
      color: black,
    },
    margin: '18px 200px 18px 18px ',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '80%',
  },
  button: {
    backgroundColor: theme.palette['text-inverse'],
    border: `1px solid ${theme.palette['text-01']}`,
    color: theme.palette['text-01'],
    borderRadius: 0,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette['text-inverse'],
    },
  },
  buttonText: {
    whiteSpace: 'nowrap',
  },
  control: {
    color: theme.palette['ui-05'],
    '&$checked': {
      color: theme.palette['ui-07'],
    },
    '&:hover': {
      color: theme.palette['ui-07'],
    },
  },
  blackTextColor: {
    color: theme.palette['ui-07'],
  },
  gridItem: {
    paddingTop: 23,
  },
  gridContainer: {
    marginTop: 35,
  },
  filterParameter: {
    color: theme.palette['text-02'],
  },
  legend: {
    display: 'none',
  },
});
