import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import {withRouter} from 'react-router-dom';
import * as qs from 'query-string';

import {Table, ProfilePicture, RelativeDate} from 'components/Widgets';
import {DefaultRegionPreferences} from 'components/Region';

import {withPagination} from 'services/graphql';
import {numberWithCommas, singularOrPlural} from 'consts/utilities';

import styles from './live.css';

class LiveCampaigns extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    pending: PropTypes.bool,
    data: PropTypes.object,
    filter: PropTypes.oneOf(['all', 'draft', 'launched', 'completed']),
  };

  userColumns = [
    {
      heading: '',
      data: data => data.user,
      render: user => (
        <div className={styles.advertiser}>
          <ProfilePicture src={user.profilePicture} />
          <div className={styles.advertiserName}>{user.fullName}</div>
        </div>
      ),
    },
    {
      heading: 'Number of assigned campaigns',
      data: data => data.count,
    },
  ];

  campaignColumns = [
    {
      heading: 'Brand',
      data: campaign => campaign.advertiser,
      render: advertiser => (
        <div className={styles.advertiser}>
          <ProfilePicture square src={advertiser.profilePicture} size={40} />
          <div className={styles.advertiserName}>{advertiser.name}</div>
        </div>
      ),
    },
    {
      heading: 'Name',
      data: campaign => campaign.name,
    },
    {
      heading: 'Launched',
      data: campaign => campaign.started,
      render: date => <RelativeDate date={date} />,
    },
    {
      heading: 'Sales',
      data: campaign => campaign.owner.profilePicture,
      render: profilePicture =>
        profilePicture ? (
          <div className={styles.picture}>
            <ProfilePicture src={profilePicture} />
          </div>
        ) : null,
      width: '90px',
    },
    {
      heading: 'Campaign',
      data: campaign => campaign.campaignManager?.profilePicture,
      render: profilePicture =>
        profilePicture ? (
          <div className={styles.picture}>
            <ProfilePicture src={profilePicture} />
          </div>
        ) : null,
      width: '90px',
    },
  ];

  render() {
    const {data} = this.props;
    return (
      <div className={styles.root}>
        <DefaultRegionPreferences />

        {data.campaigns && (
          <div>
            <div className={styles.campaignCount} style={{margin: '60px 0 30px 0'}}>
              {`${numberWithCommas(data.campaigns.count)} ${singularOrPlural(
                'campaign',
                data.campaigns.count
              )}`}
            </div>
            <Table
              columns={this.campaignColumns}
              data={data.campaigns.edges.map(edge => edge.node)}
              emptyMessage="No campaigns"
            />
          </div>
        )}

        {data.usersByAssignedCampaignCount && (
          <Table
            columns={this.userColumns}
            data={data.usersByAssignedCampaignCount}
            emptyMessage="Couldn't load users"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, {location}) => {
  const params = qs.parse(location.search);
  const filter = params.filter || 'launched';
  return {
    filter,
    region: params.region,
  };
};

const LiveCampaignsQuery = gql`
  query LiveCampaignsQuery($cursor: String, $region: UUIDString) {
    campaigns(first: 500, after: $cursor, state: "launched", regionId: $region) {
      edges {
        node {
          id
          name
          started
          advertiser {
            id
            name
            profilePicture
          }
          owner {
            id
            profilePicture
          }
          campaignManager {
            id
            profilePicture
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
    usersByAssignedCampaignCount {
      user {
        id
        fullName
        profilePicture
      }
      count
    }
  }
`;

export default compose(
  withRouter,
  connect(mapStateToProps),
  graphql(LiveCampaignsQuery, {
    props: withPagination(LiveCampaignsQuery, 'campaigns'),
    options: {
      pollInterval: 60000,
    },
  })
)(LiveCampaigns);
