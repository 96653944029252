import React from 'react';

const Umbrella = ({color = 'black', ariaLabel = ''}) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0179844 8.93672C0.488344 6.42119 1.79122 4.15339 3.70292 2.52266C5.61463 0.89193 8.0159 0 10.4945 0C12.9731 0 15.3744 0.89193 17.2861 2.52266C19.1978 4.15339 20.5006 6.42119 20.971 8.93672C21.0091 9.09669 21.0097 9.26375 20.9727 9.42401C20.9357 9.58426 20.8622 9.73306 20.7583 9.85802C20.6595 9.98423 20.5347 10.086 20.3929 10.1559C20.2512 10.2258 20.0961 10.2621 19.939 10.2621H11.5891V19.0226C11.5907 19.4141 11.5173 19.802 11.3732 20.164C11.229 20.526 11.0169 20.8549 10.7491 21.1317C10.4813 21.4085 10.1632 21.6277 9.81298 21.7767C9.46278 21.9258 9.0875 22.0016 8.7088 21.9999C8.32863 22.0033 7.9516 21.9287 7.59957 21.7802C7.24754 21.6318 6.92754 21.4126 6.65813 21.1354C6.38872 20.8581 6.17528 20.5282 6.03018 20.165C5.88509 19.8018 5.81123 19.4124 5.8129 19.0194C5.82769 18.7369 5.94666 18.4711 6.14532 18.2765C6.34398 18.082 6.60723 17.9736 6.88088 17.9736C7.15453 17.9736 7.41777 18.082 7.61644 18.2765C7.8151 18.4711 7.93407 18.7369 7.94886 19.0194C7.94886 19.2243 8.02761 19.4208 8.16778 19.5657C8.30795 19.7106 8.49806 19.792 8.69629 19.792C8.89452 19.792 9.08463 19.7106 9.2248 19.5657C9.36497 19.4208 9.44372 19.2243 9.44372 19.0194V10.2621H1.05313C0.89679 10.2578 0.743069 10.2196 0.601921 10.15C0.460772 10.0804 0.335346 9.98094 0.23377 9.85802C0.137794 9.72841 0.0690289 9.57953 0.0318737 9.42089C-0.00528145 9.26226 -0.0100117 9.09735 0.0179844 8.93672ZM18.5661 8.0542C17.975 6.33208 16.8823 4.84149 15.4384 3.78733C13.9944 2.73317 12.2701 2.16719 10.5023 2.16719C8.73455 2.16719 7.01018 2.73317 5.56623 3.78733C4.12229 4.84149 3.02965 6.33208 2.43853 8.0542H18.5661Z"
      fill={color}
    />
  </svg>
);

export default Umbrella;
