import {gql} from '@apollo/client';

import {Campaign} from 'services/fragments';

export const CampaignPostsQuery = gql`
  query CampaignPostsQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      archived
      description
      dashboardCampaignStatsUrl
      requireInsights
      insightsCount
      advertiser {
        id
        domain
      }
      emojis {
        value
        count
      }
      hashtags {
        value
        count
      }
      commentSentiment {
        formattedValue
      }
      captionSentiment {
        formattedValue
      }
      costPerEngagement {
        formattedValue
      }
      projectedCostPerEngagement {
        formattedValue
      }
      reach
      likes
      comments
      engagements
      impressions
      videoViews
      videoEngagement {
        formattedValue
      }
      engagement {
        formattedValue
      }
      rewardModel
      reportToken
      submissionsToken
      insightsToken
      state
      price {
        formattedValue
      }
      listPrice {
        formattedValue
      }
      tags
      report {
        insights
        influencers
        submissions
        assets
        followers
        combinedFollowers
        cpe
      }
      posts {
        id
        archived
        opened
        deadline
        submissionDeadline
        instructions
        schedule {
          submissionDeadline
        }
        brief {
          __typename
        }
        price {
          value
          formattedValue
        }
        gigs {
          id
          instagramContent {
            __typename
            id
          }
        }
        postType
        report {
          __typename
          assets
          submissions
          processedSubmissions
          followers
          reach
          profileVisits
          websiteClicks
          frequency
          ... on StandardPostReport {
            engagements {
              likes
              comments
              saves
              replies
              shares
              total
            }
            engagementRate {
              value
              formattedValue
            }
            impressions {
              home
              profile
              hashtags
              location
              explore
              other
              total
            }
            projectedCpe {
              value
              formattedValue
            }
          }
          ... on VideoPostReport {
            engagements {
              likes
              comments
              saves
              replies
              shares
              total
            }
            engagementRate {
              value
              formattedValue
            }
            impressions {
              home
              profile
              hashtags
              other
              total
            }
            projectedCpv {
              value
              formattedValue
            }
            videoViews
            videoViewRate {
              formattedValue
            }
          }
          ... on StoryPostReport {
            storyImpressions
            actions {
              linkClicks
              profileVisits
              websiteClicks
              stickerTaps
              replies
              shares
              total
            }
          }
        }
      }
      photos {
        coverPhoto {
          url
        }
      }
      targeting {
        regions {
          id
        }
        ages
        interests {
          id
        }
      }
      ...targetFields
      reportToken
      totalSubmissions
    }
  }
  ${Campaign.targetFragment}
`;

export const RenewTokenMutation = gql`
  mutation RenewTokenMutation($campaignId: UUIDString!) {
    generateCampaignReportToken(id: $campaignId) {
      campaign {
        id
        reportToken
      }
    }
  }
`;

export const CreatePostMutation = gql`
  mutation CreatePostMutation($campaignId: UUIDString!) {
    createPost(campaignId: $campaignId) {
      ok
      post {
        id
        postType
      }
    }
  }
`;

export const UpdateTagsMutation = gql`
  mutation UpdateTagsMutation($campaignId: UUIDString!, $tags: [String]) {
    updateCampaign(id: $campaignId, tags: $tags) {
      ok
      campaign {
        id
        tags
      }
    }
  }
`;
