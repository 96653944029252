import React from 'react';

import {TilesProps} from './types';
import CampaignTile from '../../../components/Widgets/CampaignTile';
import {RowWrap} from '../../../components/Widgets';
import {useStyles} from './styles';

export const CampaignsTiles: React.FC<TilesProps> = ({campaigns}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <RowWrap>
        {campaigns.map((campaign, index) => {
          return <CampaignTile key={index} campaign={campaign} />;
        })}
      </RowWrap>
    </div>
  );
};
export default CampaignsTiles;
