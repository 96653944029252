import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import * as qs from 'query-string';
import {withRouter} from 'react-router-dom';

import {LoaderDots} from 'components/Widgets';

import CampaignSetup from '../components/CampaignSetup';
import {Campaign} from '../../../services/fragments';

const getDefaults = location => {
  const rewardModel = location.state?.model || 'assets';
  return {
    rewardModel,
    units: 0,
    marketSlug: location.state?.market || 'uk',
    shippingRequired: false,
    prompts: [],
  };
};

const NewCampaignSetup = ({loading, createCampaign, location, baseReward, baseRewardLoading}) => {
  if (baseRewardLoading || baseReward === undefined) {
    return <LoaderDots />;
  }

  const campaign = {
    ...getDefaults(location),
    name: 'New Campaign',
    state: 'new',
    price: {
      value: 0,
    },
    listPrice: {
      value: 0,
    },
    customRewardUnits: parseInt(baseReward * 100),
    hasCustomReward: false,
    organicPerformanceMetrics: [],
    paidPerformanceMetrics: [],
    cpmBenchmark: {
      value: 0,
    },
  };

  return <CampaignSetup loading={loading} campaign={campaign} updateCampaign={createCampaign} />;
};

NewCampaignSetup.propTypes = {
  loading: PropTypes.bool,
  campaign: PropTypes.object,
  createCampaign: PropTypes.func.isRequired,
  baseReward: PropTypes.number,
  baseRewardLoading: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      advertiserId: PropTypes.string.isRequired,
    }),
    state: PropTypes.shape({
      market: PropTypes.string,
      model: PropTypes.string,
    }),
  }),
};

const UserFragment = gql`
  fragment createCampaignUserFields on User {
    __typename
    id
    fullName
    roleName
  }
`;

const RewardSuggestionQuery = gql`
  query RewardSuggestionQuery(
    $marketSlug: String!
    $listPrice: Int!
    $rewardModel: RewardModel!
    $units: Int!
    $shippingRequired: Boolean!
  ) {
    getRewardSuggestion(
      marketSlug: $marketSlug
      listPrice: $listPrice
      rewardModel: $rewardModel
      units: $units
      shippingRequired: $shippingRequired
    ) {
      value
    }
  }
`;

const CreateCampaignMutation = gql`
  mutation CreateCampaignMutation(
    $advertiserId: UUIDString!
    $description: String
    $shippingRequired: Boolean
    $requireInsights: Boolean
    $pictures: [PictureInput]
    $industry: String
    $hubspotDealId: String
    $mondayBoardId: String
    $rewardModel: RewardModel!
    $paymentTerms: PaymentTerms
    $name: String
    $targetInstagram: Int
    $targetTiktok: Int
    $targetYoutube: Int
    $targetTwitch: Int
    $targetContent: Int
    $price: Int!
    $listPrice: Int!
    $customRewardUnits: Int
    $campaignManager: UUIDString
    $marketSlug: String!
    $hasNda: Boolean
    $brandSafety: Boolean
    $brandMatch: Boolean
    $proBono: Boolean
    $owner: UUIDString
    $prompts: [Prompt]
    $organicPerformanceMetrics: [String]
    $paidPerformanceMetrics: [String]
    $campaignKpis: [CampaignKpiInput!]
    $cpmBenchmark: Int
  ) {
    createCampaign(
      advertiserId: $advertiserId
      description: $description
      shippingRequired: $shippingRequired
      requireInsights: $requireInsights
      pictures: $pictures
      industry: $industry
      hubspotDealId: $hubspotDealId
      mondayBoardId: $mondayBoardId
      rewardModel: $rewardModel
      paymentTerms: $paymentTerms
      name: $name
      targetInstagram: $targetInstagram
      targetTiktok: $targetTiktok
      targetYoutube: $targetYoutube
      targetTwitch: $targetTwitch
      targetContent: $targetContent
      price: $price
      listPrice: $listPrice
      customRewardUnits: $customRewardUnits
      campaignManager: $campaignManager
      marketSlug: $marketSlug
      hasNda: $hasNda
      brandSafety: $brandSafety
      brandMatch: $brandMatch
      proBono: $proBono
      owner: $owner
      prompts: $prompts
      organicPerformanceMetrics: $organicPerformanceMetrics
      paidPerformanceMetrics: $paidPerformanceMetrics
      campaignKpis: $campaignKpis
      cpmBenchmark: $cpmBenchmark
    ) {
      ok
      campaign {
        id
        archived
        campaignManager {
          id
          ...createCampaignUserFields
        }
        description
        hasNda
        brandSafety
        brandMatch
        proBono
        price {
          formattedValue
          value
        }
        prompts {
          text
          choices
          type
          brandVisible
        }
        listPrice {
          formattedValue
          value
        }
        customRewardUnits
        paymentTerms
        industry
        hubspotDealId
        mondayBoardId
        name
        owner {
          id
          ...createCampaignUserFields
        }
        shippingRequired
        requireInsights
        state
        ...targetFields
        posts {
          id
        }
        paymentTerms
        organicPerformanceMetrics
        paidPerformanceMetrics
        cpmBenchmark {
          formattedValue
          value
        }
      }
    }
  }
  ${UserFragment}
  ${Campaign.targetFragment}
`;

export default compose(
  withRouter,
  graphql(RewardSuggestionQuery, {
    options: ({location}) => ({
      variables: {
        ...getDefaults(location),
        listPrice: 0,
      },
    }),
    props: ({data: {getRewardSuggestion = {}}, loading}) => ({
      baseRewardLoading: loading,
      baseReward: getRewardSuggestion.value,
    }),
  }),
  graphql(CreateCampaignMutation, {
    options: {
      refetchQueries: ['CampaignAdvertisersQuery', 'CampaignList'],
    },
    props: ({
      mutate,
      ownProps: {
        history,
        location,
        match: {params},
      },
    }) => {
      const search = qs.parse(location.search);
      return {
        createCampaign: async fields => {
          const {data} = await mutate({
            variables: {
              ...fields,
              advertiserId: search.advertiserId,
            },
          });
          history.push(`/brands/${params.advertiser}/${data.createCampaign.campaign.id}/setup`);
        },
      };
    },
  })
)(NewCampaignSetup);
