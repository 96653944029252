import {makeStyles} from '@material-ui/core/styles';

import {black, white, black40, mainContentWidth} from '../../../consts/brand.integration';

export const useStyles = makeStyles({
  nav: {
    maxWidth: mainContentWidth,
    margin: '0 auto',
  },
  content: {
    maxWidth: mainContentWidth,
    margin: '24px auto 0',
  },
  header: {
    textAlign: 'right',
    marginBottom: '24px',
  },
  newCampaignBtn: {
    height: 40,
    borderRadius: 0,
    padding: '12px 16px',
    color: white,
    backgroundColor: black,
    '&:hover': {
      backgroundColor: black,
    },
  },
  empty: {
    color: black40,
    margin: '0 auto',
  },
});
