import PropTypes from 'prop-types';
import React from 'react';

import {getThemeColor} from 'services/themer';

const UploadIcon = ({className}) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Untitled-2"
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      enableBackground="new 0 0 17 14"
    >
      <path fill="none" />
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="1_1.Campaign_Create"
          transform="translate(-869.000000, -395.000000)"
          fill={getThemeColor()}
        >
          <g id="Group-+-campaign-details" transform="translate(176.000000, 80.000000)">
            <g
              id="Group-+-Create-Campaign-+-1.-Brief--•--2.-Sche"
              transform="translate(396.000000, 4.000000)"
            >
              <g id="Group" transform="translate(0.000000, 98.000000)">
                <g id="Group-Copy" transform="translate(0.000000, 212.000000)">
                  <g id="Photos-Copy-+-Button-4-Copy-2-Copy">
                    <path
                      d="M305,1 C300.581778,1 297,4.58177778 297,9 C297,13.4184444 300.581778,17 305,17 C309.418222,17 313,13.4184444 313,9 C313,4.58177778 309.418222,1 305,1 L305,1 Z M304.995556,14.0828889 L300.585111,8.98022222 L303.893778,8.98022222 L303.893778,4.10777778 L306.097333,4.10777778 L306.097333,8.98022222 L309.406,8.98022222 L304.995556,14.0828889 L304.995556,14.0828889 Z"
                      id="Fill-1028"
                      transform="translate(305.000000, 9.000000) rotate(-180.000000) translate(-305.000000, -9.000000) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path fill="none" />
    </svg>
  </div>
);

UploadIcon.propTypes = {
  className: PropTypes.string,
};

export default UploadIcon;
