import React from 'react';

import NumericHighlight from '../NumericHighlight';
import ChartHighlight from '../ChartHighlight';
import {CampaignHighlights} from '../../types';
import {useStyles} from './styles';
import {formatEngagementRate, roundRate} from '../../../utils';

interface Props {
  highlights: CampaignHighlights;
}

const Highlights: React.FC<Props> = ({highlights}: Props) => {
  const classes = useStyles();

  const getChartData = (er: number) => {
    return [
      {
        name: 'Total ER',
        value: 100 - er,
      },
      {
        name: 'ER',
        value: er,
      },
    ];
  };

  if (!highlights) return null;

  const roundedTotalER = roundRate(highlights.engagementRate);
  const roundedERStatic = roundRate(highlights.engagementRateStatic);
  const roundedERStaticFromTotal = roundRate(highlights.engagementRateStaticFromTotal);
  const roundedERStory = roundRate(highlights.engagementRateStory);
  const roundedERStoryFromTotal = roundRate(highlights.engagementRateStoryFromTotal);

  const erInFeed =
    roundedERStatic === 0 ? 0 : roundedERStatic === roundedTotalER ? 100 : roundedERStaticFromTotal;

  const erStory =
    roundedERStory === 0 ? 0 : roundedERStory === roundedTotalER ? 100 : roundedERStoryFromTotal;

  return (
    <div className={classes.wrapper}>
      <NumericHighlight
        title="Total ER"
        value={formatEngagementRate(highlights.engagementRate)}
        className={classes.highlight}
      />
      <ChartHighlight
        title="ER in-feed"
        hint="ER of all posts (not stories posts)"
        value={`${roundedERStatic}%`}
        chartData={
          highlights.engagementRateStaticFromTotal !== null &&
          getChartData(roundedERStaticFromTotal)
        }
        chartAriaLabel="Static engagement rate % of total engagement rate"
        valueUnderChart={`${erInFeed}% of total ER`}
        className={classes.highlight}
      />
      <ChartHighlight
        title="ER Story"
        hint="ER of stories"
        value={`${roundedERStory}%`}
        chartData={
          highlights.engagementRateStoryFromTotal !== null && getChartData(roundedERStoryFromTotal)
        }
        chartAriaLabel="Stories engagement rate % of total engagement rate"
        valueUnderChart={`${erStory}% of total ER`}
        className={classes.highlight}
      />
    </div>
  );
};

export default Highlights;
