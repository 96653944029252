import React from 'react';
import moment from 'moment';
import emojiFlag from 'emoji-flag';
import {makeStyles} from '@material-ui/core';

import {ProfilePicture, Badge, FollowerData} from 'components/Widgets';

import {black40, boxShadowBase} from '../../../../../../consts/brand.integration';
import {InfluencerInfluenscoreData} from 'hooks/useDataServiceApi/types';
import {InfluenscoreChartStatic} from 'components/Widgets/InfluenscoreChart/InfluenscoreChartStatic';

interface Props {
  influencer: Influencer & {influenscoreData: InfluencerInfluenscoreData};
}

const getLocation = (region?: Region) => {
  if (region) {
    const location = region.name;
    const country = region.country;
    if (location !== country) {
      return `${location}, ${country}`;
    }
    return location;
  }
  return '';
};

const InfluencerCard: React.FC<Props> = ({influencer}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.profilePictureWrapper}>
        <ProfilePicture src={influencer.profilePicture ?? ''} size={60} />
        <Badge type={influencer.state} />
      </div>
      {influencer.influenscoreData && (
        <div className={classes.influenscoreWrapper}>
          <InfluenscoreChartStatic size={60} values={influencer.influenscoreData} />
        </div>
      )}
      <div className={classes.contentWrapper}>
        <h3 className={classes.userName}>{influencer.fullName}</h3>
        <div className={classes.userMetaWrapper}>
          <div className={classes.userMetaFollowers}>
            <FollowerData
              instagramFollowers={influencer.instagramAccount?.followers}
              tiktokFollowers={influencer.tiktokAccount?.followers}
              youtubeSubscriberCount={influencer.youtubeChannel?.subscriberCount}
              twitchFollowers={influencer.twitchChannel?.followers}
            />
          </div>
          {influencer.isSignedUp ? (
            <div>
              <strong>{moment().diff(influencer.birthday, 'years')}</strong> year old{' '}
              <strong>{influencer.gender}</strong>
              <p>
                <strong>{getLocation(influencer.targetRegion ?? undefined)}</strong>{' '}
                {emojiFlag(influencer.targetRegion?.countryCode)}
              </p>
            </div>
          ) : (
            <>Not signed up</>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '346px',
    height: '140px',
    padding: '3.5rem 1rem',
    borderRadius: '10px',
    boxShadow: boxShadowBase,
  },
  profilePictureWrapper: {
    position: 'absolute',
    top: '.5rem',
    height: '60px',
    width: '60px',
  },
  contentWrapper: {
    marginTop: '1rem',
    marginLeft: '5rem',
    flex: '1',
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userMetaWrapper: {
    fontSize: '12px',
    color: black40,
    marginTop: '.5rem',
  },
  userMetaFollowers: {
    display: 'flex',
    alignItems: 'center',
  },
  userMetaSocialChannel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
    fontWeight: 700,
  },
  '.userMetaSocialChannel:nth-child(2n)': {
    borderLeft: `1px solid ${black40}`,
    maxHeight: '15px',
    paddingLeft: '5px',
  },
  '.userMetaSocialChannel svg': {
    height: '20px',
    width: '20px',
    color: black40,
    marginRight: '3px',
  },
  interestIconsWrapper: {
    display: 'flex',
  },
  interestIcon: {
    display: 'block',
    fontSize: '16px',
    color: '$black40',
  },
  influenscoreWrapper: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
  },
});

export default InfluencerCard;
