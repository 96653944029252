// eslint-disable-next-line import/named
import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black} from 'consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: black,
      width: '1.25em',
    },
  })
);
