import React, {useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';

export interface HeadCell<T> {
  id: keyof T;
  label: string;
  numeric?: boolean;
  align?: TableCellProps['align'];
}

interface TableData<T> {
  rows: T[];
  headCells: HeadCell<T>[];
  renderRow: (row: T, index: number) => JSX.Element;
  sortMetric: keyof T;
  ariaLabel?: string;
  rowCount?: number;
  emptyMessage?: string;
}

interface TableProps<T> {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T;
  headCells: HeadCell<T>[];
  rowCount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (
    a[orderBy] &&
    b[orderBy] &&
    typeof a[orderBy] === 'object' &&
    typeof b[orderBy] === 'object'
  ) {
    if ('raw' in a[orderBy] && 'raw' in b[orderBy]) {
      if (b[orderBy].raw < a[orderBy].raw) {
        return -1;
      }
      if (b[orderBy].raw > a[orderBy].raw) {
        return 1;
      }
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {[key in Key]: number | string | {raw: number; formatted: string}},
  b: {[key in Key]: number | string | {raw: number; formatted: string}}
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const SortableTableHead: React.FC<TableProps<any>> = props => {
  const {headCells, classes, order, orderBy, onRequestSort} = props;
  const createSortHandler =
    <T,>(property: keyof T) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align ?? (headCell.numeric ? 'center' : 'left')}
            sortDirection={orderBy === headCell.id ? order : false}
            aria-label={headCell.label}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SortableTable = <T,>({
  rows,
  headCells,
  sortMetric,
  renderRow,
  ariaLabel,
  rowCount,
  emptyMessage,
}: TableData<T>): React.ReactElement => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState(sortMetric);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowCount || 5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table aria-label={ariaLabel}>
          <SortableTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(() => {
              if (rows.length === 0 && emptyMessage != null)
                return (
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <Typography align="center">{emptyMessage}</Typography>
                    </TableCell>
                  </TableRow>
                );

              return stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => renderRow(row, index));
            })()}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length !== 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'table',
      tableLayout: 'fixed',
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    influencerCell: {
      width: '280px',
      '& svg': {
        marginRight: '2px',
      },
    },
    row: {
      cursor: 'pointer',
    },
  })
);

export default SortableTable;
