import 'core-js';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import {ConfigCatProvider, PollingMode, LogLevel, createConsoleLogger} from 'configcat-react';
import {ApolloProvider} from '@apollo/client';
import {HelmetProvider} from 'react-helmet-async';

import ErrorReporting from './services/error-reporting';
import store, {history} from './store';
import client from './client';
import Router from './Router';
import {CONFIGCAT_POLLING_SECONDS} from './consts/variables';

ErrorReporting.init();

const render = Router => {
  ReactDOM.render(
    <AppContainer>
      <ConfigCatProvider
        sdkKey={process.env.CONFIGCAT_SDK_KEY}
        pollingMode={PollingMode.ManualPoll}
        options={{
          cacheTimeToLiveSeconds: CONFIGCAT_POLLING_SECONDS,
          pollIntervalSeconds: CONFIGCAT_POLLING_SECONDS,
          logger: createConsoleLogger(LogLevel.Error),
        }}
      >
        <ApolloProvider client={client}>
          <HelmetProvider>
            <Router store={store} history={history} />
          </HelmetProvider>
        </ApolloProvider>
      </ConfigCatProvider>
    </AppContainer>,
    document.getElementById('outlet')
  );
};

render(Router);

if (module.hot) {
  module.hot.accept('./Router', () => {
    render(Router);
  });
}
