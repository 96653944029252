import React from 'react';

import useRequiresPermission from 'hooks/useRequiresPermission';

type Props = {
  permission: string;
  hasAccess?: boolean;
};

const RequiresPermission: React.FC<Props> = ({children, permission, hasAccess = true}) => {
  const hasPermission = Boolean(useRequiresPermission(permission));

  if (!hasPermission || !hasAccess) return null;
  return <>{children}</>;
};

export default RequiresPermission;
