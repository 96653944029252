import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Definition, HideInDemoMode, Internal, Tooltip} from 'components/Widgets';

import {capitalize, numberWithCommas} from 'consts/utilities';

import BudgetDefinition from '../BudgetDefinition';

interface Props {
  campaign: Campaign;
}

const CampaignStats: React.FC<Props> = ({campaign}) => {
  const classes = useStyles();

  const hasASubmission = campaign.posts?.some(p => p?.gigs?.length > 0);
  const hasInstagramSubmission = campaign.posts?.some(
    p => p?.postType !== 'tiktok' && p?.postType !== 'youtube'
  );

  return campaign.state !== 'draft' ? (
    <Box className={classes.definitionRow} style={{maxWidth: '75%'}}>
      <Grid container spacing={2}>
        <Grid item>
          <Definition gold title="Type" definition={capitalize(campaign.rewardModel)} />
        </Grid>
        <Grid item>
          <Definition
            gold
            title={campaign.rewardModel}
            definition={numberWithCommas(campaign.target?.targetOverall)}
          />
        </Grid>
        <Grid item>
          <BudgetDefinition budget={campaign.price ? campaign.price.formattedValue : null} />
        </Grid>
      </Grid>
      {hasInstagramSubmission && (
        <Box className={classes.definitionRow}>
          {campaign.report && (
            <Internal>
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip
                    overlay={<span>How many influencer have been accepted into the campaign</span>}
                    mouseEnterDelay={0.2}
                    placement="bottom"
                  >
                    <Definition
                      gold
                      title="Influencers"
                      definition={numberWithCommas(campaign.report.influencers)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    overlay={
                      <span>
                        Total number of submissions to the campaign. <br />
                        The count includes alls gigs that are submitted, reviewed, approved or
                        rejected.
                      </span>
                    }
                    mouseEnterDelay={0.2}
                    placement="bottom"
                  >
                    <Definition
                      gold
                      title="Submissions"
                      definition={numberWithCommas(campaign.totalSubmissions)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    overlay={<span>Number of submissions that have been posted on Instagram</span>}
                    mouseEnterDelay={0.2}
                    placement="bottom"
                  >
                    <Definition
                      gold
                      title="Live submissions"
                      definition={numberWithCommas(campaign.report.submissions)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    overlay={
                      <span>
                        Total number of assets for all submissions that are live on Instagram.
                        <br /> Each part of a story or a feed post counts as an asset for this
                        number.
                        <br /> A carousel post with 2 images and a story with 3 frames would be 5
                        total live assets.
                      </span>
                    }
                    mouseEnterDelay={0.2}
                    placement="bottom"
                  >
                    <Definition
                      gold
                      title="Live assets"
                      definition={numberWithCommas(campaign.report.assets)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  {campaign.report.followers && (
                    <Tooltip
                      overlay={
                        <span>Total reach of all influencers at the time of them reserving</span>
                      }
                      mouseEnterDelay={0.2}
                      placement="bottom"
                    >
                      <Definition
                        gold
                        title="Reserved followers"
                        definition={numberWithCommas(campaign.report.followers)}
                      />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  {campaign.report.combinedFollowers && (
                    <Tooltip
                      overlay={
                        <span>
                          Total combined reach of all instagram posts at the time they were posted
                        </span>
                      }
                      mouseEnterDelay={0.2}
                      placement="bottom"
                    >
                      <Definition
                        gold
                        title="Combined followers"
                        definition={numberWithCommas(campaign.report.combinedFollowers)}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Internal>
          )}
          <Grid container className={classes.definitionRow} spacing={2}>
            {hasASubmission && (
              <Grid item>
                <Definition
                  gold
                  title="Submissions"
                  definition={numberWithCommas(campaign.totalSubmissions)}
                />
              </Grid>
            )}
            {hasASubmission && (
              <Grid item>
                <Definition gold title="Followers" definition={numberWithCommas(campaign.reach)} />
              </Grid>
            )}
            {campaign.requireInsights && (
              <Grid item>
                <Definition
                  gold
                  title="Insights"
                  definition={numberWithCommas(campaign.insightsCount)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.definitionRow} spacing={2}>
            <Grid item>
              <Definition gold title="Likes" definition={numberWithCommas(campaign.likes)} />
            </Grid>
            <Grid item>
              <Definition gold title="Comments" definition={numberWithCommas(campaign.comments)} />
            </Grid>
            <Grid item>
              <Definition
                gold
                title="Engagements"
                definition={numberWithCommas(campaign.engagements)}
              />
            </Grid>
            {campaign.impressions > 0 && (
              <Grid item>
                <Definition
                  gold
                  title="Impressions"
                  definition={numberWithCommas(campaign.impressions)}
                />
              </Grid>
            )}
            {campaign.engagement && (
              <Grid item>
                <Definition
                  gold
                  title="Engagement rate"
                  definition={campaign.engagement.formattedValue}
                />
              </Grid>
            )}
            <HideInDemoMode>
              <Grid item>
                {campaign.state === 'completed' ? (
                  <Definition
                    gold
                    title="CPE"
                    definition={campaign.costPerEngagement?.formattedValue}
                  />
                ) : (
                  <Definition
                    gold
                    title="Projected CPE"
                    definition={campaign.projectedCostPerEngagement?.formattedValue}
                  />
                )}
              </Grid>
            </HideInDemoMode>
          </Grid>
        </Box>
      )}

      {campaign.posts?.some(post => post.postType === 'video') && (
        <Grid container className={classes.definitionRow} spacing={2}>
          <Grid item>
            <Definition
              gold
              title="Video views"
              definition={numberWithCommas(campaign.videoViews)}
            />
          </Grid>
          <Grid item>
            <Definition
              gold
              title="Video view rate"
              definition={campaign.videoEngagement?.formattedValue}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  ) : null;
};

const useStyles = makeStyles({
  definitionRow: {
    marginTop: 22,
  },
});

export default CampaignStats;
