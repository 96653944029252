import React from 'react';
import {MenuItem, Select, withStyles} from '@material-ui/core';

import {NONE_OPTION} from '../../../consts/variables';
import {useStyles} from './styles';
import {theme} from '../../../consts/theme';
import {RewardModels} from '../../../scenes/Admin/Campaigns/enums';
import {IFilters} from '../StateFilter/types';

const SelectMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  },
})(MenuItem);

const mapRewardModelOptions: Record<string, string> = {
  [RewardModels.Assets]: 'Assets',
  [RewardModels.Engagement]: 'Engagement',
  [RewardModels.Impressions]: 'Impressions',
  [RewardModels.Reach]: 'Reach',
};

const CampaignTypeFiler: React.FC<IFilters> = ({filters, setFilters}) => {
  const classes = useStyles();
  return (
    <label htmlFor="campaign-type-select">
      <Select
        value={filters.rewardModel}
        className={classes.select}
        style={{width: '80px'}}
        inputProps={{id: 'campaign-type-select'}}
        onChange={e => {
          setFilters({...filters, rewardModel: e.target.value ?? null});
        }}
      >
        <SelectMenuItem value={NONE_OPTION}>Type</SelectMenuItem>
        {Object.values(RewardModels).map(item => (
          <SelectMenuItem key={item} value={item}>
            {mapRewardModelOptions[item]}
          </SelectMenuItem>
        ))}
      </Select>
    </label>
  );
};

export default CampaignTypeFiler;
