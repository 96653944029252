import React from 'react';
import moment, {Moment} from 'moment-timezone';

import {SHORT_DATE_TIME_FORMAT} from '../../../consts/variables';

interface Props {
  date?: moment.MomentInput;
  format?: string;
  timezone?: string | null;
}

const FormattedDate: React.FC<Props> = ({date, format = SHORT_DATE_TIME_FORMAT, timezone}) => {
  const d: Moment = moment(date);
  if (!date || !d.isValid()) {
    return <span>-</span>;
  }

  return <span title={d.format()}>{d.tz(timezone ?? 'Europe/London').format(format)}</span>;
};

export default FormattedDate;
