import React, {ReactNode} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogTitle, DialogContent, IconButton, Typography} from '@material-ui/core';

import {useStyles} from './styles';

interface Props {
  isOpened: boolean;
  children: ReactNode | ReactNode[];
  onClose: () => void;
}

const FiltrationParametersDialog: React.FC<Props> = ({isOpened, children, onClose}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="filtration-parameters-dialog-title"
      classes={{root: classes.dialog}}
      onClose={onClose}
    >
      <DialogTitle
        disableTypography
        id="filtration-parameters-dialog-title"
        classes={{root: classes.dialogTitle}}
      >
        <Typography variant="h3" component="h1">
          Filtration parameters
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            size="small"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>{children}</DialogContent>
    </Dialog>
  );
};

export default FiltrationParametersDialog;
