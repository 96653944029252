import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';

import DivHeading from '../../../../../components/Widgets/DivHeading';
import {useStyles} from './styles';

interface Props {
  sendInvite: ({email}: {email: string}) => Promise<void>;
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

const InviteMemberForm: React.FC<Props> = (props: Props) => {
  const {sendInvite} = props;
  const classes = useStyles();

  const {handleSubmit, handleChange, values, touched, errors, isValid} = useFormik({
    validationSchema: validationSchema,
    initialValues: {email: ''},
    onSubmit: ({email}) => {
      sendInvite({email});
    },
  });

  return (
    <>
      <DivHeading title="Invite member" />
      <form className={classes.form} onSubmit={handleSubmit}>
        <label htmlFor="email-input">
          <TextField
            className={classes.root}
            placeholder="email"
            variant="outlined"
            id="email-input"
            inputProps={{
              'aria-labelledby': 'email-button',
            }}
            name="email"
            title="email"
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            onChange={handleChange('email')}
          />
        </label>
        <Button
          variant="outlined"
          type="submit"
          id="email-button"
          aria-label="Send invite email"
          className={classes.button}
          disabled={!isValid}
        >
          Send Invite Email
        </Button>
      </form>
    </>
  );
};

export default InviteMemberForm;
