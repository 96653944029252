import React, {useState, useEffect} from 'react';
import {
  Button,
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import {useStyles} from './styles';
import {IFilterWell} from './types';
import SelectedItemChip from '../../../../Advertiser/AdvertiserSettings/components/IndustrySelector/SelectedItemChip';
import {ICampaignFilters} from '../../types';
import {AddOnTypes, CampaignTypes} from '../../enums';
import {ENTER_KEY_CODE} from '../../../../../consts/variables';

const AdminCampaignFilters: React.FC<IFilterWell> = ({
  changeFilters,
  resetFilters,
  currentFilters,
  initialFilters,
}) => {
  const classes = useStyles();
  const Checkbox = (props: any) => (
    <MuiCheckbox
      {...props}
      checkedIcon={<CheckBoxOutlinedIcon className={classes.blackTextColor} />}
    />
  );

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  useEffect(() => {
    setSelectedFilters(getSelectedExtendedFilters(currentFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const onChangeHasNda = () => {
    changeFilters({
      ...currentFilters,
      hasNda: typeof currentFilters.hasNda === 'undefined' ? false : undefined,
    });
  };

  const handleCheckboxChange = event => {
    changeFilters({...currentFilters, [event.target.name]: event.target.checked});
  };

  const handleEnterKeyDown = event => {
    if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
      event.preventDefault();
      changeFilters({...currentFilters, [event.target.name]: !event.target.checked});
    }
  };

  return (
    <div className={classes.root}>
      <div>
        {selectedFilters.length > 0 && (
          <div className={classes.filter}>
            <div className={classes.chipsWrapper}>
              {selectedFilters.map(item => (
                <SelectedItemChip
                  key={item}
                  id={item}
                  value={mapExtendedFilterOptions[item]}
                  onChange={() => changeFilters({...currentFilters, [item]: initialFilters[item]})}
                />
              ))}
            </div>
            <Button className={classes.button} variant="outlined" onClick={resetFilters}>
              <Typography className={classes.buttonText} variant="button">
                Clear all filters
              </Typography>
            </Button>
          </div>
        )}
      </div>
      <div className={classes.formControls}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={8}>
            <FormControl component="fieldset">
              <legend className={classes.legend}>Add-ons options</legend>
              <FormLabel>
                <Typography component="h2" variant="h4" className={classes.blackTextColor}>
                  Add-ons
                </Typography>
              </FormLabel>
              <FormGroup className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentFilters.shippingRequired}
                      name={AddOnTypes.ShippingRequired}
                      className={classes.control}
                      color="default"
                      onChange={handleCheckboxChange}
                      onKeyDown={handleEnterKeyDown}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[AddOnTypes.ShippingRequired]}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentFilters.brandSafety}
                      name={AddOnTypes.BrandSafety}
                      className={classes.control}
                      color="default"
                      onChange={handleCheckboxChange}
                      onKeyDown={handleEnterKeyDown}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[AddOnTypes.BrandSafety]}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentFilters.brandMatch}
                      className={classes.control}
                      name={AddOnTypes.BrandMatch}
                      color="default"
                      onChange={handleCheckboxChange}
                      onKeyDown={handleEnterKeyDown}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[AddOnTypes.BrandMatch]}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={typeof currentFilters.hasNda !== 'undefined'}
                      name={AddOnTypes.HasNda}
                      className={classes.control}
                      color="default"
                      onChange={() => onChangeHasNda()}
                      onKeyDown={event => {
                        if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
                          event.preventDefault();
                          onChangeHasNda();
                        }
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[AddOnTypes.HasNda]}
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl component="fieldset">
              <legend className={classes.legend}>Other options</legend>
              <FormLabel>
                <Typography component="h2" variant="h4" className={classes.blackTextColor}>
                  Other
                </Typography>
              </FormLabel>
              <FormGroup className={classes.gridItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentFilters.mine}
                      name={CampaignTypes.MyCampaigns}
                      className={classes.control}
                      color="default"
                      onChange={handleCheckboxChange}
                      onKeyDown={handleEnterKeyDown}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[CampaignTypes.MyCampaigns]}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentFilters.archived}
                      name={CampaignTypes.Archived}
                      className={classes.control}
                      color="default"
                      onChange={handleCheckboxChange}
                      onKeyDown={handleEnterKeyDown}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.filterParameter}>
                      {mapExtendedFilterOptions[CampaignTypes.Archived]}
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AdminCampaignFilters;

export function getSelectedExtendedFilters(currentFilters: ICampaignFilters) {
  const listOfChips = [...Object.values(AddOnTypes), ...Object.values(CampaignTypes)];
  const filters: string[] = [];
  listOfChips.forEach(item => {
    const value = currentFilters[item];

    if (value || (item === 'hasNda' && value === false)) {
      filters.push(item);
    }
  });
  return filters;
}

export const mapExtendedFilterOptions: Record<string, string> = {
  [AddOnTypes.ShippingRequired]: 'Shipping',
  [AddOnTypes.BrandSafety]: 'Brand safety',
  [AddOnTypes.BrandMatch]: 'Brand match',
  [AddOnTypes.HasNda]: 'Without NDA',
  [CampaignTypes.MyCampaigns]: 'My campaigns',
  [CampaignTypes.Archived]: 'Archived',
};
