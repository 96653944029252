/* eslint-disable graphql/template-strings */
import {gql} from '@apollo/client';

export const AdvertiserSettingsQuery = gql`
  query AdvertiserSettingsQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      domain
      vatNumber
      archived
      profilePicture
      influencerCooldown
      primaryRegion {
        id
        name
      }
    }
  }
`;

export const UpdateAdvertiserMutation = gql`
  mutation updateAdvertiser(
    $id: UUIDString!
    $name: StrippedString
    $domain: StrippedString
    $regionId: UUIDString!
    $vatNumber: StrippedString
    $profilePicture: String
    $archived: Boolean
    $influencerCooldown: Int
  ) {
    updateAdvertiser(
      id: $id
      name: $name
      domain: $domain
      vatNumber: $vatNumber
      regionId: $regionId
      profilePicture: $profilePicture
      archived: $archived
      influencerCooldown: $influencerCooldown
    ) {
      ok
      advertiser {
        id
        name
        archived
        profilePicture
        influencerCooldown
        vatNumber
        domain
        primaryRegion {
          id
          name
        }
      }
    }
  }
`;

export const IndustryTreeQuery = gql`
  query IndustryTreeQuery {
    industries {
      id
      title
      children {
        id
        title
        active
      }
    }
  }
`;

export const AdvertiserIndustryQuery = gql`
  query AdvertiserIndustryQuery($advertiserId: UUIDString!) {
    advertiserIndustries(advertiserId: $advertiserId) {
      id
      title
      active
    }
  }
`;

export const UpdateAdvertiserIndustryMutation = gql`
  mutation AdvertiserIndustryFormMutation(
    $advertiserId: UUIDString!
    $advertiserIndustryId: UUIDString!
  ) {
    advertiserIndustryForm(
      advertiserId: $advertiserId
      advertiserIndustryId: $advertiserIndustryId
    ) {
      advertiserIndustry {
        id
        title
        active
      }
    }
  }
`;
