import React from 'react';
import PropTypes from 'prop-types';

import {ProfilePicture} from 'components/Widgets';

import styles from './style.css';

const UserBox = ({user}) =>
  user ? (
    <div className={styles.wrapper}>
      <ProfilePicture src={user.profilePicture} />
      <div className={styles.content}>
        <div className={styles.name}>{user.fullName}</div>
        <div className={styles.email}>{user.email}</div>
      </div>
    </div>
  ) : null;

UserBox.propTypes = {
  user: PropTypes.object,
};

export default UserBox;
