import React from 'react';
import PropTypes from 'prop-types';
import {Control, Errors} from 'react-redux-form';

import {RegionPicker} from 'components/Region';
import {SelfTagModal} from 'components/SelfTags';

import {minLength, isEmail} from 'services/validators';

import {
  Checkbox,
  DatePicker,
  ImageUploader,
  InputRange,
  IntRangeInput,
  ListOfTextsInput,
  ListOfFilesInput,
  RadioButton,
  RangeSlider,
  ExponentialRangeSlider,
  Select,
  TextArea,
  TextInput,
  TextInputWithImage,
  BriefEditor,
  Button,
} from '..';
import withCustomControl from './CustomControl';

export const FormCheckbox = withCustomControl(props => (
  <Control.checkbox component={Checkbox} {...props} />
));
export const FormRadio = withCustomControl(props => (
  <Control.radio component={RadioButton} {...props} />
));
export const FormTextInput = withCustomControl(props => (
  <Control.text component={TextInput} {...props} />
));
export const FormIntRangeInput = withCustomControl(props => (
  <Control.text component={IntRangeInput} {...props} />
));
export const FormTextInputWithImage = withCustomControl(props => (
  <Control.text component={TextInputWithImage} {...props} />
));
export const FormTooltipInput = withCustomControl(props => (
  <Control.text component={TextInput} dark {...props} />
));
export const FormTextArea = withCustomControl(props => (
  <Control.textarea component={TextArea} {...props} />
));
export const FormSelect = withCustomControl(props => (
  <Control.select component={Select} {...props} />
));
export const FormDatePicker = withCustomControl(props => (
  <Control.select component={DatePicker} {...props} />
));
export const FormFileInput = props => <Control.file model="file" {...props} />;
export const FormRegionPicker = withCustomControl(props => (
  <Control component={RegionPicker} {...props} />
));
export const FormImageUploader = withCustomControl(props => (
  <Control component={ImageUploader} {...props} />
));
export const FormRangeSlider = withCustomControl(props => (
  <Control component={RangeSlider} {...props} />
));
export const FormExponentialRangeSlider = withCustomControl(props => (
  <Control component={ExponentialRangeSlider} {...props} />
));

export const FormButton = props => (
  <Control.button type="button" model="." component={Button} {...props} />
);

FormButton.propTypes = {
  pending: PropTypes.bool,
};

export const FormInputRange = withCustomControl(props => (
  <Control component={InputRange} {...props} />
));

export const FormListOfTextsInput = withCustomControl(props => (
  <Control component={ListOfTextsInput} {...props} />
));

export const FormListOfFilesInput = withCustomControl(props => (
  <Control component={ListOfFilesInput} {...props} />
));

export const FormSubmitButton = props => (
  <FormButton
    type="submit"
    {...props}
    mapProps={{
      pending: ({fieldValue}) => fieldValue.pending || props.pending,
      success: ({fieldValue}) => fieldValue.submitted,
      failure: ({fieldValue}) => fieldValue.submitFailed,
    }}
    disabled={fieldValue =>
      props.disabled || !fieldValue.valid || props.pending || fieldValue.pending
    }
  />
);

FormSubmitButton.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const FormErrors = props => <Errors model="." {...props} />;
export const FormEmailInput = props => (
  <FormTextInput
    type="email"
    show="touched"
    name="email"
    placeholder="Email"
    validators={{isEmail}}
    messages={{
      isEmail: val => (val ? `"${val}" is not a valid email address` : 'Email is required'),
    }}
    required
    {...props}
  />
);

export const FormPasswordInput = props => (
  <FormTextInput
    type="password"
    name="password"
    placeholder="Password"
    show="touched"
    validators={{length: minLength(3)}}
    messages={{
      length: 'Password must be at least 3 characters',
    }}
    required
    {...props}
  />
);

export const SelfTagModalInput = withCustomControl(props => (
  <Control component={SelfTagModal} {...props} />
));

export const BriefEditorInput = withCustomControl(props => (
  <Control component={BriefEditor} {...props} />
));

export {default as FormCheckboxGroup} from './FormCheckboxGroup';
export {default as Form} from './Form';
