import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import {useStyles} from './styles';

interface Props {
  isOpened: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  contentText: string;
  renderTitleIcon: () => React.ReactNode;
}

const ConfirmDialog: React.FC<Props> = ({
  isOpened,
  onConfirm,
  onCancel,
  title,
  contentText,
  renderTitleIcon,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpened} aria-labelledby="confirm-dialog-title" classes={{root: classes.dialog}}>
      <DialogTitle
        disableTypography
        id="confirm-dialog-title"
        classes={{root: classes.dialogTitle}}
      >
        {renderTitleIcon()}
        <Typography variant="h3" className={classes.typography}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        <DialogContentText className={classes.dialogContentText}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActions}}>
        <Button className={classes.containedButton} aria-label="Delete user" onClick={onConfirm}>
          Delete
        </Button>
        <Button
          variant="outlined"
          aria-label="Cancel dialog"
          className={classes.outlinedButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
