import React, {ReactNode, useEffect} from 'react';
import compose from 'lodash/flowRight';
import {useHistory, useRouteMatch, withRouter} from 'react-router-dom';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';

import {CampaignNoGigs} from 'components/Campaign';
import {LoaderDots} from 'components/Widgets';

import {singularOrPlural} from 'consts/utilities';
import {campaignUrlFromParams} from 'consts/urls';
import {withPagination} from 'services/graphql';

import styles from './style.css';
import {CampaignHeader} from '../components';

interface Props {
  loading?: boolean;
  loadMore: () => void;
  gigs?: Gig[];
  children?: ReactNode | ReactNode[];
}

type Params = {
  campaign: string;
  gigId: string;
};

const UnverifiedGigs: React.FC<Props> = ({gigs, children, loading}) => {
  const history = useHistory();
  const {params}: {params: Params} = useRouteMatch();

  useEffect(() => {
    if (gigs?.count > 0 && !params.gigId) {
      // component mounting
      history.push(`${campaignUrlFromParams(params)}/unverified/${gigs.edges[0].node.id}`);
    }
  }, [gigs]);

  if (loading) {
    return (
      <div className={styles.loader}>
        <LoaderDots />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.headerWrapper}>
        <CampaignHeader title="Unverified gigs" campaignId={params.campaign} showProgress />
        <p className={styles.subHeader}>
          {gigs?.count} {singularOrPlural('post', gigs?.count)} left
        </p>
      </div>
      {gigs?.edges.length === 0 && <CampaignNoGigs title={'No gigs left'} />}
      {children}
    </div>
  );
};

export const UnverifiedGigsQuery = gql`
  query UnverifiedGigsQuery($campaign: UUIDString!) {
    gigs(campaignId: $campaign, unverified: true) {
      edges {
        node {
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      count
    }
  }
`;

export default graphql(UnverifiedGigsQuery, {
  options: ({match: {params}}) => ({
    variables: {
      campaign: params.campaign,
    },
  }),
  props: compose(
    withRouter,
    ({data: {gigs, loading}, loadMore}) => ({
      gigs,
      loading,
      loadMore,
    }),
    withPagination(UnverifiedGigsQuery, 'gigs')
  ),
})(UnverifiedGigs);
