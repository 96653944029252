import React from 'react';
import * as htmlparser from 'htmlparser2';
import classNames from 'classnames';

import styles from './style.css';

let key = 0;
const getKey = () => `Brief${key++}`;

/* Blocks */

const Divider = () => (
  <div className={styles.divider}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
      <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" opacity="0.191">
        <path
          fill="#A7A9AC"
          d="M12.5 18.083l-9.273-5.978L0 14.185v2.49l6.25 4.03 3.965 2.556a4.19 4.19 0 004.57 0l3.965-2.556 6.25-4.03v-2.49l-3.227-2.08-9.273 5.978z"
          opacity="0.701"
          transform="translate(-179 -882) translate(179 882)"
        />
        <path
          fill="#787878"
          d="M18.75 3.487L14.785.931a4.19 4.19 0 00-4.57 0L6.25 3.487 0 7.517v6.65l3.227-2.08L12.5 6.11 10.124 9.03c-1.432 1.76-1.432 4.352 0 6.113l2.376 2.922 2.376-2.922c1.432-1.76 1.432-4.352 0-6.113L12.5 6.109l9.273 5.978L25 14.167v-6.65l-6.25-4.03z"
          transform="translate(-179 -882) translate(179 882)"
        />
      </g>
    </svg>
  </div>
);

const Important = ({content}: {content: string}) => (
  <div className={styles.important}>
    <div className={styles.importantHeader}>
      <div className={styles.importantHeaderContent}>Important!</div>
    </div>
    <div className={styles.importantContent}>{content}</div>
  </div>
);

/* Rendering */

const parseHtml = (html: string) => {
  const renderNode = (node: Record<string, any>) => {
    if (node.type == 'text') {
      return node.data;
    }
    if (node.type == 'tag') {
      if (node.tagName == 'b') {
        return <b key={getKey()}>{node.children.map(renderNode)}</b>;
      }
      if (node.tagName == 'i') {
        return <i key={getKey()}>{node.children.map(renderNode)}</i>;
      }
      if (node.tagName == 'a') {
        return (
          <a key={getKey()} href={node.attribs.href} style={{textDecoration: 'underline'}}>
            {node.children.map(renderNode)}
          </a>
        );
      }
    }
    return null;
  };

  const dom = htmlparser.parseDOM(html, {decodeEntities: true});
  return dom.map(renderNode);
};

const renderDosAndDonts = (dos: string[], donts: string[]) => {
  return (
    <div>
      {dos.map((item, i) => (
        <div key={i} className={styles.dosAndDontsItem}>
          <span className={classNames([styles.dosAndDontsDot, styles.green])} />
          <div className={classNames([styles.dosAndDontsText, styles.do])}>{parseHtml(item)}</div>
        </div>
      ))}
      {donts.map((item, i) => (
        <div key={i} className={styles.dosAndDontsItem}>
          <span className={classNames([styles.dosAndDontsDot, styles.red])} />
          <div className={classNames([styles.dosAndDontsText, styles.dont])}>{parseHtml(item)}</div>
        </div>
      ))}
    </div>
  );
};

const renderBriefSection = (section: BriefSection) => {
  const renderBlock = (section: BriefSection) => {
    switch (section.type) {
      case 'paragraph':
        return <div>{parseHtml(section.value ?? '')}</div>;
      case 'heading':
        return <h1 className={styles.heading}>{section.value}</h1>;
      case 'subHeading':
        return <h2 className={styles.heading}>{section.value}</h2>;
      case 'divider':
        return <Divider />;
      case 'important':
        return <Important content={parseHtml(section.value ?? '')} />;
      case 'orderedList':
        return (
          <ol className={styles.list}>
            {' '}
            {section.items?.map(item => (
              <li key={getKey()} className={styles.listItem}>
                {parseHtml(item ?? '')}
              </li>
            ))}{' '}
          </ol>
        );
      case 'unorderedList':
        return (
          <ul className={styles.list}>
            {' '}
            {section.items?.map(item => (
              <li key={getKey()} className={styles.listItem}>
                {parseHtml(item ?? '')}
              </li>
            ))}{' '}
          </ul>
        );
      case 'dosAndDonts':
        return renderDosAndDonts(section.dos, section.donts);
      default:
        return null;
    }
  };
  return (
    <div key={getKey()} className={styles.block}>
      {renderBlock(section)}
    </div>
  );
};

/* Main Component */

interface Props {
  brief: any[];
}

const Brief: React.FC<Props> = ({brief}) => {
  return <div className={styles.brief}>{brief.map(renderBriefSection)}</div>;
};

export default Brief;
