import React from 'react';

const Trashbin = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Untitled-2"
      x="0px"
      y="0px"
      width="8px"
      height="9px"
      viewBox="0 0 8 9"
      enableBackground="new 0 0 17 14"
    >
      <path fill="none" />
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1_1.Campaign_Create" transform="translate(-712.000000, -558.000000)" fill="#FFFFFF">
          <g id="Group-+-campaign-details" transform="translate(176.000000, 80.000000)">
            <g
              id="Group-+-Create-Campaign-+-1.-Brief--•--2.-Sche"
              transform="translate(396.000000, 4.000000)"
            >
              <g id="Group" transform="translate(0.000000, 98.000000)">
                <g id="Group-Copy" transform="translate(0.000000, 216.000000)">
                  <g id="Gig-Index-Photo-Copy" transform="translate(5.000000, 103.000000)">
                    <g id="Fill-666-+-Fill-667" transform="translate(135.000000, 57.000000)">
                      <g>
                        <path
                          d="M1.31715254,8.989 L6.58535593,8.989 L7.90250847,1.75 L0,1.75 L1.31715254,8.989 L1.31715254,8.989 Z M2.42874576,7.805875 L1.48515254,2.940625 L2.28257627,2.93975 L3.24922034,7.805875 L2.42874576,7.805875 L2.42874576,7.805875 Z M4.65423729,7.805875 L5.62088136,2.93975 L6.41830508,2.940625 L5.47484746,7.805875 L4.65423729,7.805875 L4.65423729,7.805875 Z"
                          id="Fill-666"
                        />
                        <path
                          d="M5.28501695,0.507125 L5.28501695,0 L2.63430508,0 L2.63430508,0.507125 L0,0.507125 L0,1.09125 L7.91905085,1.09125 L7.91905085,0.507125 L5.28501695,0.507125"
                          id="Fill-667"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path fill="none" />
    </svg>
  </div>
);

export default Trashbin;
