import React from 'react';
import {Box, Grid} from '@material-ui/core';

import useResponsive from '../../../hooks/useResponsive';
import {secondaryNavHeight} from '../../../consts/brand.integration';

interface Props {
  children: React.ReactNode;
}

const SecondaryNavigation = ({children}: Props) => {
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Box height={isDesktop ? secondaryNavHeight : '100%'} marginY="20px">
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Box>
  );
};

export default SecondaryNavigation;
