import {gql} from '@apollo/client';

export const AdvertiserQuery = gql`
  query AdvertiserQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      archived
    }
  }
`;

export const AdvertiserConfigQuery = gql`
  query AdvertiserConfigQuery($advertiserId: UUIDString!) {
    advertiserConfig(advertiserId: $advertiserId) {
      id
      advertiserId
      impressions
      engagementRate
      benchmarks
      campaignType
      budget
      viewRate
      brandCampaignsPage
      dashboardPage
    }
  }
`;

export const AdvertiserConfigMutation = gql`
  mutation AdvertiserConfigMutation(
    $advertiserId: UUIDString!
    $impressions: Boolean!
    $engagementRate: Boolean!
    $benchmarks: Boolean!
    $campaignType: Boolean!
    $budget: Boolean!
    $brandCampaignsPage: Boolean!
    $dashboardPage: Boolean!
    $viewRate: Boolean!
  ) {
    advertiserConfigForm(
      advertiserId: $advertiserId
      impressions: $impressions
      engagementRate: $engagementRate
      benchmarks: $benchmarks
      campaignType: $campaignType
      budget: $budget
      brandCampaignsPage: $brandCampaignsPage
      dashboardPage: $dashboardPage
      viewRate: $viewRate
    ) {
      ok
      advertiserConfig {
        id
        advertiserId
        impressions
        engagementRate
        benchmarks
        campaignType
        budget
        viewRate
        brandCampaignsPage
        dashboardPage
      }
    }
  }
`;
