import PropTypes from 'prop-types';
import React from 'react';

const Engagement = ({color = '#000000', size = 13}) => (
  <svg
    width={`${size + 1}px`}
    height={`${size}px`}
    viewBox="0 0 13 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Report-–-FrontPage" transform="translate(-124.000000, -752.000000)" fill={color}>
      <path
        d="M130.767042,758.285714 L134.651777,758.285714 C135.948665,758.285714 137,759.331967 137,760.642857 L137,763 L133.75,763 L133.75,761.035714 C133.75,759.520022 132.519858,758.285714 131.002401,758.285714 L130.767042,758.285714 C130.767042,758.285714 130.767042,758.285714 130.767042,758.285714 Z M130.643557,752.693217 C131.145686,752.261939 131.806836,752 132.53125,752 C134.10181,752 135.375,753.231217 135.375,754.75 C135.375,756.268783 134.10181,757.5 132.53125,757.5 C132.2076,757.5 131.896579,757.447715 131.606725,757.351404 C131.93581,756.820685 132.125,756.199565 132.125,755.535714 C132.125,754.370753 131.542387,753.337383 130.643557,752.693217 Z M124,761.035714 C124,759.950869 124.873238,759.071429 125.961372,759.071429 L130.976128,759.071429 C132.059364,759.071429 132.9375,759.943306 132.9375,761.035714 L132.9375,763 L124,763 L124,761.035714 Z M128.46875,758.285714 C130.03931,758.285714 131.3125,757.054497 131.3125,755.535714 C131.3125,754.016931 130.03931,752.785714 128.46875,752.785714 C126.89819,752.785714 125.625,754.016931 125.625,755.535714 C125.625,757.054497 126.89819,758.285714 128.46875,758.285714 Z"
        id="Rectangle-29-Copy-2"
      />
    </g>
  </svg>
);

Engagement.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Engagement;
