import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.css';

const Page = ({children, sidebar, widgets, params, toggleArchive, isArchived}) => (
  <div className={styles.root}>
    {sidebar && (
      <div className={styles.sidebar}>
        <div className={styles.sidebarContainer}>{sidebar}</div>
      </div>
    )}

    <div className={styles.main}>{children}</div>

    <div className={styles.widgets}>
      <div className={styles.widgetContainer}>
        {widgets}

        {params && (
          <button className={styles.archiveButton} onClick={toggleArchive}>
            {isArchived ? 'Unarchive campaign' : 'Archive campaign'}
          </button>
        )}
      </div>
    </div>
  </div>
);

Page.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  widgets: PropTypes.node,
  params: PropTypes.shape({
    advertiser: PropTypes.string,
    campaign: PropTypes.string,
  }),
  toggleArchive: PropTypes.func,
  isArchived: PropTypes.bool,
};

export default Page;
