import React from 'react';
import {Link} from 'react-router-dom';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';
import emojiFlag from 'emoji-flag';

import CopyPasteable from 'components/Widgets/CopyPasteable/CopyPasteable';

import {purple, bold} from '../../../consts/brand.integration';
import {getLocation} from '../../../consts/influencerHelper';
import {InstagramLink, TikTokLink, Tooltip} from '../../../components/Widgets';
import {YouTubeLink} from '../SocialLinks';

interface Props {
  influencer: Influencer;
}

interface StyleProps {
  color?: string;
}

const InfluencerListItem: React.FC<Props> = ({influencer}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to={`/influencers/${influencer.id}/`}>
        {influencer.fullName && (
          <Typography variant="subtitle2" component="p">
            {influencer.fullName}
            <Tooltip overlay={<span>Engagement Rate</span>} placement="bottom">
              <Typography variant="caption" className={classes.socialData}>
                <Icon icon="material-symbols:show-chart" width={18} />
                {influencer.instagramAccount?.engagement?.formattedValue}
              </Typography>
            </Tooltip>
          </Typography>
        )}
      </Link>
      {influencer.instagramAccount && (
        <InstagramLink username={influencer.instagramAccount.username} />
      )}
      {influencer.tiktokAccount && <TikTokLink username={influencer.tiktokAccount.username} />}
      {influencer.youtubeChannel && <YouTubeLink username={influencer.youtubeChannel.customUrl} />}
      {influencer.targetRegion && (
        <Typography variant="subtitle2" component="p" gutterBottom>
          {emojiFlag(influencer.targetRegion.countryCode)}
          <Typography variant="caption" className={classes.region}>
            {getLocation(influencer.targetRegion.name, influencer.targetRegion.country)}
          </Typography>
        </Typography>
      )}

      <Typography variant="caption">
        <CopyPasteable noIcon>{influencer.email}</CopyPasteable>
      </Typography>
    </div>
  );
};

const useStyles = makeStyles<StyleProps>(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& p, span': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          marginLeft: '5px',
        },
      },
      '& svg': {
        marginRight: '5px',
      },
    },
    socialData: (props: StyleProps) => ({
      fontWeight: bold,
      color: props.color || purple,
    }),
  })
);

export default InfluencerListItem;
