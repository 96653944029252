import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';

import {constructImgixUrl} from '../../../components/Widgets/Imgix';
import {LoaderDots} from '../../../components/Widgets';
import Metrics from './components/Metrics';
import Highlights from './components/Highlights';
import TopCreators from './components/TopCreators';
import {CampaignQuery, CampaignCreatorsQuery} from '../graphqlQueries';
import {useStyles} from './styles';
import App404 from '../../App404/App404';

const getStyleForImageWrapper = (url: string) => ({
  background: `url(${constructImgixUrl(url, 160, 160, 'crop')})`,
  backgroundSize: 'cover, cover',
});

interface Props {
  match: {
    params: {
      campaign: string;
    };
  };
}

const CampaignDetails: React.FC<Props & RouteComponentProps> = ({match: {params}}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const {loading: loadingCampaign, data: campaignQueryResponse} = useQuery(CampaignQuery, {
    variables: {campaignId: params.campaign},
  });

  const {loading: loadingCreators, data: creatorsQueryResponse} = useQuery(CampaignCreatorsQuery, {
    variables: {campaignId: params.campaign},
  });

  useEffect(() => {
    setLoading(loadingCampaign || loadingCreators);
  }, [loadingCampaign, loadingCreators]);

  if (loading) {
    return <LoaderDots />;
  }

  if (!campaignQueryResponse?.campaign) {
    return <App404 />;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h2" className={classes.title}>
        {campaignQueryResponse?.campaign?.advertiser?.name}
      </Typography>
      <Metrics campaign={campaignQueryResponse?.campaign} />
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs={9}>
          {campaignQueryResponse?.campaign?.campaignHighlights &&
            campaignQueryResponse?.campaign?.campaignHighlights?.engagementRate !== null && (
              <Highlights highlights={campaignQueryResponse?.campaign?.campaignHighlights} />
            )}
        </Grid>
        <Grid item xs={3}>
          <div
            role="img"
            aria-label={campaignQueryResponse?.campaign?.name}
            className={classes.imageWrapper}
            style={getStyleForImageWrapper(
              campaignQueryResponse?.campaign?.photos?.coverPhoto?.url
            )}
          />
        </Grid>
      </Grid>
      <TopCreators creators={creatorsQueryResponse?.offers?.edges} />
    </div>
  );
};

export default withRouter(CampaignDetails);
