// eslint-disable-next-line import/named
import {createStyles, makeStyles} from '@material-ui/core/styles';

import {red, black60, transparentBlack} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: black60,
      marginLeft: '20px',
    },
    image: {
      paddingLeft: '12px',
      borderRadius: '2px',
      boxShadow: `0px 2px 20px ${transparentBlack}`,
    },
    loading: {
      width: '80px',
      marginLeft: '28px',
    },
    uploadButtonWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    filePicker: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: '60px',
    },
    button: {
      height: '40px',
      maxWidth: '165px',
      marginTop: '1.2rem',
    },
    error: {
      color: red,
      fontSize: 12,
    },
  })
);
