import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import * as qs from 'query-string';

import {singularOrPlural} from 'consts/utilities';

import style from './style.css';

const DrawerToggleButton = ({onClick, isOpen, moreFilterCount, location}) => {
  const params = qs.parse(location.search);
  const labelFilterCount =
    moreFilterCount ||
    Object.keys(params)
      .map(key => key.replace(/^(max|min)/, ''))
      .filter((x, i, self) => self.indexOf(x) === i)
      .filter(key => key !== 'q' && key !== 'filter' && key !== 'info').length;
  const closedLabel =
    labelFilterCount > 0
      ? `${labelFilterCount} more ${singularOrPlural('filter', labelFilterCount)}`
      : 'More filters';

  return (
    <div className={style.container} onClick={onClick}>
      <div className={classNames(style.moreFilters, {[style.filtersActive]: labelFilterCount > 0})}>
        {isOpen ? 'Close' : closedLabel}
      </div>
      <div
        className={classNames(
          style.button,
          {[style.clicked]: isOpen},
          {[style.filtersActive]: moreFilterCount > 0}
        )}
      >
        <div className={style.chevron} />
      </div>
    </div>
  );
};

DrawerToggleButton.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  moreFilterCount: PropTypes.number,
  location: PropTypes.object,
};

export default withRouter(DrawerToggleButton);
