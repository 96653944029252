import compose from 'lodash/flowRight';
import React, {useEffect, useState} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {FetchResult, gql, useLazyQuery} from '@apollo/client';
import {withRouter} from 'react-router-dom';
import {FormControl, FormLabel, Typography, createStyles, makeStyles} from '@material-ui/core';

import {InfiniteScroll, Section, DebouncedTextInput, LoaderDots} from 'components/Widgets';

import {withPagination} from 'services/graphql';

import {InviteUserForm, ListAdvertiserUsers, ListPendingAdvertiserUsers} from './components';

type Props = {
  data: {
    loading: boolean;
    users: Record<string, unknown>;
    roles: object[];
    pendingUsers: Record<string, unknown>;
  };
  /* eslint-disable */
  loadMore?: Function;
  inviteUser: (
    email: any,
    name: any,
    role: any
  ) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  resendInvite: (id: any) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  revokeInvite: (id: any) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  updateRole: (
    id: any,
    role: any
  ) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  /* eslint-enable */
};

const UserManagement: React.FC<Props> = props => {
  const classes = useStyles();
  const inviteUser = user => props.inviteUser(user.email, user.name, user.role);

  const updateUserRole = (id, role) => props.updateRole(id, role);

  const [searchUsers, search] = useLazyQuery(SearchUsersQuery);
  const [searchString, setSearchString] = useState('');

  const {data, loadMore} = props;
  const roles = data.roles;

  useEffect(() => {
    if (searchString) searchUsers({variables: {search: searchString}});
  }, [searchString, searchUsers]);

  return (
    <div className={classes.root}>
      <Section title="Invite user">
        {data.loading ? (
          <LoaderDots />
        ) : (
          <InviteUserForm roles={roles} pending={data.loading} submitInvite={inviteUser} />
        )}
      </Section>
      <Section title="Pending invites">
        {data.loading ? (
          <LoaderDots />
        ) : (
          <ListPendingAdvertiserUsers
            users={data.pendingUsers.edges.map(edge => edge.node)}
            resendInvite={props.resendInvite}
            revokeInvite={props.revokeInvite}
          />
        )}
      </Section>

      <Section title="Manage users">
        <FormControl fullWidth>
          <FormLabel id="email-search-label" htmlFor="email-search">
            <Typography variant="subtitle1" color="primary">
              Search By Email
            </Typography>
          </FormLabel>

          <DebouncedTextInput
            id="email-search"
            labelId="email-search-label"
            width="100%"
            debounceDelay={500}
            value={searchString}
            placeholder="User email"
            onChange={search => setSearchString(search)}
          />
        </FormControl>

        {(() => {
          if (data.loading || search.loading) return <LoaderDots />;

          if (searchString && search.data?.users)
            return (
              <ListAdvertiserUsers
                users={search.data.users.edges.map(edge => edge.node)}
                roles={roles}
                onUpdateUserRole={updateUserRole}
              />
            );

          return (
            <InfiniteScroll loadMore={loadMore} hasMore={data.users.pageInfo.hasNextPage} noMargin>
              {data.users && (
                <ListAdvertiserUsers
                  users={data.users.edges.map(edge => edge.node)}
                  roles={roles}
                  onUpdateUserRole={updateUserRole}
                />
              )}
            </InfiniteScroll>
          );
        })()}
      </Section>
    </div>
  );
};

const UserFragment = gql`
  fragment manageUserUserFields on User {
    __typename
    id
    email
    fullName
    profilePicture
    roleName
  }
`;

const ListUserQuery = gql`
  query ListUserQuery($cursor: String) {
    users(includeInfluencers: false, after: $cursor) {
      edges {
        node {
          id
          ...manageUserUserFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    pendingUsers {
      edges {
        node {
          id
          ...manageUserUserFields
          inviteTtl
        }
      }
    }
    roles {
      name
    }
  }
  ${UserFragment}
`;

const SearchUsersQuery = gql`
  query SearchUsersQuery($search: String) {
    users(includeInfluencers: false, search: $search) {
      edges {
        node {
          id
          ...manageUserUserFields
        }
      }
    }
  }
  ${UserFragment}
`;

const inviteUser = gql`
  mutation inviteUser($email: String!, $name: String!, $role: String!) {
    inviteUser(email: $email, name: $name, role: $role) {
      ok
      user {
        id
        email
        fullName
        profilePicture
        roleName
      }
    }
  }
`;

const resendInvite = gql`
  mutation resendInvite($id: UUIDString!) {
    resendUserInvite(id: $id) {
      ok
      user {
        id
        email
        fullName
        profilePicture
        roleName
      }
    }
  }
`;

const revokeInvite = gql`
  mutation revokeInvite($id: UUIDString!) {
    revokeUserInvite(id: $id) {
      ok
    }
  }
`;

const updateRole = gql`
  mutation updateRole($id: UUIDString!, $role: String!) {
    updateUser(id: $id, role: $role) {
      ok
      user {
        id
        email
        fullName
        profilePicture
        roleName
      }
    }
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 auto',
      paddingTop: '40px',
    },
  })
);

export default compose(
  withRouter,
  graphql(ListUserQuery, {
    props: withPagination(ListUserQuery, 'users'),
  }),
  graphql(inviteUser, {
    options: {refetchQueries: ['ListUserQuery']},
    props: ({mutate}) => ({
      inviteUser: (email, name, role) =>
        mutate({
          variables: {
            email,
            name,
            role,
          },
        }),
    }),
  }),
  graphql(resendInvite, {
    options: {refetchQueries: ['ListUserQuery']},
    props: ({mutate}) => ({
      resendInvite: id =>
        mutate({
          variables: {
            id,
          },
        }),
    }),
  }),
  graphql(revokeInvite, {
    options: {refetchQueries: ['ListUserQuery']},
    props: ({mutate}) => ({
      revokeInvite: id =>
        mutate({
          variables: {
            id,
          },
        }),
    }),
  }),
  graphql(updateRole, {
    options: {refetchQueries: ['ListUserQuery', 'SearchUsersQuery']},
    props: ({mutate}) => ({
      updateRole: (id, role) =>
        mutate({
          variables: {
            id,
            role,
          },
        }),
    }),
  })
)(UserManagement);
