import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as qs from 'query-string';

import {Media, MediaContainer} from 'components/Widgets';

import styles from './style.css';

function clamp(value, min, max) {
  return Math.min(Math.max(value || 0, min), max);
}

class Gallery extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    history: PropTypes.shape({
      replace: PropTypes.func,
      setRouteLeaveHook: PropTypes.func,
    }),
    routes: PropTypes.array,
    items: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    dotsStyle: PropTypes.string,
    dotStyle: PropTypes.string,
    activeDotStyle: PropTypes.string,
    alt: PropTypes.string,
  };

  componentDidMount() {
    this.unblock = this.props.history.block(nextLocation => {
      const params = qs.parse(nextLocation.search);
      if (params.page) {
        const search = {...params};
        delete search['page'];

        this.props.history.replace({
          ...nextLocation,
          search: qs.stringify(search),
        });
      }
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  goToPage(page) {
    const {location, history} = this.props;
    const params = qs.parse(location.search);
    history.push({
      ...location,
      search: qs.stringify({
        ...params,
        page,
      }),
    });
  }

  render() {
    const {items, width, height, location, dotsStyle, dotStyle, activeDotStyle, alt} = this.props;
    const params = qs.parse(location.search);
    const page = clamp(params.page, 0, items.length - 1);

    return (
      <MediaContainer height={height} width={width}>
        <Media media={items[page]} width={width} height={height} alt={`${alt}-${page + 1}`} />

        <div className={classNames(styles.dots, dotsStyle)}>
          {items.map((v, i) => (
            <div key={v.id} className={styles.click} onClick={() => this.goToPage(i)}>
              <div
                className={classNames(styles.dot, {[styles.active]: i === page}, dotStyle, {
                  [activeDotStyle]: i === page,
                })}
              />
            </div>
          ))}
        </div>
      </MediaContainer>
    );
  }
}

export default withRouter(Gallery);
