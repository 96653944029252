import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import {useStyles} from './styles';
import {IPagination} from './types';

const Pagination: React.FC<IPagination> = ({items}) => {
  const classes = useStyles();

  return (
    <nav role="navigation" aria-label="Pagination Navigation">
      <ul className={classes.ul}>
        {items.map(({page, type, selected, ...item}, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <IconButton
                className={`${classes.paginationButton} ${classes.ellipsis}`}
                size="small"
                aria-label="Hidden pages"
                disabled
              >
                <MoreHorizIcon />
              </IconButton>
            );
          } else if (type === 'page') {
            const ariaLabel = selected ? `Page ${page}, current page` : `Go to page ${page}`;
            children = (
              <IconButton
                {...item}
                className={`${classes.paginationButton} ${selected && classes.selected}`}
                size="small"
                aria-label={ariaLabel}
              >
                <Typography variant="body2">{page}</Typography>
              </IconButton>
            );
          } else {
            const NavigationButton = ({
              component,
              ariaLabel,
            }: {
              component: React.ReactFragment;
              ariaLabel: string;
            }) => (
              <IconButton
                {...item}
                className={classes.paginationButton}
                size="small"
                aria-label={ariaLabel}
              >
                {component}
              </IconButton>
            );

            if (type === 'previous') {
              children = (
                <NavigationButton component={<ChevronLeftIcon />} ariaLabel="Go to previous page" />
              );
            } else if (type === 'next') {
              children = (
                <NavigationButton component={<ChevronRightIcon />} ariaLabel="Go to next page" />
              );
            } else if (type === 'first') {
              children = (
                <NavigationButton component={<FirstPageIcon />} ariaLabel="Go to first page" />
              );
            } else if (type === 'last') {
              children = (
                <NavigationButton component={<LastPageIcon />} ariaLabel="Go to last page" />
              );
            }
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
