import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Prompt} from 'components/Widgets';

interface Props {
  offer: Offer;
  disabled?: boolean;
}

const RevokeOfferButton: React.FC<Props> = ({offer, disabled}) => {
  const [revokeOffer] = useMutation(RevokeOfferMutation);

  return (
    <Prompt
      title="Revoke offer"
      body="Are you sure you want to revoke this offer?"
      confirmText="Revoke offer"
      control={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="mdi:file-revert" />}
          disabled={disabled}
          style={{margin: '3px'}}
        >
          Revoke Offer
        </Button>
      }
      onSubmit={() => revokeOffer({variables: {id: offer.id}})}
    />
  );
};

const RevokeOfferMutation = gql`
  mutation revokeOfferFromInfluencer($id: UUIDString!) {
    revokeOffer(id: $id) {
      ok
      offer {
        id
        state
        formattedState
        revertableState
      }
    }
  }
`;

export default RevokeOfferButton;
