import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import omit from 'lodash.omit';

import uniqueId from 'services/uniqueId';

import style from './style.css';

const RadioButton = props => {
  const id = uniqueId(props.name);
  const {
    checked,
    onChange,
    onKeyDown,
    label,
    description,
    disabled,
    radioButtonContainerClassName,
    radioButtonClassName,
    checkedRadioButtonClassName,
    labelClassName,
    descriptionClassName,
  } = props;

  return (
    <div className={style.wrapper}>
      <div className={classNames(style.container, radioButtonContainerClassName)}>
        <input
          type="radio"
          id={id}
          className={classNames(
            style.radioButton,
            {[style.disabled]: disabled},
            radioButtonClassName,
            {[checkedRadioButtonClassName]: checked}
          )}
          {...omit(
            props,
            'hasError',
            'radioButtonContainerClassName',
            'radioButtonClassName',
            'checkedRadioButtonClassName',
            'labelClassName',
            'descriptionClassName'
          )}
          checked={checked}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <label
          className={classNames(
            style.label,
            {[style.big]: description, [style.disabledLabel]: disabled},
            labelClassName
          )}
          htmlFor={id}
        >
          {label}
          {description && (
            <div
              className={classNames(
                style.description,
                {[style.disabled]: disabled},
                descriptionClassName
              )}
            >
              {description}
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  radioButtonContainerClassName: PropTypes.string,
  radioButtonClassName: PropTypes.string,
  checkedRadioButtonClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
};

export default RadioButton;
