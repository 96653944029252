import {makeStyles} from '@material-ui/styles';
import {createStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ul: {
      listStyle: 'none',
      display: 'flex',
    },
    page: {
      whiteSpace: 'nowrap',
      margin: '2px 10px',
    },
    button: {
      color: theme.palette['ui-07'],
    },
    disableButton: {
      width: 30,
    },
  })
);
