import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {useMutation} from '@apollo/client';

import NotifyDialog from '../NotifyDialog';
import ConfirmDialog from '../ConfirmDialog';
import MemberCard from '../../../MemberCard';
import {Advertiser, AdvertiserMember} from '../../../../types';
import {CheckCircleIcon, AlertCircleIcon} from '../../icons';
import {DeleteBrandUserMutation, InviteBrandUserMutation} from '../../graphqlQueries';
import {useStyles} from './styles';

interface Props {
  advertiser: Advertiser;
  brandUser: AdvertiserMember;
  isEditDisabled: boolean;
  resetInput: () => void;
}

const SavedBrandProfile: React.FC<Props> = ({
  advertiser,
  brandUser,
  isEditDisabled,
  resetInput,
}: Props) => {
  const classes = useStyles();
  const [isNotifyDialogOpened, setIsNotifyDialogOpened] = useState(false);
  const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState(false);
  const [isInviteSending, setIsInviteSending] = useState(false);
  const [deleteBrandUserMutation] = useMutation(DeleteBrandUserMutation, {
    refetchQueries: ['BrandUserQuery'],
  });
  const [inviteBrandUserMutation] = useMutation(InviteBrandUserMutation, {
    refetchQueries: ['BrandUserQuery'],
  });

  const handleNotifyDialogClose = () => {
    setIsNotifyDialogOpened(false);
  };

  const handleConfirmDialogOpen = () => {
    setIsConfirmDialogOpened(true);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpened(false);
  };

  const confirmDeleteUser = () => {
    deleteBrandUserMutation({
      variables: {userId: brandUser.id},
    });
    handleConfirmDialogClose();
    resetInput();
  };

  const sendInviteEmail = async () => {
    setIsInviteSending(true);
    await inviteBrandUserMutation({
      variables: {advertiserId: advertiser.id, userId: brandUser.id},
    });
    setIsNotifyDialogOpened(true);
  };

  return (
    <div className={classes.container}>
      <MemberCard member={brandUser} removeMember={handleConfirmDialogOpen} />
      {!brandUser?.hasInvitationSent && (
        <Button
          variant="outlined"
          className={classes.button}
          disabled={isInviteSending || isEditDisabled}
          onClick={sendInviteEmail}
        >
          Send Invite email
        </Button>
      )}
      <NotifyDialog
        isOpened={isNotifyDialogOpened}
        title="Invitation was sent"
        contentText="Invitation email was sent to specified email address"
        renderTitleIcon={() => <CheckCircleIcon />}
        onClose={handleNotifyDialogClose}
      />
      <ConfirmDialog
        isOpened={isConfirmDialogOpened}
        title="Are you sure you would like to delete user?"
        contentText="This user won’t have access to this brand on TAKUMI anymore"
        renderTitleIcon={() => <AlertCircleIcon />}
        onConfirm={confirmDeleteUser}
        onCancel={handleConfirmDialogClose}
      />
    </div>
  );
};

export default SavedBrandProfile;
