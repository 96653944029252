import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Link} from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import {capitalize} from 'lodash';

import {Influencer} from 'services/fragments';

import {LoaderDots, MenuPopover} from '../../../../components/Widgets';
import {campaignUrl} from '../../../../consts/urls';
import InfluencerProfileCard from '../../../../components/Widgets/InfluencerProfileCard/InfluencerProfileCard';
import ApprovePaymentButton from './components/ApprovePaymentButton/ApprovePaymentButton';
import MarkAsPaidButton from './components/MarkAsPaidButton/MarkAsPaidButton';
import {RowData, sortByOrder, SORT_ORDER, TABLE_HEADERS} from './tableExtras';
import CurrencyFilter, {DEFAULT_CURRENCY_FILTER_VALUE} from './components/CurrencyFilter';

const Approvals: React.FC = () => {
  const [orderBy, setOrderBy] = useState(TABLE_HEADERS.claimed);
  const [order, setOrder] = useState(SORT_ORDER.asc);
  const [currencyFilter, setCurrencyFilter] = useState(DEFAULT_CURRENCY_FILTER_VALUE);
  const {loading, error, data} = useQuery(UnapprovedPayments);

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return (
      <div style={{display: 'inline-flex'}}>
        <span style={{marginRight: '5px'}}>
          <ErrorIcon color="error" />
        </span>
        <Typography variant="body2">Error loading unapproved payments</Typography>
      </div>
    );
  }

  const handleOrderBy = (id: string) => {
    if (id === orderBy) {
      setOrder(order === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc);
    } else {
      setOrderBy(id);
    }
  };

  const createRows = (list: Offer[]) =>
    list
      .filter(
        item =>
          currencyFilter === DEFAULT_CURRENCY_FILTER_VALUE ||
          item.reward?.currency === currencyFilter
      )
      .map(
        item =>
          ({
            offerId: item.id,
            paymentId: item.payment?.id,
            bankAccount: item.payment?.bankAccount,
            influencer: item.influencer,
            campaign: {
              url: campaignUrl(item.campaign),
              advertiser: item.campaign?.advertiser?.name,
              name: item.campaign?.name,
            },
            reward: item.reward,
            claimedDate: moment(item.claimed).format('YYYY-MM-DD'),
          } as RowData)
      )
      .sort((a, b) => sortByOrder(a, b, orderBy, order));

  const HeaderCell = ({
    id,
    align,
    disableSort,
  }: {
    id: string;
    align: 'left' | 'center' | 'right';
    disableSort?: boolean;
  }) => (
    <TableCell key={id} align={align}>
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? order : SORT_ORDER.asc}
        disabled={disableSort}
        onClick={() => handleOrderBy(id)}
      >
        <Typography variant="h5">{capitalize(id)}</Typography>
      </TableSortLabel>
    </TableCell>
  );

  const CustomRow = ({data}: {data: RowData}) => (
    <TableRow>
      <TableCell>
        <InfluencerProfileCard influencer={data.influencer} />
      </TableCell>
      <TableCell>
        <Link to={data.campaign.url}>
          <div
            style={{
              display: 'grid',
              maxWidth: '15em',
            }}
          >
            <span>{data.campaign.advertiser},</span>
            <span>{data.campaign.name}</span>
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">
        {!data.bankAccount ? (
          <span>n/a</span>
        ) : (
          <div
            style={{
              display: 'grid',
              maxWidth: '15em',
            }}
          >
            <span>
              {data.bankAccount.accountName} ({data.bankAccount.currency}),
            </span>
            <span>{data.bankAccount.accountNumber}</span>
          </div>
        )}
      </TableCell>

      <TableCell align="right">{data.reward.formattedValue}</TableCell>
      <TableCell align="center">{data.claimedDate}</TableCell>
      <TableCell align="center">
        <MenuPopover
          items={[
            <ApprovePaymentButton key={data.paymentId} paymentId={data.paymentId} />,
            <MarkAsPaidButton key={data.offerId} offerId={data.offerId} />,
          ]}
        />
      </TableCell>
    </TableRow>
  );

  if (data) {
    return (
      <>
        <CurrencyFilter data={data} value={currencyFilter} onChange={setCurrencyFilter} />

        <TableContainer component={Paper} variant="outlined">
          <Table aria-label="Approvals">
            <TableHead>
              <TableRow>
                <HeaderCell id={TABLE_HEADERS.influencer} align="left" />
                <HeaderCell id={TABLE_HEADERS.campaign} align="left" />
                <HeaderCell id={TABLE_HEADERS.account} align="left" />
                <HeaderCell id={TABLE_HEADERS.reward} align="right" />
                <HeaderCell id={TABLE_HEADERS.claimed} align="center" />
                <HeaderCell id={TABLE_HEADERS.approval} align="center" disableSort />
              </TableRow>
            </TableHead>
            <TableBody>
              {createRows(data.unapprovedPayments).map((row, i) => (
                <CustomRow key={`row-${i}`} data={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  return null;
};

const UnapprovedPayments = gql`
  query UnapprovedPayments {
    unapprovedPayments {
      id
      payment {
        id
        type
        bankAccount {
          id
          accountName
          accountNumber
          currency
        }
      }
      influencer {
        id
        fullName
        email
        profilePicture
        targetRegion {
          id
          name
          country
          countryCode
        }
        ...socialPlatformsFields
      }
      campaign {
        id
        name
        advertiser {
          id
          name
          domain
        }
      }
      reward {
        value
        formattedValue
        currency
      }
      claimed
    }
  }
  ${Influencer.socialPlatformsFragment}
`;

export default Approvals;
