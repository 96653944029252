import React, {ReactNode, useState} from 'react';
import {Icon} from '@iconify/react';
import {makeStyles} from '@material-ui/styles';
import {Tooltip} from '@material-ui/core';

import {black80} from 'consts/brand.integration';

export interface Props {
  children: string | ReactNode | ReactNode[];
  noIcon?: boolean;
  copyString?: string;
}

const CopyPasteable: React.FC<Props> = ({children, noIcon, copyString}) => {
  const classes = useStyles();
  const origTipCopy = 'Copy to clipboard';
  const [tipCopy, setTipCopy] = useState(origTipCopy);

  const copyToClipBoard = () => {
    let str: string;
    if (copyString) {
      str = copyString;
    } else {
      str = typeof children === 'string' ? children : JSON.stringify(children);
    }
    navigator.clipboard.writeText(str);
    showTemporaryCopied();
  };

  const showTemporaryCopied = () => {
    setTipCopy('Copied!');
    setTimeout(() => setTipCopy(origTipCopy), 3000);
  };

  return (
    <Tooltip title={tipCopy} arrow aria-label="copy">
      <div className={classes.root} onClick={copyToClipBoard}>
        {children} {!noIcon && <Icon icon="tdesign:copy" fontSize="0.7rem" color={black80} />}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'inline',
    cursor: 'pointer',
  },
});

export default CopyPasteable;
