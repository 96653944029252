import {makeStyles} from '@material-ui/styles';

import {black, transparentPurple} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles({
  inputRoot: {
    height: 32,
    width: 152,
    border: '1px solid black',
    borderRadius: 0,
    color: 'black',
    fontSize: 14,
    padding: '0 55px 0 5px!important',
    '& .MuiAutocomplete-endAdornment button': {
      color: 'black',
    },
    '&.Mui-focused': {
      border: '2px solid black',
    },
    '& .MuiOutlinedInput-input': {
      border: 0,
      height: 0,
      '&::placeholder': {
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  option: {
    fontSize: 14,
    '&[data-focus="true"]': {
      backgroundColor: transparentPurple,
      borderColor: 'transparent',
    },
    '&[aria-selected="true"]': {
      backgroundColor: transparentPurple,
      borderColor: 'transparent',
    },
  },
  icon: {
    color: black,
  },
  paper: {
    width: 'fit-content',
    minWidth: 152,
  },
  clearIndicator: {
    visibility: 'visible',
    paddingTop: 2,
  },
  clearButton: {
    height: 20,
  },
});
