import React, {useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {useHistory, RouteComponentProps} from 'react-router-dom';
import * as qs from 'query-string';

import {Form, FormSubmitButton, FormPasswordInput, FormTextInput} from 'components/Widgets/Forms';

import useHomePage from '../../../../hooks/useHomePage';
import styles from './style.css';
import {formData, Params} from './types';
import {OnboardPasswordMutation} from './graphqlQueries';
import {minLength} from '../../../../services/validators';
import {Heading} from '../../../../components/Widgets';

const OnboardPassword: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const homePage = useHomePage();
  const params: Params = qs.parse(history.location.search);
  const [updateCurrentUser, updateCurrentUserResult] = useMutation(OnboardPasswordMutation);

  useEffect(() => {
    if (updateCurrentUserResult.data) {
      if (updateCurrentUserResult.data.updateCurrentUser?.ok) {
        const redirectUrl = params?.redirect_pathname ?? homePage;
        history.push(redirectUrl);
        window.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCurrentUserResult]);

  const onSubmit = (data: formData) => {
    updateCurrentUser({
      variables: {fullName: data.fullName, password: data.password},
      refetchQueries: ['TopNavigationQuery', 'EditProfileQuery'],
    });
  };

  return (
    <div className={styles.center}>
      <Heading title="Complete Signup" />

      <Form onSubmit={onSubmit}>
        <FormTextInput
          name="fullName"
          validators={{
            length: minLength(3),
          }}
          messages={{
            length: 'Name must be at least 3 characters',
          }}
          show="touched"
          placeholder="Your Name"
          required
        />

        <FormPasswordInput name="password" placeholder="Set a Password" />
        <FormSubmitButton style={{width: '100%'}} text="Create Account" />
      </Form>
    </div>
  );
};

export default OnboardPassword;
