import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, Typography, TableCell, makeStyles} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {TableZebraRow, FormattedDate} from 'components/Widgets';
import InfluencerProfileCard from 'components/Widgets/InfluencerProfileCard/InfluencerProfileCard';

import {SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE, GIG_STATUS} from 'consts/variables';
import {socialIcons} from 'consts/icons';

import {CalendarRow} from './types';

interface Props {
  row?: CalendarRow;
  timezone: string;
}

const gigStatus: {[key: string]: {className: string; icon: string}} = {
  awaiting_influencer_submission: {className: 'pending', icon: 'ic:baseline-pending'},
  awaiting_staff_review: {className: 'pending', icon: 'ic:baseline-pending'},
  awaiting_client_review: {className: 'pending', icon: 'ic:baseline-pending'},
  rejected_by_staff: {className: 'rejected', icon: 'ic:baseline-error'},
  reported_by_client: {className: 'pending', icon: 'ic:baseline-error'},
  resubmission_requested: {className: 'pending', icon: 'ic:baseline-pending'},
  awaiting_influencer_post: {className: 'posted', icon: 'ic:baseline-check-circle'},
  posted: {className: 'posted', icon: 'ic:baseline-check-circle'},
  completed: {className: 'posted', icon: 'ic:baseline-check-circle'},
};

const ContentTable: React.FC<Props> = ({row, timezone}) => {
  const classes = useStyles();
  if (!row) {
    return null;
  }

  return (
    <>
      <TableZebraRow key={row.id} aria-label="Content Calendar">
        <TableCell component="th" scope="row" className={classes.influencerCell}>
          <InfluencerProfileCard
            influencer={{
              id: row.influencer_id,
              profilePicture: row.influencer_instagram_profile_picture_url,
              fullName: row.influencer_full_name,
              instagramAccount: row.influencer_instagram_handle && {
                username: row.influencer_instagram_handle,
              },
              tiktokAccount: row.influencer_tiktok_handle && {
                username: row.influencer_tiktok_handle,
              },
              youtubeChannel: row.influencer_youtube_handle && {
                customUrl: row.influencer_youtube_handle,
              },
              twitchChannel: row.influencer_twitch_handle && {
                url: row.influencer_twitch_handle,
              },
            }}
          />
        </TableCell>
        <TableCell align="left">
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <div className={classes.iconContainer}>
                <Icon
                  icon={socialIcons[row.post_type] || 'ic:baseline-photo'}
                  width={24}
                  className={classes.iconContainer}
                />
              </div>
            </Grid>
            {row.gig_status && (
              <Grid item>
                <Icon
                  icon={gigStatus[row.gig_status].icon}
                  className={classes[gigStatus[row.gig_status].className as keyof typeof classes]}
                  width={14}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2" className={classes.link}>
                {row.gig_id ? (
                  <Link
                    to={
                      row.post_social_media_url
                        ? {pathname: row.post_social_media_url}
                        : `/influencers/${row.influencer_id}/gigs/${row.gig_id}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.placement}
                    {row.post_social_media_url && <Icon icon="mdi:external-link" width={14} />}
                  </Link>
                ) : (
                  row.placement
                )}
              </Typography>
              {row.gig_status && (
                <Typography variant="caption">
                  {row.gig_status !== 'posted' && GIG_STATUS[row.gig_status].status}
                </Typography>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">
          <FormattedDate
            date={row.date_submitted}
            timezone={timezone}
            format={SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE}
          />
        </TableCell>
        <TableCell align="center">
          <FormattedDate
            date={row.date_posted_to_social_media_platform}
            timezone={timezone}
            format={SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE}
          />
        </TableCell>
        <TableCell align="center">
          <FormattedDate
            date={row.post_deadline}
            timezone={timezone}
            format={SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE}
          />
        </TableCell>
      </TableZebraRow>
    </>
  );
};

const useStyles = makeStyles({
  influencerCell: {
    width: '280px',
  },
  influencerName: {
    fontWeight: 700,
  },
  influencerHandle: {
    '& svg': {
      marginRight: '2px',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& svg': {
      marginLeft: '5px',
    },
  },
  posted: {
    color: 'green',
  },
  pending: {
    color: 'orange',
  },
  rejected: {
    color: 'red',
  },
});

export default ContentTable;
