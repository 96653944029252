import compose from 'lodash/flowRight';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FetchResult} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import * as qs from 'query-string';
import {Grid} from '@material-ui/core';

import {MaterialDesignIcon} from 'components/Icons';
import {
  Button,
  Definition,
  FormattedDate,
  LoaderDots,
  Prompt,
  RequiresPermission,
  Select,
  Tooltip,
  Checkbox,
} from 'components/Widgets';
import InfluencerProfileCard from 'components/Widgets/InfluencerProfileCard/InfluencerProfileCard';

import App404 from 'scenes/App404/App404';
import {
  capitalize,
  currencySymbolForCurrency,
  numberMillified,
  numberWithCommas,
  singularOrPlural,
  formattedRewardString,
} from 'consts/utilities';
import {LONG_DATE_FORMAT} from 'consts/variables';
import {campaignParticipantPlatformFollowerCount, sumMultiPlatform} from 'consts/influencerHelper';
import {useLocalStorage} from 'consts/hooks';

import {
  Filters,
  InfluencerModal,
  ListOffers,
  ListOffersWithSelection,
  ExtraRow,
  ViewOffer,
} from './components';
import {CampaignHeader} from '../components';
import BudgetDefinition from '../BudgetDefinition';
import styles from './style.css';
import {
  CampaignParticipantsQuery,
  CampaignQuery,
  MarkAsSelectedMutation,
  OffersApprovedByBrandQuery,
  OffersCandidatesQuery,
  OffersDeclinedQuery,
  OffersInterestedQuery,
  RevokeOfferMutation,
  RevokeRequestedOffersMutation,
  UnmarkAsSelectedMutation,
} from './queries';

const ExtraRowToggler = () => {
  const [showExtraRow, setShowExtraRow] = useLocalStorage('showExtraRow', false);
  return (
    <Checkbox
      label="Show extra influencer info"
      checked={showExtraRow}
      onChange={() => setShowExtraRow(!showExtraRow)}
    />
  );
};

interface Props extends RouteComponentProps {
  data: {
    loading: boolean;
    campaign: Campaign;
  };
  filter?: string;
  awaitingSubmission?: boolean;
  /* eslint-disable @typescript-eslint/ban-types */
  /**
   * The following types are copied from the result of the HOC. I'm hoping that
   * by converting this to a functional component we'll be able to improve these
   * by using the result of `useQuery` and `useMutation`.
   */
  revokeOffer: (id: any) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  revokeRequestedOffers: () => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  markAsSelected: (id: any) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  unmarkAsSelected: (id: any) => Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  /* eslint-enable @typescript-eslint/ban-types */
}
const CampaignParticipants: React.FC<Props> = props => {
  const revokeOffer = id => {
    props.revokeOffer(id);
  };

  const columnsForFilter = (filter, shippingRequired, showEngagements, showImpressions) => {
    const columns = {
      influencer: {
        heading: 'Influencer',
        data: o => o,
        render: (offer: Offer) => <InfluencerProfileCard influencer={offer.influencer} />,
        footer: {
          reducer: accumulator => accumulator + 1,
          render: f => `${f} ${singularOrPlural('influencer', f)}`,
        },
      },
      accepted: {
        heading: 'Reserved',
        data: o => o.accepted,
        render: date => <FormattedDate format={LONG_DATE_FORMAT} date={date} />,
      },
      reward: {
        heading: 'Reward',
        data: o => formattedRewardString(o.rewardBreakdown),
        footer: {
          reducer: (accumulator, offer) => ({
            currency: offer.reward.currency,
            total: (accumulator.total || 0) + offer.rewardBreakdown.netValue.value,
            vat: accumulator.vat || offer.rewardBreakdown.showNetAndVat ? ' + applicable VAT' : '',
          }),
          render: acc =>
            `${currencySymbolForCurrency(acc.currency)}${numberWithCommas(acc.total)}${acc.vat}`,
        },
      },
      rejected: {
        heading: 'Rejected',
        data: o => o.rejected || o.revokeEvent?.created,
        render: date => (date ? <FormattedDate date={date} /> : <p>-</p>),
      },
      state: {
        heading: 'State',
        data: o => o.state,
        render: state => {
          switch (state) {
            case 'rejected':
              return <p>Rejected by Influencer</p>;
            case 'revoked':
              return <p>Revoked by TAKUMI</p>;
            case 'rejected_by_brand':
              return <p>Rejected by Brand</p>;
            default:
              return null;
          }
        },
      },
      expectedFollowers: {
        heading: 'Followers',
        data: offer => ({
          instagram: offer.influencer.instagramAccount?.followers,
          tiktok: offer.influencer.tiktokAccount?.followers,
        }),
        render: followerData => campaignParticipantPlatformFollowerCount(followerData),
        footer: {
          reducer: (accumulator, offer) => sumMultiPlatform(accumulator, offer),
          render: splitFollowers => campaignParticipantPlatformFollowerCount(splitFollowers),
        },
      },
      estimatedEngagements: {
        heading: 'Estimated engagements',
        data: d => d.influencer.estimatedEngagementsPerPost,
        render: engagements => numberWithCommas(engagements),
        footer: {
          reducer: (accumulator, d) => accumulator + d.influencer.estimatedEngagementsPerPost,
          render: f => numberWithCommas(f),
        },
      },
      estimatedImpressions: {
        heading: 'Estimated impressions',
        data: d => d.influencer.estimatedImpressions,
        render: impressions => numberWithCommas(impressions),
        footer: {
          reducer: (accumulator, d) => accumulator + d.influencer.estimatedImpressions,
          render: f => numberWithCommas(f),
        },
      },
      cancel: {
        heading: '',
        data: o => o,
        render: o =>
          o.isClaimable ? (
            'Claimable'
          ) : o.state === 'revoked' ? (
            'Revoked'
          ) : (
            <RequiresPermission permission="manage_influencers">
              <Prompt
                title="Revoke offer"
                body="Are you sure you want to revoke this offer?"
                confirmText="Revoke offer"
                control={<Button small dangerOutline text="Revoke Offer&hellip;" />}
                onSubmit={() => revokeOffer(o.id)}
              />
            </RequiresPermission>
          ),
      },
      action: {
        heading: 'Action',
        data: o => o,
        render: offer => {
          if (offer.state == 'accepted') {
            return 'Accepted';
          }

          return (
            <RequiresPermission permission="manage_influencers">
              {offer.isSelected ? (
                <Button inline text="Unselect" onClick={() => props.unmarkAsSelected(offer.id)} />
              ) : (
                <div className={styles.actions}>
                  {!props.data.campaign.isFullyReserved && (
                    <Button
                      inline
                      className={styles.actionButton}
                      text={
                        <Tooltip placement="bottom" overlay={<span>Select</span>}>
                          <MaterialDesignIcon icon="add_circle" />
                        </Tooltip>
                      }
                      onClick={() => props.markAsSelected(offer.id)}
                    />
                  )}
                  <Prompt
                    title="Reject influencer?"
                    body="Are you sure you want to reject this influencer?"
                    confirmText="Reject influencer"
                    control={
                      <Button
                        inline
                        danger
                        className={styles.actionButton}
                        text={
                          <Tooltip placement="bottom" overlay={<span>Reject</span>}>
                            <MaterialDesignIcon icon="remove_circle" />
                          </Tooltip>
                        }
                      />
                    }
                    onSubmit={() => props.revokeOffer(offer.id)}
                  />
                </div>
              )}
            </RequiresPermission>
          );
        },
        width: 120,
      },
      offer: {
        data: o => o,
        render: offer => <ViewOffer influencer={offer.influencer} />,
      },
    };

    switch (filter) {
      case 'requested':
      case 'candidate':
      case 'approved_by_brand':
        return [columns.influencer, columns.expectedFollowers]
          .concat(showEngagements && [columns.estimatedEngagements])
          .concat(showImpressions && [columns.estimatedImpressions])
          .concat(hideInDemoMode() && [columns.reward])
          .concat(supportsSelection(filter) && columns.action)
          .concat([columns.offer]);
      case 'invited':
        return [columns.influencer, columns.expectedFollowers]
          .concat(showEngagements && [columns.estimatedEngagements])
          .concat(showImpressions && [columns.estimatedImpressions])
          .concat(hideInDemoMode() && [columns.reward])
          .concat([columns.cancel]);
      case 'accepted':
        return [columns.influencer, columns.accepted, columns.expectedFollowers]
          .concat(showEngagements && [columns.estimatedEngagements])
          .concat(showImpressions && [columns.estimatedImpressions])
          .concat(hideInDemoMode() && [columns.reward])
          .concat(hideInDemoMode() && shippingRequired && [columns.address, columns.shipping])
          .concat([columns.cancel])
          .concat([columns.offer]);
      case 'rejected':
        return [columns.influencer, columns.expectedFollowers]
          .concat(showImpressions && [columns.estimatedImpressions])
          .concat(hideInDemoMode())
          .concat([columns.rejected, columns.state])
          .concat([columns.offer]);
      default:
        throw new Error(`Unknown filter: ${filter}`);
    }
  };

  const queryForFilter = filter => {
    switch (filter) {
      case 'requested':
        return OffersInterestedQuery;
      case 'candidate':
        return OffersCandidatesQuery;
      case 'approved_by_brand':
        return OffersApprovedByBrandQuery;
      case 'invited':
      case 'accepted':
        return CampaignParticipantsQuery;
      case 'rejected':
        return OffersDeclinedQuery;
      default:
        throw new Error(`Unknown filter ${filter}`);
    }
  };

  const supportsSelection = filter => {
    return ['requested', 'approved_by_brand'].includes(filter);
  };

  const hideInDemoMode = () => {
    return localStorage.getItem('DEMO_MODE') !== 'true';
  };

  const getRouteFilters = () => {
    const {filter} = props;
    if (filter === 'rejected') {
      return ['rejected', 'revoked', 'rejected_by_brand'];
    }
    return [filter];
  };

  const {data, filter, awaitingSubmission, location, history} = props;
  const params = qs.parse(location.search);

  if (data.loading) {
    return <LoaderDots />;
  }

  const campaign = data.campaign;
  if (!campaign) {
    return <App404 />;
  }

  const campaignFilters = [
    !campaign.applyFirst && 'invited',
    campaign.applyFirst && 'requested',
    campaign.brandMatch && 'candidate',
    campaign.brandMatch && 'approved_by_brand',
    'accepted',
    'rejected',
  ].filter(Boolean);

  const columns = columnsForFilter(
    filter,
    campaign.shippingRequired,
    campaign.rewardModel == 'engagement',
    campaign.rewardModel == 'impressions'
  ).filter(Boolean);

  return (
    <div className={styles.root}>
      <CampaignHeader title="Participants" campaignId={campaign.id} showProgress />

      <Grid container justifyContent="space-between" style={{marginBottom: '20px'}}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Definition gold title="Type" definition={capitalize(campaign.rewardModel)} />
            </Grid>
            <Grid item>
              <Definition
                gold
                title={capitalize(campaign.rewardModel)}
                definition={numberMillified(campaign.target?.targetOverall)}
              />
            </Grid>
            <Grid item>
              <BudgetDefinition budget={campaign.price.formattedValue} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Filters campaignFilters={campaignFilters} filterCounts={campaign.participation} />
      <div className={styles.toggle}>
        <ExtraRowToggler />
      </div>
      <div className={styles.lessThanOne}>
        {params.state === 'requested' && (
          <div className={styles.percWrapper}>
            <h3>Minimum engagement rate</h3>
            <div className={styles.percSelect}>
              <Select
                value={params.minER || 1}
                options={[
                  {name: '0%', value: 0},
                  {name: '1%', value: 1},
                  {name: '2%', value: 2},
                  {name: '3%', value: 3},
                  {name: '4%', value: 4},
                  {name: '5%', value: 5},
                  {name: '6%', value: 6},
                  {name: '7%', value: 7},
                  {name: '8%', value: 8},
                  {name: '9%', value: 9},
                  {name: '10%', value: 10},
                ]}
                onChange={target => {
                  history.replace({
                    ...location,
                    search: qs.stringify({...params, minER: target}),
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>

      {supportsSelection(filter) && !campaign.isFullyReserved && (
        <ListOffersWithSelection
          columns={columns}
          extraRow={ExtraRow}
          query={queryForFilter(filter)}
          variables={{campaignId: campaign.id, minER: params.minER}}
          brandMatch={campaign.brandMatch}
          filters={getRouteFilters()}
        />
      )}
      {supportsSelection(filter) && campaign.isFullyReserved && (
        <div className={styles.rejectAll}>
          <Prompt
            title="Reject non-accepted influencers"
            body={
              <p>
                Are you sure you want to reject all influencers in the{' '}
                {props.filter === 'approved_by_brand' ? 'approved by brand' : 'interested'} list?
              </p>
            }
            control={<Button text="Reject non-accepted influencers" />}
            onSubmit={props.revokeRequestedOffers}
          />
        </div>
      )}
      {(!supportsSelection(filter) || campaign.isFullyReserved) && (
        <ListOffers
          query={queryForFilter(filter)}
          extraRow={ExtraRow}
          variables={{campaignId: campaign.id, state: filter, awaitingSubmission}}
          columns={columns}
          filters={getRouteFilters()}
        />
      )}
      <InfluencerModal
        id={params.influencer}
        closeModal={() => {
          history.replace({
            ...location,
            search: qs.stringify({...params, influencer: undefined}),
          });
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, {location}) => {
  const params = qs.parse(location.search);
  return {
    filter: params.state || 'accepted',
    awaitingSubmission: params['awaiting-submission'],
  };
};

// prettier-ignore
export default compose(
  withRouter,
  connect(mapStateToProps),
  graphql(CampaignQuery, {
    options: ({ match: {params}, filter, awaitingSubmission }) => ({
      variables: {
        campaignId: params.campaign,
        state: filter,
        awaitingSubmission,
      },
    }),
  }),
  graphql(RevokeRequestedOffersMutation, {
    props: ({mutate, ownProps: {data:{campaign}, filter}}) => ({
      revokeRequestedOffers: () => mutate({
        variables: {id: campaign.id, state: filter},
        refetchQueries: ['OffersDeclinedQuery', 'OffersInterestedQuery', 'OffersApprovedByBrandQuery'],
      }),
    }),
  }),
  graphql(RevokeOfferMutation, {
    props: ({mutate, ownProps: {data, match: {params}}}) => ({
      revokeOffer: id => mutate({
        variables: {id},
        refetchQueries: ['OffersDeclinedQuery'],
        optimisticResponse: {
          revokeOffer: {
            __typename: 'RevokeOffer',
            ok: true,
            offer: {
              __typename: 'Offer',
              id: id,
              state: 'revoked',
              campaign: {
                __typename: 'Campaign',
                id: params.campaign,
                name: data.campaign.name,
                isFullyReserved: data.campaign.isFullyReserved,
              }
            }
          },
        },
      }),
    }),
  }),
  graphql(MarkAsSelectedMutation, {
    props: ({mutate}) => {
      return {
        markAsSelected: id => mutate({
          variables: {id},
          optimisticResponse: {
            markAsSelected: {
              __typename: 'MarkAsSelected',
              ok: true,
              offer: {
                __typename: 'Offer',
                id,
                isSelected: true,
              }
            }
          }
        }),
      };
    },
  }),
  graphql(UnmarkAsSelectedMutation, {
    props: ({mutate}) => {
      return {
        unmarkAsSelected: id => mutate({
          variables: {id},
          optimisticResponse: {
            unmarkAsSelected: {
              __typename: 'UnmarkAsSelected',
              ok: true,
              offer: {
                __typename: 'Offer',
                id,
                isSelected: false,
              }
            }
          }
        }),
      };
    },
  }),
)(CampaignParticipants);
