import React, {useState, useEffect} from 'react';
import {Divider, Card, makeStyles, Grid, Typography} from '@material-ui/core';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {ApiError, NoPerformanceData, LoaderDots} from '../../../../../components/Widgets';
import InfluencerTable from './InfluencerTable';
import {useDataServiceApi} from '../../../../../hooks/useDataServiceApi';
import {influencerSelect} from '../../../../../consts/dataApiProperties';
import {DATA_SERVICE_MODEL_INFO} from '../helper';
import {DataExport, PerformanceData} from '../types';

interface Props {
  campaign?: Campaign;
  apiData: (data: DataExport) => void;
}

const CampaignPerformanceByInfluencer: React.FC<Props> = ({campaign, apiData}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, loading, fetchCampaignPerformanceByInfluencerData} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<any>(null);
  const title = 'Creators';

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data = await fetchCampaignPerformanceByInfluencerData({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
          orderBy: 'impression_total.desc',
          select: influencerSelect,
        });
        setResourceData(data);
        apiData(data);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, campaign?.id, fetchCampaignPerformanceByInfluencerData]);

  const influencerMetrics = resourceData
    ? resourceData.map((apiResponse: PerformanceData) => {
        const influencer = {
          influencer_id: apiResponse.influencer?.influencer_id,
          full_name: apiResponse.influencer?.full_name,
          instagram_handle: apiResponse.influencer?.instagram_handle,
          tiktok_handle: apiResponse.influencer?.tiktok_handle,
          instagram_profile_url: apiResponse.influencer?.instagram_profile_url,
          tiktok_profile_url: apiResponse.influencer?.tiktok_profile_url,
          instagram_profile_picture_url: apiResponse.influencer?.instagram_profile_picture_url,
          tiktok_profile_picture_url: apiResponse.influencer?.tiktok_profile_picture_url,

          follower_total: {
            raw: apiResponse.follower_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.follower_total.formatter!(
              apiResponse.follower_total
            ),
          },
          digital_content_count: {
            raw: apiResponse.digital_content_count,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.digital_content_count
              .formatter!(apiResponse.digital_content_count),
          },
          impression_total: {
            raw: apiResponse.impression_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.impression_total.formatter!(
              apiResponse.impression_total
            ),
          },
          reach_total: {
            raw: apiResponse.reach_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.reach_total.formatter!(
              apiResponse.reach_total
            ),
          },
          engagement_total: {
            raw: apiResponse.engagement_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_total.formatter!(
              apiResponse.engagement_total
            ),
          },
          engagement_rate_by_impressions: {
            raw: apiResponse.engagement_rate_by_impressions,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_rate_by_impressions
              .formatter!(apiResponse.engagement_rate_by_impressions),
          },
        };
        return influencer;
      })
    : [];

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3}>
        {influencerMetrics?.length > 0 ? (
          <Grid item xs={12}>
            <Card>
              <InfluencerTable
                rows={influencerMetrics}
                enabledMetrics={(campaign?.organicPerformanceMetrics ?? []) as string[]}
              />
            </Card>
          </Grid>
        ) : (
          <NoPerformanceData title="Creator performance metrics" />
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPerformanceByInfluencer;
