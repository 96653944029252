import {isNumber} from 'lodash/fp';

import ErrorReporting from '../services/error-reporting';

export const numberWithCommas = (num: null | undefined | number | string): string => {
  // Converts numbers into a string with comma delimiters
  // Example: 1234567 to 1,234,567
  if (num === null || num === undefined) {
    return '';
  } else if (isNumber(num)) {
    return num.toLocaleString();
  } else {
    return parseFloat(num).toLocaleString();
  }
};

export const numberMillified = (num: null | undefined | number | string): string => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  if (num === null || num === undefined) return '';

  if (num < 999) {
    const rouded = Math.round(Number(num));
    return rouded.toString();
  }

  // Use 3 significant figures
  const convertedNum = parseFloat(Number(num).toPrecision(3));
  const tier = (Math.log10(convertedNum) / 3) | 0;

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = convertedNum / scale;
  return scaled + suffix;
};

export type NameValueMetaObj = {
  name: string;
  value: string;
  meta: string | undefined;
};

export const createObj = (name: string, value: string, meta?: string): NameValueMetaObj => {
  return {name, value, meta};
};

export const range = (from: number, to: number): number[] => {
  const arr = [];
  for (let i = from; i <= to; i = i + 1) {
    arr.push(i);
  }
  return arr;
};

export const getPageTitle = (text?: string): string => {
  if (!text) {
    return 'TAKUMI';
  }
  return `${text} - TAKUMI`;
};

export const capitalize = (text: string | undefined | null): string | null => {
  if (!text) {
    return null;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const singularOrPlural = (singularWord: string, counter: number): string => {
  return counter === 1 ? singularWord : `${singularWord}s`;
};

export const sanitizeWhitespace = (str: string): string => {
  if (str == null) return str;
  return str.replace(/(\u2800)/g, '');
};

export const hasHashtagAd = (caption: string): boolean => {
  if (caption === '#ad' || caption === '*ad') {
    return true;
  }
  return caption.match(/((?:\*|#)ad)\b/i) !== null;
};

export const currencyForMarket = (market: string): string => {
  if (market === 'uk') {
    return '£';
  }

  if (market === 'eu') {
    return '€';
  }

  if (market === 'us') {
    return '$';
  }

  if (market === 'is') {
    return 'kr.';
  }

  if (market === 'za') {
    return 'R';
  }

  return '';
};

export const currencySymbolForCurrency = (market: string): string => {
  if (market === 'GBP') {
    return '£';
  }

  if (market === 'EUR') {
    return '€';
  }

  if (market === 'USD') {
    return '$';
  }

  if (market === 'ISK') {
    return 'kr.';
  }

  if (market === 'ZAR') {
    return 'R';
  }

  return '';
};

export const marketFromSlug = (slug: string): string | null => {
  switch (slug) {
    case 'us':
      return 'United States';
    case 'eu':
      return 'European Union';
    case 'uk':
      return 'United Kingdom';
    case 'is':
      return 'Iceland';
    default:
      return null;
  }
};

export const ordinalSuffixOf = (i: number): string => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export function formatPercentage(value: number | null | undefined): string {
  const val = value ?? 0;
  const rounded = Math.round(val * 100) / 100;
  return rounded.toFixed(2) + '%';
}

export function formatFraction(value: number): string {
  return formatPercentage(value * 100);
}

export function formatCurrency(value: number, currencyCode?: string): string {
  if (!value) return '';
  const symbol = currencyForMarket(currencyCode ? currencyCode : '');
  return symbol + value.toFixed(2);
}

export const formattedRewardString = (reward: RewardBreakdown): string => {
  try {
    if (reward.showNetAndVat) {
      return `${reward.netValue?.formattedValue} + VAT`;
    }
    return reward.totalValue?.formattedValue;
  } catch (error: any) {
    ErrorReporting.captureError(error);
    throw error;
  }
};

export const formatRemaining = (campaign: Campaign) => {
  if (campaign.state === 'completed') {
    return 'Completed';
  } else if (campaign.isFullyReserved) {
    return 'Fully reserved';
  }
  return 'Launched';
};
