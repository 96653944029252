import React from 'react';

import styles from './style.css';

type Props = {
  title: string;
  emails: string[];
};

const EmailList: React.FC<Props> = ({title, emails}) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.list}>{emails.join(', ')}</div>
    </div>
  );
};

export default EmailList;
