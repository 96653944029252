// eslint-disable-next-line import/named
import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black, white, fontFamily} from 'consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      fontSize: 14,
      color: black,
    },
    collapsed: {
      display: 'none',
    },
    menu: {
      borderRadius: '4px',
      boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.1)',
      minWidth: 320,
      zIndex: 10,
      boxSizing: 'border-box',
      backgroundColor: white,
      height: 'calc(100vh - 500px)',
      maxHeight: 608,
      minHeight: 318,
      overflowY: 'auto',
      marginBottom: 50,
    },
    autoSizableMenu: {
      width: 'auto',
    },
    chips: {
      marginTop: '16px',
      display: 'flex',
      flexWrap: 'wrap',
    },
  })
);

export const placeholderIndustryStyle = {
  fontSize: '14px',
  fontFamily: fontFamily,
};
