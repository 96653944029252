import {makeVar} from '@apollo/client';

import {getStoredApiToken, removeStoredApiToken, storeApiToken} from './auth';

/**
 * This is the key used to store the access token for a user whilst they are
 * impersonating another user. It should only exist in localStorage during
 * impersonation.
 */
export const ACTOR_TOKEN_KEY = 'actor_token';

const getActorToken = () => window.localStorage.getItem(ACTOR_TOKEN_KEY);
const storeActorToken = (token: string) => window.localStorage.setItem(ACTOR_TOKEN_KEY, token);
const removeActorToken = () => window.localStorage.removeItem(ACTOR_TOKEN_KEY);

const IMPERSONATION_TOKEN_EXPIRY_KEY = 'impersonation_token_expiry';
const storeImpersonationTokenExpiry = (expires: string) =>
  window.localStorage.setItem(IMPERSONATION_TOKEN_EXPIRY_KEY, expires);
const removeImpersonationTokenExpiry = () =>
  window.localStorage.removeItem(IMPERSONATION_TOKEN_EXPIRY_KEY);

export const startUserImpersonation = ({token, expires}: {token: string; expires: string}) => {
  const actorToken = getStoredApiToken()!;

  /**
   * NOTE: When we call `removeStoredApiToken` it will remove ALL tokens (e.g.
   * the token used for authentication, AND the actor token). We therefore remove
   * the existing tokens first before setting the new values.
   */
  removeStoredApiToken();
  storeActorToken(actorToken);
  storeApiToken(token);
  storeImpersonationTokenExpiry(expires);

  /**
   * We reload the application after setting the relevant tokens to ensure that
   * all data is reloaded and queries re-ran.
   */
  window.location.href = '/';
};

export const endUserImpersonation = () => {
  const token = getActorToken();
  removeActorToken();

  removeStoredApiToken();
  removeImpersonationTokenExpiry();
  if (token) storeApiToken(token);

  /**
   * Just like when starting impersonation, we reload the entire application
   * to ensure all data is reloaded correctly.
   */
  window.location.href = '/';
};

export const isImpersonating = makeVar(Boolean(getActorToken()));
export const getImpersonationExpiryDate = () =>
  window.localStorage.getItem(IMPERSONATION_TOKEN_EXPIRY_KEY);
