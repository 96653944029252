import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
    },
    highlight: {
      marginRight: '32px',
    },
  })
);
