import React, {useEffect, useRef, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {REJECT_OPTIONS} from 'consts/variables';

import Modal, {ModalElement} from '../../Widgets/Modal';
import {LoaderDots} from '../../Widgets';

interface Props {
  gig: Gig;
}

const GigReject: React.FC<Props> = ({gig}) => {
  const classes = useStyles();
  const [rejectGig, {loading}] = useMutation(RejectMutation);
  const [otherSelected, setOtherSelected] = useState(false);
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      reason: '',
      otherReason: '',
    },
    validationSchema: yup.object({
      reason: yup.string().required(),
      otherReason: yup.lazy(() => {
        return otherSelected ? yup.string().required() : yup.string().notRequired();
      }),
    }),
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    if (formik.values.reason) {
      setOtherSelected(formik.values.reason === 'other');
    }
  }, [formik.values.reason]);

  const handleSubmit = () => {
    const reason = otherSelected ? formik.values.otherReason : formik.values.reason;
    rejectGig({variables: {id: gig.id, reason: reason}});
    modal.current?.close();
  };

  const renderToggler = () => (
    <Button className={classes.toggler} variant="text" fullWidth>
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          Reject
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle2" color="secondary">
            Rejecting a gig makes the gig claimable. It should be used if the gig is rejected by the
            client, but is on brief. Revoking an offer is to remove an influencer from a campaign.
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );

  const renderForm = () => (
    <FormControl component="fieldset" fullWidth>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <RadioGroup
            aria-label="reason"
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
          >
            {REJECT_OPTIONS.map((option: string, index: number) => (
              <FormControlLabel
                key={`option-${index}`}
                id={`option-${index}`}
                value={option}
                control={<Radio color="primary" />}
                label={option}
              />
            ))}
            <FormControlLabel
              key={`option-other`}
              id={`option-other`}
              value="other"
              control={<Radio color="primary" />}
              label="Other reason"
              checked={otherSelected}
            />
          </RadioGroup>
        </Grid>
        <Grid item>
          {otherSelected && (
            <TextField
              id="other-reason"
              name="otherReason"
              label="Reason"
              value={formik.values.otherReason}
              aria-describedby={'otherReason'}
              error={formik.touched.otherReason}
              variant="outlined"
              minRows={4}
              maxRows={10}
              fullWidth
              multiline
              required
              onChange={formik.handleChange}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );

  return (
    <Modal
      ref={modal}
      id="gig-reject"
      title="Reject"
      loading={loading}
      modalToggler={renderToggler()}
      fullWidth
      maxWidth="xs"
      submitTitle="Submit"
      showClose
      disabled={!formik.values.reason && !formik.values.otherReason}
      onSubmit={formik.submitForm}
    >
      {loading ? <LoaderDots /> : renderForm()}
    </Modal>
  );
};

const useStyles = makeStyles({
  toggler: {
    color: 'red',
  },
});

const RejectMutation = gql`
  mutation RejectGig($id: UUIDString!, $reason: String!) {
    rejectGig(id: $id, reason: $reason) {
      ok
      gig {
        id
        state
        rejectReason
        canBeReported
      }
    }
  }
`;

export default GigReject;
