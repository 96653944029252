import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {Label} from '../';
import styles from './style.css';

class IntRangeInput extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSaved: false,
      showFailed: false,
      controls: {
        0: {
          from: undefined,
          to: undefined,
        },
      },
    };
  }

  getValue = () => {
    const {controls} = this.state;
    return [
      ...new Set(
        Object.keys(controls).reduce((acc, next) => {
          if (
            isNaN(controls[next].to) ||
            isNaN(controls[next].from) ||
            controls[next].to < controls[next].from
          ) {
            return acc;
          } else {
            let prev = acc;
            if (prev === undefined) {
              prev = [];
            }
            return [
              ...prev,
              ...Array(parseInt(controls[next].to) + 1 - parseInt(controls[next].from))
                .fill()
                .map((_, idx) => parseInt(controls[next].from) + idx),
            ];
          }
        }, undefined)
      ),
    ];
  };

  onChange = async (index, value, to) => {
    const {controls} = this.state;
    await this.setState({
      controls: {
        ...controls,
        [index]: {
          from: !to ? parseInt(value) : controls[index].from,
          to: to ? parseInt(value) : controls[index].to,
        },
      },
    });
    this.props.onChange(this.getValue());
  };

  addControl = () => {
    const {controls} = this.state;
    this.setState({
      controls: {
        ...controls,
        [Object.keys(controls).length]: {
          from: undefined,
          to: undefined,
        },
      },
    });
  };

  removeControl = async () => {
    const {controls} = this.state;
    const keys = Object.keys(controls);
    const filtered = keys.reduce((acc, k) => {
      if (parseInt(k) !== keys.length - 1) {
        return {...acc, [k]: controls[k]};
      }
      return acc;
    }, {});
    await this.setState({controls: filtered});

    this.props.onChange(this.getValue());
  };

  render() {
    const {description, hasError, disabled, label} = this.props;
    const {controls} = this.state;

    return (
      <div className={styles.root}>
        {label && <Label label={label} />}
        {Object.keys(controls).map((control, i) => (
          <div key={i} className={styles.inputs}>
            <input
              className={classNames(styles.input, {
                [styles.error]: hasError,
                [styles.disabled]: disabled,
              })}
              type="number"
              placeholder="from"
              value={control.from}
              disabled={disabled}
              onChange={e => this.onChange(i, e.target.value, false)}
            />
            <input
              className={classNames(styles.input, {
                [styles.error]: hasError,
                [styles.disabled]: disabled,
              })}
              type="number"
              placeholder="to"
              value={control.to}
              disabled={disabled}
              onChange={e => this.onChange(i, e.target.value, true)}
            />
          </div>
        ))}
        <div className={styles.controlButtons}>
          {Object.keys(controls).length > 1 && (
            <div className={styles.removeControl} onClick={this.removeControl}>
              -
            </div>
          )}
          <div className={styles.addControl} onClick={this.addControl}>
            +
          </div>
        </div>

        {description && <div className={styles.description}>{description}</div>}
      </div>
    );
  }
}

export default IntRangeInput;
