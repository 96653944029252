import React from 'react';

import {TreeViewEntity} from '../../../types';
import SelectedItemChip from '../SelectedItemChip';

interface Props {
  selectedEntitiesIds: Array<string>;
  industries: ReadonlyArray<TreeViewEntity>;
  isReadOnly?: boolean;
  onChange: (text: string) => void;
}

const SelectedIndustryChips: React.FC<Props> = ({
  selectedEntitiesIds,
  industries,
  isReadOnly,
  onChange,
}) => {
  return (
    <>
      {selectedEntitiesIds.length > 0 &&
        selectedEntitiesIds.map(item => (
          <SelectedItemChip
            key={item}
            id={item}
            value={getNodeName(industries, item)}
            isReadOnly={isReadOnly}
            onChange={onChange}
          />
        ))}
    </>
  );
};

export default SelectedIndustryChips;

function getNodeName(industries: ReadonlyArray<TreeViewEntity> | null, nodeId: string): string {
  if (industries) {
    for (const item of industries) {
      const industry = item.children.find(x => x.id === nodeId);
      if (industry) {
        return industry.title;
      }
    }
  }
  return '';
}
