import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {CampaignInsightsQuery} from './graphqlQueries';
import CampaignInsightsTable from '../components/CampaignInsightsTable';
import {CampaignHeader} from '../components';

interface Props {
  campaign: Campaign;
}

type Params = {
  campaign: string;
};

const CampaignInsights: React.FC<Props> = () => {
  const {
    params: {campaign: campaignId},
  }: {params: Params} = useRouteMatch();

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {data: campaignData, loading: isLoading} = useQuery<Props>(CampaignInsightsQuery, {
    variables: {
      campaignId,
    },
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (campaignData) {
      setCampaign(campaignData.campaign);
    }
  }, [campaignData, isLoading]);

  return (
    <>
      <CampaignHeader title="Campaign Insights" campaignId={campaignId} showProgress />
      {campaign && <CampaignInsightsTable posts={campaign.posts} loading={loading} />}
    </>
  );
};

export default CampaignInsights;
