import {makeStyles} from '@material-ui/styles';

import {theme} from '../../../consts/theme';
import {black} from '../../../consts/brand.integration';

export const useStyles = makeStyles({
  select: {
    '&.MuiInput-root': {
      fontSize: 13,
      height: 32,
      minHeight: 32,
      border: `1px solid ${theme.palette['text-01']}`,
      borderRadius: 0,
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        outline: '-webkit-focus-ring-color auto 1px',
      },
      '& .MuiSelect-root': {
        paddingLeft: '10px',
      },
      '& .MuiSelect-icon': {
        marginRight: '10px',
        marginTop: '2px',
        fontSize: '20px',
        color: black,
      },
    },
    '&.MuiInput-underline': {
      '&:before': {
        border: '0',
      },
      '&:hover:before': {
        border: '0',
      },
    },
  },
});
