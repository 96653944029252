import React, {ReactElement, ReactNode, useRef, useState} from 'react';

import {Modal} from '..';
import {ModalElement} from '../Modal';

interface Props {
  title: string;
  body: string | ReactNode | ReactNode[];
  onSubmit: (payload?: Record<string, unknown>) => Promise<any> | void;
  control: ReactElement;
  confirmText: string;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

const Prompt: React.FC<Props> = ({
  title,
  body,
  onSubmit,
  control,
  confirmText = 'OK',
  disabled,
  fullWidth = false,
  loading,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const modal = useRef<ModalElement>(null);

  const handleSubmit = async (payload?: Record<string, unknown>) => {
    setSubmitting(true);
    try {
      await onSubmit(payload);
    } finally {
      setSubmitting(false);
      modal.current?.close();
    }
  };

  return (
    <Modal
      ref={modal}
      id="prompt"
      title={title}
      modalToggler={control}
      submitTitle={confirmText}
      showClose
      loading={submitting || loading}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      onSubmit={handleSubmit}
    >
      {body}
    </Modal>
  );
};

export default Prompt;
