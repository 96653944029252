// eslint-disable-next-line import/named
import {createStyles, makeStyles} from '@material-ui/core/styles';

import {theme} from 'consts/theme';

import {black, mainContentWidth, red, white} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
      paddingTop: '40px',
      paddingLeft: 0,
      '& .MuiGrid-item': {
        fontStyle: 'normal',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        padding: '6px 4px',
      },
    },
    nav: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
    },
    hintWrapper: {
      display: 'flex',
      alignItems: 'center',
      color: black,
      marginBottom: '40px',
      marginTop: '16px',
    },
    infoText: {
      marginBottom: '5px',
      marginLeft: '5px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      width: '505px',
      height: '24px',
    },
    columnName: {
      marginBottom: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      color: black,
    },
    toggleHintWrapperActive: {
      color: black,
      fontSize: '12px',
    },
    toggleHintWrapperInactive: {
      color: theme.palette['text-02'],
      fontSize: '12px',
    },
    toggleHintWrapperDisabled: {
      color: theme.palette['text-03'],
      fontSize: '12px',
    },
    toggleText: {
      marginLeft: '5px',
    },
    wrapper: {
      display: 'flex',
      marginTop: '55px',
    },
    alertTxt: {
      color: white,
      backgroundColor: red,
      width: 'fit-content',
      padding: '2px 10px',
    },
  })
);
