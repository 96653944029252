import React, {ReactElement} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {match} from 'react-router-dom';

import {SecondaryNavigation} from 'components/Widgets';

import {CampaignNavigation} from '../components';

interface Props {
  match: match<any>;
  children: ReactElement | ReactElement[];
}

const CampaignWrapper: React.FC<Props> = ({match, children}) => {
  const classes = useStyles();
  return (
    <>
      <SecondaryNavigation>
        <CampaignNavigation {...{match}} />
      </SecondaryNavigation>

      <Box className={classes.content}>{children}</Box>
    </>
  );
};

const useStyles = makeStyles({
  content: {
    padding: '1rem 0 0',
  },
});

export default CampaignWrapper;
