import ApolloClient from 'apollo-client'; // eslint-disable-line
import {ApolloLink, Observable} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {RetryLink} from 'apollo-link-retry';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';

import NotificationService from 'services/notifications';
import RequestsService from 'services/requests';
import {getBaseURL} from 'services/api';
import {endUserImpersonation, isImpersonating} from 'services/impersonation';

import introspectionQueryResultData from '../fragmentTypes.json';
import {getStoredApiToken} from './services/auth';

const redirectToLogin = () => {
  const {pathname} = window.location;

  if (pathname !== '/login') {
    const redirect = pathname !== '/' ? `?redirect_pathname=${pathname}` : '';
    window.location.replace(`/login${redirect}`);
  }
};

const errorHandlerLink = onError(({graphQLErrors, networkError, response, operation}) => {
  if (graphQLErrors) {
    const {operationName, variables} = operation;
    const context = {
      operationName,
      variables,
    };
    NotificationService.onGraphQLError({graphQLErrors}, context);
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      if (isImpersonating()) return endUserImpersonation();
      redirectToLogin();
    } else {
      NotificationService.onNetworkError({networkError});
    }
  }

  if (response) {
    response.errors = null;
  }
});

class SetHeaders extends ApolloLink {
  request(operation, forward) {
    const headers = {};

    const token = getStoredApiToken();
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    operation.setContext({
      headers,
    });

    return forward(operation);
  }
}

class InFlightRequestLink extends ApolloLink {
  request(operation, forward) {
    const key = operation.toKey();
    RequestsService.start(key);

    const observable = forward(operation);

    return new Observable(observer => {
      const subscription = observable.subscribe({
        next: result => {
          RequestsService.stop(key);
          observer.next(result);
        },
        error: error => {
          RequestsService.stop(key);
          observer.error(error);
        },
        complete: () => observer.complete(),
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  }
}

const customFetch = (_, options) => {
  const {operationName} = JSON.parse(options.body);
  const uri = `${getBaseURL()}/graphql/${operationName}`; // call getBaseURL() every time.. yes
  return fetch(uri, options);
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// prettier-ignore
const link = ApolloLink.from([
  errorHandlerLink,
  new InFlightRequestLink(),
  new SetHeaders(),
  new RetryLink(),
  new HttpLink({fetch: customFetch})
]);

export const cache = new InMemoryCache({
  fragmentMatcher,
});

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: __DEV__,
});

client.initStore = () => {
  /* do nothing */
};

export default client;
