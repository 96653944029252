import React from 'react';
import {Link} from 'react-router-dom';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Avatar, Grid, TableCell, Typography} from '@material-ui/core';

import {TableZebraRow, InstagramLink, TikTokLink, Tooltip, SortableTable} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import {shouldDisplayMetric} from '../helper';

interface Influencer {
  influencer_id: string;
  full_name: string;
  instagram_handle: string;
  tiktok_handle: string;
  instagram_profile_url: string;
  tiktok_profile_url: string;
  instagram_profile_picture_url: string;
  tiktok_profile_picture_url: string;
  follower_total: {raw: number; formatted: string};
  digital_content_count: {raw: number; formatted: string};
  impression_total: {raw: number; formatted: string};
  reach_total: {raw: number; formatted: string};
  engagement_total: {raw: number; formatted: string};
  engagement_rate_by_impressions: {raw: number; formatted: string};
}

interface HeadCell {
  id: keyof Influencer;
  label: string;
  numeric: boolean;
}

export interface InfluencerTableProps {
  rows: Influencer[];
  enabledMetrics?: string[];
}

const InfluencerTable: React.FC<InfluencerTableProps> = ({rows, enabledMetrics = []}) => {
  const classes = useStyles();

  const headCells = [
    {id: 'full_name', numeric: false, label: 'Creator'},
    {id: 'digital_content_count', numeric: true, label: 'Assets'},
    {id: 'reach_total', numeric: true, label: 'Reach'},
    {id: 'impression_total', numeric: true, label: 'Impressions'},
    {id: 'engagement_total', numeric: true, label: 'Engagements'},
    {id: 'engagement_rate_by_impressions', numeric: true, label: 'Engagement Rate'},
  ].filter(({id}) => shouldDisplayMetric(enabledMetrics, id)) as HeadCell[];

  return (
    <SortableTable<InfluencerTableProps['rows'][number]>
      ariaLabel="Influencer Performance"
      headCells={headCells}
      rows={rows}
      renderRow={(row, i) => (
        <TableZebraRow key={i} tabIndex={-1}>
          <TableCell component="th" scope="row" className={classes.influencerCell}>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <Avatar src={row.instagram_profile_picture_url} />
              </Grid>
              <Grid item>
                <Link to={`/influencers/${row.influencer_id}`} aria-label="Influencer">
                  <Typography variant="body2">
                    <strong>{row.full_name}</strong>
                  </Typography>
                </Link>
                {row.instagram_handle && <InstagramLink username={row.instagram_handle} />}
                {row.tiktok_handle && <TikTokLink username={row.tiktok_handle} />}
              </Grid>
            </Grid>
          </TableCell>

          {shouldDisplayMetric(enabledMetrics, 'digital_content_count') && (
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(row.digital_content_count.raw)} placement="left">
                <Typography variant="body1" component="span">
                  {row.digital_content_count.formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          )}

          {shouldDisplayMetric(enabledMetrics, 'reach_total') && (
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(row.reach_total.raw)} placement="left">
                <Typography variant="body1" component="span">
                  {row.reach_total.formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          )}

          {shouldDisplayMetric(enabledMetrics, 'impression_total') && (
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(row.impression_total.raw)} placement="left">
                <Typography variant="body1" component="span">
                  {row.impression_total.formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          )}

          {shouldDisplayMetric(enabledMetrics, 'engagement_total') && (
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(row.engagement_total.raw)} placement="left">
                <Typography variant="body1" component="span">
                  {row.engagement_total.formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          )}

          {shouldDisplayMetric(enabledMetrics, 'engagement_rate_by_impressions') && (
            <TableCell align="center">
              <Tooltip
                overlay={numberWithCommas(row.engagement_rate_by_impressions.raw)}
                placement="left"
              >
                <Typography variant="body1" component="span">
                  {row.engagement_rate_by_impressions.formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          )}
        </TableZebraRow>
      )}
      sortMetric="engagement_rate_by_impressions"
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    influencerCell: {
      width: '280px',
      '& svg': {
        marginRight: '2px',
      },
    },
  })
);

export default InfluencerTable;
