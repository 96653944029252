import React from 'react';
import classNames from 'classnames';
import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';

import {NavigationLink, RequiresPermission} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import {light, sizeCaption} from '../../../../../consts/brand.integration';
import useFeatureFlags from '../../../../../hooks/useFeatureFlags';

interface Props {
  influencer: Influencer;
  mediaCount?: number;
  gigCount?: number | string;
  campaignCount?: number;
}

const InfluencerNavigationLinks: React.FC<Props> = ({
  influencer,
  mediaCount,
  gigCount,
  campaignCount,
}) => {
  const classes = useStyles();
  const {showInfluencerInstagramTab} = useFeatureFlags();
  const influencerUrlBase = `/influencers/${influencer.id}`;

  return (
    <Grid container spacing={4} style={{margin: 0}}>
      {influencer.isSignedUp && (
        <Grid item>
          <NavigationLink to={`${influencerUrlBase}/gigs`}>
            Gigs{' '}
            <Box className={classNames([classes.normalFont, classes.number])}>
              {gigCount && numberWithCommas(gigCount)}
            </Box>
          </NavigationLink>
        </Grid>
      )}
      {showInfluencerInstagramTab && !influencer.isPrivate && influencer.hasFacebookPage && (
        <Grid item>
          <NavigationLink to={`${influencerUrlBase}/instagram-posts`}>
            Instagram{' '}
            <Box className={classNames([classes.normalFont, classes.number])}>
              {mediaCount && numberWithCommas(mediaCount)}
            </Box>
          </NavigationLink>
        </Grid>
      )}
      {influencer.isSignedUp && (
        <>
          <Grid item>
            <NavigationLink to={`${influencerUrlBase}/campaigns`}>
              Campaigns{' '}
              <Box className={classNames([classes.normalFont, classes.number])}>
                {campaignCount && numberWithCommas(campaignCount)}
              </Box>
            </NavigationLink>
          </Grid>
          <Grid item>
            <NavigationLink to={`${influencerUrlBase}/events`}>History</NavigationLink>
          </Grid>
          <Grid item>
            <NavigationLink to={`${influencerUrlBase}/insights`}>Insights</NavigationLink>
          </Grid>
          <RequiresPermission permission="manage_influencers">
            <Grid item>
              <NavigationLink to={`${influencerUrlBase}/settings`}>Settings</NavigationLink>
            </Grid>
          </RequiresPermission>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    normalFont: {
      fontWeight: light,
      fontSize: sizeCaption,
    },
    number: {
      display: 'inline-block',
    },
  })
);

export default InfluencerNavigationLinks;
