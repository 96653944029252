import {theme} from '../../consts/theme';
import {black} from '../../consts/brand.integration';

export const maxCampaignsToShow = 10;

export const impressionsLegend = {
  value: 'Impressions',
  type: 'rect',
  id: 'impressions',
  color: theme.palette['stats-impressions'],
};

export const engagementRateLegend = {
  value: 'Engagement Rate',
  type: 'plainline',
  payload: {strokeDasharray: '1 0', strokeWidth: 2},
  id: 'engagementRate',
  color: theme.palette['stats-er'],
};

export const axisStyle = {fontSize: '12px', fontWeight: 400, fill: black};

export const xAxisStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
};

export const yAxisPadding = {top: 42, bottom: 10};

export const campaignNameLimit = 10;

export const notApplicable = 'N/A';
