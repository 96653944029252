import {gql} from '@apollo/client';

import {Gig} from 'services/fragments';

export const GigQuery = gql`
  query GigHistoryQuery($gigId: UUIDString!) {
    gig(id: $gigId) {
      id
      offer {
        id
        influencer {
          id
          fullName
        }
        deliverables {
          id
          gig {
            id
            post {
              id
              postType
            }
          }
        }
      }
      history {
        __typename
        created
        user {
          id
          fullName
          email
          profilePicture
        }
        ... on CreateSubmissionEvent {
          submission {
            media {
              ...mediaResultFields
            }
            caption
            hashtags
            mentions
          }
        }
        ... on GigReviewEvent {
          feedback
        }
        ... on GigApproveEvent {
          feedback
        }
        ... on RejectGigEvent {
          reason
        }
        ... on GigResubmissionRequestEvent {
          reason
          explanation
        }
        ... on UpdateCaptionEvent {
          submission {
            caption
            hashtags
            mentions
          }
        }
        ... on ReportEvent {
          reason
          explanation
        }
        ... on GigPostedEvent {
          postType
          link
        }
        ... on OfferClaimedEvent {
          payment {
            id
            type
            successful
            dashboardLink
          }
        }
      }
    }
  }
  ${Gig.mediaPreview}
`;
