import {makeStyles} from '@material-ui/core/styles';

import {black30, black40, purple, purpleLight} from 'consts/brand.integration';

export const useStyles = makeStyles({
  definitionRow: {
    marginTop: 22,
  },
  links: {
    a: {
      display: 'block',
      lineHeight: 1.8,
      fontSize: 14,
      textAlign: 'right',
    },
  },
  zipLink: {
    color: purple,
  },
  insightsLink: {
    color: black40,
    lineHeight: 1.8,
    fontSize: 14,
    cursor: 'pointer',
    textAlign: 'right',
  },
  csvLink: {
    color: purple,
  },
  shareWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  shareReport: {
    background: purpleLight,
    width: 116,
    height: 30,
    position: 'relative',
    overflow: 'hidden',
    marginLeft: 'auto',
    willChange: 'width',
    transition: 'width 0.3s',
  },
  shareReportOpen: {
    width: 275,
  },
  renew: {
    color: purple,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: 8,
    width: 60,
    textAlign: 'right',
    marginLeft: -60,
    willChange: 'marginLeft opacity',
    transition: 'marginLeft 0.3s',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  renewOpen: {
    marginLeft: 0,
  },
  shareReportInput: {
    width: 150,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 10,
    border: 0,
    backgroundColor: 'transparent',
    color: purple,
    fontSize: 11,
    outline: 'none',
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
  shareReportButton: {
    margin: 0,
    position: 'absolute',
    left: 0,
    height: 30,
  },
  helpText: {
    opacity: 0,
    height: 0,
    transition: 'opacity 1s 0.5s',
    fontSize: 12,
    color: black30,
    marginTop: 10,
    width: 338,
    textAlign: 'right',
  },
  helpTextOpen: {
    opacity: 1,
    height: 20,
  },
  textLinks: {
    marginBottom: 30,
  },
  addPost: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  tags: {
    marginBottom: 30,
    flex: '1 0 338px',
  },
  campaignInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    flex: '1 0 338px',
  },
  right: {
    width: 255,
  },
  definitions: {
    marginBottom: 50,
  },
  submissionsInternal: {
    width: 230,
    marginLeft: 110,
    marginTop: 10,
  },
  submissionsDisclaimer: {
    p: {
      marginBottom: 10,
    },
  },
  deadlinesWrapper: {
    margin: '22px 0 22px',
  },
});
