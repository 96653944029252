import React from 'react';
import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import {CampaignInfluenscoreData, InfluencerInfluenscoreData} from 'hooks/useDataServiceApi/types';

import {coloursClientWebApp} from 'consts/brand.integration';
import {darken} from '@material-ui/core';

export const InfluenscorePalette = {
  background: coloursClientWebApp.black,
  reach: coloursClientWebApp.vividSkyBlue,
  engagement: coloursClientWebApp.sunglow,
  impressions: coloursClientWebApp.malachite,
  audience: coloursClientWebApp.ruddy,
};

export type InfluenscoreChartProps = {
  values: InfluencerInfluenscoreData | CampaignInfluenscoreData;
  factor?: number;
  legend?: boolean;
  height?: number;
  variant?: 'light' | 'dark';
};

const InfluenscoreChart = ({
  values: {overall, cpf, cpr, cpm, cpe},
  factor = 10,
  legend = false,
  height = 600,
  variant = 'light',
}: InfluenscoreChartProps) => {
  const variantColour = (colour: string) => (variant === 'light' ? darken(colour, 0.1) : colour);

  const options: ApexOptions = {
    chart: {
      // force the chart to rerender when props change
      id: `radialBar-${(overall ?? 0).toFixed(1)}`,
    },
    colors: [
      variantColour(InfluenscorePalette.reach),
      variantColour(InfluenscorePalette.engagement),
      variantColour(InfluenscorePalette.impressions),
      variantColour(InfluenscorePalette.audience),
    ],
    legend: {
      show: legend,
      position: 'bottom',
      onItemClick: {
        toggleDataSeries: false,
      },
      fontSize: '10px',
      itemMargin: {
        vertical: 5,
        horizontal: 5,
      },
      labels: {
        colors: variant === 'dark' ? coloursClientWebApp.white : coloursClientWebApp.black,
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          background:
            variant === 'dark' ? coloursClientWebApp.almostBlack : coloursClientWebApp.offWhite,
          strokeWidth: '100px',
        },
        hollow: {
          size: '40%',
          background:
            variant === 'dark' ? coloursClientWebApp.almostBlack : coloursClientWebApp.white,
        },
        dataLabels: {
          name: {
            offsetY: -10,
            fontSize: '12px',
            color: variant === 'dark' ? coloursClientWebApp.white : coloursClientWebApp.black,
          },
          value: {
            fontSize: '28px',
            fontWeight: 'bold',
            offsetY: 10,
            color: variant === 'dark' ? coloursClientWebApp.white : coloursClientWebApp.black,
            formatter(val: number | string): string {
              if (typeof val === 'string') {
                return (parseFloat(val) / 10).toFixed(1);
              }
              return val.toFixed(1);
            },
          },
          total: {
            show: true,
            label: 'Overall',
            fontSize: '18px',
            color: variant === 'dark' ? coloursClientWebApp.white : coloursClientWebApp.black,
            formatter: () => (overall ?? 0 ?? factor).toFixed(1),
          },
        },
      },
    },
    labels: ['Reach', 'Engagement', 'Impressions', 'Audience'],
  };

  const scores = [
    (cpf ?? 0) * factor,
    (cpr ?? 0) * factor,
    (cpm ?? 0) * factor,
    (cpe ?? 0) * factor,
  ];

  return <ReactApexChart type={'radialBar'} series={scores} options={options} height={height} />;
};

export default InfluenscoreChart;
