import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {IconButton, Typography} from '@material-ui/core';

import {useStyles} from './styles';
import {IPagination} from './types';

const Pagination: React.FC<IPagination> = ({items, count, goToFirstPage}) => {
  const classes = useStyles();
  return (
    <ul className={classes.ul}>
      {items.map(({page, type, selected, ...item}, index) => {
        let children = null;

        if (type === 'page') {
          if (selected) {
            children = (
              <Typography variant="h4" component="h2" className={classes.page}>
                {page} of {count}
              </Typography>
            );
          }
        } else if (type === 'next' || type === 'previous') {
          if (item.disabled) {
            children = <div></div>;
          } else {
            children = (
              <IconButton
                size="small"
                type="button"
                className={classes.button}
                aria-label={type === 'next' ? 'Go to next insight' : 'Go to previous insight'}
                {...item}
              >
                {type === 'next' ? <ArrowForwardIcon /> : <ArrowBackIcon />}
              </IconButton>
            );
          }

          if (item.disabled && type === 'next') {
            children = (
              <IconButton
                size="small"
                type="button"
                className={classes.button}
                onClick={goToFirstPage}
              >
                <ArrowForwardIcon />
              </IconButton>
            );
          }
        }

        return <li key={index}>{children}</li>;
      })}
    </ul>
  );
};

export default Pagination;
