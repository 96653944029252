import React from 'react';
import {Button, FormControl, Grid, TextField} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {UserRoles} from '../../../../../consts/roles';
import useCurrentUser from '../../../../../hooks/useCurrentUser';

interface Props {
  onCancel: () => void;
  onReview: (values: {feedback?: string}) => void;
  onApprove: (values: {feedback?: string}) => void;
}

const ReviewOrApproveView: React.FC<Props> = ({onCancel, onReview, onApprove}) => {
  const currentUser = useCurrentUser();

  const formik: Record<any, any> = useFormik({
    initialValues: {
      feedback: '',
    },
    validationSchema: yup.object({
      feedback: yup.string().notRequired(),
    }),
    onSubmit: values =>
      currentUser?.roleName === UserRoles.Advertiser ? onApprove(values) : onReview(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="feedback"
              name="feedback"
              label="Would you like to leave feedback?"
              value={formik.values.feedback}
              aria-describedby={'feedback'}
              variant="outlined"
              minRows={10}
              fullWidth
              multiline
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item container spacing={4} justifyContent="flex-end">
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              type="reset"
              size="large"
              fullWidth
              startIcon={<Icon icon="icon-park:return" />}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<Icon icon="material-symbols:check" />}
            >
              {currentUser?.roleName === UserRoles.Advertiser ? 'Approve' : 'Review'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReviewOrApproveView;
