import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid, Box, Button, Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Definition, Separator, FormattedDate} from '../../../../../components/Widgets';
import {REJECTED_STATES, REVOKABLE_STATES} from '../constants';
import {formattedRewardString} from '../../../../../consts/utilities';
import {getPostTypeFormatted} from '../../../../../consts/campaignPostType';
import {socialIcons} from '../../../../../consts/icons';
import ForceApproveButton from '../ForceApproveButton';
import ForceReserveButton from '../ForceReserveButton';
import MarkAsPaid from '../MarkAsPaid';
import RevertRejectionButton from '../RevertRejectionButton';
import RevokeOfferButton from '../RevokeOfferButton';
import UpdateRewardButton from '../UpdateRewardButton';
import UpdatePaymentTermsButton from '../UpdatePaymentTerms';
import UpdateDeliverables from '../UpdateDeliverables';

interface Props {
  offer: Offer;
  campaign: Campaign;
}

const InfluencerOffer: React.FC<Props> = ({offer, campaign}) => {
  const classes = useStyles();

  const [openMarkAsPaidModal, setOpenMarkAsPaidModal] = useState(false);
  const isRevokable = REVOKABLE_STATES.includes(String(offer.state));
  const isRejected = REJECTED_STATES.includes(String(offer.state));
  const isPayable = Boolean(offer.payable);
  const isPaymentSuccessful = Boolean(offer.payment?.successful);

  const Deliverables = () => {
    return offer.deliverables?.map((deliverable, index) => (
      <Box key={index} className={classes.root} display="flex" alignItems="center">
        <Icon icon={socialIcons[deliverable?.post?.postType]} />
        <Typography variant="body1" component="p">
          {getPostTypeFormatted(deliverable?.post?.postType)}
        </Typography>
        <Typography variant="caption">
          ({deliverable?.delivered ? 'Posted: ' : 'Deadline: '}
          <FormattedDate
            date={deliverable?.delivered ? deliverable.deliveredAt : deliverable?.post?.deadline}
            format="Do MMM, YYYY, HH:mm zz"
          />
          )
        </Typography>
      </Box>
    ));
  };

  return (
    <>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid>
          <Definition small title="Campaign Deliverables" definition={<Deliverables />} />
        </Grid>
        <Grid item>
          <UpdateDeliverables offer={offer} campaign={campaign} disabled={isPaymentSuccessful} />
        </Grid>
      </Grid>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid>
          <Definition
            small
            title="Reward"
            definition={formattedRewardString(offer.rewardBreakdown)}
          />
        </Grid>
        <Grid item>
          {!isRejected && (
            <>
              <UpdateRewardButton
                offer={offer}
                campaign={campaign}
                disabled={isPaymentSuccessful}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Definition
            small
            title="Payment Terms"
            definition={offer.paymentTerms?.replace('_', ' ')}
          />
        </Grid>
        <Grid item>
          {!isRejected && (
            <>
              <UpdatePaymentTermsButton offer={offer} disabled={isPayable || isPaymentSuccessful} />
            </>
          )}
        </Grid>
      </Grid>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Definition small title="State" definition={offer.formattedState} />
        </Grid>
        <Grid item style={{display: 'flex', flexDirection: 'column'}}>
          {(offer.state === 'pending' || offer.state === 'invited') && (
            <ForceReserveButton offer={offer} campaign={campaign} disabled={isPaymentSuccessful} />
          )}
          {isRevokable && <RevokeOfferButton offer={offer} disabled={isPaymentSuccessful} />}
          {offer.state === 'candidate' && <ForceApproveButton offer={offer} />}
          {offer.revertableState && (
            <RevertRejectionButton offer={offer} disabled={isPaymentSuccessful} />
          )}
        </Grid>
      </Grid>
      <Separator />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Definition
            small
            title="Payment"
            definition={offer.payment?.successful ? 'Paid' : 'Unpaid'}
          />
        </Grid>
        <Grid item>
          {offer.state === 'accepted' && (
            <>
              <MarkAsPaid
                offerId={offer.id}
                open={openMarkAsPaidModal}
                onClose={() => setOpenMarkAsPaidModal(false)}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Icon icon="material-symbols:check-circle" />}
                disabled={isPaymentSuccessful}
                onClick={() => setOpenMarkAsPaidModal(true)}
              >
                Mark As Paid
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Separator />
    </>
  );
};

const useStyles = makeStyles({
  root: {
    margin: '5px 0',
    '& p': {
      textTransform: 'capitalize',
      margin: '0 5px',
    },
    '& span': {
      fontStyle: 'italic',
      opacity: 0.7,
    },
  },
});

export default InfluencerOffer;
