import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import * as qs from 'query-string';

import {InfiniteScroll, RowWrap, LoaderDots} from 'components/Widgets';
import {CampaignNoGigs} from 'components/Campaign';
import {GigMedia} from 'components/Gig';

import {withHookPagination} from 'services/graphql';

import {GigSubmissionsFilter} from './components';
import styles from './style.css';

const GigQuery = gql`
  query GigQuery($unreviewed: Boolean, $reported: Boolean, $mine: Boolean, $cursor: String) {
    gigs(unreviewed: $unreviewed, reported: $reported, mine: $mine, first: 24, after: $cursor) {
      edges {
        node {
          id
          ...GigMediaFields
          post {
            id
            campaign {
              id
              name
              advertiser {
                id
                profilePicture
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${GigMedia.fragments.gig}
`;

const Submissions = ({children, location, history}) => {
  const params = qs.parse(location.search);
  const {loading, error, data, fetchMore, variables} = useQuery(GigQuery, {
    variables: {
      unreviewed: params.unreviewed,
      reported: params.reported,
      mine: params.mine,
    },
  });

  const loadMore = withHookPagination(GigQuery, 'gigs', data, variables, fetchMore);

  if (error) return <div>Unknown error occurred</div>;

  return (
    <div className={styles.root}>
      <GigSubmissionsFilter location={location} replace={history.replace} />
      {loading ? (
        <LoaderDots />
      ) : data.gigs.count === 0 ? (
        <CampaignNoGigs title={'No gigs'} />
      ) : (
        <>
          <InfiniteScroll loadMore={loadMore} hasMore={data.gigs.pageInfo.hasNextPage}>
            <RowWrap>
              {data.gigs.edges
                .map(edge => edge.node)
                .map(gig => (
                  <GigMedia
                    key={gig.id}
                    campaign={gig.campaign}
                    feature="influencer"
                    gig={gig}
                    influencer={gig.influencer}
                    url={`/gigs/${gig.id}`}
                    showCampaign
                  />
                ))}
            </RowWrap>
          </InfiniteScroll>
          {children}
        </>
      )}
    </div>
  );
};

Submissions.propTypes = {
  children: PropTypes.node,
  loadMore: PropTypes.func,
  data: PropTypes.shape({
    gigs: PropTypes.object,
    loading: PropTypes.bool,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(Submissions);
