import React, {useState, useEffect} from 'react';

import ImageLoader from '../Widgets/ImageLoader';

interface Props {
  url: string;
}

const TikTokPreview: React.FC<Props> = ({url}) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`);
        const json = await response.json();
        setThumbnail(json.thumbnail_url);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [setThumbnail, url]);

  if (!thumbnail) return null;

  return <ImageLoader alt={'Tiktok preview'} src={thumbnail} />;
};

export default TikTokPreview;
