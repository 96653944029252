import React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import * as qs from 'query-string';

import {Checkbox, FilterItem} from 'components/Widgets';

import styles from './style.css';

const nameForFilter = filter => {
  switch (filter) {
    case 'requested':
      return 'Interested';
    case 'candidate':
      return 'Pending approval';
    case 'approved_by_brand':
      return 'Approved by brand';
    case 'invited':
    case 'accepted':
    case 'rejected':
      return filter;
    default:
      throw new Error(`Unknown filter ${filter}`);
  }
};

type Props = {
  location: {search: string};
  history: RouteComponentProps['history'];
  campaignFilters: Array<string>;
  filterCounts: Array<Record<string, unknown>>;
};

const Filters: React.FC<Props> = ({campaignFilters, filterCounts, location, history}) => {
  const params = qs.parse(location.search);
  const currentFilter = params.state || 'accepted';
  const awaitingSubmission = params['awaiting-submission'] !== undefined;
  const rejectedFilters = ['rejected', 'revoked', 'rejected_by_brand'];
  const progressFilters = campaignFilters.filter(filter => !rejectedFilters.includes(filter));

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.left}>
          {progressFilters.map(filter => (
            <Link
              key={filter}
              to={location => ({
                ...location,
                search: qs.stringify({state: filter}),
              })}
            >
              <FilterItem small checked={filter === currentFilter}>
                {nameForFilter(filter)}
                <span className={styles.count}>
                  {(filterCounts.find(p => p.type === filter) || {count: 0}).count}
                </span>
              </FilterItem>
            </Link>
          ))}
        </div>

        <div className={styles.right}>
          <Link
            to={location => ({
              ...location,
              search: qs.stringify({state: 'rejected'}),
            })}
          >
            <FilterItem small checked={currentFilter === 'rejected'}>
              Rejected
              <span className={styles.count}>
                {filterCounts.reduce(
                  (acc, n) => (rejectedFilters.includes(n.type) ? acc + n.count : acc),
                  0
                )}
              </span>
            </FilterItem>
          </Link>
        </div>
      </div>
      {currentFilter === 'accepted' && (
        <div className={styles.awaitingSubmission}>
          <Checkbox
            label="Awaiting submission"
            checked={awaitingSubmission}
            onChange={() => {
              const search = new URLSearchParams(location.search);
              !awaitingSubmission
                ? search.append('awaiting-submission', 'true')
                : search.delete('awaiting-submission');

              history.push({
                ...location,
                search: search.toString(),
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(Filters);
