import {createStyles, makeStyles} from '@material-ui/core/styles';

import {
  fontFamily,
  black,
  white,
  roman,
  semiBold,
  inputPlaceholder,
  transparentPurple,
} from '../../../../../consts/brand.integration';
import {theme} from '../../../../../consts/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    calendarWrapper: {
      marginLeft: 24,
    },
    weekDay: {
      color: black,
      fontWeight: semiBold,
    },
    inRange: {
      borderRadius: '0 !important',
      backgroundColor: transparentPurple,
    },
    startEdge: {
      borderRadius: '0 !important',
      backgroundColor: 'rgba(155, 81, 224, 0.4)',
    },
    endEdge: {
      borderRadius: '0 !important',
      backgroundColor: 'rgba(155, 81, 224, 0.4)',
    },
    day: {
      height: 26,
      width: 24,
      marginRight: 4,
      marginLeft: 4,
      '&:focus .rdrDayNumber': {
        backgroundColor: 'rgba(155, 81, 224, 0.4)',
      },
      '&:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, &:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, &:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span':
        {
          color: black,
        },
    },
    dayPassive: {
      '& .rdrDayNumber span': {
        color: inputPlaceholder,
        fontWeight: roman,
      },
    },
    months: {
      width: 520,
      justifyContent: 'space-between',
    },
    month: {
      width: 248,
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',
    },
    monthName: {
      color: black,
      fontSize: '14px',
      height: 40,
      lineHeight: '20px',
    },
    dateDisplayWrapper: {
      backgroundColor: white,
    },
    dateDisplay: {
      margin: 0,
      '& span:last-child': {
        marginLeft: 24,
      },
    },
    dateDisplayItem: {
      border: `1px solid ${theme.palette['ui-05']}`,
      borderRadius: 0,
      boxShadow: 'none',
      width: 248,
      '& input': {
        cursor: 'auto',
        color: black,
        fontFamily,
      },
    },
    dayInPreview: {
      border: 0,
    },
    dayStartPreview: {
      border: 0,
    },
    dayEndPreview: {
      border: 0,
    },
    dayToday: {
      '&:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, &:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, &:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, &:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after':
        {
          background: 'transparent',
        },
      '& .rdrDayNumber span': {
        fontWeight: semiBold,
      },
      '& .rdrDayNumber span:after': {
        background: 'transparent',
      },
    },
    dayNumber: {
      fontWeight: semiBold,
    },
    dateRangePickerWrapper: {
      padding: '24px 24px 24px 0',
      minHeight: 376,
      '& .rdrDefinedRangesWrapper': {
        borderRight: 0,
        width: 'auto',
      },
      '& .rdrStaticRange': {
        fontWeight: semiBold,
        fontSize: '14px',
        borderBottom: 'none',
        fontFamily: fontFamily,
        borderLeft: `3px solid ${white}`,
        '&:last-child': {
          borderTop: '1px solid #eff2f7',
          marginTop: 20,
        },
      },
      '& .rdrStaticRangeSelected': {
        backgroundColor: transparentPurple,
        borderLeft: `3px solid ${theme.palette['stats-insights']}`,
      },
      '& .rdrStaticRangeLabel': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    monthAndYearWrapper: {
      padding: 0,
      height: 40,
    },
    monthAndYearPickers: {
      display: 'none',
    },
    nextPrevButton: {
      backgroundColor: white,
      border: `1px solid ${black}`,
      borderRadius: 0,
      margin: 0,
      '&:hover': {
        backgroundColor: transparentPurple,
      },
      '&:focus': {
        backgroundColor: transparentPurple,
      },
      '& i': {
        margin: 'auto',
      },
    },
    prevButton: {
      '& i': {
        borderColor: `transparent ${black} transparent transparent`,
      },
    },
    nextButton: {
      '& i': {
        borderColor: `transparent transparent transparent ${black}`,
      },
    },
  })
);
