import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

import {ProfilePicture} from 'components/Widgets';

import {advertiserUrl, campaignUrl} from 'consts/urls';

import {purple} from '../../../../../../consts/brand.integration';

interface Props {
  campaign: Campaign;
}

const CampaignProfile: React.FC<Props> = ({campaign}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Link to={campaignUrl(campaign)}>
          <ProfilePicture src={campaign.photos?.coverPhoto?.url} size={55} />
        </Link>
      </div>
      <div className={classes.info}>
        <div>
          <Link className={classes.campaignName} to={campaignUrl(campaign)}>
            {campaign.name}
          </Link>
          {campaign.public && <span className={classes.public}>public</span>}
        </div>
        <Link className={classes.advertiserName} to={advertiserUrl(campaign.advertiser)}>
          {campaign.advertiser?.name}
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0',
    alignItems: 'center',
  },
  info: {
    marginLeft: '15px',
  },
  public: {
    color: purple,
    marginLeft: '5px',
  },
  campaignName: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  advertiserName: {
    fontWeight: 500,
  },
});

export default CampaignProfile;
