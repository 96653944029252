import {createStyles, makeStyles} from '@material-ui/core';
import React from 'react';
import Highlighter from 'react-highlight-words';

import {purple} from '../../../consts/brand.integration';

interface Props {
  caption: string;
  hashtags: string[];
  mentions: string[];
}

const GigCaption: React.FC<Props> = ({caption = '', hashtags = [], mentions = []}) => {
  const classes = useStyles();

  return (
    <Highlighter
      highlightClassName={classes.highlight}
      searchWords={[...hashtags.map(tag => `#${tag}`), ...mentions.map(mention => `@${mention}`)]}
      autoEscape
      highlightTag="span"
      textToHighlight={caption}
      className={classes.caption}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      display: 'box',
      boxOrient: 'vertical',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
    },
    highlight: {
      color: purple,
    },
  })
);

export default GigCaption;
