import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import {Label} from '../';
import styles from './style.css';

class AutosavingControl extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    debounceInterval: PropTypes.number,
    initialState: PropTypes.object,
  };

  static defaultProps = {
    debounceInterval: 0,
    initialState: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      showSaved: false,
      showFailed: false,
      value: props.initialState['value'] || '',
    };
  }

  componentDidMount() {
    this.isUnmounted = false;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    clearTimeout(this.hideSavedTimerId);
    clearTimeout(this.hideFailedTimerId);
  }

  makeChange = debounce(async value => {
    try {
      clearTimeout(this.hideSavedTimerId);
      await this.props.onChange(value);
      if (!this.isUnmounted) {
        this.setState({showSaved: true});
        this.hideSavedTimerId = setTimeout(() => this.setState({showSaved: false}), 2000);
      }
    } catch (error) {
      this.setState({showFailed: true});
    }
  }, this.props.debounceInterval);

  handleChange = value => {
    this.setState({showSaved: false, showFailed: false, value});
    this.makeChange(value);
  };

  render() {
    const {label} = this.props;
    const {showSaved, showFailed, value} = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.label}>
          <Label label={label} />
          <span
            className={classNames([
              styles.status,
              styles.statusSaved,
              showSaved && styles.statusVisible,
            ])}
          >
            Saved
          </span>
          <span
            className={classNames([
              styles.status,
              styles.statusFailed,
              showFailed && styles.statusVisible,
            ])}
          >
            Failed to save
          </span>
        </div>

        <div className={styles.controlWrapper}>
          <div className={styles.control}>
            {React.cloneElement(this.props.children, {onChange: this.handleChange, value})}
          </div>
        </div>
      </div>
    );
  }
}

export default AutosavingControl;
