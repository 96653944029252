import React from 'react';
import {Route, RouteComponentProps, RouteProps, useRouteMatch} from 'react-router-dom';

import useRequiresPermission from '../../hooks/useRequiresPermission';

interface Props extends RouteProps {
  path: string;
  permission: string;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  children?: React.ReactNode;
}

const SecureRoute: React.FC<Props> = ({
  path,
  permission,
  component = null,
  children = null,
  ...rest
}) => {
  const match = useRouteMatch(path);
  const hasPermission = useRequiresPermission(permission);

  if (match && hasPermission !== undefined) {
    return (
      <Route
        {...rest}
        path={path}
        render={props => {
          if (hasPermission) {
            if (component) {
              const Component = component;
              return <Component {...props} />;
            } else if (children) {
              return children;
            } else {
              throw new Error('Missing component or children to render!');
            }
          } else {
            // using web api to enforce this works correctly
            window.location.replace(`/404`);
            return null;
          }
        }}
      />
    );
  }

  return null;
};

export default SecureRoute;
