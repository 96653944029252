import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './style.css';

const Loader = ({className}) => <div className={classNames(styles.root, className)} />;

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
