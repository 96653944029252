import {createStyles, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationButtonContainer: {
      paddingTop: 24,
      paddingLeft: 322,
    },
    menuItem: {
      '&:hover': {
        backgroundColor: `${theme.palette['stats-general']}20`,
      },
      '&.MuiListItem-root.Mui-selected': {
        backgroundColor: `${theme.palette['stats-general']}20`,
      },
    },
    select: {
      '&.MuiTablePagination-select': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        fontSize: 12,
      },
      '&:focus': {
        backgroundColor: `${theme.palette['stats-general']}20`,
      },
    },
    input: {
      '&.MuiTablePagination-input': {
        marginRight: 0,
      },
    },
    actions: {
      '&.MuiTablePagination-actions': {
        marginLeft: 0,
      },
    },
    tablePagination: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
  })
);
