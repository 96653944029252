import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button} from '@material-ui/core';

import {Prompt} from 'components/Widgets';

const DisableUserButton: React.FC<{fullName: string; email: string}> = ({fullName, email}) => {
  const [disableUser, {loading}] = useMutation(DisableUserMutation, {
    variables: {username: email},
    refetchQueries: ['ListUserQuery', 'SearchUsersQuery'],
  });

  return (
    <Prompt
      title="Disable User"
      body={
        <>
          Are you sure you want to disable <strong>{fullName}</strong>? This action cannot be
          reversed.
        </>
      }
      confirmText="Disable User"
      control={
        <Button variant="outlined" disabled={loading}>
          Disable User
        </Button>
      }
      onSubmit={() => disableUser()}
    />
  );
};

const DisableUserMutation = gql`
  mutation DisableUserMutation($username: String!) {
    disableUser(username: $username, dry: false) {
      ok
      disabledEmails
      dryRun
    }
  }
`;

export default DisableUserButton;
