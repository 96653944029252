import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';

import {ProfilePicture, RowWrap} from 'components/Widgets';

import {advertiserUrl} from 'consts/urls';

import {black40} from '../../../../../consts/brand';

interface Props {
  advertisers: Advertiser[];
}

const AdminListAdvertisers: React.FC<Props> = ({advertisers}) => {
  const classes = useStyles();
  return (
    <RowWrap className={classes.root}>
      {advertisers.map(advertiser => (
        <div key={advertiser.domain} className={classes.advertiserCardBox}>
          <Link to={advertiserUrl(advertiser)}>
            <div className={classes.advertiserCard}>
              <ProfilePicture
                className={classes.advertiserLogo}
                square
                size={50}
                src={advertiser.profilePicture}
              />
              <div className={classes.advertiserInfo}>
                <h3 className={classes.advertiserName}>{advertiser.name}</h3>
                <p className={classes.advertiserDomain}>{advertiser.domain}</p>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </RowWrap>
  );
};

const useStyles = makeStyles({
  root: {
    marginTop: '30px',
  },
  advertiserCardBox: {
    position: 'relative',
    width: '255px',
    height: '80px',
  },
  advertiserCard: {
    display: 'flex',
    alignItems: 'center',
  },
  advertiserLogo: {
    flex: '0 0 50px',
  },
  advertiserInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
  },
  advertiserName: {
    fontWeight: 700,
    fontSize: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '170px',
    overflow: 'hidden',
  },
  advertiserDomain: {
    fontSize: '12px',
    color: black40,
    textTransform: 'lowercase',
  },
});

export default AdminListAdvertisers;
