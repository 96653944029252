import PropTypes from 'prop-types';
/* eslint-disable max-len */

import React from 'react';

export default class Logo extends React.PureComponent {
  static propTypes = {
    size: PropTypes.number,
    desaturated: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    size: 25,
  };

  render() {
    return (
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Untitled-2"
        x="0px"
        y="0px"
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 26 25"
        enableBackground="new 0 0 17 14"
      >
        <path
          d="M13.000121,18.8062818 L13.000121,18.8062818 L12.999879,18.8062818 L3.35562689,12.588941 L0,14.7520241 L0,17.3423924 L6.50006048,21.5329852 L10.6240149,24.1913941 C12.0813212,25.131015 13.9186788,25.131015 15.376227,24.1913941 L19.4999395,21.5329852 L26,17.3423924 L26,14.7520241 L22.6443731,12.588941 L13.000121,18.8062818 Z"
          id="Fill-1"
          fill={this.props.desaturated ? '#A7A9AC' : '#A67A2E'}
        />
        <path
          d="M19.4999395,3.62649625 L15.3759851,0.968087368 C13.9186788,0.0284663884 12.0813212,0.0284663884 10.623773,0.968087368 L6.50006048,3.62649625 L0,7.81708904 L0,10.4074573 L0,14.7338775 L3.35562689,12.5705404 L12.999879,6.35319966 L10.5289416,9.39192528 C9.03946034,11.2231834 9.03946034,13.9186592 10.5286997,15.7494095 L12.999879,18.7881351 L15.4710584,15.7494095 C16.9605397,13.9186592 16.9605397,11.2231834 15.4710584,9.39192528 L13.000121,6.35319966 L22.6443731,12.5705404 L26,14.7338775 L26,10.4074573 L26,7.81708904 L19.4999395,3.62649625 Z"
          id="Fill-3"
          fill="#0C0C0C"
        />
      </svg>
    );
  }
}
