import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const HideInDemoMode: React.FC<Props> = ({children}) => {
  return localStorage?.getItem('DEMO_MODE') === 'true' ? <></> : children;
};

export default HideInDemoMode;
