import {createStyles, makeStyles} from '@material-ui/styles';

import {
  black,
  black20,
  black60,
  green,
  purple,
  red,
  transitionTimeBase,
  gridRowSpacing,
  white,
} from '../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    target: {
      display: 'flex',
      padding: '20px 12px',
      width: '346px',
      height: '90px',
      transform: 'translate3d(0, 0, 0)',
      '&:hover $notifyButton': {
        opacity: 1,
      },
    },
    profilePictureBox: {
      position: 'relative',
      marginRight: '16px',
    },
    notifyButton: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: `${black}5`,
      borderRadius: '50%',
      opacity: 0,
      transition: `all ${transitionTimeBase}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: `${purple}95`,
      },
    },
    notifyButtonDisabled: {
      backgroundColor: `${white}5`,
    },
    notifyButtonIcon: {
      color: white,
    },
    notifyButtonDisabledIcon: {
      color: white,
      transform: 'translate(0, 2px)',
    },
    reservedCount: {
      display: 'block',
      textAlign: 'center',
      color: white,
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      width: '20px',
      height: '20px',
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: 900,
      background: red,
      borderRadius: '10px',
      border: `2px solid ${white}`,
    },
    userData: {
      display: 'flex',
      flexDirection: 'column',
      width: '256px',
    },
    userName: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    interestIconsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '3px',
      width: '240px',
    },
    interestIcons: {
      display: 'flex',
      flexShrink: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '8px',
    },
    interestIcon: {
      display: 'block',
      fontSize: '14px',
      color: black20,
      marginRight: '3px',
      flex: '0 1 auto',
    },
    interestIconHighlighted: {
      color: black60,
    },
    notified: {
      flex: '1 0 auto',
      fontSize: '11px',
      lineHeight: '14px',
      fontWeight: 700,
      color: purple,
      textTransform: 'lowercase',
      fontVariant: 'small-caps',
      position: 'relative',
      paddingLeft: '2px',
      '&:before': {
        content: `''`,
        display: 'block',
        position: 'absolute',
        right: '100%',
        top: '0',
        height: '100%',
        width: '20px',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0), white)',
      },
    },
    opened: {
      flex: '1 0 auto',
      fontSize: '11px',
      lineHeight: '14px',
      fontWeight: 700,
      color: green,
      textTransform: 'lowercase',
      fontVariant: 'small-caps',
      position: 'relative',
      paddingLeft: '2px',
      '&:before': {
        content: `''`,
        display: 'block',
        position: 'absolute',
        right: '100%',
        top: '0',
        height: '100%',
        width: '20px',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0), white)',
      },
    },
    targetButton: {
      margin: '16px 0 4px',
    },
    caption: {
      textTransform: 'capitalize',
      color: purple,
    },
    toggleButtonGroup: {
      width: '100%',
      minWidth: '500px',
      marginBottom: gridRowSpacing,
    },
  })
);
