import React from 'react';
import {gql, useQuery, useMutation} from '@apollo/client';
import moment from 'moment';
import {Box, Grid} from '@material-ui/core';

import {FormattedAddress, Definition, Section, LoaderDots} from '../../../../components/Widgets';
import {Form, FormSubmitButton, FormTextInput} from '../../../../components/Widgets/Forms';
import {RegionPicker} from '../../../../components/Region';

interface Props {
  match: {
    params: {
      influencerId: string;
    };
  };
}

const Settings: React.FC<Props> = ({match: {params}}) => {
  const {loading, error, data} = useQuery(InfluencerQuery, {
    variables: {id: params.influencerId},
  });
  const [updateLocation] = useMutation(UpdateLocationMutation);
  const [updateEmail] = useMutation(UpdateEmailMutation);

  if (loading) return <LoaderDots />;
  if (error) return <div>Unknown error encountered</div>;

  const {influencer, regions} = data;

  const setRegion = regions =>
    updateLocation({
      variables: {
        id: influencer.id,
        targetRegionId: regions[0].id,
      },
    });

  const setEmail = model => updateEmail({variables: {...model, id: influencer.id}});

  return (
    <Grid container spacing={6}>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Section title="Location" />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Definition
              title="Target region"
              definition={
                <RegionPicker
                  value={[influencer.targetRegion]}
                  availableRegions={regions}
                  single
                  onChange={setRegion}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Definition title="Current location" definition={influencer.currentRegion?.name} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Section title="Date of birth" />
        </Grid>
        <Grid item xs={12}>
          <Definition
            definition={influencer.birthday && moment(influencer.birthday).format('LL')}
          />
        </Grid>
      </Grid>
      {influencer.address && (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Section title="address" />
          </Grid>
          <Grid item xs={12}>
            <FormattedAddress address={influencer.address} />
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Section title="Email" />
        </Grid>
        <Grid item xs={12}>
          <Box component="span">
            The email that the influencer has set as their email. If the influencer wants to change
            their email, they can do it within the settings on their app. This will confirm they are
            who they say that are. <br />
            <strong>
              If the influencer asks to change their email because they no longer have access to it,
              you must ask them to contact @TakumiHQ on Instagram and request that we update their
              email there, to prove they have access to the Instagram account. This is to prevent
              potential abuse of someone taking over an account. <br />
            </strong>
            These email changes are logged and you are responsible for verifying the email owner of
            the account wants the new email address set.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Form initialState={{email: influencer.email}} onSubmit={setEmail}>
            <FormTextInput name="email" type="text" />
            <FormSubmitButton text="Change email" />
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

const InfluencerQuery = gql`
  query InfluencerQuery($id: UUIDString!) {
    influencer(id: $id) {
      id
      birthday
      targetRegion {
        id
        name
      }
      currentRegion {
        id
        name
      }
      address {
        id
        address1
        address2
        name
        city
        postalCode
        phonenumber
      }
      email
    }
    regions(includeUnsupported: true) {
      id
      name
    }
  }
`;

const UpdateLocationMutation = gql`
  mutation UpdateLocationMutation($id: UUIDString!, $targetRegionId: UUIDString) {
    updateInfluencer(id: $id, targetRegionId: $targetRegionId) {
      ok
      influencer {
        id
        targetRegion {
          id
          name
        }
      }
    }
  }
`;

const UpdateEmailMutation = gql`
  mutation UpdateEmailMutation($id: UUIDString!, $email: String!) {
    setInfluencerEmail(id: $id, email: $email) {
      ok
      influencer {
        id
        email
      }
    }
  }
`;

export default Settings;
