// eslint-disable-next-line import/named
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const togglesData: {sm: number; name: string; text?: string}[] = [
  {
    name: 'impressions',
    sm: 3,
    text: 'Impressions',
  },
  {
    name: 'brandCampaignsPage',
    sm: 9,
    text: 'Campaigns',
  },
  {
    name: 'engagementRate',
    sm: 3,
    text: 'Engagement Rate',
  },
  {
    name: 'dashboardPage',
    sm: 9,
    text: 'Dashboard',
  },
  {
    name: 'benchmarks',
    sm: 3,
    text: 'Benchmarks',
  },
  {
    name: '',
    sm: 9,
  },
  {
    name: 'hidden',
    sm: 3,
    text: 'hidden',
  },
  {
    name: '',
    sm: 9,
  },
  {
    name: 'campaignType',
    sm: 3,
    text: 'Campaign Type',
  },
  {
    name: '',
    sm: 9,
  },
  {
    name: 'budget',
    sm: 3,
    text: 'Budget',
  },
  {
    name: '',
    sm: 9,
  },
  {
    name: 'viewRate',
    sm: 3,
    text: 'View Rate',
  },
  {
    name: '',
    sm: 9,
  },
];

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
      '&.Mui-focusVisible': {
        color: theme.palette['stats-insights'],
      },
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(25px)',
        color: theme.palette.common.white,
        '&.Mui-focusVisible': {
          color: theme.palette['stats-insights'],
        },
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.common.black,
          borderColor: theme.palette.common.black,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 13,
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
    },
    checked: {},
    disabled: {
      '&$checked': {
        transform: 'translateX(25px)',
        color: '#d9d9d9',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#A1A4B1',
          borderColor: '#A1A4B1',
        },
      },
    },
  })
)(Switch);

export {togglesData, AntSwitch};
