import React, {useRef} from 'react';
import Button from '@material-ui/core/Button';
import {Container} from '@material-ui/core';

import {Modal, Prompt} from '../../../components/Widgets';
import {ModalElement} from '../../../components/Widgets/Modal';
import CampaignPlaylistTargetingSearch from '../CampaignPlaylistTargetingSearch';

interface Props {
  campaign: Campaign;
  notifyAllTargetsInCampaign: () => void;
}

const CampaignPlaylistModal: React.FC<Props> = ({campaign, notifyAllTargetsInCampaign}) => {
  const modal = useRef<ModalElement>(null);

  return (
    <Modal
      ref={modal}
      id="influencer-search"
      modalToggler={
        <Button color="secondary" variant="contained" size="large">
          Notify Influencers
        </Button>
      }
      fullWidth
      maxWidth="lg"
      showClose
    >
      <Container>
        <Prompt
          title="Notify all targeted influencers"
          body="Are you sure you want to notify all targeted influencers? This will make the campaign public"
          confirmText="Notify"
          control={
            <Button
              key="make_campaign_public_btn"
              color="secondary"
              variant="contained"
              size="large"
            >
              Notify everyone targeted
            </Button>
          }
          onSubmit={notifyAllTargetsInCampaign}
        />
        <CampaignPlaylistTargetingSearch campaign={campaign} />
      </Container>
    </Modal>
  );
};

export default CampaignPlaylistModal;
