import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black, transparentPurple} from 'consts/brand.integration';

type StyleProps = {
  isReadonly: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '10px',
      '&:hover': {
        backgroundColor: ({isReadonly}) => (isReadonly ? 'transparent' : transparentPurple),
        '& $removeMembership': {
          opacity: 1,
        },
      },
    },
    removeMembership: {
      opacity: 0,
      position: 'absolute',
      top: '-24px',
      right: '-24px',
      color: black,
      fontSize: '12px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        opacity: 1,
      },
    },
    info: {
      marginLeft: '16px',
      overflow: 'hidden',
    },
    fullName: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textDecoration: 'underline',
    },
    email: {
      fontSize: '12px',
      color: black,
      textTransform: 'lowercase',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    chip: {
      backgroundColor: theme.palette['ui-04'],
      height: '26px',
      borderRadius: '2px',
    },
  })
);
