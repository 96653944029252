import React, {useState} from 'react';
import {
  ComposedChart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import {Box, makeStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import {NoPerformanceData} from '../../../../../components/Widgets';
import useResponsive from '../../../../../hooks/useResponsive';
import {theme} from '../../../../../consts/theme';
import ChartLegend from './ChartLegend';
import ChartTooltip from './ChartTooltip';
import {lineData} from './consts';
import {hasValues, calculateDailyChange, formattedDate} from './utils';
import {ChangeableKeys, DataPoint} from './types';

interface Props {
  data: DataPoint[] | null;
  loading: boolean;
}

type VisibilityState = Record<ChangeableKeys, boolean>;

const Chart: React.FC<Props> = ({data, loading}) => {
  const isDesktop = useResponsive('up', 'md');
  const classes = useStyles({isDesktop});

  const dailyChangeData = data ? calculateDailyChange(data) : [];

  const getInitialVisibility = (metric: keyof typeof linesVisibility) => {
    if (hasValues('engagement_rate_by_impressions', dailyChangeData)) {
      return metric === 'engagement_rate_by_impressions';
    }
    return hasValues(metric, dailyChangeData);
  };

  const initialVisibility: VisibilityState = {
    engagement_total: getInitialVisibility('engagement_total'),
    engagement_rate_by_impressions: getInitialVisibility('engagement_rate_by_impressions'),
    reach_total: getInitialVisibility('reach_total'),
    impression_total: getInitialVisibility('impression_total'),
  };

  const [linesVisibility, setLinesVisibility] = useState(initialVisibility);

  const legendLineData = lineData.map(line => ({
    ...line,
    hide: !hasValues(line.dataKey, dailyChangeData),
  }));

  const handleLegendClick = (dataKey: keyof typeof linesVisibility) => {
    setLinesVisibility(prevState => ({
      ...prevState,
      [dataKey]: !prevState[dataKey],
    }));
  };

  if (loading) {
    return <Skeleton variant="rect" animation="wave" className={classes.skeleton} />;
  }

  const isEveryLineDisabled = dailyChangeData.every(d =>
    Object.keys(linesVisibility).every(line => (d as Record<string, unknown>)[line] == null)
  );

  if (isEveryLineDisabled) return null;

  if (!data) {
    return <NoPerformanceData title="Performance Chart engagement rate metrics" />;
  }

  const maxAxisDomain = data.reduce((prev, dataPoint) => {
    let newMax: number = prev;

    if (linesVisibility['engagement_total'] && dataPoint['engagement_total'] > newMax)
      newMax = dataPoint['engagement_total'];

    if (linesVisibility['reach_total'] && dataPoint['reach_total'] > newMax)
      newMax = dataPoint['reach_total'];

    if (linesVisibility['impression_total'] && dataPoint['impression_total'] > newMax)
      newMax = dataPoint['impression_total'];

    return newMax;
  }, 0);

  return (
    <div aria-label="Performance Metrics Chart">
      <ResponsiveContainer width="100%" aspect={isDesktop ? 5 : 2} className={classes.root}>
        <ComposedChart data={dailyChangeData}>
          {lineData.map(line => (
            <Line
              key={line.dataKey}
              type="monotone"
              dataKey={line.dataKey}
              yAxisId={line.yAxisId}
              stroke={line.color}
              strokeWidth="3"
              name={line.name}
              dot={false}
              hide={!linesVisibility[line.dataKey]}
            />
          ))}
          {lineData.map(line => (
            <YAxis
              key={line.yAxisId}
              yAxisId={line.yAxisId}
              domain={[0, line.calculateMaxAxisDomain?.(data) ?? maxAxisDomain]}
              hide={true}
              axisLine={{stroke: line.color}}
              tick={{fill: line.color}}
              tickFormatter={line.tickFormatter}
            />
          ))}
          <XAxis dataKey="snapshot_date" tickFormatter={formattedDate} />
          <Tooltip content={<ChartTooltip />} />
          <CartesianGrid stroke="#999" strokeDasharray="5 5" />
        </ComposedChart>
      </ResponsiveContainer>
      <Box position="relative" bgcolor="white" className={classes.legend}>
        <Legend
          verticalAlign="bottom"
          content={
            <ChartLegend
              linesVisibility={linesVisibility}
              handleLegendClick={handleLegendClick}
              lineData={legendLineData}
            />
          }
        />
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  root: (props: {isDesktop: boolean}) => ({
    backgroundColor: theme.palette['ui-02'],
    height: 'auto!important',
    maxWidth: props.isDesktop ? '100%' : '80vw',
    padding: '1rem 0 0',
  }),
  legend: (props: {isDesktop: boolean}) => ({
    position: 'relative',
    width: '100%',
    height: props.isDesktop ? '40px' : '80px',
  }),
  skeleton: {
    opacity: 0.5,
    height: '330px',
  },
});

export default Chart;
