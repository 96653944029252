import React from 'react';
import {Box, Grid, isWidthUp, Paper, withWidth} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

import {ChatBubble, Engagement, Heart} from 'components/Icons';
import {Media} from 'components/Widgets';
import GigCaption from 'components/Campaign/GigCaption';

import {numberWithCommas} from 'consts/utilities';
import {red, blue, green} from 'consts/brand';

const calcMediaEngagement = (followers, instagramPost) => {
  if (followers === 0) {
    return '0.0 %';
  }

  return `${(((instagramPost.likes + instagramPost.comments) / followers) * 100).toFixed(1)} %`;
};

interface Props {
  influencer: Influencer;
  instagramPost: InstagramPost;
  width: Breakpoint;
}

const InstagramMedia: React.FC<Props> = ({influencer, instagramPost, width}) => {
  const isWidthUpSM = isWidthUp('sm', width);

  return (
    <Paper key={instagramPost.id} square elevation={2}>
      <Box padding={1}>
        <a
          href={`https://instagram.com/p/${instagramPost.shortcode}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box>
            <Media media={instagramPost.media} />
          </Box>
        </a>
        <Box marginTop={1}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={4} style={{textAlign: 'left'}}>
              <Heart color={red} /> {numberWithCommas(instagramPost.likes)}
            </Grid>
            <Grid item xs={12} sm={4} style={{textAlign: isWidthUpSM ? 'center' : 'left'}}>
              <ChatBubble color={blue} /> {numberWithCommas(instagramPost.comments)}
            </Grid>
            <Grid item xs={12} sm={4} style={{textAlign: isWidthUpSM ? 'right' : 'left'}}>
              <Engagement color={green} />{' '}
              {calcMediaEngagement(influencer.instagramAccount?.followers, instagramPost)}
            </Grid>
            <Grid item xs={12}>
              <Box padding={1}>
                <GigCaption
                  caption={instagramPost.caption}
                  hashtags={instagramPost.hashtags}
                  mentions={instagramPost.mentions}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default withWidth()(InstagramMedia);
