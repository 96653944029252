import React from 'react';
import PropTypes from 'prop-types';

import {CampaignGig} from 'components/Campaign';

import {campaignUrlFromParams} from 'consts/urls';

const CampaignGigWrap = ({history, match: {params}, location}) => {
  const goToGig = gigId => {
    history.push(
      `${campaignUrlFromParams(params)}/posts/${params.postId}/gig/${gigId}${location.search}`
    );
  };

  const goBack = () => {
    history.push(`${campaignUrlFromParams(params)}/posts`);
  };

  return <CampaignGig goBack={goBack} goToGig={goToGig} />;
};

CampaignGigWrap.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default CampaignGigWrap;
