export const brandTotals: {[key: string]: {name: string; description: string}} = {
  campaign_count: {
    name: 'Total Campaigns',
    description: 'Total count of campaigns for the advertiser',
  },
  influencer_count_total: {
    name: 'Total Creators',
    description: 'Total count of creators for the advertiser',
  },
  post_count_total: {
    name: 'Total Posts',
    description: 'Total count of posts for the advertiser',
  },
  reach_total: {
    name: 'Total Reach',
    description: 'Total reach of posts for the advertiser',
  },
};

export const brandAverages: {[key: string]: {name: string; description: string}} = {
  engagement_rate_by_impressions_average: {
    name: 'Engagement Rate (avg)',
    description: 'Average engagement rate by impressions per campaign for the advertiser',
  },
  influencer_count_average: {
    name: 'Creators (avg)',
    description: 'Average number of creators per campaign for the advertiser',
  },
  post_count_average: {
    name: 'Posts (avg)',
    description: 'Average number of posts per campaign for the advertiser',
  },
  reach_average: {
    name: 'Reach (avg)',
    description: 'Average reach per campaign for the advertiser',
  },
};
