import {Card, CardContent, makeStyles, Typography} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {useHistory} from 'react-router';

interface Props {
  data: NotificationEvent;
  seen: false;
}

const NotificationItem: React.FC<Props> = ({data, seen}) => {
  const classes = useStyles({canClick: Boolean(data.url)});
  const history = useHistory();

  const {eventTitle, eventDescription, created, url} = data;
  let dateStr: string;

  if (!seen) {
    dateStr = `${moment.duration(moment().diff(moment(created))).humanize()} ago`;
  } else {
    dateStr = moment(created).format('YYYY-MM-DD');
  }

  const onClick = () => (url ? history.push(url) : {});

  return (
    <Card
      className={classes.notificationItem + (!seen ? ' ' + classes.notificationsItemUnseen : '')}
      variant="elevation"
      onClick={onClick}
    >
      <CardContent>
        <Typography variant="body1">{eventTitle}</Typography>
        <Typography variant="body2">{eventDescription}</Typography>
        <Typography variant="caption">{dateStr}</Typography>
      </CardContent>
    </Card>
  );
};

type StyleProps = {
  canClick: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  notificationItem: {
    margin: 10,
    borderRadius: 0,
    backgroundColor: theme.palette['hover-primary'],
    color: theme.palette['ui-01'],
    '& > div': {
      '&:hover': {
        borderLeft: ({canClick}) => (canClick ? `4px solid ${theme.palette['highlight-01']}` : 0),
        cursor: ({canClick}) => (canClick ? 'pointer' : 'not-allowed'),
      },
    },
  },
  notificationsItemUnseen: {
    '& > div': {
      borderLeft: `4px solid ${theme.palette['stats-impressions']}`,
      '& .MuiTypography-body1': {
        fontWeight: 'bold',
      },
    },
  },
}));

export default NotificationItem;
