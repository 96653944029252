import React from 'react';
import {gql, useQuery} from '@apollo/client';
import ErrorIcon from '@material-ui/icons/Error';
import {
  createStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

import {LoaderDots} from '../../../../../../components/Widgets';

const ContentRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-child(even)': {
        background: '#F4F4F4',
      },
      '&:nth-child(odd)': {
        background: '#FCFCFC',
      },
    },
  })
)(TableRow);

const UpcomingPayments: React.FC = () => {
  const {loading, data, error} = useQuery(PayableStatsQuery);

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return (
      <div style={{display: 'inline-flex'}}>
        <span style={{marginRight: '5px'}}>
          <ErrorIcon color="error" />
        </span>
        <Typography variant="body2">Error loading upcoming payments</Typography>
      </div>
    );
  }

  const {claimable, upcoming}: PayableStats = data.payableStats;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body2">
          The table below shows how much is claimable <i>overall right now </i>
          and how much has become claimable in the past 14 days.
        </Typography>
        <Typography variant="body2">
          Following that is a list of how much becomes claimable in the next few days and finally,
          how much has been committed in all active campaigns.
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} variant="outlined">
          <Table aria-label="Upcoming payments">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <b>GBP</b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <b>USD</b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <b>EUR</b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <b>SEK</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claimable.map(({description, amounts}, i: number) => (
                <ContentRow key={`claimable-row-${i}`}>
                  <TableCell>
                    <Typography variant="body2">{description}</Typography>
                  </TableCell>
                  <TableCell align="right">{amounts.gbp.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.usd.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.eur.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.sek.formattedValue}</TableCell>
                </ContentRow>
              ))}
              {upcoming.map(({description, amounts}, i: number) => (
                <ContentRow key={`upcoming-row-${i}`}>
                  <TableCell>
                    <Typography variant="body2">{description}</Typography>
                  </TableCell>
                  <TableCell align="right">{amounts.gbp.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.usd.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.eur.formattedValue}</TableCell>
                  <TableCell align="right">{amounts.sek.formattedValue}</TableCell>
                </ContentRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const PayableStatsQuery = gql`
  query UpcomingPaymentsPayableStatsQuery {
    payableStats {
      claimable {
        description
        amounts {
          gbp {
            formattedValue
          }
          usd {
            formattedValue
          }
          eur {
            formattedValue
          }
          sek {
            formattedValue
          }
        }
      }
      upcoming {
        description
        amounts {
          gbp {
            formattedValue
          }
          usd {
            formattedValue
          }
          eur {
            formattedValue
          }
          sek {
            formattedValue
          }
        }
      }
    }
  }
`;

export default UpcomingPayments;
