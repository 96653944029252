import React from 'react';
import {Box} from '@material-ui/core';

import {DocumentTitle} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

const AdminWrapper: React.FC = ({children}) => {
  return (
    <>
      <DocumentTitle title={getPageTitle('Admin')} />
      <Box>{children}</Box>
    </>
  );
};

export default AdminWrapper;
