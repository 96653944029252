import PropTypes from 'prop-types';
import React from 'react';
import {DragSource, DropTarget} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';

import {Trashbin} from 'components/Icons';
import Imgix from 'components/Widgets/Imgix';

import styles from './style.css';
import withDragDropContext from '../../../services/drag-drop-context';

const ItemTypes = {
  IMAGE: 'image',
};

const imageSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      url: props.picture ? props.picture.url : null,
    };
  },

  isDragging(props, monitor) {
    return props.picture.url === monitor.getItem().url;
  },
};

const imageTarget = {
  drop(props, monitor) {
    const {files} = monitor.getItem();
    if (files && files.length > 0) {
      props.handleDrop(files);
    } else {
      props.setSorting(false);
    }
    return {
      files: monitor.getItem().files,
    };
  },

  hover(props, monitor, component) {
    if (!props.picture) {
      return;
    }
    if (monitor.getItemType() === ItemTypes.IMAGE) {
      props.setSorting(true);
    }

    const ownId = props.picture.url;
    const draggedId = monitor.getItem().url;

    // react-dnd stuff below
    if (draggedId === ownId) {
      return;
    }

    const ownIndex = props.index;
    const draggedIndex = monitor.getItem().index;

    const boundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node
    const clientOffset = monitor.getClientOffset();
    const ownMiddleY = (boundingRect.bottom - boundingRect.top) / 2;
    if (!clientOffset) {
      return;
    }
    const offsetY = clientOffset.y - boundingRect.top;

    if (draggedIndex < ownIndex && offsetY < ownMiddleY) {
      return;
    }

    if (draggedIndex > ownIndex && offsetY > ownMiddleY) {
      return;
    }

    props.moveImage(draggedId, ownId);
  },
};

class GalleryImage extends React.PureComponent {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    picture: PropTypes.object,
    isDropzone: PropTypes.bool,
    removeImage: PropTypes.func,
  };

  constructor(context) {
    super(context);
    this.state = {
      hover: false,
    };
  }

  setHover(value) {
    this.setState({
      hover: value,
    });
  }

  render() {
    const {id, picture, isDropzone, isDragging, connectDragSource, connectDropTarget, removeImage} =
      this.props;
    const {hover} = this.state;
    const opacity = isDragging ? 0 : 1;

    const style = isDropzone ? {height: '146px', display: 'flex', alignItems: 'center'} : {};
    return connectDragSource(
      connectDropTarget(
        <div
          className={styles.root}
          style={{...style, opacity}}
          onMouseEnter={() => this.setHover(true)}
          onMouseLeave={() => this.setHover(false)}
        >
          {isDropzone && (
            <div className={styles.dropzone}>
              Drop Image files here <small className={styles.dropzoneSubtitle}>max 5 images</small>
            </div>
          )}
          {!isDropzone && (
            <div className={styles.imageContainer}>
              {hover && (
                <div className={styles.overlay}>
                  <span className={styles.overlayText}>Drag to Reorder</span>
                  <span className={styles.remove} onClick={() => removeImage(picture)}>
                    <Trashbin />
                  </span>
                </div>
              )}
              {id === 0 && !hover && <span className={styles.primary}>Cover</span>}
              {id === 0 ? (
                <Imgix
                  src={picture.url}
                  className={styles.image}
                  height={300}
                  width={300}
                  fit="crop"
                />
              ) : (
                <Imgix src={picture.url} className={styles.image} height={300} width={300} />
              )}
            </div>
          )}
        </div>
      )
    );
  }
}

// eslint-disable-next-line new-cap
export default withDragDropContext(
  DropTarget([ItemTypes.IMAGE, NativeTypes.FILE], imageTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))(
    // eslint-disable-next-line new-cap
    DragSource(ItemTypes.IMAGE, imageSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }))(GalleryImage)
  )
);
