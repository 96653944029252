import PropTypes from 'prop-types';
import React from 'react';
import {Grid} from '@material-ui/core';

import {Brief, Definition, FormattedDate} from 'components/Widgets';

import {capitalize, numberWithCommas} from 'consts/utilities';

import {ReportGigItem} from '../';
import styles from './style.css';

const ReportGigs = ({post, index}) => (
  <div className={styles.root}>
    <h2 className={styles.sectionHeading}>
      {post.opened ? (
        <span>
          Post {index} <span className={styles.dashHeading}>-</span>{' '}
          <span>
            <FormattedDate date={post.opened} format="Do MMMM, YYYY" />
          </span>
        </span>
      ) : (
        <span>Post {index}</span>
      )}
    </h2>
    <div className={styles.postInfo}>
      <Grid container spacing={2}>
        <Grid item>
          <Definition gold title="Type" definition={capitalize(post.postType)} />
        </Grid>
        <Grid item>
          <Definition
            gold
            title="Submissions open"
            definition={<FormattedDate date={post.opened} format="Do MMM" />}
          />
        </Grid>
        <Grid item>
          <Definition
            gold
            title="Deadline"
            definition={<FormattedDate date={post.deadline} format="Do MMM" />}
          />
        </Grid>
      </Grid>
    </div>
    {post.supportsStats && (
      <div className={styles.postInfo}>
        <Grid container spacing={2}>
          <Grid item>
            <Definition gold title="Likes" definition={numberWithCommas(post.likes)} />
          </Grid>
          <Grid item>
            <Definition gold title="Comments" definition={numberWithCommas(post.comments)} />
          </Grid>
          <Grid item>
            <Definition gold title="Engagements" definition={numberWithCommas(post.engagements)} />
          </Grid>
          <Grid item>
            <Definition gold title="Engagement rate" definition={post.engagement.formattedValue} />
          </Grid>
        </Grid>
      </div>
    )}
    <div className={styles.leftColumn}>
      <div className={styles.brief}>{post.brief && <Brief brief={post.brief} />}</div>
    </div>
    <div className={styles.rightColumn}>
      <div className={styles.hashTags}>
        <Definition
          gold
          title="Hashtags"
          definition={post.hashtags.map(hashtag => (
            <div key={hashtag}>#{hashtag}</div>
          ))}
        />
      </div>
      {post.mention && (
        <div>
          <Definition gold title="Mention" definition={<div>@{post.mention}</div>} />
        </div>
      )}
    </div>
    {post?.gigs
      ?.filter(gig => gig.instagramContent)
      .map(gig => (
        <ReportGigItem key={gig.id} gig={gig} />
      ))}
  </div>
);

ReportGigs.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ReportGigs;
