import PropTypes from 'prop-types';
import React from 'react';

import {DocumentTitle} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

import {OnboardPassword} from './components';
import styles from './style.css';

const Welcome = props => (
  <div className={styles.root}>
    <DocumentTitle title={getPageTitle('Welcome')} />
    <div className={styles.center}>{props.children || <OnboardPassword {...props} />}</div>
  </div>
);

Welcome.propTypes = {
  children: PropTypes.any,
};

export default Welcome;
