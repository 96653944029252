import React from 'react';
import {Typography, withStyles, makeStyles, createStyles} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  whisper,
  nearWhite,
  purple,
  black,
  transparentBlack,
  gridRowSpacing,
} from '../../../consts/brand.integration';
import {FormattedDate} from '../index';

interface Props {
  posts: Post[];
  selectedPosts: Post[];
  onSelect: (event: React.MouseEvent<HTMLElement>, newSelectedPosts: Post[]) => void;
}

const SelectDeliverables: React.FC<Props> = ({posts, selectedPosts, onSelect}) => {
  const classes = useStyles();

  return (
    <>
      <ToggleButtonGroup
        value={selectedPosts}
        aria-label="Campaign Deliverables"
        className={classes.toggleButtonGroup}
        onChange={onSelect}
      >
        {posts?.map((post, index) => (
          <SelectDeliverable key={post?.id} value={post}>
            <Typography variant="caption" component="p">
              Post {index + 1}
            </Typography>
            <Typography variant="body1" component="p" className={classes.caption}>
              {post?.postType}
            </Typography>
            <Typography variant="body2" component="p">
              <FormattedDate
                date={post?.opened}
                format="Do MMM YYYY, HH:mm zz"
                timezone={post.timezone}
              />
            </Typography>
          </SelectDeliverable>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      textTransform: 'capitalize',
      color: purple,
    },
    toggleButtonGroup: {
      width: '100%',
      marginBottom: gridRowSpacing,
    },
  })
);

const SelectDeliverable = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'block',
      textAlign: 'left',
      color: black,
      border: `1px solid ${transparentBlack}!important`,
      '&:hover': {
        color: black,
        backgroundColor: `${nearWhite}!important`,
      },
      '&.Mui-selected': {
        position: 'relative',
        color: black,
        backgroundColor: `${whisper}!important`,
        '&:hover': {
          color: black,
          backgroundColor: `${whisper}!important`,
        },
        '&::after': {
          content: '"\u2713"',
          position: 'absolute',
          top: 5,
          right: 5,
          width: 20,
          height: 20,
        },
      },
    },
  })
)(ToggleButton);

export default SelectDeliverables;
