import {useEffect, useState, useRef} from 'react';
import * as configCat from 'configcat-react';
import {isEqual} from 'lodash';

import useCurrentUser from './useCurrentUser';
import ErrorReporting from '../services/error-reporting';
import {CONFIGCAT_POLLING_SECONDS} from '../consts/variables';

/**
 * Set the feature flags to use and respective types here
 */
type FeatureFlags = {
  maintenanceMode: boolean;
  useSentryReplay: boolean;
  sentrySampleRate: number;
  useLogRocket: boolean;
  showInfluencerInstagramTab: boolean;
  useMetabaseCampaignStats: boolean;
  showPerformanceReport: boolean;
  showContentPlanningTable: boolean;
  showCampaignPerformanceChart: boolean;
  showCampaignSoldForDifferentPriceThenRateCardOption: boolean;
  showCampaignPaidMediaPerformance: boolean;
  showBrandPerformance: boolean;
  showBrandSocials: boolean;
  showCampaignPerformanceAggregate: boolean;
  showCampaignPerformanceCompare: boolean;
  showCampaignPerformanceSettings: boolean;
  displayBrandMembersProfile: boolean;
  showBrandCompetitors: boolean;
  useYoutubeIntegration: boolean;
  useTwitchIntegration: boolean;
  useNewRewardModel: boolean;
  useCreateInfluencerProfile: boolean;
  useAlternativeInfluencerSearch: boolean;
  showCampaignInfluenscore: boolean;
  showInfluencerInfluenscore: boolean;
};

/**
 * Set the flags default values here
 */
const DEFAULT_VALUES: FeatureFlags = {
  maintenanceMode: false,
  useSentryReplay: false,
  sentrySampleRate: 0,
  useLogRocket: false,
  showInfluencerInstagramTab: false,
  useMetabaseCampaignStats: false,
  showPerformanceReport: false,
  showContentPlanningTable: false,
  showCampaignPerformanceChart: false,
  showCampaignSoldForDifferentPriceThenRateCardOption: false,
  showCampaignPaidMediaPerformance: false,
  showBrandPerformance: false,
  showBrandSocials: false,
  showCampaignPerformanceAggregate: false,
  showCampaignPerformanceCompare: false,
  showCampaignPerformanceSettings: false,
  displayBrandMembersProfile: false,
  showBrandCompetitors: false,
  useYoutubeIntegration: false,
  useTwitchIntegration: false,
  useNewRewardModel: false,
  useCreateInfluencerProfile: false,
  useAlternativeInfluencerSearch: false,
  showCampaignInfluenscore: false,
  showInfluencerInfluenscore: false,
};

type Props = FeatureFlags & {
  loading: boolean;
};

/**
 * ConfigCat feature flags helper
 *
 * You can set your feature flags, types and default values.
 *
 * This hook implements `useCurrentUser`, so it is automagicaly alerted
 * to the current user's logged in and identity information.
 * This allows for targetted flags by:
 * - attribute `identifier` or `email` using `currentUser.email`
 * - custom attribute `role` using `currentUser.roleName`.
 *
 * @see https://configcat.com
 *
 * @implements `useCurrentUser`
 * @constant `CONFIGCAT_POLLING_SECONDS`
 *
 */
const useFeatureFlags = (): Props => {
  const configCatClient = configCat.useConfigCatClient();
  const currentUser = useCurrentUser();

  let interval: NodeJS.Timer | number | null = null;

  const [loading, setLoading] = useState(false);
  const flags = useRef<FeatureFlags>(DEFAULT_VALUES);

  const setConfigCatUser = (user?: User) => {
    if (user) {
      configCatClient.setDefaultUser(
        new configCat.User(user.id as string, user.email as string, undefined, {
          role: user.roleName as string,
        })
      );
    } else {
      configCatClient.setDefaultUser(new configCat.User('NO_USER'));
    }
  };

  useEffect(() => {
    if (currentUser) {
      setConfigCatUser(currentUser);
    } else {
      setConfigCatUser();
    }
    configCatClient.forceRefreshAsync();
    fetchFeatureFlags();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setConfigCatUser(currentUser);
    }

    // first cache data fetch
    fetchFeatureFlags();

    // set interval cache data fetch
    interval = setInterval(fetchFeatureFlags, CONFIGCAT_POLLING_SECONDS * 1000);

    return () => {
      if (interval) {
        clearInterval(interval as number);
        interval = null;
      }
    };
  }, []);

  /**
   * Fetch latest flag data from configCat cache and populate `flags` state
   */
  const fetchFeatureFlags = async () => {
    setLoading(true);
    try {
      const response = await configCatClient.getAllValuesAsync();
      const newFlags: FeatureFlags = response.reduce(
        (acc, {settingKey, settingValue}) => ({...acc, [settingKey]: settingValue}),
        DEFAULT_VALUES
      );
      if (!isEqual(flags.current, newFlags)) {
        flags.current = newFlags;
        checkNonHookFlags(newFlags);
      }
    } catch (error: any) {
      ErrorReporting.captureError(error);
    }
    setLoading(false);
  };

  // Some flags might not be for use with a hook,
  // we can check and act on these here.
  const checkNonHookFlags = (flags: FeatureFlags) => {
    if (flags.useLogRocket) {
      window.localStorage.setItem('USE_LOGROCKET', String(true));
    } else {
      window.localStorage.removeItem('USE_LOGROCKET');
    }

    if (flags.sentrySampleRate) {
      window.localStorage.setItem('SENTRY_SAMPLE_RATE', String(flags.sentrySampleRate));
    } else {
      window.localStorage.removeItem('SENTRY_SAMPLE_RATE');
    }

    if (flags.useSentryReplay) {
      window.localStorage.setItem('USE_SENTRY_REPLAY', String(true));
    } else {
      window.localStorage.removeItem('USE_SENTRY_REPLAY');
    }
  };

  return {
    loading,
    ...flags.current,
  };
};

export default useFeatureFlags;
