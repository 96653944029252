import React from 'react';

import MetricItem from '../MetricItem';
import {RewardModels} from '../../../../../scenes/Admin/Campaigns/enums';
import {numberWithCommas, capitalize, numberMillified} from '../../../../../consts/utilities';
import {Campaign} from '../../types';
import {useStyles} from './styles';

interface Props {
  campaign: Campaign;
}

const getAllCampaignGigs = (posts: any) =>
  posts?.reduce((prev: any, current: any) => current?.gigs.length + prev, 0);

const Metrics: React.FC<Props> = ({campaign}: Props) => {
  const classes = useStyles();

  const getArrowDirection = (fact: number, plan: number) => {
    if (fact - plan > 0) return 'up';

    if (fact - plan < 0) return 'down';

    return undefined;
  };

  return (
    <div className={classes.wrapper}>
      {campaign?.rewardModel !== null && (
        <MetricItem title="Type" value={capitalize(campaign?.rewardModel)} />
      )}
      <MetricItem title="Posts" value={getAllCampaignGigs(campaign?.posts)} />
      <MetricItem
        title="Followers"
        value={numberWithCommas(campaign?.followersTotal)}
        formattedValue={numberMillified(campaign?.followersTotal)}
      />
      <MetricItem
        title="Reach"
        value={numberWithCommas(campaign?.reachTotal)}
        formattedValue={campaign?.reachTotal === 0 ? 'N/A' : numberMillified(campaign?.reachTotal)}
        plannedValue={
          campaign?.rewardModel === RewardModels.Reach
            ? numberWithCommas(campaign?.units)
            : undefined
        }
        formattedPlannedValue={
          campaign?.rewardModel === RewardModels.Reach
            ? numberMillified(campaign?.units)
            : undefined
        }
        arrow={
          campaign?.rewardModel === RewardModels.Reach
            ? getArrowDirection(campaign?.reachTotal, campaign?.units)
            : undefined
        }
      />
      <MetricItem
        title="Engagements"
        value={numberWithCommas(campaign?.engagementsTotal)}
        formattedValue={
          campaign?.engagementsTotal === 0 ? 'N/A' : numberMillified(campaign?.engagementsTotal)
        }
        plannedValue={
          campaign?.rewardModel === RewardModels.Engagement
            ? numberWithCommas(campaign?.units)
            : undefined
        }
        formattedPlannedValue={
          campaign?.rewardModel === RewardModels.Engagement
            ? numberMillified(campaign?.units)
            : undefined
        }
        arrow={
          campaign?.rewardModel === RewardModels.Engagement
            ? getArrowDirection(campaign?.engagementsTotal, campaign?.units)
            : undefined
        }
      />
      {campaign?.impressionsTotal !== null && (
        <MetricItem
          title="Impressions"
          value={numberWithCommas(campaign?.impressionsTotal)}
          formattedValue={
            campaign?.impressionsTotal === 0 ? 'N/A' : numberMillified(campaign?.impressionsTotal)
          }
          plannedValue={
            campaign?.rewardModel === RewardModels.Impressions
              ? numberWithCommas(campaign?.units)
              : undefined
          }
          formattedPlannedValue={
            campaign?.rewardModel === RewardModels.Impressions
              ? numberMillified(campaign?.units)
              : undefined
          }
          arrow={
            campaign?.rewardModel === RewardModels.Impressions
              ? getArrowDirection(campaign?.impressionsTotal, campaign?.units)
              : undefined
          }
        />
      )}
      <MetricItem
        title="Creators"
        value={campaign?.totalCreators === 0 ? 'N/A' : numberWithCommas(campaign?.totalCreators)}
        plannedValue={
          campaign?.rewardModel === RewardModels.Assets
            ? numberWithCommas(campaign?.units)
            : undefined
        }
        formattedPlannedValue={
          campaign?.rewardModel === RewardModels.Assets
            ? numberWithCommas(campaign?.units)
            : undefined
        }
        arrow={
          campaign?.rewardModel === RewardModels.Assets
            ? getArrowDirection(campaign?.totalCreators, campaign?.units)
            : undefined
        }
      />
      {campaign?.price !== null && (
        <MetricItem
          title="Budget"
          value={campaign?.price?.formattedValue}
          className={classes.budgetMetric}
        />
      )}
    </div>
  );
};

export default Metrics;
