import {ICampaignFilters} from '../scenes/Admin/Campaigns/types';
import {CAMPAIGN_FILTER_STATE, NONE_OPTION} from './variables';
import {ALL_SUPPORTED_REGIONS} from '../components/Region/SelectRegion';
import {
  currentDay,
  startOfDefaultRange,
} from '../scenes/Dashboard/Filters/components/DateRangePicker/consts';

export const filtersVariables: ICampaignFilters = {
  searchString: undefined,
  advertiserIndustries: [],
  region: ALL_SUPPORTED_REGIONS,
  rewardModel: NONE_OPTION,
  mine: false,
  archived: false,
  shippingRequired: false,
  brandSafety: false,
  brandMatch: false,
  hasNda: undefined,
  state: CAMPAIGN_FILTER_STATE.Launched.value,
  startDate: startOfDefaultRange,
  endDate: currentDay,
  searchAdvertiser: null,
};
