import React from 'react';
import {Button, Typography} from '@material-ui/core';
import {gql, useLazyQuery, useReactiveVar} from '@apollo/client';

import {Prompt} from 'components/Widgets';

import useRequiresPermission from 'hooks/useRequiresPermission';
import {IMPERSONATE_ANY_USER, IMPERSONATE_ADVERTISER} from 'consts/permissions';
import {UserRoles} from 'consts/roles';
import {isImpersonating, startUserImpersonation} from 'services/impersonation';

const ImpersonateUserButton: React.FC<{
  fullName: string;
  userId: string;
  role: string;
  disabled?: boolean;
}> = ({fullName, userId, role, disabled}) => {
  const canImpersonateAnyUser = useRequiresPermission(IMPERSONATE_ANY_USER);
  const canImpersonateAdvertiser = useRequiresPermission(IMPERSONATE_ADVERTISER);
  const isCurrentlyImpersonating = useReactiveVar(isImpersonating);

  const [getTokenForImpersonation, {error, loading}] = useLazyQuery<{
    impersonationToken: {token: string; expires: string};
  }>(ImpersonateUserQuery, {
    onCompleted: data => {
      startUserImpersonation(data.impersonationToken);
    },
  });

  const canImpersonate: boolean =
    Boolean(canImpersonateAnyUser) ||
    Boolean(canImpersonateAdvertiser && role === UserRoles.Advertiser);

  return (
    <Prompt
      title="Impersonate User"
      body={
        isCurrentlyImpersonating ? (
          <Typography variant="body2" color="error">
            Impersonating is disabled because you are already impersonating another user.
          </Typography>
        ) : (
          <>
            <Typography variant="body2" gutterBottom>
              Please confirm you want to start impersonating <strong>{fullName}</strong>. This will
              refresh the website.
            </Typography>
            {error && (
              <Typography variant="body2" color="error">
                Unable to start user impersonation.
              </Typography>
            )}
          </>
        )
      }
      confirmText="Impersonate User"
      control={
        <Button variant="outlined" disabled={!canImpersonate || disabled}>
          Impersonate User
        </Button>
      }
      disabled={isCurrentlyImpersonating}
      loading={loading}
      onSubmit={() => {
        getTokenForImpersonation({variables: {userId}});
      }}
    />
  );
};

const ImpersonateUserQuery = gql`
  query ImpersonateUserQuery($userId: UUIDString!) {
    impersonationToken(userId: $userId) {
      token
      expires
    }
  }
`;

export default ImpersonateUserButton;
