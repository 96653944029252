import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {DocumentTitle} from 'components/Widgets';
import {Form, FormPasswordInput, FormSubmitButton} from 'components/Widgets/Forms';

import {getPageTitle} from 'consts/utilities';
import {changePassword} from 'services/api';

import {ErrorMessage} from './components';
import {Nav} from '../components';
import styles from './style.css';

class RecoverPasswordToken extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      canSubmit: true,
      posting: false,
      error: null,
      payload: null,
    };
  }

  onSubmit = async model => {
    const {params} = this.props.match;
    const token = params.token;
    if (model.password && token) {
      try {
        this.setState({posting: true});
        const response = await changePassword(model.password, token);
        this.setState({payload: response.data});
      } catch (error) {
        this.setState({error});
      } finally {
        this.setState({posting: false});
      }
    }
  };

  renderMessage() {
    return (
      <div>
        <h4>{this.state.payload.message}</h4>
        <p className={styles.loginLink}>
          <Link to="/login">Go to login</Link>
        </p>
      </div>
    );
  }

  render() {
    const {posting, error, payload} = this.state;

    if (payload) {
      return this.renderMessage();
    }

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle('Password recovery')} />
        <Nav />

        <div className={styles.center}>
          <Form onSubmit={this.onSubmit}>
            <FormPasswordInput name="password" placeholder="New Password" />
            <ErrorMessage error={error?.message} />
            <FormSubmitButton pending={posting} text="Save password" />
          </Form>
        </div>
      </div>
    );
  }
}

export default RecoverPasswordToken;
