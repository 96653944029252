import {gql} from '@apollo/client';

import {GigMedia} from '../../../components/Gig';

export const CampaignQuery = gql`
  query CampaignPerformanceCampaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      advertiser {
        id
        name
        domain
        profilePicture
      }
      marketSlug
      archived
      posts {
        id
        postType
        gigs {
          id
        }
      }
      organicPerformanceMetrics
      paidPerformanceMetrics
    }
  }
`;

export const GigQuery = gql`
  query CampaignPerformanceGigQuery($campaignId: UUIDString!) {
    gigs(campaignId: $campaignId) {
      edges {
        node {
          id
          ...GigMediaFields
          state
          influencer {
            id
            fullName
            username
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${GigMedia.fragments.gig}
`;
