import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Dashboard from '../scenes/Dashboard';
import CampaignDetails from '../scenes/Dashboard/CampaignDetails';

/**
 * @path `/dashboard`
 */
export const DashboardRoute = (): JSX.Element => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Dashboard />
      </Route>
      <Route path={`${path}/campaigns/:campaign`}>
        <CampaignDetails />
      </Route>
    </Switch>
  );
};
