import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
    },
    budgetMetric: {
      borderLeft: `1px solid ${black}`,
      paddingLeft: '40px',
      marginRight: 0,
    },
  })
);
