import React from 'react';
import {Popover, IconButton, makeStyles, createStyles} from '@material-ui/core';
import {Icon} from '@iconify/react';

interface Props {
  items: React.ReactNode[];
}

const MenuPopover: React.FC<Props> = ({items}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'menu-popover' : undefined;
  const classes = useStyles();

  return (
    <div>
      <IconButton aria-describedby={id} aria-label="More options" onClick={handleClick}>
        <Icon icon="mdi:dots-horizontal" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={classes}
        onClose={handleClose}
      >
        <div style={{padding: '5px 10px'}}>
          {items.map((item, index) => (
            <div key={index} style={{margin: '5px 0'}}>
              {item}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  })
);

export default MenuPopover;
