import {gql} from '@apollo/client';

export const EditProfileQuery = gql`
  query EditProfileQuery {
    user: currentUser {
      id
      roleName
      fullName
      email
      profilePicture
      emailNotificationPreference
    }
  }
`;

export const EditProfileMutation = gql`
  mutation EditProfileMutation(
    $fullName: String
    $profilePicture: String
    $emailNotificationPreference: EmailNotificationPreferences
  ) {
    updateCurrentUser(
      fullName: $fullName
      profilePicture: $profilePicture
      emailNotificationPreference: $emailNotificationPreference
    ) {
      ok
      user {
        id
        fullName
        profilePicture
        emailNotificationPreference
      }
    }
  }
`;
