import React, {useRef} from 'react';
import {Button, DialogContentText, FormControl, Grid, TextField} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';

import Modal, {ModalElement} from '../../Widgets/Modal';

interface Props {
  updateSubmissionCaption: (caption: string) => Promise<any>;
  caption?: string;
}

const EditSubmissionCaption: React.FC<Props> = ({updateSubmissionCaption, caption}) => {
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      caption: caption || '',
    },
    validationSchema: yup.object({
      caption: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    await updateSubmissionCaption(formik.values.caption);
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="edit-captions"
      modalToggler={
        <Button variant="text" fullWidth>
          Edit caption
        </Button>
      }
      title="Edit caption"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
    >
      <DialogContentText component={'span'}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="caption"
                name="caption"
                label="Caption"
                value={formik.values.caption}
                aria-describedby={'caption'}
                error={formik.touched.caption}
                variant="outlined"
                minRows={4}
                maxRows={10}
                multiline
                required
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </DialogContentText>
    </Modal>
  );
};

export default EditSubmissionCaption;
