import {gql} from '@apollo/client';

export const CampaignInsightsQuery = gql`
  query CampaignInsightsQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      description
      brandSafety
      extendedReview
      archived
      state
      posts {
        id
        postType
        gigs {
          id
          post {
            id
            postType
          }
          influencer {
            id
            fullName
            profilePicture
            instagramAccount {
              id
              username
            }
            tiktokAccount {
              id
              username
            }
          }
          instagramContent {
            id
            ... on InstagramPost {
              link
            }
            ... on TiktokPost {
              link
            }
          }
          insight {
            id
            state
            created
            modified
          }
        }
      }
    }
  }
`;
