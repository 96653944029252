import React, {useState} from 'react';

import {UploadMedia} from 'components/Widgets';

import styles from './style.css';

const MediaUploader: React.FC = () => {
  const [url, setUrl] = useState<string>();

  const handleUploadSuccess = (url: string) => {
    setUrl(url);
  };

  return (
    <div className={styles.root}>
      <UploadMedia onUploadSuccess={handleUploadSuccess} />
      <div className={styles.url}>{url}</div>
    </div>
  );
};

export default MediaUploader;
