import {gql} from '@apollo/client';

import {Gig} from 'services/fragments';

export const UnreviewedGigsQuery = gql`
  query UnreviewedGigsQuery($campaign: UUIDString!) {
    campaign(id: $campaign) {
      id
      brandSafety
    }
    gigs(campaignId: $campaign, unreviewed: true) {
      edges {
        node {
          id
          history {
            created
            user {
              id
              fullName
              email
            }
            __typename
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      count
    }
  }
`;

export const ReviewGigMutation = gql`
  mutation ReviewGigMutation($gigId: UUIDString!, $feedback: String) {
    reviewGig(id: $gigId, feedback: $feedback) {
      ok
      gig {
        id
        state
        post {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const ApproveGigMutation = gql`
  mutation ApproveUnreviewedGigMutation($gigId: UUIDString!, $feedback: String) {
    approveGig(id: $gigId, feedback: $feedback) {
      ok
      gig {
        id
        state
        post {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const ReportGigMutation = gql`
  mutation ReportGigMutation($gigId: UUIDString!, $reason: String!) {
    reportGig(id: $gigId, reason: $reason) {
      ok
      gig {
        id
        state
        post {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const RejectGigMutation = gql`
  mutation RejectGigMutation($gigId: UUIDString!, $reason: String!) {
    rejectGig(id: $gigId, reason: $reason) {
      ok
      gig {
        id
        state
        post {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const RequestGigResubmissionMutation = gql`
  mutation RequestGigResubmissionMutation($gigId: UUIDString!, $reason: String!) {
    requestGigResubmission(id: $gigId, reason: $reason) {
      ok
      gig {
        id
        state
        post {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const GigQuery = gql`
  query UnreviwedGigsGigQuery($gigId: UUIDString!) {
    gig(id: $gigId) {
      id
      created
      reviewDate
      state
      submission {
        created
        caption
        hashtags
        mentions
        media {
          ...mediaResultFields
        }
      }
      post {
        id
        postType
        schedule {
          internalReviewDeadline
          externalReviewDeadline
        }
      }
      influencer {
        id
        fullName
        profilePicture
      }
    }
    isAdvertiser: hasRole(roleName: "advertiser")
    canAccessAllInfluencers: hasPermission(permission: "access_all_influencers")
  }
  ${Gig.mediaPreview}
`;
