import React from 'react';
import {Typography} from '@material-ui/core';
import {ComposedChart, Legend} from 'recharts';

import {numberWithCommas} from '../../../../../consts/utilities';
import {generateLegendPayload} from '../../../utils';
import {Summary, VisibleMetrics} from '../../../types';
import {useStyles} from './styles';

interface Props {
  summary: Summary;
  visibleMetrics: VisibleMetrics;
  containerWidth: number;
}

const ChartLegend: React.FC<Props> = ({summary, visibleMetrics, containerWidth}: Props) => {
  const classes = useStyles();

  const renderMetric = (metric: string, value: number) => (
    <div className={classes.metricWrapper}>
      <Typography variant="body2" className={classes.metric}>
        {metric}
      </Typography>
      <Typography component="span" variant="h2" className={classes.metricValue}>
        {numberWithCommas(value)}
      </Typography>
    </div>
  );

  return (
    <div className={classes.metrics}>
      {renderMetric('Total Campaigns', summary?.totalCampaigns)}
      {renderMetric('Total Creators', summary?.totalCreators)}
      {visibleMetrics?.impressions && renderMetric('Total Impressions', summary?.totalImpressions)}
      <ComposedChart width={containerWidth * 0.2} height={50}>
        <Legend layout="vertical" align="left" payload={generateLegendPayload(visibleMetrics)} />
      </ComposedChart>
    </div>
  );
};

export default ChartLegend;
