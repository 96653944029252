import React from 'react';

const Match = ({color = 'black', size = 24, ariaLabel = ''}) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    width="18"
    height={`${size}px`}
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50646 10.5378C-1.67001 16.9412 0.397213 21.1765 5.64091 24C3.32158 18.605 5.69133 15.1765 8.51486 12C6.49805 19.7647 10.1787 20.7731 11.4392 16.5378C13.809 18.5546 13.4056 21.1765 11.8426 24C16.7838 20.9748 18.8006 16.8908 15.1703 9.52941C14.414 10.437 14.0107 11.7983 12.6997 11.8487C9.372 11.9496 15.7249 2.42017 7.70814 0C9.47284 4.13445 1.96024 6.85714 3.17032 13.6639C1.96024 13.2605 1.50646 12.1008 1.50646 10.5378Z"
      fill={color}
    />
  </svg>
);

export default Match;
