import {makeStyles} from '@material-ui/styles';

import {black} from '../../../consts/brand.integration';
import {theme} from '../../../consts/theme';
import {IFilterStyle} from './types';

export const industryFilterStyle: IFilterStyle = {
  button: {
    height: 32,
    minHeight: 32,
    border: `1px solid ${theme.palette['text-01']}`,
    borderRadius: 0,
    width: 104,
    padding: '0 10px 0',
    '&:hover': {
      border: `1px solid ${theme.palette['text-01']}`,
      borderRadius: 0,
      cursor: 'pointer',
    },
  },
};
export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  groupRoot: {
    margin: '24px 24px 24px 0',
  },
  button: {
    backgroundColor: theme.palette['text-inverse'],
    border: `1px solid ${theme.palette['text-01']}`,
    color: theme.palette['text-01'],
    borderRadius: 0,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette['text-inverse'],
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
  checked: {
    backgroundColor: theme.palette['text-01'],
    color: theme.palette['text-inverse'],
    border: `1px solid ${theme.palette['text-01']}`,
    borderRadius: 0,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette['text-01'],
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
  filtersBlock: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  select: {
    '&.MuiInput-root': {
      fontSize: 14,
      height: 32,
      minHeight: 32,
      border: `1px solid ${theme.palette['text-01']}`,
      borderRadius: 0,
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        outline: '-webkit-focus-ring-color auto 1px',
      },
      '& .MuiSelect-root': {
        paddingLeft: '10px',
      },
      '& .MuiSelect-icon': {
        marginRight: '10px',
        marginTop: '2px',
        fontSize: '20px',
        color: black,
      },
    },
    '&.MuiInput-underline': {
      '&:before': {
        border: '0',
      },
      '&:hover:before': {
        border: '0',
      },
    },
  },
  filterButton: {
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  campaignsFound: {
    marginBottom: 14,
  },
  message: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0em',
    fontStyle: 'normal',
  },
  searchInput: {
    marginTop: 24,
  },
});
