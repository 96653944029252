import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button} from '@material-ui/core';

import {Prompt, LoaderDots} from 'components/Widgets';

import {MaterialDesignIcon} from '../../../../../../components/Icons';
import styles from './styles.css';

interface Props {
  paymentId: string;
}

const ApprovePaymentButton: React.FC<Props> = ({paymentId}) => {
  const [approvePayment, {loading, data}] = useMutation(ApprovePaymentMutation);

  if (loading) return <LoaderDots />;

  if (data?.approvePayment?.ok) {
    return <MaterialDesignIcon icon="done" className={styles.approvedIcon} />;
  }

  return (
    <Prompt
      title="Approve payment"
      body="Are you sure you want to approve this payment?"
      confirmText="Approve payment"
      control={
        <Button variant="outlined" fullWidth>
          Approve
        </Button>
      }
      onSubmit={() => approvePayment({variables: {paymentId}})}
    />
  );
};

const ApprovePaymentMutation = gql`
  mutation ApprovePayment($paymentId: UUIDString!) {
    approvePayment(paymentId: $paymentId) {
      ok
      payment {
        id
      }
    }
  }
`;

export default ApprovePaymentButton;
