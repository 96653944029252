import React from 'react';
import {createStyles, Divider, makeStyles} from '@material-ui/core';

const Separator = () => {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
};

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      margin: '1rem 0',
    },
  })
);

export default Separator;
