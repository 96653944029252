import React from 'react';
import {Helmet} from 'react-helmet-async';

interface Props {
  children?: React.ReactNode;
  title: string;
}

const DocumentTitle = ({children, title}: Props) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);

export default DocumentTitle;
