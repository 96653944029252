import React from 'react';

import Editor from './Editor';
import styles from './style.css';
import {serialize, deserialize} from './helpers';

interface Props {
  onChange: (value: any) => void;
  value: Record<any, any>[];
  documentTitle: string;
}

const BriefEditor: React.FC<Props> = ({onChange, value, documentTitle}) => {
  const onEditorChange = (data: Record<any, any>) => {
    const serialized = serialize(data);
    onChange(serialized);
  };

  return (
    <React.Fragment>
      <div className={styles.title}>Brief</div>
      <div className={styles.root}>
        <Editor
          initialValue={deserialize(value)}
          placeholder="Press tab for more formatting options."
          documentTitle={documentTitle}
          onChange={onEditorChange}
        />
      </div>
    </React.Fragment>
  );
};

export default BriefEditor;
