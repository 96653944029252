import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '36px',
    },
    button: {
      height: '40px',
      border: '1px solid #000',
      borderRadius: 0,
      padding: '12px 16px',
      marginLeft: '80px',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  })
);
