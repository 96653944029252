import React from 'react';
import {Alert, Color} from '@material-ui/lab';
import {Divider, Snackbar, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

interface Props {
  message: string;
  type: Color;
  timeout?: number;
  showHelpdeskLink?: boolean;
  open: boolean;
  onClose: () => void;
}

const TAKUMI_HELP_URL = 'https://help.takumi.com/';
const TAKUMI_SUPPORT_URL = 'https://takumihq.atlassian.net/servicedesk/customer/portal/2';

const NotificationBarComponent: React.FC<Props> = ({
  message,
  type,
  timeout,
  showHelpdeskLink,
  open,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={open}
      autoHideDuration={null}
      onClose={onClose}
    >
      <Alert severity={type} elevation={6} variant="filled" onClose={onClose}>
        {message}
        {type === 'error' && (
          <p>
            <br />
            <Divider variant="fullWidth" />
            <Typography variant="body2" style={{marginTop: '1rem'}}>
              Perhaps we can help...
            </Typography>
            <Typography variant="body2">
              <ul className={classes.list}>
                <li style={{fontWeight: 'bold'}}>
                  <a
                    className={classes.link}
                    href={`${TAKUMI_HELP_URL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TAKUMI FAQ
                    <span className={classes.noUnderline}> - Knowledge base and help chat</span>
                  </a>
                </li>
                {showHelpdeskLink && (
                  <li style={{fontWeight: 'bold'}}>
                    <a
                      className={classes.link}
                      href={`${TAKUMI_SUPPORT_URL}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      TAKUMI Support Helpdesk
                      <span className={classes.noUnderline}> - Raise a support ticket</span>
                    </a>
                  </li>
                )}
              </ul>
            </Typography>
          </p>
        )}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles({
  list: {
    listStyle: 'square',
    paddingInlineStart: '2rem',
    marginBlockStart: '0.5rem',
  },
  link: {
    color: 'white !important',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noUnderline: {
    textDecoration: 'none !important',
  },
});

export default NotificationBarComponent;
