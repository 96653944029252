import {
  Legend,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import React from 'react';
import ReactApexcharts from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {useTheme} from '@material-ui/core/styles';

type InfluenscoreChartProps = {
  data: {
    s_cpf?: number;
    s_cpr?: number;
    s_cpe?: number;
    s_cpm?: number;
    influenscore?: number;
  };
};

const InfluenscoreChartRecharts = ({data}: InfluenscoreChartProps) => {
  const chartData = [
    {name: 'Followers', value: data.s_cpf, color: '#f00'},
    {name: 'Reach', value: data.s_cpr, color: '#0f0'},
    {name: 'Engagement', value: data.s_cpe, color: '#00f'},
    {name: 'Impressions', value: data.s_cpm, color: '#ff0'},
  ];
  return (
    // <ResponsiveContainer minHeight={400} minWidth={400} width={"100%"} height={400}>
    <RadialBarChart
      startAngle={450}
      endAngle={90}
      data={chartData}
      innerRadius={'40%'}
      height={400}
      width={400}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, 10]}
        angleAxisId={0}
        tick={false}
        dataKey={'value'}
      />
      <RadialBar dataKey={'value'} background angleAxisId={0} data={chartData} />
      <Tooltip />
      <text
        color={'#000'}
        fontSize={36}
        x={'50%'}
        y={'50%'}
        textAnchor={'middle'}
        fontWeight={'bold'}
      >
        {data.influenscore?.toFixed(1)}
      </text>
      <text>InfluenScore</text>
    </RadialBarChart>
    // </ResponsiveContainer>
  );
};

const InfluenscoreChart = (props: InfluenscoreChartProps) => {
  const theme = useTheme();
  const options: ApexOptions = {
    chart: {
      // force the chart to rerender when props change
      id: `radialBar-${props.data.influenscore?.toFixed(1)}`,
    },
    colors: [
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.error.main,
    ],
    legend: {
      show: true,
      position: 'bottom',
      onItemClick: {
        toggleDataSeries: false,
      },
      fontSize: '16px',
      itemMargin: {
        vertical: 5,
        horizontal: 5,
      },
      labels: {
        colors: theme.palette.text.primary,
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          background: theme.palette.grey[100],
        },
        hollow: {
          size: '40px',
        },
        dataLabels: {
          name: {
            offsetY: -16,
            color: theme.palette.text.primary,
          },
          value: {
            fontSize: '36px',
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            formatter(val: number | string): string {
              if (typeof val === 'string') {
                return (parseFloat(val) / 10).toFixed(1);
              }

              return (val / 10).toFixed(1);
            },
          },
          total: {
            label: 'Overall',
            show: true,
            formatter: () => (props.data.influenscore ?? 0).toFixed(1),
          },
        },
      },
    },
    labels: ['Reach', 'Engagement', 'Impressions', 'Audience'],
  };
  const normalizeScore = (score?: number) => {
    return score ? score * 10 : 0;
  };

  return (
    <>
      <ReactApexcharts
        type={'radialBar'}
        series={[
          normalizeScore(props.data.s_cpr),
          normalizeScore(props.data.s_cpe),
          normalizeScore(props.data.s_cpm),
          normalizeScore(props.data.s_cpf),
        ]}
        options={options}
        height={600}
      />
    </>
  );
};

export default InfluenscoreChart;
