import React from 'react';
import {Tag, WithOutContext as ReactTags} from 'react-tag-input';

import './style.css';

/**
 * react-tag-input types state the type of `removeComponent` should be
 * `React.Component<any, any>` - however a functional component can be passed
 */
const Empty = (() => null) as unknown as React.Component;

interface Props {
  value: Region[];
  availableRegions: Region[];
  single: boolean;
  onChange: (regions: Region[]) => void;
}

const RegionPicker: React.FC<Props> = ({value = [], availableRegions = [], single, onChange}) => {
  const handleAddition = (tag: Tag) => {
    const region = availableRegions.filter(r => r.name === tag.text)[0];

    if (!region) {
      return;
    }

    if (value && value.filter(tag => tag?.text === name).length > 0) {
      return;
    }

    const nextRegions = single ? [region] : value.concat(region);
    onChange(nextRegions);
  };

  const handleDelete = (index: number) => {
    const nextRegions = [...value];
    nextRegions.splice(index, 1);
    onChange(nextRegions);
  };

  const tags = (value || []).map(region => ({
    text: region?.name || 'Unknown',
    id: region?.id,
  }));

  const suggestions: Tag[] = availableRegions
    .map(region => ({id: region.id, text: region.name!}))
    .filter(region => !tags.map(tag => tag.text).includes(region.text!));

  return (
    <ReactTags
      allowDeleteFromEmptyInput={false}
      placeholder="Search regions..."
      tags={tags}
      suggestions={suggestions}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      removeComponent={single ? Empty : undefined}
      autocomplete
      minQueryLength={1}
      allowDragDrop={false}
    />
  );
};

export default RegionPicker;
