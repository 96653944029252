import {createStyles, makeStyles} from '@material-ui/core';
import React, {ReactNode} from 'react';

interface Props {
  children: ReactNode | ReactNode[];
}

const FilterGroup: React.FC<Props> = ({children}) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px -5px',
      whiteSpace: 'nowrap',
    },
  })
);

export default FilterGroup;
