import React, {ReactNode} from 'react';
import {makeStyles, createStyles} from '@material-ui/styles';
import ISO6391 from 'iso-639-1';
import {Box, Grid} from '@material-ui/core';

import {
  BusinessAccount,
  CreatorAccount,
  NormalAccount,
  Glasses,
  NoGlasses,
  Curled,
  Straight,
} from '../../../../../components/Icons/InfoIcons';
import {Definition} from '../../../../../components/Widgets';
import Children from './components/Children';
import TagList from './components/TagList';

const capitalize = (s: string, deDash = false) => {
  let string = s;
  if (deDash) string = string.replace('-', ' ');
  const split = string.split(' ');
  return split.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const iconForAccountType = (type: string) => {
  switch (type) {
    case 'normal':
      return <NormalAccount />;
    case 'business':
      return <BusinessAccount />;
    case 'creator':
      return <CreatorAccount />;
    default:
      return null;
  }
};

const iconForGlasses = (glasses: ReactNode = null) => {
  if (glasses) return <Glasses />;
  return <NoGlasses />;
};

const iconForHairType = (type: string) => {
  switch (type) {
    case 'ea769c27-a210-4fb3-8341-a0c3174b2124':
      return <Curled />;
    case '0e7fb575-4184-48b9-b3b7-8e054c6f1e9a':
      return <Straight />;
    default:
      return null;
  }
};

const Color: React.FC<{color: string}> = ({color}) => {
  const classes = useStyles();
  return <Box className={classes.color} style={{backgroundColor: color}} />;
};

interface IconDefinitionProps {
  title: string;
  definition: ReactNode;
  icon: ReactNode;
}

const IconDefinition: React.FC<IconDefinitionProps> = ({title, definition, icon}) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Definition title={title} definition={definition} />
      </Grid>
      <Grid item xs={6} style={{alignSelf: 'center', textAlign: 'right'}}>
        {icon}
      </Grid>
    </Grid>
  );
};

interface Props {
  influencer: Influencer;
}

const InfluencerTags: React.FC<Props> = ({influencer}) => {
  if (!influencer.information) return null;

  const {accountType, glasses, eyeColour, hairType, hairColour, children, languages, tags} =
    influencer.information;

  return (
    <Grid container spacing={2}>
      {accountType && (
        <Grid item xs={12}>
          <IconDefinition
            title="Account type"
            definition={capitalize(accountType)}
            icon={iconForAccountType(accountType)}
          />
        </Grid>
      )}
      {glasses !== null && (
        <Grid item xs={12}>
          <IconDefinition
            title="Wears glasses"
            definition={glasses ? 'Yes' : 'No'}
            icon={iconForGlasses(glasses)}
          />
        </Grid>
      )}
      {eyeColour && (
        <Grid item xs={12}>
          <IconDefinition
            title="Eye color"
            definition={capitalize(eyeColour.name, true)}
            icon={<Color color={eyeColour.hex!} />}
          />
        </Grid>
      )}
      {hairType && (
        <Grid item xs={12}>
          <IconDefinition
            title="Hair type"
            definition={hairType.name}
            icon={iconForHairType(hairType.id)}
          />
        </Grid>
      )}
      {hairColour && (
        <Grid item xs={12}>
          <IconDefinition
            title="Hair color"
            definition={hairColour.name}
            icon={<Color color={hairColour.hex!} />}
          />
        </Grid>
      )}
      {children?.length !== 0 && (
        <Grid item xs={12}>
          <Children list={children} />
        </Grid>
      )}
      {languages?.length !== 0 && (
        <Grid item xs={12}>
          <TagList title="Languages" tags={languages?.map(lang => ISO6391.getName(lang))} />
        </Grid>
      )}
      {tags?.length !== 0 && (
        <Grid item xs={12}>
          <TagList title="Tags" tags={tags.map(tag => tag?.name)} />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    color: {
      display: 'inline-flex',
      height: '30px',
      width: '30px',
      borderRadius: '15px',
    },
  })
);

export default InfluencerTags;
