import React, {useState, useCallback, useRef, useEffect} from 'react';
import {gql, useMutation} from '@apollo/client';
import {DialogContent, Button} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {SelectDeliverables, Modal} from '../../../../../components/Widgets';
import {ModalElement} from '../../../../../components/Widgets/Modal';

interface Props {
  offer: Offer;
  campaign: Campaign;
  disabled?: boolean;
}

const UpdateDeliverables: React.FC<Props> = ({offer, campaign}) => {
  const modal = useRef<ModalElement>(null);

  const initialDeliverablesRef = useRef<OfferDeliverable[]>([]);
  const [selectedDeliverables, setSelectedDeliverables] = useState(() => {
    if (offer?.deliverables && campaign?.posts) {
      return campaign.posts.filter(
        post =>
          post &&
          offer.deliverables.some(
            deliverable => deliverable && deliverable.postId && deliverable.postId === post.id
          )
      );
    } else {
      return [];
    }
  });

  useEffect(() => {
    initialDeliverablesRef.current = offer?.deliverables || [];
  }, [offer]);

  const [addDeliverableToOffer] = useMutation(AddDeliverableToOfferMutation);
  const [removeDeliverableFromOffer] = useMutation(RemoveDeliverableFromOfferMutation);

  const selectDeliverables = useCallback((event, newSelectedPosts) => {
    setSelectedDeliverables(newSelectedPosts);
  }, []);

  const isSelectedPost = post => {
    return !!selectedDeliverables.find(selectedDeliverable => selectedDeliverable.id === post.id);
  };

  const handleSubmit = async () => {
    try {
      for (const post of selectedDeliverables) {
        if (!initialDeliverablesRef.current.find(d => d.postId === post.id)) {
          await addDeliverableToOffer({
            variables: {
              offerId: offer.id,
              postId: post.id,
            },
            refetchQueries: ['InfluencerOfferQuery'],
          });
        }
      }

      for (const deliverable of initialDeliverablesRef.current) {
        if (!selectedDeliverables.find(post => post.id === deliverable.postId)) {
          await removeDeliverableFromOffer({
            variables: {
              offerId: offer.id,
              deliverableId: deliverable.id,
            },
            refetchQueries: ['InfluencerOfferQuery'],
          });
        }
      }

      modal.current?.close();
    } catch (error) {
      console.error('Error updating deliverables:', error);
    }
  };

  return (
    <Modal
      ref={modal}
      id="update-deliverables"
      title="Update Campaign Deliverables"
      modalToggler={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="material-symbols:content-copy" />}
          disabled={!offer?.canBeEdited}
        >
          Update Deliverables
        </Button>
      }
      submitTitle="Update"
      fullWidth
      maxWidth="sm"
      showClose
      onSubmit={handleSubmit}
    >
      <DialogContent>
        <SelectDeliverables
          posts={campaign?.posts}
          selectedPosts={selectedDeliverables}
          isSelected={isSelectedPost}
          onSelect={selectDeliverables}
        />
      </DialogContent>
    </Modal>
  );
};

const AddDeliverableToOfferMutation = gql`
  mutation addDeliverableToOffer($offerId: UUIDString!, $postId: UUIDString!) {
    addDeliverableToOffer(offerId: $offerId, postId: $postId) {
      offer {
        id
        state
        deliverables {
          id
          postId
          post {
            id
            postType
          }
        }
      }
    }
  }
`;

const RemoveDeliverableFromOfferMutation = gql`
  mutation removeDeliverableFromOffer($offerId: UUIDString!, $deliverableId: UUIDString!) {
    removeDeliverableFromOffer(offerId: $offerId, deliverableId: $deliverableId) {
      offer {
        id
        state
        deliverables {
          id
          postId
        }
      }
    }
  }
`;

export default UpdateDeliverables;
