import React from 'react';
import {SvgIcon} from '@material-ui/core';

import {useStyles} from './styles';

const FilterIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon}>
      <svg
        width="34"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" fill="white" />
        <path
          d="M26 7H6L14 16.46V23L18 25V16.46L26 7Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default FilterIcon;
