import PropTypes from 'prop-types';
import React from 'react';

const CloseBold = ({color = '#FFFFFF'}) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Close-Bold" transform="translate(-982.000000, -21.000000)" fill={color}>
        <path
          d="M993.150136,40.5820968 L989.303439,40.5820968 L989.303439,32.2842216 L981.774904,32.2842216 L981.774904,28.3276189 L989.303439,28.3276189 L989.303439,20.0297438 L993.150136,20.0297438 L993.150136,28.3276189 L1000.67867,28.3276189 L1000.67867,32.2842216 L993.150136,32.2842216 L993.150136,40.5820968 Z"
          id="Close-Icon-Gray"
          transform="translate(991.226788, 30.305920) rotate(-45.000000) translate(-991.226788, -30.305920) "
        />
      </g>
    </g>
  </svg>
);

CloseBold.propTypes = {
  color: PropTypes.string,
};

export default CloseBold;
