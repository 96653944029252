import React from 'react';
import {Switch, useRouteMatch} from 'react-router-dom';

import {MANAGE_INFLUENCERS} from '../consts/permissions';
import {AdminSignups} from '../scenes/Admin';
import {AdminInfluencerInstagramPosts} from '../scenes/Admin/Influencer';
import AdminInfluencerWrapper from '../scenes/Admin/InfluencerWrapper';
import SecureRoute from './components/SecureRoute';

export const Signups = (): JSX.Element => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <SecureRoute
        exact
        path={`${path}`}
        permission={MANAGE_INFLUENCERS}
        component={AdminSignups}
      />
      <SecureRoute path={`${path}/:influencerId`} permission={MANAGE_INFLUENCERS}>
        <AdminSignups>
          <AdminInfluencerWrapper>{/* <AdminInfluencerInstagramPosts /> */}</AdminInfluencerWrapper>
        </AdminSignups>
      </SecureRoute>
    </Switch>
  );
};
