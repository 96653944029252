import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import {DialogContentText} from '@material-ui/core';

import {FormattedDate, Divider, LoaderDots, Modal} from 'components/Widgets';
import {Form, FormTextInput, FormSubmitButton} from 'components/Widgets/Forms';

import styles from './style.css';

class CommentsModal extends PureComponent {
  static propTypes = {
    commentOnOffer: PropTypes.func.isRequired,
    markCommentsAsSeen: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    offerId: PropTypes.string,
    offer: PropTypes.object,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: undefined,
      showNotSeen: true,
      notSeenComments: [],
    };
  }

  componentDidUpdate(oldProps) {
    const {offer} = this.props;
    if (offer && (!oldProps.offer || oldProps.offer.id !== offer.id)) {
      this.setState({
        notSeenComments: offer.comments.filter(comment => !comment.seen).map(comment => comment.id),
        showNotSeen: true,
      });
      setTimeout(() => {
        this.setState({showNotSeen: false});
      }, 2500);
      this.scrollToBottom();
      this.props.markCommentsAsSeen();
    }
  }

  scrollToBottom = async () => {
    try {
      await this.commentBottom.scrollIntoView({behavior: 'smooth'});
    } catch {
      // silently fail
    }
  };

  onSubmit = async model => {
    const {offer} = this.props;
    await this.props.commentOnOffer(offer.id, model);
    this.scrollToBottom();
  };

  keepTrackOfCommentChange = value => {
    this.setState({
      comment: value,
    });
  };

  render() {
    const {offerId, closeModal, offer, loading} = this.props;
    const {showNotSeen, notSeenComments} = this.state;
    if (!offerId) {
      return null;
    }

    return (
      <Modal
        id="comment-history"
        title="Comment history"
        open
        fullWidth
        maxWidth="sm"
        scroll
        showClose
        onClose={closeModal}
      >
        <DialogContentText>
          <div className={styles.root}>
            {loading ? (
              <LoaderDots />
            ) : !offer.comments.length ? (
              <p className={styles.noComments}>No comments</p>
            ) : (
              offer.comments.map(c =>
                c.creator.roleName === 'advertiser' ? (
                  <div
                    key={c.created}
                    className={classNames([
                      styles.brandCommentWrapper,
                      {[styles.notSeenBrand]: notSeenComments.includes(c.id) && showNotSeen},
                    ])}
                  >
                    <h4 className={styles.user}>{c.creator.fullName}</h4>
                    <p>{c.content}</p>
                    <p className={styles.brandCreated}>
                      <FormattedDate date={c.created} format="Do MMM, YYYY HH:mm:ss" />
                    </p>
                  </div>
                ) : (
                  <div
                    key={c.created}
                    className={classNames([
                      styles.commentWrapper,
                      {[styles.notSeenTakumi]: notSeenComments.includes(c.id) && showNotSeen},
                    ])}
                  >
                    <h4 className={styles.user}>{c.creator.fullName}</h4>
                    <p>{c.content}</p>
                    <p className={styles.created}>
                      <FormattedDate date={c.created} format="Do MMM, YYYY HH:mm:ss" />
                    </p>
                  </div>
                )
              )
            )}
            <Divider style={{margin: '20px -20px'}} />
            <div className={styles.input}>
              <Form resetAfterSubmit onSubmit={this.onSubmit}>
                <FormTextInput
                  name="comment"
                  placeholder="Add a comment&hellip;"
                  onChange={this.keepTrackOfCommentChange}
                />
                <FormSubmitButton text="Comment" gold block disabled={!this.state.comment} />
              </Form>
            </div>
            <div
              ref={el => {
                this.commentBottom = el;
              }}
              style={{float: 'left', clear: 'both'}}
            />
          </div>
        </DialogContentText>
      </Modal>
    );
  }
}

const OfferCommentsQuery = gql`
  query OfferCommentsQuery($offerId: UUIDString!) {
    offer(id: $offerId) {
      id
      comments {
        id
        content
        creator {
          id
          roleName
          fullName
        }
        created
        seen
      }
    }
  }
`;

const CommentOnOfferMutation = gql`
  mutation CommentOnOfferMutation($offerId: UUIDString!, $comment: String!) {
    commentOnOffer(id: $offerId, comment: $comment) {
      offer {
        id
        comments {
          id
          content
          seen
          creator {
            id
            roleName
            fullName
          }
          created
        }
      }
    }
  }
`;

const MarkCommentAsSeenMutation = gql`
  mutation MarkCommentsAsSeenMutation($offerId: UUIDString!) {
    markCommentsAsSeen(id: $offerId) {
      offer {
        id
        comments {
          id
          seen
        }
      }
    }
  }
`;

export default compose(
  graphql(OfferCommentsQuery, {
    props: ({data: {offer, loading}}) => ({
      offer,
      loading,
    }),
    skip: ownProps => !ownProps.offerId,
  }),
  graphql(CommentOnOfferMutation, {
    props: ({mutate}) => ({
      commentOnOffer: (offerId, {comment}) =>
        mutate({
          variables: {offerId, comment},
        }),
    }),
  }),
  graphql(MarkCommentAsSeenMutation, {
    props: ({mutate, ownProps: {offerId}}) => ({
      markCommentsAsSeen: () =>
        mutate({
          variables: {offerId},
        }),
    }),
  })
)(CommentsModal);
