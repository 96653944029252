import PropTypes from 'prop-types';
import React from 'react';

import {Button} from 'components/Widgets';

import styles from './style.css';

const formatTTL = ttl => {
  const ttlInHours = Math.round(ttl / 3600);
  return ttlInHours < 0 ? 'Expired' : `Expires in ${ttlInHours} hours`;
};

const AdminListPendingAdvertiserUsers = ({users, resendInvite, revokeInvite}) => (
  <div className={styles.root}>
    <table className={styles.table}>
      <tbody>
        <tr className={styles.row}>
          <th className={styles.cell}>Email</th>
          <th className={styles.cell}>Time to live</th>
          <th className={styles.cell}>Role</th>
        </tr>
        {users.map(user => (
          <tr key={user.id} className={styles.row}>
            <td className={styles.cell}>{user.email}</td>

            <td className={styles.cell}>{formatTTL(user.inviteTtl)}</td>

            <td className={styles.cell}>{user.roleName}</td>

            <td className={styles.buttonCell}>
              <Button dangerOutline text="Revoke" onClick={() => revokeInvite(user.id)} />
              <Button text="Resend" onClick={() => resendInvite(user.id)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

AdminListPendingAdvertiserUsers.propTypes = {
  resendInvite: PropTypes.func.isRequired,
  revokeInvite: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default AdminListPendingAdvertiserUsers;
