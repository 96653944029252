import {createStyles, makeStyles} from '@material-ui/core/styles';

import {
  black,
  black10,
  black20,
  white,
  boxShadowBase,
  semiBold,
  transparentBlack,
} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
      width: 346,
      position: 'relative',
      '&:hover': {
        boxShadow: `0px 2px 20px 0px ${transparentBlack}`,
      },
    },
    campaignPictureWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      backgroundColor: black20,
      color: white,
      flex: '0 0 344px',
      borderBottom: `1px solid ${black10}`,
      margin: '12px 12px 0 12px',
    },
    advertiserInfoWrapper: {
      display: 'flex',
      width: '100%',
      padding: '0 10px',
    },
    contentWrapper: {
      display: 'flex',
      width: '100%',
      marginBottom: '10px',
    },
    advertiserLogo: {
      boxShadow: boxShadowBase,
      flexShrink: 0,
    },
    advertiserCampaignInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 80px)',
      marginLeft: 10,
      flexGrow: 2,
    },
    campaignName: {
      fontWeight: semiBold,
      fontSize: 18,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      lineHeight: '24px',
    },
    advertiserInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: 1,
      marginBottom: '-2px',
    },
    advertiserName: {
      fontSize: 12,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: 10,
    },
    price: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 16,
      fontWeight: semiBold,
      paddingRight: '16px',
      paddingTop: '36px',
    },
    campaignProgress: {
      color: black,
      margin: '8px 12px',
    },
    rate: {
      fontSize: '12px',
      fontWeight: 400,
      height: '56px',
      width: '95px',
      backgroundColor: '#E00B79',
      paddingLeft: '8px',
      paddingTop: '4px',
    },
    rateValue: {
      fontSize: '24px',
      fontWeight: 600,
    },
  })
);
