import {createBrowserHistory} from 'history';
import {compose, legacy_createStore as createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import {createLogger} from 'redux-logger';

import promiseMiddleware from './middleware/promise';
import errorHandler from './middleware/error-handler';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

function serializeStore(state) {
  function serializeObject(obj) {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value && !value.toJS && typeof value === 'object') {
        result[key] = serializeObject(value);
      } else {
        result[key] = value?.toJS ? value.toJS() : value;
      }
    });
    return result;
  }

  return serializeObject(state);
}

const actionLogger = createLogger({
  collapsed: true,
  stateTransformer: state => serializeStore(state),
});

let finalCreateStore;

if (__DEV__) {
  finalCreateStore = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(errorHandler, promiseMiddleware, routerMiddleware(history), actionLogger)
    )
  );
} else {
  finalCreateStore = createStore(
    createRootReducer(history),
    compose(applyMiddleware(errorHandler, promiseMiddleware, routerMiddleware(history)))
  );
}

const store = finalCreateStore;

export default store;
