import React, {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import {gql, useMutation} from '@apollo/client';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useCurrentUser from 'hooks/useCurrentUser';

import {KeyValueText} from '../../../../components/Widgets';
import CopyPasteable from '../../../../components/Widgets/CopyPasteable/CopyPasteable';

interface Props {
  influencer: Influencer;
}

const InfoDump: React.FC<Props> = ({influencer}) => {
  const currentUser = useCurrentUser();
  const isDeveloper = currentUser?.roleName === 'developer';
  const [getOtpForInfluencer, {data: otpCodeData}] = useMutation<{
    getOtpForInfluencer: GetOtpForInfluencer;
  }>(GetOtpForInfluencer);
  const [otpCodeExpanded, setOtpCodeExpanded] = useState(false);
  const [qrOtpCode, setQrOtpCode] = useState('');

  const requestOtpForInfluencer = () =>
    getOtpForInfluencer({variables: {influencerId: influencer.id}});

  useEffect(() => {
    if (otpCodeExpanded) {
      requestOtpForInfluencer();
    }
    setQrOtpCode('');
  }, [otpCodeExpanded]);

  useEffect(() => {
    if (otpCodeData?.getOtpForInfluencer?.loginCode) {
      setQrOtpCode(otpCodeData.getOtpForInfluencer.loginCode);
    }
  }, [otpCodeData]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs>
        {influencer.isSignedUp && isDeveloper && (
          <KeyValueText name="ID" value={<CopyPasteable>{influencer.id}</CopyPasteable>} />
        )}
      </Grid>
      <Grid item xs>
        {influencer?.device?.id && (
          <KeyValueText
            name="Device"
            value={
              influencer.device && <CopyPasteable>{influencer.device?.deviceModel}</CopyPasteable>
            }
            placeholder="No device"
          />
        )}
      </Grid>
      {isDeveloper && (
        <>
          <Grid item xs>
            {influencer?.device?.id && (
              <KeyValueText
                name="Device Id"
                value={influencer.device && <CopyPasteable>{influencer.device?.id}</CopyPasteable>}
                placeholder="No device"
              />
            )}
          </Grid>
          <Grid item xs>
            <Accordion
              id="otpLoginQrCode"
              aria-label="otp-login-qr-code"
              style={{borderRadius: 0}}
              onChange={(event, isExpanded) => setOtpCodeExpanded(isExpanded)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="caption">Generate OTP QR</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <QRCode
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={qrOtpCode}
                      viewBox={`0 0 256 256`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      <CopyPasteable>{qrOtpCode}</CopyPasteable>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const GetOtpForInfluencer = gql`
  mutation OTPLogin($influencerId: UUIDString) {
    getOtpForInfluencer(id: $influencerId) {
      ok
      loginCode
    }
  }
`;

export default InfoDump;
