import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {useQuery, useMutation, gql} from '@apollo/client';
import {Chip, TableRow, TableCell, TableBody, Table, Button} from '@material-ui/core';

import DivHeading from 'components/Widgets/DivHeading';
import {MenuPopover, ProfilePicture} from 'components/Widgets';

import NotificationService from 'services/notifications';
import useRequiresPermission from 'hooks/useRequiresPermission';
import {IMPERSONATE_ADVERTISER, REMOVE_USER_FROM_ADVERTISER} from 'consts/permissions';
import ImpersonateUserButton from 'scenes/Admin/UserManagement/components/ListAdvertiserUsers/ImpersonateUserButton';
import {UserRoles} from 'consts/roles';

import {InviteMemberForm} from '..';
import {Match, Advertiser, AdvertiserMember} from '../../types';
import {useStyles} from './styles';

interface Edge {
  node: AdvertiserMember;
}

interface Props {
  match: Match;
  advertiser: Advertiser;
  sendInvite: (id: string, email: string) => void;
}

const Members: React.FC<Props & RouteComponentProps> = ({
  match: {params},
  advertiser,
  sendInvite,
}: Props) => {
  const hasRemovePermission = useRequiresPermission(REMOVE_USER_FROM_ADVERTISER);
  const hasImpersonatePermission = useRequiresPermission(IMPERSONATE_ADVERTISER);

  const classes = useStyles();

  const {loading, data} = useQuery(AdvertiserMembersQuery, {
    variables: {domain: params.advertiser},
  });

  const [removeMemberMutation] = useMutation(RemoveMemberMutation, {
    refetchQueries: ['AdvertiserMembersListQuery'],
  });

  const removeMember = (userId: string) => {
    removeMemberMutation({
      variables: {userId, advertiserId: advertiser.id},
    });
  };

  const sendInviteHandler = async ({email}: {email: string}) => {
    await sendInvite(advertiser.id, email);
    NotificationService.send(`Invite sent to ${email}`);
  };

  return (
    <div className={classes.root}>
      {!loading && data?.members && (
        <>
          <InviteMemberForm sendInvite={sendInviteHandler} />

          {data?.members.edges.length !== 0 && (
            <>
              <DivHeading title="Members" />
              <Table classes={classes}>
                <TableBody>
                  {data.members.edges
                    .map((edge: Edge) => edge.node)
                    .map((user: AdvertiserMember) => (
                      <TableRow key={user.id}>
                        <TableCell>
                          <div>
                            <ProfilePicture src={user.profilePicture ?? undefined} size={40} />
                          </div>
                        </TableCell>

                        <TableCell>{user.fullName || '-'}</TableCell>

                        <TableCell>{user.email}</TableCell>

                        <TableCell>
                          {user.hasInvitationSent && !user.hasAcceptedInvite && (
                            <Chip label="Invite pending" className={classes.chip} />
                          )}
                        </TableCell>

                        <TableCell>
                          {(hasRemovePermission || hasImpersonatePermission) && (
                            <MenuPopover
                              items={[
                                ...(hasImpersonatePermission
                                  ? [
                                      <ImpersonateUserButton
                                        key={`impersonate-${user.id}`}
                                        fullName={user.fullName}
                                        userId={user.id}
                                        disabled={!user.hasAcceptedInvite}
                                        role={UserRoles.Advertiser}
                                      />,
                                    ]
                                  : []),

                                ...(hasRemovePermission
                                  ? [
                                      <Button
                                        key={`remove-${user.id}`}
                                        variant="outlined"
                                        onClick={() => removeMember(user.id)}
                                      >
                                        Remove
                                      </Button>,
                                    ]
                                  : []),
                              ]}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const AdvertiserMembersQuery = gql`
  query AdvertiserMembersListQuery($domain: String!) {
    members: usersForAdvertiser(domain: $domain, first: 100) {
      edges {
        node {
          id
          profilePicture
          fullName
          email
          hasAcceptedInvite
          hasInvitationSent
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const RemoveMemberMutation = gql`
  mutation RemoveMemberMutation($userId: UUIDString!, $advertiserId: UUIDString!) {
    removeUserFromAdvertiser(userId: $userId, id: $advertiserId) {
      ok
    }
  }
`;

export default withRouter(Members);
