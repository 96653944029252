/* eslint-disable graphql/template-strings */
import {gql} from '@apollo/client';

export const CountriesQuery = gql`
  query CountriesQuery {
    countries {
      id
      name
      supported
    }
  }
`;

export const InstagramUserQuery = gql`
  query InstagramUserQuery($username: String) {
    instagramUser(username: $username) {
      id
      profilePicture
    }
  }
`;

export const CreateAdvertiserMutation = gql`
  mutation CreateAdvertiserMutation(
    $uniqueRef: StrippedString!
    $name: StrippedString!
    $profilePicture: String!
    $regionId: UUIDString!
    $instagramUser: StrippedString
    $vatNumber: String
    $advertiserIndustriesIds: [UUIDString]
  ) {
    createAdvertiser(
      uniqueRef: $uniqueRef
      name: $name
      profilePicture: $profilePicture
      regionId: $regionId
      instagramUser: $instagramUser
      vatNumber: $vatNumber
      advertiserIndustriesIds: $advertiserIndustriesIds
    ) {
      advertiser {
        id
        domain
      }
    }
  }
`;
