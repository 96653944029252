import {makeStyles, Theme} from '@material-ui/core/styles';

import {black, black20, fontFamily, semiBold} from '../../../../../consts/brand.integration';

interface Props {
  mobileOpen: boolean;
}

export const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    width: '100%',
    padding: 0,
    marginBottom: '24px',
  },
  accordionSummaryContent: {
    backgroundColor: black,
  },
  accordionSummaryContentExpanded: {
    backgroundColor: theme.palette['ui-06'],
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  profileAccordionRoot: {
    backgroundColor: 'transparent',
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
  },
  profileWrapper: {
    cursor: 'pointer',
    padding: '8px 8px 8px 32px',
    minWidth: '100%',
    maxHeight: 96,
    [theme.breakpoints.only('sm')]: {
      padding: ({mobileOpen}) => (mobileOpen ? '8px 32px 8px' : '0 2px'),
    },
    '&:hover': {
      backgroundColor: theme.palette['ui-06'],
    },
  },
  impersonationWarning: {
    display: 'flex',
    fontSize: '12px',
    color: 'red',
  },
  accordionSummaryRoot: {
    padding: 0,
    height: 98,
    '&:focus': {
      borderTop: '1px solid white',
      borderBottom: '1px solid white',
    },
  },
  fullName: {
    fontWeight: semiBold,
    fontSize: '14px',
    lineHeight: 1,
    wordBreak: 'break-all',
    color: theme.palette['ui-01'],
    [theme.breakpoints.down('sm')]: {
      display: ({mobileOpen}) => (mobileOpen ? 'inline' : 'none'),
    },
  },
  profileAccordionItem: {
    fontWeight: semiBold,
    fontSize: 14,
    lineHeight: '32px',
    cursor: 'pointer',
    padding: '0 30px',
    width: '100%',
    borderRight: '4px solid transparent',
    color: theme.palette['ui-01'],
    '&:hover': {
      backgroundColor: theme.palette['ui-06'],
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 22,
    },
  },
  activeLink: {
    borderRightColor: theme.palette['stats-insights'],
    fontWeight: semiBold,
    background: theme.palette['ui-06'],
  },
  buttonListItem: {
    margin: '16px 0',
    padding: 0,
  },
  buttonListItemButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    margin: 0,
    padding: 0,
    textAlign: 'left',
    fontFamily: fontFamily,
  },
  buttonListItemLabel: {
    paddingLeft: 30,
  },
  profileEmail: {
    padding: '25px 30px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: black20,
    cursor: 'default',
  },
  multiListItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    position: 'fixed',
    bottom: 24,
    left: 206,
    width: 291,
    background: 'black',
    padding: 0,
    '& > button': {
      [theme.breakpoints.down('xs')]: {
        padding: '12px 8px 12px 22px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '6px 0',
      },
    },
    [theme.breakpoints.only('sm')]: {
      left: ({mobileOpen}) => (mobileOpen ? 206 : 50),
    },
  },
}));
