import {gql} from '@apollo/client';

import {Campaign} from 'services/fragments';

export const DashboardCampaignsQuery = gql`
  query DashboardAdminCampaignsQuery(
    $order: String
    $advertiserIndustriesIds: [UUIDString]
    $state: String
    $rewardModel: String
    $region: UUIDString
    $searchAdvertiser: String
    $offset: Int
    $limit: Int
    $shippingRequired: Boolean
    $brandSafety: Boolean
    $brandMatch: Boolean
    $hasNda: Boolean
    $mine: Boolean
    $archived: Boolean
    $startDate: String
    $endDate: String
  ) {
    campaigns(
      order: $order
      advertiserIndustriesIds: $advertiserIndustriesIds
      state: $state
      rewardModel: $rewardModel
      searchAdvertiser: $searchAdvertiser
      regionId: $region
      offset: $offset
      limit: $limit
      shippingRequired: $shippingRequired
      brandSafety: $brandSafety
      brandMatch: $brandMatch
      hasNda: $hasNda
      mine: $mine
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      edges {
        node {
          id
          totalCreators
          created
          name
          price {
            formattedValue
          }
          campaignHighlights {
            engagementRate
          }
          isFullyReserved
          ...targetFields
          accessibleData {
            id
            name
            impressions
            benchmark
            engagementRate
            type
            reach
            assets
            budget
          }
          advertiser {
            id
            name
            profilePicture
          }
          photos {
            coverPhoto {
              url
            }
          }
          state
          started
        }
      }
      totalCampaignsData(
        order: $order
        advertiserIndustriesIds: $advertiserIndustriesIds
        state: $state
        regionId: $region
        rewardModel: $rewardModel
        searchAdvertiser: $searchAdvertiser
        shippingRequired: $shippingRequired
        brandSafety: $brandSafety
        brandMatch: $brandMatch
        hasNda: $hasNda
        mine: $mine
        archived: $archived
        startDate: $startDate
        endDate: $endDate
      ) {
        totalCampaigns
        totalCreators
        totalImpressions
        advertiserNames
      }
      count
    }
  }
  ${Campaign.targetFragment}
`;

export const CampaignQuery = gql`
  query DashboardCampaignPostsQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      advertiser {
        id
        name
      }
      reach
      reachTotal
      engagements
      engagementsTotal
      impressions
      impressionsTotal
      totalCreators
      followersTotal
      rewardModel
      ...targetFields
      state
      price {
        formattedValue
      }
      posts {
        id
        gigs {
          id
        }
      }
      engagement {
        formattedValue
      }
      campaignHighlights {
        engagementRate
        engagementRateStory
        engagementRateStatic
        engagementRateStoryFromTotal
        engagementRateStaticFromTotal
      }
      photos {
        coverPhoto {
          url
        }
      }
    }
  }
  ${Campaign.targetFragment}
`;

const DashboardMediaResultFields = gql`
  fragment DashboardMediaResultFields on MediaResult {
    __typename
    ... on Image {
      id
      url
    }
    ... on Video {
      id
      url
      thumbnail
    }
    ... on Gallery {
      items {
        __typename
        id
        ... on Image {
          url
        }
        ... on Video {
          url
          thumbnail
        }
      }
    }
  }
`;

const DashboardOfferFragment = gql`
  fragment DashboardOfferFragment on Offer {
    id
    influencerMetrics {
      engagementRateStatic
      engagementRateStory
      totalImpressions
      reach
    }
    influencer {
      id
      fullName
      profilePicture
      username
      followers
    }
    gigs {
      id
      instagramContent {
        id
        media {
          ...DashboardMediaResultFields
        }
      }
      submission {
        media {
          ...DashboardMediaResultFields
        }
      }
    }
  }
  ${DashboardMediaResultFields}
`;

export const CampaignCreatorsQuery = gql`
  query DashboardCampaignParticipantsQuery($campaignId: UUIDString!) {
    offers: topOffersInCampaign(campaignId: $campaignId) {
      edges {
        node {
          id
          ...DashboardOfferFragment
        }
      }
    }
  }
  ${DashboardOfferFragment}
`;
