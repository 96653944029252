import React from 'react';
import {createStyles, Grid, Avatar, Typography, makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';

import {CustomCard, FollowerData, InfluencerData, Separator} from 'components/Widgets';
import CopyPasteable from 'components/Widgets/CopyPasteable/CopyPasteable';
import InfluencerHandles from 'components/Widgets/InfluencerHandles/InfluencerHandles';

import defaultAvatar from 'scenes/App/components/SideBarMenu/img/defaultAvatar.png';
import {getLocation} from 'consts/influencerHelper';

interface Props {
  influencer: Influencer;
  address?: any;
}

const InfluencerProfile: React.FC<Props> = ({influencer, address}) => {
  const classes = useStyles();
  const {
    profilePicture,
    fullName,
    biography,
    state,
    gender,
    email,
    instagramAccount,
    tiktokAccount,
    youtubeChannel,
    twitchChannel,
    engagement,
    targetRegion,
  } = influencer;
  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomCard>
          <Link to={`/influencers/${influencer.id}`}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4}>
                <Avatar src={profilePicture ?? defaultAvatar} className={classes.avatar} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h2" component="p">
                  {fullName}
                </Typography>
                <Typography variant="h3" component="p">
                  <InfluencerHandles influencer={influencer} />
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Separator />
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <FollowerData
                instagramFollowers={instagramAccount?.followers}
                tiktokFollowers={tiktokAccount?.followers}
                youtubeSubscriberCount={youtubeChannel?.subscriberCount}
                twitchFollowers={twitchChannel?.followers}
                engagementRate={engagement?.formattedValue}
                size="large"
              />
            </Grid>
          </Grid>
          {biography && (
            <>
              <Separator />
              <Grid item xs={12}>
                <InfluencerData icon="mdi:biography" data={biography} />
              </Grid>
            </>
          )}
          <Separator />
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <InfluencerData
                icon={state === 'verified' ? 'mdi:account-success' : 'mdi:account-pending'}
                data={state}
              />
            </Grid>
            {gender && (
              <Grid item>
                <InfluencerData
                  icon={gender === 'female' ? 'material-symbols:female' : 'material-symbols:male'}
                  data={gender}
                />
              </Grid>
            )}
          </Grid>
          <>
            <Separator />
            <Grid item xs={12}>
              <InfluencerData
                icon="material-symbols:mail"
                component={<CopyPasteable>{email}</CopyPasteable>}
              />
            </Grid>
          </>
          <Separator />
          {targetRegion && (
            <Grid item xs={12}>
              <InfluencerData
                icon={`circle-flags:${targetRegion.countryCode?.toLowerCase()}`}
                data={getLocation(targetRegion.name, targetRegion.country)}
              />
            </Grid>
          )}
          {address && (
            <>
              <Separator />
              <Grid item xs={12}>
                <InfluencerData icon="mdi:address-marker" data={address} />
              </Grid>
            </>
          )}
        </CustomCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      height: '100%',
      width: '100%',
      maxHeight: '128px',
      maxWidth: '128px',
    },
  })
);

export default InfluencerProfile;
