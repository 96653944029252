import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {makeStyles, createStyles} from '@material-ui/core';

interface Props {
  influencer: Influencer;
}

const ViewOffer: React.FC<Props> = ({influencer}) => {
  const classes = useStyles();
  const location = useLocation();
  const influencerId = influencer.id;
  const brandId = location.pathname.replace('/participants', '/');
  const influencerOffer = `${brandId}influencers?q=/influencers/${influencerId}`;

  return (
    <Link to={influencerOffer} className={classes.root}>
      View Offer
    </Link>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: 'black',
      color: 'white',
      cursor: 'pointer',
      fontSize: '14px',
      fontVariant: 'small-caps',
      textTransform: 'lowercase',
      fontWeight: 700,
      lineHeight: 1,
      padding: '5px 10px 7px',
      margin: '0',
      border: '0',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
  })
);

export default ViewOffer;
