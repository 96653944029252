import PropTypes from 'prop-types';
import React from 'react';

import {DefaultRegionPreferences} from 'components/Region';
import {Button} from 'components/Widgets';

import styles from './style.css';

const AdminInfluencerRegionCursorHeader = ({count, moveNext, hasNextPage}) => {
  return (
    <div className={styles.topBar}>
      <div className={styles.count}>Remaining: {count}</div>
      <div className={styles.buttons}>
        {hasNextPage && (
          <div>
            <Button gray text="Next" onClick={moveNext} />
          </div>
        )}
        <div className={styles.selectRegion}>
          <DefaultRegionPreferences />
        </div>
      </div>
    </div>
  );
};

AdminInfluencerRegionCursorHeader.propTypes = {
  influencerId: PropTypes.string,
  count: PropTypes.number,
  moveNext: PropTypes.func,
  hasNextPage: PropTypes.bool,
};

export default AdminInfluencerRegionCursorHeader;
