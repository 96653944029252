import {createStyles, makeStyles, Theme} from '@material-ui/core';

import {white, black} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      color: theme.palette['ui-05'],
      '&$checked': {
        color: theme.palette['ui-07'],
      },
      '&:hover': {
        color: theme.palette['ui-07'],
      },
      '&.Mui-disabled': {
        opacity: 0.3,
      },
    },
    blackTextColor: {
      color: theme.palette['ui-07'],
    },
    label: {
      color: black,
      paddingTop: 9,
    },
    pill: {
      backgroundColor: white,
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      marginBottom: 8,
      marginRight: 10,
      marginTop: 8,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
  })
);
