import React, {useRef} from 'react';
import {Button, DialogContentText, Grid} from '@material-ui/core';

import Modal, {ModalElement} from '../../Widgets/Modal';

interface Props {
  // confirmForceApprove: (caption: string) => Promise<any>;
  confirmForceApprove: () => string;
  brandName?: Maybe<string>;
}

const GigForceApprove: React.FC<Props> = ({confirmForceApprove, brandName}) => {
  const modal = useRef<ModalElement>(null);

  const handleSubmit = async () => {
    confirmForceApprove();
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="gog-force-approve"
      modalToggler={
        <Button variant="text" fullWidth>
          Force approval
        </Button>
      }
      title="Confirm gig approval"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={handleSubmit}
    >
      <DialogContentText component={'span'}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            Please confirm you wish to approve this gig on behalf of{' '}
            {`"${brandName}"` || 'the client'}.
          </Grid>
          <Grid item></Grid>
        </Grid>
      </DialogContentText>
    </Modal>
  );
};

export default GigForceApprove;
