export const influencerProperties = [
  'influencer_id',
  'instagram_handle',
  'tiktok_handle',
  'instagram_profile_picture_url',
  'tiktok_profile_picture_url',
  'instagram_profile_url',
  'tiktok_profile_url',
  'full_name',
];

export const influencerSelect = `*,influencer:influencer_information(${influencerProperties.join(
  ', '
)})`;
