import PropTypes from 'prop-types';
import React from 'react';
import {applyMiddleware, legacy_createStore as createStore} from 'redux';
import thunk from 'redux-thunk';
import {Form as ReduxForm, combineForms, actions} from 'react-redux-form';

import NotificationService from 'services/notifications';
import ErrorReporting from 'services/error-reporting';

const isPromise = subject => subject && typeof subject.then == 'function';

export default class Form extends React.PureComponent {
  static displayName = 'Form';

  static propTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func,
      dispatch: PropTypes.func,
      getState: PropTypes.func,
    }),

    // provided props
    initialState: PropTypes.any,
    model: PropTypes.string.isRequired,
    getDispatch: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onValidChange: PropTypes.func,
    encType: PropTypes.oneOf(['multipart/form-data']),
    resetAfterSubmit: PropTypes.bool,
  };

  static defaultProps = {
    initialState: {},
    model: 'local',
    onChange: () => {
      /* do nothing */
    },
    onValidChange: () => {
      /* do nothing */
    },
  };

  constructor(props) {
    super(props);

    this.store =
      props.store ||
      createStore(
        combineForms({
          [props.model]: props.initialState,
        }),
        applyMiddleware(thunk)
      );

    this.dispatch = action => {
      if (typeof action === 'function') {
        return action(this.store.dispatch, this.store.getState);
      }

      return this.store.dispatch(action);
    };
  }

  componentDidMount() {
    if (this.props.getDispatch) {
      this.props.getDispatch(this.dispatch);
    }
  }

  handleChange = value => {
    this.props.onChange(value);

    const state = this.store.getState();
    if (state.forms['$form'].valid) {
      this.props.onValidChange(value);
    }
  };

  handleSubmit = async (model, event) => {
    if (this.props.encType == 'multipart/form-data') {
      model = new FormData(event.target);
    }

    const maybePromise = this.props.onSubmit(model);

    if (!isPromise(maybePromise)) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn(
          'Return a promise from onSubmit to get automatic loading and success/failure handling'
        );
      }
      return;
    }

    try {
      this.dispatch(actions.setPending(this.props.model, true));
      await maybePromise;
      this.dispatch(actions.setSubmitted(this.props.model, true));
    } catch (err) {
      ErrorReporting.captureError(err);
      NotificationService.onFormError(err);
      this.dispatch(actions.setSubmitFailed(this.props.model, true));
    } finally {
      this.dispatch(actions.setPending(this.props.model, false));
      if (this.props.resetAfterSubmit) {
        this.dispatch(actions.reset(this.props.model));
      }
    }
  };

  render() {
    const {initialState, onChange, onSubmit, onValidChange, ...rest} = this.props; // eslint-disable-line
    return (
      <ReduxForm
        store={this.store}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        {...rest}
      />
    );
  }
}
