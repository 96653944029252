import React from 'react';
import {Card, Divider, makeStyles} from '@material-ui/core';
import {TooltipProps} from 'recharts';

import {
  formatPercentage,
  numberMillified,
  numberWithCommas,
} from '../../../../../../consts/utilities';
import {FormattedDate} from '../../../../../../components/Widgets';
import {LONG_DATE_FORMAT} from '../../../../../../consts/variables';
import {white} from '../../../../../../consts/brand.integration';

type Props = Pick<TooltipProps, 'active' | 'payload' | 'label'>;

const ChartTooltip: React.FC<Props> = ({active, payload, label}) => {
  const classes = useStyles();

  if (active && payload?.length) {
    const currentData = payload[0].payload;
    const metrics = payload.map((item: any) => {
      const changeKey = `${item.dataKey}_change` as keyof typeof currentData;
      const changeValue = currentData[changeKey];
      const arrow = changeValue >= 0 ? '↑' : '↓';
      const color = changeValue >= 0 ? 'green' : 'red';

      return {
        name: item.name,
        value:
          item.dataKey === 'engagement_rate_by_impressions'
            ? formatPercentage(item.value)
            : numberMillified(item.value),
        change: numberWithCommas(changeValue),
        arrow,
        color,
      };
    });

    return (
      <Card className={classes.root}>
        <p className={classes.spacing}>
          <FormattedDate date={label} format={LONG_DATE_FORMAT} />
        </p>
        <Divider className={classes.spacing} />
        {metrics.map((metric: any, index: number) => (
          <p key={index} className={classes.spacing}>
            {metric.name}: <strong>{metric.value}</strong>{' '}
            <span style={{color: metric.color, fontWeight: 700}}>
              {metric.arrow} {metric.change}
            </span>
          </p>
        ))}
      </Card>
    );
  }
  return null;
};

const useStyles = makeStyles({
  root: {
    background: white,
    padding: '0.5rem 1rem',
  },
  spacing: {
    margin: '5px 0',
  },
});

export default ChartTooltip;
