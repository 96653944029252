import React from 'react';
import {Avatar, Grid, Typography, TableCell, TableRow, makeStyles} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {TableZebraRow, FormattedDate, NoPerformanceData} from 'components/Widgets';

import useFeatureFlags from 'hooks/useFeatureFlags';
import {socialIcons} from 'consts/icons';
import {getPostTypeFormatted} from 'consts/campaignPostType';
import {SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE} from 'consts/variables';

import {PlanningRow} from './types';

interface Props {
  row?: PlanningRow;
  timezone?: string;
}

const PlanningTable: React.FC<Props> = ({row, timezone}) => {
  const classes = useStyles();
  const {showContentPlanningTable} = useFeatureFlags();

  if (!row) {
    return null;
  }

  const {post, creatorNumber} = row;
  const postIcon = socialIcons[post.postType] || 'ic:baseline-photo';

  const planningTable = (
    <TableZebraRow key={creatorNumber} aria-label="Campaign Planning Row">
      <TableCell component="th" scope="row" className={classes.influencerCell}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <Avatar src="#" />
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className={classes.influencerName}
              aria-label="Placeholder Name"
            >
              {'Creator ' + creatorNumber}
            </Typography>
            <Typography
              variant="body2"
              className={classes.influencerHandle}
              aria-label="Placeholder Username"
            >
              {'@creator' + creatorNumber}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <div className={classes.iconContainer}>
              <Icon icon={postIcon} width={24} className={classes.iconContainer} />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.link}>
              {getPostTypeFormatted(post.postType)}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">-</TableCell>
      <TableCell align="center">
        <FormattedDate
          date={new Date(post.deadline)}
          timezone={timezone}
          format={SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE}
        />
      </TableCell>
    </TableZebraRow>
  );

  const renderedContent = showContentPlanningTable ? (
    planningTable
  ) : (
    <TableRow>
      <TableCell colSpan={6}>
        <NoPerformanceData title="Campaign content calendar" />
      </TableCell>
    </TableRow>
  );

  return <>{renderedContent}</>;
};

const useStyles = makeStyles({
  influencerCell: {
    width: '280px',
  },
  influencerName: {
    fontWeight: 700,
  },
  influencerHandle: {
    '& svg': {
      marginRight: '2px',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& svg': {
      marginLeft: '5px',
    },
  },
});

export default PlanningTable;
