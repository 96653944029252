import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {graphql} from '@apollo/client/react/hoc';
import * as qs from 'query-string';

import {
  LinkButton,
  InfiniteScroll,
  FilterGroup,
  FilterItem,
  RequiresPermission,
  SearchBox,
} from 'components/Widgets';

import {newAdvertiserUrl} from 'consts/urls';
import {withPagination, withDebouncedProps} from 'services/graphql';
import {numberWithCommas} from 'consts/utilities';
import {ACCESS_ALL_ADVERTISERS} from 'consts/permissions';

import {ListAdvertisers} from './components';
import {AdminAdvertisersQuery} from './graphqlQuery';
import styles from './style.css';

const SearchUI = ({searchString, filter, handleSearchChanged}) => {
  const params = qs.parse(location.search);
  return (
    <div>
      <div className={styles.searchBox}>
        <RequiresPermission permission="create_brand">
          <LinkButton to={newAdvertiserUrl()} className={styles.newButton}>
            New Brand
          </LinkButton>
        </RequiresPermission>
        <SearchBox
          searchString={searchString}
          placeholder="Search brands"
          onChange={handleSearchChanged}
        />
      </div>

      <FilterGroup>
        <Link
          to={location => ({
            ...location,
            search: qs.stringify({...params, filter: 'all'}),
          })}
        >
          <FilterItem checked={filter === 'all'}>All</FilterItem>
        </Link>
        <Link
          to={location => ({
            ...location,
            search: qs.stringify({...params, filter: 'archived'}),
          })}
        >
          <FilterItem checked={filter === 'archived'}>Archived</FilterItem>
        </Link>
      </FilterGroup>
    </div>
  );
};

SearchUI.propTypes = {
  searchString: PropTypes.string,
  filter: PropTypes.string,
  handleSearchChanged: PropTypes.func.isRequired,
};

const AdminAdvertisers = ({data, loadMore, filter, replace, searchString}) => {
  return (
    <div className={styles.root}>
      <div>
        <RequiresPermission permission={ACCESS_ALL_ADVERTISERS}>
          <SearchUI
            replace={replace}
            searchString={searchString}
            filter={filter}
            handleSearchChanged={event => replace(event.target.value)}
          />
        </RequiresPermission>

        {!data.loading && data.advertisers && data.advertisers.count === 0 && searchString && (
          <p className={styles.noAdvertisers}>
            Found no advertisers when searching for{' '}
            <span className={styles.noAdvertisersString}>{searchString}</span>
          </p>
        )}
      </div>

      {!data.loading && data.advertisers && data.advertisers.count > 0 && (
        <div className={styles.advertiserCount}>
          {`${numberWithCommas(data.advertisers.count)} brand${
            data.advertisers.count > 1 ? 's' : ''
          }`}
        </div>
      )}

      {!data.loading && data.advertisers && (
        <InfiniteScroll loadMore={loadMore} hasMore={data.advertisers.pageInfo.hasNextPage}>
          <ListAdvertisers
            pending={data.loading}
            advertisers={data.advertisers.edges.map(edge => edge.node)}
          />
        </InfiniteScroll>
      )}
    </div>
  );
};

AdminAdvertisers.propTypes = {
  filter: PropTypes.string,
  searchString: PropTypes.string,
  loadMore: PropTypes.func.isRequired,
  data: PropTypes.shape({
    advertisers: PropTypes.object,
  }),
  replace: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {history, location}) => {
  const params = qs.parse(location.search);
  const filter = params.filter || 'all';

  return {
    filter,
    searchString: params.q,
    archived: filter === 'archived', // For query, not component
    replace: nextSearchString =>
      history.replace({
        ...location,
        search: qs.stringify({
          ...params,
          q: nextSearchString || undefined,
        }),
      }),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withDebouncedProps(['searchString']),
  graphql(AdminAdvertisersQuery, {
    props: withPagination(AdminAdvertisersQuery, 'advertisers'),
  })
)(AdminAdvertisers);
