import {useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import {Button, Grid, Hidden, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useRouteMatch} from 'react-router-dom';
import {Icon} from '@iconify/react';

import {UnreviewedGigsQuery} from './queries';
import {LoaderDots} from '../../../components/Widgets';
import Timeline from './components/Timeline/Timeline';
import Submission from './components/Submission/Submission';
import SubmissionReview from './components/SubmissionReview/SubmissionReview';
import {CampaignHeader} from '../components';

const UnreviewedGigs: React.FC = () => {
  const {params}: {params: {campaign: Campaign; gigId: string}} = useRouteMatch();
  const {loading, error, data} = useQuery(UnreviewedGigsQuery, {
    variables: {
      campaign: params.campaign,
    },
    fetchPolicy: 'network-only',
  });
  const [unreviewedList, setUnreviewedList] = useState<Gig[]>([]);
  const [selected, setSelected] = useState<Gig | null>();
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [submissionsLeft, setSubmissionsLeft] = useState<string>('0');

  useEffect(() => {
    const list = data?.gigs?.edges.map((item: GigEdge) => item.node);
    if (list) {
      setUnreviewedList(list);

      // we have a preselected, so lets use that
      // if (params.gigId) {
      //   const preSelected = list.find((item: Gig) => item.id === params.gigId);
      //   setSelected(preSelected ?? null);
      // } else {
      //   setSelected(list[0] ?? null);
      // }
      setSelected(list[0] ?? null);
    }
  }, [data]);

  useEffect(() => {
    const currentIndex = unreviewedList?.findIndex(item => item.id === selected?.id);
    setHasPrevious(unreviewedList?.length && currentIndex > 0);
    setHasNext(currentIndex !== -1 && currentIndex < unreviewedList?.length - 1);
    setSubmissionsLeft(
      unreviewedList?.length ? `${currentIndex + 1} / ${unreviewedList.length}` : '0'
    );
    // WIP not working yet
    // history.replace({pathname: `${location.pathname}/${selected?.id ?? ''}`});
  }, [selected]);

  const onSelectPrevious = () => {
    const currentIndex = unreviewedList?.findIndex(item => item.id === selected?.id);
    setSelected(unreviewedList[currentIndex - 1]);
  };

  const onSelectNext = () => {
    const currentIndex = unreviewedList?.findIndex(item => item.id === selected?.id);
    setSelected(unreviewedList[currentIndex + 1]);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CampaignHeader title="Unreviewed Submissions" campaignId={params.campaign} showProgress />
      </Grid>

      <Grid item xs={12} md style={{alignSelf: 'center'}}>
        <Typography variant="subtitle1">{`Submissions left: ${submissionsLeft}`}</Typography>
      </Grid>

      <Grid item xs={6} md={2}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          startIcon={<Icon icon="ic:baseline-chevron-left" />}
          disabled={!hasPrevious}
          onClick={onSelectPrevious}
        >
          <Typography variant="button">Previous</Typography>
        </Button>
      </Grid>

      <Grid item xs={6} md={2}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          endIcon={<Icon icon="ic:baseline-chevron-right" />}
          disabled={!hasNext}
          onClick={onSelectNext}
        >
          <Typography variant="button">Next</Typography>
        </Button>
      </Grid>

      {loading && (
        <Grid item xs={12}>
          <LoaderDots />
        </Grid>
      )}

      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error.message}</Alert>
        </Grid>
      )}

      {unreviewedList?.length === 0 && (
        <Grid item xs={12}>
          <Alert severity="info">No more submissions to review.</Alert>
        </Grid>
      )}

      {unreviewedList?.length > 0 && (
        <>
          <Hidden smDown>
            {selected?.history?.length > 0 && (
              <Grid item xs={12}>
                <Timeline gigId={selected?.id} historyItems={selected?.history?.reverse()} />
              </Grid>
            )}
          </Hidden>
          <Grid item xs={12} md={6}>
            {<Submission gigId={selected?.id} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <SubmissionReview gigId={selected?.id} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UnreviewedGigs;
