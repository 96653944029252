import React from 'react';
import {withStyles} from '@material-ui/styles';
import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Grid,
  Stepper as MuiStepper,
  Step as MuiStep,
  StepLabel,
  Typography,
  Button as MuiButton,
  Tooltip,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Icon} from '@iconify/react';

import {getHistoryEventLabel} from '../../../../Admin/Gig/GigHistory/helpers';
import {FormattedDate} from '../../../../../components/Widgets';

interface Props {
  gigId: string;
  historyItems: HistoryInterface[];
}

const Timeline: React.FC<Props> = ({gigId, historyItems}) => {
  return (
    <Card data-testid="unreviewed-timeline">
      <CardContent>
        <Grid container direction="row">
          <Grid item xs>
            <Stepper nonLinear activeStep={historyItems.length - 1}>
              {historyItems.map((item, index) => (
                <Step key={`gig-history-item-${index}`}>
                  <StepLabel>
                    <Typography variant="subtitle1" noWrap>
                      {getHistoryEventLabel(item)}
                    </Typography>
                    <Typography variant="caption" noWrap>
                      <FormattedDate date={item?.created} />
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <span>
                  See more <Icon icon="la:external-link-square-alt" width={14} />
                </span>
              }
              placement="right"
              arrow={true}
            >
              <Link to={`/history/${gigId}`} target="_blank">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<Icon icon="ic:outline-info" width={24} />}
                ></Button>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Card = withStyles({
  root: {
    padding: 0,
  },
})(MuiCard);

const CardContent = withStyles({
  root: {
    padding: '0 !important',
  },
})(MuiCardContent);

const Button = withStyles({
  root: {
    height: '100%',
    minWidth: '3rem',
  },
  containedPrimary: {
    padding: 0,
  },
  endIcon: {
    margin: 0,
  },
})(MuiButton);

const Stepper = withStyles({
  root: {
    overflow: 'scroll',
  },
})(MuiStepper);

const Step = withStyles({
  root: {},
})(MuiStep);

export default Timeline;
