import {useQuery} from '@apollo/client';

import {UserRoles} from '../consts/roles';
import useAdvertiserAccess from '../scenes/Advertiser/hooks/useAdvertiserAccess';
import {EditProfileQuery} from '../scenes/EditProfile/graphqlQuery';

const useAdvertiserPageAccess = (page: string) => {
  const {data: userData} = useQuery(EditProfileQuery);
  const advertiserAccess = useAdvertiserAccess();

  if (userData?.user?.roleName === UserRoles.Advertiser && advertiserAccess) {
    if (!advertiserAccess[page]) {
      return true;
    }
  }

  return false;
};

export default useAdvertiserPageAccess;
