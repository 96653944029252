import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {MaterialDesignIcon} from 'components/Icons';
import {LoaderDots, DraggableTable, ProfilePicture} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import styles from './style.css';

const columns = {
  order: {heading: '#', data: d => d.order + 1},
  influencer: {
    heading: 'Influencer',
    data: d => (
      <div className={styles.playlistRow}>
        <ProfilePicture src={d.influencer.profilePicture} size={50} />
        <div>
          <Link key={d.influencer.id} to={`/influencers/${d.influencer.id}/gigs`}>
            <div className={styles.username}>{d.influencer.fullName}</div>
          </Link>
          <div>{d.influencer.email}</div>
        </div>
      </div>
    ),
  },
  reward: {
    heading: 'Reward',
    data: d => d.reward.formattedValue,
    render: reward => reward,
  },
  followers: {
    heading: 'Followers',
    data: d => d.influencer.followers,
    render: followers => numberWithCommas(followers),
    footer: {
      reducer: (accumulator, d) => accumulator + d.influencer.followers,
      render: f => numberWithCommas(f),
    },
  },
  engagements: {
    heading: 'Estimated engagements',
    data: d => d.influencer.estimatedEngagementsPerPost,
    render: engagements => numberWithCommas(engagements),
    footer: {
      reducer: (accumulator, d) => accumulator + d.influencer.estimatedEngagementsPerPost,
      render: f => numberWithCommas(f),
    },
  },
  impressions: {
    heading: 'Estimated impressions',
    data: d => d.influencer.estimatedImpressions,
    render: impressions => numberWithCommas(impressions),
    footer: {
      reducer: (accumulator, d) => accumulator + d.influencer.estimatedImpressions,
      render: f => numberWithCommas(f),
    },
  },
  status: {
    heading: 'Status',
    data: d =>
      d.lastNotificationSent
        ? 'Notified ' + moment(d.lastNotificationSent).fromNow()
        : 'Waiting to notify',
  },
  accepted: {
    heading: 'Accepted',
    data: d => d.offer && d.offer.state === 'accepted' && <MaterialDesignIcon icon={'done'} />,
  },
};

class PlaylistTable extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    playlist: PropTypes.object.isRequired,
    rewardModel: PropTypes.oneOf(['assets', 'reach', 'engagement', 'impressions']),
    updatePlaylist: PropTypes.func.isRequired,
    setIsDragging: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
  };

  savePlaylist = influencerIds => {
    this.props
      .updatePlaylist(this.props.playlist.id, influencerIds, this.props.playlist.hash)
      .then(() => this.forceUpdate());
  };

  onItemsUpdate = itemList => {
    const influencerIds = itemList.map(d => d.influencer.id);
    this.savePlaylist(influencerIds);
  };

  render() {
    const {playlist, loading, setIsDragging, isDragging, rewardModel} = this.props;

    if (loading) {
      return <LoaderDots />;
    }

    return (
      <DraggableTable
        keySelector={row => row.influencer.id}
        updateItems={this.onItemsUpdate}
        setDraggingIndicatorText={d => d.influencer.fullName}
        isDragging={isDragging}
        columns={[columns.order, columns.influencer, columns.followers]
          .concat(rewardModel === 'engagement' && [columns.engagements])
          .concat(rewardModel === 'impressions' && [columns.impressions])
          .concat([columns.reward, columns.score])
          .concat([columns.status, columns.accepted])
          .filter(Boolean)}
        data={playlist.entries.map((e, i) => ({...e, order: i}))}
        loading={false}
        emptyMessage="Playlist is empty"
        setIsDragging={setIsDragging}
      />
    );
  }
}

export default PlaylistTable;
