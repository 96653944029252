import React, {useState, ReactNode} from 'react';
import {useQuery} from '@apollo/client';
import Button from '@material-ui/core/Button';

import SaveEmailForm from './components/SaveEmailForm';
import SavedBrandProfile from './components/SavedBrandProfile';
import {Advertiser} from '../../types';
import {useStyles} from './styles';
import {BrandUserQuery} from './graphqlQueries';
import DivHeading from '../../../../../components/Widgets/DivHeading';
import {EditProfileQuery} from '../../../../EditProfile/graphqlQuery';

interface Props {
  advertiser: Advertiser;
}

const BrandProfile: React.FC<Props> = ({advertiser}: Props) => {
  const classes = useStyles();
  const [isInputVisible, setInputVisible] = useState(false);
  const {loading, data} = useQuery(BrandUserQuery, {
    variables: {advertiserId: advertiser.id},
  });
  const brandUser = {...data?.brandProfileUserForAdvertiser};
  const {data: _userData} = useQuery(EditProfileQuery);
  const isEditDisabled = false;

  const handleClick = () => {
    setInputVisible(true);
  };

  if (loading) return null;

  if (brandUser?.email) {
    return (
      <Wrapper>
        <SavedBrandProfile
          advertiser={advertiser}
          brandUser={brandUser}
          isEditDisabled={isEditDisabled}
          resetInput={() => setInputVisible(false)}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className={classes.container}>
        {isInputVisible ? (
          <SaveEmailForm advertiser={advertiser} />
        ) : (
          <Button
            variant="outlined"
            className={classes.button}
            disabled={isEditDisabled}
            onClick={handleClick}
          >
            Invite Brand
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export default BrandProfile;

const Wrapper = ({children}: {children: ReactNode}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <DivHeading className={classes.heading} title="Brand profile" />
      {children}
    </div>
  );
};
