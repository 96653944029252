import React from 'react';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';
import {createStyles, Grid, makeStyles} from '@material-ui/core';

import {
  black,
  black70,
  purple,
  secondaryNavHeight,
  semiBold,
  sizeH6,
  transitionTimeBase,
} from '../../../consts/brand.integration';

interface Props {
  children: React.ReactNode;
  noActiveStyles?: boolean;
  to: string;
}

const NavigationLink = ({children, noActiveStyles, to}: Props) => {
  const classes = useStyles();
  return (
    <Grid item>
      <NavLink
        className={classNames([classes.link])}
        activeClassName={!noActiveStyles ? classes.active : undefined}
        to={to}
      >
        {children}
      </NavLink>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      fontSize: sizeH6,
      color: black,
      height: secondaryNavHeight,
      lineHeight: secondaryNavHeight,
      transition: `all ${transitionTimeBase}`,
      borderBottom: '3px solid transparent',
      fontWeight: semiBold,
      '&:hover': {
        color: black70,
      },
      padding: '5px 0',
    },
    active: {
      borderBottomColor: purple,
      color: black,
      fontWeight: semiBold,
    },
  })
);

export default NavigationLink;
