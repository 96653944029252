import React, {useEffect} from 'react';
import {Button, ButtonGroup, Typography, IconButton, Checkbox, FormLabel} from '@material-ui/core';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import {TextInput} from '../../../../../../components/Widgets';
import PlusCircle from '../PlusCircleIcon';
import {Prompt} from './types';
import {useStyles} from './styles';
import {CAMPAIGN_STATE_DRAFT, CAMPAIGN_STATE_NEW} from '../../constants';

const promptTypes = [
  {name: 'Text', value: 'text_input'},
  {name: 'Single Choice', value: 'single_choice'},
  {name: 'Multiple Choice', value: 'multiple_choice'},
  {name: 'Confirmation Screen', value: 'confirm'},
];

type SecurePrompt = Prompt & {
  locked: boolean;
};

interface Props {
  prompts: Prompt[];
  onSetPrompts: (prompt: any) => void;
  isBrandMatch: boolean;
  campaignState: string;
  disabled?: boolean;
}

const Prompts: React.FC<Props> = ({
  prompts,
  onSetPrompts,
  isBrandMatch,
  campaignState,
  disabled,
}) => {
  const classes = useStyles();
  const [localPrompts, setLocalPrompts] = React.useState<SecurePrompt[]>([]);

  useEffect(() => {
    const securePrompts = prompts.reduce((acc: SecurePrompt[], prompt) => {
      acc.push({
        ...prompt,
        locked: campaignState !== CAMPAIGN_STATE_NEW && campaignState !== CAMPAIGN_STATE_DRAFT,
      });
      return acc;
    }, []);

    setLocalPrompts(securePrompts);
  }, []);

  useEffect(() => {
    const sanitisedPrompts = localPrompts.map(({locked, ...rest}) => rest);
    onSetPrompts(sanitisedPrompts);
  }, [localPrompts]);

  const handleAddPrompt = () => {
    setLocalPrompts([
      ...localPrompts,
      {
        text: '',
        type: 'text_input',
        choices: [],
        brandVisible: false,
        locked: false,
      },
    ]);
  };

  const handleSetPrompt = (prompt: SecurePrompt, index: number) => {
    if (!prompt.locked) {
      setLocalPrompts(localPrompts.map((_prompt, i) => (i === index ? prompt : _prompt)));
    }
  };

  const handleRemovePrompt = (prompt: SecurePrompt) => {
    if (!prompt.locked) {
      setLocalPrompts(localPrompts.filter(_prompt => _prompt !== prompt));
    }
  };

  return (
    <div className={classes.root}>
      {localPrompts.map((prompt, idx) => (
        <div key={idx} className={classes.prompt}>
          <div>
            <ButtonGroup variant="outlined" aria-label="outlined button group" disabled={disabled}>
              {promptTypes.map(type => {
                const checked = type.value === prompt.type;
                return (
                  <Button
                    key={type.value}
                    className={checked ? classes.checked : classes.typeButton}
                    disabled={disabled || prompt.locked}
                    onClick={() => {
                      prompt.type = type.value;
                      if (prompt.type !== 'text_input') {
                        if (!prompt.choices?.length) {
                          prompt.choices = [''];
                        }
                      }
                      handleSetPrompt(prompt, idx);
                    }}
                  >
                    {type.name}
                  </Button>
                );
              })}
            </ButtonGroup>
            {prompt.type !== 'confirm' && (
              <div>
                <FormLabel id="prompt-label">
                  <Typography variant="subtitle1" component="h3" className={classes.subtitle}>
                    Prompt
                  </Typography>
                </FormLabel>
                <TextInput
                  value={prompt.text}
                  placeholder="Text"
                  inputStyle={classes.textInput}
                  inputWrapperStyle={classes.inputWrapperStyle}
                  labelledBy="prompt-label"
                  disabled={disabled || prompt.locked}
                  onChange={(val: string) => {
                    prompt.text = val;
                    handleSetPrompt(prompt, idx);
                  }}
                />
              </div>
            )}
            {isBrandMatch && (
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  color="default"
                  className={classes.checkbox}
                  checked={prompt.brandVisible}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  inputProps={{
                    'aria-labelledby': 'visible-to-brand-label',
                  }}
                  disabled={disabled}
                  onChange={({target}) => {
                    prompt.brandVisible = target.checked;
                    handleSetPrompt(prompt, idx);
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Enter') event.preventDefault();
                  }}
                />
                <FormLabel id="visible-to-brand-label" className={classes.checkboxLabel}>
                  <Typography variant="subtitle1" component="h4">
                    Answer visible to brand
                  </Typography>
                  <Typography variant="caption">
                    The prompt and answer will be visible to the brand in the "brand match" page, if
                    the campaign is set up to be a brand match campaign.
                  </Typography>
                </FormLabel>
              </div>
            )}
          </div>
          {prompt.type && (prompt.type.indexOf('choice') !== -1 || prompt.type === 'confirm') && (
            <div>
              <Typography variant="subtitle1" component="h3" className={classes.subtitle}>
                {prompt.type === 'confirm' ? 'Confirmations' : 'Choices'}
              </Typography>
              {prompt.choices?.map((choice, choiceIndex) => (
                <div key={choiceIndex} className={classes.promptChoice}>
                  <FormLabel id="choice-label" className={classes.hiddenLabel} aria-hidden>
                    Choice
                  </FormLabel>
                  <TextInput
                    key={choiceIndex}
                    value={choice}
                    placeholder="Text"
                    labelledBy="choice-label"
                    inputStyle={classes.textInput}
                    inputWrapperStyle={classes.inputWrapperStyle}
                    disabled={disabled || prompt.locked}
                    onChange={(val: string) => {
                      prompt.choices[choiceIndex] = val;
                      handleSetPrompt(prompt, idx);
                    }}
                  />
                  {prompt.choices.length > 1 && (
                    <IconButton
                      className={classes.removePromptChoiceButton}
                      aria-label="Remove prompt choice"
                      disabled={disabled || prompt.locked}
                      onClick={() => {
                        if (prompt.choices.length > 1) {
                          prompt.choices = prompt.choices.filter((choice, i) => i !== choiceIndex);
                          handleSetPrompt(prompt, idx);
                        }
                      }}
                    >
                      <HighlightOffRoundedIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <IconButton
                className={classes.addPromptChoiceButton}
                aria-label="Add prompt choice"
                disabled={disabled || prompt.locked}
                onClick={() => {
                  if (!prompt.choices?.length) prompt.choices = [''];
                  prompt.choices.push('');
                  handleSetPrompt(prompt, idx);
                }}
              >
                <PlusCircle />
              </IconButton>
            </div>
          )}
          <IconButton
            className={classes.removePromptButton}
            aria-label="Remove prompt"
            disabled={disabled || prompt.locked}
            onClick={() => handleRemovePrompt(prompt)}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </div>
      ))}
      <Button
        className={classes.addPromptBtn}
        disabled={disabled}
        onClick={() => handleAddPrompt()}
      >
        Add prompt
      </Button>
    </div>
  );
};

export default Prompts;
