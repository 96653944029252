import * as qs from 'query-string';
import {useHistory, useLocation} from 'react-router-dom';

/**
 * This hook has been built solely to handle the `campaigns` query parameter
 * when comparing/aggregating a brands campaigns performance. However the key is
 * a constant so could be passed as configuration in the future.
 */
const KEY = 'campaigns';

const mergeAndDeduplicate = (array1: string[], array2: string[]): string[] => {
  return Array.from(new Set([...array1, ...array2]));
};

const useSelectedCampaignsLocationState = () => {
  const history = useHistory();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const parsedCampaignIDs = qs.parse(location.search, {arrayFormat: 'separator'})[KEY] || [];
  const selected = Array.isArray(parsedCampaignIDs) ? parsedCampaignIDs : [parsedCampaignIDs];

  const updateURLState = (value: string) => {
    const updatedSearch = qs.stringify({
      ...qs.parse(location.search),
      [KEY]: value,
    });
    history.replace({
      ...location,
      search: updatedSearch,
    });
  };

  const add = (input: string | string[]) => {
    const inputArray = Array.isArray(input) ? input : [input];
    updateURLState(mergeAndDeduplicate(selected, inputArray).join(','));
  };

  const remove = (input: string | string[]) => {
    const inputArray = Array.isArray(input) ? input : [input];
    const updatedArray = selected.filter(item => !inputArray.includes(item));
    updateURLState(updatedArray.join(','));
  };

  return {
    add,
    remove,
    selected,
  };
};

export {useSelectedCampaignsLocationState};
