import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {IconButton, Typography} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {black} from '../../consts/brand.integration';
import useHomePage from '../../hooks/useHomePage';

interface PropsType {
  title?: string;
}

const BackButton: React.FC<PropsType> = ({title}) => {
  const history = useHistory();
  const homePage = useHomePage();
  const classes = useStyles();

  return (
    <div className={classes.advertiserNameHeaderWrapper}>
      {title && (
        <Fragment>
          <IconButton
            className={classes.backButton}
            aria-label="Back"
            onClick={() => history.push(homePage)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography component="h1" variant="h1">
            {title}
          </Typography>
        </Fragment>
      )}
    </div>
  );
};

export default BackButton;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    advertiserNameHeaderWrapper: {
      display: 'flex',
      position: 'relative',
      paddingBottom: '40px',
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    backButton: {
      color: black,
      marginRight: 12,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);
