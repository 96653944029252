import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import {Label} from '../';
import styles from './style.css';

type Props = {
  description: string;
  label: string;
  hasError: boolean;
  disabled: boolean;
  required: boolean;
  onChange: (e: unknown) => void;
};

const ListOfFilesInput = ({
  description,
  label,
  hasError,
  disabled,
  required = false,
  onChange,
}: Props): JSX.Element => {
  const [controls, setControls] = useState<Array<File | undefined>>([undefined]);

  useEffect(() => {
    onChange(controls);
  }, [onChange, controls]);

  const addControl = () => setControls([...controls, undefined]);
  const removeControl = () => setControls(controls.slice(0, controls.length - 1));

  return (
    <div className={styles.root}>
      {label && <Label label={label} />}
      {Object.keys(controls).map(i => (
        <input
          key={i}
          className={classNames(styles.input, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
          })}
          type="file"
          required={required}
          disabled={disabled}
          onChange={e => {
            setControls(
              controls.map((value, index) => {
                if (index.toString() === i) {
                  return e.target.files![0]; // eslint-disable-line
                } else {
                  return value;
                }
              })
            );
          }}
        />
      ))}
      <div className={styles.controlButtons}>
        {Object.keys(controls).length > 1 && (
          <div className={styles.removeControl} onClick={removeControl}>
            -
          </div>
        )}
        <div className={styles.addControl} onClick={addControl}>
          +
        </div>
      </div>

      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default ListOfFilesInput;
