import {gql} from '@apollo/client';

import {CampaignCard} from '../../../components/Campaign';

export const CampaignsQuery = gql`
  query AdminCampaignsQuery(
    $searchString: String
    $cursor: String
    $state: String
    $mine: Boolean
    $archived: Boolean
    $hasNda: Boolean
    $shippingRequired: Boolean
    $brandSafety: Boolean
    $brandMatch: Boolean
    $rewardModel: String
    $region: UUIDString
    $advertiserIndustriesIds: [UUIDString]
  ) {
    campaigns(
      search: $searchString
      first: 12
      after: $cursor
      state: $state
      mine: $mine
      archived: $archived
      hasNda: $hasNda
      brandSafety: $brandSafety
      brandMatch: $brandMatch
      shippingRequired: $shippingRequired
      rewardModel: $rewardModel
      regionId: $region
      advertiserIndustriesIds: $advertiserIndustriesIds
    ) {
      edges {
        node {
          id
          ...CampaignCardCampaign
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
  }
  ${CampaignCard.fragments.campaign}
`;
