import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      minWidth: 74,
      height: 40,
      padding: '12px 16px',
      color: 'white',
      backgroundColor: black,
      margin: '10px',
      fontSize: '14px',
      fontWeight: 600,
      borderRadius: 0,
      '&:hover': {
        color: 'white',
        backgroundColor: black,
      },
    },
    body: {
      fontSize: 18,
      fontWeight: 400,
      color: black,
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      margin: 15,
    },
    closeIcon: {
      right: 25,
      top: 18,
      position: 'absolute',
      cursor: 'pointer',
    },
  })
);
