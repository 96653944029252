import React from 'react';
import {Grid} from '@material-ui/core';

import {NoMetricData} from 'components/Widgets/Alerts';

import {numberWithCommas} from 'consts/utilities';

import {formattedMetrics, shouldDisplayMetric} from '../helper';
import MetricItem from '../../../../Dashboard/CampaignDetails/components/MetricItem';

interface Props {
  summaryMetrics: Array<Record<string, number>>;
  modelName: string;
  enabledMetrics: string[];
}

const PaidSummaryMetrics: React.FC<Props> = ({summaryMetrics, enabledMetrics, modelName}) => {
  const currentMetrics = summaryMetrics[0];
  const metricsToShow = Object.entries(currentMetrics).filter(([name]) =>
    shouldDisplayMetric(enabledMetrics, name)
  );
  const justifyContent =
    // eslint-disable-next-line no-nested-ternary
    metricsToShow.length === 1
      ? 'flex-start'
      : metricsToShow.length >= 4
      ? 'space-between'
      : 'space-evenly';

  return (
    <Grid item xs={12}>
      {metricsToShow.length !== 0 ? (
        <Grid container spacing={3} justifyContent={justifyContent}>
          {metricsToShow.map(([metricName, metricValue], index) => {
            const formattedData = formattedMetrics(metricName, metricValue, modelName);
            if (!formattedData) return null;

            return (
              <Grid
                key={index}
                item
                xs={6}
                sm={4}
                md={2}
                aria-label={`Paid Media - ${formattedData.metricDisplayName}`}
              >
                <MetricItem
                  title={formattedData.metricDisplayName}
                  description={formattedData.metricDescription}
                  value={numberWithCommas(metricValue)}
                  formattedValue={formattedData.formattedValue}
                  placement="bottom"
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <NoMetricData type="paid media" />
      )}
    </Grid>
  );
};

export default PaidSummaryMetrics;
