/* eslint-disable graphql/required-fields */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import {connect} from 'react-redux';
import moment from 'moment';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import UUID from 'uuid-v4';
import * as qs from 'query-string';
import {withRouter} from 'react-router-dom';

import {
  FilterGroup,
  DrawerToggleButton,
  InfiniteScroll,
  ProfilePicture,
  FormattedDate,
} from 'components/Widgets';
import {FilterWell} from 'components/Widgets/FilterWell';

import {numberWithCommas, singularOrPlural} from 'consts/utilities';
import {withPagination} from 'services/graphql';

import {AdminInfluencerEventsFilterWell, InfluencerEvent, MessageBox} from './components';
import styles from './style.css';
class Events extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    influencer: PropTypes.object,
    influencerEvents: PropTypes.object,
    loading: PropTypes.bool,
    filters: PropTypes.shape({
      state: PropTypes.oneOf(['draft', 'launched', 'completed']),
      reward_model: PropTypes.oneOf(['assets', 'reach']),
      campaign_type: PropTypes.oneOf(['standard', 'product', 'event']),
      archived: PropTypes.oneOf(['true']),
      shipping_required: PropTypes.oneOf(['true']),
    }),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
    commentOnInfluencer: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      wellOpen: false,
    };
  }

  getOptions = () => {
    const {filters} = this.props;

    return (
      <AdminInfluencerEventsFilterWell
        changeFilters={this.changeFilters}
        resetFilters={this.resetFilters}
        filters={filters}
      />
    );
  };

  handleAddComment = comment => {
    this.props.commentOnInfluencer(comment);
  };

  toggleWell = () => {
    this.setState({wellOpen: !this.state.wellOpen});
  };

  changeFilters = newFilters => {
    const {history, location, filters} = this.props;
    history.replace({
      ...location,
      search: qs.stringify({
        ...filters,
        ...newFilters,
      }),
    });
  };

  resetFilters = () => {
    const {history, location, filters} = this.props;
    history.replace({
      ...location,
      search: qs.stringify({
        ...filters,
        to: undefined,
        from: undefined,
        type: undefined,
      }),
    });
  };

  render() {
    const {user, influencerEvents, filters, loadMore} = this.props;
    const {wellOpen} = this.state;

    if (!influencerEvents || !user) {
      return <div />;
    }

    const groupedEvents = influencerEvents.edges
      .map(edge => edge.node)
      .reduce((group, event) => {
        if (event === null) {
          return group;
        }

        const key = moment(event.created).format('MM YYYY');
        (group[key] = group[key] || []).push(event);
        return group;
      }, {});

    return (
      <div className={styles.root}>
        <div className={styles.input}>
          <div className={styles.event}>
            <ProfilePicture className={styles.profilePicture} src={user.profilePicture} size={40} />
            <MessageBox action={this.handleAddComment} placeholder={'Add comment…'} />
          </div>
        </div>
        <div className={styles.filterInfo}>
          <div className={styles.eventCount}>
            <span>{numberWithCommas(influencerEvents.count)} </span>
            {singularOrPlural('event', influencerEvents.count)}
          </div>
          <div className={styles.filterGroup}>
            <FilterGroup>
              <DrawerToggleButton isOpen={wellOpen} filters={filters} onClick={this.toggleWell} />
            </FilterGroup>
          </div>
        </div>
        <FilterWell
          isOpen={wellOpen}
          filters={filters}
          changeFilters={this.changeFilters}
          resetFilters={this.resetFilters}
          options={this.getOptions()}
        >
          <InfiniteScroll loadMore={loadMore} hasMore={influencerEvents.pageInfo.hasNextPage}>
            {groupedEvents &&
              Object.keys(groupedEvents).map(date => (
                <div key={date}>
                  <div className={styles.groupTitle}>
                    <FormattedDate date={groupedEvents[date][0].created} format="Do MMM, YYYY" />
                  </div>

                  {groupedEvents[date].map(event => (
                    <InfluencerEvent key={event.created} event={event} />
                  ))}
                </div>
              ))}
          </InfiniteScroll>
        </FilterWell>
      </div>
    );
  }
}

const InfluencerEventsQuery = gql`
  query InfluencerEventsQuery(
    $id: UUIDString!
    $eventType: EventType
    $dateFrom: String
    $dateTo: String
    $cursor: String
  ) {
    influencerEvents(
      id: $id
      eventType: $eventType
      dateFrom: $dateFrom
      dateTo: $dateTo
      after: $cursor
      first: 15
    ) {
      edges {
        node {
          created
          type
          text
          user {
            id
            profilePicture
            fullName
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      count
    }
    influencer(id: $id) {
      id
    }
    user: currentUser {
      id
      fullName
      profilePicture
    }
  }
`;

const InfluencerCommentMutation = gql`
  mutation InfluencerCommentMutation($id: UUIDString!, $comment: String!) {
    commentOnInfluencer(id: $id, comment: $comment) {
      ok
      influencerEvent {
        id
        created
        type
        text
        user {
          id
          profilePicture
          fullName
        }
      }
    }
  }
`;

const mapStateToProps = (state, {location}) => {
  const params = qs.parse(location.search);
  return {
    filters: params,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  graphql(InfluencerEventsQuery, {
    options: ({match: {params}, location}) => {
      const locParams = qs.parse(location.search);
      return {
        variables: {
          eventType: locParams.type,
          dateFrom: locParams.from,
          dateTo: locParams.to,
          id: params.influencerId,
        },
      };
    },
    props: compose(
      ({data: {user, influencer, influencerEvents, loading}, loadMore}) => ({
        user,
        loadMore,
        influencer,
        influencerEvents,
        loading,
      }),
      withPagination(InfluencerEventsQuery, 'influencerEvents')
    ),
  }),
  graphql(InfluencerCommentMutation, {
    props: ({
      mutate,
      ownProps: {
        influencer,
        user,
        match: {params},
        location,
      },
    }) => ({
      commentOnInfluencer: comment =>
        mutate({
          variables: {
            comment,
            id: influencer.id,
          },
          update: (proxy, {data: {commentOnInfluencer}}) => {
            const locParams = qs.parse(location.search);
            const variables = {
              eventType: locParams.type,
              dateFrom: locParams.from,
              dateTo: locParams.to,
              id: params.influencerId,
            };

            try {
              const data = proxy.readQuery({
                query: InfluencerEventsQuery,
                variables,
              });
              data.influencerEvents.edges.unshift({node: commentOnInfluencer.influencerEvent});
              proxy.writeQuery({
                query: InfluencerEventsQuery,
                data,
                variables,
              });
            } catch (err) {
              // No events to update.
              return;
            }
          },
          optimisticResponse: {
            commentOnInfluencer: {
              ok: true,
              influencerEvent: {
                id: UUID(),
                created: new Date().toISOString(),
                type: 'comment',
                text: comment,
                user: {
                  id: user.id,
                  profilePicture: user.profilePicture,
                  fullName: user.fullName,
                  __typename: 'User',
                },
                __typename: 'InfluencerEvent',
              },
              __typename: 'CommentOnInfluencer',
            },
          },
        }),
    }),
  })
)(Events);
