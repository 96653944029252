import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {LoaderDots} from 'components/Widgets';

import styles from './style.css';

/* TODO:
  - Add 'sortable' key
  - Add 'sortKey' key
  - Implement sorting
  - Add 'default' key for nulls
*/

export const TableHeader = ({columns}) => (
  <thead>
    <tr className={styles.headingRow}>
      {columns.map((col, index) => (
        <th key={index} className={styles.headingItem}>
          {col.heading}
        </th>
      ))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func,
      footer: PropTypes.shape({
        reducer: PropTypes.func,
      }),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export const TableBody = ({
  columns,
  extraRow,
  data,
  loading,
  keySelector,
  onRowClick,
  emptyMessage,
}) => (
  <tbody>
    {data &&
      (!loading && (data.size === 0 || data.length === 0) ? (
        <tr>
          <td className={styles.emptyMessage} colSpan={200}>
            {emptyMessage}
          </td>
        </tr>
      ) : (
        data.map(d => {
          const key = keySelector ? keySelector(d) : d.id;
          return (
            <React.Fragment key={key}>
              <tr
                className={!onRowClick ? styles.row : styles.rowClickable}
                onClick={onRowClick ? () => onRowClick(d) : null}
              >
                {columns.map(col => {
                  const itemData = typeof col.data === 'function' ? col.data(d) : col.data;
                  return (
                    <td
                      key={`${key}.${col.heading}--${Math.random() * 999}`}
                      className={styles.item}
                      width={col.width}
                    >
                      {col.render ? col.render(itemData) : itemData}
                    </td>
                  );
                })}
              </tr>
              {extraRow && (
                <tr>
                  <td colSpan={columns.length}>{extraRow(d)}</td>
                </tr>
              )}
            </React.Fragment>
          );
        })
      ))}
  </tbody>
);

TableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func,
      footer: PropTypes.shape({
        reducer: PropTypes.func,
      }),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  extraRow: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
  keySelector: PropTypes.func,
  onRowClick: PropTypes.func,
};

export const TableFooter = ({columns, data}) =>
  data?.length !== 0 &&
  columns.filter(c => c.footer !== undefined && c.footer !== null).length > 0 && (
    <tfoot className={classNames([styles.row, styles.footer])}>
      <tr>
        {columns.map((col, index) => (
          <td key={`${col.heading}-${index}`} className={styles.item}>
            {col.footer?.reducer ? (
              col.footer.render ? (
                col.footer.render(data.reduce(col.footer.reducer, 0))
              ) : (
                data.reduce(col.footer.reducer, 0)
              )
            ) : (
              <div className={styles.hyphen}>—</div>
            )}
          </td>
        ))}
      </tr>
    </tfoot>
  );

TableFooter.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func,
      footer: PropTypes.shape({
        reducer: PropTypes.func,
      }),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  data: PropTypes.array,
};

const Table = ({data, columns, extraRow, loading, emptyMessage, keySelector, onRowClick}) => (
  <table className={styles.table}>
    <TableHeader columns={columns} />
    <TableBody
      columns={columns}
      extraRow={extraRow}
      data={data}
      emptyMessage={emptyMessage}
      keySelector={keySelector}
      loading={loading}
      onRowClick={onRowClick}
    />
    {loading && (
      <tbody>
        <tr>
          <td className={styles.emptyMessage} colSpan={200}>
            <LoaderDots />
          </td>
        </tr>
      </tbody>
    )}
    <TableFooter columns={columns} data={data} />
  </table>
);

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func,
      footer: PropTypes.shape({
        reducer: PropTypes.func,
      }),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  extraRow: PropTypes.func,
  emptyMessage: PropTypes.string,
  keySelector: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default Table;
