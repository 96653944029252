export const UserRoles = {
  Developer: 'developer',
  Advertiser: 'advertiser',
  CampaignManager: 'campaign_manager',
  CommunityManager: 'community_manager',
  AccountManager: 'account_manager',
  SalesDirector: 'sales_director',
  Accounting: 'accounting',
};

export const TakumiUserRoles = [
  UserRoles.CampaignManager,
  UserRoles.CommunityManager,
  UserRoles.AccountManager,
  UserRoles.SalesDirector,
  UserRoles.Accounting,
  UserRoles.Developer,
];

export const ExternalUserRoles = [UserRoles.Advertiser];
