import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button, Grid, Typography} from '@material-ui/core';

import {LoaderDots, RequiresPermission} from '../../../components/Widgets';

interface Props {
  gig: Gig;
}

const GigDismissReport: React.FC<Props> = ({gig}) => {
  const [dismissReport, {loading}] = useMutation(DismissMutation);

  if (loading) {
    return <LoaderDots />;
  }

  const onClick = () => {
    dismissReport({variables: {id: gig.id}});
  };

  return (
    <RequiresPermission permission="manage_influencers">
      <Button variant="text" fullWidth onClick={onClick}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            Dismiss report
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle2" color="secondary">
              Dismissing a report will make the gig accepted, should only be used if the report from
              a client is not valid and should be overridden
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </RequiresPermission>
  );
};

const DismissMutation = gql`
  mutation DismissMutation($id: UUIDString!) {
    dismissGigReport(id: $id) {
      ok
      gig {
        id
        state
        canBeReported
      }
    }
  }
`;

export default GigDismissReport;
