import React from 'react';
import {Grid, Typography, Divider} from '@material-ui/core';

import MetricItem from '../../Dashboard/CampaignDetails/components/MetricItem';
import {numberWithCommas, numberMillified} from '../../../consts/utilities';

type Metrics = Record<string, any>;

type MetricsCategory = Record<string, Metrics>;

interface Props {
  metrics: Metrics;
  metricsCategory: MetricsCategory;
  title: string;
  classes: Record<string, string>;
}

const AdvertiserMetrics: React.FC<Props> = ({metrics, metricsCategory, classes, title}) => {
  const allMetrics = Object.fromEntries(metrics);
  return (
    <>
      <Typography component="h4" variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3} aria-label="Brand Totals Metrics">
        {Object.entries(metricsCategory).map(([key, metricInfo]) => {
          const value = allMetrics[key];
          if (value === undefined) return null;
          return (
            <Grid key={key} item xs={6} sm={4} md={3} aria-label={metricInfo.name}>
              <MetricItem
                title={metricInfo.name}
                description={metricInfo.description}
                value={numberWithCommas(value)}
                formattedValue={numberMillified(value)}
                placement="bottom"
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AdvertiserMetrics;
