import React from 'react';
import classNames from 'classnames';

import uniqueId from '../../../services/uniqueId';
import styles from './style.css';

type Props = {
  label: string;
  id?: string;
  showFailed?: boolean;
  showSaved?: boolean;
};

const Label = ({label, showFailed, showSaved, id = uniqueId()}: Props): JSX.Element => (
  <label className={styles.label} htmlFor={id}>
    {label}
    <span
      className={classNames([styles.status, styles.statusSaved, showSaved && styles.statusVisible])}
    >
      Saved
    </span>
    <span
      className={classNames([
        styles.status,
        styles.statusFailed,
        showFailed && styles.statusVisible,
      ])}
    >
      Failed to save
    </span>
  </label>
);

export default Label;
