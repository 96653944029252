import React from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {ProgressBar} from 'components/Widgets';

import useFeatureFlags from 'hooks/useFeatureFlags';

export type CampaignState = 'draft' | 'launched' | 'completed';
interface Props {
  state: CampaignState;
  target: CampaignTarget;
}

const CampaignProgress: React.FC<Props> = ({state, target}) => {
  const {useYoutubeIntegration, useTwitchIntegration} = useFeatureFlags();

  const getDescription = (state: string) => {
    switch (state) {
      case 'draft':
        return 'Draft';
      case 'launched':
        return 'Launched';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  };

  const getIcon = (state: string) => {
    switch (state) {
      case 'draft':
        return <Icon icon="fa6-solid:pencil" />;
      case 'launched':
        return <Icon icon="ion:rocket" />;
      case 'completed':
        return <Icon icon="mdi:tick" />;
      default:
        return '';
    }
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item style={{textAlign: 'right'}}>
        <Box marginBottom=".5rem" display="inline-flex">
          <Typography variant="body1" color="secondary" style={{paddingRight: '.2rem'}}>
            {getIcon(state)}
          </Typography>
          <Typography variant="body1" color="secondary">
            {getDescription(state)}
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <ProgressBar
          title="Reserved"
          value={target.acceptedOverall ?? 0}
          total={target.targetOverall ?? 100}
        />
      </Grid>

      {state !== 'draft' && (
        <>
          <Grid item container direction="column" style={{paddingTop: '1rem'}}>
            {Number(target.targetInstagram) > 0 && (
              <Grid item>
                <ProgressBar
                  title="Delivered Instagram"
                  value={target.deliveredInstagram ?? 0}
                  total={target.targetInstagram ?? 100}
                />
              </Grid>
            )}
            {Number(target.targetTiktok) > 0 && (
              <Grid item>
                <ProgressBar
                  title="Delivered TikTok"
                  value={target.deliveredTiktok ?? 0}
                  total={target.targetTiktok ?? 100}
                />
              </Grid>
            )}
            {useYoutubeIntegration && Number(target.targetYoutube) > 0 && (
              <Grid item>
                <ProgressBar
                  title="Delivered YouTube"
                  value={target.deliveredYoutube ?? 0}
                  total={target.targetYoutube ?? 100}
                />
              </Grid>
            )}
            {useTwitchIntegration && Number(target.targetTwitch) > 0 && (
              <Grid item>
                <ProgressBar
                  title="Delivered Twitch"
                  value={target.deliveredTwitch ?? 0}
                  total={target.targetTwitch ?? 100}
                />
              </Grid>
            )}
            {Number(target.targetContent) > 0 && (
              <Grid item>
                <ProgressBar
                  title="Delivered Content"
                  value={target.deliveredContent ?? 0}
                  total={target.targetContent ?? 100}
                />
              </Grid>
            )}
            <Grid item>
              <ProgressBar
                title="Delivered Overall"
                value={target.deliveredOverall ?? 0}
                total={target.targetOverall ?? 100}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CampaignProgress;
