import React from 'react';
import {Chip as MuiChip, ChipProps, Box, Tooltip} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {makeStyles, withStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';

import {PlatformsConfig, Platforms} from 'consts/platforms';

import {black10} from '../../../consts/brand.integration';

interface Props extends ChipProps {
  platform: Platforms;
  handle: string;
  isLinked?: boolean;
}

const SocialPlatformChip = ({platform, handle, isLinked, ...rest}: Props) => {
  const classes = useStyles();
  if (PlatformsConfig[platform] == null) return null;
  const {icon, path} = PlatformsConfig[platform];

  return (
    <Link to={{pathname: path(handle)}} target="_blank" rel="noopener noreferrer">
      <Tooltip
        title="This social platform is not linked"
        placement="bottom-start"
        arrow
        disableHoverListener={isLinked}
      >
        <Box display="flex" position="relative">
          <Chip
            avatar={<Icon icon={icon} />}
            variant={isLinked ? 'default' : 'outlined'}
            color="primary"
            label={
              <Box display="flex" alignItems="center">
                {handle}
                {!isLinked && (
                  <Icon icon="bx:error" width={28} color="red" className={classes.unlinkedIcon} />
                )}
              </Box>
            }
            {...rest}
          />
        </Box>
      </Tooltip>
    </Link>
  );
};

const Chip = withStyles({
  root: {
    cursor: 'pointer',
  },
})(MuiChip);

const useStyles = makeStyles({
  unlinkedIcon: {
    borderLeft: `1px solid ${black10}`,
    marginLeft: '.3rem',
    paddingLeft: '.2rem',
    marginRight: '-.6rem',
  },
});

export default SocialPlatformChip;
