import PropTypes from 'prop-types';
import React from 'react';

import {CampaignGig} from 'components/Campaign';

const Gig = ({match: {params}, history}) => {
  const goToGig = gigId => history.push(`/influencers/${params.influencerId}/gigs/${gigId}`);
  const goBack = () => history.push(`/influencers/${params.influencerId}/gigs`);

  return <CampaignGig goBack={goBack} goToGig={goToGig} />;
};

Gig.propTypes = {
  params: PropTypes.shape({
    influencerId: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Gig;
