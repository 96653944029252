import React from 'react';

import {Definition, HideInDemoMode} from 'components/Widgets';

type Props = {
  budget?: string;
};

const BudgetDefinition: React.FC<Props> = ({budget}) => {
  if (!budget) {
    return null;
  }
  return (
    <HideInDemoMode>
      <Definition gold title="Budget" definition={budget} />
    </HideInDemoMode>
  );
};

export default BudgetDefinition;
