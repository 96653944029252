import React from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

import {numberWithCommas} from '../../../consts/utilities';
import {theme} from '../../../consts/theme';
import {CheckCircle, Clock} from '../../Icons';

interface Props {
  target: CampaignTarget;
  state: string;
}

const CampaignCardProgressBar: React.FC<Props> = ({target, state}) => {
  const classes = useStyles();

  const toPercent = (a: number, b: number) => Math.min(Math.round((a / b) * 100), 100);
  const toTitle = (a: number, b: number) =>
    `Reserved: ${numberWithCommas(a)}\nSubmitted: ${numberWithCommas(b)}`;

  return (
    <div
      className={classes.root}
      title={toTitle(target?.acceptedOverall ?? 0, target?.deliveredOverall ?? 0)}
    >
      <div className={classes.bar}>
        <div
          className={classes.reserved}
          style={{
            width: `${toPercent(target?.acceptedOverall ?? 0, target?.targetOverall ?? 100)}%`,
          }}
        />
        <div
          className={classes.submitted}
          style={{
            width: `${toPercent(target?.deliveredOverall ?? 0, target?.targetOverall ?? 100)}%`,
          }}
        />
      </div>
      {state === 'draft' && (
        <div className={classes.iconWrapper}>
          <Clock color={theme.palette['ui-05']} />
        </div>
      )}
      {state === 'completed' && (
        <div className={classes.iconWrapper}>
          <CheckCircle color={theme.palette['stats-impressions']} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      padding: '8px 0',
    },
    bar: {
      backgroundColor: theme.palette['ui-03'],
      position: 'relative',
      height: 12,
      width: '100%',
      overflow: 'hidden',
    },
    reserved: {
      backgroundColor: '#000',
      position: 'absolute',
      height: 12,
      transition: 'width 1s',
      top: 0,
      left: 0,
      overflow: 'hidden',
    },
    submitted: {
      backgroundColor: theme.palette['stats-impressions'],
      position: 'absolute',
      height: 12,
      transition: 'width 1s',
      top: 0,
      left: 0,
      overflow: 'hidden',
    },
    iconWrapper: {
      paddingLeft: 12,
      display: 'flex',
    },
  })
);

export default CampaignCardProgressBar;
