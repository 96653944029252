import React, {useState, useCallback, useEffect, FocusEvent} from 'react';
import TextField from '@material-ui/core/TextField';
// eslint-disable-next-line import/named
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {debounce} from 'lodash';
import {CSSProperties} from '@material-ui/styles';

import {inputPlaceholder} from '../../../consts/brand.integration';

type Props = {
  id: string;
  labelId: string;
  debounceDelay: number;
  value: string;
  width?: CSSProperties['width'];
  isReadOnly?: boolean;
  placeholder?: string;
  errorText?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (text: string, event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (text: string) => void;
  disabled?: boolean;
};

type StyleProps = {
  width: Props['width'];
};

export default function DebouncedTextInput({
  debounceDelay,
  value,
  id,
  labelId,
  isReadOnly,
  placeholder,
  width = 450,
  onFocus,
  onBlur,
  onChange,
  errorText,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles({width});
  const [localValue, setLocalValue] = useState<string | number | undefined | null>(value);
  const debouncedOnChange = useCallback(
    debounce((text: string) => {
      onChange?.(text);
    }, debounceDelay),
    [onChange]
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  function onChangeLocalValue(event: FocusEvent<HTMLInputElement>) {
    const text = event.target.value;
    setLocalValue(text);
    debouncedOnChange(text);
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setLocalValue(text);
    if (onBlur) {
      onBlur(text, event);
    }
  };

  return (
    <TextField
      variant="outlined"
      value={localValue}
      disabled={isReadOnly}
      placeholder={placeholder}
      className={classes.textField}
      error={!!errorText}
      helperText={errorText}
      InputProps={{
        id,
        'aria-labelledby': labelId,
        classes: {
          input: classes.inputText,
        },
        disabled: disabled ?? false,
      }}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={onChangeLocalValue}
    />
  );
}

const useStyles = makeStyles<Theme, StyleProps>(theme =>
  createStyles({
    textField: {
      width: ({width}) => width || 0,
      marginRight: theme.spacing(2),
    },
    inputText: {
      '&::placeholder': {
        color: inputPlaceholder,
        fontWeight: 400,
        opacity: 1,
      },
      fontSize: '14px',
      fontWeight: 600,
      padding: 14,
    },
  })
);
