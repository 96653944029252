import PropTypes from 'prop-types';
import React from 'react';

import {ProfilePicture} from 'components/Widgets';

import styles from './style.css';

const CampaignBox = ({campaign, reward}) => {
  return (
    <div className={styles.root}>
      <ProfilePicture
        square
        src={campaign.advertiser.profilePicture}
        size={30}
        className={styles.photo}
      />
      <div className={styles.meta}>
        <div className={styles.info}>
          <div className={styles.campaign}>{campaign.name}</div>
          <div className={styles.advertiser}>{campaign.advertiser.name}</div>
        </div>
        {reward && <div className={styles.paidAmount}>{reward.formattedValue}</div>}
      </div>
    </div>
  );
};

CampaignBox.propTypes = {
  campaign: PropTypes.object.isRequired,
  reward: PropTypes.object,
};

export default CampaignBox;
