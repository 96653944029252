import {makeVar} from '@apollo/client';

import {ACTOR_TOKEN_KEY} from './impersonation';

/**
 * This is the key used in localStorage for authenticating all API requests.
 *
 * NOTE: Whilst this is often the token returned by logging in, it could be
 * a token used for impersonating a user.
 */
const AUTH_TOKEN_KEY = 'token';

export const getStoredApiToken = () => window.localStorage.getItem(AUTH_TOKEN_KEY);

/**
 * This is a Apollo reactive variable
 * It can be used to inform other actors of when the user log in status changes
 *
 * @see https://www.apollographql.com/docs/react/local-state/reactive-variables/
 */
export const isLoggedIn = makeVar(getStoredApiToken() !== null);

export const storeApiToken = (value: string) => {
  if (value.trim().length > 0) {
    window.localStorage.setItem(AUTH_TOKEN_KEY, value);
    isLoggedIn(true);
  }
};

export const removeStoredApiToken = () => {
  window.localStorage.removeItem(ACTOR_TOKEN_KEY);

  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  isLoggedIn(false);
};
