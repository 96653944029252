import React from 'react';
import {PieChart, Pie, Cell, PieProps} from 'recharts';
import {Typography} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';

import {purple, transparentPurple} from '../../../../../consts/brand.integration';
import {Tooltip} from '../../../../../components/Widgets';
import {useStyles} from './styles';

interface Props {
  title: string;
  hint: string;
  value: string | number | null;
  valueUnderChart: string;
  chartData: PieProps['data'];
  chartAriaLabel: string;
  className?: string;
}

const ChartHighlight: React.FC<Props> = ({
  title,
  hint,
  value,
  valueUnderChart,
  chartData,
  chartAriaLabel,
  className,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.highlight, className)}>
      <div className={classes.titleWrapper}>
        <Typography variant="caption" component="span">
          {title}
        </Typography>
        <Tooltip overlay={<span>{hint}</span>} mouseEnterDelay={0.2} placement="top">
          <InfoOutlinedIcon className={classes.infoIcon} aria-label="Info" />
        </Tooltip>
      </div>
      <Typography variant="h3" component="span" className={classes.value}>
        {value}
      </Typography>
      <PieChart width={55} height={55} role="img" aria-label={chartAriaLabel}>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
        >
          <Cell fill={transparentPurple} />
          <Cell fill={purple} />
        </Pie>
      </PieChart>
      <Typography variant="body2" className={classes.underChart}>
        {valueUnderChart}
      </Typography>
    </div>
  );
};

export default ChartHighlight;
