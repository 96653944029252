import PubSub from 'pubsub-js';

class RequestsService {
  constructor() {
    this.requests = {};
  }

  start(key) {
    if (Object.keys(this.requests).length == 0) {
      PubSub.publish('requests', {
        loading: true,
      });
    }
    this.requests[key] = true;
  }

  stop(key) {
    delete this.requests[key];

    if (Object.keys(this.requests).length == 0) {
      PubSub.publish('requests', {
        loading: false,
      });
    }
  }

  // error(key) {
  //   delete this.requests[key];
  // }

  subscribe(cb) {
    return PubSub.subscribe('requests', (_, {loading}) => cb({loading}));
  }

  unsubscribe(value) {
    PubSub.unsubscribe(value);
  }
}

const service = new RequestsService();

export default service;
