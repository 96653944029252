import {darken} from '@material-ui/core';
import {coloursClientWebApp} from 'consts/brand.integration';
import React from 'react';

interface RingData {
  color: string;
  value: number;
}

interface RadialBarChartProps {
  rings: RingData[];
  size?: number;
  strokeWidth?: number;
  gap?: number;
  centerText?: string;
  fontSize?: number;
  variant?: 'light' | 'dark';
}

const RadialBarChart: React.FC<RadialBarChartProps> = ({
  rings,
  size = 200,
  strokeWidth,
  gap = 1,
  fontSize = 12,
  centerText,
  variant = 'light',
}) => {
  const center = size / 2;
  const useStrokeWidth = strokeWidth ?? size / 15;
  const maxRadius = (size - useStrokeWidth) / 2;
  const totalValue = rings.reduce((sum, ring) => sum + ring.value, 0);

  const variantColour = (colour: string) => (variant === 'light' ? darken(colour, 0.1) : colour);

  const generateRing = (ring: RingData, index: number): JSX.Element => {
    const radius = maxRadius - index * (useStrokeWidth + gap);
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${circumference * ring.value} ${circumference}`;
    const rotation = -90; // Start from the top

    return (
      <circle
        key={index}
        cx={center}
        cy={center}
        r={radius}
        fill={variant === 'dark' ? coloursClientWebApp.almostBlack : coloursClientWebApp.white}
        stroke={variantColour(ring.color)}
        strokeWidth={useStrokeWidth}
        strokeDasharray={strokeDasharray}
        transform={`rotate(${rotation} ${center} ${center})`}
      />
    );
  };

  const ringElements = rings.map((ring, index) => generateRing(ring, index));

  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={center}
        cy={center}
        r={maxRadius}
        fill={variant === 'dark' ? coloursClientWebApp.almostBlack : coloursClientWebApp.white}
      />
      {ringElements}
      {centerText && (
        <text
          x={center}
          y={center}
          fontFamily="Arial"
          fontSize={fontSize}
          fontWeight="bold"
          fill={variant === 'dark' ? coloursClientWebApp.white : coloursClientWebApp.black}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {centerText}
        </text>
      )}
    </svg>
  );
};

export default RadialBarChart;
