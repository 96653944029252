import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import throttle from 'lodash.throttle';
import ReactDOM from 'react-dom';

import {LoaderDots, Button} from '../';
import styles from './style.css';

export default class InfiniteScroll extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    useOverflowAuto: PropTypes.bool,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    noMoreText: PropTypes.string,
    alwaysShowButton: PropTypes.bool,
    skipLoading: PropTypes.bool,
    pending: PropTypes.bool, // XXX: Legacy: Don't use
    noMargin: PropTypes.bool,
  };

  state = {
    showLoadMore: false,
    loading: false,
  };

  componentDidMount() {
    const {useOverflowAuto} = this.props;
    const listenerElement = useOverflowAuto ? this.parentNode() : window;
    listenerElement.addEventListener('scroll', this.listenToScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasMore !== prevProps.hasMore) {
      this.setState({showLoadMore: true}); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    const {useOverflowAuto} = this.props;
    const listenerElement = useOverflowAuto ? this.parentNode() : window;
    listenerElement.removeEventListener('scroll', this.listenToScroll);
  }

  parentNode() {
    return ReactDOM.findDOMNode(this).parentNode; // eslint-disable-line react/no-find-dom-node
  }

  setWrapper = component => {
    this.wrapper = component;

    if (this.wrapper !== null) {
      this.setState({
        showLoadMore: this.wrapper.offsetHeight < window.innerHeight,
      });
    }
  };

  isAtBottom() {
    const {useOverflowAuto} = this.props;
    if (useOverflowAuto) {
      const parentNode = this.parentNode();
      return parentNode.scrollTop === parentNode.scrollHeight - parentNode.offsetHeight;
    }
    return window.innerHeight + window.scrollY + window.innerHeight >= this.wrapper.offsetHeight;
  }

  listenToScroll = event => {
    event.preventDefault();
    this.handleScroll();
  };

  // XXX: Delete me after fixing infinite scroll in Facebook integration
  isLoading = () => Boolean(this.state.loading || this.props.pending);

  handleScroll = throttle(async () => {
    if (this.wrapper === null) {
      return;
    }

    const {hasMore} = this.props;

    this.setState({
      showLoadMore: this.wrapper.offsetHeight < window.innerHeight,
    });

    if (!hasMore || this.isLoading()) {
      return;
    }

    if (this.isAtBottom()) {
      try {
        this.setState({loading: true});
        await this.props.loadMore();
      } finally {
        this.setState({loading: false});
      }
    }
  }, 100);

  render() {
    const {children, hasMore, loadMore, noMoreText, alwaysShowButton, skipLoading} = this.props;
    const isLoading = this.isLoading();

    const shouldStickButton = hasMore && alwaysShowButton;
    return (
      <div ref={this.setWrapper} {...(!this.props.noMargin ? {className: styles.root} : {})}>
        {children}
        {!hasMore && Boolean(noMoreText) && (
          <div className={styles.noMoreWrapper}>{noMoreText}</div>
        )}
        {((hasMore && this.state.showLoadMore && !isLoading) || shouldStickButton) && (
          <div className={styles.loadMoreWrapper}>
            <Button grayOutline text="Load more" onClick={() => hasMore && loadMore()} />
          </div>
        )}
        {!skipLoading && isLoading && (
          <div className={styles.loaderWrapper}>
            <LoaderDots />
          </div>
        )}
      </div>
    );
  }
}
