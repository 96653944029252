import React, {ReactNode, useEffect} from 'react';
import {useLazyQuery, gql, DocumentNode} from '@apollo/client';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import CampaignProfile from './CampaignProfile';
import {
  FormattedDate,
  InfiniteScroll,
  LoaderDots,
  Tooltip,
} from '../../../../../components/Widgets';
import {withHookPagination} from '../../../../../services/graphql';
import {filters} from '../constants';

interface Props {
  filter: string;
  influencerId: string;
  querySource?: DocumentNode;
}

type ColumnObj = {
  heading: string;
  data: (data: Record<string, any>) => Record<string, any>;
  render: (data: Record<string, any>) => ReactNode;
};

/* eslint-disable graphql/template-strings */
const NoQuery = gql`
  query noQuery {
    noQuery
  }
`;

const CampaignsTable: React.FC<Props> = ({querySource = NoQuery, filter, influencerId}) => {
  const classes = useStyles();
  const [fetchData, {loading, data, variables, fetchMore}] = useLazyQuery(querySource, {
    variables: {
      id: influencerId,
    },
  });

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  const loadMore = withHookPagination(
    querySource,
    'influencerCampaigns',
    data,
    variables,
    fetchMore
  );

  const columns: Record<string, ColumnObj> = {
    campaign: {
      heading: 'Campaign',
      data: data => data.campaign,
      render: campaign => <CampaignProfile campaign={campaign} />,
    },
    deadline: {
      heading: 'Deadline',
      data: data => data.campaign.posts,
      // XXX: change for multi-post
      render: posts => <FormattedDate date={posts[0].deadline} format="Do MMM" />,
    },
    accepted: {
      heading: 'Accepted',
      data: data => data.offer.accepted,
      render: date => <FormattedDate date={date} format="Do MMM" />,
    },
    payable: {
      heading: 'Payable',
      data: data => data.offer.payable,
      render: date => <FormattedDate date={date} format="Do MMM" />,
    },
    reward: {
      heading: 'Reward',
      data: data => data.reward,
      render: (reward: Currency) => <div>{reward ? reward.formattedValue : '-'}</div>,
    },
    state: {
      heading: 'Status',
      data: data => data.offer,
      render: data => (
        <div>
          <span className={classes.capitalize}>{data.state}</span>
          {data.state === 'revoked' && data.revokeEvent?.creator && (
            <span>
              {' '}
              by
              <Tooltip
                overlay={<span>{data.revokeEvent.creator.email}</span>}
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                {' '}
                {data.revokeEvent.creator.fullName}
              </Tooltip>
            </span>
          )}
        </div>
      ),
    },
    revokedRejectedDate: {
      heading: 'Revoked/Rejected date',
      data: data =>
        data.state === 'rejected' ? data.offer.rejected : data.offer.revokeEvent?.created,
      render: date => (date ? <FormattedDate date={String(date)} /> : <p>-</p>),
    },
  };

  const columnsForFilter = () => {
    switch (filter) {
      case filters.ACTIVE:
        return [columns.campaign, columns.accepted, columns.deadline, columns.reward];
      case filters.REQUESTED:
        return [columns.campaign, columns.deadline, columns.reward];
      case filters.AWAITING_RESPONSE:
        return [columns.campaign, columns.reward];
      case filters.REVOKED_OR_REJECTED:
        return [columns.campaign, columns.state, columns.revokedRejectedDate];
      case filters.HISTORY:
        return [columns.campaign, columns.accepted, columns.payable, columns.reward];
      case filters.EXPIRED:
        return [columns.campaign, columns.deadline];
      default:
        return [];
    }
  };

  return (
    <InfiniteScroll {...{loadMore}} hasMore={data?.influencerCampaigns.pageInfo?.hasNextPage}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columnsForFilter().map((column, index) => (
                <TableCell key={index}>{column.heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.influencerCampaigns.edges?.map(
              (edge: InfluencerCampaignAndOfferEdge, index: number) => (
                <TableRow key={index}>
                  {columnsForFilter().map((column, index) => (
                    <TableCell key={index}>{column.render(column.data(edge.node!))}</TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box marginTop={10}>
            <LoaderDots />
          </Box>
        )}
        {!loading && !data?.influencerCampaigns.count && (
          <Box width={1} marginTop={10}>
            <Typography align="center">No Campaigns match your query</Typography>
          </Box>
        )}
      </TableContainer>
    </InfiniteScroll>
  );
};

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
  },
});

export default CampaignsTable;
