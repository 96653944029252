/* eslint-disable react/prop-types */
import React, {PureComponent} from 'react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import {DropTarget} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

import {MaterialDesignIcon} from 'components/Icons';

import {uploadImage} from 'services/api';

import withDragDropContext from '../../../services/drag-drop-context';
import styles from './style.css';
import {useDefaultGlobalStyles} from '../../../scenes/App/App/style.integration';

const boxTarget = {
  drop(props, monitor) {
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  },
};

class CoverPhoto extends PureComponent {
  render() {
    const {canDrop, isOver, connectDropTarget, coverPhoto, removeCover, classes, disabled} =
      this.props;
    const isActive = !disabled && canDrop && isOver;
    return connectDropTarget(
      <div
        className={classNames([
          styles.cover,
          styles.marchingAnts,
          {[styles.canDrop]: canDrop},
          {[styles.marching]: isActive},
        ])}
      >
        <div className={styles.coverExplanation}>
          <MaterialDesignIcon icon="arrow_upward" className={styles.arrow} />

          <Typography variant="body2" className={classes.label} style={{marginTop: 10}}>
            Upload
          </Typography>
        </div>
        {coverPhoto && (
          <div>
            <div className={styles.uploadedCover}>
              <div className={styles.deletePhoto} onClick={() => removeCover()}>
                <MaterialDesignIcon icon="highlight_off" />
              </div>
              <img src={coverPhoto} />
            </div>
            <div className={styles.info}>
              <strong>Hero image</strong>
              Drag in place to replace
            </div>
          </div>
        )}
      </div>
    );
  }
}

const CoverPhotoDropZone = DropTarget(
  props => props.accepts,
  boxTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(withStyles(useDefaultGlobalStyles)(CoverPhoto));

class Photos extends PureComponent {
  render() {
    const {canDrop, isOver, connectDropTarget, photos, removeImage, disabled} = this.props;
    const isActive = !disabled && canDrop && isOver;
    return connectDropTarget(
      <div
        className={classNames([
          styles.photos,
          styles.marchingAnts,
          {[styles.canDrop]: !disabled && canDrop},
          {[styles.marching]: isActive},
        ])}
      >
        <div className={styles.photosList}>
          {photos.map(photo => (
            <div key={photo.uri}>
              {!disabled && (
                <div className={styles.deletePhoto} onClick={() => removeImage(photo.uri)}>
                  <MaterialDesignIcon icon="highlight_off" />
                </div>
              )}
              <img src={photo.link || photo.uri} />
            </div>
          ))}
        </div>
        <div className={styles.leftScrollIndicator} />
        <div className={styles.rightScrollIndicator} />
        <div
          className={classNames([
            styles.mainExplanation,
            {[styles.hasImages]: photos && photos.length > 0},
          ])}
        >
          <Typography variant="caption" style={{marginBottom: 10}}>
            Drag and drop JPG, PNG or GIF files here or click to browse files
          </Typography>
        </div>
      </div>
    );
  }
}
const PhotosDropZone = DropTarget(
  props => props.accepts,
  boxTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(Photos);

class ImageUploader extends PureComponent {
  state = {
    coverPhoto: '',
    photos: [],
  };

  componentDidMount() {
    const {value} = this.props;
    if (value) {
      const {secondaryPhotos, coverPhoto} = value;
      this.setState({
        photos: secondaryPhotos?.map(photo => ({link: photo.url, uri: photo.url})),
        coverPhoto: coverPhoto?.url,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      const {onChange} = this.props;
      onChange?.(this.state);
    }
  }

  handleDropAndUpload = (monitor, coverPhoto, loadingAction) => {
    const itemDropped = monitor.getItem();

    if (!itemDropped.files) return false;
    itemDropped.files.forEach(async file => {
      let uri = '';
      const previewReader = new FileReader();
      previewReader.addEventListener('load', e => {
        if (coverPhoto) this.setState({coverPhoto: e.target.result});
        else {
          uri = e.target.result;
          this.setState({photos: [...this.state.photos, {uri}]});
        }
      });
      previewReader.readAsDataURL(file);

      const link = await uploadImage(file, loadingAction);
      if (coverPhoto) this.setState({coverPhoto: link});
      else {
        this.setState({
          photos: [...this.state.photos.filter(p => p.uri !== uri), {uri, link}],
        });
      }
    });
  };

  removeImage = uri => {
    const {photos} = this.state;
    this.setState({photos: photos.filter(p => p.uri !== uri)});
  };

  removeCover = () => {
    this.setState({coverPhoto: ''});
  };

  handleCoverDrop = (item, monitor) => this.handleDropAndUpload(monitor, true);
  handleOtherDrop = (item, monitor) => this.handleDropAndUpload(monitor, false);
  render() {
    const {disabled} = this.props;
    const {coverPhoto, photos} = this.state;
    const {FILE} = NativeTypes;

    return (
      <div className={styles.root}>
        <PhotosDropZone
          accepts={[FILE]}
          photos={photos}
          removeImage={this.removeImage}
          disabled={disabled}
          onDrop={this.handleOtherDrop}
        />
        <CoverPhotoDropZone
          accepts={[FILE]}
          coverPhoto={coverPhoto}
          removeCover={this.removeCover}
          disabled={disabled}
          onDrop={this.handleCoverDrop}
        />
      </div>
    );
  }
}

export default withDragDropContext(ImageUploader);
