import React from 'react';
import classNames from 'classnames';

import RequestsService from 'services/requests';

import styles from './style.css';

class GlobalLoader extends React.PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.subscription = RequestsService.subscribe(({loading}) =>
      this.setState({
        loading,
      })
    );
  }

  componentWillUnmount() {
    RequestsService.unsubscribe(this.subscription);
  }

  render() {
    const className = classNames({
      [styles.loader]: true,
      [styles.hidden]: !this.state.loading,
    });

    return <div className={className} />;
  }
}

export default GlobalLoader;
