import axios, {AxiosPromise} from 'axios';
import tus from 'tus-js-client';

import ErrorReporting from './error-reporting';
import {getStoredApiToken, storeApiToken} from './auth';

export function getBaseURL(): string {
  const customHost = window.localStorage.getItem('API_HOST');
  return customHost ? customHost : `https://${process.env.API_HOST}`;
}

export function getEnvLocationURL(): string {
  return window.location.hostname.replace('admin.', '');
}

function tokenHeaders(): Record<string, string> {
  const token = getStoredApiToken();
  const headers: Record<string, string> = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
}

export const getStatus = async () => {
  const response = await fetch(`${getBaseURL()}/status`);
  const data = await response.json();
  if (data.error) {
    return Promise.reject(data.error);
  }
  return data;
};

export const client = axios.create({
  baseURL: getBaseURL(),
  timeout: 30 * 1000,
  headers: {
    ...tokenHeaders(),
  },
});

client.interceptors.response.use(
  response => response,
  function (error) {
    // If the server gives us an error message, use that.
    if (error.response?.data?.error) {
      return Promise.reject(error.response.data.error);
    }

    return Promise.reject(error);
  }
);

export async function uploadImage(file: File, progress?: () => void): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  const response = await uploadMedia(
    formData,
    progress ||
      (() => {
        /* do nothing */
      })
  );
  return response.data.urls[0];
}

type ProgressFunction = ({
  key,
  loaded,
  total,
}: {
  key: string;
  loaded: number;
  total: number;
}) => void;

type UploadResult = {
  data: {
    key: string;
    urls: string[];
    filetype: string;
  };
};

export async function uploadMultipleMedia(
  formData: FormData,
  keys: string[],
  progress: ProgressFunction = () => {
    /* do nothing */
  }
): Promise<UploadResult[]> {
  const promises = keys.map(
    key =>
      new Promise((resolve: (res: UploadResult) => void, reject: (err: string) => void) => {
        const file = <File>formData.get(key);
        const upload = new tus.Upload(file, {
          endpoint: `https://upload.${getEnvLocationURL()}/tus`,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          chunkSize: 6 * 1024 * 1024,
          metadata: {
            filename: file.name,
            filetype: file.type,
            token: getStoredApiToken(),
          },
          onError: reject,
          onProgress: (loaded: number, total: number) => progress({key, loaded, total}),
          onSuccess: async () => {
            const response = await fetch(upload.url);
            const json = await response.json();
            resolve({data: {key, urls: [json.url], filetype: file.type}});
          },
        });
        try {
          upload.start();
        } catch (e) {
          reject(e);
        }
      })
  );

  return await Promise.all(promises).then(result => result);
}

export async function uploadMedia(
  formData: FormData,
  progress: () => void = () => {
    /* do nothing */
  }
): Promise<UploadResult> {
  const result = await uploadMultipleMedia(formData, ['file'], progress);
  return result[0];
}

export async function downloadImage(imgUrl: string): Promise<string | undefined> {
  try {
    const response = await axios({
      method: 'GET',
      url: imgUrl,
      responseType: 'blob',
    });

    return await uploadImage(response.data);
  } catch (error) {
    ErrorReporting.captureError(error, {action: 'DownloadImage'});
    return undefined;
  }
}

export async function getAppVersion(): Promise<string> {
  const response = await axios.get('/_version');
  return response.data.version;
}

// Legacy endpoints that don't exist on graphql yet
export async function login(email: string, password: string): Promise<void> {
  const response = await client.post('/login', {email, password}, {headers: {Authorization: ''}});
  storeApiToken(response.data.token);
  return Promise.resolve();
}

export function enrollVerify(token: string): AxiosPromise<unknown> {
  return client.post('/users/enroll/verify', {token});
}

export function resetPassword(email: string): AxiosPromise<unknown> {
  return client.post('/password-recovery', {email});
}

export function changePassword(password: string, token: string): AxiosPromise<unknown> {
  return client.put('/self/password', {password, token});
}

export async function retrieveCampaignFinanceReport(
  year: number,
  month: number
): Promise<Record<string, unknown> | null> {
  const response = client.get(`/_/finance/campaign/report/${year}/${month}`, {
    responseType: 'blob',
  });
  const data = (await response).data;
  return data;
}
