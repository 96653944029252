import React from 'react';

type Props = {
  address: Address;
} & React.HTMLAttributes<HTMLElement>;

const FormattedAddress = ({address, ...rest}: Props): JSX.Element => (
  <address {...rest}>
    <strong>{address.name}</strong>
    <p>{address.address1}</p>
    <p>{address.address2}</p>
    <p>
      {address.city ? `${address.city}, ` : ''}
      {address.state ? `${address.state}, ` : ''}
      {address.postalCode}
    </p>
    <i>{address.phonenumber}</i>
  </address>
);

export default FormattedAddress;
