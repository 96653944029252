import React, {ReactElement} from 'react';
import {Button, Grid, Tooltip, TooltipProps} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {SubmissionFormType} from './SubmissionReview';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {TakumiUserRoles, UserRoles} from '../../../../../consts/roles';

interface Props {
  onSelect: (value: SubmissionFormType) => void;
}

const SelectView: React.FC<Props> = ({onSelect}) => {
  const currentUser = useCurrentUser();

  const withTooltip = (
    copy: string,
    children: ReactElement,
    placement: TooltipProps['placement'] = 'bottom'
  ) => (
    <Tooltip title={copy} placement={placement} arrow={true}>
      {children}
    </Tooltip>
  );

  const renderSelectAdvertiser = () => (
    <>
      <Grid item xs={5}>
        {withTooltip(
          'Report a problem or ask for resubmission',
          <Button
            variant="outlined"
            color="primary"
            type="button"
            size="large"
            fullWidth
            startIcon={<Icon icon="octicon:report-16" />}
            onClick={() => onSelect('report')}
          >
            Report Issue
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        {withTooltip(
          'Review and approve this submission',
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            startIcon={<Icon icon="material-symbols:check" />}
            onClick={() => onSelect('approve')}
          >
            Approve
          </Button>
        )}
      </Grid>
    </>
  );

  const renderSelectManager = () => (
    <>
      <Grid item xs={4}>
        {withTooltip(
          'Reject submission',
          <Button
            variant="outlined"
            color="primary"
            type="button"
            size="large"
            fullWidth
            startIcon={<Icon icon="material-symbols:report" />}
            onClick={() => onSelect('reject')}
          >
            Reject
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        {withTooltip(
          'Request for resubmission',
          <Button
            variant="outlined"
            color="primary"
            type="button"
            size="large"
            fullWidth
            startIcon={<Icon icon="codicon:report" />}
            onClick={() => onSelect('resubmit')}
          >
            Resubmit
          </Button>
        )}
      </Grid>
      <Grid item xs={4}>
        {withTooltip(
          'Review submission',
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            fullWidth
            startIcon={<Icon icon="material-symbols:check" />}
            onClick={() => onSelect('review')}
          >
            Review
          </Button>
        )}
      </Grid>
    </>
  );

  return (
    <Grid item container spacing={4} justifyContent="center" alignItems="center">
      {TakumiUserRoles.includes(currentUser?.roleName as string)
        ? renderSelectManager()
        : renderSelectAdvertiser()}
    </Grid>
  );
};

export default SelectView;
