import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {DatePicker} from '../../';

const DateFilter = ({
  label,
  errorMessage,
  minDate,
  selected,
  clearable,
  dateFormat,
  small,
  changeFilters,
  filterName,
  timezone,
}) => (
  <DatePicker
    selected={selected}
    minDate={minDate}
    filterName={filterName}
    isClearable={clearable}
    label={label}
    errorMessage={errorMessage}
    dateFormat={dateFormat}
    small={small}
    timezone={timezone}
    onChange={value => changeFilters({[filterName]: moment(value).format('YYYY-MM-DD')})}
  />
);

DateFilter.propTypes = {
  changeFilters: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  selected: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  small: PropTypes.bool,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  dateFormat: PropTypes.string,
  errorMessage: PropTypes.string,
  timezone: PropTypes.string,
};

export default DateFilter;
