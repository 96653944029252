import {useQuery} from '@apollo/client';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Link, useHistory} from 'react-router-dom';
import React, {useState, useEffect, Fragment, useRef} from 'react';
import {
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {cloneDeep, differenceWith, fromPairs, isEqual, keys, toPairs} from 'lodash';
import * as qs from 'query-string';

import IndustrySelector from '../../Advertiser/AdvertiserSettings/components/IndustrySelector';
import {IndustryTreeQuery} from '../../Advertiser/AdvertiserSettings/graphqlQuery';
import {CampaignsQuery} from './graphqlQuery';
import {industryFilterStyle, useStyles} from './style';
import {ICampaignFilters} from './types';
import {CampaignCard} from '../../../components/Campaign';
import {campaignUrl} from '../../../consts/urls';
import {numberWithCommas, singularOrPlural} from '../../../consts/utilities';
import {InfiniteScroll, RowWrap, SearchInput} from '../../../components/Widgets';
import {withHookPagination} from '../../../services/graphql';
import {CountriesQuery} from '../../Advertiser/AdvertiserCreate/graphqlQuery';
import FilterIcon from './components/FilterIcon';
import FiltrationParametersDialog from './components/FiltrationParametersDialog';
import AdminCampaignFilters from './components/AdminCampaignFilters';
import {NONE_OPTION} from '../../../consts/variables';
import {ALL_SUPPORTED_REGIONS} from '../../../components/Region/SelectRegion';
import {RewardModels} from './enums';
import {theme} from '../../../consts/theme';
import {placeholderIndustryStyle} from '../../Advertiser/AdvertiserSettings/components/IndustrySelector/styles';
import {getIndustryById} from '../../../utils/campaignsFilter';
import {filtersVariables} from '../../../consts/campaignsFilters';
import {CampaignFiltersChips} from '../../../components/Widgets/CampaignFiltersChips';
import useAdvertiserPageAccess from '../../../hooks/useAdvertiserPageAccess';
import App404 from '../../App404/App404';
import styles from '../../App404/style.css';
import FilterState from '../../../components/Widgets/StateFilter';

const mapRewardModelOptions: Record<string, string> = {
  [RewardModels.Assets]: 'Assets',
  [RewardModels.Engagement]: 'Engagement',
  [RewardModels.Impressions]: 'Impressions',
  [RewardModels.Reach]: 'Reach',
};

const SelectMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  },
})(MenuItem);

const AdminCampaigns: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = qs.parse(history.location.search, {
    parseBooleans: true,
  });

  const initialFilters = useRef<ICampaignFilters>(cloneDeep(filtersVariables));

  const [filters, setFilters] = useState(initialFilters.current);

  // eslint-disable-next-line prettier/prettier
  const [isFiltrationParametersDialogOpened, setIsFiltrationParametersDialogOpened] =
    useState(false);

  const {data: industriesData} = useQuery(IndustryTreeQuery);
  const countriesResult = useQuery(CountriesQuery);
  const {data, loading, fetchMore, variables, refetch} = useQuery(CampaignsQuery, {
    variables: {
      ...filters,
      mine: !filters?.mine ? undefined : filters.mine,
      archived: !filters?.archived ? undefined : filters.archived,
      shippingRequired: !filters?.shippingRequired ? undefined : filters.shippingRequired,
      brandSafety: !filters?.brandSafety ? undefined : filters.brandSafety,
      brandMatch: !filters?.brandMatch ? undefined : filters.brandMatch,
      rewardModel: filters?.rewardModel === NONE_OPTION ? undefined : filters.rewardModel,
      advertiserIndustriesIds: filters?.advertiserIndustries.map(industry => industry.id),
    },
  });

  const updateDeeplinking = () => {
    const changed = fromPairs(
      differenceWith(toPairs(filters), toPairs(initialFilters.current), isEqual)
    );
    history.replace({
      ...history.location,
      search: qs.stringify({
        ...changed,
        advertiserIndustries: [],
      }),
    });
  };

  useEffect(() => {
    if (keys(searchParams).length) {
      setFilters({...filters, ...searchParams});
    }
  }, []);

  useEffect(() => {
    updateDeeplinking();
    refetch();
  }, [filters]);

  if (useAdvertiserPageAccess('brandCampaignsPage')) {
    return (
      <div className={styles.component404}>
        <App404 />
      </div>
    );
  }

  const onIndustryChanged = (industryId: string) => {
    const industry = getIndustryById(industriesData?.industries, industryId);

    if (industry) {
      if (!filters.advertiserIndustries.includes(industry)) {
        filters.advertiserIndustries.push(industry);
      } else {
        filters.advertiserIndustries.splice(filters.advertiserIndustries.indexOf(industry), 1);
      }

      setFilters({...filters});
    }
  };

  const renderCampaigns = () => {
    const loadMore = withHookPagination(CampaignsQuery, 'campaigns', data, variables, fetchMore);

    if (loading) {
      return null;
    }

    return (
      <InfiniteScroll loadMore={loadMore} hasMore={data.campaigns.pageInfo.hasNextPage}>
        <RowWrap>
          {data.campaigns.edges
            .map((edge: any) => edge.node)
            .map((campaign: any) => (
              <Link key={campaign.id} to={campaignUrl(campaign)} aria-labelledby={campaign.id}>
                <CampaignCard campaign={campaign} />
              </Link>
            ))}
        </RowWrap>
      </InfiniteScroll>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchInput}>
        <SearchInput
          searchString={filters.searchString}
          placeholder="Search"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilters({
              ...filters,
              searchString: e.target.value === '' ? undefined : e.target.value,
            })
          }
        />
      </div>

      <div className={classes.filtersBlock}>
        <Grid className={classes.filtersBlock} container>
          <Grid item>
            <ButtonGroup className={classes.groupRoot}>
              <FilterState {...{filters, setFilters}} />
            </ButtonGroup>
          </Grid>

          <Grid item>
            <div className={classes.groupRoot}>
              <label htmlFor="campaign-type-select">
                <Select
                  value={filters.rewardModel}
                  className={classes.select}
                  style={{width: '80px'}}
                  inputProps={{id: 'campaign-type-select'}}
                  onChange={e => {
                    setFilters({...filters, rewardModel: e.target.value ?? null});
                  }}
                >
                  <SelectMenuItem value={NONE_OPTION}>Type</SelectMenuItem>
                  {Object.values(RewardModels).map(item => (
                    <SelectMenuItem key={item} value={item}>
                      {mapRewardModelOptions[item]}
                    </SelectMenuItem>
                  ))}
                </Select>
              </label>
            </div>
          </Grid>

          <Grid item>
            <div className={classes.groupRoot}>
              <label htmlFor="regions-select">
                <Select
                  value={filters.region}
                  className={classes.select}
                  style={{width: '185px'}}
                  inputProps={{id: 'regions-select'}}
                  onChange={e => {
                    setFilters({...filters, region: e.target.value ?? null});
                  }}
                >
                  <SelectMenuItem value={ALL_SUPPORTED_REGIONS}>
                    All supported regions
                  </SelectMenuItem>
                  {(countriesResult?.data?.countries ?? []).map(country => (
                    <SelectMenuItem key={country.id} value={country.id}>
                      {country.name}
                    </SelectMenuItem>
                  ))}
                </Select>
              </label>
            </div>
          </Grid>

          <Grid item>
            <div className={classes.groupRoot}>
              <IndustrySelector
                id="brand-industry-input"
                industries={industriesData?.industries}
                selectedIndustries={filters.advertiserIndustries}
                isAutoSizableMenu
                styles={{
                  control: (base: any) => ({...base, ...industryFilterStyle.button}),
                  valueContainer: (base: any) => ({...base, padding: 0}),
                  placeholder: (base: any) => ({
                    ...base,
                    ...placeholderIndustryStyle,
                    marginLeft: '4px',
                    color: '#000',
                  }),
                }}
                additionalComponents={{
                  DropdownIndicator: () => <ArrowDropDownIcon fontSize="small" />,
                }}
                placeholder="Industry"
                onChange={onIndustryChanged}
              />
            </div>
          </Grid>
        </Grid>

        <div style={{marginTop: '24px'}}>
          <IconButton
            aria-label="advanced filters"
            className={classes.filterButton}
            onClick={() => setIsFiltrationParametersDialogOpened(true)}
          >
            <FilterIcon />
          </IconButton>
        </div>
      </div>

      {data?.campaigns && (
        <Fragment>
          <CampaignFiltersChips
            filters={filters}
            setFilters={setFilters}
            industries={industriesData?.industries}
            onIndustryChanged={onIndustryChanged}
          />
          <div className={classes.campaignsFound}>
            {!loading && data.campaigns.count === 0 ? (
              <Typography variant="body2" component="h2" className={classes.message}>
                No campaigns found for selected filters
              </Typography>
            ) : (
              <Typography variant="body2" component="h2" className={classes.message}>
                {`${numberWithCommas(data.campaigns.count)} ${singularOrPlural(
                  'campaign',
                  data.campaigns.count
                )} found`}
              </Typography>
            )}
          </div>
          {renderCampaigns()}
        </Fragment>
      )}

      <FiltrationParametersDialog
        isOpened={isFiltrationParametersDialogOpened}
        onClose={() => setIsFiltrationParametersDialogOpened(false)}
      >
        <AdminCampaignFilters
          changeFilters={(newFilters: ICampaignFilters) => setFilters({...filters, ...newFilters})}
          resetFilters={() => setFilters({...filtersVariables})}
          currentFilters={{...filters}}
          initialFilters={filtersVariables}
        />
      </FiltrationParametersDialog>
    </div>
  );
};

export default AdminCampaigns;
