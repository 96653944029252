import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import styles from './styles.css';

const weekdayAbbrevs = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const MAX_DATE_DIFF = 65;

const daysSpannedByDates = (a, b) => {
  const startDate = a < b ? moment(a).startOf('day') : moment(b).startOf('day');
  const endDate = a < b ? moment(b).startOf('day') : moment(a).startOf('day');
  const days = [];
  const step = Math.ceil(endDate.diff(startDate, 'days') / MAX_DATE_DIFF);
  while (!startDate.endOf('day').isAfter(endDate)) {
    days.push(startDate.startOf('day').clone());
    startDate.add(step, 'day');
  }
  days.push(startDate.startOf('day').clone());
  return days;
};

const momentizePeriods = periods =>
  periods.map(period => ({
    ...period,
    start: moment(period.start),
    end: moment(period.end),
  }));

const ScheduleVisualizer = ({periods: rawPeriods}) => {
  const periods = momentizePeriods(rawPeriods.filter(x => x.start !== x.end));
  const days = daysSpannedByDates(periods[0].start, periods[periods.length - 1].end);

  // UNIX timestamp territory
  const scheduleStart = days[0].valueOf();
  const scheduleEnd = days[days.length - 1].clone().endOf('day').valueOf();

  const range = scheduleEnd - scheduleStart;

  // Fractional territory
  const renderData = periods.map(({start, end, title}) => ({
    paddingLeftWidth: (start.valueOf() - scheduleStart) / range,
    width: (end.valueOf() - start.valueOf()) / range,
    paddingRightWidth: (scheduleEnd - end.valueOf()) / range,
    title,
    start,
    end,
  }));

  // Calculate position of now marker
  const nowDate = moment().valueOf();
  const now =
    nowDate >= scheduleStart && nowDate <= scheduleEnd ? (nowDate - scheduleStart) / range : 1;
  const antiNow = 1 - now;
  const campaignStarted = nowDate >= scheduleStart;

  return (
    <div className={styles.root}>
      <div className={styles.calendar}>
        {days.map(d => (
          <div
            key={d.toISOString()}
            className={classNames([styles.day, {[styles.weekend]: [0, 6].includes(d.weekday())}])}
          >
            {weekdayAbbrevs[d.weekday()]}
            <br />
            {d.date()}
          </div>
        ))}
      </div>
      <div className={styles.periods}>
        {renderData.map(period => (
          <div key={period.title} className={styles.period}>
            <div style={{flex: period.paddingLeftWidth}} />
            <div style={{flex: period.width}} className={styles.fragment}>
              <div
                className={styles.fragmentInnards}
                title={`${period.start.format('LLLL')} to ${period.end.format('LLLL')}`}
              >
                {period.title}
              </div>
            </div>
            <div style={{flex: period.paddingRightWidth}} />
          </div>
        ))}
      </div>

      {campaignStarted && (
        <div className={styles.nowContainer}>
          <div style={{flex: now}} className={styles.nowProportion}>
            <div className={styles.now} />
          </div>
          <div style={{flex: antiNow}} />
        </div>
      )}
    </div>
  );
};

ScheduleVisualizer.propTypes = {
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
};

export default ScheduleVisualizer;
