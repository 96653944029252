import styles from './Divider.css';

class Divider {
  static get contentless() {
    return true;
  }

  constructor({data, api}) {
    this.api = api;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: styles['ce-delimiter'],
    };

    this._data = {};
    this._element = this.drawView();

    this.data = data;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    let div = document.createElement('DIV');
    const logo = this.createElementFromHTML(
      '<svg width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 56.3 (81716) - https://sketch.com --><title>SVG Layer</title><desc>Created with Sketch.</desc><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.19094122"><g id="Artboard-Copy-2" transform="translate(-179.000000, -882.000000)" fill-rule="nonzero"><g id="SVG-Layer" transform="translate(179.000000, 882.000000)"><path d="M12.5001163,18.0829633 L12.5001163,18.0829633 L12.4998837,18.0829633 L3.22656432,12.104751 L0,14.1846386 L0,16.6753773 L6.25005815,20.7047935 L10.2153989,23.2609559 C11.616655,24.1644375 13.383345,24.1644375 14.7848337,23.2609559 L18.7499418,20.7047935 L25,16.6753773 L25,14.1846386 L21.7734357,12.104751 L12.5001163,18.0829633 Z" id="Fill-1" fill="#A7A9AC" opacity="0.701032366"></path><path d="M18.7499418,3.48701563 L14.7846011,0.930853238 C13.383345,0.0273715273 11.616655,0.0273715273 10.2151663,0.930853238 L6.25005815,3.48701563 L0,7.51643177 L0,10.0071705 L0,14.1671899 L3.22656432,12.0870581 L12.4998837,6.10884583 L10.1239823,9.03069738 C8.69178879,10.7915225 8.69178879,13.3833262 10.1237497,15.143663 L12.4998837,18.0655145 L14.8760177,15.143663 C16.3082113,13.3833262 16.3082113,10.7915225 14.8760177,9.03069738 L12.5001163,6.10884583 L21.7734357,12.0870581 L25,14.1671899 L25,10.0071705 L25,7.51643177 L18.7499418,3.48701563 Z" id="Fill-3" fill="#787878"></path></g></g></g></svg>'
    );

    div.appendChild(logo);
    div.classList.add(this._CSS.wrapper, this._CSS.block);

    return div;
  }

  /**
   * Return Tool's view
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    return this._element;
  }

  /**
   * Extract Tool's data from the view
   * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
   * @returns {DelimiterData} - saved data
   * @public
   */
  save() {
    return {};
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: `<svg width="19" height="4" viewBox="0 0 19 4" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 0H7a1.25 1.25 0 1 1 0 2.5H1.25a1.25 1.25 0 1 1 0-2.5zM11 0h5.75a1.25 1.25 0 0 1 0 2.5H11A1.25 1.25 0 0 1 11 0z"/></svg>`,
      title: 'Divider',
    };
  }
}

export default Divider;
