import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {LeftChevron, RightChevron, CloseBold} from 'components/Icons';

import {ESC_KEY_CODE, LEFT_KEY_CODE, RIGHT_KEY_CODE} from 'consts/variables';

import styles from './style.css';

export default class Lightbox extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any,
    closeStyle: PropTypes.string,
    arrows: PropTypes.bool,
    rightArrow: PropTypes.bool,
    leftArrow: PropTypes.bool,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    bigClose: PropTypes.bool,
    selectedFilters: PropTypes.bool,
    noPadding: PropTypes.bool,
  };

  componentDidMount() {
    if (this.props.arrows) {
      document.addEventListener('keydown', this.handleKeydown);
    }
  }

  componentWillUnmount() {
    if (this.props.arrows) {
      document.removeEventListener('keydown', this.handleKeydown);
    }
  }

  handleKeydown = event => {
    if (event.keyCode === ESC_KEY_CODE) {
      this.props.onClose();
    } else if (event.keyCode === LEFT_KEY_CODE) {
      this.props.onPrev();
    } else if (event.keyCode === RIGHT_KEY_CODE) {
      this.props.onNext();
    }
  };

  handleClose = event => {
    const className = event.target.className;
    if (typeof className === 'string' && className.indexOf(styles.root) > -1) {
      this.props.onClose(this.props.selectedFilters);
    }
  };

  render() {
    const {leftArrow, rightArrow, arrows, bigClose, noPadding} = this.props;
    const style = {
      top: `${document.getElementsByTagName('body')[0].scrollTop}px`,
      padding: noPadding ? '0' : '20px',
    };
    const className = classNames({
      [styles.root]: true,
      [styles.isOpen]: this.props.isOpen,
    });

    const leftStyle = classNames({
      [styles.disabled]: !leftArrow,
      [styles.leftArrow]: true,
    });

    const rightStyle = classNames({
      [styles.disabled]: !rightArrow,
      [styles.rightArrow]: true,
    });

    return (
      <div className={className} onClick={this.handleClose}>
        {bigClose && (
          <div className={classNames(this.props.closeStyle)} onClick={this.props.onClose}>
            <CloseBold />
          </div>
        )}
        <div className={styles.box} style={style}>
          {arrows && (
            <div className={leftStyle} onClick={this.props.onPrev}>
              <LeftChevron />
            </div>
          )}
          {!bigClose && (
            <div
              className={classNames(styles.close, this.props.closeStyle)}
              onClick={this.props.onClose}
            >
              Close
            </div>
          )}
          {this.props.children}
          {arrows && (
            <div className={rightStyle} onClick={this.props.onNext}>
              <RightChevron />
            </div>
          )}
        </div>
      </div>
    );
  }
}
