import React from 'react';

export const AlertTriangleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M13.72 5.14889L2.42669 24.0022C2.19385 24.4055 2.07065 24.8626 2.06935 25.3283C2.06804 25.7939 2.18868 26.2517 2.41926 26.6563C2.64984 27.0608 2.98233 27.3979 3.38364 27.634C3.78495 27.8702 4.24109 27.9971 4.70669 28.0022H27.2934C27.759 27.9971 28.2151 27.8702 28.6164 27.634C29.0177 27.3979 29.3502 27.0608 29.5808 26.6563C29.8114 26.2517 29.932 25.7939 29.9307 25.3283C29.9294 24.8626 29.8062 24.4055 29.5734 24.0022L18.28 5.14889C18.0423 4.75703 17.7077 4.43305 17.3083 4.2082C16.9089 3.98336 16.4583 3.86523 16 3.86523C15.5417 3.86523 15.0911 3.98336 14.6918 4.2082C14.2924 4.43305 13.9577 4.75703 13.72 5.14889V5.14889Z"
        stroke="black"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12V17.3333"
        stroke="black"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22.666H16.0133"
        stroke="black"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CheckCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.3332 14.7764V16.0031C29.3315 18.8783 28.4005 21.676 26.6789 23.9788C24.9574 26.2817 22.5375 27.9664 19.7803 28.7816C17.0231 29.5968 14.0762 29.4989 11.3791 28.5025C8.68208 27.5061 6.37938 25.6645 4.81445 23.2525C3.24953 20.8405 2.50623 17.9872 2.69541 15.1182C2.88459 12.2492 3.99611 9.51818 5.86421 7.3325C7.73231 5.14683 10.2569 3.62358 13.0614 2.98994C15.866 2.35629 18.8002 2.64619 21.4265 3.81641"
      stroke="black"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3333 5.33398L16 18.6807L12 14.6807"
      stroke="black"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AlertCircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9993 29.3346C23.3631 29.3346 29.3327 23.3651 29.3327 16.0013C29.3327 8.63751 23.3631 2.66797 15.9993 2.66797C8.63555 2.66797 2.66602 8.63751 2.66602 16.0013C2.66602 23.3651 8.63555 29.3346 15.9993 29.3346Z"
      stroke="black"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 10.668V16.0013"
      stroke="black"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 21.332H16.0133"
      stroke="black"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
