import {makeStyles} from '@material-ui/styles';

import {theme} from '../../../../../consts/theme';

export const useStyles = makeStyles({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  paginationButton: {
    border: `1px solid ${theme.palette['ui-07']}`,
    borderRadius: 0,
    color: theme.palette['ui-07'],
    height: 32,
    minWidth: 32,
    margin: '8px 8px 8px 0',
    '&:hover': {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  },
  selected: {
    backgroundColor: `${theme.palette['stats-general']}20`,
  },
  ellipsis: {
    width: 32,
    color: theme.palette['ui-05'],
  },
});
