import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import {useMutation} from '@apollo/client';

import SelectView from './SelectView';
import ReviewOrApproveView from './ReviewOrApproveView';
import ResubmitView from './ResubmitView';
import ReportView from './ReportView';
import RejectView from './RejectView';
import {
  ApproveGigMutation,
  RejectGigMutation,
  ReportGigMutation,
  RequestGigResubmissionMutation,
  ReviewGigMutation,
} from '../../queries';

interface Props {
  gigId: string;
}

export type SubmissionFormType = 'select' | 'approve' | 'review' | 'report' | 'reject' | 'resubmit';

const SubmissionReview: React.FC<Props> = ({gigId}) => {
  const [formType, setFormType] = useState<SubmissionFormType>('select');
  const [reviewGig] = useMutation(ReviewGigMutation, {refetchQueries: ['UnreviewedGigsQuery']});
  const [approveGig] = useMutation(ApproveGigMutation, {refetchQueries: ['UnreviewedGigsQuery']});
  const [reportGig] = useMutation(ReportGigMutation, {refetchQueries: ['UnreviewedGigsQuery']});
  const [rejectGig] = useMutation(RejectGigMutation, {refetchQueries: ['UnreviewedGigsQuery']});
  const [requestGigResubmission] = useMutation(RequestGigResubmissionMutation, {
    refetchQueries: ['UnreviewedGigsQuery'],
  });

  useEffect(() => {
    setFormType('select');
  }, [gigId]);

  const onCancel = () => {
    setFormType('select');
  };

  const handleReview = async (values: {feedback?: string}) => {
    await reviewGig({
      variables: {
        gigId,
        feedback: values.feedback,
      },
    });
  };

  const handleApprove = async (values: {feedback?: string}) => {
    await approveGig({
      variables: {
        gigId,
        feedback: values.feedback,
      },
    });
  };

  const handleReport = async (values: {reason: string}) => {
    await reportGig({
      variables: {
        gigId,
        reason: values.reason,
      },
    });
  };

  const handleReject = async (values: {reason: string}) => {
    await rejectGig({
      variables: {
        gigId,
        reason: values.reason,
      },
    });
  };

  const handleResubmit = async (values: {reason: string; explanation?: string}) => {
    await requestGigResubmission({
      variables: {
        gigId,
        reason: values.reason,
        explanation: values.explanation,
      },
    });
  };

  return (
    <Card data-testid="unreviewed-review">
      <CardHeader title={<Typography variant="h6">Review</Typography>} data-testid="" />
      <CardContent>
        {formType === 'select' && <SelectView onSelect={setFormType} />}
        {(formType === 'approve' || formType === 'review') && (
          <ReviewOrApproveView
            onCancel={onCancel}
            onReview={handleReview}
            onApprove={handleApprove}
          />
        )}
        {formType === 'report' && <ReportView onCancel={onCancel} onSubmit={handleReport} />}
        {formType === 'reject' && <RejectView onCancel={onCancel} onSubmit={handleReject} />}
        {formType === 'resubmit' && <ResubmitView onCancel={onCancel} onSubmit={handleResubmit} />}
      </CardContent>
    </Card>
  );
};

export default SubmissionReview;
