import {gql} from '@apollo/client';

export const influencerInfo = gql`
  fragment InfluencerInfoFields on InfluencerSearchResult {
    __typename
    id
    fullName
    profilePicture
    state
    isSignedUp
    birthday
    gender
    targetRegion {
      id
      name
      country
      countryCode
    }
    instagramAccount {
      id
      followers
      username
    }
    tiktokAccount {
      id
      followers
      username
    }
    youtubeChannel {
      id
      subscriberCount
    }
    twitchChannel {
      id
      followers
    }
  }
`;

export const InfluencerSearchQuery = gql`
  query InfluencerSearch(
    $regionId: UUIDString
    $searchString: String
    $gender: String
    $hasFacebookPage: Boolean
    $hasTiktokAccount: Boolean
    $hasYoutubeChannel: Boolean
    $hasTwitchChannel: Boolean
    $minFollowers: Int
    $maxFollowers: Int
    $minEngagement: Float
    $maxEngagement: Float
    $minAge: Int
    $maxAge: Int
    $minParticipatingCampaignCount: Int
    $maxParticipatingCampaignCount: Int
    $interestIds: [UUIDString]
    $hasInterests: Boolean
    $sortBy: InfluencerSortType
    $sortOrder: SortOrder
    $information: InformationParams
    $state: [String]
    $eligible: Boolean
    $cursor: String
  ) {
    influencerSearch(
      regionId: $regionId
      search: $searchString
      gender: $gender
      hasFacebookPage: $hasFacebookPage
      hasTiktokAccount: $hasTiktokAccount
      hasYoutubeChannel: $hasYoutubeChannel
      hasTwitchChannel: $hasTwitchChannel
      minFollowers: $minFollowers
      maxFollowers: $maxFollowers
      minEngagement: $minEngagement
      maxEngagement: $maxEngagement
      minAge: $minAge
      maxAge: $maxAge
      minParticipatingCampaignCount: $minParticipatingCampaignCount
      maxParticipatingCampaignCount: $maxParticipatingCampaignCount
      interestIds: $interestIds
      hasInterests: $hasInterests
      sortBy: $sortBy
      sortOrder: $sortOrder
      information: $information
      state: $state
      eligible: $eligible
      first: 24
      after: $cursor
    ) {
      edges {
        node {
          id
          ...InfluencerInfoFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
  }
  ${influencerInfo}
`;

export const RegionQuery = gql`
  query RegionQuery($includeUnsupported: Boolean, $market: String) {
    countries(includeUnsupported: $includeUnsupported, market: $market) {
      id
      name
      supported
    }
  }
`;
