export function advertiserUrl(advertiser) {
  if (!advertiser) {
    throw new Error('no advertiser');
  }
  return `/brands/${advertiser.domain}`;
}

export function advertiserUrlFromParams(params) {
  if (!params.advertiser) {
    throw new Error('no advertiser');
  }

  return `/brands/${params.advertiser}`;
}

export function newAdvertiserUrl() {
  return '/brands/new';
}

export function campaignUrl(campaign) {
  if (!campaign) {
    throw new Error('no campaign');
  }

  if (!campaign.id) {
    throw new Error('Campaign id missing');
  }

  return `/brands/${campaign.advertiserDomain || campaign.advertiser.domain}/${campaign.id}`;
}

export function campaignUrlFromDashboard(campaign) {
  if (!campaign) {
    throw new Error('no campaign');
  }

  if (!campaign.id) {
    throw new Error('Campaign id missing');
  }

  return `/dashboard/campaigns/${campaign.id}`;
}

export function campaignUrlFromParams(params) {
  if (!params.advertiser) {
    throw new Error('no advertiser');
  }
  if (!params.campaign) {
    throw new Error('no influencer');
  }
  return `/brands/${params.advertiser}/${params.campaign}`;
}

export function newCampaignUrl(advertiser) {
  if (!advertiser) {
    throw new Error('no advertiser');
  }
  return `/brands/${advertiser.domain}/campaigns/new`;
}

export function setupCampaignUrl(advertiser) {
  if (!advertiser) {
    throw new Error('no advertiser');
  }
  return `/brands/${advertiser.domain}/campaigns/setup`;
}

export function influencerGigUrl(id, gigId) {
  return `/influencers/${id}/gigs/${gigId}`;
}

export function pickStoryFrameUrl(gigId) {
  if (!gigId) {
    throw new Error('no gig');
  }
  return `/story/${gigId}`;
}

export const TRACKING_SERVICE = 'https://parcelsapp.com/en/tracking/';
