import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Divider, Grid, TableCell, Typography, makeStyles} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {
  TableZebraRow,
  Tooltip,
  FormattedDate,
  MediaPreview,
  MediaContainer,
  SortableTable,
} from 'components/Widgets';

import {numberWithCommas, numberMillified, formatPercentage} from 'consts/utilities';
import {socialIcons} from 'consts/icons';
import {isDeleted, latestMedia, hasMedia} from 'consts/gigHelper';
import {CALENDAR_DATE_FORMAT} from 'consts/variables';
import type {CampaignPaidAdsPerformanceResponse} from 'hooks/useDataServiceApi/types';

import {shouldDisplayMetric} from '../helper';

interface Props {
  metrics: Array<CampaignPaidAdsPerformanceResponse[number] & Gig>;
  enabledMetrics?: string[];
}

const PaidAdvertTable: React.FC<Props> = ({metrics, enabledMetrics = []}) => {
  const classes = useStyles();

  const headCells = [
    {id: 'gig_id', numeric: false, label: 'Advert'},
    {
      id: 'engagement_cumulative',
      numeric: true,
      label: 'Engagement',
      requiredMetric: 'engagement_cumulative',
    },
    {
      id: 'impression_cumulative',
      numeric: true,
      label: 'Impressions',
      requiredMetric: 'impression_cumulative',
    },
    {
      id: 'video_play_cumulative',
      numeric: true,
      label: 'Views',
      /**
       * NOTE: We use `video_view_3_second_cumulative` or `video_view_2_second_cumulative` (for Meta
       * & TikTok views respectively) - however we originally used `video_play_cumulative_total`
       * hence that being the setting that must be enabled.
       */
      requiredMetric: 'video_play_cumulative_total',
    },
    {
      id: 'click_through_rate_lifetime',
      numeric: true,
      label: 'CTR%',
      requiredMetric: 'click_through_rate_average',
    },
    {
      id: 'engagement_rate_by_impressions',
      numeric: true,
      label: 'ER%',
      requiredMetric: 'engagement_total',
    },
    {
      id: 'view_through_rate_lifetime',
      numeric: true,
      label: 'VTR%',
      requiredMetric: 'view_through_rate_average',
    },
  ].filter(
    /**
     * When defining the possible values for the paid metric settings, I used
     * the names of the fields in `PaidSummaryMetrics` and `PaidPlatformData`.
     *
     * These are not quite the same as the fields used here, so we therefore
     * have to manually define the name of the required metric and filter on that.
     *
     */
    ({requiredMetric}) => !requiredMetric || shouldDisplayMetric(enabledMetrics, requiredMetric)
  );

  const platform = (value: string): string => {
    switch (value) {
      case 'instagram':
        return 'Instagram';
      case 'tiktok':
        return 'TikTok';
      default:
        return value;
    }
  };

  return (
    <>
      <Grid item xs={12} aria-label="Advert Performance Metrics">
        <Card>
          <SortableTable<Props['metrics'][number]>
            rows={metrics}
            headCells={headCells}
            sortMetric="engagement_cumulative"
            ariaLabel="Advert Performance Table"
            renderRow={(row, index: number) => {
              return (
                <TableZebraRow key={index} tabIndex={-1}>
                  <TableCell component="th" scope="row" className={classes.link}>
                    <Link to={`/gigs/${row.gig_id}`}>
                      <Grid container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item>
                          {hasMedia(row) && (
                            <MediaContainer height={100} width={100}>
                              <MediaPreview
                                media={latestMedia(row)}
                                height={100}
                                width={100}
                                isDeleted={isDeleted(row)}
                                arePillsShown={false}
                              />
                            </MediaContainer>
                          )}
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            <strong>{row.influencer?.fullName}</strong>
                          </Typography>
                          <Typography variant="body2">
                            <i>@{row.influencer?.username}</i>
                          </Typography>
                          <Divider className={classes.divider} />
                          <Typography variant="caption" className={classes.platform}>
                            <Icon
                              icon={socialIcons[row.social_media_platform] || 'ic:baseline-photo'}
                              width={16}
                            />{' '}
                            {platform(row.social_media_platform)}
                          </Typography>
                          <Typography variant="caption">
                            <FormattedDate date={row.snapshot_date} format={CALENDAR_DATE_FORMAT} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </TableCell>

                  {shouldDisplayMetric(enabledMetrics, 'engagement_total') && (
                    <TableCell align="center">
                      <Tooltip
                        overlay={numberWithCommas(row.engagement_cumulative)}
                        placement="left"
                      >
                        <Typography variant="body1" component="span">
                          {numberMillified(row.engagement_cumulative)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}

                  {shouldDisplayMetric(enabledMetrics, 'impression_total') && (
                    <TableCell align="center">
                      <Tooltip
                        overlay={numberWithCommas(row.impression_cumulative)}
                        placement="left"
                      >
                        <Typography variant="body1" component="span">
                          {numberMillified(row.impression_cumulative)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}

                  {shouldDisplayMetric(enabledMetrics, 'video_play_cumulative_total') &&
                    row.social_media_platform === 'tiktok' && (
                      <TableCell align="center">
                        <Tooltip
                          overlay={numberWithCommas(row.video_view_2_second_cumulative)}
                          placement="left"
                        >
                          <Typography variant="body1" component="span">
                            {numberMillified(row.video_view_2_second_cumulative)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    )}

                  {shouldDisplayMetric(enabledMetrics, 'video_play_cumulative_total') &&
                    row.social_media_platform === 'instagram' && (
                      <TableCell align="center">
                        <Tooltip
                          overlay={numberWithCommas(row.video_view_3_second_cumulative)}
                          placement="left"
                        >
                          <Typography variant="body1" component="span">
                            {numberMillified(row.video_view_3_second_cumulative)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    )}

                  {shouldDisplayMetric(enabledMetrics, 'click_through_rate_average') && (
                    <TableCell align="center">
                      <Typography variant="body1" component="span">
                        {formatPercentage(row.click_through_rate_lifetime)}
                      </Typography>
                    </TableCell>
                  )}

                  {shouldDisplayMetric(enabledMetrics, 'engagement_total') && (
                    <TableCell align="center">
                      <Typography variant="body1" component="span">
                        {formatPercentage(row.engagement_rate_by_impressions)}
                      </Typography>
                    </TableCell>
                  )}

                  {shouldDisplayMetric(enabledMetrics, 'view_through_rate_average') && (
                    <TableCell align="center">
                      <Typography variant="body1" component="span">
                        {formatPercentage(row.view_through_rate_lifetime)}
                      </Typography>
                    </TableCell>
                  )}
                </TableZebraRow>
              );
            }}
          />
        </Card>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
  },
  divider: {
    margin: '5px auto',
  },
  platform: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default PaidAdvertTable;
