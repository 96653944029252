import * as React from 'react';
import {Button, Dialog, DialogContent, DialogActions} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

import {useStyles} from './styles';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  title: string;
  body: React.ReactNode | string;
  buttonName: string;
}

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  '& .MuiDialogContent-root': {
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

export const ControlDialog = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      <BootstrapDialog
        aria-label="customized-dialog-title"
        open={props.open}
        onClose={props.handleClose}
      >
        <Typography gutterBottom variant="h3" className={classes.title}>
          {props.title}
          <IconButton
            aria-label="close"
            size="small"
            className={classes.closeIcon}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <DialogContent>
          {typeof props.body === 'string' ? (
            <Typography gutterBottom className={classes.body}>
              {props.body}
            </Typography>
          ) : (
            props.body
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className={classes.submit}
            aria-label="submit"
            onClick={props.handleSubmit}
          >
            {props.buttonName}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
