import PropTypes from 'prop-types';
import React from 'react';

import {Logo} from '../';
import styles from './style.css';

const LogoWithText = ({className, header, subtitle}) => (
  <div className={className}>
    <Logo className={styles.logo} size={68} desaturated />
    <div className={styles.header}>{header}</div>
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
  </div>
);

LogoWithText.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  subtitle: PropTypes.object,
};

export default LogoWithText;
