import React, {useRef} from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import * as yup from 'yup';
import {useFormik} from 'formik';
import type {MutationFunction} from '@apollo/client';

import {Modal} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';

import {black, white} from 'consts/brand';

interface Props {
  title: string;
  mutation: MutationFunction;
  onSubmit: (mutation: Props['mutation'], socialUrl: string, closeModal: () => void) => void;
  loading: boolean;
  error?: string;
}

const AddSocialAccountModal: React.FC<Props> = ({title, mutation, onSubmit, loading, error}) => {
  const modal = useRef<ModalElement>(null);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      socialUrl: '',
    },
    validationSchema: yup.object({
      socialUrl: yup.string().required(),
    }),
    onSubmit: ({socialUrl}, {resetForm}) => {
      const closeModal = () => {
        modal.current?.close();
        resetForm();
      };

      onSubmit(mutation, socialUrl, closeModal);
    },
  });

  return (
    <Modal
      ref={modal}
      title={title}
      id="add-social-account-modal"
      modalToggler={
        <Button variant="contained" color="primary" startIcon={<AddIcon />} size="large">
          {title}
        </Button>
      }
      submitTitle="Add"
      fullWidth
      maxWidth="sm"
      loading={loading}
      onSubmit={formik.handleSubmit}
      onClose={formik.resetForm}
    >
      <FormControl fullWidth>
        <InputLabel htmlFor="socialUrl">Account URL</InputLabel>
        <Input
          id="socialUrl"
          name="socialUrl"
          type="text"
          value={formik.values.socialUrl}
          aria-describedby="socialUrl"
          error={Boolean(formik.errors.socialUrl)}
          required
          onChange={formik.handleChange}
        />
      </FormControl>
      {error != null ? (
        <Typography className={classes.error} color="error" align="center">
          {error}
        </Typography>
      ) : null}
    </Modal>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      marginTop: '10px',
    },
  })
);

export default AddSocialAccountModal;
