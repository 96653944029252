import React from 'react';
import classNames from 'classnames';

import {Media, MediaContainer, RelativeDate} from '../../../../components/Widgets';
import {MaterialDesignIcon} from '../../../../components/Icons';
import styles from './style.css';

const SIZE = 200;

interface Props {
  gig: Gig;
  frame: StoryFrame;
  selectFrame: (gig: Gig, frame: StoryFrame) => void;
}

const Frame: React.FC<Props> = ({gig, frame, selectFrame}) => {
  const getStylesForStoryFrame = (gig: Gig, frame: StoryFrame) => {
    const stl = [styles.post];
    if (frame.instagramStoryId) {
      if (gig.instagramContent && gig.instagramContent.id === frame.instagramStoryId) {
        stl.push(styles.selected);
      } else {
        stl.push(styles.partOfAnotherStory);
      }
    }
    return stl;
  };

  return (
    <div key={frame.id} className={styles.frameCard}>
      <div
        className={classNames(getStylesForStoryFrame(gig, frame))}
        onClick={() => selectFrame(gig, frame)}
      >
        <MediaContainer width={SIZE} height={SIZE}>
          <Media media={frame.media} width={SIZE} height={SIZE} loop={false} preload={'none'} />
        </MediaContainer>
      </div>
      <span className={styles.postedDate}>
        Posted <RelativeDate date={frame.posted} />
      </span>
      {frame?.mentions?.map(mention => (
        <div key={`${mention.username}_${Math.random() * 999}`} className={styles.tags}>
          <a
            href={`https://www.instagram.com/${mention.username}/`}
            target="_blank"
            title={mention.name}
            rel="noopener noreferrer"
          >
            @{mention.username}
          </a>
        </div>
      ))}
      {frame?.hashtags?.map(hashtag => (
        <div key={`${hashtag.username}_${Math.random() * 999}`} className={styles.tags}>
          <a
            href={`https://www.instagram.com/explore/tags/${hashtag.name}/`}
            target="_blank"
            title={hashtag.name}
            rel="noopener noreferrer"
          >
            #{hashtag.name}
          </a>
        </div>
      ))}
      {frame?.locations?.map(location => (
        <div key={location.id} className={styles.tags}>
          <a
            href={`https://www.instagram.com/explore/locations/${location.id}/`}
            target="_blank"
            rel="noopener noreferrer"
            title={location.name || 'Unknown'}
          >
            <MaterialDesignIcon icon="room" className={styles.locationIcon} />
            {location.name || 'location'}
          </a>
        </div>
      ))}
      {frame.swipeUpLink && (
        <div className={styles.tags}>
          <a
            href={frame.swipeUpLink}
            title={frame.swipeUpLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {frame.swipeUpLink}
          </a>
        </div>
      )}
    </div>
  );
};

export default Frame;
