import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button, Grid, Typography} from '@material-ui/core';

import {RequiresPermission} from '../../../components/Widgets';

interface Props {
  gig: Gig;
}

const GigRevertReport: React.FC<Props> = ({gig}) => {
  const [revertReport, revertReportResult] = useMutation(RevertReportMutation);

  const onClick = () => {
    if (revertReportResult?.loading) {
      return;
    }
    revertReport({variables: {id: gig.id}});
  };

  return (
    <RequiresPermission permission="manage_influencers">
      <Button variant="text" fullWidth onClick={onClick}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            Revert to reviewed
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle2" color="secondary">
              Revert a report back to being just reviewed, the client will have to either approve or
              report the content again
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </RequiresPermission>
  );
};

const RevertReportMutation = gql`
  mutation RevertReportMutation($id: UUIDString!) {
    revertGigReport(id: $id) {
      ok
      gig {
        id
        state
        canBeReported
      }
    }
  }
`;

export default GigRevertReport;
