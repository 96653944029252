import {Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';

import {NavigationLink} from '../../../../components/Widgets';

const FinancesWrapper: React.FC = ({children}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.title}>Finances</Typography>
      </Grid>
      <Grid item container xs={12} spacing={2} className={classes.navigation}>
        <Grid item>
          <NavigationLink to="/finances/statistics">Statistics</NavigationLink>
        </Grid>
        <Grid item>
          <NavigationLink to="/finances/approvals">Approvals</NavigationLink>
        </Grid>
        <Grid item>
          <NavigationLink to="/finances/reports">Reports</NavigationLink>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  navigation: {
    marginTop: '20px',
  },
  content: {
    marginTop: '20px',
  },
});

export default FinancesWrapper;
