import React, {useEffect, useState, useRef} from 'react';
import {ComposedChart, XAxis, YAxis, Bar, Line, Brush} from 'recharts';
import {useHistory} from 'react-router-dom';

import ChartLegend from './components/ChartLegend';
import {campaignUrlFromDashboard} from '../../../consts/urls';
import {AccessibleData, Summary, VisibleMetrics} from '../types';
import {formatTickForXAxis, formatTickForRightYAxis} from '../utils';
import {maxCampaignsToShow, axisStyle, yAxisPadding, xAxisStyle} from '../consts';
import useElementSize from '../../../hooks/useElementSize';
import {theme} from '../../../consts/theme';
import {black} from '../../../consts/brand.integration';
import {useStyles} from './styles';

interface Props {
  data: Array<AccessibleData>;
  summary: Summary;
  visibleMetrics: VisibleMetrics;
  count: number;
}

const MIN_CHART_WIDTH = 762; // avoids chart negative width value errors
const CHART_WIDTH_MULTIPLIER = 0.64;

const Chart: React.FC<Props> = ({data, summary, visibleMetrics, count}) => {
  const classes = useStyles();
  const history = useHistory();
  const [width, setWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const size = useElementSize(ref);

  useEffect(() => {
    if (size.width > MIN_CHART_WIDTH) {
      setWidth(size.width);
    }
  }, [size]);

  return (
    <div ref={ref} className={classes.chartContainer}>
      <ComposedChart
        aria-hidden={true}
        width={width * CHART_WIDTH_MULTIPLIER}
        height={450}
        data={data}
        className={classes.composedChart}
      >
        <XAxis
          interval={0}
          dataKey="id"
          axisLine={false}
          tickLine={false}
          angle={-34}
          textAnchor="end"
          height={60}
          style={{...axisStyle, ...xAxisStyle}}
          tickFormatter={(id: string) => formatTickForXAxis(id, data)}
          onClick={(tick: any) => history.push(campaignUrlFromDashboard({id: tick.value}))}
        />
        <YAxis
          yAxisId={1}
          axisLine={false}
          tickLine={false}
          style={axisStyle}
          tickCount={9}
          padding={yAxisPadding}
        />
        <YAxis
          yAxisId={2}
          orientation="right"
          axisLine={false}
          tickLine={false}
          style={axisStyle}
          tickCount={9}
          padding={yAxisPadding}
          tickFormatter={formatTickForRightYAxis}
        />
        <Bar
          yAxisId={1}
          dataKey="impressions"
          name="Impressions"
          fill={theme.palette['stats-impressions']}
          barSize={32}
          className={classes.bar}
          onClick={(barData: any) => history.push(campaignUrlFromDashboard(barData.payload))}
        />
        <Line
          yAxisId={2}
          dataKey="engagementRate"
          name="Engagement Rate"
          type="linear"
          legendType="plainline"
          dot={false}
          stroke={theme.palette['stats-er']}
          strokeWidth="2"
        />
        {count > maxCampaignsToShow && (
          <Brush
            className={classes.brush}
            dataKey="id"
            endIndex={maxCampaignsToShow - 1}
            tickFormatter={() => ''}
            height={12}
            stroke={black}
          />
        )}
      </ComposedChart>
      <ChartLegend summary={summary} visibleMetrics={visibleMetrics} containerWidth={width} />
    </div>
  );
};

export default Chart;
