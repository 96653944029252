import {createStyles, makeStyles} from '@material-ui/core/styles';

import {semiBold, lineHeightLarge} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    metrics: {
      paddingLeft: 48,
      paddingTop: 76,
    },
    metricWrapper: {
      marginBottom: 24,
    },
    metric: {
      fontWeight: semiBold,
    },
    metricValue: {
      lineHeight: lineHeightLarge,
    },
  })
);
