import {makeStyles} from '@material-ui/styles';

import {black} from '../../../consts/brand.integration';
import {theme} from '../../../consts/theme';

export const useStyles = makeStyles({
  clearFilterButton: {
    color: black,
    padding: '8px',
    height: '40px',
    width: '150px',
    backgroundColor: theme.palette['ui-02'],
    '&:hover': {
      backgroundColor: theme.palette['ui-02'],
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
  clearFilterIcon: {
    marginRight: '8px',
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 8,
  },
});
