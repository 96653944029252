import PubSub from 'pubsub-js';

const ONE_MINUTE = 60 * 1000;

class TimeService {
  constructor() {
    setInterval(() => PubSub.publish('minute_update', {}), ONE_MINUTE);
  }

  subscribe(callback: () => void) {
    return PubSub.subscribe('minute_update', callback);
  }

  unsubscribe(value: any) {
    PubSub.unsubscribe(value);
  }
}

const service = new TimeService();

export default service;
