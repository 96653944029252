import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {MaterialDesignIcon} from '../../../../components/Icons';
import {Section} from '../../../../components/Widgets';

const TargetingSection: React.FC<{targeting: Campaign['targeting']}> = props => {
  const classes = useStyles();
  const targeting = props.targeting ?? {};

  return (
    <Section title="Targeting">
      <Grid item container xs={12} direction="row">
        {Object.keys(targeting ?? {}).map(key => (
          <Grid key={key} item xs={12} md={4}>
            <div key={key} className={classes.targeting}>
              {targeting[key as keyof Targeting] ? (
                <MaterialDesignIcon icon="check" className={classes.success} />
              ) : (
                <MaterialDesignIcon icon="close" className={classes.fail} />
              )}{' '}
              {key}
            </div>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

const useStyles = makeStyles({
  targeting: {
    fontWeight: 500,
    lineHeight: '40px',
    i: {
      lineHeight: '40px',
      verticalAlign: 'top',
    },
  },
  success: {
    color: 'green',
  },
  fail: {
    color: 'red',
  },
});

export default TargetingSection;
