import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black, purple} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    posted: {
      marginTop: '40px',
    },
    summary: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      margin: '36px 0 0',
    },
    isStorySummary: {
      justifyContent: 'center',
    },
    dots: {
      bottom: '-38px',
    },
    dot: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: 'none',
      boxShadow: 'none',
      backgroundColor: black,
      marginLeft: '6px',
      marginRight: '6px',
    },
    activeDot: {
      backgroundColor: purple,
    },
  })
);
