import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export const BusinessAccount = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, 6.000000)" stroke={color} strokeWidth="3">
        <path d="M2,15 C9.31644162,17.6666667 15.9831083,19 22,19 C28.0168917,19 34.6835584,17.6666667 42,15" />
        <path d="M21.5,15 L21.5,24" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="1.5" y="6.5" width="40" height="25" rx="7" />
        <path d="M12,6 C13.0648358,2 16.2315025,3.88578059e-16 21.5,0 C26.7684975,0 29.9351642,2 31,6" />
      </g>
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
    </g>
  </svg>
);
BusinessAccount.propTypes = propTypes;

export const CreatorAccount = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, 6.000000)" stroke={color} strokeWidth="3">
        <path d="M25.2966304,1.5 L17.7033696,1.5 L11.6327813,6.09138553 L3,6.09138553 C2.17157288,6.09138553 1.5,6.7629584 1.5,7.59138553 L1.5,30 C1.5,30.8284271 2.17157288,31.5 3,31.5 L40,31.5 C40.8284271,31.5 41.5,30.8284271 41.5,30 L41.5,7.59138553 C41.5,6.7629584 40.8284271,6.09138553 40,6.09138553 L31.3672187,6.09138553 L25.2966304,1.5 Z" />
        <circle cx="21.5" cy="17.5" r="9" />
      </g>
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
    </g>
  </svg>
);
CreatorAccount.propTypes = propTypes;

export const Curled = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <circle stroke={color} strokeWidth="2" cx="22" cy="22" r="13" />
    </g>
  </svg>
);
Curled.propTypes = propTypes;

export const Female = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <g transform="translate(12.000000, 5.000000)" stroke={color} strokeWidth="3">
        <circle cx="9.5" cy="9.5" r="9.5" />
        <path d="M9.5,32.5 L9.5,19.5" strokeLinecap="square" />
        <path d="M5.5,28.5 L13.5,28.5" strokeLinecap="square" />
      </g>
    </g>
  </svg>
);
Female.propTypes = propTypes;

export const Glasses = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width={size} height={size} />
      <g transform="translate(0.000000, 12.000000)">
        <path
          d="M9.5,16.6044444 C14.278295,16.6044444 18.16,12.6301199 18.16,7.71776953 C18.16,3.39349193 15.0906781,1.39555556 9.5,1.39555556 C3.90932194,1.39555556 0.84,3.39349193 0.84,7.71776953 C0.84,12.6301199 4.72170504,16.6044444 9.5,16.6044444 Z"
          stroke={color}
          strokeWidth="1.68"
        />
        <path
          d="M33.5,16.6044444 C38.278295,16.6044444 42.16,12.6301199 42.16,7.71776953 C42.16,3.39349193 39.0906781,1.39555556 33.5,1.39555556 C27.9093219,1.39555556 24.84,3.39349193 24.84,7.71776953 C24.84,12.6301199 28.721705,16.6044444 33.5,16.6044444 Z"
          stroke={color}
          strokeWidth="1.68"
        />
        <path
          d="M18,7.16666667 C18.6961264,5.61111111 19.862793,4.83333333 21.5,4.83333333 C23.137207,4.83333333 24.3038736,5.61111111 25,7.16666667"
          stroke={color}
          strokeWidth="1.68"
        />
        <circle fill={color} cx="10.5" cy="8.5" r="1.5" />
        <circle fill={color} cx="32.5" cy="8.5" r="1.5" />
      </g>
    </g>
  </svg>
);
Glasses.propTypes = propTypes;

export const Male = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <g transform="translate(26.207107, 19.384776) rotate(-315.000000) translate(-26.207107, -19.384776) translate(16.207107, 3.384776)">
        <circle stroke={color} strokeWidth="3" cx="9.58147545" cy="22.2383297" r="9.5" />
        <path d="M9.5,12.5 L9.5,5.5" stroke={color} strokeWidth="3" strokeLinecap="square" />
        <polygon fill={color} points="9.5 3.41060513e-13 14 6 5 6" />
      </g>
    </g>
  </svg>
);
Male.propTypes = propTypes;

export const NoGlasses = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <g transform="translate(5.000000, 16.000000)">
        <path
          d="M0,3 C0,3 1.47977075,2.3939184e-16 5.5,0 C8.18015283,0 10.0134862,1 11,3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M22,3 C22,3 23.4797708,2.3939184e-16 27.5,0 C30.1801528,0 32.0134862,1 33,3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle fill={color} cx="5.5" cy="4.5" r="1.5" />
        <circle fill={color} cx="27.5" cy="4.5" r="1.5" />
      </g>
    </g>
  </svg>
);
NoGlasses.propTypes = propTypes;

export const NormalAccount = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(9.000000, 6.000000)" stroke={color} strokeWidth="3">
        <path d="M0,0 L25,0 L25,19 C25,23.418278 21.418278,27 17,27 L8,27 C3.581722,27 5.41083001e-16,23.418278 0,19 L0,0 Z" />
        <path d="M25,0 L28.1146201,0 C30.5999014,-4.56538782e-16 32.6146201,2.01471863 32.6146201,4.5 L32.6146201,4.5 C32.6146201,6.98528137 30.5999014,9 28.1146201,9 L25,9 L25,0 Z" />
        <path d="M0,31.5362607 L25,31.5362607" strokeLinecap="round" />
      </g>
    </g>
  </svg>
);
NormalAccount.propTypes = propTypes;

export const Other = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <g transform="translate(12.000000, 12.000000)" stroke={color} strokeWidth="3">
        <circle cx="9.5" cy="9.5" r="9.5" />
      </g>
    </g>
  </svg>
);
Other.propTypes = propTypes;

export const Straight = ({color = 'black', size = 30}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect opacity="0.198869978" x="0" y="0" width="43" height="43" />
      <path d="M12,8.98643243 L12,34.7137146" stroke={color} strokeWidth="2" />
      <path d="M21.5,8.98643243 L21.5,34.7137146" stroke={color} strokeWidth="2" />
      <path d="M31.5,8.98643243 L31.5,34.7137146" stroke={color} strokeWidth="2" />
    </g>
  </svg>
);
Straight.propTypes = propTypes;
