import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Grid} from '@material-ui/core';

import {MaterialDesignIcon} from 'components/Icons';
import {
  Definition,
  DownloadCSV,
  FormattedDate,
  Internal,
  RequiresPermission,
  Tooltip,
} from 'components/Widgets';

import {capitalize, numberWithCommas} from 'consts/utilities';

import useRequiresPermission from '../../../../hooks/useRequiresPermission';
import {TEAM_MEMBER} from '../../../../consts/permissions';
import styles from './style.css';

interface EngagementsProps {
  engagements?: Engagements;
  engagementRate?: Percent;
}

const Engagements = ({engagements, engagementRate}: EngagementsProps): JSX.Element => {
  if (engagements?.total! > 0) {
    return (
      <>
        <div className={styles.heading}>Engagements</div>
        <Grid container className={styles.definitionRow} spacing={2}>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of likes based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="likes" definition={numberWithCommas(engagements?.likes)} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of comments based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="comments"
                definition={numberWithCommas(engagements?.comments)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of saves based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="saves" definition={numberWithCommas(engagements?.saves)} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of replies based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="replies"
                definition={numberWithCommas(engagements?.replies)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of shares based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="shares" definition={numberWithCommas(engagements?.shares)} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={
                <span>
                  The engagement rate, based on total engagements and followers of live gigs in this
                  post
                </span>
              }
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="engagement rate"
                definition={engagementRate?.formattedValue}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  }
  return <></>;
};

interface ImpressionsProps {
  impressions?: Impressions;
}

const Impressions = ({impressions}: ImpressionsProps): JSX.Element => {
  if (impressions?.total! > 0) {
    return (
      <>
        <div className={styles.heading}>Impressions</div>
        <Grid container className={styles.definitionRow} spacing={2}>
          <Grid item>
            <Tooltip
              overlay={
                <span>Total number of impressions from the home screen based on insights</span>
              }
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="home" definition={numberWithCommas(impressions?.home)} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={
                <span>Total number of impressions from the profile screen based on insights</span>
              }
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="profile"
                definition={numberWithCommas(impressions?.profile)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of impressions from hashtags based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="hashtags"
                definition={numberWithCommas(impressions?.hashtags)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of impressions from explore based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="explore"
                definition={numberWithCommas(impressions?.explore)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of impressions from location based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition
                gold
                title="location"
                definition={numberWithCommas(impressions?.location)}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              overlay={<span>Total number of impressions from other based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="other" definition={numberWithCommas(impressions?.other)} />
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  }
  return <></>;
};

interface ActionsProps {
  actions: Record<string, any>;
}

const Actions = ({actions}: ActionsProps): JSX.Element => {
  if (actions.total > 0) {
    return (
      <>
        <div className={styles.heading}>Actions</div>
        <Grid container className={styles.definitionRow} spacing={2}>
          <Grid item>
            <Definition
              gold
              title="link clicks"
              definition={numberWithCommas(actions.linkClicks)}
            />
          </Grid>
          <Grid item>
            <Definition
              gold
              title="profile visits"
              definition={numberWithCommas(actions.profileVisits)}
            />
          </Grid>
          <Grid item>
            <Definition
              gold
              title="website clicks"
              definition={numberWithCommas(actions.websiteClicks)}
            />
          </Grid>
          <Grid item>
            <Definition
              gold
              title="sticker taps"
              definition={numberWithCommas(actions.stickerTaps)}
            />
          </Grid>
          <Grid item>
            <Definition gold title="replies" definition={numberWithCommas(actions.replies)} />
          </Grid>
          <Grid item>
            <Definition gold title="shares" definition={numberWithCommas(actions.shares)} />
          </Grid>
          <Grid item>
            <Definition gold title="total" definition={numberWithCommas(actions.total)} />
          </Grid>
        </Grid>
      </>
    );
  }
  return <></>;
};

interface BasePostReportProps {
  post: Post & {
    report: StandardPostReport | VideoPostReport | StoryPostReport;
  };
  impressions: Record<string, any>;
  engagements: Record<string, any>;
}

const BasePostReport = ({post, impressions, engagements}: BasePostReportProps) => {
  const {report} = post;
  return (
    <>
      <Grid container className={styles.definitionRow} spacing={2}>
        <Grid item>
          <Definition gold title="Type" definition={capitalize(post.postType)} />
        </Grid>
        <Grid item>
          <Definition
            gold
            title="Submissions open"
            definition={<FormattedDate date={post.opened} format="Do MMM" />}
          />
        </Grid>
        <Grid item>
          <Definition
            gold
            title="Deadline"
            definition={<FormattedDate date={post.deadline} format="Do MMM" />}
          />
        </Grid>
        {post.price?.formattedValue && (
          <Grid item>
            <Tooltip
              overlay={
                <span>
                  The configured price for this post, which is only used for calculation in these
                  reports
                </span>
              }
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="Price" definition={post.price.formattedValue} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.definitionRow} spacing={2}>
        <Grid item>
          <Tooltip
            overlay={<span>Submissions that are have been posted Instagram</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition
              gold
              title="submission"
              definition={numberWithCommas(report?.submissions)}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            overlay={<span>Number of processed insights in this campaign post</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition
              gold
              title="insights"
              definition={numberWithCommas(report?.processedSubmissions)}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            overlay={<span>Number of assets posted in this campaign post</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition gold title="assets" definition={numberWithCommas(report?.assets)} />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            overlay={<span>Total number of followers at the time each gig was posted</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition gold title="followers" definition={numberWithCommas(report?.followers)} />
          </Tooltip>
        </Grid>
      </Grid>
      {report?.reach && report.reach > 0 && (
        <Grid container className={styles.definitionRow} spacing={2}>
          <Grid item>
            <Tooltip
              overlay={<span>Total reach based on insights</span>}
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <Definition gold title="reach" definition={numberWithCommas(report?.reach)} />
            </Tooltip>
          </Grid>
          {impressions && (
            <Grid item>
              <Tooltip
                overlay={<span>Total impressions based on insights</span>}
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                <Definition gold title="impressions" definition={numberWithCommas(impressions)} />
              </Tooltip>
            </Grid>
          )}
          {engagements && (
            <Grid item>
              <Tooltip
                overlay={<span>Total engagements based on insights</span>}
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                <Definition gold title="engagements" definition={numberWithCommas(engagements)} />
              </Tooltip>
            </Grid>
          )}
          {report?.frequency && (
            <Grid item>
              <Tooltip
                overlay={<span>The ratio of total impressions to total reach</span>}
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                <Definition
                  gold
                  title="frequency"
                  definition={numberWithCommas(report.frequency)}
                />
              </Tooltip>
            </Grid>
          )}
          {report?.projectedCpe?.formattedValue && (
            <Grid item>
              <Tooltip
                overlay={
                  <span>
                    The projected cost per engagement for this post, based on the price configured
                    for this post
                  </span>
                }
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                <Definition
                  gold
                  title="Projected CPE"
                  definition={report?.projectedCpe?.formattedValue}
                />
              </Tooltip>
            </Grid>
          )}
          {report?.projectedCpv?.formattedValue && (
            <Grid item>
              <Tooltip
                overlay={
                  <span>
                    The projected cost per view view for this post, based on the price configured
                    for this post
                  </span>
                }
                mouseEnterDelay={0.2}
                placement="bottom"
              >
                <Definition
                  gold
                  title="Projected CPV"
                  definition={report.projectedCpv!.formattedValue}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

interface StandardPostReportProps {
  post: Post & {
    report: StandardPostReport;
  };
}

const StandardPostReport = ({post}: StandardPostReportProps) => {
  const {report} = post;
  const {engagements, engagementRate, impressions} = report;
  return (
    <>
      <Grid container className={styles.definitionRow} spacing={2}>
        <Grid item>
          <Tooltip
            overlay={<span>Total number of profile visits based on insights</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition
              gold
              title="profile visits"
              definition={numberWithCommas(report.profileVisits)}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            overlay={<span>Total number of website clicks based on insights</span>}
            mouseEnterDelay={0.2}
            placement="bottom"
          >
            <Definition
              gold
              title="website clicks"
              definition={numberWithCommas(report.websiteClicks)}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Engagements engagements={engagements} engagementRate={engagementRate} />
      <Impressions impressions={impressions} />
    </>
  );
};

interface VideoPostReportProps {
  post: Post & {
    report: VideoPostReport;
  };
}

const VideoPostReport = ({
  post: {
    report: {videoViews, videoViewRate, engagements, engagementRate, impressions},
  },
}: VideoPostReportProps) => (
  <div>
    <Engagements engagements={engagements} engagementRate={engagementRate} />
    <Impressions impressions={impressions} />
    <div className={styles.heading}>Video</div>
    <Grid container className={styles.definitionRow} spacing={2}>
      <Grid item>
        <Definition gold title="views" definition={numberWithCommas(videoViews)} />
      </Grid>
      <Grid item>
        <Definition gold title="view rate" definition={videoViewRate.formattedValue} />
      </Grid>
    </Grid>
  </div>
);

interface StoryPostReportProps {
  post: Post & {
    report: StoryPostReport;
  };
}

const StoryPostReport = ({post}: StoryPostReportProps) => (
  <Actions actions={post?.report?.actions!} />
);

interface DownloadInsightsProps {
  campaign: Campaign;
  post: Post;
  postIndex: number;
}

const DownloadInsights = ({campaign, post, postIndex}: DownloadInsightsProps) => {
  const hasPermission = useRequiresPermission(TEAM_MEMBER);
  const {loading, error, data} = useQuery(InsightsQuery, {
    variables: {postId: post.id},
    skip: !hasPermission,
  });

  const filename = `${campaign.name} - Post ${postIndex} - insights.csv`;

  let fields = [
    'gig.id',
    'gig.influencer.fullName',
    'gig.influencer.followers',
    'gig.instagramContent.followers',
    'state',
    'reach',
  ];
  if (post.postType === 'story') {
    fields = fields.concat([
      'views',
      'shares',
      'replies',
      'linkClicks',
      'profileVisits',
      'stickerTaps',
      'impressions',
      'follows',
      'backNavigations',
      'forwardNavigations',
      'nextStoryNavigations',
      'exitedNavigations',
      'websiteClicks',
      'emails',
    ]);
  } else {
    fields = fields.concat([
      'nonFollowersReach',
      'likes',
      'comments',
      'shares',
      'bookmarks',
      'profileVisits',
      'replies',
      'websiteClicks',
      'calls',
      'emails',
      'follows',
      'fromHashtagsImpressions',
      'fromHomeImpressions',
      'fromExploreImpressions',
      'fromLocationImpressions',
      'fromProfileImpressions',
      'fromOtherImpressions',
      'totalImpressions',
    ]);
  }

  if (loading || error || !data) {
    return <></>;
  }

  if (data.insights?.count > 0) {
    return (
      <DownloadCSV
        className={styles.insightsLink}
        data={data.insights.edges.map((edge: InsightEdge) => edge.node)}
        filename={filename}
        text="Download Insights (Excel)"
        fields={fields}
      />
    );
  }
  return <></>;
};

interface Props {
  open: boolean;
  toggle: () => void;
  campaign: Campaign;
  post: Post;
  postIndex: number;
}

const PostReport: React.FC<Props> = ({open, toggle, campaign, post, postIndex}) => {
  if (!post.report) {
    // Still allow insight downloading for internal
    return (
      <RequiresPermission permission="manage_influencers">
        <div className={styles.root}>
          {open ? (
            <Internal className={styles.content}>
              <DownloadInsights campaign={campaign} post={post} postIndex={postIndex} />
            </Internal>
          ) : (
            <div className={styles.closed} onClick={toggle}>
              <div className={styles.expand}>Expand report</div>
              <MaterialDesignIcon className={styles.chevron} icon="keyboard_arrow_down" />
            </div>
          )}
        </div>
      </RequiresPermission>
    );
  }
  let impressions = null;
  if (post.report.__typename === 'StoryPostReport') {
    impressions = post.report.storyImpressions;
  } else {
    impressions = post.report.impressions.total;
  }

  let engagements = null;
  if (post.report.__typename !== 'StoryPostReport') {
    engagements = post.report.engagements.total;
  }

  return (
    <div className={styles.root}>
      {open ? (
        <div>
          <Internal className={styles.content}>
            <DownloadInsights campaign={campaign} post={post} postIndex={postIndex} />
            <BasePostReport post={post} impressions={impressions} engagements={engagements} />
            {post.report.__typename === 'StandardPostReport' && <StandardPostReport post={post} />}
            {post.report.__typename === 'VideoPostReport' && <VideoPostReport post={post} />}
            {post.report.__typename === 'StoryPostReport' && <StoryPostReport post={post} />}
          </Internal>
          <div className={styles.closed} onClick={toggle}>
            <MaterialDesignIcon className={styles.chevron} icon="keyboard_arrow_up" />
            <div className={styles.collapse}>Collapse report</div>
          </div>
        </div>
      ) : (
        <div className={styles.closed} onClick={toggle}>
          <div className={styles.expand}>Expand report</div>
          <MaterialDesignIcon className={styles.chevron} icon="keyboard_arrow_down" />
        </div>
      )}
    </div>
  );
};

const InsightsQuery = gql`
  query PostReportInsightsQuery($postId: UUIDString) {
    insights(postId: $postId, processed: true) {
      edges {
        node {
          id
          processed
          state
          reach

          ... on StoryInsight {
            views
            shares
            replies
            linkClicks
            profileVisits
            stickerTaps
            impressions
            follows
            backNavigations
            forwardNavigations
            nextStoryNavigations
            exitedNavigations
            websiteClicks
            emails
          }
          ... on PostInsight {
            nonFollowersReach
            likes
            comments
            shares
            bookmarks
            profileVisits
            replies
            websiteClicks
            calls
            emails
            follows
            fromHashtagsImpressions
            fromHomeImpressions
            fromExploreImpressions
            fromLocationImpressions
            fromProfileImpressions
            fromOtherImpressions
            totalImpressions
          }

          gig {
            id
            influencer {
              id
              username
              followers
            }
            instagramContent {
              id
              followers
            }
          }
          __typename
        }
      }
      count
    }
  }
`;

export default PostReport;
