import ErrorReporting from 'services/error-reporting';

// Catch errors in reducers
export default function errorHandler() {
  return next => action => {
    try {
      return next(action);
    } catch (error) {
      // eslint-disable-next-line
      console.log('Error in errorHandler', error, action);

      if (__DEV__) {
        throw error;
      }

      ErrorReporting.captureError(error, {action}, 'errorHandlerMiddleware.errorHandler');

      return next({
        error,
        pending: false,
        type: action.type,
      });
    }
  };
}
