import React from 'react';
import {cloneDeep, isEqual} from 'lodash';
import {Button, Typography} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {ICampaignFilters} from '../../../scenes/Admin/Campaigns/types';
import SelectedItemChip from '../../../scenes/Advertiser/AdvertiserSettings/components/IndustrySelector/SelectedItemChip';
import {
  getSelectedExtendedFilters,
  mapExtendedFilterOptions,
} from '../../../scenes/Admin/Campaigns/components/AdminCampaignFilters';
import SelectedIndustryChips from '../../../scenes/Advertiser/AdvertiserSettings/components/IndustrySelector/SelectedIndustryChips';
import {TreeViewEntity} from '../../../scenes/Advertiser/AdvertiserSettings/types';
import {filtersVariables} from '../../../consts/campaignsFilters';
import {useStyles} from './styles';

export interface IProps {
  filters: ICampaignFilters;
  setFilters: React.Dispatch<React.SetStateAction<ICampaignFilters>>;
  industries: ReadonlyArray<TreeViewEntity>;
  onIndustryChanged: (text: string) => void;
}

export const CampaignFiltersChips: React.FC<IProps> = ({
  filters,
  setFilters,
  onIndustryChanged,
  industries,
}) => {
  const classes = useStyles();
  const selectedExtendedFilters = getSelectedExtendedFilters(filters);
  return (
    <>
      {!isEqual(filters, filtersVariables) && (
        <div className={classes.chipsContainer}>
          {selectedExtendedFilters.map(item => (
            <SelectedItemChip
              key={item}
              id={item}
              value={mapExtendedFilterOptions[item]}
              onChange={() => setFilters({...filters, [item]: filtersVariables[item]})}
            />
          ))}
          <SelectedIndustryChips
            selectedEntitiesIds={filters.advertiserIndustries.map(item => item.id)}
            industries={industries}
            onChange={onIndustryChanged}
          />

          <Button
            className={classes.clearFilterButton}
            onClick={() => setFilters({...cloneDeep(filtersVariables)})}
          >
            <ClearIcon className={classes.clearFilterIcon} />
            <Typography component="span" variant="button">
              Clear all filters
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
};
