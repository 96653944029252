import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {useRouteMatch} from 'react-router-dom';

import {LoaderDots, NavigationLink, RequiresPermission} from 'components/Widgets';

import {campaignUrlFromParams} from 'consts/urls';

import {
  EDIT_CAMPAIGN,
  MANAGE_INFLUENCERS,
  MANAGE_SHIPPING_INFO,
  VIEW_CAMPAIGN_PERFORMANCE,
  VIEW_PARTICIPANTS,
  VIEW_POST_REPORTS,
} from '../../../../consts/permissions';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

interface Props {
  isAdvertiser: boolean;
}

type Params = {
  campaign: string;
};

const CampaignNavigation: React.FC<Props> = ({isAdvertiser}) => {
  const {params}: {params: Params} = useRouteMatch();
  const {loading, data} = useQuery(CampaignQuery, {
    variables: {
      campaignId: params.campaign,
    },
  });

  const {showPerformanceReport} = useFeatureFlags();
  const baseUrl = campaignUrlFromParams(params);

  if (loading) {
    return <LoaderDots />;
  }

  return (
    <>
      <RequiresPermission permission={EDIT_CAMPAIGN}>
        <NavigationLink to={`${baseUrl}/setup`}>Setup</NavigationLink>
      </RequiresPermission>

      <NavigationLink to={`${baseUrl}/posts`}>Posts</NavigationLink>

      {(data?.campaign?.brandSafety || !isAdvertiser) && (
        <NavigationLink to={`${baseUrl}/unapproved`}>Pending Approvals</NavigationLink>
      )}

      {showPerformanceReport && (
        <RequiresPermission permission={VIEW_CAMPAIGN_PERFORMANCE}>
          <NavigationLink to={`${baseUrl}/performance`}>Performance</NavigationLink>
        </RequiresPermission>
      )}

      <RequiresPermission permission={VIEW_POST_REPORTS}>
        <NavigationLink to={`${baseUrl}/detailed_report`}>Report</NavigationLink>
      </RequiresPermission>

      <RequiresPermission permission={MANAGE_INFLUENCERS}>
        <NavigationLink to={`${baseUrl}/unverified`}>Unverified</NavigationLink>
      </RequiresPermission>

      <RequiresPermission permission={EDIT_CAMPAIGN}>
        <NavigationLink to={`${baseUrl}/targeting`}>Targeting</NavigationLink>
      </RequiresPermission>

      <RequiresPermission permission={VIEW_PARTICIPANTS}>
        <NavigationLink to={`${baseUrl}/participants`}>Participants</NavigationLink>
      </RequiresPermission>

      {data?.campaign?.prompts.length > 0 && (
        <RequiresPermission permission={VIEW_PARTICIPANTS}>
          <NavigationLink to={`${baseUrl}/prompts`}>Prompts</NavigationLink>
        </RequiresPermission>
      )}

      <RequiresPermission permission={MANAGE_INFLUENCERS}>
        <NavigationLink to={`${baseUrl}/influencers`}>Manage Influencer</NavigationLink>
      </RequiresPermission>

      <RequiresPermission permission={MANAGE_INFLUENCERS}>
        <NavigationLink to={`${baseUrl}/notify`}>Notify</NavigationLink>
      </RequiresPermission>

      <RequiresPermission permission={VIEW_PARTICIPANTS}>
        <NavigationLink to={`${baseUrl}/calendar`}>Content Calendar</NavigationLink>
      </RequiresPermission>

      {data?.campaign?.shippingRequired && (
        <RequiresPermission permission={MANAGE_SHIPPING_INFO}>
          <NavigationLink to={`${baseUrl}/shipping`}>Shipping</NavigationLink>
        </RequiresPermission>
      )}

      <RequiresPermission permission={MANAGE_INFLUENCERS}>
        <NavigationLink to={`${baseUrl}/insights`}>Gig Insights</NavigationLink>
      </RequiresPermission>

      {data?.campaign.brandMatch && (
        <NavigationLink to={`${baseUrl}/match`}>Brand match</NavigationLink>
      )}
    </>
  );
};

const CampaignQuery = gql`
  query CampaignSetupQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      brandSafety
      archived
      brandMatch
      shippingRequired
      prompts {
        text
        choices
        type
      }
    }
    isAdvertiser: hasRole(roleName: "advertiser")
  }
`;

export default CampaignNavigation;
