import React from 'react';
import {Box, Button, FormControl, FormLabel, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {useGlobalStyles} from '../../../App/App/style.integration';
import {useStyles} from './styles';
import {ILoginForm} from './types';
import Form from '../../../../components/Widgets/Forms/Form';
import {isEmail, minLength} from '../../../../services/validators';
import {ControlEmailInput} from '../EmailInput';
import {ControlPasswordInput} from '../PasswordInput';

const LoginForm: React.FC<ILoginForm> = ({onSubmit}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Form onSubmit={onSubmit}>
      <FormControl>
        <FormLabel id="email-input-label" htmlFor="email-input" className={classes.emailFormLabel}>
          <Typography component="span" variant="h4" className={classes.label}>
            Email
          </Typography>
        </FormLabel>
        <ControlEmailInput
          type="email"
          show="touched"
          name="email"
          placeholder="Email"
          validators={{isEmail}}
          messages={{
            isEmail: (val: string) =>
              val ? `"${val}" is not a valid email address` : 'Email is required',
          }}
          required
          errorWrapper={({children}: any) => (
            <Typography variant="caption" component="span" className={classes.helperText}>
              {children}
            </Typography>
          )}
        />
      </FormControl>

      <FormControl>
        <FormLabel
          id="password-input-label"
          htmlFor="password-input"
          className={classes.passwordFormLabel}
        >
          <Typography component="span" variant="h4" className={classes.label}>
            Password
          </Typography>
        </FormLabel>
        <ControlPasswordInput
          type="password"
          name="password"
          placeholder="Password"
          show="touched"
          validators={{length: minLength(3)}}
          messages={{
            length: 'Password must be at least 3 characters',
          }}
          required
          errorWrapper={({children}: any) => (
            <Typography variant="caption" component="span" className={classes.helperText}>
              {children}
            </Typography>
          )}
        />
      </FormControl>

      <Box display="flex" flexDirection="row">
        <Link to="/password-recovery">
          <Typography
            variant="body2"
            className={`${globalClasses.body2Link} ${classes.forgotPassword}`}
          >
            Forgot password
          </Typography>
        </Link>
      </Box>

      <Box flexDirection="row-reverse" display="flex">
        <Button variant="outlined" className={classes.button} fullWidth type="submit">
          <ArrowForwardIcon fontSize="small" />
          <Typography component="span" variant="button" className={classes.loginText}>
            Log in
          </Typography>
        </Button>
      </Box>
    </Form>
  );
};

export default LoginForm;
