import PropTypes from 'prop-types';
import React from 'react';
import {actions} from 'react-redux-form';

import {FormCheckbox} from '../';

export default class FormCheckboxGroup extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
  };

  getAllName() {
    return `all.${this.props.name}`;
  }

  changeAll = (model, value) => {
    return dispatch => {
      dispatch(actions.change(model, value)); // Update the all checkbox

      if (value) {
        const values = React.Children.map(this.props.children, child => child.props.value);
        dispatch(actions.change(`local.${this.props.name}[]`, values));
      } else {
        dispatch(actions.change(`local.${this.props.name}[]`, []));
      }
    };
  };

  changeSingle = (model, value) => {
    return dispatch => {
      dispatch(actions.xor(model, value)); // Update the checkbox
      dispatch(actions.change(this.getAllName()), false); // Uncheck the all checkbox
    };
  };

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        name: `${this.props.name}[]`,
        changeAction: this.changeSingle,
      })
    );

    return (
      <div>
        <FormCheckbox label="All" name={this.getAllName()} changeAction={this.changeAll} />
        {childrenWithProps}
      </div>
    );
  }
}
