import React from 'react';
import {Chip as MuiChip, ChipProps} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {withStyles} from '@material-ui/styles';

import {black10} from '../../../consts/brand.integration';
import {PostType, PostTypeIcons} from '../../../consts/postTypes';

interface Props extends ChipProps {
  type: PostType;
}

const config = {
  [PostType.InstagramStandard]: {
    icon: PostTypeIcons[PostType.InstagramStandard],
    label: 'Instagram Picture',
  },
  [PostType.InstagramVideo]: {
    icon: PostTypeIcons[PostType.InstagramVideo],
    label: 'Instagram Video',
  },
  [PostType.InstagramStory]: {
    icon: PostTypeIcons[PostType.InstagramStory],
    label: 'Instagram Story',
  },
  [PostType.InstagramReel]: {
    icon: PostTypeIcons[PostType.InstagramReel],
    label: 'Instagram Reel',
  },
  [PostType.Tiktok]: {
    icon: PostTypeIcons[PostType.Tiktok],
    label: 'TikTok',
  },
  [PostType.Youtube]: {
    icon: PostTypeIcons[PostType.Youtube],
    label: 'YouTube',
  },
  [PostType.YoutubeShort]: {
    icon: PostTypeIcons[PostType.Youtube],
    label: 'YouTube Short',
  },
  [PostType.YoutubeVideo]: {
    icon: PostTypeIcons[PostType.Youtube],
    label: 'YouTube Video',
  },
  [PostType.Twitch]: {
    icon: PostTypeIcons[PostType.Twitch],
    label: 'Twitch',
  },
  [PostType.Content]: {
    icon: PostTypeIcons[PostType.Content],
    label: 'Content',
  },
};

const PostTypeChip = ({type, ...rest}: Props) => {
  if (config[type] == null) return null;
  const {icon, ...props} = config[type];

  return <Chip avatar={<Icon icon={icon} />} {...props} {...rest} />;
};

const Chip = withStyles({
  root: {
    width: 'fit-content',
    padding: '1rem .2rem',
    backgroundColor: black10,

    '& .MuiChip-avatar': {
      width: '16px',
    },
  },
})(MuiChip);

export default PostTypeChip;
