import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Divider, Grid, Typography} from '@material-ui/core';
import {gql, useQuery} from '@apollo/client';
import groupBy from 'lodash.groupby';

import {FormattedDate, HideInDemoMode, LoaderDots, Section} from 'components/Widgets';
import {GigMedia, InfluencerBox} from 'components/Gig';

import {campaignUrl} from 'consts/urls';
import {getOverlayText} from 'consts/gigHelper';
import {EDIT_CAMPAIGN} from 'consts/permissions';
import {UserRoles} from 'consts/roles';
import {getPostTypeFormatted} from 'consts/campaignPostType';
import useRequiresPermission from 'hooks/useRequiresPermission';
import useCurrentUser from 'hooks/useCurrentUser';

import {PostReport} from '../components';

interface Props {
  campaign: Campaign;
  post: Post;
  postIndex: number;
}

const CampaignPost: React.FC<Props> = ({campaign, post, postIndex}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [reportOpen, setReportOpen] = useState(false);
  const isAdvertiser = currentUser?.roleName === UserRoles.Advertiser;
  const {loading, data} = useQuery(OffersForPost, {
    variables: {
      id: post.id,
      submitted: isAdvertiser ? true : null,
    },
  });
  const canEditCampaign = useRequiresPermission(EDIT_CAMPAIGN);

  const renderTitle = () => (
    <Typography variant="body2" color="textSecondary">
      {post.opened ? (
        <>
          {postIndex}. {getPostTypeFormatted(post.postType ?? 'Unknown')} Post -{' '}
          <FormattedDate date={post.opened} format="Do MMM, YYYY" />
        </>
      ) : (
        <>
          {postIndex}. {post.postType} Post
        </>
      )}
    </Typography>
  );

  const toggleReport = () => setReportOpen(!reportOpen);

  if (loading) {
    return <LoaderDots />;
  }

  const offersForPost: Offer[] =
    data?.offersForPost.edges.map((edge: OfferEdge) => edge.node) ?? [];

  const availableGigs = offersForPost
    .reduce((acc: Gig[], item: Offer) => {
      const deliverablesWithGigs = item.deliverables?.filter(
        deliverable => deliverable?.gig && deliverable.postId === post.id
      );
      const gigs = deliverablesWithGigs?.reduce((acc: Gig[], item: OfferDeliverable) => {
        acc = acc ?? [];
        if (item.gig) {
          acc.push(item.gig);
        }
        return acc;
      }, []);

      if (gigs?.length > 0) {
        acc = acc ?? [];
        acc.push(gigs);
      }
      return acc;
    }, [])
    .flat();

  const gigsGroupedByState = groupBy(availableGigs, 'state');

  const hasAwaitingSubmission = offersForPost.some((offer: Offer) =>
    offer.deliverables?.some(deliverable => deliverable?.postId === post.id && !deliverable?.gig)
  );

  return (
    <Section
      title={renderTitle()}
      secondary={
        canEditCampaign && (
          <Link to={location => `${location.pathname}/${post.id}/edit`}>
            <Button variant="outlined" color="secondary">
              <Typography variant="button" color="textSecondary">
                Edit
              </Typography>
            </Button>
          </Link>
        )
      }
    >
      <HideInDemoMode>
        <Box marginTop="22px">
          <PostReport
            campaign={campaign}
            post={post}
            postIndex={postIndex}
            open={reportOpen}
            toggle={toggleReport}
          />
        </Box>
      </HideInDemoMode>

      <Grid container spacing={4}>
        {Object.keys(gigsGroupedByState).map(state => (
          <Grid key={state} container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box maxWidth="50%" marginY={2}>
                <Typography variant="body2" className={classes.stateText}>
                  {state.replaceAll('_', ' ')}
                </Typography>
                <Divider />
              </Box>
            </Grid>
            {gigsGroupedByState[state].map((gig: Gig) => (
              <Grid key={gig.id} item>
                <GigMedia
                  feature="influencer"
                  gig={gig}
                  influencer={gig.influencer}
                  showEngagement={gig.state === 'approved'}
                  url={`${campaignUrl(campaign)}/posts/${post.id}/gig/${gig.id}`}
                  {...getOverlayText(gig)}
                />
              </Grid>
            ))}
          </Grid>
        ))}

        {hasAwaitingSubmission && (
          <Grid item xs={12}>
            <Box maxWidth="50%" marginY={2}>
              <Typography variant="body2">Awaiting submission</Typography>
              <Divider />
            </Box>
            <Grid container item xs={12} spacing={2}>
              {offersForPost.map(offer =>
                offer.deliverables?.map(
                  deliverable =>
                    deliverable?.postId === post.id &&
                    !deliverable?.gig && (
                      <Grid key={deliverable?.id} item xs={12} sm={4}>
                        <Box
                          paddingX={2}
                          paddingY={1}
                          border="1px solid #E6E7E8"
                          borderRadius="4px"
                          alignContent="left"
                        >
                          <InfluencerBox influencer={offer.influencer!} />
                        </Box>
                      </Grid>
                    )
                )
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

const OffersForPost = gql`
  query offersForPost($id: UUIDString!, $submitted: Boolean) {
    offersForPost(id: $id, submitted: $submitted) {
      count
      edges {
        node {
          id
          accepted
          influencer {
            id
            profilePicture
            email
            fullName
            instagramAccount {
              id
              username
              followers
            }
            tiktokAccount {
              id
              username
              followers
            }
            youtubeChannel {
              id
              customUrl
              subscriberCount
            }
            twitchChannel {
              id
              url
              followers
            }
          }
          deliverables {
            id
            postId
            gig {
              id
              state
              ...GigMediaFields
            }
          }
        }
      }
    }
  }
  ${GigMedia.fragments.gig}
`;

const useStyles = makeStyles({
  stateText: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
});

export default CampaignPost;
