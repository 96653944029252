import {Checkbox, Grid, Typography, FormLabel, CheckboxProps} from '@material-ui/core';
import React from 'react';
import {Control} from 'react-redux-form';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import withCustomControl from '../../../../../../components/Widgets/Forms/CustomControl';
import {useGlobalStyles} from '../../../../../App/App/style.integration';
import {useStyles} from './styles';

type CustomCheckboxProps = {
  id: string;
  label: string;
  description?: string;
  roman?: boolean;
  'data-testid'?: string;
} & Partial<CheckboxProps>;

export const CustomCheckbox = (props: CustomCheckboxProps) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Grid container>
      <Grid item xs={1}>
        <Checkbox
          id={props.id}
          data-testid={props['data-testid']}
          name={props.name}
          checked={props.checked}
          disabled={props.disabled}
          color="default"
          className={classes.control}
          icon={undefined}
          checkedIcon={<CheckBoxOutlinedIcon className={classes.blackTextColor} />}
          inputProps={{
            'aria-labelledby': `${props.name}-label`,
          }}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
        />
      </Grid>

      {props?.icon && (
        <Grid item>
          <div className={classes.pill}>{props.icon}</div>
        </Grid>
      )}

      <Grid item xs={10}>
        <FormLabel id={`${props.name}-label`} htmlFor={props.id}>
          <Typography
            variant="body2"
            className={`${props.roman ? '' : globalClasses.body2SemiBold} ${classes.label}`}
          >
            {props.label}
          </Typography>
        </FormLabel>
        <Typography variant="caption" component="p">
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const ControlCheckbox = withCustomControl<CustomCheckboxProps>(props => (
  <Control.checkbox component={CustomCheckbox} {...props} />
));
