import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';

import {
  AdminInfluencerCampaigns,
  AdminInfluencerEvents,
  AdminInfluencerGig,
  AdminInfluencerGigs,
  AdminInfluencerInsights,
  AdminInfluencerInstagramPosts,
  AdminInfluencerSearch,
  AdminInfluencerSettings,
} from '../scenes/Admin/Influencer';
import AdminInfluencerWrapper from '../scenes/Admin/InfluencerWrapper';

/**
 * @path `/influencers`
 */
export const Influencers = (): JSX.Element => {
  const match = useRouteMatch();
  const {path} = match;
  return (
    <Switch>
      <Route exact path={`${path}`} component={AdminInfluencerSearch} />
      <Route path={`${path}/:influencerId`}>
        <InfluencerId />
      </Route>
    </Switch>
  );
};

/**
 * @path `/influencers/:influencerId`
 */
export const InfluencerId = (): JSX.Element => {
  const {path} = useRouteMatch();
  return (
    <AdminInfluencerWrapper>
      <Switch>
        <Route path={`${path}/gigs`}>
          <AdminInfluencerGigs>
            <Route path={`${path}/gigs/:gigId`} component={AdminInfluencerGig} />
          </AdminInfluencerGigs>
        </Route>
        <Route path={`${path}/instagram-posts`} component={AdminInfluencerInstagramPosts} />
        <Route path={`${path}/campaigns`} component={AdminInfluencerCampaigns} />
        <Route path={`${path}/events`} component={AdminInfluencerEvents} />
        <Route path={`${path}/insights`} component={AdminInfluencerInsights} />
        <Route path={`${path}/settings`} component={AdminInfluencerSettings} />

        <Redirect from={`${path}`} to={`${path}/gigs`} />
      </Switch>
    </AdminInfluencerWrapper>
  );
};
