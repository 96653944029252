import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {makeStyles} from '@material-ui/styles';

interface Props {
  onCancel: () => void;
  onSubmit: (values: {reason: string}) => void;
}

const ReportView: React.FC<Props> = ({onCancel, onSubmit}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const formik: Record<any, any> = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: yup.object({
      reason: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    setShowDialog(true);
  };

  const handleCancelDialog = () => setShowDialog(false);

  const renderDialog = () => (
    <Dialog open={showDialog} onClose={handleCancelDialog}>
      <DialogTitle>Are you sure you want to report this issue?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container direction="column" spacing={3}>
            <Grid item xs>
              <Typography variant="subtitle1">Details:</Typography>
              <Typography variant="body2">"{formik.values.reason}"</Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" size="large" onClick={handleCancelDialog}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              className={classes.danger}
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<Icon icon="material-symbols:warning" />}
              onClick={() => onSubmit(formik.values)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="reason"
              name="reason"
              label="Please provide details?"
              value={formik.values.reason}
              aria-describedby={'reason'}
              error={formik.touched.reason}
              variant="outlined"
              minRows={4}
              maxRows={10}
              fullWidth
              multiline
              required
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item container spacing={4} justifyContent="flex-end">
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              size="large"
              fullWidth
              startIcon={<Icon icon="icon-park:return" />}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<Icon icon="material-symbols:check" />}
              onClick={formik.submitForm}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {renderDialog()}
    </form>
  );
};

const useStyles = makeStyles({
  danger: {
    color: 'red',
    borderColor: 'red',
  },
});

export default ReportView;
