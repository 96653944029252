import React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Icon} from '@iconify/react';
import clsx from 'clsx';

import {black80, bold, sizeCaption, sizeLarge, sizeBody2} from '../../../consts/brand.integration';
import {CustomCard, Tooltip} from '..';
import {numberWithCommas} from '../../../consts/utilities';

interface Props {
  value: number | string | boolean;
  tooltip?: string;
  color?: string;
  icon?: string;
  size?: 'small' | 'large';
  label?: string;
  isFullyReserved?: boolean;
}

interface StyleProps {
  color?: string;
  size?: 'small' | 'large';
}

const formatNumber = (value: number, label?: string) => `${numberWithCommas(value)} ${label}`;

const formatBoolean = (value: boolean, isFullyReserved?: boolean) => {
  if (isFullyReserved) {
    return value ? 'Fully Reserved' : 'Not Fully Reserved';
  }
  return value ? 'True' : 'False';
};

const formatValue = (
  value: number | string | boolean,
  label?: string,
  isFullyReserved?: boolean
) => {
  switch (typeof value) {
    case 'number':
      return formatNumber(value, label);
    case 'boolean':
      return formatBoolean(value, isFullyReserved);
    case 'string':
    default:
      return value || '';
  }
};

const DataItem: React.FC<Props> = ({value, tooltip, color, icon, size, label, isFullyReserved}) => {
  const classes = useStyles({color, size});

  const displayValue = formatValue(value, label, isFullyReserved);

  return (
    <div className={clsx(classes.root)}>
      <Tooltip overlay={<span>{tooltip}</span>} mouseEnterDelay={0.3} placement="bottom">
        <Icon icon={displayValue && icon} />
        <span>{displayValue}</span>
      </Tooltip>
    </div>
  );
};

interface CampaignProps {
  campaign: {
    state?: string;
    isFullyReserved?: boolean;
    followersTotal?: number;
    likes?: number;
    impressionsTotal?: number;
    reachTotal?: number;
    archived?: boolean;
    brandSafety?: boolean;
    canBeCompleted?: boolean;
  };
  size?: 'small' | 'large';
  color?: string;
}

const CampaignData: React.FC<CampaignProps> = ({campaign, size, color}) => {
  const classes = useStyles();
  const dataItems = [
    {
      value: campaign.state,
      icon: campaign.state === 'completed' ? 'mdi:check-circle' : 'mdi:progress-clock',
      tooltip: 'Campaign Status',
    },
    {
      value: campaign.isFullyReserved,
      icon: campaign.isFullyReserved ? 'mdi:account-check' : 'mdi:account-clock',
      tooltip: 'Reservation',
    },
    {
      value: campaign.followersTotal,
      icon: 'mdi:account-group',
      tooltip: 'Total Followers',
      label: 'followers',
    },
    {
      value: campaign.likes,
      icon: 'mdi:thumb-up',
      tooltip: 'Total Likes',
      label: 'likes',
    },
    {
      value: campaign.impressionsTotal,
      icon: 'mdi:eye',
      tooltip: 'Total Impressions',
      label: 'impressions',
    },
    {
      value: campaign.reachTotal,
      icon: 'mdi:account-group',
      tooltip: 'Total Reach',
      label: 'users',
    },
  ];
  return (
    <CustomCard>
      <div className={clsx(classes.root)}>
        {dataItems.map((item, index) => {
          if (item.value === 0) return null;
          return (
            <DataItem
              key={index}
              value={item.value}
              icon={item.icon}
              tooltip={item.tooltip}
              label={item.label}
              size={size}
              color={color}
            />
          );
        })}
      </div>
    </CustomCard>
  );
};

const useStyles = makeStyles<StyleProps>(() =>
  createStyles({
    root: (props: StyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      color: props.color || black80,
      fontWeight: bold,
      fontSize: props.size === 'large' ? sizeBody2 : sizeCaption,
      marginRight: props.size === 'large' ? '20px' : '5px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
      },
      '& svg': {
        height: props.size === 'large' ? '24px' : sizeLarge,
        width: props.size === 'large' ? '24px' : sizeLarge,
        color: props.color || black80,
        marginRight: '5px',
      },
      '&:not(:first-child)': {
        borderLeft: `1px solid ${black80}`,
        paddingLeft: props.size === 'large' ? '20px' : '5px',
        maxHeight: '15px',
      },
    }),
  })
);

export default CampaignData;
