import {gql, useMutation, useQuery} from '@apollo/client';
import {Box, Container, Divider, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {useParams} from 'react-router';
import {Alert} from '@material-ui/lab';

import {DocumentTitle, LoaderDots} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

import AdvertiserTabs from '../AdvertiserTabs';
import AdvertiserCompetitorsTable from './AdvertiserCompetitorsTable';
import AddAdvertiserCompetitorModal from './AddAdvertiserCompetitorModal';

const AdvertiserCompetitors: React.FC = () => {
  const classes = useStyles();
  const params = useParams<{advertiser: string}>();
  const {loading, error, data} = useQuery(AdvertiserCompetitorsQuery, {
    variables: {
      advertiserDomain: params.advertiser,
    },
  });
  const [addAdvertiserCompetitor] = useMutation(AddAdvertiserCompetitorMutation);
  const [removeAdvertiserCompetitor] = useMutation(RemoveAdvertiserCompetitorMutation);

  const onAddAdvertiserCompetitor = async (
    competitorId: string,
    bidirectional: boolean = false
  ) => {
    const response = await addAdvertiserCompetitor({
      variables: {
        advertiserId: data?.advertiser.id,
        competitorId,
        bidirectional,
      },
      refetchQueries: ['AdvertiserCompetitors'],
    });
    return response;
  };

  const onRemoveAdvertiserCompetitor = async (competitorId: string) => {
    await removeAdvertiserCompetitor({
      variables: {
        advertiserId: data?.advertiser.id,
        competitorId,
      },
      refetchQueries: ['AdvertiserCompetitors'],
    });
  };

  return (
    <>
      <DocumentTitle title={getPageTitle(`${data?.advertiser.name} - Competitors`)} />
      <Container>
        <AdvertiserTabs params={params} />
        {loading && <LoaderDots />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {data?.advertiser && (
          <Grid container spacing={4}>
            <Grid container item xs={12}>
              <Grid item xs>
                <Typography variant="h3">Competitors</Typography>
              </Grid>
              <Grid item>
                <AddAdvertiserCompetitorModal
                  advertiserId={data.advertiser.id}
                  onAddCompetitor={onAddAdvertiserCompetitor}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AdvertiserCompetitorsTable
                data={data.advertiser.competitors}
                onRemoveCompetitor={onRemoveAdvertiserCompetitor}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    margin: '10px auto 20px',
  },
}));

const AdvertiserCompetitorsQuery = gql`
  query AdvertiserCompetitors($advertiserDomain: String!) {
    advertiser(domain: $advertiserDomain) {
      id
      name
      competitors {
        id
        name
        domain
        profilePicture
        campaigns {
          id
        }
        primaryRegion {
          id
          countryCode
          country
        }
        socialAccounts {
          ... on InstagramAccount {
            id
            username
            profilePicture
            followers
          }
          ... on TikTokAccount {
            id
            username
            originalCover
            followers
          }
        }
      }
    }
  }
`;

const AddAdvertiserCompetitorMutation = gql`
  mutation AddAdvertiserCompetitorMutation(
    $advertiserId: UUIDString!
    $competitorId: UUIDString!
    $bidirectional: Boolean
  ) {
    addAdvertiserCompetitor(
      advertiserId: $advertiserId
      competitorId: $competitorId
      bidirectional: $bidirectional
    ) {
      ok
    }
  }
`;

const RemoveAdvertiserCompetitorMutation = gql`
  mutation RemoveAdvertiserCompetitorMutation(
    $advertiserId: UUIDString!
    $competitorId: UUIDString!
  ) {
    removeAdvertiserCompetitor(advertiserId: $advertiserId, competitorId: $competitorId) {
      ok
    }
  }
`;

export default AdvertiserCompetitors;
