import {Box, IconButton, TableCell, Tooltip, Typography} from '@material-ui/core';
import {DeleteOutline} from '@material-ui/icons';
import emojiFlag from 'emoji-flag';
import React from 'react';
import {Link} from 'react-router-dom';

import {ProfilePicture, SortableTable, TableZebraRow} from 'components/Widgets';

interface Props {
  data: Advertiser[];
  onRemoveCompetitor: (id: string) => void;
}

const AdvertiserCompetitorsTable: React.FC<Props> = ({data, onRemoveCompetitor}) => {
  const headCells = [
    {id: 'name', label: 'Name'},
    {id: 'campaigns', label: 'Campaigns Total', numeric: true},
    {id: 'followers', label: 'Followers Total', numeric: true},
    {id: 'region', label: 'Region', align: 'center'},
    {id: 'actions', label: ''},
  ];

  const calcCompetitorFollowersTotal = (
    socialAccounts?: readonly Maybe<AdvertiserSocialProfile>[] | null
  ) =>
    socialAccounts?.reduce((acc, socialAccount) => {
      acc += socialAccount?.followers ?? 0;
      return acc;
    }, 0);

  return (
    <SortableTable
      ariaLabel="competitors table"
      rows={data}
      headCells={headCells}
      sortMetric="followers"
      emptyMessage="There are no competitors to show."
      rowCount={25}
      renderRow={row => (
        <TableZebraRow key={row.id}>
          <TableCell component="th" scope="row">
            <Box display="flex">
              <ProfilePicture src={row.profilePicture ?? ''} size={40} />
              <Box sx={{marginLeft: 10, marginY: 'auto'}}>
                <Link to={`/brands/${row.domain}`}>
                  <Typography variant="h6">{row.name}</Typography>
                </Link>
              </Box>
            </Box>
          </TableCell>

          <TableCell align="center">
            <Typography variant="body1">{row.campaigns?.length.toLocaleString()}</Typography>
          </TableCell>

          <TableCell align="center">
            <Typography variant="body1">
              {calcCompetitorFollowersTotal(row.socialAccounts)?.toLocaleString() ?? '-'}
            </Typography>
          </TableCell>

          <TableCell align="center">
            <Typography variant="body1">
              {row.primaryRegion?.country} {emojiFlag(row.primaryRegion?.countryCode)}
            </Typography>
          </TableCell>

          <TableCell align="center">
            <Tooltip title="Remove" arrow>
              <IconButton aria-label="Back" onClick={() => onRemoveCompetitor(row.id!)}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableZebraRow>
      )}
    />
  );
};

export default AdvertiserCompetitorsTable;
