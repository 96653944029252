import {IAdvertiserIndustry} from '../components/Widgets/IndustryFilter/types';

export const getIndustryById = (
  industries: IAdvertiserIndustry[],
  industryId: string
): IAdvertiserIndustry | void => {
  for (const parentIndustry of industries) {
    for (const industry of parentIndustry.children) {
      if (industry.id == industryId) return industry;
    }
  }
};
