import {AdvertiserIndustryEntity} from '../../../Advertiser/AdvertiserSettings/types';
import {performanceSettingsValues} from '../CampaignPerformance/helper';

export interface ICampaignSetupProps {
  advertiserIndustries: Array<AdvertiserIndustryEntity>;
  campaignManagers: any;
  owners: any;
  campaign: Campaign;
  paymentTerms: {name: string; value: string | number}[];
  loading: boolean;
  error: any;
  classes?: any;
  updateCampaign: (data: any) => void;
  archiveCampaign: () => void;
  showCampaignSoldForDifferentPriceThenRateCardOption: boolean;
  useYoutubeIntegration: boolean;
  useTwitchIntegration: boolean;
  showCampaignPerformanceSettings: boolean;
}

type Target = {
  targetInstagram?: number;
  targetTiktok?: number;
  targetYoutube?: number;
  targetTwitch?: number;
  targetContent?: number;
  targetOverall?: number;
};

export enum CampaignKpiMetric {
  Impressions = 'impressions',
  Engagements = 'engagements',
  Reach = 'reach',
}

export type CampaignKpiEntry = {
  metric: CampaignKpiMetric;
  value: number;
};

export interface ICampaignSetupState {
  shippingRequired?: boolean;
  brandMatch?: boolean;
  proBono?: boolean;
  rewardModel?: string;
  marketSlug?: string;
  customRewardUnits?: string | number;
  withPremiumPrice?: boolean;
  hasCustomReward?: boolean;
  listPrice?: number | any;
  price?: number | any;
  withPremiumPriceId: string;
  brandSafetyId: string;
  brandMatchId: string;
  proBonoId: string;
  shippingId: string;
  requireInsightId: string;
  hasNdaId: string;
  photos?: any;
  pictures?: any;
  prompts?: any;
  openArchiveModal: boolean;
  industry?: string;
  hubspotDealId?: string;
  mondayBoardId?: string;
  paymentTerms: string;
  target: Target;
  organicPerformanceMetrics?: Array<(typeof performanceSettingsValues)['organic'][number]>;
  paidPerformanceMetrics?: Array<(typeof performanceSettingsValues)['paid'][number]>;
  campaignKpis?: CampaignKpiEntry[];
  cpmBenchmark: number | any;
}
