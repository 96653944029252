import {Grid, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';

import UserCard from './UserCard';
import {Media, MediaContainer} from '../../../../../components/Widgets';
import {GigCaption} from '../../../../../components/Campaign';
import {black20, nearWhite} from '../../../../../consts/brand.integration';

interface Props {
  historyItem: HistoryInterface;
  gig: Gig;
}

const CardSubmitted: React.FC<Props> = ({historyItem}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
      <Grid item xs style={{margin: 'auto'}}>
        <MediaContainer width={293} height={293}>
          <Media media={historyItem.submission.media} width={293} height={293} />
        </MediaContainer>
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={classes.feedback}>
          <Typography variant="subtitle1">Caption:</Typography>
          <Typography variant="body2" className={classes.gigCaption}>
            <GigCaption {...historyItem.submission} />
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  gigCaption: {
    padding: '0rem 4rem',
  },
  feedback: {
    margin: '0 1rem',
    padding: '1rem',
    backgroundColor: nearWhite,
    border: `1px dashed ${black20}`,
  },
});

export default CardSubmitted;
