import {gql} from '@apollo/client';

import {Gig} from 'services/fragments';

export const GigQuery = gql`
  query GigQuery($gigId: UUIDString!) {
    gig(id: $gigId) {
      id
      created
      state
      isVerified
      post {
        id
        timezone
      }
      submission {
        caption
        mentions
        hashtags
        media {
          ...mediaResultFields
        }
      }
      instagramContent {
        __typename
        id
        created
        followers
        media {
          ...mediaResultFields
        }
        ... on InstagramStory {
          storyFrames {
            id
            posted
            swipeUpLink
            locations
            mentions
            hashtags
            instagramStoryId
            media {
              ...mediaResultFields
            }
          }
        }
      }
    }
  }
  ${Gig.mediaPreview}
`;

export const StoryFramesQuery = gql`
  query StoryFramesQuery(
    $gigId: UUIDString!
    $cursor: String
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    storyFramesForGig(id: $gigId, first: 20, after: $cursor, dateFrom: $fromDate, dateTo: $toDate) {
      edges {
        node {
          id
          posted
          swipeUpLink
          locations
          mentions
          hashtags
          instagramStoryId
          media {
            ...mediaResultFields
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
  }
  ${Gig.mediaPreview}
`;

export const LinkStoryFrameToGig = gql`
  mutation LinkStoryFrameToGig($frameId: UUIDString!, $gigId: UUIDString!) {
    linkStoryFrameToGig(id: $frameId, gigId: $gigId) {
      storyFrame {
        id
        instagramStoryId
      }
    }
  }
`;

export const UnlinkStoryFrameFromGig = gql`
  mutation UnlinkStoryFrameFromGig($frameId: UUIDString!) {
    unlinkStoryFrameFromGig(id: $frameId) {
      storyFrame {
        id
        instagramStoryId
      }
    }
  }
`;

export const VerifyGigMutation = gql`
  mutation VerifyGigMutation($gigId: UUIDString!) {
    verifyGig(id: $gigId) {
      ok
      gig {
        id
        isVerified
      }
    }
  }
`;
