import React from 'react';
import {Link} from 'react-router-dom';
import {gql, useMutation} from '@apollo/client';
import {Button, Grid} from '@material-ui/core';

import {RequiresPermission, Modal} from 'components/Widgets';

import {REPORTED, REQUIRES_RESUBMIT, REVIEWED, SUBMITTED} from 'consts/variables';
import {pickStoryFrameUrl} from 'consts/urls';
import {isLive} from 'consts/gigHelper';
import {getEnvLocationURL} from 'services/api';

import {
  GigRevertReport,
  GigUnsubmit,
  EditSubmissionCaption,
  GigReport,
  GigDismissReport,
  GigReject,
  GigForceApprove,
} from '..';
import GigLinkToPlatform from '../CampaignGig/GigLinkToPlatform';
import {getSocialPlatformNameByPostType} from '../../../utils/gigUtils';
import {
  CAMPAIGN_MANAGER,
  MANAGE_INFLUENCERS,
  REVIEW_GIG,
  VIEW_GIG_HISTORY,
} from '../../../consts/permissions';

interface Props {
  gig: Gig;
  reportGig: () => void;
  toggler: Record<any, any>;
}

const GigActionsMenu: React.FC<Props> = ({gig, toggler}) => {
  const [requestResubmission] = useMutation(RequestResubmission);
  const [updateSubmissionCaption] = useMutation(UpdateLatestSubmissionCaption);
  const [approveGig] = useMutation(ApproveGigMutation);
  const brandId = gig?.post?.campaign?.advertiser?.domain;
  const campaignId = gig?.post?.campaign?.id;
  const influencerId = gig?.influencer?.id;
  const influencerOffer = `/brands/${brandId}/${campaignId}/influencers?q=/influencers/${influencerId}`;

  const onRequestResubmission = (reason: string, explanation: string) =>
    requestResubmission({
      variables: {
        id: gig.id,
        explanation,
        reason,
      },
      optimisticResponse: {
        requestGigResubmission: {
          __typename: 'RequestGigResubmission',
          ok: true,
          gig: {
            __typename: 'Gig',
            id: gig.id,
            state: REQUIRES_RESUBMIT,
            rejectReason: reason,
          },
        },
      },
    });

  const onUpdateSubmissionCaption = (caption: string) =>
    updateSubmissionCaption({
      variables: {
        id: gig.id,
        caption,
      },
      optimisticResponse: {
        updateLatestSubmissionCaption: {
          __typename: 'UpdateLatestSubmissionCaption',
          ok: true,
          gig: {
            __typename: 'Gig',
            id: gig.id,
            submission: {
              __typename: 'Submission',
              caption,
            },
          },
        },
      },
    });

  // Gig force approval modal callback
  const confirmForceApproveGig = () => {
    return approveGig({
      variables: {
        gigId: gig.id,
      },
      optimisticResponse: {
        approveGig: {
          __typename: 'approveGig',
          ok: true,
          gig: {
            __typename: 'Gig',
            id: gig.id,
            state: gig.state,
            approver: gig.approver,
          },
        },
      },
    });
  };

  return (
    <Modal id="gig-actions-menu" modalToggler={toggler} fullWidth maxWidth="xs" showClose>
      <Grid container direction="column" alignItems="center" spacing={4}>
        {(gig.state === SUBMITTED || gig.state === REPORTED) && (
          <Grid item>
            <GigUnsubmit requestResubmission={onRequestResubmission} />
          </Grid>
        )}

        {gig.canBeReported && (
          <Grid item>
            <GigReport gig={gig} />
          </Grid>
        )}

        <RequiresPermission permission={MANAGE_INFLUENCERS}>
          {gig.state === REPORTED && (
            <>
              <Grid item>
                <GigDismissReport gig={gig} />
              </Grid>
              <Grid item>
                <GigRevertReport gig={gig} />
              </Grid>
              <Grid item>
                <GigReject gig={gig} />
              </Grid>
            </>
          )}
        </RequiresPermission>

        {isLive(gig) ? (
          <Grid item>
            <Button
              variant="text"
              fullWidth
              rel="noopener noreferrer"
              href={gig.instagramContent?.link}
              target="_blank"
            >
              {`Open on ${getSocialPlatformNameByPostType(gig.post?.postType!)}`}
            </Button>
          </Grid>
        ) : gig.post?.postType !== 'story' ? (
          <RequiresPermission permission={REVIEW_GIG}>
            <Grid item>
              <GigLinkToPlatform gig={gig} />
            </Grid>
          </RequiresPermission>
        ) : (
          <RequiresPermission permission={REVIEW_GIG}>
            {gig.instagramContent && (
              <Grid item>
                <Button variant="text" fullWidth>
                  <Link to={pickStoryFrameUrl(gig.id)}>Choose story frames</Link>
                </Button>
              </Grid>
            )}
          </RequiresPermission>
        )}

        <RequiresPermission permission={REVIEW_GIG}>
          {gig.insight && (
            <Grid item>
              <Button variant="text" fullWidth>
                <Link to={`/insight/${gig.insight.id}`}>View insight</Link>
              </Button>
            </Grid>
          )}
        </RequiresPermission>

        <RequiresPermission permission={MANAGE_INFLUENCERS}>
          <Grid item>
            <Button variant="text" fullWidth>
              <Link to={influencerOffer}>View offer</Link>
            </Button>
          </Grid>
        </RequiresPermission>

        {(gig.state === SUBMITTED || gig.state === REVIEWED) && (
          <RequiresPermission permission={REVIEW_GIG}>
            <Grid item>
              <EditSubmissionCaption
                updateSubmissionCaption={onUpdateSubmissionCaption}
                caption={gig.submission?.caption!}
              />
            </Grid>
          </RequiresPermission>
        )}

        {gig.isPosted && (
          <Grid item>
            <Button
              variant="text"
              fullWidth
              href={`https://download.${getEnvLocationURL()}/download/${
                gig.post?.campaign?.reportToken
              }/${gig.id}`}
              target="_blank"
            >
              Download media
            </Button>
          </Grid>
        )}
        {gig.submission && gig.post?.campaign?.submissionsToken && (
          <Grid item>
            <Button
              variant="text"
              fullWidth
              href={`https://download.${getEnvLocationURL()}/submission/${
                gig.post.campaign.submissionsToken
              }/${gig.id}`}
              target="_blank"
            >
              Download original submission
            </Button>
          </Grid>
        )}

        <RequiresPermission permission={VIEW_GIG_HISTORY}>
          <Grid item>
            <Button variant="text" fullWidth>
              <Link to={`/history/${gig.id}`}>View history</Link>
            </Button>
          </Grid>
        </RequiresPermission>

        {gig.post?.campaign?.brandSafety &&
          ['submitted', 'reviewed'].includes(String(gig.state)) && (
            <RequiresPermission permission={CAMPAIGN_MANAGER}>
              <Grid item>
                <GigForceApprove
                  brandName={gig.post.campaign.advertiser?.name}
                  confirmForceApprove={confirmForceApproveGig}
                />
              </Grid>
            </RequiresPermission>
          )}
      </Grid>
    </Modal>
  );
};

const RequestResubmission = gql`
  mutation RequestResubmission($id: UUIDString!, $reason: String!, $explanation: String) {
    requestGigResubmission(id: $id, reason: $reason, explanation: $explanation) {
      ok
      gig {
        id
        state
        rejectReason
      }
    }
  }
`;

const UpdateLatestSubmissionCaption = gql`
  mutation UpdateLatestSubmissionCaption($id: UUIDString!, $caption: String!) {
    updateLatestSubmissionCaption(id: $id, caption: $caption) {
      ok
      gig {
        id
        submission {
          caption
        }
      }
    }
  }
`;

const ApproveGigMutation = gql`
  mutation ApproveGigMutation($gigId: UUIDString!) {
    approveGig(id: $gigId) {
      ok
      gig {
        id
        state
        approver {
          id
          fullName
        }
      }
    }
  }
`;

export default GigActionsMenu;
