import React from 'react';

const Refresh = ({color = 'black', ariaLabel = ''}) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7108:66127)">
      <path
        d="M15.8335 2.66675V6.66675H11.8335"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1665 13.3333V9.33325H5.1665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.83984 5.99989C3.17795 5.04441 3.75259 4.19016 4.51015 3.51683C5.2677 2.84351 6.18348 2.37306 7.17203 2.14939C8.16058 1.92572 9.18967 1.95612 10.1633 2.23774C11.1369 2.51936 12.0233 3.04303 12.7398 3.75989L15.8332 6.66655M1.1665 9.33322L4.25984 12.2399C4.97634 12.9567 5.86275 13.4804 6.83638 13.762C7.81 14.0437 8.8391 14.0741 9.82765 13.8504C10.8162 13.6267 11.732 13.1563 12.4895 12.4829C13.2471 11.8096 13.8217 10.9554 14.1598 9.99989"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7108:66127">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Refresh;
