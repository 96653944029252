import {Button, Typography} from '@material-ui/core';
import React from 'react';

import {IItemFilter} from './types';

const ItemFilter: React.FC<IItemFilter> = ({className, title, handleClick}) => {
  return (
    <Button
      disableFocusRipple
      disableRipple
      className={className}
      aria-label={title}
      onClick={handleClick}
    >
      <Typography style={{fontSize: 13}} variant="body2">
        {title}
      </Typography>
    </Button>
  );
};

export default ItemFilter;
