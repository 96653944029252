import React from 'react';
import PropTypes from 'prop-types';

import {
  ColumnFilter,
  ColumnHeader,
  RadioButtonFilter,
  ResetButtonFilter,
  DateFilter,
} from 'components/Widgets/FilterWell';

import styles from './style.css';

const AdminInfluencerEventsFilterWell = ({changeFilters, resetFilters, filters}) => (
  <div className={styles.root}>
    <ColumnFilter>
      <ColumnHeader header="Type" />
      <RadioButtonFilter
        label="All"
        filterName="type"
        changeFilters={changeFilters}
        filters={filters}
        value={undefined}
      />
      <RadioButtonFilter
        label="Influencer events"
        filterName="type"
        changeFilters={changeFilters}
        filters={filters}
        value="only_influencer_events"
      />
      <RadioButtonFilter
        label="Gigs"
        filterName="type"
        changeFilters={changeFilters}
        filters={filters}
        value="only_gig_events"
      />
      <RadioButtonFilter
        label="Offer events"
        filterName="type"
        changeFilters={changeFilters}
        filters={filters}
        value="only_offer_events"
      />
    </ColumnFilter>

    <ColumnFilter>
      <ColumnHeader header="Date" />
      <div className={styles.dates}>
        <DateFilter
          label="From"
          filterName="from"
          changeFilters={changeFilters}
          selected={filters.from}
          maxDate={filters.to}
          isClearable
          errorMessage="Date must be in the past"
          dateFormat="DD/MM/YYYY"
          small
          timezone="Atlantic/Reykjavik"
        />
        <DateFilter
          label="To"
          filterName="to"
          errorMessage="Date must be after `From` date"
          changeFilters={changeFilters}
          selected={filters.to}
          minDate={filters.from}
          isClearable
          dateFormat="DD/MM/YYYY"
          small
          timezone="Atlantic/Reykjavik"
        />
      </div>
    </ColumnFilter>

    <ResetButtonFilter resetFilters={resetFilters} />
  </div>
);

AdminInfluencerEventsFilterWell.propTypes = {
  changeFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default AdminInfluencerEventsFilterWell;
