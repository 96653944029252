import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/styles';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Box, Button, Grid, Hidden, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Lock} from '@material-ui/icons';
import Check from '@material-ui/icons/Check';

import {LoaderDots, Prompt} from 'components/Widgets';
import {CampaignState} from 'components/Widgets/ProgressBar';

import {black, white} from 'consts/brand.integration';
import {EDIT_CAMPAIGN} from 'consts/permissions';
import useRequiresPermission from 'hooks/useRequiresPermission';
import {Campaign} from 'services/fragments';

import CampaignProgress from './components/CampaignProgress';
import InfluenscoreChart from 'components/Widgets/InfluenscoreChart/InfluenscoreChart';
import {useDataServiceApi} from 'hooks/useDataServiceApi';
import useCurrentUser from 'hooks/useCurrentUser';
import {CampaignInfluenscoreData} from 'hooks/useDataServiceApi/types';

interface Props {
  title: string;
  campaignId?: string;
  showInfluenscore?: boolean;
  showProgress?: boolean;
  showCompleteCampaign?: boolean;
}

const CampaignHeader: React.FC<Props> = ({
  title,
  campaignId,
  showInfluenscore,
  showProgress,
  showCompleteCampaign,
}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const canEditCampaign = useRequiresPermission(EDIT_CAMPAIGN);
  const {loading, error, data} = useQuery(CampaignQuery, {
    variables: {
      id: campaignId,
    },
    skip: !campaignId,
  });
  const [completeCampaign, {error: completeCampaignError}] = useMutation(CompleteCampaignMutation, {
    variables: {
      id: campaignId,
    },
    refetchQueries: ['CampaignQuery'],
  });

  const {
    error: dataApiError,
    loading: dataApiLoading,
    fetchInfluenscoreCampaignV1Beta,
  } = useDataServiceApi();

  const [influenscoreData, setInfluenscoreData] = useState<CampaignInfluenscoreData>();

  const showCompleteCampaignButton =
    showCompleteCampaign &&
    canEditCampaign &&
    (data?.campaign?.state as CampaignState) === 'launched';

  const fetchInfluenscoreData = async () => {
    const response = await fetchInfluenscoreCampaignV1Beta({
      apiToken: currentUser?.takumiDataApiToken ?? '',
      campaignId,
    });
    if (response && response.length > 0) {
      setInfluenscoreData({
        campaign_id: response[0].campaign_id,
        cpe: response[0].s_cpe,
        cpr: response[0].s_cpr,
        cpm: response[0].s_cpm,
        cpf: response[0].s_cpf,
        overall: response[0].influenscore,
      });
    }
  };

  useEffect(() => {
    if (currentUser && showInfluenscore) {
      fetchInfluenscoreData();
    }
  }, [currentUser, showInfluenscore]);

  return (
    <Grid container className={classes.heading} spacing={4}>
      {loading && (
        <Grid item xs={12}>
          <LoaderDots />
        </Grid>
      )}

      <Grid item container sm={12} md={showInfluenscore ? 4 : 8} direction="row">
        <Grid item xs={8}>
          <Typography variant="h3" color="secondary">
            {data?.campaign?.name ? data.campaign.name : 'New Campaign'}
          </Typography>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="right">
              {data?.campaign?.archived && (
                <AlertArchived icon={<Lock />} aria-label="archived">
                  Archived
                </AlertArchived>
              )}
            </Box>
          </Grid>
        </Hidden>
      </Grid>

      {showInfluenscore && (
        <Grid item container sm={12} md={4} justifyContent="center">
          <InfluenscoreChart
            height={250}
            values={influenscoreData ?? ({} as CampaignInfluenscoreData)}
          />
        </Grid>
      )}

      <Grid item container sm={12} md={4} direction="column">
        <Hidden smDown>
          <Grid item>
            <Box display="flex" justifyContent="right">
              {data?.campaign?.archived && (
                <AlertArchived icon={<Lock />} aria-label="archived">
                  Archived
                </AlertArchived>
              )}
            </Box>
          </Grid>
        </Hidden>
        <Grid item>
          {data?.campaign && showProgress && (
            <CampaignProgress
              state={data.campaign.state as CampaignState}
              target={data.campaign.target}
            />
          )}
        </Grid>

        <Grid item>
          {showCompleteCampaignButton && (
            <Box marginTop="1.5rem" textAlign="right">
              <Prompt
                title="Complete campaign"
                body={<p>Are you sure you want to complete this campaign?</p>}
                control={
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Check />}
                    disabled={!data?.campaign?.canBeCompleted}
                  >
                    <Typography variant="button">Complete campaign</Typography>
                  </Button>
                }
                onSubmit={() => completeCampaign()}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {error && <Alert severity="error">{error.message}</Alert>}
        {completeCampaignError && <Alert severity="error">{completeCampaignError.message}</Alert>}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  heading: {
    marginBottom: '35px',
  },
});

const AlertArchived = withStyles({
  root: {
    '&.MuiAlert-standardSuccess': {
      padding: '0 1rem',
      width: '140px',
      border: `1px solid ${black}`,
      color: black,
      backgroundColor: white,
      '& .MuiAlert-icon': {
        color: black,
      },
    },
  },
})(Alert);

const CampaignQuery = gql`
  query campaign($id: UUIDString!) {
    campaign(id: $id) {
      id
      name
      archived
      state
      ...targetFields
      canBeCompleted
    }
  }
  ${Campaign.targetFragment}
`;

const CompleteCampaignMutation = gql`
  mutation completeCampaign($id: UUIDString!) {
    completeCampaign(id: $id) {
      ok
    }
  }
`;

export default CampaignHeader;
