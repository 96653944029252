import React from 'react';
import {Icon} from '@iconify/react';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles} from '@material-ui/core/styles';

interface Props {
  icon?: string;
  data?: string;
  component?: any;
}

const InfluencerData: React.FC<Props> = ({icon, data, component}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {icon && (
        <div className={classes.root}>
          <Icon icon={icon} />
        </div>
      )}
      {data && (
        <Typography variant="body2" component="p" className={classes.data}>
          {data}
        </Typography>
      )}
      <div className={classes.data}>{component && component}</div>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        height: '24px',
        width: '24px',
      },
    },
    data: {
      marginLeft: '10px',
    },
  })
);

export default InfluencerData;
