import {
  ACCESS_ALL_ADVERTISERS,
  ACCESS_ALL_GIGS,
  ACCESS_ALL_INFLUENCERS,
  DEVELOPER,
  MANAGE_INFLUENCERS,
  MANAGE_PAYMENTS,
  MANAGE_USERS,
  CAMPAIGNS_PAGE_ACCESS,
  DASHBOARD_PAGE_ACCESS,
} from '../../../../consts/permissions';

export interface SideBarRoutes {
  [key: string]: {[key: string]: string};
}

export const SIDE_BAR_ROUTES: SideBarRoutes = {
  brands: {name: 'Brands', permissions: ACCESS_ALL_ADVERTISERS},
  dashboard: {name: 'Dashboard', permissions: ACCESS_ALL_ADVERTISERS},
  campaigns: {name: 'Campaigns', permissions: ACCESS_ALL_ADVERTISERS},
  gigs: {name: 'Gigs', permissions: ACCESS_ALL_GIGS},
  influencers: {name: 'Influencers', permissions: ACCESS_ALL_INFLUENCERS},
  signups: {name: 'Signups', permissions: MANAGE_INFLUENCERS},
  users: {name: 'Users', permissions: MANAGE_USERS},
  insights: {name: 'Insights', permissions: MANAGE_INFLUENCERS},
  finances: {name: 'Finances', permissions: MANAGE_PAYMENTS},
  tasktiger: {name: 'Tasks', permissions: DEVELOPER},
};

export const ADVERTISER_SIDE_BAR_ROUTES: SideBarRoutes = {
  dashboard: {name: 'Dashboard', permissions: DASHBOARD_PAGE_ACCESS},
  campaigns: {name: 'Campaigns', permissions: CAMPAIGNS_PAGE_ACCESS},
};

export const ADVERTISER_SIDE_BAR_ACCESS_FIELD: Record<string, string> = {
  dashboard: 'dashboardPage',
  campaigns: 'brandCampaignsPage',
};

export const INTERACTIVE_INSIGHTS_ROUTE = 'insights';

export const DESKTOP_DRAWER_WIDTH = 200;
export const TABLET_DRAWER_WIDTH = 44;
export const DEFAULT_CAMPAIGN_NAME = '00000000-0000-0000-0000-000000000000';
