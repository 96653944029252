import React from 'react';
import {Card, CardProps} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import {whisper} from '../../../consts/brand.integration';

const CustomCard: React.FC<CardProps> = ({children, className, ...rest}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, className)} {...rest}>
      {children}
    </Card>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '1rem',
      boxShadow: 'none',
      backgroundColor: whisper,
    },
  })
);

export default CustomCard;
