import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      marginTop: '87px',
      marginBottom: '96px',
    },
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '36px',
    },
    heading: {
      fontWeight: 600,
      fontSize: '24px',
      margin: 0,
    },
    button: {
      height: '40px',
      border: '1px solid #000',
      borderRadius: 0,
      padding: '12px 16px',
      marginTop: '4px',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  })
);
