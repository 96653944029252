import React from 'react';
import {Card, Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import {GigData, PerformanceData} from '../../Campaign/components/CampaignPerformance/types';
import GigPerformanceTable from '../../Campaign/components/CampaignPerformance/GigData/GigTable';

interface Props {
  gigContent: GigData[];
  gigMetrics: PerformanceData[];
}

const GigMetrics: React.FC<Props> = ({gigContent, gigMetrics}) => {
  const classes = useStyles();

  const gigs = gigContent.map(gig => gig.node);
  const metrics = gigs?.map(gig => {
    const gigMetric = gigMetrics.find(metric => metric.gig_id === gig.id);
    return {...gig, ...gigMetric};
  });

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Posts
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container aria-label="Aggregate Post Metrics" className={classes.spacing}>
        <Grid item xs={12}>
          <Card>
            <GigPerformanceTable metrics={metrics} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default GigMetrics;
