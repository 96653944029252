import type {
  CampaignPerformanceByInfluencerResponse,
  CampaignPerformanceHistoricalDataResponse,
  CampaignInstagramPaidAdPerformanceResponse,
  CampaignTikTokPostPaidAdPerformanceResponse,
} from 'hooks/useDataServiceApi/types';
import {InfluencerTableProps} from 'scenes/Campaign/components/CampaignPerformance/InfluencerData/InfluencerTable';
import {DataPoint} from 'scenes/Campaign/components/CampaignPerformance/PerformanceChart/types';

import {DATA_SERVICE_MODEL_INFO} from '../../Campaign/components/CampaignPerformance/helper';
import {PerformanceData} from '../../Campaign/components/CampaignPerformance/types';

type Metrics = Record<string, any>;

type PlatformAggregatedMetrics = {[platform: string]: AggregatedMetrics};

interface AggregatedMetrics {
  influencer_count: number;
  digital_content_count: number;
  impression_total: number;
  reach_total: number;
  engagement_total: number;
  engagement_rate_by_impressions: number;
  impression_change?: number;
  reach_change?: number;
  engagement_change?: number;
  engagement_rate_by_impressions_percent_change_average?: number;
  video_play_cumulative_total: number;
  cost_per_1000_impressions_average: number;
  click_through_rate_average: number;
  view_through_rate_average: number;
}

export const aggregateMetrics = (metrics: Metrics[]): AggregatedMetrics => {
  const initialMetrics: AggregatedMetrics = {
    influencer_count: 0,
    digital_content_count: 0,
    impression_total: 0,
    reach_total: 0,
    engagement_total: 0,
    engagement_rate_by_impressions: 0,
    impression_change: 0,
    reach_change: 0,
    engagement_change: 0,
    engagement_rate_by_impressions_percent_change_average: 0,
  };

  const aggregated = metrics.reduce<AggregatedMetrics>((acc, curr) => {
    return {
      influencer_count: acc.influencer_count + curr.influencer_count,
      digital_content_count: acc.digital_content_count + curr.digital_content_count,
      impression_total: acc.impression_total + curr.impression_total,
      reach_total: acc.reach_total + curr.reach_total,
      engagement_total: acc.engagement_total + curr.engagement_total,
      engagement_rate_by_impressions:
        acc.engagement_rate_by_impressions + curr.engagement_rate_by_impressions,
      impression_change: acc.impression_change + curr.impression_change,
      reach_change: acc.reach_change + curr.reach_change,
      engagement_change: acc.engagement_change + curr.engagement_change,
      engagement_rate_by_impressions_percent_change_average:
        acc.engagement_rate_by_impressions_percent_change_average +
        curr.engagement_rate_by_impressions_percent_change_average,
    };
  }, initialMetrics);

  const metricsCount = metrics?.length || 0;

  if (metricsCount > 0) {
    aggregated.engagement_rate_by_impressions /= metricsCount;
  }

  return aggregated;
};

export const aggregatePlatformMetrics = (metrics: Metrics[]): any[] => {
  const initialMetrics: AggregatedMetrics = {
    influencer_count: 0,
    digital_content_count: 0,
    impression_total: 0,
    reach_total: 0,
    engagement_total: 0,
    engagement_rate_by_impressions: 0,
  };

  const platformAggregated = metrics.reduce<PlatformAggregatedMetrics>((acc, curr) => {
    const platform = curr.social_media_platform;

    if (!acc[platform]) {
      acc[platform] = {...initialMetrics};
    }

    Object.keys(initialMetrics).forEach(key => {
      acc[platform][key] += curr[key];
    });

    return acc;
  }, {});

  const aggregatedArray = Object.entries(platformAggregated).map(([platform, metrics]) => ({
    platform,
    metrics,
  }));

  return aggregatedArray;
};

export const influencerMetrics = (
  metrics: CampaignPerformanceByInfluencerResponse
): InfluencerTableProps['rows'] => {
  const groupedByInfluencerID = metrics.reduce<
    Record<string, Array<CampaignPerformanceByInfluencerResponse[number]>>
  >(
    (acc, metric) => ({
      ...acc,
      [metric.influencer_id]: [...(acc[metric.influencer_id] ?? []), metric],
    }),
    {}
  );

  return Object.values(groupedByInfluencerID).map(influencersMetrics => {
    const {influencer} = influencersMetrics[0];

    const sumField = (field: keyof (typeof influencersMetrics)[number]) =>
      influencersMetrics.reduce((acc, metrics) => acc + (metrics[field] as number), 0);

    const averageField = (field: keyof (typeof influencersMetrics)[number]) =>
      sumField(field) / influencersMetrics.length;

    const digital_content_count = sumField('digital_content_count');
    const impression_total = sumField('impression_total');
    const reach_total = sumField('reach_total');
    const engagement_total = sumField('engagement_total');
    const engagement_rate_by_impressions = averageField('engagement_rate_by_impressions');

    return {
      influencer_id: influencer.influencer_id,
      full_name: influencer.full_name,
      instagram_handle: influencer.instagram_handle,
      tiktok_handle: influencer.tiktok_handle,
      instagram_profile_url: influencer.instagram_profile_url,
      tiktok_profile_url: influencer.tiktok_profile_url,
      instagram_profile_picture_url: influencer.instagram_profile_picture_url,
      tiktok_profile_picture_url: influencer.tiktok_profile_picture_url,
      follower_total: {
        raw: influencersMetrics[0].follower_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.follower_total.formatter!(
          influencersMetrics[0].follower_total
        ),
      },
      digital_content_count: {
        raw: digital_content_count,
        formatted:
          DATA_SERVICE_MODEL_INFO.campaign_performance.digital_content_count.formatter!(
            digital_content_count
          ),
      },
      impression_total: {
        raw: impression_total,
        formatted:
          DATA_SERVICE_MODEL_INFO.campaign_performance.impression_total.formatter!(
            impression_total
          ),
      },
      reach_total: {
        raw: reach_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.reach_total.formatter!(reach_total),
      },
      engagement_total: {
        raw: engagement_total,
        formatted:
          DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_total.formatter!(
            engagement_total
          ),
      },
      engagement_rate_by_impressions: {
        raw: engagement_rate_by_impressions,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_rate_by_impressions
          .formatter!(engagement_rate_by_impressions),
      },
    };
  });
};

export const aggregateHistoricalData = (
  data: CampaignPerformanceHistoricalDataResponse
): DataPoint[] => {
  const groupedBySnapshotDate = data.reduce<
    Record<string, Array<CampaignPerformanceHistoricalDataResponse[number]>>
  >(
    (acc, metric) => ({
      ...acc,
      [metric.snapshot_date]: [...(acc[metric.snapshot_date] ?? []), metric],
    }),
    {}
  );

  return Object.entries(groupedBySnapshotDate).map(([snapshot_date, historicalData]) => {
    const sumField = (field: keyof (typeof historicalData)[number]) =>
      historicalData.reduce((acc, metrics) => acc + (metrics[field] as number), 0);

    const averageField = (field: keyof (typeof historicalData)[number]) =>
      sumField(field) / historicalData.length;

    return {
      snapshot_date,
      engagement_total: sumField('engagement_total'),
      impression_total: sumField('impression_total'),
      reach_total: sumField('reach_total'),
      engagement_rate_by_impressions: averageField('engagement_rate_by_impressions'),
      engagement_rate_by_followers: averageField('engagement_rate_by_followers'),
    };
  });
};

export const postMetrics = (metrics: any[]) => {
  return metrics.map((metric: PerformanceData) => {
    const influencer = {
      gig_id: metric.gig_id,
      influencer_id: metric.influencer.influencer_id,
      full_name: metric.influencer.full_name,
      instagram_handle: metric.influencer.instagram_handle,
      tiktok_handle: metric.influencer.tiktok_handle,
      instagram_profile_url: metric.influencer.instagram_profile_url,
      tiktok_profile_url: metric.influencer.tiktok_profile_url,
      instagram_profile_picture_url: metric.influencer.instagram_profile_picture_url,
      tiktok_profile_picture_url: metric.influencer.tiktok_profile_picture_url,
      generated_timestamp: metric.generated_timestamp,

      follower_total: {
        raw: metric.follower_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.follower_total.formatter!(
          metric.follower_total
        ),
      },
      digital_content_count: {
        raw: metric.digital_content_count,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.digital_content_count.formatter!(
          metric.digital_content_count
        ),
      },
      impression_total: {
        raw: metric.impression_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.impression_total.formatter!(
          metric.impression_total
        ),
      },
      reach_total: {
        raw: metric.reach_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.reach_total.formatter!(
          metric.reach_total
        ),
      },
      engagement_total: {
        raw: metric.engagement_total,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_total.formatter!(
          metric.engagement_total
        ),
      },
      engagement_rate_by_impressions: {
        raw: metric.engagement_rate_by_impressions,
        formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_rate_by_impressions
          .formatter!(metric.engagement_rate_by_impressions),
      },
    };
    return influencer;
  });
};

type AggregatedPaidMetrics = {
  impression_total: number;
  engagement_total: number;
  video_play_cumulative_total: number;
  engagement_rate_by_impressions_average: number;
  click_through_rate_average: number;
  view_through_rate_average: number;
};

export const aggregatePaidMetrics = (metrics: Metrics[]): AggregatedPaidMetrics => {
  const initialMetrics: AggregatedPaidMetrics = {
    impression_total: 0,
    engagement_total: 0,
    video_play_cumulative_total: 0,
    engagement_rate_by_impressions_average: 0,
    click_through_rate_average: 0,
    view_through_rate_average: 0,
  };

  const aggregated = metrics.reduce<AggregatedPaidMetrics>((acc, curr) => {
    return {
      impression_total: acc.impression_total + curr.impression_cumulative_total,
      engagement_total: acc.engagement_total + curr.engagement_cumulative_total,
      video_play_cumulative_total:
        acc.video_play_cumulative_total +
        ((curr.video_view_2_second_cumulative_total ?? 0) +
          (curr.video_view_3_second_cumulative_total ?? 0)),
      engagement_rate_by_impressions_average:
        acc.engagement_rate_by_impressions_average + curr.engagement_rate_by_impressions_average,
      click_through_rate_average: acc.click_through_rate_average + curr.click_through_rate_average,
      view_through_rate_average: acc.view_through_rate_average + curr.view_through_rate_average,
    };
  }, initialMetrics);

  const metricsCount = metrics?.length || 0;

  if (metricsCount > 0) {
    aggregated.engagement_rate_by_impressions_average /= metricsCount;
    aggregated.click_through_rate_average /= metricsCount;
    aggregated.view_through_rate_average /= metricsCount;
  }

  return aggregated;
};

export const aggregatePaidPlatformData = (
  instagram: CampaignInstagramPaidAdPerformanceResponse,
  tiktok: CampaignTikTokPostPaidAdPerformanceResponse
): {instagram: Record<string, number>; tiktok: Record<string, number>} => {
  const sumField = (
    source:
      | CampaignInstagramPaidAdPerformanceResponse
      | CampaignTikTokPostPaidAdPerformanceResponse,
    field:
      | keyof CampaignInstagramPaidAdPerformanceResponse[number]
      | keyof CampaignTikTokPostPaidAdPerformanceResponse[number]
  ) =>
    source.reduce(
      (acc, metrics) => acc + ((metrics as Record<string, string | number>)[field] as number),
      0
    );

  const averageField = (
    source:
      | CampaignInstagramPaidAdPerformanceResponse
      | CampaignTikTokPostPaidAdPerformanceResponse,
    field:
      | keyof CampaignInstagramPaidAdPerformanceResponse[number]
      | keyof CampaignTikTokPostPaidAdPerformanceResponse[number]
  ) => sumField(source, field) / source.length;

  return {
    instagram: {
      click_through_rate_lifetime_average: averageField(
        instagram,
        'click_through_rate_lifetime_average'
      ),
      engagement_cumulative_total: sumField(instagram, 'engagement_cumulative_total'),
      engagement_rate_by_impressions_average: averageField(
        instagram,
        'engagement_rate_by_impressions_average'
      ),
      impression_cumulative_total: sumField(instagram, 'impression_cumulative_total'),
      video_view_3_second_cumulative_total: sumField(
        instagram,
        'video_view_3_second_cumulative_total'
      ),
      view_through_rate_lifetime_average: averageField(
        instagram,
        'view_through_rate_lifetime_average'
      ),
    },
    tiktok: {
      click_through_rate_lifetime_average: averageField(
        tiktok,
        'click_through_rate_lifetime_average'
      ),
      engagement_cumulative_total: sumField(tiktok, 'engagement_cumulative_total'),
      engagement_rate_by_impressions_average: averageField(
        tiktok,
        'engagement_rate_by_impressions_average'
      ),
      impression_cumulative_total: sumField(tiktok, 'impression_cumulative_total'),
      video_view_2_second_cumulative_total: sumField(
        tiktok,
        'video_view_2_second_cumulative_total'
      ),
      view_through_rate_lifetime_average: averageField(
        tiktok,
        'view_through_rate_lifetime_average'
      ),
    },
  };
};
