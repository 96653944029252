import React from 'react';
import {Typography} from '@material-ui/core';

import {MediaContainer, MediaPreview} from '../../../../../components/Widgets';
import {latestMedia, hasMedia, isDeleted} from '../../../../../consts/gigHelper';
import {numberMillified} from '../../../../../consts/utilities';
import {TopCreator} from '../../types';
import {useStyles} from './styles';
import {formatEngagementRate} from '../../../utils';

interface Props {
  creator: TopCreator;
}

const CreatorCard: React.FC<Props> = ({creator}: Props) => {
  const classes = useStyles();

  if (!creator) return null;

  if (!creator?.gigs?.length) return null;

  const gig = creator.gigs[0];
  const media = latestMedia(gig, true);

  return (
    <div className={classes.card}>
      {hasMedia(gig, true) && (
        <MediaContainer height={296} width={296}>
          <MediaPreview
            media={media}
            height={296}
            width={296}
            isDeleted={isDeleted(gig)}
            arePillsShown={false}
            alt={`Post of creator ${creator.influencer?.username}`}
          />
          {creator.influencerMetrics && (
            <div className={classes.erWrapper}>
              {creator.influencerMetrics.engagementRateStatic !== null && (
                <div className={classes.erBlock}>
                  <Typography variant="caption">ER in-feed:</Typography>
                  <Typography variant="h3" component="span">
                    {formatEngagementRate(creator.influencerMetrics.engagementRateStatic)}
                  </Typography>
                </div>
              )}
              {creator.influencerMetrics.engagementRateStory !== null && (
                <div className={classes.erBlock}>
                  <Typography variant="caption">ER Story:</Typography>
                  <Typography variant="h3" component="span">
                    {formatEngagementRate(creator.influencerMetrics.engagementRateStory)}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </MediaContainer>
      )}
      <div className={classes.infoWrapper}>
        <Typography variant="h4" component="span">
          {creator.influencer.fullName}
        </Typography>
        <div className={classes.metrics}>
          {creator.influencer?.followers !== null && (
            <div className={classes.metric}>
              <Typography variant="caption">Followers</Typography>
              <Typography variant="h4" component="span">
                {numberMillified(creator.influencer?.followers)}
              </Typography>
            </div>
          )}
          {creator.influencerMetrics && creator.influencerMetrics?.totalImpressions !== null && (
            <div className={classes.metric}>
              <Typography variant="caption">Impressions</Typography>
              <Typography variant="h4" component="span">
                {numberMillified(creator.influencerMetrics.totalImpressions)}
              </Typography>
            </div>
          )}
          {creator.influencerMetrics && creator.influencerMetrics?.reach !== null && (
            <div className={classes.metric}>
              <Typography variant="caption">Reach</Typography>
              <Typography variant="h4" component="span">
                {numberMillified(Math.round(creator.influencerMetrics.reach))}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatorCard;
