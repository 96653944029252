import React from 'react';
import emojiFlag from 'emoji-flag';

import {MaterialDesignIcon} from 'components/Icons';
import {Tooltip} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import styles from './style.css';

const formatPercentage = (v: number) => `${(v * 100).toFixed(1)}%`;

type Stat = {
  image: {
    median: number;
    count: number;
  };
  video: {
    median: number;
    count: number;
  };
  gallery: {
    median: number;
    count: number;
  };
};

type FollowerCountriesProps = {
  regionInsights: Array<{
    region: {countryCode: string};
    followerPercentage: {formattedValue: string};
  }>;
  className: string;
};

const FollowerCountries: React.FC<FollowerCountriesProps> = ({regionInsights, className}) => {
  if (!regionInsights) return null;
  return (
    regionInsights && (
      <div className={className}>
        <h3 className={styles.header}>Countries</h3>
        {regionInsights.map(insight => (
          <React.Fragment key={insight.region.countryCode}>
            <div>
              {emojiFlag(insight.region.countryCode)} {insight.followerPercentage.formattedValue}
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  );
};

type AudienceSplitProps = {
  name: string;
  className: string;
  split: Array<{
    name: string;
    followerCount: number;
    followerPercentage: {
      value: number;
      formattedValue: string;
    };
  }>;
};

const AudienceSplit: React.FC<AudienceSplitProps> = ({split, name, className}) => {
  if (!split) return null;
  const rowsCount = Math.ceil(split.length / 2);
  let rows;
  if (rowsCount >= 4) {
    const leftColumn = split.slice(0, rowsCount);
    const rightColumn = split.slice(rowsCount);
    rows = leftColumn.map((item, idx) => [item, rightColumn[idx]]);
  } else {
    rows = split.map(item => [item, undefined]);
  }

  return (
    <div className={className}>
      <h3 className={styles.header}>{name}</h3>
      <table>
        {rows.map(([left, right]) => (
          <tr key={left.name} className={styles.tableRow}>
            <td className={styles.leftCell}>{left.name}</td>
            <td className={styles.leftColumn}>{formatPercentage(left.followerPercentage.value)}</td>
            {right && (
              <>
                <td className={styles.leftCell}>{right.name}</td>
                <td>{formatPercentage(right.followerPercentage.value)}</td>
              </>
            )}
          </tr>
        ))}
      </table>
    </div>
  );
};

type ReactPostsStatsRowProps = {
  row: {
    median: number;
    count: number;
  };
  header: string;
  followers: number;
  name: string;
  icon: string;
};

const RecentPostStatsRow: React.FC<ReactPostsStatsRowProps> = ({
  row,
  header,
  followers,
  name,
  icon,
}) => {
  if (!row.median) return null;

  return (
    <Tooltip
      placement="left"
      overlay={
        <span>
          Median {header.toLowerCase()} over {numberWithCommas(row.count)} {name} posts
          <br />
          Ratio to current followers: {formatPercentage(row.median / followers)}
        </span>
      }
    >
      <MaterialDesignIcon icon={icon} className={styles.statIcon} /> {numberWithCommas(row.median)}:{' '}
      {formatPercentage(row.median / followers)} ({row.count})
    </Tooltip>
  );
};

type RecentPostStatsProps = {
  statSection: Stat;
  followers: number;
  header: string;
  className: string;
};

const RecentPostStats: React.FC<RecentPostStatsProps> = ({
  statSection,
  header,
  followers,
  className,
}) => {
  return (
    statSection && (
      <div className={className}>
        <h3 className={styles.header}>{header}</h3>
        <div className={styles.statsRows}>
          <RecentPostStatsRow
            row={statSection.image}
            followers={followers}
            header={header}
            name="image"
            icon="camera_roll"
          />
          <RecentPostStatsRow
            row={statSection.video}
            followers={followers}
            header={header}
            name="video"
            icon="movie"
          />
          <RecentPostStatsRow
            row={statSection.gallery}
            followers={followers}
            header={header}
            name="gallery"
            icon="collections"
          />
        </div>
      </div>
    )
  );
};

const ExtraRow = (offer?: Offer) => {
  if (!offer.influencer.instagramAccount) {
    return null;
  }
  const {instagramAccount} = offer?.influencer;
  const {audienceInsight} = offer?.influencer?.instagramAccount;
  const regionInsights = audienceInsight?.regionInsights;
  const stats = instagramAccount?.recentPostsStats;

  return (
    <div className={styles.extraRow}>
      <FollowerCountries className={styles.section} regionInsights={regionInsights} />
      <AudienceSplit
        className={styles.section}
        split={audienceInsight?.genderSplit}
        name="Gender"
      />
      <AudienceSplit
        className={styles.section}
        split={audienceInsight?.ageSplit.sort((x, y) => parseInt(x.name) - parseInt(y.name))}
        name="Age"
      />
      {stats && (
        <>
          <RecentPostStats
            className={styles.section}
            statSection={stats.impressions}
            followers={instagramAccount.followers}
            header="Impressions"
          />
          <RecentPostStats
            className={styles.section}
            statSection={stats.engagements}
            followers={instagramAccount.followers}
            header="Engagements"
          />
        </>
      )}
    </div>
  );
};

export default ExtraRow;
