import React, {FormEvent, ReactNode, useEffect, useImperativeHandle, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Close, Done} from '@material-ui/icons';

import {purple} from '../../../consts/brand';

export interface ModalProps {
  /** Identifier string, also used for accessibility */
  id: string;
  /** Title of Modal */
  title: string;
  /** Element to use as footer */
  footer?: ReactNode | ReactNode[];
  /** Element to use to toggle visibility of modal */
  modalToggler?: JSX.Element;
  /** If true, the dialog stretches to maxWidth. Notice that the dialog width grow is limited by the default margin. */
  fullWidth?: boolean;
  /** Max width for modal in 'xs' | 'sm' | 'md' | 'lg' | 'xl' */
  maxWidth?: DialogProps['maxWidth'];
  /** Enables content scrolling */
  scroll?: boolean;
  /** Open/Close modal prop */
  open?: boolean;
  /** Handler function for `onSubmit()` action */
  onSubmit?: () => void;
  /** Handler function for `onClose()` action */
  onClose?: () => void;
  /** Display 'Cancel' button */
  showCancel?: boolean;
  /** Display a close button in the top right corner */
  showClose?: boolean;
  /** Display the submit button. Set to false if controlling submission from elsewhere. */
  showSubmit?: boolean;
  /** Submit button title */
  submitTitle?: string;
  /** Loading state prop */
  loading?: boolean;
  disabled?: boolean;
  /** Body for modal */
  children: ReactNode | ReactNode[];
  /** React ref object */
  ref?: React.RefObject<ModalElement>;
  /** Element type to use for dialog content */
  innerElement?: keyof JSX.IntrinsicElements;
}

export interface ModalElement {
  /** Close the modal */
  close: () => void;
}

const Modal: React.FC<ModalProps> = React.forwardRef(
  (
    {
      id,
      title,
      footer,
      modalToggler,
      fullWidth,
      maxWidth,
      scroll,
      open = false,
      onSubmit,
      onClose,
      showCancel,
      showClose,
      showSubmit = true,
      submitTitle = 'OK',
      loading,
      disabled,
      children,
      innerElement,
    },
    ref
  ) => {
    const classes = useStyle();
    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
      setIsOpen(open);
    }, [open]);

    const close = () => setIsOpen(false);

    useImperativeHandle(ref, () => ({
      close,
    }));

    const handleClose = () => {
      setIsOpen(false);

      if (onClose) {
        onClose();
      }
    };

    const handleSubmit = () => {
      if (onSubmit) {
        onSubmit();
      }
    };

    const WrappedToggler = () => (
      <>
        {React.cloneElement(modalToggler, {
          onClick: () => setIsOpen(!isOpen),
        })}
      </>
    );

    const DialogTitleAndClose = ({id, children}: {id: string; children: ReactNode}) => (
      <>
        <DialogTitle id={id} disableTypography>
          <Typography variant="h4">{children}</Typography>
        </DialogTitle>
        {showClose && (
          <IconButton className={classes.dialogClose} aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </>
    );

    return (
      <>
        {modalToggler && <WrappedToggler />}
        <Dialog
          id={id}
          open={isOpen}
          scroll={scroll ? 'paper' : undefined}
          aria-labelledby={`${id}-title`}
          aria-describedby={`${id}-description`}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
        >
          <InnerElement innerElement={innerElement} handleSubmit={handleSubmit}>
            {(title || showClose) && (
              <DialogTitleAndClose id={`${id}-title`}>{title}</DialogTitleAndClose>
            )}
            <DialogContent dividers={scroll}>{children}</DialogContent>
            {onSubmit && (showCancel || showSubmit) && (
              <DialogActions>
                <Grid container direction="column">
                  <Grid container item justifyContent="flex-end" alignItems="center" spacing={2}>
                    {showCancel && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    )}
                    {showSubmit && (
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={
                            loading ? <CircularProgress color="inherit" size="1em" /> : <Done />
                          }
                          disabled={disabled || loading}
                        >
                          {submitTitle}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </DialogActions>
            )}
            {footer && (
              <Box maxWidth="100%" marginTop={2}>
                {footer}
              </Box>
            )}
            {showClose && !title && !onSubmit && <Box marginBottom={2}></Box>}
          </InnerElement>
        </Dialog>
      </>
    );
  }
);

type InnerElementProps = {
  innerElement?: keyof JSX.IntrinsicElements;
  handleSubmit: () => void;
};

const InnerElement: React.FC<InnerElementProps & Parameters<typeof React.createElement>[1]> = ({
  innerElement,
  handleSubmit,
  ...props
}) => {
  if (innerElement != null) return React.createElement(innerElement, props);

  return (
    <form
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
        handleSubmit();
      }}
      {...props}
    />
  );
};

const useStyle = makeStyles({
  dialogTitle: {
    margin: 0,
  },
  dialogClose: {
    position: 'absolute',
    top: '0.3em',
    right: '0.3em',
    color: `${purple}`,
  },
});

export default Modal;
