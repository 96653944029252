import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';

import {useDataServiceApi} from '../../../hooks/useDataServiceApi';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {CampaignQuery} from './graphqlQueries';
import {CampaignCalendar, CampaignHeader} from '../components';

type Props = {
  currentUser: User;
  campaign: Campaign;
};

const CampaignContentCalendar: React.FC<Props> = () => {
  const location = useLocation();
  const user = useCurrentUser();
  const campaignId = location.pathname.split('/')[3];
  const {data: campaignData} = useQuery<Props>(CampaignQuery, {variables: {campaignId}});
  const campaign = campaignData?.campaign;
  const {data: marketsData} = useQuery<{markets: Array<Pick<Market, 'slug' | 'defaultTimezone'>>}>(
    MarketsQuery
  );

  const {loading, error, fetchContentCalendarData} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<any>(null);
  const timezone =
    marketsData?.markets?.find(({slug}) => slug === campaign?.marketSlug)?.defaultTimezone ??
    undefined;

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data = await fetchContentCalendarData({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setResourceData(data);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, campaign?.id, fetchContentCalendarData]);

  return (
    <>
      <CampaignHeader title="Content Calendar" campaignId={campaignId} showProgress />
      {campaign && (
        <>
          <CampaignCalendar
            calendar={resourceData}
            campaign={campaign}
            loading={loading}
            error={error}
            timezone={timezone}
          />
        </>
      )}
    </>
  );
};

const MarketsQuery = gql`
  query MarketsQuery {
    markets {
      slug
      defaultTimezone
    }
  }
`;

export default CampaignContentCalendar;
