import {useQuery} from '@apollo/client';

import {AdvertiserConfig} from 'scenes/Advertiser/AdvertiserAccessSettings/types';

import {AdminAdvertisersQuery} from '../../Admin/Advertisers/graphqlQuery';
import {AdvertiserConfigQuery} from '../AdvertiserAccessSettings/graphqlQuery';

const useAdvertiserAccess = (skip = false): AdvertiserConfig | null => {
  const {data: adminAdvertisersData} = useQuery(AdminAdvertisersQuery, {skip: skip});
  const advertisers = adminAdvertisersData?.advertisers.edges.map(edge => edge.node);
  const [firstAdvertiser] = advertisers ?? [];
  const {data: advertiserConfigData} = useQuery(AdvertiserConfigQuery, {
    skip: !firstAdvertiser,
    variables: {advertiserId: firstAdvertiser?.id},
  });

  if (!firstAdvertiser || !advertiserConfigData) {
    return null;
  }

  return advertiserConfigData.advertiserConfig;
};

export default useAdvertiserAccess;
