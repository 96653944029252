import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {Typography, Chip} from '@material-ui/core';

import {ProfilePicture} from 'components/Widgets';

import useRequiresPermission from 'hooks/useRequiresPermission';
import {REMOVE_USER_FROM_ADVERTISER} from 'consts/permissions';

import {AdvertiserMember} from '../../types';
import {useStyles} from './styles';

interface Props {
  member: AdvertiserMember;
  removeMember: (memberId: string) => void;
}

const MemberCard: React.FC<Props> = ({member, removeMember}: Props) => {
  const hasRemovePermission = useRequiresPermission(REMOVE_USER_FROM_ADVERTISER);
  const classes = useStyles({isReadonly: !hasRemovePermission});

  return (
    <div className={classes.root} aria-label="Member card">
      {hasRemovePermission && (
        <IconButton
          className={classes.removeMembership}
          aria-label="Remove member"
          aria-describedby={`member_${member.email}`}
          onClick={() => removeMember(member.id)}
        >
          <HighlightOffRoundedIcon />
        </IconButton>
      )}
      <ProfilePicture aria-label="Member profile picture" src={member.profilePicture} size={64} />
      <div className={classes.info} id={`member_${member.email}`}>
        {member.fullName && (
          <Typography variant="h4" className={classes.fullName}>
            {member.fullName}
          </Typography>
        )}
        <p className={classes.email}>{member.email}</p>
        {member.hasInvitationSent && !member.hasAcceptedInvite && (
          <Chip label="Invite pending" className={classes.chip} />
        )}
      </div>
    </div>
  );
};

export default MemberCard;
