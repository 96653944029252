import React, {useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {Save} from '@material-ui/icons';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {makeStyles} from '@material-ui/styles';

import {black} from '../../../../../../consts/brand';

interface Props {
  campaign: Campaign;
  disabled?: boolean;
}

const SetCampaignOffPlatform: React.FC<Props> = ({campaign, disabled}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [offPlatformChecked, setOffPlatformChecked] = useState(Boolean(campaign.offPlatform));
  const [setOffPlatform] = useMutation(SetOffPlatform);

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: yup.object({
      reason: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const closeDialog = () => setOpenDialog(false);

  const handleSubmit = () => {
    setOffPlatform({
      variables: {
        id: campaign.id,
        offPlatform: true,
        offPlatformReason: formik.values.reason,
      },
    });
    closeDialog();
  };

  const handleCancel = () => {
    setOffPlatformChecked(false);
    closeDialog();
  };

  const handleSwitchChange = (state: boolean) => {
    if (!state && offPlatformChecked) {
      setOffPlatform({variables: {id: campaign.id, offPlatform: false}});
      setOffPlatformChecked(false);
      formik.resetForm();
    } else if (state) {
      setOffPlatformChecked(true);
      setOpenDialog(true);
    }
  };

  const renderToggle = () => (
    <Grid container style={{marginTop: '7px'}} spacing={4} direction="column">
      <Grid item xs>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={offPlatformChecked}
                disabled={disabled}
                onChange={event => handleSwitchChange(event.target.checked)}
              />
            }
            label={
              <Typography variant="body2" style={{fontWeight: 600}}>
                This campaign will be handled 'Off Platform'
              </Typography>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        {offPlatformChecked && campaign.offPlatformReason && (
          <TextField
            className={classes.reasonField}
            variant="outlined"
            value={campaign.offPlatformReason}
            multiline
            disabled
          />
        )}
      </Grid>
    </Grid>
  );

  const renderDialog = () => (
    <Dialog open={openDialog} onClose={handleCancel}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Handle Campaign Off Platform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container direction="column" spacing={4}>
              <Grid item xs>
                <Typography variant="body2">
                  Please provide a reason why this campaign will be handled off platform.
                </Typography>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth>
                  <InputLabel htmlFor="reason">Reason</InputLabel>
                  <Input
                    id="reason"
                    name="reason"
                    type="text"
                    value={formik.values.reason}
                    aria-describedby={'reason'}
                    error={formik.touched.reason}
                    required
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" color="primary" size="large" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Save />}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      {renderToggle()}
      {renderDialog()}
    </>
  );
};

const useStyles = makeStyles({
  reasonField: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: `${black}`,
    },
  },
});

const SetOffPlatform = gql`
  mutation SetOffPlatform($id: UUIDString!, $offPlatform: Boolean!, $offPlatformReason: String) {
    setOffPlatform(id: $id, offPlatform: $offPlatform, offPlatformReason: $offPlatformReason) {
      ok
      campaign {
        id
        offPlatform
        offPlatformReason
      }
    }
  }
`;

export default SetCampaignOffPlatform;
