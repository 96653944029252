import Loadable from 'react-loadable';

import LoaderDots from 'components/Widgets/LoaderDots';

export default function LazyComponent(opts) {
  return Loadable({
    loading: LoaderDots,
    delay: 200,
    ...opts,
  });
}
