import React from 'react';
import {Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import SummaryMetrics from '../../Campaign/components/CampaignPerformance/SummaryData/SummaryMetrics';
import SummaryChart from '../../Campaign/components/CampaignPerformance/SummaryData/SummaryChart';
import {PerformanceData} from '../../Campaign/components/CampaignPerformance/types';

interface Props {
  metrics: PerformanceData[];
  historicalMetrics: PerformanceData[];
}

const CampaignMetrics: React.FC<Props> = ({metrics, historicalMetrics}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Total Performance Summary
      </Typography>
      <Divider className={classes.spacing} />
      <Grid
        container
        spacing={3}
        aria-label="Aggregate Performance Metrics"
        className={classes.spacing}
      >
        <SummaryMetrics summaryMetrics={metrics} modelName="campaign_performance" />
        {historicalMetrics && <SummaryChart historicalData={historicalMetrics} />}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignMetrics;
