import React from 'react';
import classNames from 'classnames';
import {makeStyles, createStyles} from '@material-ui/core';

import {purple, white, red, yellow} from '../../../consts/brand.integration';

interface Props {
  red: boolean;
  yellow: boolean;
  black: boolean;
}

const Pill: React.FC<Props> = ({children, red, black, yellow}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames([
        classes.pill,
        {[classes.red]: red},
        {[classes.yellow]: yellow},
        {[classes.black]: black},
      ])}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    pill: {
      backgroundColor: purple,
      color: white,
      fontSize: '14px',
      opacity: 0.8,
      padding: '8px 15px',
      borderRadius: '100px',
      fontWeight: 500,
      marginLeft: '10px',
      lineHeight: 1,
    },
    red: {
      backgroundColor: red,
    },
    yellow: {
      backgroundColor: yellow,
    },
    black: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  })
);

export default Pill;
