import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {numberWithCommas} from '../../../consts/utilities';
import styles from './style.css';

const InputRange = ({
  minValue,
  maxValue,
  onChange,
  value,
  disabled,
  step = 1,
  formatValue = value => value,
}) => (
  <div>
    <div>
      <input
        className={classNames(disabled && styles.disabled)}
        type="range"
        step={step}
        min={minValue}
        max={maxValue}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
      />
    </div>
    <div className={styles.minMaxValues}>
      <div className={styles.minValue}>
        <span>{numberWithCommas(minValue)}</span>
      </div>
      <div className={styles.value}>{numberWithCommas(formatValue(value))}</div>
      <div className={styles.maxValue}>
        <span>{numberWithCommas(maxValue)}</span>
      </div>
    </div>
  </div>
);

InputRange.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  step: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  formatValue: PropTypes.func,
};

export default InputRange;
