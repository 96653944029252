import {Theme} from '@material-ui/core';

import {black, roman, semiBold, transparentBlack} from '../../../../consts/brand.integration';

export const customStyles = (theme: Theme) => ({
  radioButton: {
    border: `2px solid ${theme.palette['ui-05']}`,
    height: 20,
    width: 20,
    flex: '0 0 20px',
    borderRadius: '50%',
  },
  checkedRadioButton: {
    border: `2px solid ${black}`,
    height: 20,
    width: 20,
    flex: '0 0 20px',
    borderRadius: '50%',
    '&:after': {
      backgroundColor: black,
    },
  },
  radioButtonContainer: {
    marginLeft: 0,
  },
  label: {
    fontWeight: semiBold,
    paddingLeft: '16px',
    fontSize: '14px !important',
  },
  icon: {
    color: transparentBlack,
    marginTop: '10px',
  },
  description: {
    fontWeight: roman,
    color: black,
    fontSize: '12px',
    lineHeight: '18px',
  },
  inputLabel: {
    color: black,
  },
  definitionClassName: {
    fontSize: '16px',
  },
  definitionTitleClassName: {
    fontSize: '12px',
    fontVariant: 'none',
    color: theme.palette['brand-03'],
  },
  customSection: {
    marginTop: 24,
  },
  gridContainer: {
    marginTop: 7,
  },
  rewardFieldset: {
    border: 'none',
    marginTop: '24px',
  },
  rewardLegend: theme.typography.subtitle1,
});
