import React from 'react';
import classNames from 'classnames';

import {useStyles} from './styles';

interface Props {
  text: string;
  selected?: boolean;
  big?: boolean;
  danger?: boolean;
  transparent?: boolean;
  handleOnClick: () => void;
}

const HighlightedButton: React.FC<Props> = ({
  text,
  selected,
  handleOnClick,
  big,
  transparent,
  danger,
}) => {
  const classes = useStyles();

  const className = classNames({
    [classes.root]: true,
    [classes.danger]: danger,
    [classes.big]: big,
    [classes.transparent]: transparent,
    [classes.default]: !transparent,
    [classes.selected]: selected,
  });

  return (
    <div className={classNames(className)} onClick={handleOnClick}>
      <span className={classes.item}>{text}</span>
    </div>
  );
};

export default HighlightedButton;
