import React from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Checkbox, FormControlLabel, FormGroup, Typography} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import {LoaderDots} from '../../../../../../components/Widgets';

const ProcessingStatus: React.FC = () => {
  const [updateProcessing] = useMutation(TogglePaymentProcessing);
  const {loading, data, error} = useQuery(PaymentProcessingStatusQuery);

  if (error) {
    return <div>Error loading processing status</div>;
  }

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return (
      <div style={{display: 'inline-flex'}}>
        <span style={{marginRight: '5px'}}>
          <ErrorIcon color="error" />
        </span>
        <Typography variant="body2">Error loading processing status</Typography>
      </div>
    );
  }

  const {
    paymentProcessingStatus: {revolutIntl, revolutUs},
  } = data;

  const handleRevolutIntlChange = () =>
    updateProcessing({
      variables: {
        provider: 'revolut_intl',
        processing: !revolutIntl,
      },
    });

  const handleRevolutUsChange = () =>
    updateProcessing({
      variables: {
        provider: 'revolut_us',
        processing: !revolutUs,
      },
    });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={revolutIntl}
            name="revolutIntl"
            color="primary"
            onChange={handleRevolutIntlChange}
          />
        }
        label="Revolut Intl."
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={revolutUs}
            name="revolutUs"
            color="primary"
            onChange={handleRevolutUsChange}
          />
        }
        label="Revolut US"
      />
    </FormGroup>
  );
};

const TogglePaymentProcessing = gql`
  mutation TogglePaymentProcessing($provider: PaymentProvider!, $processing: Boolean!) {
    togglePaymentProcessing(paymentProvider: $provider, processing: $processing) {
      ok
      paymentProcessingStatus {
        id
        revolutIntl
        revolutUs
      }
    }
  }
`;

const PaymentProcessingStatusQuery = gql`
  query PaymentProcessingStatusQuery {
    paymentProcessingStatus {
      id
      revolutIntl
      revolutUs
    }
  }
`;

export default ProcessingStatus;
