import {makeStyles} from '@material-ui/core';

import {
  roman,
  semiBold,
  sizeH1,
  sizeH5,
  sizeH6,
  black20,
  black50,
  purple,
  red,
  black,
  white,
  lineHeightLarge,
  lineHeightBig,
  sizeH4,
} from '../../../consts/brand.integration';

export const useStyles = makeStyles({
  root: {
    fontWeight: roman,
    verticalAlign: 'top',
  },
  title: {
    fontSize: sizeH6,
    lineHeight: '1.2',
    fontWeight: semiBold,
    color: black50,
    fontVariant: 'small-caps',
  },
  definition: {
    fontSize: sizeH4,
    fontWeight: roman,
  },
  placeholder: {
    color: black20,
  },
  right: {
    textAlign: 'right',
    margin: '0 0 0 24px',
  },
  center: {
    textAlign: 'center',
  },
  report: {
    fontSize: sizeH6,
    marginBottom: '-5px',
  },
  largeDefinition: {
    fontSize: sizeH1,
  },
  smallDefinition: {
    fontSize: sizeH5,
    lineHeight: lineHeightBig,
    fontWeight: 'normal',
  },
  big: {
    fontSize: '50px',
    lineHeight: lineHeightLarge,
    fontWeight: semiBold,
  },
  extraMargin: {
    marginBottom: '6px',
  },
  gold: {
    color: purple,
  },
  red: {
    color: red,
  },
  invertedRoot: {
    width: '110px',
    height: '64px',
    backgroundColor: black,
    padding: '8px',
    marginBottom: '10px',
  },
  invertedTitle: {
    color: white,
  },
  invertedDef: {
    color: purple,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
