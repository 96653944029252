import {Button, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';
import {Icon} from '@iconify/react';

import UserCard from './UserCard';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {UserRoles} from '../../../../../consts/roles';

interface Props {
  historyItem: HistoryInterface;
}

const CardClaimed: React.FC<Props> = ({historyItem}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
      {historyItem.payment && currentUser?.roleName !== UserRoles.Advertiser && (
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Icon icon={'cil:find-in-page'} width={32} className={classes.iconContainer} />
          </Grid>
          <Grid item xs>
            <Link
              to={{pathname: historyItem.payment.dashboardLink}}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outlined" className={classes.button}>
                <Typography variant="button">Look up payment</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  iconContainer: {
    marginLeft: '.6rem',
  },
  button: {
    margin: '.2rem 0 0 .2rem',
  },
});

export default CardClaimed;
