import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {Typography, createStyles, withStyles} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {
  Button,
  InterestIcon,
  ProfilePicture,
  Prompt,
  RelativeDate,
  Tooltip,
  FormattedDate,
  FollowerData,
} from 'components/Widgets';
import {MaterialDesignIcon} from 'components/Icons';

import {whisper, nearWhite, black, transparentBlack} from 'consts/brand.integration';

import {useStyles} from './styles';

interface Props {
  campaign: Campaign;
  influencer: Influencer;
  onNotify: (deliverables?: OfferDeliverable[]) => void;
  offer?: Offer;
}

const PlaylistTarget: React.FC<Props> = ({campaign, influencer, onNotify, offer}) => {
  const classes = useStyles();
  const [selectedDeliverables, setSelectedDeliverables] = useState<OfferDeliverable[]>([]);

  const activeReservationsCount = influencer?.participatingCampaignIds?.length;
  const hasMessage = !!campaign?.pushNotificationMessage;
  const highlightedInterests = campaign?.interestIds;
  const canBeNotified = hasMessage && !offer;

  return (
    <div className={classes.target}>
      <div className={classes.profilePictureBox}>
        <ProfilePicture src={influencer.profilePicture} size={50} />
        {canBeNotified && (
          <Prompt
            title="Campaign Deliverables"
            body={
              <ToggleButtonGroup
                value={selectedDeliverables}
                aria-label="Campaign Deliverables"
                className={classes.toggleButtonGroup}
                onChange={(event, value) => setSelectedDeliverables(value)}
              >
                {campaign?.posts?.map((post, index) => (
                  <SelectDeliverable
                    key={post?.id}
                    value={post}
                    style={{width: '100%', display: 'block'}}
                  >
                    <Typography variant="caption">Post {index + 1}</Typography>
                    <Typography
                      variant="body1"
                      style={{textTransform: 'capitalize', color: 'purple'}}
                    >
                      {post?.postType}
                    </Typography>
                    <Typography variant="body2">
                      <FormattedDate date={post?.opened} format="Do MMM, YYYY" />
                    </Typography>
                  </SelectDeliverable>
                ))}
              </ToggleButtonGroup>
            }
            confirmText={campaign.state == 'launched' ? 'Notify' : 'Notify on launch'}
            control={
              <Button
                inline
                danger
                text={
                  <div className={classes.notifyButton}>
                    <Tooltip
                      overlay={
                        <span>{campaign.state == 'launched' ? 'Notify' : 'Notify on launch'}</span>
                      }
                      mouseEnterDelay={1}
                      placement="bottom"
                    >
                      <MaterialDesignIcon
                        className={classes.notifyButtonIcon}
                        icon={campaign.state === 'launched' ? 'notifications' : 'playlist_add'}
                      />
                    </Tooltip>
                  </div>
                }
              />
            }
            onSubmit={() => onNotify(selectedDeliverables)}
          />
        )}
        {!canBeNotified && (
          <div className={[classes.notifyButton, classes.notifyButtonDisabled]}>
            <Tooltip
              overlay={
                <span>
                  {(() => {
                    if (!hasMessage) {
                      return 'Message missing';
                    }
                    if (offer) {
                      if (offer.isAccepted) {
                        return 'Already participating';
                      }
                      return 'Has an offer';
                    }
                  })()}
                </span>
              }
              mouseEnterDelay={0.2}
              placement="bottom"
            >
              <MaterialDesignIcon
                className={classes.notifyButtonDisabledIcon}
                icon="do_not_disturb"
              />
            </Tooltip>
          </div>
        )}
        {activeReservationsCount > 0 && (
          <div className={classes.reservedCount}>
            <Tooltip
              overlay={<span>Active reservations</span>}
              mouseEnterDelay={0.2}
              placement="top"
            >
              {activeReservationsCount}
            </Tooltip>
          </div>
        )}
      </div>
      <div className={classes.userData}>
        <Typography variant="body1" className={classes.userName}>
          <Link to={`/influencers/${influencer.id}`}>{influencer.fullName}</Link>
        </Typography>
        <div>
          <FollowerData
            instagramFollowers={influencer.instagramAccount?.followers}
            tiktokFollowers={influencer.tiktokAccount?.followers}
          />
          <Typography variant="subtitle1">
            Signed up <RelativeDate date={influencer.userCreated} />
          </Typography>
        </div>
        <div className={classes.interestIconsWrapper}>
          <div className={classes.interestIcons}>
            {influencer.interests?.map(interest => (
              <InterestIcon
                key={interest?.id}
                interestId={interest?.id}
                className={classNames(
                  classes.interestIcon,
                  highlightedInterests &&
                    highlightedInterests.includes(interest?.id) &&
                    classes.interestIconHighlighted
                )}
              />
            ))}
          </div>
          {offer &&
            (offer.isAccepted ? (
              <p className={classes.notified}>Participating</p>
            ) : (
              <p className={classes.opened}>
                Offer made <RelativeDate date={offer.created} />
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

const SelectDeliverable = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'block',
      textAlign: 'left',
      color: black,
      border: `1px solid ${transparentBlack}!important`,
      '&:hover': {
        color: black,
        backgroundColor: `${nearWhite}!important`,
      },
      '&.Mui-selected': {
        position: 'relative',
        color: black,
        backgroundColor: `${whisper}!important`,
        '&:hover': {
          color: black,
          backgroundColor: `${whisper}!important`,
        },
        '&::after': {
          content: '"\u2713"',
          position: 'absolute',
          top: 5,
          right: 5,
          width: 20,
          height: 20,
        },
      },
    },
  })
)(ToggleButton);

export default PlaylistTarget;
