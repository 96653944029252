import React from 'react';

const Compare = ({color = 'black', ariaLabel = ''}) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.24 1H11.2622V22.8446H13.24V1Z" fill={color} />
    <path
      d="M0.666748 3.22803V5.40764V18.3885V20.5681H2.64455H8.03641V18.3885H2.64455V5.40764H8.03641V3.22803H2.64455H0.666748Z"
      fill={color}
    />
    <path d="M24 12.4794H22.0222V14.8527H24V12.4794Z" fill={color} />
    <path d="M24 8.94348H22.0222V11.3411H24V8.94348Z" fill={color} />
    <path
      d="M22.0223 3.22803H19.9268V5.40764H22.0223V7.80522H24.0001V5.40764V3.22803H22.0223Z"
      fill={color}
    />
    <path d="M18.6319 18.3885H16.7012V20.5924H18.6319V18.3885Z" fill={color} />
    <path
      d="M22.0222 18.3885H19.7383V20.5682H22.0222H24V18.3885V15.991H22.0222V18.3885Z"
      fill={color}
    />
    <path d="M18.8202 3.22803H16.7012V5.43186H18.8202V3.22803Z" fill={color} />
  </svg>
);

export default Compare;
