import React from 'react';
import {Divider, FormControl, InputLabel, ListSubheader, MenuItem, Select} from '@material-ui/core';

import {LoaderDots} from 'components/Widgets';

// These are special region constants defined on the server
export const ALL_REGIONS = '1841eaf3-72d1-4214-b4f4-3884ff3833cb';
export const ALL_SUPPORTED_REGIONS = '29b4baa8-3d8c-4673-b1d3-3c36c3293d3e';

interface Props {
  countries: {
    id: string;
    name: string;
    supported: boolean;
  }[];
  pending: boolean;
  onChange: (change: string) => void;
  region: string;
  regionName?: string;
  showUnsupported?: boolean;
}

const SelectRegion: React.FC<Props> = ({
  countries,
  pending,
  onChange,
  region = '',
  regionName = '',
  showUnsupported,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="region">Region</InputLabel>
      <Select
        labelId="region"
        name="region"
        aria-label="region"
        value={region || regionName}
        defaultValue={ALL_SUPPORTED_REGIONS}
        onChange={event => {
          if (event.target.value !== undefined) {
            onChange(event.target.value as string);
          }
        }}
      >
        {showUnsupported && <MenuItem value={ALL_REGIONS}>All Regions</MenuItem>}
        <MenuItem value={ALL_SUPPORTED_REGIONS}>All Supported Regions</MenuItem>
        {countries &&
          countries
            .filter(country => country.supported === true)
            .map(country => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
        {showUnsupported && [
          <Divider key="divider" />,
          <ListSubheader key="sub-header">Unsupported Regions</ListSubheader>,
          countries &&
            countries
              .filter(country => country.supported === false)
              .map(country => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              )),
        ]}
      </Select>
      {pending && <LoaderDots />}
    </FormControl>
  );
};

export default SelectRegion;
