import {TextField} from '@material-ui/core';
import React from 'react';
import {Control} from 'react-redux-form';

import withCustomControl from '../../../../components/Widgets/Forms/CustomControl';
import {useStyles} from '../LoginForm/styles';

const CustomPasswordInput = (props: any) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      id="password-input"
      name="password"
      inputProps={{
        'aria-labelledby': 'password-input-label',
      }}
      variant="outlined"
      placeholder="Password"
      type="password"
      className={classes.control}
      InputProps={{
        classes: {
          input: classes.inputText,
        },
      }}
      disabled={props.disabled}
      required={props.required}
      value={props.value}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onChange={props.onChange}
    />
  );
};

export const ControlPasswordInput = withCustomControl(props => (
  <Control.text component={CustomPasswordInput} {...props} />
));
