import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import * as qs from 'query-string';

import {
  Button,
  Column,
  DatePicker,
  InfiniteScroll,
  Media,
  MediaContainer,
  Label,
  LoaderDots,
  RowWrap,
  Row,
  Heading,
  Section,
} from 'components/Widgets';

import Frame from './Frame';
import {withHookPagination} from '../../../../services/graphql';
import {
  GigQuery,
  LinkStoryFrameToGig,
  StoryFramesQuery,
  UnlinkStoryFrameFromGig,
  VerifyGigMutation,
} from './queries';
import styles from './style.css';

const SIZE = 400;

const PickStoryFrames: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [gig, setGig] = useState<Gig | null>(null);
  const [storyFramesForGig, setStoryFramesForGig] = useState<Record<string, unknown> | null>(null);

  const history = useHistory();
  const location = useLocation();
  const {params}: {params: {gigId: string}} = useRouteMatch();
  const {gigId} = params;
  const {fromDate, toDate} = qs.parse(location.search);

  const {loading: loadingGig, data: gigQueryResponse} = useQuery(GigQuery, {
    variables: {gigId},
  });

  const {
    loading: loadingStoryFrames,
    data: storyFramesQueryResponse,
    variables: storyFramesQueryVars,
    fetchMore: storyFramesQueryFetchMore,
  } = useQuery(StoryFramesQuery, {
    variables: {gigId, fromDate, toDate},
  });
  const loadMoreStoryFrames = withHookPagination(
    StoryFramesQuery,
    'storyFramesForGig',
    storyFramesQueryResponse,
    storyFramesQueryVars,
    storyFramesQueryFetchMore
  );

  const [verifyGig, {loading: loadingVerifyGig}] = useMutation(VerifyGigMutation);
  const [linkStoryFrameToGig] = useMutation(LinkStoryFrameToGig);
  const [unlinkStoryFrameFromGig] = useMutation(UnlinkStoryFrameFromGig);

  useEffect(() => {
    setLoading(loadingGig || loadingStoryFrames);
  }, [loadingGig, loadingStoryFrames]);

  useEffect(() => {
    if (gigQueryResponse) {
      setGig(gigQueryResponse.gig);
    }
  }, [gigQueryResponse]);

  useEffect(() => {
    if (storyFramesQueryResponse) {
      setStoryFramesForGig(storyFramesQueryResponse.storyFramesForGig);
    }
  }, [storyFramesQueryResponse]);

  const replaceDates = (fromDate?: string, toDate?: string) => {
    history.replace({
      ...location,
      search: qs.stringify({
        fromDate: fromDate,
        toDate: toDate,
      }),
    });
  };

  const handleChangedFromDate = (newFromDate: string) => {
    replaceDates(newFromDate, toDate);
  };

  const handleChangedToDate = (newToDate: string) => {
    replaceDates(fromDate, newToDate);
  };

  const linkStoryFrame = (frame: StoryFrame, gig: Gig) =>
    linkStoryFrameToGig({
      variables: {
        frameId: frame.id,
        gigId: gig.id,
      },
      optimisticResponse: ({frameId}: {frameId: string}) => ({
        linkStoryFrameToGig: {
          __typename: 'LinkStoryFrameToGig',
          ok: true,
          storyFrame: {
            __typename: 'StoryFrame',
            id: frameId,
            instagramStoryId: gig.instagramContent?.id,
          },
        },
      }),
      refetchQueries: [
        {
          query: GigQuery,
          variables: {gigId: gigId},
        },
      ],
    });

  const unlinkStoryFrame = (frame: StoryFrame) =>
    unlinkStoryFrameFromGig({
      variables: {
        frameId: frame.id,
      },
      optimisticResponse: ({frameId}: {frameId: string}) => ({
        unlinkStoryFrameFromGig: {
          __typename: 'UnlinkStoryFrameFromGig',
          ok: true,
          storyFrame: {
            __typename: 'StoryFrame',
            id: frameId,
            instagramStoryId: null,
          },
        },
      }),
      refetchQueries: [
        {
          query: GigQuery,
          variables: {gigId: gigId},
        },
      ],
    });

  const selectFrame = (gig: Gig, frame: StoryFrame) => {
    if (
      frame.instagramStoryId &&
      gig.instagramContent &&
      frame.instagramStoryId == gig.instagramContent.id
    ) {
      unlinkStoryFrame(frame);
    } else {
      linkStoryFrame(frame, gig);
    }
  };

  if (loading) {
    return (
      <div>
        <LoaderDots />
      </div>
    );
  }

  if (!gig) {
    return <div>Gig not found</div>;
  }

  if (!gig.instagramContent) {
    return <div>Story missing. The influencer might not have posted yet.</div>;
  }

  if (gig?.instagramContent.__typename === 'InstagramPost') {
    return <div>Gig isn't a story</div>;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Heading title="Choose story frames" />
        <Row>
          <Column>
            <Section title="Submission">
              <MediaContainer width={SIZE} height={SIZE}>
                <Media media={gig?.submission?.media} width={SIZE} height={SIZE} />
              </MediaContainer>
            </Section>
          </Column>
          <Column>
            <Section
              title={
                <>
                  Selected story frames
                  {gig.isVerified ? (
                    <div className={styles.verifiedText}>Verified</div>
                  ) : (
                    <Button
                      loading={loadingVerifyGig}
                      className={styles.verifyButton}
                      small
                      text={'Verify'}
                      onClick={() => {
                        verifyGig({
                          variables: {
                            gigId: gig.id,
                          },
                        });
                      }}
                    />
                  )}
                </>
              }
            >
              <div className={styles.selectedFrames}>
                {!gig?.instagramContent?.storyFrames?.length && (
                  <div>
                    <p>
                      If the story frames do not appear bellow, it means the creator has not yet
                      posted the story.
                    </p>
                    <p>
                      Please wait until the story has been posted, then select from the story frames
                      below.
                    </p>
                  </div>
                )}
                <RowWrap>
                  {gig?.instagramContent?.storyFrames?.map((frame: StoryFrame) => (
                    <Frame key={frame.id} gig={gig} frame={frame} selectFrame={selectFrame} />
                  ))}
                </RowWrap>
              </div>
            </Section>
          </Column>
        </Row>

        <Row>
          <Column>
            <Label label="Choose From date" />
            <DatePicker
              selected={fromDate}
              timezone={gig?.post?.timezone}
              onChange={handleChangedFromDate}
            />
          </Column>
          <Column>
            <Label label="Choose To date" />
            <DatePicker
              selected={toDate}
              timezone={gig?.post?.timezone}
              onChange={handleChangedToDate}
            />
          </Column>
        </Row>

        <Section title="Select the relevant story frames">
          {loading ? (
            <LoaderDots />
          ) : (
            <InfiniteScroll
              loadMore={loadMoreStoryFrames}
              hasMore={storyFramesForGig.pageInfo.hasNextPage}
            >
              <RowWrap>
                {storyFramesForGig.edges
                  .map(edge => edge.node)
                  .map(frame => (
                    <Frame key={frame.id} gig={gig} frame={frame} selectFrame={selectFrame} />
                  ))}
              </RowWrap>
            </InfiniteScroll>
          )}
        </Section>
      </div>
    </div>
  );
};

export default PickStoryFrames;
