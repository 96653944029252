import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

import styles from './style.css';

const OfferPaymentLink = ({payment, loading}) => {
  if (loading || !payment) {
    return <div className={styles.root} />;
  }

  return (
    <div className={styles.root}>
      <a
        href={payment.dashboardLink}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.dashboardlink}
      >
        Lookup payment
      </a>
    </div>
  );
};

OfferPaymentLink.propTypes = {
  offerId: PropTypes.string.isRequired,
  payment: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

const GetOfferPaymentQuery = gql`
  query GetOfferPaymentQuery($offerId: UUIDString!) {
    offer(id: $offerId) {
      id
      payment {
        id
        type
        successful
        dashboardLink
      }
    }
  }
`;

export default compose(
  graphql(GetOfferPaymentQuery, {
    props: ({data: {loading, offer}}) => {
      if (loading) {
        return {loading};
      }
      return {loading, payment: offer.payment};
    },
    options: ({offerId}) => ({
      variables: {
        offerId,
      },
    }),
  })
)(OfferPaymentLink);
