import {createStaticRanges} from 'react-date-range';
import {isEqual} from 'lodash';
import moment from 'moment';

export const startOfDefaultRange = moment().subtract(1, 'months').startOf('day').toDate();
export const startOfPreviousMonth = moment().subtract(1, 'months').startOf('month').toDate();
export const startOfCurrentQuarter = moment().startOf('quarter').toDate();
export const endOfPreviousMonth = moment()
  .subtract(1, 'months')
  .endOf('month')
  .startOf('day')
  .toDate();
export const currentDay = moment().startOf('day').toDate();

export const allPeriodRange = {startDate: undefined, endDate: undefined};
export const defaultRange = {startDate: startOfDefaultRange, endDate: currentDay};
export const previousMonthRange = {startDate: startOfPreviousMonth, endDate: endOfPreviousMonth};
export const currentQuarterRange = {startDate: startOfCurrentQuarter, endDate: currentDay};

export const predefinedRanges = createStaticRanges([
  {
    label: 'Last month',
    range: () => defaultRange,
    isSelected: range => isEqual({...defaultRange, key: 'selected'}, range),
  },
  {
    label: 'Previous month',
    range: () => previousMonthRange,
    isSelected: range => isEqual({...previousMonthRange, key: 'selected'}, range),
  },
  {
    label: 'Current quarter',
    range: () => currentQuarterRange,
    isSelected: range => isEqual({...currentQuarterRange, key: 'selected'}, range),
  },
  {
    label: 'Clear preset',
    range: () => allPeriodRange,
    isSelected: range => isEqual({...allPeriodRange, key: 'selected'}, range),
  },
]);
