import {makeStyles} from '@material-ui/styles';

import {semiBold} from 'consts/brand.integration';

import {bold, secondaryNavHeight} from '../../../../../consts/brand';
import {theme} from '../../../../../consts/theme';

export const useStyles = makeStyles({
  counter: {
    fontWeight: 400,
    float: 'right',
    padding: '0 36px 0 0',
  },
  ellipse: {
    height: '10px',
    width: '10px',
    display: 'inline-block',
    background: theme.palette['stats-insights'],
    borderRadius: '50%',
    marginLeft: '12px',
  },
  link: {
    fontSize: '14px',
    fontWeight: semiBold,
    display: 'inline-block',
    color: theme.palette['ui-01'],
    height: secondaryNavHeight,
    lineHeight: '38px',
    padding: '2px 0 0 38px',
    transition: 'all $transitionTimeBase',
    borderRight: '4px solid transparent',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette['ui-06'],
    },
  },
  active: {
    borderRightColor: theme.palette['stats-insights'],
    fontWeight: bold,
    background: 'rgba(255,255,255,0.1)',
  },
});
