import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {MaterialDesignIcon} from 'components/Icons';

import styles from './style.css';

const LightModal = ({
  onCloseModal,
  children,
  closeButton = false,
  wide,
  onProceed,
  proceedButtonText,
}) => {
  const onBgClick = e => {
    if (e.currentTarget === e.target) onCloseModal();
  };

  return (
    <div className={styles.modalBg} onClick={onBgClick}>
      <div className={classNames([styles.modalItem, {[styles.wide]: wide}])}>
        {closeButton && (
          <div className={styles.modalCloseButton} onClick={onCloseModal}>
            <MaterialDesignIcon icon="close" className={styles.modalCloseButtonIcon} />
          </div>
        )}
        {proceedButtonText && (
          <div className={styles.modalProceedButton} onClick={onProceed}>
            {proceedButtonText}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

LightModal.propTypes = {
  onCloseModal: PropTypes.func,
  onProceed: PropTypes.func,
  proceedButtonText: PropTypes.string,
  children: PropTypes.node,
  closeButton: PropTypes.bool,
  wide: PropTypes.bool,
};

export default LightModal;
