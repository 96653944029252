import React, {ReactNode} from 'react';
import {makeStyles, createStyles} from '@material-ui/core';

import {purple} from '../../../consts/brand.integration';

interface Props {
  title: string | ReactNode;
  onTitleClick?: () => void;
  secondary?: ReactNode;
  onSecondaryClick?: () => void;
}

const Section: React.FC<Props> = ({title, onTitleClick, secondary, onSecondaryClick, children}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <div
          className={!onTitleClick ? classes.title : classes.titleClickable}
          onClick={onTitleClick}
        >
          {title}
        </div>
        <div
          className={!onSecondaryClick ? classes.secondary : classes.secondaryClickable}
          onClick={onSecondaryClick}
        >
          {secondary}
        </div>
      </div>
      {children && <div className={classes.content}>{children}</div>}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      padding: '10px 0',
      color: purple,
      borderBottom: `1px solid ${purple}`,
      marginBottom: '20px',
      textTransform: 'uppercase',
      fontSize: '13px',
      lineHeight: '7px',
      fontWeight: 500,
    },
    titleClickable: {
      cursor: 'pointer',
    },
    secondary: {
      marginLeft: 'auto',
      color: purple,
    },
    secondaryClickable: {
      composes: 'secondary',
      cursor: 'pointer',
    },
    content: {
      marginBottom: '50px',
    },
    title: {
      position: 'relative',
      width: '100%',
      margin: 'auto',
    },
  })
);

export default Section;
