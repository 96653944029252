import React, {useState, MouseEvent} from 'react';
import moment from 'moment';
import {Popover, Button} from '@material-ui/core';
import {Range} from 'react-date-range';
import classNames from 'classnames';

import DateRangePicker from '../DateRangePicker';
import CalendarIcon from '../CalendarIcon';
import {useStyles} from './styles';

interface Props {
  range: Range;
  onRangeChange: (range: Range) => void;
}

const DateFilter: React.FC<Props> = ({range, onRangeChange}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | Element | ((element: Element) => Element)>(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sanitizeRange = (range: {startDate?: Date | string | string; endDate?: Date | string}) => {
    const formatted: {startDate?: Date; endDate?: Date} = {};
    if (range.startDate) {
      formatted.startDate =
        typeof range.startDate === 'string' ? moment(range.startDate).toDate() : range.startDate;
    }
    if (range.endDate) {
      formatted.endDate =
        typeof range.endDate === 'string' ? moment(range.endDate).toDate() : range.endDate;
    }
    return formatted;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-filter-popover' : undefined;

  const renderButtonText = (range: Range) => {
    if (!range.startDate || !range.endDate) return 'All time';

    const formattedStartDate = moment(range.startDate).format('MMM D `YY');
    const formattedEndDate = moment(range.endDate).format('MMM D `YY');
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        startIcon={<CalendarIcon />}
        className={classNames({
          [classes.popoverButton]: true,
          [classes.open]: open,
        })}
        onClick={handleClick}
      >
        {renderButtonText(range)}
      </Button>
      <Popover
        id={id}
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <DateRangePicker range={sanitizeRange(range)} onRangeChange={onRangeChange} />
      </Popover>
    </>
  );
};

export default DateFilter;
