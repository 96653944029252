import {parseTwitchUrl, parseYoutubeUrl} from 'utils/postUtils';

export enum Platforms {
  Instagram = 'instagram',
  TikTok = 'tiktok',
  YouTube = 'youtube',
  Twitch = 'twitch',
  Snapchat = 'snapchat',
  Content = 'content_only',
}

export const PlatformsConfig = {
  [Platforms.Instagram]: {
    name: 'Instagram',
    icon: 'skill-icons:instagram',
    iconMono: 'mdi:instagram',
    path: (handle: string) => `https://instagram.com/${handle}`,
  },
  [Platforms.TikTok]: {
    name: 'TikTok',
    icon: 'logos:tiktok-icon',
    iconMono: 'ic:baseline-tiktok',
    path: (handle: string) => `https://www.tiktok.com/@${handle}`,
  },
  [Platforms.YouTube]: {
    name: 'YouTube',
    icon: 'logos:youtube-icon',
    iconMono: 'mdi:youtube',
    path: (handle: string) => parseYoutubeUrl(handle),
  },
  [Platforms.Twitch]: {
    name: 'Twitch',
    icon: 'logos:twitch',
    iconMono: 'cib:twitch',
    path: (handle: string) => parseTwitchUrl(handle),
  },
  [Platforms.Snapchat]: {
    name: 'Snapchat',
    icon: 'fa:snapchat-square',
    iconMono: 'fa:snapchat-square',
    path: (handle: string) => handle,
  },
  [Platforms.Content]: {
    name: 'Content',
    icon: 'ic:baseline-photo',
    iconMono: 'ic:baseline-photo',
    path: (handle: string) => null,
  },
};
