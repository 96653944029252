import React, {useState} from 'react';
import FileSaver from 'file-saver/FileSaver';

import {retrieveCampaignFinanceReport} from '../../../../services/api';
import {LoaderDots} from '../../../../components/Widgets';
import ErrorReporting from '../../../../services/error-reporting';
import styles from './styles.css';

type YearReports = {
  year: number;
  months: number[];
};

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const START_YEAR = 2018;

  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  const reports: YearReports[] = [];
  let year = currentYear;
  let months: number[] = [];

  while (year >= START_YEAR) {
    months = Array.from(Array(year === currentYear ? currentMonth : 12), (el, i) => i + 1);
    reports.push({year, months});
    year -= 1;
  }

  const retrieveReport = async (year: number, month: number) => {
    if (loading || isNaN(month) || isNaN(year)) return;
    setLoading(true);

    try {
      const data = await retrieveCampaignFinanceReport(year, month);
      if (data) {
        FileSaver.saveAs(data, `campaign-finance-report-${year}-${month}.csv`);
      }
    } catch (error) {
      ErrorReporting.captureError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderYearRow = (data: YearReports) => (
    <tr>
      <td>{data.year}</td>
      <td>{data.months.map(month => renderMonthCell(data.year, month))}</td>
    </tr>
  );

  const renderMonthCell = (year: number, month: number) => (
    <td>
      <div className={styles.link} onClick={() => retrieveReport(year, month)}>
        <span>{month < 10 ? `0${month}` : month}</span>
      </div>
    </td>
  );

  return (
    <div>
      {loading && <LoaderDots />}
      <div>
        <p>Select the report for download (.csv)</p>
      </div>
      <br />
      <table className={styles.reportTable} aria-label="Reports">
        <thead>
          <tr>
            <th>Year</th>
            <th>Month</th>
          </tr>
        </thead>
        <tbody>{reports.map(item => renderYearRow(item))}</tbody>
      </table>
    </div>
  );
};

export default Reports;
