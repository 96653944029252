import React from 'react';
import {Switch, Redirect, useRouteMatch} from 'react-router';

import {ACCOUNTING_ROLE} from '../consts/permissions';
import FinancesWrapper from '../scenes/Admin/Finances/FinancesWrapper/FinancesWrapper';
import Statistics from '../scenes/Admin/Finances/Statistics/Statistics';
import Approvals from '../scenes/Admin/Finances/Approvals/Approvals';
import Reports from '../scenes/Admin/Finances/Reports/Reports';
import SecureRoute from './components/SecureRoute';

/**
 * @path `/finances`
 */
export const Finances = (): JSX.Element => {
  const match = useRouteMatch();
  const {path} = match;

  return (
    <FinancesWrapper {...{match}}>
      <Switch>
        <SecureRoute
          path={`${path}/statistics`}
          permission={ACCOUNTING_ROLE}
          component={Statistics}
        />
        <SecureRoute
          path={`${path}/approvals`}
          permission={ACCOUNTING_ROLE}
          component={Approvals}
        />
        <SecureRoute path={`${path}/reports`} permission={ACCOUNTING_ROLE} component={Reports} />
        <Redirect exact from={`${path}`} to={`${path}/statistics`} />
      </Switch>
    </FinancesWrapper>
  );
};
