import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Box, Grid, Typography} from '@material-ui/core';
import {max} from 'date-fns';

import type {
  CampaignPaidAdsPerformanceResponse,
  CampaignPerformanceByGigResponse,
} from 'hooks/useDataServiceApi/types';
import {UserRoles} from 'consts/roles';
import useCurrentUser from 'hooks/useCurrentUser';

import {CampaignQuery, GigQuery} from './graphqlQueries';
import {LoaderDots, UnauthorizedAccess, FormattedDate} from '../../../components/Widgets';
import {LONG_DATE_FORMAT} from '../../../consts/variables';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import {
  CampaignPerformanceSummary,
  CampaignPerformanceByPlatform,
  CampaignPerformanceByInfluencer,
  CampaignPerformanceByGig,
  CampaignPaidPerformanceSummary,
  CampaignPaidAdvertPerformance,
  CampaignPaidPerformanceByPlatform,
  CampaignHeader,
  CampaignPerformanceInfluenscore,
} from '../components';
import ExportJsonToExcel from '../../../components/ExportJsonToExcel';
import {
  DataExport,
  HistoricalData,
  InstagramPaidAdPerformance,
  TikTokPaidAdPerformance,
} from '../components/CampaignPerformance/types';

type APIData = Record<string, DataExport | null> & {
  Gigs: CampaignPerformanceByGigResponse | [];
  'Paid Media (Instagram)'?: InstagramPaidAdPerformance[];
  'Paid Media (TikTok)'?: TikTokPaidAdPerformance[];
  'Paid Media Adverts'?: CampaignPaidAdsPerformanceResponse | [];
};

const getExcelData = (apiData: APIData) => {
  return {
    ...apiData,
    Gigs: apiData.Gigs?.map(
      ({campaign_id, advertiser_id, gig_id, influencer_id, influencer, ...data}) => ({
        campaign_id,
        advertiser_id,
        gig_id,
        influencer_id,
        influencer_name: influencer.full_name,
        ...data,
      })
    ),
    Creators: apiData.Creators?.map(
      ({
        /**
         * We extract these three keys and then include them manually so that
         * we can manually define the column order.
         */
        campaign_id,
        advertiser_id,
        influencer_id,
        /**
         * `influencer` is an object, which `ExportJsonToExcel` will render as a
         * blank cell. We therefore exclude it from the data.
         */
        influencer,
        ...data
      }) => ({
        campaign_id,
        advertiser_id,
        influencer_id,
        influencer_name: influencer.full_name,
        ...data,
      })
    ),
    ...(apiData['Paid Media Adverts']
      ? {
          'Paid Media Adverts': apiData['Paid Media Adverts'].map(data => ({
            ...data,
            influencer_id: apiData.Gigs.find(({gig_id}) => data.gig_id === gig_id)?.influencer_id,
            influencer_name: apiData.Gigs.find(({gig_id}) => data.gig_id === gig_id)?.influencer
              .full_name,
          })),
        }
      : {}),
  };
};

const CampaignPerformance: React.FC = () => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const campaignId = location.pathname.split('/')[3];
  const queryOptions = {variables: {campaignId}};
  const {data: campaignData} = useQuery<{campaign: Campaign}>(CampaignQuery, queryOptions);
  const {loading, data: gigData} = useQuery<{gigs: GigConnection}>(GigQuery, queryOptions);
  const campaign = campaignData?.campaign;
  const gigs = gigData?.gigs;
  const {showPerformanceReport, showCampaignPaidMediaPerformance, showCampaignInfluenscore} =
    useFeatureFlags();

  const brandData = {
    brand: campaign?.advertiser?.name ?? '',
    campaign: campaign?.name ?? '',
    id: campaign?.id ?? '',
  };

  const [historicalData, setHistoricalData] = useState<Array<Partial<HistoricalData[number]>>>([]);
  const [apiData, setApiData] = useState<APIData>({
    Summary: null,
    Platforms: null,
    Creators: null,
    Gigs: [],
    ...(showCampaignPaidMediaPerformance
      ? {
          'Paid Media Summary': null,
          'Paid Media (Instagram)': [],
          'Paid Media (TikTok)': [],
          'Paid Media Adverts': [],
        }
      : {}),
  });

  const handleApiData = (type: string, data: DataExport) => {
    const arrayData = Array.isArray(data) ? data : [];
    setApiData(prevState => ({...prevState, [type]: arrayData}));
  };

  const hasExportData =
    Object.values(apiData).every(data => data != null) &&
    (apiData.Summary as DataExport).length > 0;

  const lastUpdated = max([
    ...(apiData.Gigs ?? []).map(({generated_timestamp}) => new Date(generated_timestamp)),
    /**
     * We can safely use the non-null assertion here as HistoricalData is only a partial due to
     * filtering disabled settings. We never filter out the `generated_timestamp` field.
     */
    ...(historicalData ?? []).map(({generated_timestamp}) => new Date(generated_timestamp!)),
  ]);

  if (loading) {
    return <LoaderDots />;
  }

  if (!showPerformanceReport) {
    return <UnauthorizedAccess />;
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <CampaignHeader title="Campaign Performance" campaignId={campaignId} showProgress />
        </Grid>
        <Grid item xs={12}>
          {lastUpdated && (
            <Typography variant="caption">
              <i>
                Metrics refreshed at: <FormattedDate date={lastUpdated} format={LONG_DATE_FORMAT} />
              </i>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {currentUser?.roleName !== UserRoles.Advertiser && (
            <Box textAlign="right">
              <ExportJsonToExcel
                apiData={getExcelData(apiData)}
                brandData={brandData}
                disabled={!hasExportData}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <CampaignPerformanceSummary
        campaign={campaign}
        apiData={(data: DataExport) => handleApiData('Summary', data)}
        historicalData={historicalData}
        setHistoricalData={setHistoricalData}
      />
      <CampaignPerformanceByPlatform
        campaign={campaign}
        apiData={(data: DataExport) => handleApiData('Platforms', data)}
      />
      <CampaignPerformanceByInfluencer
        campaign={campaign}
        apiData={(data: DataExport) => handleApiData('Creators', data)}
      />
      <CampaignPerformanceByGig
        campaign={campaign}
        gigs={gigs}
        apiData={(data: DataExport) => handleApiData('Gigs', data)}
      />
      {showCampaignPaidMediaPerformance && (
        <>
          <CampaignPaidPerformanceSummary
            campaign={campaign}
            apiData={(data: DataExport) => handleApiData('Paid Media Summary', data)}
          />
          <CampaignPaidPerformanceByPlatform campaign={campaign} apiData={handleApiData} />
          <CampaignPaidAdvertPerformance
            campaign={campaign}
            gigs={gigs}
            apiData={(data: DataExport) => handleApiData('Paid Media Adverts', data)}
          />
        </>
      )}
      {showCampaignInfluenscore && <CampaignPerformanceInfluenscore campaign={campaign} />}
    </>
  );
};

export default CampaignPerformance;
