import React from 'react';

import {DocumentTitle, LogoWithText} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

import styles from './style.css';

const App404: React.FC = () => (
  <div className={styles.main}>
    <DocumentTitle title={getPageTitle('Page not found')} />
    <LogoWithText header={<span>404</span>} subtitle={<span>Page not found</span>} />
  </div>
);

export default App404;
