import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {
  MenuItem,
  Select,
  CircularProgress,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  Box,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';
import {Icon} from '@iconify/react';
import {createStyles, makeStyles} from '@material-ui/styles';

import {DocumentTitle} from 'components/Widgets';
import DebouncedTextInput from 'components/Widgets/DebouncedTextInput';

import {getPageTitle} from 'consts/utilities';
import {uploadImage} from 'services/api';
import {
  AdvertiserSettingsQuery,
  UpdateAdvertiserMutation,
  IndustryTreeQuery,
  AdvertiserIndustryQuery,
  UpdateAdvertiserIndustryMutation,
} from 'scenes/Advertiser/AdvertiserSettings/graphqlQuery';
import {CREATE_BRAND, VIEW_BRAND_INFO} from 'consts/permissions';

import IndustrySelector from './components/IndustrySelector';
import {ImageUploader} from './components';
import {Advertiser} from './types';
import AdvertiserTabs from '../AdvertiserTabs';
import useRequiresPermission from '../../../hooks/useRequiresPermission';
import {CountriesQuery} from '../AdvertiserCreate/graphqlQuery';
import {theme} from '../../../consts/theme';
import {placeholderIndustryStyle} from './components/IndustrySelector/styles';
import {black, inputPlaceholder, mainContentWidth} from '../../../consts/brand.integration';

interface Props {
  match: {
    params: {
      advertiser: string;
    };
  };
}

const AdvertiserSettings: React.FC<Props> = ({match: {params}}) => {
  const classes = useStyles();
  const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);
  const [isImageUploading, toggleIsImageUploading] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [updateAdvertiser] = useMutation(UpdateAdvertiserMutation);
  const {data, loading} = useQuery(AdvertiserSettingsQuery, {
    variables: {domain: params.advertiser},
  });
  const [updateAdvertiserIndustry] = useMutation(UpdateAdvertiserIndustryMutation);
  const {data: advertiserIndustriesData} = useQuery(AdvertiserIndustryQuery, {
    skip: !data,
    variables: {advertiserId: data?.advertiser.id},
  });
  const {data: industriesData} = useQuery(IndustryTreeQuery, {
    skip: !data,
  });
  const countriesResult = useQuery(CountriesQuery);

  const hasEditPermission = useRequiresPermission(CREATE_BRAND);
  const hasViewPermission = useRequiresPermission(VIEW_BRAND_INFO);
  const [errorDomain, setErrorDomain] = useState('');
  const [errorName, setErrorName] = useState('');

  useEffect(() => {
    if (data) {
      setAdvertiser(data.advertiser);
    }
  }, [data]);

  const handleSubmit = (name: string, value: string) => {
    let errors = false;
    if (name === 'domain') {
      if (!value.search(/^[a-zA-Z0-9_\-.\s]+$/)) {
        value.replace(/\s/g, '_').toLowerCase();
        setErrorDomain('');
      } else {
        setErrorDomain(
          "Invalid Brand Instagram Name (only special characters allowed  '-', '_' or '.')"
        );
        errors = true;
      }
    } else if (name === 'name') {
      if (!value.search(/^[A-Z0-9!&?]/i)) {
        setErrorName('');
      } else {
        setErrorName('Invalid Company name');
        errors = true;
      }
    }
    if (!errors) {
      updateAdvertiser({
        variables: {...advertiser, regionId: advertiser?.primaryRegion.id, [name]: value},
      });
    }
  };

  const handleImage = async (image: FileList | null) => {
    if (!image || !advertiser) {
      return;
    }
    toggleIsImageUploading(true);
    const imageUrl = await uploadImage(image[0]);
    if (!imageUrl) {
      toggleIsImageUploading(false);
      return;
    }
    updateAdvertiser({
      variables: {
        ...advertiser,
        regionId: advertiser?.primaryRegion.id,
        profilePicture: imageUrl,
      },
    });
    toggleIsImageUploading(false);
  };

  return (
    <>
      <div className={classes.root}>
        <AdvertiserTabs params={params} />
      </div>
      {advertiser && (
        <>
          <DocumentTitle title={getPageTitle(`${advertiser.name} - Settings`)} />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.title}>
                Brand settings
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12}>
                <Box textAlign="center">
                  <CircularProgress size={50} />
                </Box>
              </Grid>
            )}
            {!loading && (hasViewPermission || hasEditPermission) && (
              <>
                <Grid item xs={12} sm={6} style={{paddingLeft: isXs ? '1rem' : '5rem'}}>
                  <FormControl fullWidth>
                    <FormLabel id="brand-logo-label" htmlFor="brand-logo-image">
                      <Typography variant="h3" color="primary">
                        Logo
                      </Typography>
                    </FormLabel>
                    <Box width={200} marginTop=".5rem">
                      <ImageUploader
                        id="brand-logo-image"
                        labelId="brand-logo-label"
                        pending={isImageUploading}
                        image={advertiser.profilePicture}
                        square
                        size={140}
                        isReadOnly={!hasEditPermission}
                        onUpload={handleImage}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid container item xs={12} sm={6} direction="column" spacing={4}>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <FormLabel
                        id="brand-instagram-name-label"
                        htmlFor="brand-instagram-name-input"
                      >
                        <Typography variant="subtitle1" color="primary">
                          Brand Instagram Name
                        </Typography>
                      </FormLabel>
                      <DebouncedTextInput
                        id="brand-instagram-name-input"
                        labelId="brand-instagram-name-label"
                        value={advertiser.domain}
                        debounceDelay={1000}
                        placeholder="Add brand Instagram username"
                        errorText={errorDomain}
                        disabled={!hasEditPermission}
                        onChange={(domain: string) => handleSubmit('domain', domain)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs>
                    <FormControl fullWidth>
                      <FormLabel id="company-name-label" htmlFor="company-name-input">
                        <Typography variant="subtitle1" color="primary">
                          Company Name
                        </Typography>
                      </FormLabel>
                      <DebouncedTextInput
                        id="company-name-input"
                        labelId="company-name-label"
                        value={advertiser.name}
                        debounceDelay={1000}
                        placeholder="Add company name"
                        errorText={errorName}
                        disabled={!hasEditPermission}
                        onChange={(name: string) => handleSubmit('name', name)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs>
                    <FormControl fullWidth>
                      <FormLabel id="vat-number-label" htmlFor="vat-number-input">
                        <Typography variant="subtitle1" color="primary">
                          VAT number
                        </Typography>
                      </FormLabel>
                      <DebouncedTextInput
                        id="vat-number-input"
                        labelId="vat-number-label"
                        value={advertiser.vatNumber}
                        debounceDelay={1000}
                        placeholder="Add VAT number"
                        disabled={!hasEditPermission}
                        onChange={(vatNumber: string) => handleSubmit('vatNumber', vatNumber)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs>
                    <FormControl fullWidth>
                      <FormLabel id="region-label" htmlFor="region-select">
                        <Typography variant="subtitle1" color="primary">
                          Region
                        </Typography>
                      </FormLabel>
                      <Select
                        variant="outlined"
                        inputProps={{id: 'region-select', 'aria-labelledby': 'region-label'}}
                        value={advertiser.primaryRegion.id}
                        className={classes.select}
                        IconComponent={props => (
                          <KeyboardArrowDown
                            {...props}
                            style={{marginRight: '10px', color: theme.palette['ui-07']}}
                          />
                        )}
                        disabled={!hasEditPermission}
                        onChange={e => {
                          handleSubmit('regionId', e.target.value);
                        }}
                      >
                        {(countriesResult?.data?.countries ?? []).map(country => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs>
                    <FormControl fullWidth>
                      <FormLabel id="brand-industry-label" htmlFor="brand-industry-select">
                        <Typography variant="subtitle1" color="primary">
                          Industry (optional)
                        </Typography>
                      </FormLabel>
                      <Box>
                        <IndustrySelector
                          id="brand-industry-select"
                          labelId="brand-industry-label"
                          industries={industriesData?.industries ?? []}
                          selectedIndustries={advertiserIndustriesData?.advertiserIndustries}
                          isSelectedItemVisible
                          isReadOnly={!hasEditPermission}
                          styles={{
                            container: (base: any) => ({
                              ...base,
                              marginRight: '16px',
                              width: '450px',
                            }),
                            placeholder: (base: any) => ({
                              ...base,
                              ...placeholderIndustryStyle,
                              fontWeight: 400,
                              marginLeft: '6px',
                              color: inputPlaceholder,
                            }),
                          }}
                          onChange={industryId =>
                            updateAdvertiserIndustry({
                              variables: {
                                advertiserId: advertiser.id,
                                advertiserIndustryId: industryId,
                              },
                              refetchQueries: ['AdvertiserIndustryQuery'],
                            })
                          }
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <Box textAlign="right" marginRight={`${isXs ? '0' : '3rem'}`}>
                      <Button
                        variant="outlined"
                        color="primary"
                        aria-label="Archive brand"
                        startIcon={<Icon icon="mdi:archive-outline" />}
                        size="large"
                        onClick={() =>
                          updateAdvertiser({
                            variables: {
                              id: advertiser.id,
                              name: advertiser.name,
                              profilePicture: advertiser.profilePicture,
                              archived: !advertiser.archived,
                              influencerCooldown: advertiser.influencerCooldown,
                            },
                          })
                        }
                      >
                        {`${advertiser.archived ? 'Unarchive' : 'Archive'} brand`}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
    },
    title: {
      color: black,
      marginTop: '50px',
      marginBottom: '40px',
    },
    select: {
      width: '450px',
      height: '48px',
      marginRight: theme.spacing(2),
    },
  })
);

export default AdvertiserSettings;
