import React from 'react';
import {Box, CircularProgress, useTheme, useMediaQuery} from '@material-ui/core';

interface Props {
  loading?: boolean;
}

const LoaderDots: React.FC<Props> = ({loading = true}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        visibility: loading ? 'visible' : 'hidden',
        zIndex: 1000,
      }}
      data-testid="loader"
    >
      <CircularProgress color="primary" size={isXs ? 20 : 24} />
    </Box>
  );
};

export default LoaderDots;
