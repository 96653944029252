import React, {useState, useEffect} from 'react';

interface Props {
  url: string;
  width: number;
  height: number;
}

const TikTokEmbed: React.FC<Props> = ({url, width, height}) => {
  const [doc, setDoc] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`);
      const json = await response.json();
      setDoc(json.html);
    };

    fetchData();
  }, [url, setDoc]);

  if (!doc) return null;

  return <iframe frameBorder="0" width={width} height={height} srcDoc={doc} src="about:blank" />;
};

export default TikTokEmbed;
