import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.css';

const Error = ({error}) => {
  if (!error) {
    return null;
  }

  return <div className={styles.message}>{error}</div>;
};

Error.propTypes = {
  error: PropTypes.string,
};

export default Error;
