const serializeSection = (section: Record<any, any>) => {
  switch (section.type) {
    case 'paragraph':
      return {type: 'paragraph', value: section.data.text};
    case 'dosAndDonts':
      return {
        type: 'dosAndDonts',
        dos: section.data.items.filter(item => item.checked).map(item => item.text),
        donts: section.data.items.filter(item => !item.checked).map(item => item.text),
      };
    case 'header':
      return {
        type: section.data.level === 1 ? 'heading' : 'subHeading',
        value: section.data.text,
      };
    case 'list':
      return {
        type: section.data.style === 'ordered' ? 'orderedList' : 'unorderedList',
        items: section.data.items,
      };
    case 'important':
      return {
        type: 'important',
        value: section.data.value,
      };
    case 'divider':
      return {
        type: 'divider',
      };
    default:
      return null;
  }
};

export const serialize = (data: Record<any, any>[]) => {
  const serialized = data.map(serializeSection);
  return serialized;
};

export const deserializeSection = (section: Record<any, any>) => {
  switch (section.__typename) {
    case 'BriefParagraph':
      return {type: 'paragraph', data: {text: section.value}};
    case 'BriefDosAndDonts':
      return {
        type: 'dosAndDonts',
        data: {
          items: [
            ...section.dos.map(text => ({checked: true, text})),
            ...section.donts.map(text => ({checked: false, text})),
          ],
        },
      };
    case 'BriefHeading':
      return {type: 'header', data: {level: 1, text: section.value}};
    case 'BriefSubHeading':
      return {type: 'header', data: {level: 2, text: section.value}};
    case 'BriefUnorderedList':
      return {
        type: 'list',
        data: {items: section.items, style: 'unordered'},
      };
    case 'BriefOrderedList':
      return {
        type: 'list',
        data: {items: section.items, style: 'ordered'},
      };
    case 'BriefImportant':
      return {
        type: 'important',
        data: {value: section.value},
      };
    case 'BriefDivider':
      return {
        type: 'divider',
        data: {},
      };
    default:
      return null;
  }
};

export const deserialize = (data: Record<any, any>[]) => {
  if (data) {
    return data.map(deserializeSection);
  } else {
    return [];
  }
};
