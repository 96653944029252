// eslint-disable-next-line import/named
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {
  mainContentWidth,
  black,
  red,
  white,
  semiBold,
  inputPlaceholder,
} from '../../../consts/brand.integration';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
      paddingLeft: 16,
    },
    backButton: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
    },
    title: {
      color: black,
      marginTop: '50px',
      marginBottom: '40px',
    },
    label: {
      color: black,
      marginBottom: '4px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
    control: {
      width: '450px',
      height: '48px',
      marginRight: theme.spacing(2),
      fontSize: '16px',
      '& .MuiInputBase-root': {
        height: '48px',
      },
    },
    button: {
      height: '40px',
      maxWidth: '165px',
      marginTop: '28px',
      color: white,
      backgroundColor: black,
      '&:hover': {
        color: black,
        backgroundColor: 'transparent',
      },
    },
    error: {
      fontWeight: semiBold,
      color: red,
      fontSize: 12,
      marginLeft: '14px',
      marginRight: '14px',
    },
    helperText: {
      '&.MuiFormHelperText-root.Mui-error': {
        fontWeight: semiBold,
        color: red,
      },
    },
    inactiveSelect: {
      color: inputPlaceholder,
      fontSize: '14px',
      fontWeight: 400,
    },
    inputText: {
      '&::placeholder': {
        color: inputPlaceholder,
        fontWeight: 400,
        opacity: 1,
      },
      fontSize: '14px',
      fontWeight: 600,
    },
  })
);
