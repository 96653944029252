import PropTypes from 'prop-types';
/* eslint-disable max-len */
import React from 'react';

const SalesLogo = ({height = 21}) => {
  return (
    <svg
      width={`${198 * (height / 21)}px`}
      height={`${height}px`}
      viewBox="0 0 198 21"
      version="1.1"
    >
      <g id="Overview" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" fill="#000000">
          <path
            d="M19.2169645,0 L19.2169645,3.1850252 L11.794751,3.1850252 L11.794751,19.4868774 L7.42247529,19.4868774 L7.42247529,3.1850252 L0,3.1850252 L0,0 L19.2169645,0 Z M26.7484259,0 L32.2542837,0 L42.2135742,19.4868774 L37.0315597,19.4868774 L34.7913607,15.1146017 L23.5636625,15.1146017 L21.5124812,19.4868774 L16.8161005,19.4868774 L26.7484259,0 Z M33.226075,11.9298383 L29.1776425,3.45493815 L25.1286864,11.9298383 L33.226075,11.9298383 Z M48.3149421,0 L48.3149421,8.50186531 L56.5468944,0 L61.9176648,0 L53.3351659,8.77177826 L62.9433863,19.4868774 L57.1676157,19.4868774 L48.3149421,9.23070882 L48.3149421,19.4868774 L43.9424046,19.4868774 L43.9424046,0 L48.3149421,0 Z M64.5641992,0 L68.9364749,0 L68.9364749,11.9028732 C68.9364749,15.1413051 71.0146214,16.9767655 74.3614373,16.9767655 C77.6001309,16.9767655 79.7594345,15.3845147 79.7594345,11.7944892 L79.7594345,0 L84.1317102,0 L84.1317102,11.7135938 C84.1317102,16.1670266 82.2695464,20 73.686524,20 C68.9631782,20 64.5641992,17.5974867 64.5641992,12.4963676 L64.5641992,0 Z M94.9561882,0 L102.32421,15.8701486 L109.557667,0 L116.845055,0 L116.845055,19.4868774 L112.472518,19.4868774 L112.472518,2.75305976 L112.418588,2.75305976 L104.807357,19.4868774 L99.4635513,19.4868774 L91.6635644,2.75305976 L91.6096341,2.75305976 L91.6096341,19.4868774 L87.6690621,19.4868774 L87.6690621,0 L94.9561882,0 Z M121.313437,0 L125.685974,0 L125.685974,19.4868774 L121.313437,19.4868774 L121.313437,0 Z"
            id="Logotype-Copy-5"
          />
          <path
            d="M146.961,5.447 C146.961,2.639 146.097,0.128 142.047,0.128 C138.861,0.128 136.728,1.532 136.755,4.907 C136.782,6.851 136.944,8.012 140.724,10.334 C143.532,12.062 145.287,12.818 145.287,15.464 C145.287,17.705 144.18,18.92 141.912,18.92 C138.429,18.92 138.267,16.679 138.267,14.519 L136.485,14.519 C136.323,18.326 138.024,20.378 141.831,20.378 C145.233,20.378 147.015,18.677 147.015,15.194 C147.015,11.414 144.558,10.901 142.155,9.173 C139.941,7.58 138.483,7.499 138.483,4.61 C138.483,2.315 139.806,1.586 141.912,1.586 C144.558,1.586 145.152,3.044 145.206,5.447 L146.961,5.447 Z M155.007,0.506 L157.059,0.506 L163.134,20 L161.325,20 L159.759,14.87 L152.118,14.87 L150.579,20 L148.851,20 L155.007,0.506 Z M152.631,13.412 L159.3,13.412 L155.979,2.396 L155.925,2.396 L152.631,13.412 Z M173.502,20 L173.502,18.542 L167.049,18.542 L167.049,0.506 L165.321,0.506 L165.321,20 L173.502,20 Z M176.04,0.506 L176.04,20 L185.058,20 L185.058,18.542 L177.768,18.542 L177.768,10.712 L184.545,10.712 L184.545,9.254 L177.768,9.254 L177.768,1.964 L184.869,1.964 L184.869,0.506 L176.04,0.506 Z M197.964,5.447 C197.964,2.639 197.1,0.128 193.05,0.128 C189.864,0.128 187.731,1.532 187.758,4.907 C187.785,6.851 187.947,8.012 191.727,10.334 C194.535,12.062 196.29,12.818 196.29,15.464 C196.29,17.705 195.183,18.92 192.915,18.92 C189.432,18.92 189.27,16.679 189.27,14.519 L187.488,14.519 C187.326,18.326 189.027,20.378 192.834,20.378 C196.236,20.378 198.018,18.677 198.018,15.194 C198.018,11.414 195.561,10.901 193.158,9.173 C190.944,7.58 189.486,7.499 189.486,4.61 C189.486,2.315 190.809,1.586 192.915,1.586 C195.561,1.586 196.155,3.044 196.209,5.447 L197.964,5.447 Z"
            id="Sales"
          />
        </g>
      </g>
    </svg>
  );
};

SalesLogo.propTypes = {
  height: PropTypes.number,
};

export default SalesLogo;
