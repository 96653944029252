import PropTypes from 'prop-types';
import React from 'react';

const InstagramImage = ({src, size}) => {
  let resizedSrc = src;

  if (size && src) {
    const dpi = window.devicePixelRatio;
    const supportedSizes = [80, 120, 160, 320, 480, 960, 1080];

    const selectedSize = supportedSizes.find(s => s > size * dpi);

    if (selectedSize !== undefined) {
      const params = src.split('/');
      const index = params.findIndex(p => p.startsWith('t'));

      if (index > -1) {
        params.splice(index + 1, 0, `s${selectedSize}x${selectedSize}`);
        resizedSrc = params.join('/');
      }
    }
  }

  return <img alt="Instagram post" src={resizedSrc} />;
};

InstagramImage.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default InstagramImage;
