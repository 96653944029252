import moment from 'moment';

import 'moment-duration-format';
import {RESERVED, REPORTED, REJECTED} from './variables';

export function formatExpiresIn(seconds: number) {
  return moment.duration(seconds, 'seconds').format('d[d] h[h] m[m]');
}

export function getOverlayText(gig) {
  if (gig.state === 'awaiting_shipping') {
    return {
      text: 'Awaiting product dispatch',
    };
  }
  if (gig.state === 'in_transit') {
    return {
      text: 'In transit',
    };
  }
  if (gig.state === RESERVED) {
    return {
      text: 'Awaiting submission',
    };
  }
  if (gig.state === REPORTED) {
    return {
      text: 'Reported',
      subText: gig.reportReason,
    };
  }
  if (gig.state === REJECTED) {
    return {
      text: 'Rejected',
      subText: gig.rejectReason,
    };
  }
  return {
    text: '',
    subText: '',
  };
}

export function isLive(gig: Gig) {
  if (gig.instagramContent) {
    if (gig.instagramContent.__typename == 'InstagramPost') {
      return !gig.instagramContent.deleted;
    }
  }
  return false;
}

export function isDeleted(gig: Gig) {
  return gig.instagramContent?.deleted;
}

export function latestMedia(gig: Gig, ignoreVerified = false) {
  if (gig.isVerified || ignoreVerified) {
    if (gig.instagramContent?.link && gig.post?.postType === 'tiktok') {
      return {
        url: gig.instagramContent.link,
        __typename: 'TikTok',
      };
    }

    if (gig.instagramContent?.media?.url) {
      return gig.instagramContent.media;
    }

    if (gig.instagramContent?.media?.items) {
      return gig.instagramContent.media;
    }
  }

  if (gig.submission) {
    return gig.submission.media;
  }
  return null;
}

export function hasMedia(gig: Gig, ignoreVerified = false) {
  return !!latestMedia(gig, ignoreVerified);
}

export function followers(gig: Gig) {
  return gig.instagramContent ? gig.instagramContent.followers : gig.influencer?.followers;
}
