import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {CampaignHeader, CampaignShippingTable} from '../components';
import {CampaignShippingQuery, CampaignShippingParticipantsQuery} from './graphqlQueries';

interface Props {
  campaign: Campaign;
  offers: Offer;
}

const CampaignShipping: React.FC<Props> = () => {
  const location = useLocation();
  const campaignId = location.pathname.split('/')[3];
  const [loading, setLoading] = useState<boolean>(true);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [offers, setOffers] = useState<Offer | undefined>(undefined);
  const {data: campaignData, loading: isLoading} = useQuery(CampaignShippingQuery, {
    variables: {
      campaignId,
    },
  });
  const {data: offerData} = useQuery(CampaignShippingParticipantsQuery, {
    variables: {
      campaignId,
      state: 'accepted',
    },
  });

  useEffect(() => {
    if (campaignData) {
      setCampaign(campaignData.campaign);
    }
    if (offerData) {
      setOffers(offerData?.offers);
      setLoading(isLoading);
    }
  }, [campaignData, offerData, isLoading]);

  return (
    <>
      <CampaignHeader title="Shipping Information" campaignId={campaignId} />
      {campaign && <CampaignShippingTable offers={offers} loading={loading} />}
    </>
  );
};

export default CampaignShipping;
