import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Divider, Typography, createStyles, makeStyles} from '@material-ui/core';
import {gql, useMutation, useQuery} from '@apollo/client';

import {DocumentTitle, LoaderDots} from 'components/Widgets';

import {mainContentWidth} from 'consts/brand';
import {getPageTitle} from 'consts/utilities';

import AdvertiserTabs from '../AdvertiserTabs';
import AddSocialAccountModal from './AddSocialAccountModal';
import AdvertiserSocialsTable from './AdvertiserSocialsTable';

const AdvertiserSocials: React.FC = () => {
  const classes = useStyles();
  const params = useParams<{advertiser: string}>();
  const {advertiser: domain} = params;

  const {loading, error, data} = useQuery(AdvertiserSocialsQuery, {
    variables: {domain},
  });

  const advertiserId = data?.advertiser.id;

  const [addProfile, addProfileMutation] = useMutation(AddAdvertiserSocialAccount, {
    refetchQueries: ['AdvertiserSocialsQuery'],
  });

  const [removeProfile] = useMutation(RemoveAdvertiserSocialAccount, {
    refetchQueries: ['AdvertiserSocialsQuery'],
  });

  const onSubmit = async (
    mutation: typeof addProfile,
    socialUrl: string,
    closeDialog: () => void
  ) => {
    await mutation({variables: {advertiserId, socialUrl}});
    closeDialog();
  };

  return (
    <div className={classes.wrapper}>
      <DocumentTitle title={getPageTitle(`${data?.advertiser.name} - Socials`)} />
      <AdvertiserTabs params={params} />
      {(() => {
        if (loading) {
          return <LoaderDots />;
        }

        return (
          <>
            <Box
              className={classes.box}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h3">Social Accounts</Typography>
              <AddSocialAccountModal
                title="Add Account"
                mutation={addProfile}
                loading={addProfileMutation.loading}
                error={addProfileMutation.error?.message}
                onSubmit={onSubmit}
              />
            </Box>
            <Divider className={classes.spacing} />
            <AdvertiserSocialsTable
              data={data.advertiser.socialAccounts}
              remove={socialAccountId =>
                removeProfile({variables: {advertiserId, socialAccountId}})
              }
            />

            <Box
              className={classes.box}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h3">Competitors</Typography>
            </Box>
            <Divider className={classes.spacing} />
            <AdvertiserSocialsTable data={data.advertiser.competitorsSocialAccounts} />
          </>
        );
      })()}
    </div>
  );
};

const AdvertiserSocialsQuery = gql`
  query AdvertiserSocialsQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      socialAccounts {
        ... on InstagramAccount {
          __typename
          id
          username
          followers
          profilePicture
        }
        ... on TikTokAccount {
          __typename
          id
          username
          nickname
          followers
          cover
        }
      }
      competitorsSocialAccounts {
        ... on InstagramAccount {
          __typename
          id
          username
          followers
          profilePicture
        }

        ... on TikTokAccount {
          __typename
          id
          username
          nickname
          followers
          cover
        }
      }
    }
  }
`;

const AddAdvertiserSocialAccount = gql`
  mutation AddAdvertiserSocialAccount($advertiserId: UUIDString!, $socialUrl: String!) {
    addAdvertiserSocialAccount(advertiserId: $advertiserId, socialUrl: $socialUrl) {
      ok
    }
  }
`;

const RemoveAdvertiserSocialAccount = gql`
  mutation RemoveAdvertiserSocialAccount(
    $advertiserId: UUIDString!
    $socialAccountId: UUIDString!
  ) {
    removeAdvertiserSocialAccount(advertiserId: $advertiserId, socialAccountId: $socialAccountId) {
      ok
    }
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
      padding: 0,
    },
    root: {
      maxWidth: mainContentWidth,
    },
    box: {
      marginTop: '4.5rem',
    },
    spacing: {
      margin: '10px auto 20px',
    },
  })
);

export default AdvertiserSocials;
