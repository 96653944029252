import PropTypes from 'prop-types';
import React from 'react';

const LeftChevron = ({color = '#FFFFFF'}) => (
  <svg
    width="27px"
    height="62px"
    viewBox="0 0 27 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="Gig-–-Reported"
        transform="translate(-23.000000, -378.000000)"
        stroke={color}
        strokeWidth="4"
      >
        <path d="M46.3522863,381 L26,408.68464 L46.3522863,436.590655" id="Left" />
      </g>
    </g>
  </svg>
);

LeftChevron.propTypes = {
  color: PropTypes.string,
};

export default LeftChevron;
