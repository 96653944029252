import {socialIcons} from './icons';

export const CampaignPostType = {
  standard: 'standard',
  video: 'video',
  story: 'story',
  reel: 'reel',
  tiktok: 'tiktok',
  youtube: 'youtube', //legacy
  youtube_short: 'youtube_short',
  youtube_video: 'youtube_video',
  twitch: 'twitch',
  content: 'content',
};

export const InstagramPostTypes = [
  CampaignPostType.standard,
  CampaignPostType.video,
  CampaignPostType.story,
  CampaignPostType.reel,
];

export const InstagramPostTypesWithInsights = [
  CampaignPostType.standard,
  CampaignPostType.video,
  CampaignPostType.reel,
];

export const TiktokPostTypes = [CampaignPostType.tiktok];

export const YoutubePostTypes = [
  CampaignPostType.youtube, //legacy
  CampaignPostType.youtube_video,
  CampaignPostType.youtube_short,
];

export const TwitchPostTypes = [CampaignPostType.twitch];

export const getPostTypeFormatted = (postType: string): string => {
  switch (postType) {
    case 'standard':
      return 'Instagram Static';
    case 'reel':
      return 'Instagram Reel';
    case 'story':
      return 'Instagram Story';
    case 'video':
      return 'Instagram Video';
    case 'tiktok':
      return 'TikTok';
    case 'youtube': //legacy
      return 'YouTube';
    case 'youtube_short':
      return 'YouTube Short';
    case 'youtube_video':
      return 'YouTube Video';
    case 'twitch':
      return 'Twitch';
    case 'content':
      return 'Content Only';
    default:
      return postType;
  }
};

export const getPostTypePlatform = (postType: string): string => {
  switch (postType) {
    case 'standard':
    case 'reel':
    case 'story':
    case 'video':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    case 'youtube': //legacy
    case 'youtube_video':
    case 'youtube_short':
      return 'YouTube';
    case 'twitch':
      return 'Twitch';
    case 'content':
      return 'Content';
    default:
      return postType;
  }
};

export const getDeliverablePostTypeIcons = (campaignPosts: Array<{postType: string}>) => {
  return campaignPosts
    .map(({postType}) => postType)
    .sort()
    .map(postType => ({
      name: getPostTypePlatform(postType),
      icon: socialIcons[postType],
    }))
    .filter(({name}, i, arr) => arr.findIndex(el => el.name === name) === i);
};
