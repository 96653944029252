import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import update from 'immutability-helper';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Link, withRouter} from 'react-router-dom';
import * as qs from 'query-string';

import {
  DocumentTitle,
  Button,
  Column,
  LoaderDots,
  Prompt,
  RequiresPermission,
  Row,
} from 'components/Widgets';
import {TableHeader, TableBody} from 'components/Widgets/Table';
import tableStyles from 'components/Widgets/Table/style.css';

import {DND_ITEM_TYPES} from 'consts/variables';
import {getPageTitle, numberWithCommas, singularOrPlural} from 'consts/utilities';

import {CommentsModal, PromptsModal} from './components';
import styles from './style.css';
import InfluencerProfileCard from '../../../components/Widgets/InfluencerProfileCard/InfluencerProfileCard';
import {CampaignHeader} from '../components';
import {Influencer} from '../../../services/fragments';

class DraggableTableBody extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        render: PropTypes.func,
        footer: PropTypes.shape({
          reducer: PropTypes.func,
        }),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    emptyMessage: PropTypes.string,
    keySelector: PropTypes.func,
    className: PropTypes.string,
    droppableId: PropTypes.string.isRequired,
  };

  getListStyle = _isDraggingOver => ({
    width: '1080px',

    // background: isDraggingOver ? 'lightblue' : undefined,
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    boxShadow: isDragging
      ? 'rgba(9, 30, 66, 0.1) 0px 4px 8px -2px, rgba(9, 30, 66, 0.1) 0px 0px 1px'
      : undefined,
    display: isDragging ? 'table' : undefined,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  render() {
    const {data, droppableId, keySelector, emptyMessage, columns, className} = this.props;

    return (
      <Droppable droppableId={droppableId} type={DND_ITEM_TYPES.TABLE_ROW}>
        {(provided, snapshot) => (
          <tbody
            ref={provided.innerRef}
            style={this.getListStyle(snapshot.isDraggingOver)}
            className={className}
          >
            {data.size === 0 || data.length === 0 ? (
              <tr>
                <td className={tableStyles.emptyMessage} colSpan={200}>
                  {emptyMessage}
                </td>
              </tr>
            ) : (
              data.map((d, i) => {
                const key = keySelector ? keySelector(d) : d.id;
                const index = i;
                return (
                  <Draggable
                    key={key}
                    draggableId={key}
                    index={index}
                    isDragDisabled={d.isDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={styles.row}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {columns.map(col => {
                          const itemData = typeof col.data === 'function' ? col.data(d) : col.data;
                          return (
                            <td
                              key={`${key}-${col.key || col.heading}`}
                              className={tableStyles.item}
                              width={col.width ? `${col.width}%` : null}
                            >
                              {col.render ? col.render(itemData) : itemData}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                );
              })
            )}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    );
  }
}

class CampaignBrandMatch extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        campaign: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    setApplyFirstOfferPosition: PropTypes.func.isRequired,
    rejectCandidate: PropTypes.func.isRequired,
    approveCandidates: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId == 'playlist') {
      // Nothing to do
      if (result.source.index == result.destination.index) {
        return;
      }

      this.props.setApplyFirstOfferPosition(result.source.index, result.destination.index);
    }
  };

  columns = {
    influencer: {
      heading: 'Influencer',
      data: d => <InfluencerProfileCard influencer={d.influencer} />,
      footer: {
        reducer: accumulator => accumulator + 1,
        render: f => `${numberWithCommas(f)} influencers`,
      },
      width: 30,
    },
    engagement: {
      heading: 'Average engagement rate',
      data: d => (
        <div>
          {d.influencer.instagramAccount && (
            <div>
              Instagram: {d.influencer.instagramAccount.engagement?.formattedValue ?? 'n/a'}
            </div>
          )}
          {d.influencer.tiktokAccount && (
            <div>
              TikTok: {d.influencer.tiktokAccount.engagementRate90days?.formattedValue ?? 'n/a'}
            </div>
          )}
          {d.influencer.youtubeChannel && (
            <div>
              YouTube:{' '}
              {d.influencer.youtubeChannel.engagementRateByImpressions
                ? `${numberWithCommas(d.influencer.youtubeChannel.engagementRateByImpressions)}%`
                : 'n/a'}
            </div>
          )}
          {d.influencer.twitchChannel && <div>Twitch: n/a</div>}
        </div>
      ),
      width: 20,
    },
    prompts: {
      heading: '',
      key: 'prompts',
      data: offer => (
        <Link
          to={location => {
            const params = qs.parse(location.search);
            return {
              ...location,
              search: qs.stringify({...params, prompts: offer.id}),
            };
          }}
          className={styles.comments}
        >
          prompts
        </Link>
      ),
      width: 20,
    },
    comment: {
      heading: '',
      key: 'comment',
      data: offer => {
        const unseenCount = offer.comments.reduce(
          (acc, comment) => acc + (comment.seen ? 0 : 1),
          0
        );

        return (
          <Link
            to={location => {
              const params = qs.parse(location.search);
              return {
                ...location,
                search: qs.stringify({...params, offer: offer.id}),
              };
            }}
            className={styles.comments}
          >
            comments&hellip;
            {offer.comments.length > 0 && (
              <div className={styles.countBubbleWrapper}>
                <div className={styles.countBubbleAll}>{offer.comments.length}</div>
                {unseenCount > 0 && <div className={styles.countBubbleUnseen}>{unseenCount}</div>}
              </div>
            )}
          </Link>
        );
      },
      width: 20,
    },
    action: {
      heading: '',
      key: 'action',
      data: offer => {
        switch (offer.state) {
          case 'rejected_by_brand':
            return 'Rejected';
          case 'approved_by_brand':
          case 'accepted':
            return 'Approved';
          case 'candidate':
            return (
              <RequiresPermission permission="advertiser">
                <Prompt
                  title="Reject influencer?"
                  body="Are you sure you want to reject this influencer?"
                  confirmText="Reject influencer"
                  control={<Button small dangerOutline text="Reject influencer&hellip;" />}
                  onSubmit={() => this.props.rejectCandidate(offer.id)}
                />
              </RequiresPermission>
            );
          default:
            return null;
        }
      },
      width: 15,
    },
  };

  render() {
    const {data, location, history} = this.props;
    const params = qs.parse(location.search);
    const {campaign, offers, approvedOffers, rejectedOffers} = data;
    if (!campaign || !offers) {
      return <LoaderDots />;
    }
    if (!campaign.applyFirst) {
      return <div>Not an apply first campaign</div>;
    }

    const title = `${campaign.advertiser.name} - ${campaign.name} - Brand match`;

    const offersData = offers.edges
      .map(edge => edge.node)
      .filter(offer => offer.state === 'candidate');
    const selectedCount = offersData.length;

    const approvedOffersData = approvedOffers.edges.map(edge => edge.node);
    const rejectedOffersData = rejectedOffers.edges.map(edge => edge.node);

    const columns = [this.columns.influencer, this.columns.engagement]
      .concat(campaign.hasBrandVisiblePrompts ? [this.columns.prompts] : [])
      .concat([this.columns.comment, this.columns.action]);

    const isReachCampaign = campaign.rewardModel === 'reach';

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle(title)} />

        <div className={styles.playlistWrapper}>
          <div className={styles.heading}>
            <Row noMargin noPadding>
              <CampaignHeader title="Brand match" campaignId={params.campaign} />
            </Row>
            <Row noMargin noPadding>
              <Column right>
                <RequiresPermission permission="advertiser">
                  <Prompt
                    title={`Approve ${selectedCount} ${singularOrPlural(
                      'influencer',
                      selectedCount
                    )}`}
                    body="All influencers that you haven't rejected will be eligible for your campaign"
                    confirmText="Approve influencers"
                    control={
                      <Button text="Approve influencers&hellip;" disabled={selectedCount === 0} />
                    }
                    onSubmit={this.props.approveCandidates}
                  />
                </RequiresPermission>
              </Column>
            </Row>
          </div>

          <div className={styles.instructions}>
            {isReachCampaign ? (
              <>
                <p>
                  The following curated list of influencers have expressed interest in your
                  campaign.
                </p>
                <p>
                  Please aim to select influencers to hit the combined estimated follower count as
                  agreed with your <b>TAKUMI</b> representative.
                </p>
              </>
            ) : (
              <>
                <p>The following influencers have expressed interest in your campaign.</p>
                <p>Please aim to accept at least 50% of the list.</p>
              </>
            )}
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <table className={tableStyles.table}>
              <TableHeader columns={columns} />
              <DraggableTableBody
                droppableId="playlist"
                columns={columns}
                data={offersData}
                emptyMessage="No influencers to approve"
              />
            </table>
          </DragDropContext>

          <div className={styles.heading}>Approved influencers</div>
          <table className={tableStyles.table}>
            <TableHeader columns={columns} />
            <TableBody
              columns={columns}
              data={approvedOffersData}
              emptyMessage="No approved influencers"
            />
          </table>

          <div className={styles.heading}>Rejected influencers</div>
          <table className={tableStyles.table}>
            <TableHeader columns={columns} />
            <TableBody
              columns={columns}
              data={rejectedOffersData}
              emptyMessage="No rejected influencers"
            />
          </table>
        </div>
        <PromptsModal
          offerId={params.prompts}
          closeModal={() => {
            history.replace({
              ...location,
              search: qs.stringify({...params, prompts: undefined}),
            });
          }}
        />
        <CommentsModal
          offerId={params.offer}
          closeModal={() => {
            history.replace({
              ...location,
              search: qs.stringify({...params, offer: undefined}),
            });
          }}
        />
      </div>
    );
  }
}

const BrandMatchQuery = gql`
  query BrandMatchQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      brandMatch
      applyFirst
      interestIds
      pushNotificationMessage
      public
      state
      hash
      hasBrandVisiblePrompts
      advertiser {
        id
        name
      }
      participation {
        type
        count
      }
      rewardModel
    }

    offers: offersCandidates(campaignId: $campaignId) {
      edges {
        node {
          id
          ...brandMatchOffer
        }
      }
    }

    approvedOffers: offersApprovedByBrand(campaignId: $campaignId) {
      edges {
        node {
          id
          ...brandMatchOffer
        }
      }
    }

    rejectedOffers: offersRejectedByBrand(campaignId: $campaignId) {
      edges {
        node {
          id
          ...brandMatchOffer
        }
      }
    }
  }

  fragment brandMatchOffer on Offer {
    id
    state
    created
    comments {
      id
      seen
    }
    influencer {
      id
      fullName
      profilePicture
      ...socialPlatformsFields
    }
  }
  ${Influencer.socialPlatformsFragment}
`;

const SetApplyFirstOfferPositionMutation = gql`
  mutation SetApplyFirstOfferPositionMutation(
    $id: UUIDString!
    $fromIndex: Int!
    $toIndex: Int!
    $hash: String!
  ) {
    setApplyFirstOfferPosition(id: $id, fromIndex: $fromIndex, toIndex: $toIndex, hash: $hash) {
      hash
    }
  }
`;

const RejectCandidateMutation = gql`
  mutation RejectCandidateMutation($offerId: UUIDString!) {
    rejectCandidate(offerId: $offerId) {
      ok
      offer {
        id
        state
        campaign {
          id
          participation {
            type
            count
          }
        }
      }
    }
  }
`;

const ApproveCandidatesMutation = gql`
  mutation ApproveCandidatesMutation($campaignId: UUIDString!) {
    approveCandidates(campaignId: $campaignId) {
      ok
      campaign {
        id
        participation {
          type
          count
        }
      }
      offers {
        id
        state
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(BrandMatchQuery, {
    options: ({match: {params}}) => ({
      variables: {
        campaignId: params.campaign,
      },
    }),
  }),
  graphql(RejectCandidateMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
        data: {campaign},
      },
    }) => {
      return {
        rejectCandidate: offerId =>
          mutate({
            variables: {offerId},
            refetchQueries: ['BrandMatchQuery', 'OffersDeclinedQuery'],
            update: (proxy, {data: {rejectCandidate}}) => {
              try {
                const variables = {campaignId: params.campaign};
                const data = proxy.readQuery({
                  query: BrandMatchQuery,
                  variables,
                });

                proxy.writeQuery({
                  query: BrandMatchQuery,
                  data: {
                    ...data,
                    offers: {
                      ...data.offers,
                      edges: data.offers.edges.filter(e => e.node.id !== rejectCandidate.offer.id),
                    },
                    rejectedOffers: {
                      ...data.rejectedOffers,
                      edges: [
                        ...data.rejectedOffers.edges,
                        {
                          ...data.offers.edges.find(e => e.node.id === rejectCandidate.offer.id),
                          state: 'rejected_by_brand',
                        },
                      ],
                    },
                  },
                  variables,
                });
              } catch (err) {
                // No events to update.
                console.error(err);
                return;
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              rejectCandidate: {
                __typename: 'RejectCandidate',
                ok: true,
                offer: {
                  __typename: 'Offer',
                  id: offerId,
                  state: 'rejected_by_brand',
                  campaign: {
                    __typename: 'Campaign',
                    id: campaign.id,
                    participation: campaign.participation.reduce(
                      (acc, n) =>
                        n.type === 'candidate'
                          ? [
                              ...acc,
                              {type: 'candidate', count: n.count - 1, __typename: 'Participation'},
                            ]
                          : [...acc, n],
                      []
                    ),
                  },
                },
              },
            },
          }),
      };
    },
  }),
  graphql(ApproveCandidatesMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
        data: {campaign, offers},
      },
    }) => {
      return {
        approveCandidates: () =>
          mutate({
            variables: {campaignId: params.campaign},
            refetchQueries: ['OffersApprovedByBrandQuery', 'BrandMatchQuery'],
            update: proxy => {
              try {
                const variables = {campaignId: params.campaign};
                const data = proxy.readQuery({
                  query: BrandMatchQuery,
                  variables,
                });

                proxy.writeQuery({
                  query: BrandMatchQuery,
                  data: {
                    ...data,
                    offers: {
                      ...data.offers,
                      edges: [],
                    },
                    approvedOffers: {
                      ...data.approvedOffers,
                      edges: [...data.approvedOffers.edges, ...data.offers.edges],
                    },
                  },
                  variables,
                });
              } catch (err) {
                // No events to update.
                return;
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              approveCandidates: {
                __typename: 'ApproveCandidates',
                ok: true,
                offers: {
                  ...offers,
                  edges: offers.edges.map(e => ({...e.node, state: 'approved_by_brand'})),
                },
                campaign: {
                  __typename: 'Campaign',
                  id: campaign.id,
                  participation: campaign.participation.reduce((acc, n) => {
                    switch (n.type) {
                      case 'candidate':
                        return [...acc, {type: 'candidate', count: 0, __typename: 'Participation'}];
                      case 'approved_by_brand':
                        return [
                          ...acc,
                          {
                            type: 'approved_by_brand',
                            count: n.count + offers.edges.length,
                            __typename: 'Participation',
                          },
                        ];
                      default:
                        return [...acc, n];
                    }
                  }, []),
                },
              },
            },
          }),
      };
    },
  }),
  graphql(SetApplyFirstOfferPositionMutation, {
    props: ({
      mutate,
      ownProps: {
        data,
        match: {params},
      },
    }) => {
      return {
        setApplyFirstOfferPosition: (fromIndex, toIndex) =>
          mutate({
            variables: {
              id: data.campaign.id,
              fromIndex,
              toIndex,
              hash: data.campaign.hash,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setApplyFirstOfferPosition: {
                hash: 'pending',
                __typename: 'SetApplyFirstOfferPosition',
              },
            },
            update: (store, {data: {setApplyFirstOfferPosition}}) => {
              if (!setApplyFirstOfferPosition) {
                // eslint-disable-next-line no-console
                return;
              }

              const data = store.readQuery({
                query: BrandMatchQuery,
                variables: {campaignId: params.campaign}, // Must match BrandMatchQuery
              });

              data.campaign.hash = setApplyFirstOfferPosition.hash;

              data.offers.edges = update(data.offers.edges, {
                $splice: [
                  [fromIndex, 1],
                  [toIndex, 0, data.offers.edges[fromIndex]],
                ],
              });

              store.writeQuery({
                query: BrandMatchQuery,
                data,
                variables: {campaignId: params.campaign}, // Must match BrandMatchQuery
              });
            },
          }),
      };
    },
  })
)(CampaignBrandMatch);
