import {useTheme, Theme} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type Query = 'up' | 'down' | 'between' | 'only';

function useResponsive(query: Query, start: Breakpoint, end?: Breakpoint): boolean {
  const theme: Theme = useTheme();
  const mediaQuery = useMediaQuery;

  const mediaUp = mediaQuery(theme.breakpoints.up(start));
  const mediaDown = mediaQuery(theme.breakpoints.down(start));
  const mediaBetween = end ? mediaQuery(theme.breakpoints.between(start, end)) : false;
  const mediaOnly = mediaQuery(theme.breakpoints.only(start));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  return mediaOnly;
}

export default useResponsive;
