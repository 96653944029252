import {gql, useLazyQuery, useReactiveVar} from '@apollo/client';
import {useEffect, useMemo} from 'react';

import ErrorReporting from '../services/error-reporting';
import {isLoggedIn} from '../services/auth';

const useCurrentUser = () => {
  const loggedIn = useReactiveVar(isLoggedIn);
  const [getCurrentUser, {error, data}] = useLazyQuery(CurrentUserQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const currentUser = useMemo(() => {
    return data?.currentUser || null;
  }, [data]);

  useEffect(() => {
    if (loggedIn) {
      getCurrentUser();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (error) {
      ErrorReporting.captureError(error);
    }
  }, [error]);

  return currentUser;
};

const CurrentUserQuery = gql`
  query CurrentUserQuery {
    currentUser {
      id
      email
      roleName
      fullName
      profilePicture
      birthday
      hasInvitationSent
      hasAcceptedInvite
      takumiDataApiToken
    }
  }
`;

export default useCurrentUser;
