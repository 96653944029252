import React from 'react';
import {Switch, useRouteMatch} from 'react-router-dom';

import Insight from '../scenes/Admin/Insights/Insight';
import {AdminInsights} from '../scenes/Admin';
import SecureRoute from './components/SecureRoute';
import {MANAGE_INFLUENCERS} from '../consts/permissions';

export const Insights = (): JSX.Element => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <SecureRoute
        exact
        path={`${path}`}
        permission={MANAGE_INFLUENCERS}
        component={AdminInsights}
      />
      <SecureRoute path={`${path}/:insightId`} permission={MANAGE_INFLUENCERS}>
        <AdminInsights>
          <Insight />
        </AdminInsights>
      </SecureRoute>
    </Switch>
  );
};
