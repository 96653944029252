import PropTypes from 'prop-types';
import React from 'react';

const PaperPlane = ({color = '#000000', size = 16}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 16 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Gig-–-Reserved" transform="translate(-879.000000, -571.000000)" fill={color}>
        <g id="Gig-Detail" transform="translate(80.000000, 158.000000)">
          <g id="Report-Popup" transform="translate(605.000000, 396.000000)">
            <g id="Input" transform="translate(21.000000, 12.000000)">
              <path
                d="M188.679628,5.2677529 L188.769438,5.17794268 L173,10.7279221 L178.109729,15.8376513 L188.591037,5.35634369 L179.520389,16.9662431 L184.961822,21.2175559 L188.679628,5.2677529 L188.679628,5.2677529 Z"
                id="Rectangle-598"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

PaperPlane.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default PaperPlane;
