import {Grid, IconButton, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import SortIcon from '@material-ui/icons/Sort';
import {useQuery} from '@apollo/client';

import IndustryFilter from '../../../components/Widgets/IndustryFilter';
import StateFilter from '../../../components/Widgets/StateFilter';
import {useStyles} from './styles';
import {IFilters} from './types';
import CampaignTypeFiler from '../../../components/Widgets/CampaignTypeFilter';
import RegionsFilter from '../../../components/Widgets/RegionsFilter';
import AdminCampaignFilters from '../../Admin/Campaigns/components/AdminCampaignFilters';
import {ICampaignFilters} from '../../Admin/Campaigns/types';
import FiltrationParametersDialog from '../../Admin/Campaigns/components/FiltrationParametersDialog';
import {filtersVariables} from '../../../consts/campaignsFilters';
import FilterIcon from '../../Admin/Campaigns/components/FilterIcon';
import DateFilter from './components/DateFilter';
import {CampaignFiltersChips} from '../../../components/Widgets/CampaignFiltersChips';
import {numberWithCommas, singularOrPlural} from '../../../consts/utilities';
import {useGlobalStyles} from '../../App/App/style.integration';
import BrandSearch from './components/BrandSearch';
import {UserRoles} from '../../../consts/roles';
import {EditProfileQuery} from '../../EditProfile/graphqlQuery';

enum SortType {
  HighToLow = 'desc',
  LowToHigh = 'asc',
}

const Filters: React.FC<IFilters> = ({
  filters,
  setFilters,
  onIndustryChanged,
  industriesData,
  onRangeChange,
  industries,
  order,
  changeOrder,
  visibleMetrics,
  summary,
  advertiserNames,
  loading,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  // eslint-disable-next-line prettier/prettier
  const [isFiltrationParametersDialogOpened, setIsFiltrationParametersDialogOpened] =
    useState(false);

  const orderHandler = () =>
    changeOrder(order === SortType.HighToLow ? SortType.LowToHigh : SortType.HighToLow);
  const sortText = order === SortType.HighToLow ? 'High to low' : 'Low to high';
  const {data: userData} = useQuery(EditProfileQuery);

  return (
    <>
      <Grid container>
        <Grid item>
          <div className={classes.groupRoot}>
            <StateFilter filters={filters} setFilters={setFilters} />
          </div>
        </Grid>

        <Grid item>
          <div className={classes.groupRoot}>
            <CampaignTypeFiler filters={filters} setFilters={setFilters} />
          </div>
        </Grid>

        <Grid item>
          <div className={classes.groupRoot}>
            <label htmlFor="regions-select" id="regions-label">
              <RegionsFilter
                id="regions-select"
                ariaLabelledby="regions-label"
                filters={filters}
                handleChange={e => {
                  setFilters({...filters, region: e.target.value ?? null});
                }}
              />
            </label>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.groupRoot}>
            <IndustryFilter
              filters={filters}
              industriesData={industriesData}
              onIndustryChanged={onIndustryChanged}
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.groupRoot}>
            <DateFilter
              range={{
                startDate: filters.startDate,
                endDate: filters.endDate,
              }}
              onRangeChange={onRangeChange}
            />
          </div>
        </Grid>
        <Grid item>
          {userData?.user?.roleName !== UserRoles.Advertiser && (
            <BrandSearch
              filters={filters}
              setFilters={setFilters}
              advertiserNames={advertiserNames}
            />
          )}
        </Grid>
        <Grid item className={classes.extendedFiltersGridItem}>
          <IconButton
            aria-label="advanced filters"
            className={classes.filterButton}
            onClick={() => setIsFiltrationParametersDialogOpened(true)}
          >
            <FilterIcon />
          </IconButton>
        </Grid>
      </Grid>
      <FiltrationParametersDialog
        isOpened={isFiltrationParametersDialogOpened}
        onClose={() => setIsFiltrationParametersDialogOpened(false)}
      >
        <AdminCampaignFilters
          changeFilters={(newFilters: ICampaignFilters) => setFilters({...filters, ...newFilters})}
          resetFilters={() => setFilters({...filtersVariables})}
          currentFilters={{...filters}}
          initialFilters={filtersVariables}
        />
      </FiltrationParametersDialog>

      <CampaignFiltersChips
        filters={filters}
        setFilters={setFilters}
        industries={industries}
        onIndustryChanged={onIndustryChanged}
      />

      {summary?.totalCampaigns === 0 && !loading ? (
        <Typography variant="body2" component="h2" className={classes.message}>
          No campaigns found for selected filters
        </Typography>
      ) : (
        <div className={classes.campaignsFoundBlock}>
          {!loading && (
            <Typography variant="body2" component="h2" className={classes.message}>
              {summary?.totalCampaigns &&
                `${numberWithCommas(summary?.totalCampaigns)} ${singularOrPlural(
                  'campaign',
                  summary?.totalCampaigns
                )} found`}
            </Typography>
          )}
          {visibleMetrics?.engagementRate && (
            <button
              className={classes.sortCaption}
              tabIndex={0}
              aria-label={`${sortText} ordering`}
              onClick={orderHandler}
            >
              <Typography
                component="h3"
                variant="caption"
                className={globalClasses.captionBoldLink}
              >
                Campaigns ER - {sortText}{' '}
              </Typography>
              <SortIcon
                fontSize="small"
                className={
                  order === SortType.HighToLow
                    ? classes.sortIcon
                    : `${classes.sortIcon} ${classes.checkedSortIcon}`
                }
              />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Filters;
