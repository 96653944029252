import React, {useState} from 'react';
import {Avatar, Button, Checkbox, Grid, TableCell} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Modal, FormattedDate, SortableTable, TableZebraRow} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';

import {LONG_DATE_FORMAT} from 'consts/variables';
import {formatRemaining} from 'consts/utilities';
import {getDeliverablePostTypeIcons} from 'consts/campaignPostType';

interface Props {
  campaigns: Campaign[];
  handleModalSubmit: (selected: string[]) => void;
  forwardedRef: React.Ref<ModalElement>;
}

const CampaignModal: React.FC<Props> = ({campaigns, handleModalSubmit, forwardedRef}) => {
  const [selected, setSelected] = useState<string[]>([]);

  const headCells = [
    {id: 'selected'},
    {id: 'campaign_id', align: 'left', label: 'Campaign Name'},
    {id: 'postType', align: 'center', label: 'Platforms'},
    {id: 'state', align: 'center', label: 'Status'},
    {id: 'started', align: 'right', label: 'Live Date'},
  ];

  return (
    <Modal
      ref={forwardedRef}
      id="add-campaigns"
      title="Add Campaigns"
      modalToggler={
        <Button variant="contained" color="primary">
          Add Campaigns
        </Button>
      }
      submitTitle="Add Campaigns"
      fullWidth
      maxWidth="md"
      showClose
      onSubmit={() => handleModalSubmit(selected)}
    >
      <SortableTable
        rows={campaigns.filter(({archived}) => !archived)}
        headCells={headCells}
        sortMetric="created"
        rowCount={5}
        renderRow={(row: any, index: number) => {
          const deliverablePostTypeIcons = getDeliverablePostTypeIcons(row.posts);
          const isSelected = selected.includes(row.id);
          return (
            <TableZebraRow key={index} tabIndex={-1}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected}
                  onChange={e =>
                    setSelected(prevSelected =>
                      e.target.checked
                        ? [...prevSelected, row.id]
                        : prevSelected.filter(id => id !== row.id)
                    )
                  }
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Avatar>
                      <img src={row.photos?.coverPhoto?.url} />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <strong>{row.name}</strong>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                {deliverablePostTypeIcons.map(({name, icon}) => (
                  <Icon key={name} icon={icon} aria-label={name} width="24" />
                ))}
              </TableCell>
              <TableCell align="center">{formatRemaining(row)}</TableCell>
              <TableCell align="right">
                <FormattedDate date={row.started} format={LONG_DATE_FORMAT} />
              </TableCell>
            </TableZebraRow>
          );
        }}
      />
    </Modal>
  );
};

export default CampaignModal;
