import React, {useEffect, useRef, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Badge, ClickAwayListener} from '@material-ui/core';
import {Notifications} from '@material-ui/icons';

import InfiniteScroll from '../../../../../components/Widgets/InfiniteScroll';
import NotificationItem from './NotificationItem';
import {useStyles} from './styles';

interface Props {
  userId: string;
}

const NotificationsWidget: React.FC<Props> = ({userId}) => {
  const classes = useStyles();
  const {data: unseenCountData} = useQuery(UnseenCountQuery, {variables: {userId}});
  const {loading, data, fetchMore} = useQuery(NotificationsQuery, {
    variables: {
      userId,
      first: 10,
      after: undefined,
    },
  });
  const [isOpen, setOpen] = useState(false);
  const [markNotificationSeen] = useMutation(MarkSeenQuery, {
    refetchQueries: () => [
      {
        query: UnseenCountQuery,
        variables: {userId},
      },
    ],
  });

  const handleClickAway = () => {
    setOpen(false);
  };

  const markUnseenNotificationsSeen = () => {
    const notificationIds = data.notifications?.edges
      ?.filter((n: Record<string, any>) => !n.node.seen)
      .map((n: Record<string, any>) => n.node.id);
    if (notificationIds.length > 0) {
      markNotificationSeen({variables: {notificationIds}});
    }
  };

  const isOpenRef = useRef(isOpen);
  isOpenRef.current = isOpen;

  const toggleOpen = () => {
    if (isOpen) {
      markUnseenNotificationsSeen();
    }
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (data && !isOpen) {
      markUnseenNotificationsSeen();
    }
  }, [isOpen]);

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion expanded={isOpen} classes={{root: classes.accordionRoot}}>
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expanded: classes.accordionSummaryContentExpanded,
          }}
          onClick={toggleOpen}
        >
          <div className={classes.notificationsBadge}>
            <Badge
              badgeContent={unseenCountData?.notifications?.unseenCount}
              max={99}
              color={'error'}
              overlap="rectangular"
            >
              <Notifications />
            </Badge>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.accordionDetails}}>
          <InfiniteScroll
            hasMore={data.notifications?.pageInfo?.hasNextPage}
            loadMore={() => {
              fetchMore({
                variables: {
                  userId: userId,
                  first: 10,
                  after: data.notifications?.pageInfo?.endCursor,
                },
                updateQuery: (oldData, {fetchMoreResult}) => ({
                  notifications: {
                    edges: [...oldData.notifications.edges, ...fetchMoreResult.notifications.edges],
                    pageInfo: fetchMoreResult.notifications.pageInfo,
                    __typename: 'NotificationEventRecipientConnection',
                  },
                }),
              });
            }}
            useOverflowAuto
            noMargin
          >
            {data.notifications?.edges?.map((item: any, i: number) => (
              <NotificationItem
                key={`notification-${i}`}
                data={item.node.notificationEvent}
                seen={item.node.seen}
              />
            ))}
          </InfiniteScroll>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

const UnseenCountQuery = gql`
  query UnseenCountQuery($userId: UUIDString!) {
    notifications: notificationsForUser(userId: $userId) {
      unseenCount
    }
  }
`;

const NotificationsQuery = gql`
  query NotificationEventQuery($userId: UUIDString!, $first: Int!, $after: String) {
    notifications: notificationsForUser(userId: $userId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          seen
          notificationEvent {
            id
            eventTitle
            eventDescription
            created
            url
          }
        }
      }
    }
  }
`;

const MarkSeenQuery = gql`
  mutation MarkNotificationSeen($notificationIds: [UUIDString!]!) {
    markNotificationAsSeen(ids: $notificationIds) {
      ok
      notifications {
        id
        seen
      }
    }
  }
`;

export default NotificationsWidget;
