import React from 'react';
import {Icon} from '@iconify/react';

import {
  CampaignCardProgressBar,
  HideInDemoMode,
  HighlightFirstNumber,
  ProfilePicture,
  RelativeDate,
  RequiresPermission,
} from '../../Widgets/';
import {Umbrella, Match, NewLock} from '../../Icons/';
import {constructImgixUrl} from '../../Widgets/Imgix';
import {numberWithCommas, singularOrPlural} from '../../../consts/utilities';
import {CampaignCardFragment} from './graphql';
import {useStyles} from './styles';
import {PostTypeIcons} from '../../../consts/postTypes';
import {getPostTypePlatform} from '../../../consts/campaignPostType';

function formatRemaining(campaign: Campaign) {
  if (campaign.state === 'completed') {
    return 'Completed';
  } else if (campaign.isFullyReserved) {
    return 'Fully reserved';
  }

  if (campaign.target?.deliveredOverall > 0) {
    return `${numberWithCommas(
      Math.max(0, campaign.target?.targetOverall - campaign.target?.deliveredOverall)
    )} remaining`;
  }

  return `${numberWithCommas(
    Math.max(0, campaign.target?.targetOverall - campaign.target?.acceptedOverall)
  )} remaining`;
}

const AboveProgress = ({campaign}: {campaign: Campaign}) => {
  const classes = useStyles();
  const deliverablePostTypeIcons = campaign?.posts
    ?.map(({postType}) => postType)
    .sort()
    .map(postType => ({
      name: getPostTypePlatform(postType as PostType),
      icon: PostTypeIcons[postType as PostType],
    }))
    .filter(({name}, i, arr) => arr.findIndex(el => el.name === name) === i);

  let time = (
    <span>
      Created <RelativeDate date={campaign.created} />
    </span>
  );

  if (campaign.state !== 'draft') {
    time = (
      <span>
        Started <RelativeDate date={campaign.started} />
      </span>
    );
  }

  return (
    <div className={classes.aboveProgressBar}>
      <div className={classes.campaignProgressAbove}>{time}</div>
      <div className={classes.deliverableIcons}>
        {deliverablePostTypeIcons?.map(({name, icon}) => (
          <Icon key={name} icon={icon} aria-label={`${name} deliverables`} />
        ))}
      </div>
    </div>
  );
};

const BelowProgress = ({campaign}: {campaign: Campaign}) => {
  const classes = useStyles();
  let progress;

  if (campaign.target?.deliveredOverall ?? 0 > 0) {
    progress = (
      <HighlightFirstNumber
        sentence={`${numberWithCommas(campaign.target?.deliveredOverall)} Delivered`}
      />
    );
  } else {
    if (campaign.rewardModel === 'reach') {
      const expectedReach = numberWithCommas(campaign.target?.acceptedOverall ?? 0);
      progress = <HighlightFirstNumber sentence={`${expectedReach} Reach`} />;
    } else {
      const gigsText = singularOrPlural('Reservation', campaign.target?.acceptedOverall ?? 0);
      progress = (
        <HighlightFirstNumber sentence={`${campaign.target?.acceptedOverall ?? 0} ${gigsText}`} />
      );
    }
  }

  return (
    <div className={classes.belowProgressBar}>
      <div className={classes.campaignProgressBelow}>
        {progress}
        <HighlightFirstNumber sentence={formatRemaining(campaign)} />
      </div>
    </div>
  );
};

interface Props {
  campaign: Campaign;
}

const CampaignCard = ({campaign}: Props) => {
  const classes = useStyles();
  let style;
  if (campaign.photos.coverPhoto) {
    const background = `url(${constructImgixUrl(
      campaign.photos.coverPhoto.url,
      344,
      344,
      'crop'
    )})`;

    const shade = 'rgba(0,0,0,0.5)';
    const transparent = 'rgba(0,0,0,0)'; // Browsers don't agree what transparent means
    style = {
      background: `linear-gradient(0deg, ${shade}, ${shade} 15%, ${transparent} 30%, ${transparent}), ${background}`,
      backgroundSize: 'cover, cover',
    };
  }

  return (
    <div className={classes.root}>
      <div className={classes.campaignPictureWrapper} style={style}>
        <HideInDemoMode>
          <RequiresPermission permission="edit_campaign">
            <div className={classes.tags}>
              {campaign?.tags.map((tag: string) => (
                <div key={tag} className={classes.tag}>
                  {tag}
                </div>
              ))}
            </div>
          </RequiresPermission>
        </HideInDemoMode>

        <div className={classes.advertiserInfoWrapper}>
          <ProfilePicture
            square
            className={classes.advertiserLogo}
            src={campaign.advertiser.profilePicture}
            size={48}
            borderRadius="0"
          />

          <div className={classes.pills}>
            {campaign.brandSafety && (
              <div title="This is a brand safety campaign" className={classes.pill}>
                <Umbrella ariaLabel="Brand safety campaign" />
              </div>
            )}
            {campaign.brandMatch && (
              <div title="This is a brand match campaign" className={classes.pill}>
                <Match ariaLabel="Brand match campaign" />
              </div>
            )}
            {campaign.hasNda && (
              <div
                title="Campaign is covered by a non-disclosure agreement"
                className={classes.pill}
              >
                <NewLock ariaLabel="Campaign under NDA" />
              </div>
            )}
          </div>
          <div className={classes.advertiserCampaignInfo}>
            <h3 className={classes.campaignName} id={campaign.id}>
              {campaign.name}
            </h3>
            <div className={classes.advertiserInfo}>
              <p className={classes.advertiserName}>{campaign.advertiser.name}</p>
              <HideInDemoMode>
                <p className={classes.price}>
                  {campaign.price ? campaign.price.formattedValue : null}
                </p>
              </HideInDemoMode>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.campaignProgress}>
        <AboveProgress campaign={campaign} />
        <CampaignCardProgressBar target={campaign.target} state={campaign.state} />
        <BelowProgress campaign={campaign} />
      </div>
    </div>
  );
};

CampaignCard.fragments = {
  campaign: CampaignCardFragment,
};

export {CampaignCard};

export default CampaignCard;
