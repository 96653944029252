import {createStyles, makeStyles} from '@material-ui/core/styles';

import {
  black,
  black10,
  black20,
  white,
  boxShadowBase,
  semiBold,
  transparentBlack,
} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
      width: 360,
      position: 'relative',
      '&:hover': {
        boxShadow: `0px 2px 20px 0px ${transparentBlack}`,
      },
    },
    campaignPictureWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      backgroundColor: black20,
      color: white,
      flex: '0 0 344px',
      borderBottom: `1px solid ${black10}`,
      margin: '12px 12px 0 12px',
    },
    tags: {
      alignSelf: 'flex-start',
      margin: '0px 6px',
    },
    tag: {
      padding: '3px 12px',
      fontWeight: 500,
      display: 'inline-block',
      margin: '0 5px 8px 5px',
      fontSize: 12,
      backgroundColor: white,
      color: black,
      borderRadius: 2,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
    advertiserInfoWrapper: {
      display: 'flex',
      width: '100%',
      padding: '0 10px',
    },
    advertiserLogo: {
      boxShadow: boxShadowBase,
      flexShrink: 0,
    },
    pills: {
      position: 'absolute',
      top: 28,
      right: 28,
      display: 'flex',
      flexDirection: 'column',
    },
    pill: {
      backgroundColor: white,
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      marginBottom: 8,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
    advertiserCampaignInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 80px)',
      marginLeft: 10,
      flexGrow: 2,
    },
    campaignName: {
      fontWeight: semiBold,
      fontSize: 18,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      lineHeight: '24px',
    },
    advertiserInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: 1,
      marginBottom: '-2px',
    },
    advertiserName: {
      fontSize: 12,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: 10,
    },
    price: {
      fontSize: 16,
      fontWeight: semiBold,
      flexShrink: 0,
    },
    campaignProgress: {
      color: black,
      margin: '8px 12px',
    },
    aboveProgressBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0px 2px 2px 3px',
      fontSize: 12,
      lineHeight: 1,
    },
    deliverableIcons: {
      '& svg': {
        width: '12px',
        marginLeft: '5px',
      },
    },
    campaignProgressAbove: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    belowProgressBar: {
      fontSize: 12,
      lineHeight: 1,
    },
    campaignProgressBelow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);
