import {gql} from '@apollo/client';

export const AdminAdvertisersQuery = gql`
  query AdminAdvertisersQuery($searchStringDebounced: String, $archived: Boolean, $cursor: String) {
    advertisers(search: $searchStringDebounced, archived: $archived, first: 48, after: $cursor) {
      edges {
        node {
          id
          name
          domain
          profilePicture
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
  }
`;
