import React from 'react';
import {TableRow, TableRowProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {theme} from 'consts/theme';
import {whisper, nearWhite} from 'consts/brand.integration';

const generateSelector = (alternateEvery: number) =>
  Array.from(Array(alternateEvery)).reduce(
    (acc, _, i) => `${acc}:not(:nth-child(${2 * alternateEvery}n+${i + 1}))`,
    '&'
  );

const useStyles = makeStyles<typeof theme, {alternateEvery: number}>({
  root: ({alternateEvery}) => ({
    background: nearWhite,

    [generateSelector(alternateEvery)]: {
      background: whisper,
    },
  }),
});

type Props = {
  alternateEvery?: number;
} & TableRowProps;

const TableZebraRow = ({alternateEvery = 1, ...props}: Props) => {
  const classes = useStyles({alternateEvery: alternateEvery});

  return <TableRow className={classes.root} {...props} />;
};

export default TableZebraRow;
