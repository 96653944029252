import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black, white, purple, red, semiBold} from '../../../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      height: '40px',
      border: `1px solid ${black}`,
      borderRadius: 0,
      padding: '12px 16px',
      marginTop: '4px',
      '&:hover': {
        backgroundColor: white,
      },
    },
    form: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    root: {
      marginRight: '32px',
      '& .MuiOutlinedInput-input': {
        height: '48px',
        width: '448px',
        padding: '12px 16px',
        boxSizing: 'border-box',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '2px',
        '&.Mui-focused fieldset': {
          border: `1px solid ${purple}`,
        },
        '&.Mui-error fieldset': {
          border: `1px solid ${red}`,
        },
      },
    },
    hiddenLabel: {
      display: 'none',
    },
    helperText: {
      '&.MuiFormHelperText-root.Mui-error': {
        fontWeight: semiBold,
        color: red,
      },
    },
  })
);
