import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  FormattedDate,
  ProfilePicture,
  RelativeDate,
  RequiresPermission,
  Modal,
} from 'components/Widgets';

import {capitalize} from 'consts/utilities';
import {
  LONG_DATE_FORMAT,
  RESERVED,
  SUBMITTED,
  REVIEWED,
  APPROVED,
  REPORTED,
  REJECTED,
} from 'consts/variables';

import styles from './style.css';

const GigBusStop = ({gig}) => {
  const isAwaitingSubmission = gig.state === RESERVED;
  const isSubmitted = gig.state === SUBMITTED || gig.state === REVIEWED || gig.state === APPROVED;
  const isPosted = gig.isPosted;
  const isClaimed = gig.offer.isPaid;
  const isPostTypeContent = gig.post.postType === 'content';
  const isRejectedOrReported = gig.state === REJECTED || gig.state === REPORTED;
  const format = LONG_DATE_FORMAT;
  const firstStopStyle = () => {
    if (isAwaitingSubmission) {
      return {marginBottom: '-14px'};
    }
    if (isAwaitingSubmission) {
      return {marginBottom: '0px'};
    }
    return {};
  };
  return (
    <div className={styles.root}>
      <div className={styles.busStop} style={firstStopStyle()}>
        <span className={styles.dot} />
        <span className={!isAwaitingSubmission ? styles.line : styles.lineGray} />
        <div className={styles.gigState}>
          <p>Reserved</p>
          <div className={styles.rightAlignText}>
            <p className={!isAwaitingSubmission && styles.fadeOut}>
              <FormattedDate date={gig.created} format={format} />
            </p>
            {isAwaitingSubmission}
          </div>
        </div>
      </div>
      <div className={styles.busStop} style={isRejectedOrReported ? {marginBottom: '0px'} : {}}>
        <span className={isSubmitted || isRejectedOrReported ? styles.dot : styles.dotGray} />
        <span className={isPosted || isRejectedOrReported ? styles.line : styles.lineGray} />
        <div className={styles.gigState}>
          <p>Submitted</p>
          <p className={classNames(isSubmitted && styles.fadeOut)}>
            {gig.submission && <FormattedDate date={gig.submission.created} format={format} />}
          </p>
        </div>
      </div>
      {isRejectedOrReported && (
        <div className={styles.busStop}>
          <span className={styles.dot} />
          <span className={styles.lineGray} />
          <div className={styles.gigState}>
            <p>{capitalize(gig.state)}</p>
            <Modal
              id="gig-report"
              modalToggler={
                <a href="#" className={styles.openReport}>
                  Open report
                </a>
              }
              title="Gig report"
              showClose
            >
              {gig.reporter && (
                <div className={styles.reporter}>
                  <div className={styles.profilePictureWrapper}>
                    <ProfilePicture
                      className={styles.profilePicture}
                      src={gig.reporter.profilePicture}
                      size={60}
                    />
                  </div>
                  <div className={styles.info}>
                    <h3 className={styles.fullName} title={gig.reporter.fullName}>
                      {gig.reporter.fullName}
                    </h3>
                    <p className={styles.email} title={gig.reporter.email}>
                      {gig.reporter.email}
                    </p>
                  </div>
                </div>
              )}
              <p>{gig.reportReason}</p>
              <p>{gig.rejectReason}</p>
            </Modal>
          </div>
        </div>
      )}

      {gig.post.campaign.preApproval && !isPostTypeContent && (
        <div className={styles.busStop}>
          <span className={isPosted ? styles.dot : styles.dotGray} />
          <RequiresPermission permission="view_offer_reward_info">
            <span className={isClaimed ? styles.line : styles.lineGray} />
          </RequiresPermission>
          <div className={styles.gigState}>
            <p>Posted</p>
            <p className={classNames(isPosted && styles.fadeOut)}>
              {isPosted && gig.instagramContent?.posted ? (
                <FormattedDate date={gig.instagramContent.posted} format={format} />
              ) : (
                'Not posted yet'
              )}
            </p>
          </div>
        </div>
      )}

      <RequiresPermission permission="view_offer_reward_info">
        <div className={styles.lastBusStop}>
          <span className={isClaimed ? styles.dot : styles.dotGray} />
          <div className={styles.gigState}>
            <p>Payment</p>
            {isClaimed && (
              <p>
                Claimed <RelativeDate date={gig.offer.claimed} />
              </p>
            )}
            {!isRejectedOrReported && !isClaimed && gig.offer.payable && (
              <p>
                Payable <RelativeDate date={gig.offer.payable} />
              </p>
            )}
          </div>
        </div>
      </RequiresPermission>
    </div>
  );
};

GigBusStop.propTypes = {
  gig: PropTypes.object.isRequired,
};

export default GigBusStop;
