import {interestsItems, ageValues} from 'consts/targetingValues';

export function serialize(
  {interests, ages, regions, gender, followers, verifiedOnly, information},
  absoluteMinFollowers,
  maxCutoff
) {
  const flatten = arr => [].concat(...arr);

  const minFollowers = followers[0] > absoluteMinFollowers ? followers[0] : -1;
  const maxFollowers = followers[1] < maxCutoff ? followers[1] : -1;

  return {
    regions: regions.map(region => region.id),
    gender,
    verifiedOnly,
    ages: flatten(ages.map(x => ageValues[x])),
    interests: interests.length === interestsItems.length ? null : interests,
    maxFollowers,
    minFollowers,
    information,
  };
}

export function deserialize(targeting, maxCutoff) {
  const ages = Object.keys(ageValues).reduce((acc, key) => {
    if (targeting.ages && ageValues[key].every(age => targeting.ages.includes(age))) {
      acc.push(key);
    }

    return acc;
  }, []);

  const followers = [
    targeting.minFollowers || targeting.absoluteMinFollowers,
    targeting.maxFollowers || maxCutoff,
  ];

  const {
    hasGlasses,
    selfTags,
    eyeColours,
    hairColourCategories,
    hairTypes,
    languages,
    childrenTargeting,
  } = targeting;
  const information = {
    hasGlasses,
    tagIds: selfTags !== null ? selfTags.map(({id}) => id) : null,
    eyeColour: eyeColours && eyeColours.length > 0 ? eyeColours[0].id : null,
    hairColourCategory: hairColourCategories,
    hairType: hairTypes && hairTypes.length > 0 ? hairTypes[0].id : null,
    languages,
    childrenAges: childrenTargeting ? childrenTargeting.ages : null,
    hasBornChild: childrenTargeting ? childrenTargeting.hasBornChild : null,
    hasUnbornChild: childrenTargeting ? childrenTargeting.hasUnbornChild : null,
    childGender: childrenTargeting ? childrenTargeting.childGender : null,
  };

  return {
    regions: targeting.regions,
    gender: targeting.gender || 'all',
    verifiedOnly: targeting.verifiedOnly,
    ages,
    followers,
    information,
    interests: targeting.interests
      ? targeting.interests.map(c => c.id)
      : interestsItems.map(c => c.value),
  };
}
