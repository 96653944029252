import {createStyles, makeStyles, Theme} from '@material-ui/core';

import {black, inputPlaceholder, semiBold, white} from '../../../../consts/brand.integration';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: black,
      marginBottom: '5px',
    },
    control: {
      width: '450px',
      height: '48px',
      marginRight: theme.spacing(2),
      fontSize: '16px',
      '& .MuiInputBase-root': {
        height: '48px',
      },
    },
    inputText: {
      '&::placeholder': {
        color: inputPlaceholder,
        fontWeight: 400,
        opacity: 1,
      },
      fontSize: '14px',
      fontWeight: 600,
    },
    helperText: {
      fontWeight: semiBold,
      color: theme.palette['support-error'],
      fontSize: `${theme.typography.caption.fontSize} !important`,
      lineHeight: theme.typography.caption.lineHeight,
    },
    button: {
      height: 40,
      marginTop: 48,
      maxWidth: 100,
      color: white,
      backgroundColor: black,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: black,
      },
    },
    emailFormLabel: {
      marginTop: 60,
    },
    passwordFormLabel: {
      marginTop: 28,
    },
    forgotPassword: {
      marginTop: 16,
    },
    loginText: {
      marginLeft: 5,
    },
  })
);
