import React from 'react';
import {Typography, Grid, Box} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {Link} from 'react-router-dom';
import {isNumber} from 'lodash/fp';

import {Tooltip, RelativeDate, PostTypeChip} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';
import {red, blue, green} from 'consts/brand';
import {
  TiktokPostTypes,
  CampaignPostType,
  InstagramPostTypesWithInsights,
  YoutubePostTypes,
  TwitchPostTypes,
} from 'consts/campaignPostType';
import {usernameForPost} from 'consts/influencerHelper';
import {black} from 'consts/brand.integration';

import GigCaption from '../GigCaption';
import styles from './style.css';

function getContent(gig: Gig) {
  if (gig.instagramContent) {
    return gig.instagramContent;
  }
  return gig.submission;
}

const InstagramMediaInsights = ({instagramContent}: {instagramContent: InstagramPost}) => (
  <Box paddingY={0.5}>
    <Grid container spacing={1}>
      {isNumber(instagramContent.videoViews) && (
        <Grid item>
          <Tooltip overlay={<span>Views</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:remove-red-eye" color={black} size={10} />
              <span className={styles.count}>{numberWithCommas(instagramContent.videoViews)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {/* IG Reels uses plays instead of views */}
      {isNumber(instagramContent.plays) && (
        <Grid item>
          <Tooltip overlay={<span>Views</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:remove-red-eye" color={black} size={10} />
              <span className={styles.count}>{numberWithCommas(instagramContent.plays)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(instagramContent.comments) && (
        <Grid item>
          <Tooltip overlay={<span>Comments</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:chat" color={blue} />
              <span className={styles.count}>{numberWithCommas(instagramContent.comments)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(instagramContent.likes) && (
        <Grid item>
          <Tooltip overlay={<span>Likes</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:heart" color={red} />
              <span className={styles.count}>{numberWithCommas(instagramContent.likes)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(instagramContent.shares) && (
        <Grid item>
          <Tooltip overlay={<span>Shares</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:share" color={green} size={10} />
              <span className={styles.count}>{numberWithCommas(instagramContent.shares)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(instagramContent.engagementRateByFollowers?.value) && (
        <Grid item>
          <Tooltip overlay={<span>Engagement rate by followers</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:graph-line" color={blue} />
              <span className={styles.count}>
                {instagramContent.engagementRateByFollowers?.formattedValue}
              </span>
            </Box>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  </Box>
);

const StoryFrameInsights = ({frames}: {frames: InstagramStoryFrame[]}) => (
  <div className={styles.caption}>
    {frames.map(
      (f, i) =>
        f.instagramStoryFrameInsight && (
          <div key={f.id} className={styles.storyFrameInsight}>
            <img
              alt="Story frame"
              className={styles.frameThumb}
              src={f.media.thumbnail || f.media.url}
            />
            <div>
              {frames.length > 1 && <div className={styles.frameNumber}>Frame {i + 1}</div>}
              <div className={styles.storyInsightValue}>
                <span>Impressions: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.impressions)}
                </span>
              </div>
              <div className={styles.storyInsightValue}>
                <span>Accounts reached: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.reach)}
                </span>
              </div>
              <div className={styles.storyInsightValue}>
                <span>Exits: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.exits)}
                </span>
              </div>
              <div className={styles.storyInsightValue}>
                <span>Replies: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.replies)}
                </span>
              </div>
              <div className={styles.storyInsightValue}>
                <span>Taps forward: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.tapsForward)}
                </span>
              </div>
              <div className={styles.storyInsightValue}>
                <span>Taps back: </span>
                <span className={styles.count}>
                  {numberWithCommas(f.instagramStoryFrameInsight.tapsBack)}
                </span>
              </div>
            </div>
          </div>
        )
    )}
  </div>
);

const InstagramStoryFrames = ({instagramStory}: {instagramStory: InstagramStory}) => {
  return (
    <>
      {instagramStory?.storyFrames?.find(f => f.instagramStoryFrameInsight) && (
        <div className={styles.storyFrameInsights}>
          <span className={styles.heading}>Insights</span>
          <StoryFrameInsights frames={instagramStory.storyFrames.slice().reverse()} />
        </div>
      )}
    </>
  );
};

const TiktokMediaInsights = ({tiktokPost}: {tiktokPost: TiktokPost}) => (
  <Box paddingY={0.5}>
    <Grid container spacing={1}>
      <Grid item>
        <Tooltip overlay={<span>Views</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:remove-red-eye" color={black} size={10} />
            <span className={styles.count}>{numberWithCommas(tiktokPost.videoViews)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Comments</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:chat" color={blue} size={10} />
            <span className={styles.count}>{numberWithCommas(tiktokPost.comments)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Likes</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:heart" color={red} size={10} />
            <span className={styles.count}>{numberWithCommas(tiktokPost.likes)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Shares</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:share" color={green} size={10} />
            <span className={styles.count}>{numberWithCommas(tiktokPost.shares)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Engagement rate by followers</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:graph-line" color={blue} size={10} />
            <span className={styles.count}>{tiktokPost.engagementByFollowers?.formattedValue}</span>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  </Box>
);

const YoutubeVideoInsights = ({youtubeVideo}: {youtubeVideo: YoutubeVideo}) => (
  <Box paddingY={0.5}>
    <Grid container spacing={1}>
      <Grid item>
        <Tooltip overlay={<span>Views</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:remove-red-eye" color={black} size={10} />
            <span className={styles.count}>{numberWithCommas(youtubeVideo.viewCount)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Comments</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:chat" color={blue} size={10} />
            <span className={styles.count}>{numberWithCommas(youtubeVideo.commentCount)}</span>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip overlay={<span>Likes</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:heart" color={red} size={10} />
            <span className={styles.count}>{numberWithCommas(youtubeVideo.likeCount)}</span>
          </Box>
        </Tooltip>
      </Grid>
      {isNumber(youtubeVideo.engagementRateBySubscribers) && (
        <Grid item>
          <Tooltip overlay={<span>Engagement rate by subscribers</span>} placement="bottom">
            <Box className={styles.iconWithValue}>
              <Icon icon="mdi:graph-line" color={blue} size={10} />
              <span className={styles.count}>
                {numberWithCommas(youtubeVideo.engagementRateBySubscribers)}
              </span>
            </Box>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  </Box>
);

const TwitchVideoInsights = ({twitchVideo}: {twitchVideo: TwitchVideo}) => (
  <Box paddingY={0.5}>
    <Grid container spacing={1}>
      <Grid item>
        <Tooltip overlay={<span>Views</span>} placement="bottom">
          <Box className={styles.iconWithValue}>
            <Icon icon="mdi:remove-red-eye" color={black} size={10} />
            <span className={styles.count}>{numberWithCommas(twitchVideo.viewCount)}</span>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  </Box>
);

const PostLastScraped = ({postData}: {postData: InstagramPost}) => {
  return (
    <div className={styles.postedTime}>
      <Icon icon="mdi:clock" color="#262626" size={18} />
      <Typography variant="caption">
        Data last updated:{' '}
        <RelativeDate date={postData.scraped || postData.modified || postData.created} />
      </Typography>
    </div>
  );
};

const GigMediaInfo = ({gig}: {gig: Gig}) => {
  const content = getContent(gig);
  return (
    <div className={styles.mediaInfo}>
      {content.caption && (
        <div className={styles.caption}>
          <Link to={`/influencers/${gig.influencer?.id}`}>
            {usernameForPost(gig.post, gig.influencer) ?? gig.influencer?.fullName ?? 'Unknown'}
          </Link>
          <GigCaption
            caption={content.caption}
            hashtags={content.hashtags}
            mentions={content.mentions}
          />
        </div>
      )}

      {InstagramPostTypesWithInsights.includes(gig.post?.postType ?? '') &&
        gig.instagramContent && <InstagramMediaInsights instagramContent={gig.instagramContent} />}
      {TiktokPostTypes.includes(gig.post?.postType ?? '') && gig.tiktokPost && (
        <TiktokMediaInsights tiktokPost={gig.tiktokPost} />
      )}
      {YoutubePostTypes.includes(gig.post?.postType ?? '') && gig.youtubeVideo && (
        <YoutubeVideoInsights youtubeVideo={gig.youtubeVideo} />
      )}
      {TwitchPostTypes.includes(gig.post?.postType ?? '') && gig.twitchVideo && (
        <TwitchVideoInsights twitchVideo={gig.twitchVideo} />
      )}

      <div className={styles.metaFooter}>
        <PostTypeChip type={gig.post?.postType} />
        {gig.instagramContent && <PostLastScraped postData={gig.instagramContent} />}
      </div>

      {gig.isVerified && gig.instagramContent && gig.post?.postType == CampaignPostType.story && (
        <InstagramStoryFrames instagramStory={gig.instagramContent} />
      )}
    </div>
  );
};

export default GigMediaInfo;
