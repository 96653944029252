import React from 'react';
import {Tooltip, Typography} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {withStyles} from '@material-ui/styles';

import RowWrap from '../../../../../components/Widgets/RowWrap';
import CreatorCard from '../CreatorCard';
import {CreatorEdge} from '../../types';
import {useStyles} from './styles';
import {black, white} from '../../../../../consts/brand.integration';

interface Props {
  creators: Array<CreatorEdge>;
}

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: black,
    color: white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    lineHeight: 1.2,
  },
  arrow: {
    color: black,
  },
}))(Tooltip);

const TopCreators: React.FC<Props> = ({creators}: Props) => {
  const classes = useStyles();

  if (!creators?.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        <div className={classes.topCreatorsHeader}>
          Top creators
          <div className={classes.infoIcon}>
            <StyledTooltip
              arrow
              placement="top"
              title={
                "Creators are ranged by ER in-feed. Reach and Impressions are calculated as average from all Creator's Posts within this Campaign"
              }
            >
              <InfoIcon />
            </StyledTooltip>
          </div>
        </div>
      </Typography>
      <RowWrap className={classes.creators}>
        {creators.slice(0, 3).map((edge: CreatorEdge) => (
          <CreatorCard key={edge?.node?.id} creator={edge?.node} />
        ))}
      </RowWrap>
    </div>
  );
};

export default TopCreators;
