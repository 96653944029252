import React from 'react';
import {Link} from 'react-router-dom';
import {Divider, Grid, makeStyles, TableCell, Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {
  TableZebraRow,
  Tooltip,
  MediaPreview,
  MediaContainer,
  SortableTable,
  FormattedDate,
} from '../../../../../components/Widgets';
import {numberWithCommas} from '../../../../../consts/utilities';
import {socialIcons} from '../../../../../consts/icons';
import {isDeleted, latestMedia, hasMedia} from '../../../../../consts/gigHelper';
import {getPostTypeFormatted} from '../../../../../consts/campaignPostType';
import {GigData} from '../types';

interface Props {
  metrics: any;
}

const GigPerformanceTable: React.FC<Props> = ({metrics}) => {
  const classes = useStyles();

  const headCells = [
    {id: 'full_name', numeric: false, label: 'Post'},
    {id: 'follower_total', numeric: true, label: 'Followers'},
    {id: 'reach_total', numeric: true, label: 'Reach'},
    {id: 'impression_total', numeric: true, label: 'Impressions'},
    {id: 'engagement_total', numeric: true, label: 'Engagements'},
    {id: 'engagement_rate_by_impressions', numeric: true, label: 'Engagement Rate'},
  ];

  const engagementTotal = (row: GigData) =>
    row.post.postType === 'story'
      ? {raw: 0, formatted: '-'}
      : row.engagement_total || {raw: 0, formatted: '0'};

  const engagementRateByImpressions = (row: GigData) =>
    row.post.postType === 'story'
      ? {raw: 0, formatted: '-'}
      : row.engagement_rate_by_impressions || {raw: 0, formatted: '0'};

  const formattedMetric = (metric: any) => {
    return metric
      ? {raw: metric.raw || 0, formatted: metric.formatted || '-'}
      : {raw: 0, formatted: '-'};
  };

  const filteredData = metrics?.filter(row => row.digital_content_count?.raw > 0);

  return (
    <SortableTable
      rows={filteredData}
      headCells={headCells}
      sortMetric="engagement_rate_by_impressions"
      renderRow={(row: GigData, index: number) => {
        const follower_total = formattedMetric(row.follower_total);
        const reach_total = formattedMetric(row.reach_total);
        const impression_total = formattedMetric(row.impression_total);
        return (
          <TableZebraRow key={index} tabIndex={-1}>
            <TableCell component="th" scope="row" className={classes.link}>
              <Link to={`/gigs/${row.gig_id}`}>
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    {hasMedia(row) && (
                      <MediaContainer height={100} width={100}>
                        <MediaPreview
                          media={latestMedia(row)}
                          height={100}
                          width={100}
                          isDeleted={isDeleted(row)}
                          arePillsShown={false}
                        />
                      </MediaContainer>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <strong>{row.full_name}</strong>
                    </Typography>
                    <Typography variant="body2">
                      <i>@{row.instagram_handle || row.tiktok_handle}</i>
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="caption" className={classes.center}>
                      <Icon
                        icon={socialIcons[row.post.postType] || 'ic:baseline-photo'}
                        width={16}
                      />
                      {getPostTypeFormatted(row.post.postType)}
                    </Typography>
                    <Typography variant="caption">
                      <FormattedDate date={row.created} />
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </TableCell>
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(follower_total.raw)} placement="left">
                <Typography variant="body1">{follower_total.formatted}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(reach_total.raw)} placement="left">
                <Typography variant="body1">{reach_total.formatted}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(impression_total.raw)} placement="left">
                <Typography variant="body1">{impression_total.formatted}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip overlay={numberWithCommas(engagementTotal(row).raw)} placement="left">
                <Typography variant="body1">{engagementTotal(row).formatted}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip
                overlay={numberWithCommas(engagementRateByImpressions(row).raw)}
                placement="left"
              >
                <Typography variant="body1">
                  {engagementRateByImpressions(row).formatted}
                </Typography>
              </Tooltip>
            </TableCell>
          </TableZebraRow>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    cursor: 'pointer',
  },
  divider: {
    margin: '5px auto',
  },
});

export default GigPerformanceTable;
