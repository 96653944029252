import React from 'react';
import {Typography} from '@material-ui/core';
import classNames from 'classnames';

import {useStyles} from './styles';

interface Props {
  title: string;
  value: string | number | null;
  className?: string;
}

const MetricItem: React.FC<Props> = ({title, value, className}: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.highlight, className)}>
      <Typography variant="caption" component="span">
        {title}
      </Typography>
      <Typography variant="h2" component="span" className={classes.value}>
        {value}
      </Typography>
    </div>
  );
};

export default MetricItem;
