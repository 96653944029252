import React, {useState} from 'react';
import {Button, Typography} from '@material-ui/core';

import {TextInput} from '../../../../../../components/Widgets';
import {Form, FormTextInput} from '../../../../../../components/Widgets/Forms';
import {CornerLeftDown, Refresh} from '../../../../../../components/Icons';
import {white} from '../../../../../../consts/brand.integration';
import {KeyValue} from '../';
import {useStyles} from './styles';
import {ControlDialog} from '../../../../../../components/Widgets/ControlDialog';

interface Props {
  insight: any;
  submitInsight: any;
  requestInsightResubmission: any;
}

const TikTokInsight: React.FC<Props> = ({insight, submitInsight, requestInsightResubmission}) => {
  const classes = useStyles();

  const [rejectReason, setRejectReason] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const requireInsightResubmit = () => requestInsightResubmission(rejectReason);

  const trackReason = (rejectReason: any) => {
    setRejectReason(rejectReason);
  };

  const resubmit = insight.state === 'requires_resubmit';

  const resubmitButton = resubmit ? (
    <Button startIcon={<Refresh />} className={classes.resubmitButton} disabled>
      Resubmit has been requested
    </Button>
  ) : (
    <Button
      startIcon={<Refresh />}
      className={classes.resubmitButton}
      onClick={() => setOpenModal(true)}
    >
      Request resubmit
    </Button>
  );

  const initialState = {...insight};

  return (
    <div className={classes.root}>
      <Form initialState={initialState} onSubmit={submitInsight}>
        <Typography variant="h4" component="h2" className={classes.title}>
          Impressions & Reach
        </Typography>

        <KeyValue
          label={
            <Typography variant="subtitle1" component="label">
              Impressions
            </Typography>
          }
          labelId="impressions-label"
          value={
            <FormTextInput
              name="impressions"
              pattern="[0-9]*"
              inputmode="numeric"
              parser={(v: any) => v || 0}
              rightCursor
              small
              minWidth={200}
              labelledBy="impressions-label"
            />
          }
        />

        <KeyValue
          label={
            <Typography variant="subtitle1" component="label">
              Reach
            </Typography>
          }
          labelId="accounts-reached-label"
          value={
            <FormTextInput
              name="reach"
              pattern="[0-9]*"
              inputmode="numeric"
              parser={(v: any) => v || 0}
              rightCursor
              small
              minWidth={200}
              labelledBy="accounts-reached-label"
            />
          }
        />

        <Typography variant="h4" component="h2" className={classes.title}>
          Interactions
        </Typography>

        <KeyValue
          label={
            <Typography variant="subtitle1" component="label">
              Likes (not yet implemented)
            </Typography>
          }
          labelId="likes-label"
          value={
            <FormTextInput
              name="likes"
              pattern="[0-9]*"
              inputmode="numeric"
              parser={(v: any) => v || 0}
              rightCursor
              small
              minWidth={200}
              labelledBy="likes-label"
              disabled
            />
          }
        />

        <KeyValue
          label={
            <Typography variant="subtitle1" component="label">
              Shares (not yet implemented)
            </Typography>
          }
          labelId="shares-label"
          value={
            <FormTextInput
              name="shares"
              pattern="[0-9]*"
              inputmode="numeric"
              parser={(v: any) => v || 0}
              rightCursor
              small
              minWidth={200}
              labelledBy="shares-label"
              disabled
            />
          }
        />

        <KeyValue
          label={
            <Typography variant="subtitle1" component="label">
              Comments (not yet implemented)
            </Typography>
          }
          labelId="comments-label"
          value={
            <FormTextInput
              name="comments"
              pattern="[0-9]*"
              inputmode="numeric"
              parser={(v: any) => v || 0}
              rightCursor
              small
              minWidth={200}
              labelledBy="comments-label"
              disabled
            />
          }
        />

        <div className={classes.actions}>
          <Button
            type="submit"
            startIcon={<CornerLeftDown color={white} />}
            disabled={resubmit}
            className={classes.submitButton}
          >
            Submit
          </Button>
          {resubmitButton}
          <ControlDialog
            open={openModal}
            handleClose={() => setOpenModal(false)}
            handleSubmit={requireInsightResubmit}
            title="Request insight resubmission"
            body={
              <div>
                <p className={classes.modalBody}>
                  By rejecting this insight, the offer won't become claimable until they submit a
                  new insight
                </p>
                <label
                  id="labelReason"
                  htmlFor="reasonInput"
                  className={classes.label}
                  aria-label="reason-label"
                >
                  Reason
                </label>
                <TextInput
                  id="reasonInput"
                  labelledBy="labelReason"
                  inputStyle={classes.textInput}
                  value={rejectReason}
                  placeholder="Enter the reason"
                  onChange={trackReason}
                />
              </div>
            }
            buttonName={'Request resubmission'}
          />
        </div>
      </Form>
    </div>
  );
};

export default TikTokInsight;
