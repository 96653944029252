import React from 'react';
import PropTypes from 'prop-types';

import {RadioButton} from '../../';

const RadioButtonFilter = ({filterName, filters, label, changeFilters, value}) => (
  <RadioButton
    checked={filters[filterName] === value}
    label={label}
    onChange={() => {
      changeFilters({[filterName]: value});
    }}
  />
);

RadioButtonFilter.propTypes = {
  filterName: PropTypes.string,
  filters: PropTypes.object,
  label: PropTypes.string,
  changeFilters: PropTypes.func,
  value: PropTypes.string,
};

export default RadioButtonFilter;
