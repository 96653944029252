import React from 'react';
import {Tag, WithOutContext as ReactTags} from 'react-tag-input';

/**
 * react-tag-input types state the type of `removeComponent` should be
 * `React.Component<any, any>` - however a functional component can be passed
 */
const Empty = (() => null) as unknown as React.Component;

interface Props {
  tags: string[];
  options: string[];
  placeholder: string;
  single: boolean;
  onChange: (value: string[]) => void;
}

const TagInput: React.FC<Props> = ({
  tags = [],
  options = [],
  placeholder = 'Add tags...',
  single,
  onChange,
}) => {
  const handleAddition = (tag: Tag) => {
    if (tags.includes(tag.id)) {
      return;
    }

    const nextValue = single ? [tag.id] : tags.concat(tag.id);
    onChange(nextValue);
  };

  const handleDelete = (index: number) => {
    const nextValue = [...tags];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const suggestions: Tag[] = options
    .filter(suggestion => !tags.includes(suggestion))
    .map(tag => ({id: tag, text: tag}));

  return (
    <ReactTags
      allowDeleteFromEmptyInput={false}
      placeholder={placeholder}
      tags={tags.map(tag => ({id: tag, text: tag}))}
      suggestions={suggestions}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      removeComponent={single ? Empty : undefined}
      autocomplete
      minQueryLength={1}
      allowDragDrop={false}
    />
  );
};

export default TagInput;
