import React from 'react';
import {Box, Typography} from '@material-ui/core';

interface Props {
  title: string;
}

const Heading: React.FC<Props> = ({title}) => {
  return (
    <Box marginBottom=".5em">
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
};

export default Heading;
