import React, {ReactNode} from 'react';
import {gql, useQuery} from '@apollo/client';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {makeStyles, createStyles} from '@material-ui/styles';

import {RowWrap, InfiniteScroll, LoaderDots} from 'components/Widgets';
import {CampaignNoGigs} from 'components/Campaign';
import {GigMedia} from 'components/Gig';

import {getOverlayText} from 'consts/gigHelper';
import {withHookPagination} from 'services/graphql';

interface Props {
  children: ReactNode | ReactNode[];
}

const Gigs: React.FC<Props> = ({children}) => {
  const classes = useStyles();
  const location = useLocation();
  const {params} = useRouteMatch();

  const {loading, error, data, variables, fetchMore} = useQuery(InfluencerGigsQuery, {
    variables: {id: params.influencerId},
  });

  if (loading) return <LoaderDots />;
  if (error) return <div>Unknown error encountered</div>;
  const {gigs} = data;
  if (gigs?.count === 0) return <CampaignNoGigs />;

  const loadMore = withHookPagination(InfluencerGigsQuery, 'gigs', data, variables, fetchMore);

  return (
    <div>
      <InfiniteScroll loadMore={loadMore} hasMore={gigs.pageInfo.hasNextPage}>
        <RowWrap className={classes.root}>
          {gigs.edges
            .map((edge: any) => edge.node)
            .map((gig: Gig) => (
              <div key={gig.id}>
                <GigMedia
                  campaign={gig.post?.campaign}
                  feature="campaign"
                  gig={gig}
                  influencer={gig.influencer}
                  showEngagement
                  url={`${location.pathname}/${gig.id}`}
                  {...getOverlayText(gig, gig.post?.postType)}
                />
              </div>
            ))}
        </RowWrap>
      </InfiniteScroll>
      {children}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    loaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '100px',
    },
    noGigs: {
      marginTop: '100px',
      fontSize: '40px',
      textAlign: 'center',
    },
  })
);

const InfluencerGigsQuery = gql`
  query InfluencerGigsQuery($id: UUIDString!, $cursor: String) {
    gigs: gigsForInfluencer(id: $id, first: 12, after: $cursor) {
      edges {
        node {
          id
          ...GigMediaFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      count
    }
  }
  ${GigMedia.fragments.gig}
`;

export default Gigs;
