import React from 'react';
import {Link} from 'react-router-dom';

import {CampaignCardProgressBar, HideInDemoMode, ProfilePicture} from '../../Widgets/';
import {constructImgixUrl} from '../Imgix';
import {useStyles} from './styles';
import {formatEngagementRate} from '../../../scenes/Dashboard/utils';
import {campaignUrlFromDashboard} from '../../../consts/urls';

interface Props {
  campaign: Campaign;
}

export const CampaignTile: React.FC<Props> = ({campaign}) => {
  const classes = useStyles();
  let style;
  if (campaign.photos.coverPhoto) {
    const background = `url(${constructImgixUrl(
      campaign.photos.coverPhoto.url,
      344,
      344,
      'crop'
    )})`;

    const shade = 'rgba(0,0,0,0.5)';
    const transparent = 'rgba(0,0,0,0)'; // Browsers don't agree what transparent means
    style = {
      background: `linear-gradient(0deg, ${shade}, ${shade} 15%, ${transparent} 30%, ${transparent}), ${background}`,
      backgroundSize: 'cover, cover',
    };
  }

  return (
    <Link
      key={campaign.id}
      className={classes.root}
      aria-label={campaign.name}
      to={campaignUrlFromDashboard(campaign)}
    >
      <div className={classes.campaignPictureWrapper} style={style}>
        <div className={classes.contentWrapper}>
          {campaign.campaignHighlights.engagementRate !== null ? (
            <div className={classes.rate} aria-label="engagement-rate">
              Total ER
              <div className={classes.rateValue}>
                {formatEngagementRate(campaign.campaignHighlights.engagementRate)}
              </div>
            </div>
          ) : null}
          <div className={classes.advertiserCampaignInfo}>
            <div className={classes.price} aria-label="budget">
              <HideInDemoMode>
                <p>{campaign.price ? campaign.price.formattedValue : null}</p>
              </HideInDemoMode>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.campaignProgress}>
        <CampaignCardProgressBar target={campaign.target} state={campaign.state} />
      </div>
      <div className={classes.advertiserInfoWrapper}>
        <ProfilePicture
          square
          className={classes.advertiserLogo}
          src={campaign.advertiser.profilePicture}
          size={48}
          borderRadius="0"
        />
        <div className={classes.advertiserCampaignInfo}>
          <h3 className={classes.campaignName} id={campaign.id}>
            {campaign.name}
          </h3>
          <div className={classes.advertiserInfo}>
            <p className={classes.advertiserName}>{campaign.advertiser.name}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CampaignTile;
