import {createStyles, makeStyles} from '@material-ui/core/styles';

import {mainContentWidth} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
    },
    title: {
      paddingBottom: '24px',
      paddingLeft: '48px',
      margin: '0 auto',
    },
    wrapper: {
      display: 'flex',
      marginTop: '24px',
    },
    imageWrapper: {
      display: 'flex',
      width: 160,
      height: 160,
      flex: '0 0 160px',
      marginLeft: '30px',
    },
  })
);
