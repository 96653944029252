import * as qs from 'query-string';

import {INFLUENCER_FILTER_STATE} from '../../../../consts/variables';

export const parseAccountStateForQuery = (state: string) => {
  if (state === INFLUENCER_FILTER_STATE.Reviewed.value) {
    return [INFLUENCER_FILTER_STATE.Reviewed.value, INFLUENCER_FILTER_STATE.Verified.value];
  } else if (state === INFLUENCER_FILTER_STATE.All.value) {
    return [
      INFLUENCER_FILTER_STATE.Disabled.value,
      INFLUENCER_FILTER_STATE.New.value,
      INFLUENCER_FILTER_STATE.Reviewed.value,
      INFLUENCER_FILTER_STATE.Verified.value,
    ];
  }
  return state;
};

export const qsStringifyOptions: qs.StringifyOptions = {
  skipEmptyString: true,
  skipNull: true,
  arrayFormat: 'comma',
};

export const qsParseOptions: qs.ParseOptions = {
  parseBooleans: true,
  parseNumbers: true,
  arrayFormat: 'comma',
};
