import {Grid, TablePagination, Typography} from '@material-ui/core';
import React from 'react';

import Pagination from './components/Pagination';
import {IPaginationContainer} from './types';
import {useStyles} from './styles';

export const PaginationContainer: React.FC<IPaginationContainer> = ({
  setOffset,
  setLimit,
  offset,
  limit,
  allCampaignsCount,
  items,
}) => {
  const classes = useStyles();

  if (allCampaignsCount === 0) return null;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={classes.paginationButtonContainer}
    >
      <Grid item container justifyContent="center" xs={12} lg={4}>
        <Pagination items={items} />
      </Grid>

      <Grid item container xs={12} lg={4} className={classes.tablePagination}>
        <TablePagination
          classes={{
            menuItem: classes.menuItem,
            select: classes.select,
            input: classes.input,
            actions: classes.actions,
          }}
          component="div"
          page={offset / limit}
          count={typeof allCampaignsCount === 'undefined' ? limit : allCampaignsCount}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage={
            <Typography id="number-per-page-caption" variant="caption">
              Number per page
            </Typography>
          }
          labelDisplayedRows={() => ''}
          nextIconButtonProps={{style: {display: 'none'}}}
          backIconButtonProps={{style: {display: 'none'}}}
          SelectProps={{
            inputProps: {
              'aria-labelledby': 'number-per-page-caption',
            },
          }}
          onPageChange={() =>
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            {}
          }
          onRowsPerPageChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setOffset(0);
            setLimit(parseInt(event.target.value, 10));
          }}
        />
      </Grid>
    </Grid>
  );
};
