import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './style.css';

const EmptyMessage = ({title, left}) => (
  <div className={classNames([styles.root, left && styles.left])}>{title}</div>
);

EmptyMessage.propTypes = {
  title: PropTypes.string.isRequired,
  left: PropTypes.bool,
};

export default EmptyMessage;
