import React from 'react';
import {Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import PlatformTable from '../../Campaign/components/CampaignPerformance/PlatformData/PlatformTable';
import {PerformanceData} from '../../Campaign/components/CampaignPerformance/types';

interface Props {
  metrics: PerformanceData[];
}

const PlatformMetrics: React.FC<Props> = ({metrics}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Social Platforms
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3} aria-label="Platform Metrics" className={classes.spacing}>
        <PlatformTable metrics={metrics} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default PlatformMetrics;
