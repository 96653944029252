import React from 'react';
import {gql, useMutation} from '@apollo/client';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import {Check} from '@material-ui/icons';

import {SelectDeliverables} from '../../../../../components/Widgets';
import {currencyForMarket} from '../../../../../consts/utilities';
import PaymentTermsSelect from '../../../components/PaymentTermsSelect';
import {DEFAULT_PAYMENT_TERMS} from '../../../components/CampaignSetup/constants';

interface UseCreateOfferFormParameters {
  campaign: Campaign;
  influencerId: string;
  suggestedReward: Currency | undefined;
  onSuccess?: () => void;
}

/**
 * Returns the values required for the `CreateOffer` component.
 *
 * NOTE: This should be used by parent components of `CreateOffer` to allow
 * custom behaviour depending on where the form is rendered.
 */
export const useCreateOfferForm = ({
  campaign,
  influencerId,
  suggestedReward,
  onSuccess,
}: UseCreateOfferFormParameters) => {
  const [makeCustomOffer, {loading, error}] = useMutation(MakeCustomOfferMutation, {
    refetchQueries: [
      'InfluencerOfferQuery',
      'InfluencerWrapperCountQuery',
      'CampaignCount',
      'TargetedCampaigns',
    ],
  });

  const formik = useFormik({
    initialValues: {
      reward: suggestedReward?.value || 0,
      forceReserve: false,
      paymentTerms: campaign?.paymentTerms || DEFAULT_PAYMENT_TERMS,
      deliverablePostIds: (campaign?.posts || []).map((post: Post) => post.id),
    },
    validationSchema: yup.object({
      reward: yup.number().integer().required(),
      forceReserve: yup.boolean(),
      paymentTerms: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
    /**
     * Because of the rules of hooks, it is likely this hook is called before
     * the form has loaded (if the parent component does not render until data
     * is loaded). We therefore need to re-initialise so that the fields are
     * updated once the data has been requested.
     */
    enableReinitialize: true,
  });

  const handleSubmit = async () => {
    await makeCustomOffer({
      variables: {
        campaignId: campaign.id,
        influencerId,
        deliverablePostIds: formik.values.deliverablePostIds,
        reward: formik.values.reward,
        forceReserve: formik.values.forceReserve,
        paymentTerms: formik.values.paymentTerms,
      },
    });

    onSuccess?.();
  };

  return {formik, loading, error};
};

type Props = {
  campaign: Campaign;
  rightAlignSubmit?: boolean;
} & ReturnType<typeof useCreateOfferForm>;

const CreateOffer: React.FC<Props> = ({campaign, formik, loading, error, rightAlignSubmit}) => {
  const isSelectedPost = (post: Post) => {
    return !!formik.values.deliverablePostIds.find(id => id === post.id);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body2" component="p" gutterBottom>
              Campaign Deliverables
            </Typography>
            <SelectDeliverables
              posts={campaign?.posts ?? []}
              selectedPosts={(campaign?.posts || []).filter(({id}) =>
                formik.values.deliverablePostIds?.includes(id)
              )}
              isSelected={isSelectedPost}
              onSelect={(_, deliverablePosts: Post[]) => {
                formik.setFieldValue(
                  'deliverablePostIds',
                  deliverablePosts.map(({id}) => id)
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption" component="p">
              Reward
            </Typography>
            <Input
              id="reward"
              name="reward"
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  {currencyForMarket(campaign.marketSlug)}
                </InputAdornment>
              }
              value={formik.values.reward}
              aria-describedby="reward"
              error={formik.touched.reward && formik.errors.reward}
              fullWidth
              required
              onChange={formik.handleChange}
            />
            {formik.touched.reward && formik.errors.reward && (
              <Typography variant="caption" color="error">
                {formik.errors.reward}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={formik.values.forceReserve}
                  name="forceReserve"
                  onChange={formik.handleChange}
                />
              }
              label="Force Reserve"
            />
          </Grid>
          <Grid item xs>
            <PaymentTermsSelect
              label="Payment Terms"
              value={formik.values.paymentTerms}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            {...(rightAlignSubmit ? {direction: 'row-reverse'} : {})}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              startIcon={!campaign.isFullyReserved && <Check />}
              disabled={campaign.isFullyReserved || loading}
            >
              {campaign.isFullyReserved ? 'Campaign is fully reserved' : 'Make Offer'}
            </Button>
            {error && (
              <Typography variant="caption" color="error">
                Error making custom offer.
              </Typography>
            )}
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

const MakeCustomOfferMutation = gql`
  mutation makeCustomOffer(
    $campaignId: UUIDString!
    $influencerId: UUIDString!
    $deliverablePostIds: [UUIDString]!
    $reward: Int!
    $paymentTerms: PaymentTerms!
    $forceReserve: Boolean
  ) {
    makeCustomOffer(
      campaignId: $campaignId
      influencerId: $influencerId
      deliverablePostIds: $deliverablePostIds
      reward: $reward
      paymentTerms: $paymentTerms
      forceReserve: $forceReserve
    ) {
      ok
      offer {
        id
        state
        formattedState
        reward {
          value
          currency
          formattedValue
        }
        paymentTerms
        deliverables {
          id
          delivered
          deliveredAt
          postId
          gigId
        }
      }
    }
  }
`;

export default CreateOffer;
