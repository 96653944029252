import React from 'react';
import {DocumentNode, useQuery} from '@apollo/client';

import {Table, InfiniteScroll} from '../../../../../components/Widgets';
import {withHookPagination} from '../../../../../services/graphql';

interface Data {
  offers: {
    edges: {
      node: {
        state: string;
      };
    }[];
    pageInfo: {
      hasNextPage: boolean;
    };
  };
}

type Column = {
  heading: string;
  data: (offer: Offer) => Offer | number | Answer;
  render:
    | ((offer: Offer) => JSX.Element)
    | ((reach: number) => string)
    | ((answer: Answer) => string);
};

type Props = {
  query: DocumentNode;
  variables: Record<string, string | CampaignAnswer | null>;
  columns: Column[];
  filters: string[];
};

const ListOffers = ({query, variables, columns, filters}: Props): JSX.Element => {
  const {loading, data, fetchMore} = useQuery<Data>(query, {variables});

  const loadMore = withHookPagination(query, 'offers', data, variables, fetchMore);

  const offers =
    data?.offers?.edges.map(edge => edge.node).filter(offer => filters.includes(offer.state)) || [];

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={data?.offers?.pageInfo?.hasNextPage || false}
      skipLoading
    >
      <Table columns={columns} data={offers} loading={loading} emptyMessage="No influencers." />
    </InfiniteScroll>
  );
};

export default ListOffers;
