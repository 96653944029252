import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';

import {GigCaption} from 'components/Campaign';
import {Button, Media, MediaContainer, LoaderDots} from 'components/Widgets';
import {LeftChevron, RightChevron} from 'components/Icons';

import {LEFT_KEY_CODE, RIGHT_KEY_CODE} from 'consts/variables';
import {campaignUrlFromParams, pickStoryFrameUrl} from 'consts/urls';
import {black30} from 'consts/brand';

import {InfluencerProfile} from './components';
import styles from './style.css';
import {Gig} from '../../../../services/fragments';

const SIZE = 600;

class UnverifiedGig extends PureComponent {
  static propTypes = {
    canAccessAllInfluencers: PropTypes.bool,
    gig: PropTypes.object,
    gigPagination: PropTypes.object,
    loading: PropTypes.bool,
    gigPaginationLoading: PropTypes.bool,
    user: PropTypes.object,
    history: PropTypes.object.isRequired,
    params: PropTypes.shape({
      campaign: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      videoPlaying: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  goToGig = gigId => {
    const {history, params} = this.props;
    const url = `${campaignUrlFromParams(params)}/unverified/${gigId}`;
    history.push(url);
  };

  goToStoryFramePicker = gigId => {
    const {history} = this.props;
    const url = `${pickStoryFrameUrl(gigId)}`;
    history.push(url);
  };

  handleKeydown = event => {
    const {gig, gigPagination} = this.props;
    if (!gig) {
      return;
    }

    if (event.keyCode === LEFT_KEY_CODE) {
      if (gigPagination.previous) {
        this.goToGig(gigPagination.previous);
      }
    } else if (event.keyCode === RIGHT_KEY_CODE) {
      if (gigPagination.next) {
        this.goToGig(gigPagination.next);
      }
    }
  };

  playVideo = () => this.setState({videoPlaying: !this.state.videoPlaying});

  render() {
    const {gig, loading, gigPaginationLoading, canAccessAllInfluencers, gigPagination} = this.props;
    if (loading || gigPaginationLoading) {
      return <LoaderDots />;
    }

    if (!gig || !gigPagination || gig.isVerified) {
      return null;
    }

    return (
      <div className={styles.root}>
        <div className={styles.arrow}>
          <div
            className={classNames({
              [styles.leftArrow]: true,
              [styles.disable]: !loading && !gigPagination.previous,
            })}
            onClick={() => gigPagination.previous && this.goToGig(gigPagination.previous)}
          >
            <LeftChevron color={black30} />
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.left}>
            <MediaContainer width={SIZE} height={SIZE}>
              <Media media={gig.submission.media} width={SIZE} height={SIZE} />
            </MediaContainer>
          </div>
          <div className={styles.right}>
            <div className={styles.info}>
              <div className={styles.header}>
                <InfluencerProfile gig={gig} canAccessAllInfluencers={canAccessAllInfluencers} />
              </div>
              <div className={styles.body}>
                <GigCaption
                  caption={gig.submission.caption}
                  hashtags={gig.submission.hashtags}
                  mentions={gig.submission.mentions}
                />
              </div>
              <div className={styles.footer}>
                <Button
                  block
                  gold
                  noMargin
                  text="Choose story frames"
                  onClick={() => this.goToStoryFramePicker(gig.id)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.arrow}>
          <div
            className={classNames({
              [styles.rightArrow]: true,
              [styles.disable]: !loading && !gigPagination.next,
            })}
            onClick={() => gigPagination.next && this.goToGig(gigPagination.next)}
          >
            <RightChevron color={black30} />
          </div>
        </div>
      </div>
    );
  }
}

const GigQuery = gql`
  query GigQuery($gigId: UUIDString!) {
    gig(id: $gigId) {
      id
      created
      reviewDate
      state
      isVerified
      submission {
        created
        caption
        hashtags
        mentions
        media {
          ...mediaResultFields
        }
      }
      post {
        id
        schedule {
          postToInstagramDeadline
        }
      }
      influencer {
        id
        username
        profilePicture
      }
    }
    canAccessAllInfluencers: hasPermission(permission: "access_all_influencers")
  }
  ${Gig.mediaPreview}
`;

const GigPaginationQuery = gql`
  query GigPaginationQuery($gigId: UUIDString!, $campaign: UUIDString) {
    gigPagination(id: $gigId, campaignId: $campaign, unverified: true) {
      next
      previous
    }
  }
`;

export default compose(
  withRouter,
  graphql(GigQuery, {
    options: ({
      match: {
        params: {gigId},
      },
    }) => ({
      variables: {
        gigId,
      },
    }),
    props: ({
      ownProps: {
        history,
        match: {params},
      },
      data: {loading, gig, canAccessAllInfluencers, user},
    }) => ({
      loading,
      gig,
      canAccessAllInfluencers,
      user,
      params,
      history,
    }),
  }),
  graphql(GigPaginationQuery, {
    options: ({params: {gigId, campaign}}) => ({
      fetchPolicy: 'network-only',
      variables: {
        gigId,
        campaign,
      },
    }),
    props: ({data: {loading, gigPagination}}) => ({
      gigPaginationLoading: loading,
      gigPagination,
    }),
  })
)(UnverifiedGig);
