/**
 * A functional and more performant replacement for the current
 * `src/components/Widgets/InfiniteScroll/index.tsx` component.
 * For the moment this is a as close as possible copy.
 *
 */
import React, {ReactNode, useEffect, useRef} from 'react';
import {Button, makeStyles} from '@material-ui/core';

import {LoaderDots} from '..';

interface Props {
  loading: boolean;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  noMoreText?: string;
  alwaysShowButton?: boolean;
  skipLoading?: boolean;
  noMargin?: boolean;
  children?: ReactNode | ReactNode[];
}

const InfiniteScroller: React.FC<Props> = ({
  loading,
  hasMore,
  loadMore,
  noMoreText,
  alwaysShowButton,
  skipLoading,
  noMargin,
  children,
}) => {
  const classes = useStyles();
  const observerTarget = useRef(null);
  const loadMoreRef = useRef(loadMore);
  const hasMoreRef = useRef(hasMore);

  useEffect(() => {
    if (loadMore) {
      loadMoreRef.current = loadMore;
    }
  }, [loadMore]);

  useEffect(() => {
    hasMoreRef.current = !!hasMore;
  }, [hasMore]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMoreRef.current) {
          (async () => await loadMoreRef.current())();
        }
      },
      {threshold: 0}
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  const shouldStickButton = hasMore && alwaysShowButton;

  return (
    <div className={!noMargin ? classes.root : undefined}>
      {children}
      {!hasMore && Boolean(noMoreText) && <div className={classes.noMoreWrapper}>{noMoreText}</div>}
      {((hasMore && !loading) || shouldStickButton) && (
        <div className={classes.loadMoreWrapper}>
          <Button variant="outlined" color="secondary" disabled={!hasMore} onClick={loadMore}>
            Load more
          </Button>
        </div>
      )}
      {!skipLoading && loading && (
        <div className={classes.loaderWrapper}>
          <LoaderDots />
        </div>
      )}
      <div ref={observerTarget}></div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    marginBottom: '30px',
  },
  loaderWrapper: {
    marginTop: '20px',
  },
  noMoreWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingRight: '100px',
    fontSize: '12px',
    color: '$black60',
  },
  loadMoreWrapper: {
    width: '100%',
    textAlign: 'center',
    margin: '20px 0',
  },
});

export default InfiniteScroller;
