import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {Label} from '../';
import styles from './style.css';

export default class TextInput extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hasError: PropTypes.bool,
    dark: PropTypes.bool,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
    autoComplete: PropTypes.string,
    big: PropTypes.bool,
    small: PropTypes.bool,
    light: PropTypes.bool,
    goldBorder: PropTypes.bool,
    centeredCursor: PropTypes.bool,
    bigCentered: PropTypes.bool,
    rightCursor: PropTypes.bool,
    formatValue: PropTypes.func,
    parseValue: PropTypes.func,
    disabled: PropTypes.bool,
    resizable: PropTypes.bool,
    minWidth: PropTypes.number,
    inputStyle: PropTypes.string,
    inputWrapperStyle: PropTypes.string,
    labelledBy: PropTypes.string,
  };

  static defaultProps = {
    formatValue: x => x,
    parseValue: x => x,
  };

  state = {
    showSaved: false,
    showFailed: false,
  };

  onChange = e => {
    const {resizable, minWidth} = this.props;
    if (resizable) {
      e.target.style.width =
        Math.max(minWidth, parseInt(minWidth / 3) * e.target.value.length) + 'px';
    }
    this.props.onChange(this.props.parseValue(e.target.value));
  };

  render() {
    const {
      description,
      label,
      placeholder,
      type = 'text',
      value,
      hasError,
      prefix,
      postfix,
      autoComplete,
      dark,
      big,
      small,
      light,
      formatValue,
      goldBorder,
      centeredCursor,
      bigCentered,
      rightCursor,
      disabled,
      minWidth,
      inputStyle,
      inputWrapperStyle,
      labelledBy,
    } = this.props;

    return (
      <div
        className={classNames(styles.root, {
          [inputWrapperStyle]: inputWrapperStyle,
        })}
      >
        {label && <Label label={label} />}
        <div className={styles.inputContainer}>
          {prefix && (
            <span
              className={classNames(styles.prefix, {
                [styles.bigPrefix]: big,
                [styles.smallPrefix]: small,
                [styles.disabledFix]: disabled,
              })}
            >
              {prefix}
            </span>
          )}
          {postfix && (
            <span
              className={classNames(styles.postfix, {
                [styles.bigPostfix]: big,
                [styles.smallPostfix]: small,
                [styles.disabledFix]: disabled,
              })}
            >
              {postfix}
            </span>
          )}

          <input
            className={classNames(styles.input, {
              [styles.error]: hasError,
              [styles.inputWithPrefix]: prefix,
              [styles.inputWithPostfix]: postfix,
              [styles.inputWithSmallPrefix]: prefix && small,
              [styles.inputWithSmallPostfix]: postfix && small,
              [styles.dark]: dark,
              [styles.bigInput]: big,
              [styles.smallInput]: small,
              [styles.lightText]: light,
              [styles.disabled]: disabled,
              [styles.goldBorder]: goldBorder,
              [styles.centeredCursor]: centeredCursor,
              [styles.bigCentered]: bigCentered,
              [styles.rightCursor]: rightCursor,
              [inputStyle]: inputStyle,
            })}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={formatValue(value) || ''}
            disabled={disabled}
            style={{width: `${minWidth}px`}}
            aria-labelledby={labelledBy}
            onChange={this.onChange}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>

        {description && <div className={styles.description}>{description}</div>}
      </div>
    );
  }
}
