import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import styles from './style.css';

const LinkButton = ({children, className, ...rest}) => (
  <Link {...rest} className={classNames(className, styles.button)}>
    {children}
  </Link>
);

LinkButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default LinkButton;
