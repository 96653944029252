import {Grid, InputAdornment, TextField, Typography} from '@material-ui/core';
import React from 'react';
import {Control} from 'react-redux-form';

import withCustomControl from '../../../../../../components/Widgets/Forms/CustomControl';
import {useStyles} from './styles';

const CustomFormInput = (props: any) => {
  const classes = useStyles();
  const {
    parseValue = (event: React.ChangeEvent<HTMLInputElement>) => event,
    formatValue = (event: React.ChangeEvent<HTMLInputElement>) => event,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return props.onChange(parseValue(event.target.value));
  };

  return (
    <Grid container>
      <TextField
        fullWidth
        id={props.id}
        name={props.name}
        inputProps={{
          'aria-labelledby': props.ariaLabelledby,
          ...(props.type === 'number'
            ? {
                inputmode: 'numeric',
                pattern: '[0-9]*',
              }
            : {}),
        }}
        variant="outlined"
        type={props.type === 'number' ? 'text' : props.type}
        className={classes.control}
        InputProps={{
          classes: {
            input: classes.inputText,
          },
          startAdornment: props.prefix && (
            <InputAdornment position="start">{props.prefix}</InputAdornment>
          ),
        }}
        placeholder={props.placeholder}
        disabled={props.disabled}
        required={props.required}
        value={formatValue(props.value) || ''}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={handleChange}
      />
      <Typography variant="caption" component="p" className={classes.description}>
        {props.description}
      </Typography>
    </Grid>
  );
};

export const ControlFormInput = withCustomControl(props => (
  <Control.text component={CustomFormInput} {...props} />
));
