import React from 'react';

export default () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Influencer-state-popup" transform="translate(-621.000000, -347.000000)">
        <g id="Group-Copy" transform="translate(621.000000, 347.000000)">
          <rect id="Rectangle-93" fill="#FFFFFF" x="0" y="0" width="16" height="16" rx="4" />
          <g id="Logo-Copy-3" transform="translate(2.000000, 2.000000)">
            <path
              d="M0,8.26354368 L3.0131037,10.2931198 L4.92488423,11.5807389 C5.6003909,12.0358242 6.45214944,12.0358242 7.12778165,11.5807389 L9.03943664,10.2931198 L12.0525403,8.26354368 L12.0525403,4.91317379 L6.02633294,8.97246372 L0,4.91317379 L0,8.26354368 Z"
              id="Fill-147"
              fill="#A67A2E"
            />
            <path
              d="M12.0525529,3.65850921 L9.03944919,1.62893307 L7.12766866,0.341314028 C6.452162,-0.113771343 5.60040345,-0.113771343 4.92477125,0.341314028 L3.01311625,1.62893307 L1.25535526e-05,3.65850921 L1.25535526e-05,7.00887909 L6.02621996,2.94958917 L12.0525529,7.00887909 L12.0525529,3.65850921 Z"
              id="Fill-148"
              fill="#000000"
            />
            <path
              d="M7.17174419,4.42145859 L6.02623251,2.94965799 L4.88072083,4.42145859 C4.19027544,5.30822809 4.19027544,6.61387986 4.8805953,7.50078701 L6.02623251,8.97244996 L7.17174419,7.50078701 C7.86218958,6.61387986 7.86218958,5.30822809 7.17174419,4.42145859"
              id="Fill-149"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
