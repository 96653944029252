import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {useQuery, useMutation, gql} from '@apollo/client';

import {DocumentTitle} from 'components/Widgets';

import useFeatureFlags from 'hooks/useFeatureFlags';

import {getPageTitle} from '../../../consts/utilities';
import {Match} from './types';
import {useStyles} from './styles';
import AdvertiserTabs from '../AdvertiserTabs';
import {BrandProfile, Members} from './components';

interface Props {
  match: Match;
}

const AdvertiserMembers: React.FC<Props & RouteComponentProps> = ({match: {params}}: Props) => {
  const classes = useStyles();
  const {displayBrandMembersProfile} = useFeatureFlags();
  const {loading, data} = useQuery(AdvertiserMembersQuery, {
    variables: {domain: params.advertiser},
  });

  const [sendInviteMutation] = useMutation(SendInviteMutation, {
    refetchQueries: ['AdvertiserMembersListQuery'],
  });

  const sendInvite = (advertiserId: string, email: string) => {
    sendInviteMutation({
      variables: {advertiserId, email},
      optimisticResponse: {
        sendInvite: {
          ok: true,
        },
      },
    });
  };

  return (
    <div className={classes.wrapper}>
      <AdvertiserTabs params={params} />
      {!loading && (
        <div className={classes.root}>
          <DocumentTitle title={getPageTitle(`${data.advertiser.name} - Members`)} />
          {displayBrandMembersProfile && <BrandProfile advertiser={data.advertiser} />}

          <Members advertiser={data.advertiser} sendInvite={sendInvite} />
        </div>
      )}
    </div>
  );
};

const AdvertiserMembersQuery = gql`
  query AdvertiserMembersQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      archived
      profilePicture
      influencerCooldown
    }
  }
`;

const SendInviteMutation = gql`
  mutation SendInviteMutation($advertiserId: UUIDString!, $email: String!) {
    enrollUser(advertiserId: $advertiserId, email: $email) {
      ok
    }
  }
`;

export default withRouter(AdvertiserMembers);
