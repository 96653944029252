export const REVOKABLE_STATES = [
  'accepted',
  'approved_by_brand',
  'candidate',
  'invited',
  'pending',
  'requested',
];

export const REJECTED_STATES = ['rejected', 'revoked', 'rejected_by_brand'];
