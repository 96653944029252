import PropTypes from 'prop-types';
import React from 'react';

const Heart = ({color = '#000000', size = 11}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 11 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Report-–-FrontPage" transform="translate(-31.000000, -752.000000)" fill={color}>
      <path
        d="M33.75,752 C32.2312169,752 31,753.193907 31,754.666667 C31,758.024302 36.5,762 36.5,762 C36.5,762 42,758.078366 42,754.666667 C42,753.193907 40.7687831,752 39.25,752 C37.7312169,752 36.5,753.193907 36.5,754.666667 C36.5,753.193907 35.2687831,752 33.75,752 Z"
        id="Oval-1"
      />
    </g>
  </svg>
);

Heart.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Heart;
