export const CONFIGCAT_POLLING_SECONDS = 60; // 60s is the default for cache and polling

export const rowMarginTop = '16px';
export const LONG_DATE_FORMAT = 'Do MMMM YYYY';
export const LONG_DATE_TIME_FORMAT = 'dddd Do MMMM YYYY, HH:mm';
export const SHORT_DATE_TIME_FORMAT = 'Do MMM, HH:mm';
export const SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE = `${SHORT_DATE_TIME_FORMAT} zz`;
export const CALENDAR_DATE_FORMAT = 'Do MMMM';
export const ENTER_KEY_CODE = 'Enter';
export const WHITE_SPACE_KEY = ' ';
export const ESC_KEY_CODE = 27;
export const LEFT_KEY_CODE = 37;
export const RIGHT_KEY_CODE = 39;
export const IG_MEDIA_EXTRA_HEIGHT = 180;
export const IG_MEDIA_MIN_WIDTH = 435;
export const MEDIA_SIZE_SMALL = 77;
export const NONE_OPTION = 'none';
export const RESERVED = 'awaiting_submission';
export const SUBMITTED = 'submitted';
export const REVIEWED = 'reviewed';
export const APPROVED = 'approved';
export const PAYMENT_PROCESSING = 'payment_processing';
export const PAID = 'paid';
export const REPORTED = 'reported';
export const REJECTED = 'rejected';
export const REQUIRES_RESUBMIT = 'requires_resubmit';
export const RATEABLE = ['submitted', 'paid'];
export const REPORTABLE = ['submitted', 'awaiting_submission'];
export const CAMPAIGN_FILTER_STATE = {
  All: {value: 'all', name: 'All'},
  Draft: {value: 'draft', name: 'Draft'},
  Launched: {value: 'launched', name: 'Launched'},
  Completed: {value: 'completed', name: 'Completed'},
};
export const INFLUENCER_FILTER_STATE = {
  All: {value: 'all', name: 'All'},
  Disabled: {value: 'disabled', name: 'Disabled'},
  New: {value: 'new', name: 'New'},
  Reviewed: {value: 'reviewed', name: 'Reviewed'},
  Verified: {value: 'verified', name: 'Verified'},
};
export const UNSUBMIT_OPTIONS = [
  "The post didn't fulfill campaign brief",
  "The post didn't meet our quality standard",
];
export const REPORT_OPTIONS = [
  'Did not fulfill campaign brief',
  'Inappropriate image content',
  'Caption amendment needed',
];
export const REJECT_OPTIONS = ['Client rejected', 'Content should not go live'];
export const BLOCK_OPTIONS = [
  'low quality',
  'non-organic following',
  'concept/brand account',
  'hashtag abuse',
];
export const COOLDOWN_OPTIONS = [
  {label: '14 days', days: 14},
  {label: '30 days', days: 30},
];
export const NOTIFICATION_TIME = 5000;
export const INVALID_MENTION_REGEX = /[#@,]/g;
export const INVALID_HASHTAG_REGEX = /[#@,.]/g;
export const CUSTOM_THEME_STORAGE_KEY = 'adminCustomTheme';
export const GENDERS = [
  {value: 'male', name: 'Male'},
  {value: 'female', name: 'Female'},
  {value: 'all', name: 'Unspecified'},
];
export const CAMPAIGN_GIG_FILTERS = {
  submitted: ['submitted', 'paid', 'payment_processing'],
  awaitingSubmission: ['awaiting_shipping', 'in_transit', 'awaiting_submission'],
  reported: ['reported', 'rejected'],
};
export const FACEBOOK_CAMPAIGN_OBJECTIVES = [
  {value: 'BRAND_AWARENESS', name: 'Brand awareness'},
  {value: 'REACH', name: 'Reach'},
  {value: 'LINK_CLICKS', name: 'Link clicks'},
  {value: 'LOCAL_AWARENESS', name: 'Local awareness'},
];
export const FACEBOOK_APP_ID = '1690095864543832';
export const FACEBOOK_APP_SCOPE = 'public_profile,ads_management,ads_read,manage_pages';

export const DND_ITEM_TYPES = {
  IMAGE: 'image',
  TABLE_ROW: 'table_row',
};

export const MARKETS = [
  {
    value: 'uk',
    label: 'United Kingdom',
    description: 'GBP £',
  },
  {
    value: 'us',
    label: 'United States',
    description: 'USD $',
  },
  {
    value: 'eu',
    label: 'European Union',
    description: 'EUR €',
  },
  {
    value: 'se',
    label: 'Sweden',
    description: 'SEK kr',
  },
  {
    value: 'test',
    label: 'Demo/Test',
    description: 'A market for demoing/testing only',
  },
];

export const REWARD_MODELS = [
  {
    value: 'reach',
    label: 'Reach',
    description: 'Guaranteed follower reach',
  },
  {
    value: 'assets',
    label: 'Asset',
    description: 'Guaranteed number of influencers',
  },
];

export const GIG_STATUS: {[key: string]: {status: string}} = {
  awaiting_influencer_submission: {status: 'Awaiting submission'},
  awaiting_staff_review: {status: 'Awaiting TAKUMI review'},
  awaiting_client_review: {status: 'Awaiting review approval'},
  rejected_by_staff: {status: 'Rejected by TAKUMI'},
  reported_by_client: {status: 'Reported by client'},
  resubmission_requested: {status: 'Resubmission requested'},
  awaiting_influencer_post: {status: 'Awaiting post'},
  posted: {status: 'Posted'},
  completed: {status: 'Completed'},
};
