import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';

import {LoaderDots, Modal} from 'components/Widgets';

import styles from './style.css';

class PromptsModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    offerId: PropTypes.string,
    offer: PropTypes.object,
    loading: PropTypes.bool,
  };

  render() {
    const {offerId, closeModal, offer, loading} = this.props;
    if (!offerId) {
      return null;
    }

    return (
      <Modal id="prompts" title="Prompts" open fullWidth maxWidth="sm" onClose={closeModal}>
        <div className={styles.root}>
          {loading ? (
            <LoaderDots />
          ) : (
            <div>
              {offer.answers.map(({prompt, answer}, idx) => (
                <div key={`${offerId}-${idx}`}>
                  <div className={styles.prompt}>{prompt}</div>
                  <div className={styles.answer}>
                    {answer.length === 1 ? (
                      <div>{answer[0]}</div>
                    ) : (
                      <ul className={styles.multipleAnswer}>
                        {answer.map((ans, ansIdx) => (
                          <li key={`${offerId}-${idx}-${ansIdx}`} className={styles.multipleAnswer}>
                            {ans}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const OfferAnswersQuery = gql`
  query OfferAnswersQuery($offerId: UUIDString!) {
    offer(id: $offerId) {
      id
      answers: brandVisibleAnswers {
        prompt
        answer
      }
    }
  }
`;

export default compose(
  graphql(OfferAnswersQuery, {
    props: ({data: {offer, loading}}) => ({
      offer,
      loading,
    }),
    skip: ownProps => !ownProps.offerId,
  })
)(PromptsModal);
