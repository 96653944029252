import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Tooltip, TableZebraRow} from '../../../../../components/Widgets';
import {numberWithCommas} from '../../../../../consts/utilities';
import {DATA_SERVICE_MODEL_INFO} from '../helper';
import {PerformanceData} from '../types';

interface Props {
  metrics: {platform: string; metrics: PerformanceData}[];
  currency?: string;
}

const PlatformTable: React.FC<Props> = ({metrics, currency}) => {
  const classes = useStyles();
  const modelName = 'campaign_performance';

  return (
    <Grid container spacing={3}>
      {metrics.map((platformMetric, i) => {
        const platform = platformMetric.platform;
        const platformInfo = {
          instagram: {name: 'Instagram', icon: 'skill-icons:instagram'},
          tiktok: {name: 'TikTok', icon: 'logos:tiktok-icon'},
          youtube: {name: 'YouTube', icon: 'logos:youtube-icon'},
        };
        const gridLayout = metrics.length === 2 ? 6 : 4;

        return (
          <Grid key={`platform-${i}`} item xs={12} md={gridLayout}>
            <Card>
              <CardContent>
                <Typography variant="h4" className={classes.center} paragraph>
                  <Icon icon={platformInfo[platform].icon} width={24} />
                  &nbsp; {platformInfo[platform].name}
                </Typography>
                <TableContainer>
                  <Table aria-label={`${platform} metrics`}>
                    <TableBody>
                      {Object.entries(platformMetric.metrics).map(
                        ([metricName, metricValue], i) => {
                          const metricInfo = DATA_SERVICE_MODEL_INFO[modelName][metricName];
                          const metricDisplayName = metricInfo.name;
                          const formatter = metricInfo.formatter;

                          let formattedValue;
                          if (typeof metricValue === 'number') {
                            formattedValue = formatter
                              ? formatter(metricValue, currency)
                              : numberWithCommas(metricValue);
                          } else {
                            formattedValue = metricValue;
                          }

                          return (
                            <TableZebraRow key={`metric-${i}`}>
                              <TableCell component="th" scope="row" align="left">
                                <strong>{metricDisplayName}</strong>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip overlay={numberWithCommas(metricValue)} placement="left">
                                  <Typography variant="body1" component="span">
                                    {formattedValue}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            </TableZebraRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles({
  center: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default PlatformTable;
