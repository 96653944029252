import React from 'react';
import classNames from 'classnames';

import styles from './style.css';

type RowProps = {
  children: React.ReactNode;
  className: string;
  noMargin: boolean;
  noPadding: boolean;
  gutter: boolean;
};

export const Row = ({children, className, noMargin, noPadding, gutter}: RowProps): JSX.Element => {
  const clsName = classNames({
    [styles.row]: true,
    [styles.gutter]: gutter,
    [styles.noMargin]: noMargin,
    [styles.noPadding]: noPadding,
    [className]: Boolean(className),
  });

  return <div className={clsName}>{children}</div>;
};

type ColumnProps = {
  children: React.ReactNode;
  right: boolean;
  className: string;
  flex: number;
};

export const Column = ({className, children, right, flex = 1}: ColumnProps): JSX.Element => {
  const clsName = classNames({
    [styles.column]: true,
    [styles.right]: right,
    [className]: Boolean(className),
  });

  return (
    <div className={clsName} style={{flex}}>
      {children}
    </div>
  );
};
