import {Grid, Typography} from '@material-ui/core';
import React from 'react';

import {ProfilePicture} from '../../../../../components/Widgets';

interface Props {
  user: User;
}

const UserCard: React.FC<Props> = ({user}) => {
  return (
    <Grid container direction="row" spacing={2} data-testid="user-card">
      <Grid item>
        <ProfilePicture src={user.profilePicture} />
      </Grid>
      <Grid item container direction="column" xs>
        <Grid item>
          <Typography variant="subtitle1">{user?.fullName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">{user?.email}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserCard;
