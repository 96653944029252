import {createTheme} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

import {
  fontFamily,
  purple,
  white,
  sizeBody2,
  black,
  black60,
  red,
  black80,
} from './brand.integration';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#9B51E0',
    },
    // Brand
    'brand-01': '#000000',
    'brand-02': '#4E4E5A',
    'brand-03': '#727279',

    // Text
    'text-01': '#000000',
    'text-02': '#4E4E5A',
    'text-03': '#727279',
    'text-inverse': '#FFFFFF',

    // UI
    'ui-01': '#FFFFFF',
    'ui-02': '#FAFAFC',
    'ui-03': '#EEEEF1',
    'ui-04': '#D3D4D8',
    'ui-05': '#A1A4B1',
    'ui-06': '#232735',
    'ui-07': '#000000',

    // Highlight
    'highlight-01': '#C151E9',
    'highlight-02': '#9B51E0',

    // Hover
    'hover-primary': '#232735',
    'hover-row': '#EEEEF1',

    // Support
    'support-info': '#1EA0C3',
    'support-success': '#0693E3',
    'support-error': '#BC1C1C',
    'support-warning': '#FCB900',

    // Stats
    'stats-er': '#E00B79',
    'stats-impressions': '#00D084',
    'stats-reach': '#0403F3',
    'stats-general': '#9B51E0',
    'stats-insights': '#C151E9',
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '48px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    h6: {
      fontWeight: 700,
      fontSize: '14px',
      textTransform: 'uppercase',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '20px',
    },
    button: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCircularProgress: {
      colorPrimary: {
        color: purple,
      },
      colorSecondary: {
        color: grey[500],
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: black,
      },
      colorSecondary: {
        color: black60,
      },
      colorError: {
        color: red,
      },
      colorTextPrimary: {
        color: black,
      },
      colorTextSecondary: {
        color: purple,
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: sizeBody2,
        fontFamily: fontFamily,
        textAlign: 'left',
        '&:hover': {
          backgroundColor: `${purple}20`,
        },
        '&.Mui-selected': {
          backgroundColor: `${purple}20`,
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 4,
        textAlign: 'left',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        boxShadow: 'none !important',
        '&:hover': {
          boxShadow: 'none !important',
        },
      },
      containedPrimary: {
        color: `${white}`,
        backgroundColor: `${black}`,
        '&:hover': {
          backgroundColor: `${black}80`,
        },
      },
      outlined: {
        borderWidth: 1,
      },
      outlinedPrimary: {
        color: `${black}`,
        backgroundColor: `${white}`,
        borderColor: `${black}`,
        '&:hover': {
          borderColor: `${black}20`,
          backgroundColor: `${white}`,
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '2px',
        padding: '15px',
        '& > form': {
          display: 'contents',
        },
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: '20px',
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom: `1px solid ${black}50`,
        },
        '&::after': {
          borderBottom: `2px solid ${purple}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: `${black80}`,
        padding: '5px 10px',
        fontSize: '12px',
        color: `${white}`,
        borderRadius: 5,
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.3)',
      },
      arrow: {
        color: `${black80}`,
      },
    },
    MuiBadge: {
      badge: {
        minWidth: 'max-content',
      },
    },
    MuiAvatar: {
      fallback: {
        width: '100%',
        height: '100%',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: `${black}80`,
        '&.Mui-checked': {
          color: `${black}`,
        },
      },
    },
    MuiCard: {
      root: {
        // boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
      },
    },
    MuiPaper: {
      outlined: {
        border: 'none',
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: `${purple}`,
        },
        '&$checked + $track': {
          backgroundColor: `${purple}`,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: `transparent`,
          borderBottom: `1px solid ${purple}`,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$checked': {
          color: `${purple}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${black}`,
        },
      },
    },
    MuiSlider: {
      colorPrimary: {
        color: `${black}`,
      },
      root: {
        '&$focusVisible': {
          boxShadow: '0px 0px 0px 0.8 rgba(155, 81, 224, 0.16)',
        },
      },
      valueLabel: {
        borderRadius: 0,
        '& > span': {
          transform: 'rotate(0)',
          backgroundColor: `transparent`,
        },
        '& > span > span': {
          transform: 'rotate(0)',
          padding: '5px 6px',
          border: `2px solid ${black}`,
          backgroundColor: `${black}`,
          color: `${white}`,
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: `${purple}`,
        },
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 0,
        borderWidth: 1,
        borderColor: `${black}`,
        color: `${black}`,
        '&:hover': {
          color: `${black}`,
        },
        '&.Mui-selected': {
          color: `${white}`,
          backgroundColor: `${black}`,
          '&:hover': {
            color: `${white}`,
            backgroundColor: `${black} !important`,
          },
        },
      },
    },
    MuiTimelineDot: {
      defaultGrey: {
        backgroundColor: `${purple}`,
      },
      primary: {
        backgroundColor: `${purple}`,
      },
      outlinedPrimary: {
        borderColor: `${purple}`,
      },
      secondary: {
        backgroundColor: `${grey}`,
      },
      outlinedSecondary: {
        borderColor: `${grey}`,
      },
    },
    MuiTimelineConnector: {
      root: {
        backgroundColor: `${black}`,
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: `${purple}`,
        },
      },
    },
    MuiChip: {
      root: {
        width: 'fit-content',
        padding: '1rem .2rem',
        '& .MuiChip-avatar': {
          width: '1rem',
          backgroundColor: `transparent`,
        },
        '&.MuiChip-outlined': {
          backgroundColor: 'transparent',
        },
      },
      colorPrimary: {
        color: `${black}`,
        borderColor: `${black}20`,
        backgroundColor: `${black}20`,
      },
      colorSecondary: {
        color: `${purple}`,
        borderColor: `${purple}20`,
        backgroundColor: `${purple}20`,
      },
    },
    MuiAlert: {
      root: {
        '& .MuiAlert-action': {
          alignItems: 'flex-start',
          paddingTop: '.2rem',
        },
      },
    },
  },
} as any);

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    'brand-01': React.CSSProperties['color'];
    'brand-02': React.CSSProperties['color'];
    'brand-03': React.CSSProperties['color'];
    'text-01': React.CSSProperties['color'];
    'text-02': React.CSSProperties['color'];
    'text-03': React.CSSProperties['color'];
    'text-inverse': React.CSSProperties['color'];
    'ui-01': React.CSSProperties['color'];
    'ui-02': React.CSSProperties['color'];
    'ui-03': React.CSSProperties['color'];
    'ui-04': React.CSSProperties['color'];
    'ui-05': React.CSSProperties['color'];
    'ui-06': React.CSSProperties['color'];
    'ui-07': React.CSSProperties['color'];
    'highlight-01': React.CSSProperties['color'];
    'highlight-02': React.CSSProperties['color'];
    'hover-primary': React.CSSProperties['color'];
    'hover-row': React.CSSProperties['color'];
    'stats-er': React.CSSProperties['color'];
    'stats-impressions': React.CSSProperties['color'];
    'stats-reach': React.CSSProperties['color'];
    'stats-general': React.CSSProperties['color'];
    'support-info': React.CSSProperties['color'];
    'support-success': React.CSSProperties['color'];
    'support-error': React.CSSProperties['color'];
    'support-warning': React.CSSProperties['color'];
    'stats-insights': React.CSSProperties['color'];
  }

  // Allow configuration using createTheme
  interface PaletteOptions {
    'brand-01': React.CSSProperties['color'];
    'brand-02': React.CSSProperties['color'];
    'brand-03': React.CSSProperties['color'];
    'text-01': React.CSSProperties['color'];
    'text-02': React.CSSProperties['color'];
    'text-03': React.CSSProperties['color'];
    'text-inverse': React.CSSProperties['color'];
    'ui-01': React.CSSProperties['color'];
    'ui-02': React.CSSProperties['color'];
    'ui-03': React.CSSProperties['color'];
    'ui-04': React.CSSProperties['color'];
    'ui-05': React.CSSProperties['color'];
    'ui-06': React.CSSProperties['color'];
    'ui-07': React.CSSProperties['color'];
    'highlight-01': React.CSSProperties['color'];
    'highlight-02': React.CSSProperties['color'];
    'hover-primary': React.CSSProperties['color'];
    'hover-row': React.CSSProperties['color'];
    'stats-er': React.CSSProperties['color'];
    'stats-impressions': React.CSSProperties['color'];
    'stats-reach': React.CSSProperties['color'];
    'stats-general': React.CSSProperties['color'];
    'support-info': React.CSSProperties['color'];
    'support-success': React.CSSProperties['color'];
    'support-error': React.CSSProperties['color'];
    'support-warning': React.CSSProperties['color'];
    'stats-insights': React.CSSProperties['color'];
  }
}
