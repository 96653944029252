import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: '36px',
      marginBottom: '36px',
      width: '100%',
    },
    chip: {
      backgroundColor: theme.palette['ui-04'],
      height: '26px',
      borderRadius: '2px',
    },
  })
);
