import React from 'react';
import moment from 'moment';
import {gql, useQuery} from '@apollo/client';
import {Link} from 'react-router-dom';

import {InfiniteScroll, LoaderDots, ProfilePicture, Table} from 'components/Widgets';
import {MaterialDesignIcon} from 'components/Icons';

import {numberWithCommas} from 'consts/utilities';
import {withPagination} from 'services/graphql';
import {Influencer} from 'services/fragments';

import styles from '../components/PlaylistTable/style.css';

const columns = [
  {
    heading: 'Influencer',
    data: d => (
      <div className={styles.playlistRow}>
        <ProfilePicture src={d.influencer.profilePicture} size={50} />
        <div>
          <Link key={d.influencer.id} to={`/influencers/${d.influencer.id}/gigs`}>
            <div className={styles.username}>{d.influencer.fullName}</div>
          </Link>
          <div>{d.influencer.email}</div>
        </div>
      </div>
    ),
  },
  {
    heading: 'Reward',
    data: d => d.reward,
    render: reward => reward.formattedValue,
  },
  {
    heading: 'Followers',
    data: d => d.influencer.followers,
    render: followers => numberWithCommas(followers),
  },
  {
    heading: 'Estimated engagements',
    data: d => d.influencer.estimatedEngagementsPerPost,
    render: engagements => numberWithCommas(engagements),
  },
  {
    heading: 'Estimated impressions',
    data: d => d.influencer.estimatedImpressions,
    render: impressions => numberWithCommas(impressions),
  },
  {
    heading: 'Status',
    data: d => {
      if (d.lastNotificationSent) return 'Notified ' + moment(d.lastNotificationSent).fromNow();
      if (!d.influencer.hasDevice) return 'No device';
      return 'Not notified';
    },
  },
  {
    heading: 'Accepted',
    data: d =>
      d.offer &&
      d.offer.state !== 'pending' &&
      d.offer.state !== 'rejected' &&
      d.offer.state !== 'invited' && <MaterialDesignIcon icon={'done'} />,
  },
];

interface Props {
  campaign: Campaign;
}

const NotificationList: React.FC<Props> = ({campaign}) => {
  const {loading, error, data} = useQuery(CampaignNotificationsQuery, {
    variables: {
      campaignId: campaign.id,
    },
  });

  if (loading) {
    return <LoaderDots />;
  }

  const loadMore = withPagination(CampaignNotificationsQuery, 'campaignNotifications');

  return (
    <InfiniteScroll
      className="notificationList"
      loadMore={loadMore}
      hasMore={!data.loading && data.campaignNotifications?.pageInfo.hasNextPage}
      pending={data.loading}
    >
      {data.campaignNotifications && (
        <Table
          keySelector={row => row.influencer.id}
          columns={columns
            .filter(
              c => campaign.rewardModel == 'engagements' || c.heading !== 'Estimated engagements'
            )
            .filter(
              c => campaign.rewardModel === 'impressions' || c.heading !== 'Estimated impressions'
            )}
          data={data.campaignNotifications.edges.map(edge => edge.node)}
          emptyMessage="List is empty"
        />
      )}
    </InfiniteScroll>
  );
};

const CampaignNotificationsQuery = gql`
  query campaignNotificationsQuery($campaignId: UUIDString!, $cursor: String) {
    campaignNotifications(id: $campaignId, after: $cursor, first: 24) {
      count
      edges {
        node {
          reward {
            formattedValue
          }
          offer {
            id
            state
          }
          influencer {
            id
            hasDevice
            fullName
            email
            profilePicture
            ... on Influencer {
              ...socialPlatformsFields
            }
          }
          notificationCount
          lastNotificationSent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${Influencer.socialPlatformsFragment}
`;

export default NotificationList;
