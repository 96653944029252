import {createStyles, makeStyles} from '@material-ui/core/styles';

import {semiBold} from '../../../../../consts/brand.integration';
import {theme} from '../../../../../consts/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    metric: {
      marginRight: '40px',
    },
    title: {
      fontWeight: semiBold,
    },
    valueWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    value: {
      fontWeight: 700,
    },
    plannedValue: {
      color: theme.palette['text-02'],
      height: 24,
    },
    description: {
      maxWidth: '200px',
      padding: '10px',
    },
    descriptionIcon: {
      position: 'absolute',
      padding: '5px',
    },
    dailyChange: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
