import * as Sentry from '@sentry/react';
import React, {useEffect, useState} from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {BrowserRouter as Router} from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Routes from './routes';
import * as api from './services/api';
import {MAINTENANCE_MODE} from './consts/errorCodes';
import Maintenance from './scenes/Maintenance';
import {App} from './scenes/App';
import initGtm from './utils/gtm';

interface Props {
  store: any;
  history: any;
}

const AppRouter: React.FC<Props> = ({store, history}) => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    try {
      (async function () {
        await api.getStatus();
      })();
    } catch (error) {
      if (error.code === MAINTENANCE_MODE) {
        setIsMaintenance(true);
      }
    }
    // init tracker
    initGtm();
  }, []);

  if (isMaintenance) {
    return (
      <App>
        <Maintenance />
      </App>
    );
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Router>
      </ConnectedRouter>
    </Provider>
  );
};

export default Sentry.withProfiler(AppRouter);
