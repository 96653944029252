import {createObj, range} from './utilities';

export const interestsItems = [
  createObj('Art', 'f116f32b-3904-4164-9b3a-1c30f8abc3df', 'palette'),
  createObj('Travel', '63e5a866-9868-424c-bfeb-69c7ca9c0f2a', 'explore'),
  createObj('Photographer', '0eaaf7cd-11e1-46f9-8dd7-3e85a815a6db', 'photo_camera'),
  createObj('Video', '3a23a45e-21d0-4e46-b3fe-39f517962fc8', 'theaters'),
  createObj('Food', 'c530bc68-d862-4ddd-a14f-73deb179bdef', 'restaurant_menu'),
  createObj('Sports & Fitness', 'b0c85d85-8150-466c-bc52-917955e4d404', 'rowing'),
  createObj('Health & Wellness', '52f8ea24-b602-4737-9198-b28d379ad3a5', 'favorite'),
  createObj('Parenting', '3f5d6e8d-3284-4bbf-8c3a-bff2842c3b4b', 'child_care'),
  createObj('Home & Interior', 'f9318cf2-ad60-4110-817e-2f3b794bd309', 'home'),
  createObj('Pets', '7733b73d-4154-407c-96f5-b0ae4d503827', 'pets'),
  createObj('Beauty', '8e79af90-70d7-46d7-8db6-234695c77684', 'star'),
  createObj('Fashion', 'a3cc0363-521c-4efd-9258-7d07389b6f1b', 'face'),
  createObj('Luxury', 'd8faa3d9-c826-4619-aff0-dbe1ff6e57ac', 'watch'),
  createObj('Lifestyle', '0ee48f2d-b1f2-4a34-908d-ade90b546290', 'group'),
  createObj('Student', 'e0448012-1e0e-40a0-8153-54b0aa0fb7a5', 'menu_book'),
  createObj('Sustainability', 'fb79d46d-f882-4a13-89bc-8659ea585104', 'forest'),
  createObj('Explorer', '9f5d81f6-5e6e-4203-960b-a18a8d2f2a04', 'travel_explore'),
  createObj('LGBT+', '1de7a61b-23a1-4acf-bdcf-cbfb05a3dcd1', 'transgender'),
  createObj('Fitness', 'f4a49ec2-4610-441d-8015-0f6455b52051', 'fitness_center'),
  createObj('Gaming', '8be2f227-2419-4502-80a4-e05fef1f5980', 'sports_esports'),
  createObj('Baker', 'f78db5ab-0b22-4e1b-a576-f1d126735b62', 'cake'),
  createObj('Content Creation', '2f3d7451-82f3-40db-9988-c01dd8726694', 'video_camera_front'),
  createObj('Business', '8cb6a089-c23b-415c-a2cf-1f399f9b5050', 'business'),
  createObj('Comedy', '60f72876-809b-4bc4-b613-848e10e10a6b', 'theater_comedy'),
  createObj('Mixology', 'f8af8f84-8db3-4bb0-9c9f-56949864e879', 'local_bar'),
  createObj('Sports', '14a8de39-9137-4153-8eda-79c0040eea1b', 'sports_handball'),
  createObj('Outdoors', 'd7c1e791-7a52-42b7-ac32-e0e8cf81a6ba', 'nature_people'),
  createObj('Hair', 'ac0c7b41-710b-4c72-a9a5-8b379036fd82', 'face'),
  createObj('Skincare', '40501804-a316-49b1-982d-3a74303bf2b0', 'face_retouching_natural'),
  createObj('Beauty', '39314e7f-5864-40b4-8cde-9aa75338a39f', 'spa'),
  createObj('Family', '4ceda48b-7a84-4faf-8a6f-ca881f1825ff', 'family_restroom'),
];

export const ageValues = {
  '18-24': range(18, 24),
  '25-30': range(25, 30),
  '31-35': range(31, 35),
  '36+': range(36, 120),
};

export const genderValues = [
  createObj('All', 'all'),
  createObj('Male', 'male'),
  createObj('Female', 'female'),
];

export const graceOptions = [
  createObj('24 hours', '86400'),
  createObj('2 days', '172800'),
  createObj('4 days', '345600'),
  createObj('1 week', '604800'),
  createObj('2 weeks', '1209600'),
];
