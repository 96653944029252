import React from 'react';
import {makeStyles, createStyles} from '@material-ui/styles';

import {Definition} from 'components/Widgets';
import {Male, Female, Other} from 'components/Icons/InfoIcons';

import {black50} from '../../../../../../consts/brand.integration';

const capitalize = (str: string) => {
  const split = str.split(' ');
  return split.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const pluralizer = (number: number, singularEnding: string, pluralEnding: string) =>
  `${number} ${number === 1 ? singularEnding : pluralEnding}`;

const iconForGender = (gender: 'male' | 'female' | 'other' | string) => {
  switch (gender) {
    case 'male':
      return <Male size={40} color="#DDDDDD" />;
    case 'female':
      return <Female size={40} color="#DDDDDD" />;
    default:
      return <Other size={40} color="#DDDDDD" />;
  }
};

const getAge = (date: string) => {
  const diff = Date.now() - new Date(date).getTime();
  const age = new Date(diff);
  return age.getUTCFullYear() - 1970;
};

interface Props {
  list: Record<string, string>[];
}

const Children: React.FC<Props> = ({list}) => {
  const classes = useStyles();

  if (!list) return null;

  return (
    <div className={classes.children}>
      <Definition
        title="Children"
        definition={
          <div>
            {list.map(child => {
              const age = getAge(child.birthday);
              return (
                <div key={child.id} className={classes.child}>
                  {iconForGender(child.gender)}
                  <div className={classes.text}>
                    <div className={classes.gender}>{capitalize(child.gender)}</div>
                    <div className={classes.age}>
                      {age < 0 ? 'Due soon' : pluralizer(age, 'year old', 'years old')}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    children: {
      marginTop: '50px',
    },
    child: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px',
      marginLeft: '-10px',
    },
    text: {
      marginLeft: '10px',
    },
    gender: {
      fontSize: '15px',
      fontWeight: 600,
    },
    age: {
      fontSize: '15px',
      color: black50,
    },
  })
);

export default Children;
