import React from 'react';
import {ListSubheader, MenuItem, Select, withStyles} from '@material-ui/core';
import {useQuery} from '@apollo/client';

import {theme} from '../../../consts/theme';
import {useStyles} from './styles';
import {ALL_REGIONS, ALL_SUPPORTED_REGIONS} from '../../../components/Region/SelectRegion';
import {IFilters} from '../StateFilter/types';
import {CountriesQuery} from '../../../scenes/Advertiser/AdvertiserCreate/graphqlQuery';
import {ICountry} from './types';

const SelectMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  },
})(MenuItem);

const RegionsFilter: React.FC<IFilters> = ({
  id = '',
  ariaLabelledby = '',
  filters,
  showExtended,
  handleChange,
}) => {
  const classes = useStyles();
  const countriesResult = useQuery(CountriesQuery);

  return (
    <>
      <Select
        value={filters.region ?? ALL_SUPPORTED_REGIONS}
        className={classes.select}
        style={{width: '173px'}}
        inputProps={{id, 'aria-labelledby': ariaLabelledby}}
        onChange={handleChange}
      >
        {showExtended && <SelectMenuItem value={ALL_REGIONS}>All regions</SelectMenuItem>}
        <SelectMenuItem value={ALL_SUPPORTED_REGIONS}>All supported regions</SelectMenuItem>
        {showExtended
          ? [
              // eslint-disable-next-line react/jsx-key
              <ListSubheader style={{color: '#000'}}>
                <b>Supported</b>
              </ListSubheader>,
              (countriesResult?.data?.countries ?? [])
                .filter((country: ICountry) => country.supported)
                .map((country: ICountry) => (
                  <SelectMenuItem key={country.id} value={country.id}>
                    {country.name}
                  </SelectMenuItem>
                )),
              // eslint-disable-next-line react/jsx-key
              <ListSubheader style={{color: '#000'}}>
                <b>Unsupported</b>
              </ListSubheader>,
              (countriesResult?.data?.countries ?? [])
                .filter((country: ICountry) => !country.supported)
                .map((country: ICountry) => (
                  <SelectMenuItem key={country.id} value={country.id}>
                    {country.name}
                  </SelectMenuItem>
                )),
            ]
          : (countriesResult?.data?.countries ?? []).map((country: ICountry) => (
              <SelectMenuItem key={country.id} value={country.id}>
                {country.name}
              </SelectMenuItem>
            ))}
      </Select>
    </>
  );
};

export default RegionsFilter;
