export const CURRENCY_SORTING = [
  {
    currency: 'gbp',
    symbol: '£',
  },
  {
    currency: 'usd',
    symbol: '$',
  },
  {
    currency: 'eur',
    symbol: '€',
  },
  {
    currency: 'sek',
    symbol: 'kr',
  },
];
