import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles({
  sortIcon: {
    marginLeft: '5px',
  },
  checkedSortIcon: {
    transform: 'rotate(-180deg) scale(-1, 1)',
  },
  sortCaption: {
    height: 36,
    background: 'transparent',
    border: 'none !important',
    cursor: 'pointer',
    marginLeft: 'auto',
    padding: '12px 0',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  groupRoot: {
    margin: '0 24px 24px 0',
  },
  filterButton: {
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  extendedFiltersGridItem: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  campaignsFoundBlock: {
    display: 'flex',
    marginBottom: 24,
  },
  message: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0em',
    fontStyle: 'normal',
  },
});
