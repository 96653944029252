import React from 'react';
import {useMutation, useQuery} from '@apollo/client';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {Grid, Typography} from '@material-ui/core';

import {useStyles} from './styles';
import {togglesData, AntSwitch} from './constants';
import {AdvertiserConfigMutation, AdvertiserQuery, AdvertiserConfigQuery} from './graphqlQuery';
import AdvertiserTabs from '../AdvertiserTabs';
import {ENTER_KEY_CODE} from '../../../consts/variables';

interface Props {
  match: {
    params: {
      advertiser: string;
    };
  };
}

const AdvertiserAccessSettings: React.FC<Props> = ({match: {params}}) => {
  const classes = useStyles();
  const [updateAdvertiserConfig] = useMutation(AdvertiserConfigMutation);
  const {data} = useQuery(AdvertiserQuery, {
    variables: {domain: params.advertiser},
  });
  const advertiserConfig = useQuery(AdvertiserConfigQuery, {
    skip: !data,
    variables: {advertiserId: data?.advertiser.id},
  });

  const getToggleValue = (name: string) => {
    if (advertiserConfig?.data?.advertiserConfig) {
      return advertiserConfig.data.advertiserConfig[name];
    }
    return false;
  };

  const getToggleStatusDisabled = (name: string) => {
    return name === 'benchmarks' && !getToggleValue('engagementRate');
  };

  const setToggleValue = (name: string, value: boolean) => {
    if (advertiserConfig.data?.advertiserConfig) {
      const updatedData = advertiserConfig.data.advertiserConfig;
      updatedData[name] = value;
      if (name === 'engagementRate' && !value) {
        updatedData['benchmarks'] = false;
      }
      updateAdvertiserConfig({variables: updatedData, refetchQueries: ['AdvertiserConfigQuery']});
    } else {
      const updatedData: {[index: string]: string | boolean} = {
        advertiserId: data.advertiser.id,
        impressions: false,
        engagementRate: false,
        benchmarks: name === 'engagementRate' && value,
        campaignType: false,
        budget: false,
        viewRate: false,
        brandCampaignsPage: false,
        dashboardPage: false,
      };
      updatedData[name] = value;
      updateAdvertiserConfig({
        variables: {...updatedData},
        refetchQueries: ['AdvertiserConfigQuery'],
      });
    }
  };

  const getToggleTextStyle = (val: boolean) =>
    val ? classes.toggleHintWrapperActive : classes.toggleHintWrapperInactive;

  const getToggles = togglesData.map((toggle, index) => {
    return toggle.name ? (
      <Grid
        key={`id${toggle.name}`}
        item
        xs={12}
        sm={toggle.sm}
        className={
          toggle.name === 'benchmarks' && !getToggleValue('engagementRate')
            ? classes.toggleHintWrapperDisabled
            : getToggleTextStyle(getToggleValue(toggle.name))
        }
        style={toggle.name == 'hidden' ? {visibility: 'hidden'} : {}}
      >
        <AntSwitch
          disabled={getToggleStatusDisabled(toggle.name)}
          checked={getToggleValue(toggle.name)}
          name={`checked${toggle.name}`}
          id={`switch${toggle.name}`}
          inputProps={{role: 'switch', 'aria-labelledby': `label${toggle.name}`}}
          onKeyDown={event => {
            if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
              event.preventDefault();
              setToggleValue(toggle.name, !getToggleValue(toggle.name));
            }
          }}
          onChange={() => setToggleValue(toggle.name, !getToggleValue(toggle.name))}
        />
        <label
          id={`label${toggle.name}`}
          htmlFor={`switch${toggle.name}`}
          className={classes.toggleText}
        >
          {toggle.text}
        </label>
      </Grid>
    ) : (
      <Grid key={index} item xs={12} sm={9} />
    );
  });

  return (
    <div>
      <div className={classes.nav}>
        <AdvertiserTabs params={params} />
      </div>
      <div className={classes.root}>
        <Typography variant="h2">Default access settings</Typography>
        <div className={classes.hintWrapper}>
          <div>
            <InfoIcon />
          </div>
          <div className={classes.infoText}>
            Individual settings can be set up per each campaign in the Dashboard section
          </div>
        </div>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} className={classes.columnName}>
              <Typography variant="h3">Metrics</Typography>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.columnName}>
              <Typography variant="h3">Pages</Typography>
            </Grid>
            {getToggles}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserAccessSettings;
