import React, {Fragment} from 'react';
import {gql, useQuery} from '@apollo/client';
import {NavLink} from 'react-router-dom';

import {useStyles} from './style';
import {LoaderDots} from '../../../../../components/Widgets';

const InsightsRoute = (): JSX.Element => {
  const {loading, data} = useQuery(InsightsCountQuery);
  const styles = useStyles();

  if (loading || !data) {
    return <LoaderDots />;
  }

  return (
    <NavLink className={styles.link} activeClassName={styles.active} to={'/insights'}>
      Insights
      {!loading && data.insights.count ? (
        <Fragment>
          <span className={styles.ellipse} />
          <span className={styles.counter}> {data.insights.count}</span>
        </Fragment>
      ) : null}
    </NavLink>
  );
};

const InsightsCountQuery = gql`
  query InsightsCountQuery {
    insights(processed: false) {
      count
    }
  }
`;

export default InsightsRoute;
