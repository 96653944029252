import {Grid} from '@material-ui/core';
import React from 'react';

import UserCard from './UserCard';

interface Props {
  historyItem: HistoryInterface;
}

const CardSimple: React.FC<Props> = ({historyItem}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
    </Grid>
  );
};

export default CardSimple;
