import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {useRouteMatch} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

import {DocumentTitle, LoaderDots} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';
import App404 from 'scenes/App404/App404';

import {topNavHeight} from '../../../consts/brand.integration';

const CampaignAdvertisers: React.FC = ({children}) => {
  const classes = useStyles();
  const {params} = useRouteMatch();
  const {loading, data} = useQuery(CampaignAdvertisersQuery, {
    variables: {
      advertiserDomain: params?.advertiser,
    },
  });

  const {advertiser} = data;

  if (loading) {
    return (
      <div className={classes.emptyWrapper}>
        <LoaderDots />
      </div>
    );
  }

  if (!advertiser) {
    return (
      <div className={classes.emptyWrapper}>
        <App404 />
      </div>
    );
  }

  return (
    <div>
      <DocumentTitle title={getPageTitle(advertiser.name)} />
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  emptyWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `calc(100vh - ${topNavHeight})`,
  },
});

const CampaignAdvertisersQuery = gql`
  query CampaignAdvertisersQuery($advertiserDomain: String!) {
    user: currentUser {
      id
      roleName
      email
      profilePicture
    }
    advertiser(domain: $advertiserDomain) {
      id
      name
    }
  }
`;

export default CampaignAdvertisers;
