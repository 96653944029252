import {gql} from '@apollo/client';

export const CampaignShippingQuery = gql`
  query CampaignShippingQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      shippingRequired
      archived
      state
      isFullyReserved
    }
  }
`;

const CampaignShippingOfferFragment = gql`
  fragment CampaignShippingOfferFragment on Offer {
    id
    addressMissing
    inTransit
    isClaimable
    isSelected
    state
    accepted
    rejected
    reach
    trackingCode
    campaign {
      id
    }
    deliverables {
      id
      postId
      created
      delivered
      deliveredAt
      gigId
      gig {
        id
      }
      post {
        id
        postType
      }
    }
    influencer {
      id
      email
      fullName
      profilePicture
      address {
        id
        address1
        address2
        name
        city
        postalCode
        state
        phonenumber
      }
    }
  }
`;

export const CampaignShippingParticipantsQuery = gql`
  query CampaignShippingParticipantsQuery($campaignId: UUIDString!, $state: String) {
    offers: offersForCampaign(id: $campaignId, state: $state) {
      edges {
        node {
          id
          ...CampaignShippingOfferFragment
        }
      }
    }
  }
  ${CampaignShippingOfferFragment}
`;

export const MarkDispatchedMutation = gql`
  mutation markDispatched($id: UUIDString!, $trackingCode: String) {
    markDispatched(id: $id, trackingCode: $trackingCode) {
      ok
      offer {
        id
        trackingCode
        inTransit
      }
    }
  }
`;

export const MarkNotDispatchedMutation = gql`
  mutation MarkNotDispatched($id: UUIDString!) {
    markNotDispatched(id: $id) {
      ok
      offer {
        id
        trackingCode
        inTransit
      }
    }
  }
`;
