import React, {useRef} from 'react';
import {gql, useMutation} from '@apollo/client';
import {
  DialogContentText,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {Modal} from '../../../../components/Widgets';
import {ModalElement} from '../../../../components/Widgets/Modal';

interface Props {
  offerId: string;
  open: boolean;
  onClose: () => void;
}

const MarkAsPaid: React.FC<Props> = ({offerId, open, onClose}) => {
  const [markOfferAsPaid] = useMutation(MarkAsPaidMutation, {
    refetchQueries: ['InfluencerOfferQuery'],
  });
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      reference: '',
    },
    validationSchema: yup.object({
      reference: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    markOfferAsPaid({
      variables: {
        offerId,
        reference: formik.values.reference,
      },
      onError: () => {
        /* ignore as this mutation is flagged as experimental and can respond with error when successful */
      },
    });
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="mark-offer-as-paid"
      title="Mark Offer As Paid"
      open={open}
      submitTitle="Submit"
      showCancel
      showClose
      onSubmit={formik.submitForm}
      onClose={handleCancel}
    >
      <DialogContentText>
        <Grid container direction="column" spacing={4}>
          <Grid item xs>
            <Typography variant="body2">
              In order to manually mark this offer as paid, please provide a reason.
            </Typography>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <InputLabel htmlFor="reference">Reason</InputLabel>
              <Input
                id="reference"
                name="reference"
                type="text"
                value={formik.values.reference}
                aria-describedby="reference"
                error={formik.touched.reference}
                required
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContentText>
    </Modal>
  );
};

const MarkAsPaidMutation = gql`
  mutation MarkAsPaid($offerId: UUIDString!, $reference: String!) {
    markAsPaid(offerId: $offerId, reference: $reference) {
      ok
      offer {
        id
      }
    }
  }
`;

export default MarkAsPaid;
