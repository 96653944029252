import PropTypes from 'prop-types';
import React from 'react';

import {TakumiEyeIcon, MaterialDesignIcon} from 'components/Icons';

import styles from './style.css';

const Badge = ({type}) => {
  let badge = null;
  switch (type) {
    case 'takumi':
      badge = <TakumiEyeIcon />;
      break;
    case 'verified':
      badge = <MaterialDesignIcon className={styles.verified} icon="check_circle" />;
      break;
    case 'disabled':
      badge = <MaterialDesignIcon className={styles.disabled} icon="block" />;
      break;
    default:
      return <div />;
  }
  return <div className={styles.root}>{badge}</div>;
};

Badge.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Badge;
