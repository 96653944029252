import React from 'react';
import {useQuery, gql} from '@apollo/client';
import {
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {withStyles} from '@material-ui/styles';

import {LoaderDots} from '../../../../../../components/Widgets';

type Funds = {
  currency: string;
  formattedValue: string;
};

const PrimaryCell = withStyles(() =>
  createStyles({
    root: {
      width: '16em',
    },
  })
)(TableCell);

const QueuedFunds: React.FC = () => {
  const {loading, data, error} = useQuery(QueuedFundsQuery);

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return (
      <div style={{display: 'inline-flex'}}>
        <span style={{marginRight: '5px'}}>
          <ErrorIcon color="error" />
        </span>
        <Typography variant="body2">Error loading queued funds</Typography>
      </div>
    );
  }

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="Queued up payments">
        <TableBody>
          {data.queuedFunds.map((funds: Funds, i: number) => (
            <TableRow key={`queuedFunds-${i}`}>
              <PrimaryCell key={`queuedFunds-currency-${i}`}>
                <Typography variant="h5">{funds.currency}</Typography>
              </PrimaryCell>
              <TableCell key={`queuedFunds-value-${i}`} align="right">
                <Typography variant="body2">{funds.formattedValue}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const QueuedFundsQuery = gql`
  query QueuedFundsQuery {
    queuedFunds {
      value
      formattedValue
      symbol
      currency
    }
  }
`;

export default QueuedFunds;
