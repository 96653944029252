import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {
  FormattedDate,
  InstagramLink,
  LoaderDots,
  TableZebraRow,
  TikTokLink,
} from '../../../../components/Widgets';
import {SHORT_DATE_TIME_FORMAT} from '../../../../consts/variables';
import {socialIcons} from '../../../../consts/icons';
import {capitalize} from '../../../../consts/utilities';

interface Props {
  posts: Maybe<readonly Maybe<Post>[]> | undefined;
  loading?: boolean;
}

const CampaignInsightsTable: React.FC<Props> = ({posts, loading}) => {
  const classes = useStyles();

  const gigs: Maybe<Gig>[] = posts?.flatMap(post => post?.gigs || []) || [];

  const renderGigRows = (gigs: Maybe<Gig>[]) =>
    gigs.map(
      (gig: Maybe<Gig>, index) =>
        gig && (
          <TableZebraRow key={index}>
            <TableCell align="left" component="th" scope="row" className={classes.influencerCell}>
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item>
                  <Avatar src={gig.influencer?.profilePicture || undefined} />
                </Grid>
                <Grid item>
                  <a href={`/influencers/${gig.influencer?.id}`} className={classes.influencerName}>
                    {gig.influencer?.fullName}
                  </a>
                  <span className={classes.influencerHandle}>
                    <>
                      {gig.influencer?.instagramAccount?.username && (
                        <InstagramLink username={gig.influencer.instagramAccount.username} />
                      )}
                      {gig.influencer?.tiktokAccount?.username && (
                        <TikTokLink username={gig.influencer.tiktokAccount.username} />
                      )}
                    </>
                  </span>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="center">
              <div className={classes.iconContainer}>
                <Icon
                  icon={socialIcons[gig.post?.postType || ''] || 'ic:baseline-photo'}
                  width={24}
                  className={classes.iconContainer}
                />
              </div>
              <a href={`/gigs/${gig.id}`}>{gig.post?.postType || ''}</a>
            </TableCell>
            <TableCell align="center">
              <FormattedDate date={gig.insight?.created} format={SHORT_DATE_TIME_FORMAT} />
            </TableCell>
            <TableCell align="center" aria-label="Gig state" data-testid={`state-${gig.id}`}>
              {gig.insight ? (
                <a href={`/insight/${gig.insight.id}`}>{capitalize(gig.insight.state)}</a>
              ) : (
                'Not yet submitted'
              )}
            </TableCell>
          </TableZebraRow>
        )
    );

  return (
    <Card className={classes.root}>
      <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Campaign Insights">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">
                  Influencer
                </TableCell>
                <TableCell className={classes.tableHeader} align="center">
                  Post type
                </TableCell>
                <TableCell className={classes.tableHeader} align="center">
                  Insights Submitted
                </TableCell>
                <TableCell className={classes.tableHeader} align="center">
                  Insights status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={6} rowSpan={6} className={classes.isLoading}>
                    <LoaderDots />
                  </TableCell>
                </TableRow>
              )}

              {gigs && gigs.length > 0 ? (
                renderGigRows(gigs)
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No results have been found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'table',
    tableLayout: 'fixed',
    overflowX: 'auto',
    marginTop: '20px',
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: 700,
  },
  isLoading: {
    margin: '0 auto',
  },
  influencerCell: {
    width: '280px',
  },
  influencerName: {
    fontWeight: 700,
  },
  influencerHandle: {
    '& svg': {
      marginRight: '2px',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
});

export default CampaignInsightsTable;
