import React from 'react';
import {DateRangePicker, Range} from 'react-date-range';

import {predefinedRanges} from './consts';
import {useStyles} from './styles';

require('!!style-loader!css-loader!react-date-range/dist/styles.css');
require('!!style-loader!css-loader!react-date-range/dist/theme/default.css');

interface Props {
  range: Range;
  onRangeChange: (range: Range) => void;
}

const RangePicker: React.FC<Props> = ({range, onRangeChange}) => {
  const classes = useStyles();

  const ranges = [{...range, key: 'selected'}];

  return (
    <DateRangePicker
      ariaLabels={{
        dateInput: {
          selected: {
            startDate: 'start date',
            endDate: 'end date',
          },
        },
        prevButton: 'previous month',
        nextButton: 'next month',
      }}
      startDatePlaceholder="Start Date"
      endDatePlaceholder="End Date"
      ranges={ranges}
      staticRanges={predefinedRanges}
      inputRanges={[]}
      editableDateInputs={true}
      months={2}
      direction="horizontal"
      showMonthAndYearPickers={false}
      rangeColors={['black']}
      className={classes.dateRangePickerWrapper}
      classNames={classes}
      onChange={ranges => onRangeChange(ranges.selected)}
    />
  );
};

export default RangePicker;
