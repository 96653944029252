import {debounce} from 'lodash';
import classNames from 'classnames';
import React, {RefObject, useState} from 'react';
import Select, {
  MenuProps,
  Theme,
  StylesConfig,
  SelectComponentsConfig,
  components,
} from 'react-select';
import {KeyboardArrowDown} from '@material-ui/icons';
import {ClickAwayListener} from '@material-ui/core';

import {useStyles} from './styles';
import {AdvertiserIndustryEntity, TreeViewEntity} from '../../types';
import EntitiesTree from './EntitiesTree';
import SelectedIndustryChips from './SelectedIndustryChips';
import {ENTER_KEY_CODE, ESC_KEY_CODE} from '../../../../../consts/variables';

interface Props {
  id: string;
  labelId?: string;
  selectedIndustries: Array<AdvertiserIndustryEntity> | null;
  industries: ReadonlyArray<TreeViewEntity>;
  isReadOnly?: boolean;
  isAutoSizableMenu?: boolean;
  isSelectedItemVisible?: boolean;
  onChange: (text: string) => void;
  styles?: StylesConfig<any, boolean>;
  additionalComponents?: SelectComponentsConfig<StylesConfig<any, boolean>, boolean>;
  placeholder?: string;
}

const Control = (props: any) => {
  const {selectProps} = props as MenuProps<any, boolean> & {
    innerRef: RefObject<HTMLDivElement>;
  };
  const {setIsMenuOpened} = selectProps.menuProps;

  return (
    <button
      id="industryControlId"
      type="button"
      style={{border: 'none', width: '100%'}}
      onClick={() => setIsMenuOpened(true)}
      onKeyDown={event => {
        if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
          setIsMenuOpened(true);
          setTimeout(function () {
            document.getElementById('mainMenuElementId')?.focus();
          }, 100);
        }
      }}
    >
      <components.Control {...props} />
    </button>
  );
};

export default function IndustrySelector({
  id,
  labelId,
  industries,
  selectedIndustries,
  isReadOnly,
  onChange,
  styles,
  additionalComponents,
  placeholder,
  isAutoSizableMenu,
  isSelectedItemVisible,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const [searchByStr, setSearchStr] = useState<string>('');
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const themeSelect = {
    borderRadius: 4,
    spacing: {
      baseUnit: 3,
      controlHeight: 48,
      menuGutter: 34 * 2,
    },
  } as Theme;

  const menuClassNames = classNames({
    [classes.menu]: true,
    [classes.autoSizableMenu]: isAutoSizableMenu,
  });

  const selectedEntitiesIds: Array<string> = (selectedIndustries ?? []).map(item => item.id);
  const onCheckedChanged = (entity: TreeViewEntity) => {
    onChange(entity.id);
    setIsMenuOpened(false);
  };

  const onChangeInput = debounce((text: string) => {
    setSearchStr(text);
  }, 500);
  return (
    <div className={`${styles?.container ?? ''}`}>
      <Select
        title="industry select"
        aria-label="industry select"
        aria-labelledby={labelId}
        inputId={id}
        options={[]}
        value={null}
        isSearchable
        isMulti={false}
        isDisabled={isReadOnly}
        placeholder={placeholder ? placeholder : 'Select industry'}
        menuPortalTarget={document.querySelector('body')}
        menuProps={{
          className: menuClassNames,
          searchByStr,
          selectedEntitiesIds,
          industries,
          onSelect: onCheckedChanged,
          setIsMenuOpened,
        }}
        theme={themeSelect}
        menuIsOpen={isMenuOpened}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <KeyboardArrowDown style={{marginRight: '16px'}} />,
          Menu: TreeViewMenu,
          Control,
          ...additionalComponents,
        }}
        styles={styles}
        onInputChange={(text: string, {action}) => {
          if (action === 'input-change') {
            setIsMenuOpened(true);
          }
          onChangeInput(text);
        }}
        onKeyDown={event => {
          if (event.keyCode === ESC_KEY_CODE) {
            event.preventDefault();
            setIsMenuOpened(false);
          }
        }}
      />
      {isSelectedItemVisible && (
        <div className={classes.chips}>
          <SelectedIndustryChips
            selectedEntitiesIds={selectedEntitiesIds}
            industries={industries}
            isReadOnly={isReadOnly}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}

function TreeViewMenu(props: MenuProps<any, boolean>) {
  const {innerRef, innerProps, selectProps} = props as MenuProps<any, boolean> & {
    innerRef: RefObject<HTMLDivElement>;
  };
  const {className, selectedEntitiesIds, industries, searchByStr, onSelect, setIsMenuOpened} =
    selectProps.menuProps;

  return (
    <ClickAwayListener onClickAway={() => setIsMenuOpened(false)}>
      <div
        {...innerProps}
        ref={innerRef}
        className={className}
        id="mainMenuElementId"
        role="listbox"
        tabIndex={0}
      >
        <EntitiesTree
          setIsMenuOpened={setIsMenuOpened}
          checkedIds={selectedEntitiesIds}
          searchString={searchByStr}
          entities={industries}
          onCheckedChanged={onSelect}
        />
      </div>
    </ClickAwayListener>
  );
}
