import React from 'react';
import {Select, MenuItem, makeStyles} from '@material-ui/core';

import {theme} from 'consts/theme';
import {black} from 'consts/brand.integration';

export const DEFAULT_CURRENCY_FILTER_VALUE = 'ALL';

type Props = {
  data: {
    unapprovedPayments: Array<Record<string, any>>;
  };
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
};

const CurrencyFilter: React.FC<Props> = ({data, value, onChange}) => {
  const classes = useStyles();

  const currencies = Array.from(
    new Set(data.unapprovedPayments.map(({reward}) => reward.currency))
  );

  return (
    <Select className={classes.select} value={value} onChange={e => onChange(e.target.value)}>
      <MenuItem value="ALL">All Currencies</MenuItem>
      {currencies.map(currency => (
        <MenuItem key={currency} value={currency}>
          {currency}
        </MenuItem>
      ))}
    </Select>
  );
};

/**
 * NOTE: These styles are duplicated in:
 * src/components/Widgets/CampaignTypeFilter/styles.ts
 * src/components/Widgets/RegionsFilter/styles.ts
 *
 * We may want to consolidate these into single export.
 */
const useStyles = makeStyles({
  select: {
    '&.MuiInput-root': {
      minWidth: 200,
      fontSize: 13,
      height: 32,
      minHeight: 32,
      border: `1px solid ${theme.palette['text-01']}`,
      borderRadius: 0,
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        outline: '-webkit-focus-ring-color auto 1px',
      },
      '& .MuiSelect-root': {
        paddingLeft: '10px',
      },
      '& .MuiSelect-icon': {
        marginRight: '10px',
        marginTop: '2px',
        fontSize: '20px',
        color: black,
      },
    },
    '&.MuiInput-underline': {
      '&:before': {
        border: '0',
      },
      '&:hover:before': {
        border: '0',
      },
    },
  },
});

export default CurrencyFilter;
