import React from 'react';
import PropTypes from 'prop-types';

import {numberWithCommas} from 'consts/utilities';

import styles from './style.css';

const TargetingEstimate = ({targetingEstimate, numberFormatter = numberWithCommas}) => {
  if (!targetingEstimate) {
    return null;
  }

  const {verified, eligible, total} = targetingEstimate;

  return (
    <div className={styles.breakDownBar}>
      <div className={styles.verified} style={{flex: verified}}>
        <div className={styles.number}>{numberFormatter(verified)}</div>
        <div className={styles.description}>Verified</div>
      </div>
      <div className={styles.eligible} style={{flex: eligible - verified}}>
        <div className={styles.number}>{numberFormatter(eligible)}</div>
        <div className={styles.description}>Eligible</div>
      </div>
      <div className={styles.total} style={{flex: total - eligible}}>
        <div className={styles.number}>{numberFormatter(total)}</div>
        <div className={styles.description}>Total in market</div>
      </div>
      <div className={styles.bar}>
        <div className={styles.verified} style={{flex: verified}} />
        <div className={styles.eligible} style={{flex: eligible - verified}} />
        <div className={styles.total} style={{flex: total - eligible}} />
      </div>
    </div>
  );
};

TargetingEstimate.propTypes = {
  targetingEstimate: PropTypes.object,
  numberFormatter: PropTypes.func,
};

export default TargetingEstimate;
