import {makeStyles} from '@material-ui/styles';

import {
  black,
  white,
  roman,
  purple,
  transparentBlack,
} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles({
  popoverButton: {
    height: 32,
    minWidth: 190,
    border: `1px solid ${black}`,
    borderRadius: 0,
    padding: '12px 16px',
    fontSize: 13,
    fontWeight: roman,
    '&:hover': {
      backgroundColor: white,
    },
  },
  open: {
    border: `1px solid ${purple}`,
  },
  root: {
    backgroundColor: transparentBlack,
  },
});
