import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import * as qs from 'query-string';

import {FilterGroup, FilterItem, Checkbox} from 'components/Widgets';

const GigSubmissionsFilter = ({location, replace}) => {
  const params = qs.parse(location.search);
  return (
    <div>
      <FilterGroup>
        <Link
          to={() => ({
            ...location,
            search: qs.stringify({...params, reported: undefined, unreviewed: true}),
          })}
        >
          <FilterItem checked={params.unreviewed === 'true'}>Unreviewed</FilterItem>
        </Link>
        <Link
          to={() => ({
            ...location,
            search: qs.stringify({
              ...params,
              unreviewed: undefined,
              reported: undefined,
            }),
          })}
        >
          <FilterItem checked={Object.keys(params).filter(k => k !== 'mine').length === 0}>
            Submitted
          </FilterItem>
        </Link>
        <Link
          to={() => ({
            ...location,
            search: qs.stringify({...params, unreviewed: undefined, reported: true}),
          })}
        >
          <FilterItem checked={params.reported === 'true'}>Reported</FilterItem>
        </Link>
      </FilterGroup>

      <Checkbox
        label="Only show gigs in my campaigns"
        checked={params.mine === 'true'}
        onChange={event =>
          replace({
            ...location,
            search: qs.stringify({...params, mine: event.target.checked ? true : undefined}),
          })
        }
      />
    </div>
  );
};

GigSubmissionsFilter.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  replace: PropTypes.func.isRequired,
};

export default GigSubmissionsFilter;
