import React, {ReactNode} from 'react';
import {createStyles, makeStyles} from '@material-ui/core';
import classNames from 'classnames';

import {black50, purple, white} from '../../../consts/brand.integration';
import {purpleLight} from '../../../consts/brand';

interface Props {
  children: ReactNode | ReactNode[];
  small: boolean;
  checked: boolean;
  onChange: (value: any) => void;
}

const FilterItem: React.FC<Props> = ({
  children,
  checked,
  small,
  onChange = () => {
    /* do nothing */
  },
}) => {
  const classes = useStyles();
  return (
    <button
      className={classNames(classes.item, checked && classes.itemSelected, small && classes.small)}
      onClick={() => !checked && onChange(checked)}
    >
      {children}
    </button>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      display: 'block',
      padding: '5px 20px',
      margin: '0px 5px',
      lineHeight: 1,
      color: black50,
      fontSize: '14px',
      transition: 'all 0.2s',
      textTransform: 'capitalize',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: purpleLight,
        color: purple,
      },
    },
    itemSelected: {
      color: white,
      backgroundColor: purple,
      '&:hover': {
        cursor: 'default',
        backgroundColor: purpleLight,
        color: purple,
      },
    },
    small: {
      padding: '4px 12px',
      margin: '0px 2px',
    },
  })
);

export default FilterItem;
