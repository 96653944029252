import React from 'react';
import classNames from 'classnames';

import uniqueId from '../../../services/uniqueId';
import {Label} from '../';
import styles from './style.css';

interface Props {
  id: string;
  label: string;
  cols: number;
  rows: number;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  onFocus: () => void;
  open: boolean;
  placeholder: string;
  required: boolean;
  value: string;
  className: string;
}

const TextArea: React.FC<Props> = ({
  id = uniqueId(),
  name,
  label,
  open,
  cols,
  rows,
  required,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  className,
}) => (
  <div className={styles.root}>
    {label && <Label id={id} label={label} />}
    <div className={styles.container}>
      <textarea
        id={id}
        cols={cols}
        rows={rows}
        name={name}
        autoFocus={open}
        value={value}
        className={classNames(styles.textarea, className)}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  </div>
);

export default TextArea;
