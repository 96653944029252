import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {MaterialDesignIcon} from 'components/Icons';
import {Tooltip} from 'components/Widgets';

import styles from './style.css';

const ReviewGigButton = ({reviewed, reviewGig, visible}) => {
  const handleClick = e => {
    e.preventDefault();
    reviewGig();
  };

  return (
    <div className={classNames([styles.container, {[styles.reviewed]: visible || reviewed}])}>
      <div
        className={reviewed ? styles.buttonActive : styles.button}
        onClick={!reviewed ? handleClick : null}
      >
        {reviewed && (
          <Tooltip overlay={<span>{`Reviewed by ${reviewed.fullName}`}</span>}>
            <MaterialDesignIcon
              icon={'check_circle'}
              className={reviewed ? styles.iconActive : styles.icon}
            />
          </Tooltip>
        )}
        {!reviewed && (
          <MaterialDesignIcon
            icon={'check_circle'}
            className={reviewed ? styles.iconActive : styles.icon}
          />
        )}
      </div>
    </div>
  );
};

ReviewGigButton.propTypes = {
  reviewed: PropTypes.shape({
    id: PropTypes.string,
    full_name: PropTypes.string,
  }),
  reviewGig: PropTypes.func,
  visible: PropTypes.bool,
};

export default ReviewGigButton;
