import React from 'react';
import classNames from 'classnames';
import {Avatar, createStyles, makeStyles} from '@material-ui/core';

import defaultAvatar from 'scenes/App/components/SideBarMenu/img/defaultAvatar.png';

interface Props {
  src?: string;
  alt?: string;
  className?: string;
  size?: number;
  square?: boolean;
  borderRadius?: number;
}

const ProfilePicture: React.FC<Props> = ({
  src,
  alt = '',
  className,
  size = 50,
  square,
  borderRadius = '50%',
}) => {
  const classes = useStyles({size});

  const style = classNames({
    [classes.root]: true,
    [className]: Boolean(className),
    [classes.square]: square,
  });

  if (src && src.includes('imgix')) {
    src += '?fm=jpeg&q=50&dpr=1&h=100&w=100&fit=clip';
  }

  return (
    <div className={style}>
      <Avatar
        alt={alt}
        src={src ?? defaultAvatar}
        style={{width: size, height: size, borderRadius}}
      />
    </div>
  );
};

const useStyles = makeStyles<Theme, {size: Props['size']}>(() =>
  createStyles({
    root: {
      borderRadius: 50,
      boxShadow: '0px 1px 5px rgba(0,0,0,0.1)',
      width: props => props.size,
    },
    square: {
      borderRadius: 2,
    },
  })
);

export default ProfilePicture;
