import React, {useEffect, useState} from 'react';
import {Button, Fade, Snackbar, SnackbarContent} from '@material-ui/core';
import {useReactiveVar} from '@apollo/client';
import {formatDistanceToNowStrict, isPast} from 'date-fns';

import {
  endUserImpersonation,
  getImpersonationExpiryDate,
  isImpersonating,
} from 'services/impersonation';
import useCurrentUser from 'hooks/useCurrentUser';

export const getFormattedExpiry = () => {
  const expiry = getImpersonationExpiryDate();

  if (!expiry) return 0;
  if (isPast(new Date(expiry))) return '0 seconds';
  return formatDistanceToNowStrict(new Date(expiry));
};

const ImpersonationBar = () => {
  const user = useCurrentUser();
  const [formattedExpiry, setFormattedExpiry] = useState(getFormattedExpiry());
  const isCurrentlyImpersonating = useReactiveVar(isImpersonating);

  useEffect(() => {
    if (!isCurrentlyImpersonating) return;

    const interval = setInterval(() => {
      const expiry = getImpersonationExpiryDate();
      if (expiry != null && isPast(new Date(expiry))) return endUserImpersonation();

      const formattedExpiry = getFormattedExpiry();
      setFormattedExpiry(formattedExpiry);
    }, 1000);

    return () => clearInterval(interval);
  }, [isCurrentlyImpersonating]);

  return (
    <Snackbar open={isCurrentlyImpersonating} TransitionComponent={Fade}>
      <SnackbarContent
        message={
          <div>
            Impersonating <strong>{user?.fullName}</strong> ends in{' '}
            <strong>{formattedExpiry}</strong>.
            <Button variant="text" color="secondary" size="small" onClick={endUserImpersonation}>
              End Now
            </Button>
          </div>
        }
      />
    </Snackbar>
  );
};

export default ImpersonationBar;
