import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  accordionRoot: {
    backgroundColor: 'transparent',
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    margin: '0 !important',
  },
  accordionSummaryRoot: {
    padding: 0,
    height: 98,
    [theme.breakpoints.only('sm')]: {
      height: 64,
    },
  },
  accordionSummaryContent: {
    cursor: 'pointer',
    backgroundColor: theme.palette['ui-07'],
    padding: '8px 8px 8px 32px',
    '&:hover': {
      backgroundColor: theme.palette['ui-06'],
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0 2px',
    },
  },
  accordionSummaryContentExpanded: {
    backgroundColor: theme.palette['ui-06'],
  },
  notificationsBadge: {
    color: theme.palette['ui-01'],
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette['brand-01'],
    position: 'fixed',
    bottom: 150,
    left: 206,
    width: 291,
    maxHeight: 500,
    overflow: 'scroll',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      bottom: 0,
    },
    [theme.breakpoints.only('sm')]: {
      top: '47px',
      left: 50,
    },
    [theme.breakpoints.only('xs')]: {
      top: '30px',
      left: 0,
    },
  },
}));
