import React from 'react';
import classNames from 'classnames';
import {Box, createStyles, Grid, makeStyles} from '@material-ui/core';

import SearchIcon from '../../../components/Icons/Search';
import {black20, black50, purple} from '../../../consts/brand';

interface Props {
  placeholder?: string;
  searchString?: string;
  hasPadding?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox: React.FC<Props> = ({placeholder, searchString, onChange, hasPadding}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid item xs="auto">
        <Box width={30} height={30}>
          <SearchIcon />
        </Box>
      </Grid>
      <Grid item xs={11}>
        <input
          autoFocus
          className={classNames(classes.input, {[classes.noPadding]: hasPadding})}
          placeholder={placeholder}
          type="search"
          value={searchString ?? ''}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
      border: 0,
      borderBottom: `2px solid ${black20}`,
      fontSize: '24px',
      padding: '2px 5px 15px 15px',
      transition: 'border-color 0.2s',
      '&::placeholder': {
        color: black50,
      },
      '&:focus': {
        outline: 0,
        borderBottomColor: purple,
      },
    },
    noPadding: {
      padding: '2px 5px 12px 56px',
    },
  })
);

export default SearchBox;
