import {Grid, makeStyles, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';

import {ApiError, LoaderDots} from 'components/Widgets';

import {useDataServiceApi} from 'hooks/useDataServiceApi';
import {InfluenscoreCampaignV1BetaResponse} from 'hooks/useDataServiceApi/types';
import useCurrentUser from 'hooks/useCurrentUser';
import InfluenscoreChart from 'scenes/Campaign/components/CampaignPerformance/InfluenscoreData/InfluenscoreChart';

const InfluenscoreData = ({campaign}: {campaign: Campaign}) => {
  const classes = useStyles();
  const [influenscoreData, setInfluenscoreData] = useState<InfluenscoreCampaignV1BetaResponse[0]>();
  const {error, loading, fetchInfluenscoreCampaignV1Beta} = useDataServiceApi();
  const user = useCurrentUser();
  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const result = await fetchInfluenscoreCampaignV1Beta({
          apiToken: user?.takumiDataApiToken ?? '',
          campaignId: campaign.id,
        });
        if (result && result.length > 0) {
          console.log(result[0]);
          setInfluenscoreData(result[0]);
        }
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, fetchInfluenscoreCampaignV1Beta, campaign?.id]);

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return <ApiError title={'InfluenScore'} classes={classes} />;
  }

  if (!influenscoreData) {
    return <ApiError title={'InfluenScore'} classes={classes} />;
  }

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        InfluenScore
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <InfluenscoreChart data={influenscoreData} />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default InfluenscoreData;
