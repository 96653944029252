import React from 'react';
import {useHistory} from 'react-router-dom';
import * as qs from 'query-string';
import {Typography} from '@material-ui/core';

import {LoginForm} from '..';
import styles from './style.css';
import {login} from '../../../../services/api';
import {IOnSubmit, Params} from './types';

const LoginFormContainer: React.FC = () => {
  const history = useHistory();
  const params: Params = qs.parse(history.location.search);

  const onSubmit = async ({email, password}: IOnSubmit) => {
    await login(email, password);
    await loginSucceeded();
  };

  const loginSucceeded = async () => {
    const redirectUrl = params?.redirect_pathname ?? '/';
    history.push(redirectUrl);
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h1">
        Welcome to TAKUMI!
      </Typography>
      <LoginForm onSubmit={onSubmit} />
    </div>
  );
};

export default LoginFormContainer;
