export enum AddOnTypes {
  ShippingRequired = 'shippingRequired',
  BrandSafety = 'brandSafety',
  BrandMatch = 'brandMatch',
  HasNda = 'hasNda',
}

export enum CampaignTypes {
  MyCampaigns = 'mine',
  Archived = 'archived',
}

export enum RewardModels {
  Assets = 'assets',
  Reach = 'reach',
  Engagement = 'engagement',
  Impressions = 'impressions',
}
