import React from 'react';
import classNames from 'classnames';
import {RouteComponentProps, useHistory, useRouteMatch} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {LoaderDots} from 'components/Widgets';

import {Form, FormRadio} from '../../../components/Widgets/Forms';
import {MARKETS, REWARD_MODELS} from '../../../consts/variables';
import {advertiserUrl, setupCampaignUrl} from '../../../consts/urls';
import {useStyles} from './styles';
import {CampaignHeader} from '../components';

interface Props {
  data: any;
  history: any;
}

const NewCampaign: React.FC<Props & RouteComponentProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const {params}: {params: {advertiser: string}} = useRouteMatch();
  const domain = params.advertiser;
  const {loading, error, data} = useQuery(AdvertiserQuery, {
    variables: {domain},
  });

  const handleEnterKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  const onSubmit = (d: any) =>
    history.push({
      pathname: `${setupCampaignUrl(advertiser)}`,
      search: `?advertiserId=${advertiser.id}`,
      state: {
        market: d.market,
        model: d.model,
      },
    });

  const onCancel = () =>
    history.push({
      pathname: `${advertiserUrl(advertiser)}`,
    });

  if (loading || error) {
    return (
      <div className={classes.root}>
        <CampaignHeader title="Create" />
        {loading && <LoaderDots />}
        {error && <Alert severity="error">{error.message}</Alert>}
      </div>
    );
  }

  const {advertiser} = data;

  return (
    <div className={classes.root}>
      <CampaignHeader title="Create" />
      <Form initialState={{market: 'uk', model: 'reach'}} onSubmit={onSubmit}>
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend}>Market</legend>
          <div className={classes.radioGroup}>
            {MARKETS.map(market => (
              <div key={market.value} className={classes.radioButtonItem}>
                <FormRadio
                  name="market"
                  value={market.value}
                  label={market.label}
                  description={market.description}
                  radioButtonContainerClassName={classes.radioButtonContainer}
                  radioButtonClassName={classes.radioButton}
                  checkedRadioButtonClassName={classes.checkedRadioButton}
                  labelClassName={classes.label}
                  descriptionClassName={classes.description}
                  onKeyDown={handleEnterKeyDown}
                />
              </div>
            ))}
          </div>
        </fieldset>
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend}>Model</legend>
          <div className={classes.radioGroup}>
            {REWARD_MODELS.map(model => (
              <div key={model.value} className={classes.radioButtonItem}>
                <FormRadio
                  name="model"
                  value={model.value}
                  label={model.label}
                  description={model.description}
                  radioButtonContainerClassName={classes.radioButtonContainer}
                  radioButtonClassName={classes.radioButton}
                  checkedRadioButtonClassName={classes.checkedRadioButton}
                  labelClassName={classes.label}
                  descriptionClassName={classes.description}
                  onKeyDown={handleEnterKeyDown}
                />
              </div>
            ))}
          </div>
        </fieldset>
        <div className={classes.formActions}>
          <Button
            className={classNames(classes.actionButton, classes.cancelBtn)}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={classNames(classes.actionButton, classes.nextBtn)}
            data-testid="newcampaign-button-submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

const AdvertiserQuery = gql`
  query NewCampaignAdvertiserQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      domain
    }
  }
`;

export default NewCampaign;
