/* eslint-disable graphql/no-deprecated-fields */
import {gql} from '@apollo/client';

import {Gig} from 'services/fragments';

import {Media} from '../../../../components/Widgets';

export const InsightFragment = gql`
  fragment InsightFields on InsightInterface {
    __typename
    id
    processed
    state
    reach
    ocrValues
    media {
      ...mediaResultFields
    }
    gig {
      id
      post {
        id
        postType
      }
      instagramContent {
        __typename
        id
        posted
        media {
          ...mediaResultFields
        }
      }
      influencer {
        id
        instagramAccount {
          id
          username
        }
        tiktokAccount {
          id
          username
        }
      }
      offer {
        id
        campaign {
          id
          name
          advertiser {
            id
            domain
          }
        }
      }
    }
  }
  ${Gig.mediaPreview}
`;

export const InsightQuery = gql`
  query InsightQuery($insightId: UUIDString) {
    insight(id: $insightId) {
      id
      __typename
      ...InsightFields

      ... on StoryInsight {
        views
        shares
        replies
        linkClicks
        profileVisits
        stickerTaps
        impressions
        follows
        backNavigations
        forwardNavigations
        nextStoryNavigations
        exitedNavigations
        websiteClicks
        emails
        swipeUps
        reach
      }
      ... on PostInsight {
        nonFollowersReach
        likes
        comments
        shares
        bookmarks
        profileVisits
        replies
        websiteClicks
        calls
        emails
        getDirections
        follows
        fromHashtagsImpressions
        fromHomeImpressions
        fromExploreImpressions
        fromLocationImpressions
        fromProfileImpressions
        fromOtherImpressions
      }
      ... on TikTokInsight {
        __typename
        reach
        reachPercentUk
        reachPercentUs
        trafficSourcePercentForYouPage
        trafficSourcePercentPersonalProfile
      }
    }
  }
  ${InsightFragment}
`;

export const ProcessStoryInsightMutation = gql`
  mutation ProcessStoryInsightMutation(
    $id: UUIDString!
    $reach: Int
    $views: Int
    $replies: Int
    $shares: Int
    $linkClicks: Int
    $profileVisits: Int
    $stickerTaps: Int
    $swipeUps: Int
    $impressions: Int
    $follows: Int
    $backNavigations: Int
    $forwardNavigations: Int
    $nextStoryNavigations: Int
    $exitedNavigations: Int
    $websiteClicks: Int
    $emails: Int
  ) {
    updateStoryInsight(
      id: $id
      processed: true
      reach: $reach
      views: $views
      shares: $shares
      replies: $replies
      linkClicks: $linkClicks
      profileVisits: $profileVisits
      stickerTaps: $stickerTaps
      swipeUps: $swipeUps
      impressions: $impressions
      follows: $follows
      backNavigations: $backNavigations
      forwardNavigations: $forwardNavigations
      nextStoryNavigations: $nextStoryNavigations
      exitedNavigations: $exitedNavigations
      websiteClicks: $websiteClicks
      emails: $emails
    ) {
      ok
      insight {
        id
        ... on StoryInsight {
          id
          state
          processed
        }
      }
    }
  }
`;

export const ProcessPostInsightMutation = gql`
  mutation ProcessPostInsightMutation(
    $id: UUIDString!
    $reach: Int
    $nonFollowersReach: Float
    $likes: Int
    $comments: Int
    $shares: Int
    $bookmarks: Int
    $profileVisits: Int
    $replies: Int
    $websiteClicks: Int
    $emails: Int
    $getDirections: Int
    $calls: Int
    $follows: Int
    $fromHashtagsImpressions: Int
    $fromHomeImpressions: Int
    $fromExploreImpressions: Int
    $fromLocationImpressions: Int
    $fromProfileImpressions: Int
    $fromOtherImpressions: Int
  ) {
    updatePostInsight(
      id: $id
      processed: true
      reach: $reach
      nonFollowersReach: $nonFollowersReach
      likes: $likes
      comments: $comments
      shares: $shares
      bookmarks: $bookmarks
      profileVisits: $profileVisits
      replies: $replies
      websiteClicks: $websiteClicks
      emails: $emails
      getDirections: $getDirections
      calls: $calls
      follows: $follows
      fromHashtagsImpressions: $fromHashtagsImpressions
      fromHomeImpressions: $fromHomeImpressions
      fromExploreImpressions: $fromExploreImpressions
      fromLocationImpressions: $fromLocationImpressions
      fromProfileImpressions: $fromProfileImpressions
      fromOtherImpressions: $fromOtherImpressions
    ) {
      ok
      insight {
        id
        ... on PostInsight {
          id
          state
          processed
        }
      }
    }
  }
`;

export const ProcessTikTokInsightMutation = gql`
  mutation ProcessTikTokInsightMutation(
    $id: UUIDString!
    $reach: Int
    $reachPercentUk: Float
    $reachPercentUs: Float
    $trafficSourcePercentForYouPage: Float
    $trafficSourcePercentPersonalProfile: Float
  ) {
    updateTikTokInsight(
      id: $id
      reach: $reach
      reachPercentUk: $reachPercentUk
      reachPercentUs: $reachPercentUs
      trafficSourcePercentForYouPage: $trafficSourcePercentForYouPage
      trafficSourcePercentPersonalProfile: $trafficSourcePercentPersonalProfile
    ) {
      ok
      insight {
        id
        ... on TikTokInsight {
          id
          state
          processed
        }
      }
    }
  }
`;

export const RequestInsightResubmissionMutation = gql`
  mutation RequestInsightResubmissionMutation($insightId: UUIDString!, $reason: String!) {
    requestInsightResubmission(id: $insightId, reason: $reason) {
      ok
      insight {
        id
        state
      }
    }
  }
`;
