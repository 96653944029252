import React from 'react';
import {createStyles, makeStyles, Typography} from '@material-ui/core';

import {black70, bold, sizeH3} from '../../../consts/brand.integration';

interface Props {
  title?: string;
}

const CampaignNoGigs: React.FC<Props> = ({title = 'No posts'}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'block',
      marginTop: '20%',
    },
    title: {
      color: black70,
      fontWeight: bold,
      fontSize: sizeH3,
      textAlign: 'center',
    },
  })
);

export default CampaignNoGigs;
