import React from 'react';
import PropTypes from 'prop-types';
import RCSlider, {Range} from 'rc-slider';

import {purple, purpleLight} from '../../../consts/brand';
import styles from './styles.css';
require('!!style-loader!css-loader!rc-slider/assets/index.css'); // eslint-disable-line

export const Slider = ({value, onChange, min, max, step, pushable, title, renderValueLabel}) => {
  const handleStyle = {
    borderColor: purple,
    background: purple,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  const trackStyle = {backgroundColor: purpleLight};

  return (
    <div className={styles.root}>
      <div className={styles.labelRow}>
        <div className={styles.title}>{title}</div>
        <div className={styles.valueLabel}>{renderValueLabel?.(value)}</div>
      </div>
      <div className={styles.rangeSlider}>
        <RCSlider
          min={min}
          max={max}
          value={value}
          defaultValue={min}
          step={step}
          trackStyle={[trackStyle]}
          handleStyle={[handleStyle, handleStyle]}
          pushable={pushable}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  renderValueLabel: PropTypes.func,
  title: PropTypes.string,
  step: PropTypes.number,
  pushable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export const RangeSlider = ({
  value,
  onChange,
  onAfterChange,
  min,
  max,
  step,
  pushable,
  title,
  renderValueLabel,
}) => {
  const handleStyle = {
    borderColor: purple,
    background: purple,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  const trackStyle = {backgroundColor: purpleLight};

  return (
    <div className={styles.root}>
      <div className={styles.labelRow}>
        <div className={styles.title}>{title}</div>
        <div className={styles.valueLabel}>{renderValueLabel?.(value)}</div>
      </div>
      <div className={styles.rangeSlider}>
        <Range
          min={min}
          max={max}
          value={value}
          defaultValue={[min, max]}
          step={step}
          trackStyle={[trackStyle]}
          handleStyle={[handleStyle, handleStyle]}
          pushable={pushable}
          onChange={onChange}
          onAfterChange={onAfterChange}
        />
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  renderValueLabel: PropTypes.func,
  title: PropTypes.string,
  step: PropTypes.number,
  pushable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export const ExponentialRangeSlider = ({
  value: externalValue,
  onChange: externalOnChange = () => {
    /* do nothing */
  },
  onAfterChange,
  base = 10,
  minExp = 3,
  maxExp = 6,
  renderValueLabel: externalRenderValueLabel,
  title,
}) => {
  const minMaxDifference = maxExp - minExp;

  const compose = (f, g) => x => f(g(x));

  const valueToExp = value => Math.log(value) / Math.log(base);
  const expToSliderValue = exp => ((exp - minExp) / minMaxDifference) * 1000;
  const inputMapper = compose(expToSliderValue, valueToExp);

  const sliderValueToExp = sliderValue => (sliderValue / 1000) * minMaxDifference + minExp;
  const expToValue = exp => Math.round(Math.pow(base, exp) / 1000) * 1000;
  const outputMapper = compose(expToValue, sliderValueToExp);

  const value = externalValue?.map(inputMapper);
  const onChange = values => externalOnChange(values.map(outputMapper));

  const renderValueLabel = () => externalRenderValueLabel(externalValue);

  return (
    <RangeSlider
      value={value}
      min={0}
      max={1000}
      step={1}
      pushable={0}
      renderValueLabel={renderValueLabel}
      title={title}
      onChange={onChange}
      onAfterChange={onAfterChange}
    />
  );
};

ExponentialRangeSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  base: PropTypes.number.isRequired,
  minExp: PropTypes.number.isRequired,
  maxExp: PropTypes.number.isRequired,
  renderValueLabel: PropTypes.func,
  title: PropTypes.string,
};
