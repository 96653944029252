import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  makeStyles,
  TableCell,
  Typography,
} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {FormattedDate, SortableTable, TableZebraRow} from '../../../components/Widgets';
import {LONG_DATE_FORMAT} from '../../../consts/variables';
import {formatRemaining} from '../../../consts/utilities';
import {getDeliverablePostTypeIcons} from '../../../consts/campaignPostType';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

interface Props {
  advertiser: Advertiser;
}

const AdvertiserCampaigns: React.FC<Props> = ({advertiser}) => {
  const classes = useStyles();

  const brandName = advertiser.domain;
  const campaigns = (advertiser.campaigns ?? []).filter(campaign => campaign?.archived === false);
  const {showCampaignPerformanceAggregate, showCampaignPerformanceCompare} = useFeatureFlags();
  const [selected, setSelected] = useState<number[]>([]);

  const handleSelect = (rowId: number, isChecked: boolean) => {
    setSelected(prevSelected =>
      isChecked ? [...prevSelected, rowId] : prevSelected.filter(id => id !== rowId)
    );
  };

  const aggregateUrl = () => {
    const ids = selected.join(',');
    return `/brands/${brandName}/campaign-aggregate?campaigns=${ids}`;
  };

  const compareUrl = () => {
    const ids = selected.join(',');
    return `/brands/${brandName}/campaign-comparison?campaigns=${ids}`;
  };

  const headCells = [
    {id: 'selected'},
    {id: 'campaign_id', align: 'left', label: 'Campaign Name'},
    {id: 'postType', align: 'center', label: 'Platforms'},
    {id: 'state', align: 'center', label: 'Status'},
    {id: 'started', align: 'right', label: 'Live Date'},
  ];

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.header}>
        <Grid item xs={12} md={7} lg={8}>
          <Typography variant="h3">Campaigns</Typography>
        </Grid>
        {(showCampaignPerformanceAggregate || showCampaignPerformanceCompare) && (
          <Grid
            item
            container
            spacing={1}
            xs={12}
            md={5}
            lg={4}
            alignItems="center"
            className={classes.toolbar}
          >
            {showCampaignPerformanceAggregate && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  href={aggregateUrl()}
                  disabled={selected.length === 0}
                >
                  Aggregate
                </Button>
              </Grid>
            )}
            {showCampaignPerformanceCompare && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  href={compareUrl()}
                  disabled={selected.length === 0}
                >
                  Compare
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Divider className={classes.spacing} />
      <Grid container spacing={3} aria-label="Advertiser Campaigns">
        <Grid item xs={12}>
          <Card>
            <SortableTable
              rows={campaigns}
              headCells={headCells}
              sortMetric="created"
              rowCount={10}
              renderRow={(row: any, index: number) => {
                const deliverablePostTypeIcons = getDeliverablePostTypeIcons(row.posts);
                const isSelected = selected.includes(row.id);
                return (
                  <TableZebraRow key={index} tabIndex={-1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onChange={e => handleSelect(row.id, e.target.checked)}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Grid container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item>
                          <Avatar>
                            <img src={row.photos?.coverPhoto?.url} />
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Link
                            to={`/brands/${brandName}/${row.id}/performance`}
                            aria-label="Influencer"
                          >
                            <strong>{row.name}</strong>
                          </Link>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      {deliverablePostTypeIcons.map(({name, icon}) => (
                        <Icon key={name} icon={icon} aria-label={name} width="24" />
                      ))}
                    </TableCell>
                    <TableCell align="center">{formatRemaining(row)}</TableCell>
                    <TableCell align="right">
                      <FormattedDate date={row.started} format={LONG_DATE_FORMAT} />
                    </TableCell>
                  </TableZebraRow>
                );
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: '5rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export default AdvertiserCampaigns;
