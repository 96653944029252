import React from 'react';
import {Alert} from '@material-ui/lab';
import {Divider, Grid, Typography} from '@material-ui/core';

interface ApiErrorProps {
  title?: string;
  classes?: Record<string, string>;
}

export const ApiError = React.memo<ApiErrorProps>(({title, classes}) => (
  <>
    {title && (
      <>
        <Typography variant="h4" className={classes?.header}>
          {title}
        </Typography>
        <Divider className={classes?.spacing} />
      </>
    )}
    <Grid item>
      <Alert severity="warning">
        We encountered a problem retrieving information for this campaign. Our team are
        investigating, and it will be resolved soon. Please try again in a few minutes.
      </Alert>
    </Grid>
  </>
));

interface DataProps {
  title: string;
}

export const NoPerformanceData = React.memo<DataProps>(({title}) => (
  <Alert severity="info" aria-label={title}>
    <p>
      <strong>{title} will be shown here when ready</strong>
    </p>
    We are working on building your reporting. Once creators have made their submissions and
    statistics have been calculated, they will be shown on this page. Please check back later.
  </Alert>
));

export const MissingPerformanceData = React.memo<{amountMissing: number}>(({amountMissing}) => (
  <Alert severity="info" aria-label="Missing Performance Data">
    <p>
      <strong>Missing Performance Data</strong>
    </p>
    {amountMissing === 1
      ? 'One of the campaigns you have chosen has been excluded from the comparison as it has no performance data.'
      : 'Some of the campaigns you have chosen have been excluded from the comparison as they have no performance data.'}
  </Alert>
));

export const MissingPaidPerformanceData = React.memo<{amountMissing: number}>(({amountMissing}) => (
  <Alert severity="info" aria-label="Missing Performance Data">
    <p>
      <strong>Missing Paid Performance Data</strong>
    </p>
    {amountMissing === 1
      ? 'One of the campaigns you have chosen has been excluded from the comparison as it has no paid performance data.'
      : 'Some of the campaigns you have chosen have been excluded from the comparison as they have no paid performance data.'}
  </Alert>
));

export const NoMetricData = React.memo<{type: string}>(({type}) => (
  <Alert severity="info" aria-label="No Metric Data">
    There is no {type} performance data to display.
  </Alert>
));

export const NoCampaignsSelected = React.memo(() => (
  <Alert severity="info" aria-label="No Campaigns Selected">
    There must be more than 1 campaign selected
  </Alert>
));

export const UnauthorizedAccess = React.memo(() => (
  <Alert severity="warning">
    Restricted Content: Access to this is limited to authorized users only.
  </Alert>
));

export const StoryEngagements = React.memo(() => (
  <Alert severity="info" aria-label={`Instagram Story Engagement metrics`}>
    <p>
      <strong>Instagram Story Engagements are currently excluded from metrics.</strong>
    </p>
    Due to the way in which Instagram Story engagement metrics are reported, and their short
    lifetime, we have chosen to exclude these metrics from engagement related metrics. We still
    include reach and impressions reporting, we only exclude them from the ER% calculation. This may
    result in that calculation differing to what is expected.
  </Alert>
));
