import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.css';

export default class MessageBox extends React.PureComponent {
  static propTypes = {
    action: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      message: '',
    };
  }

  handleChange = event => {
    this.setState({
      message: event.target.value,
    });
  };

  handleKeyDown = event => {
    // Enter
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.props.action(this.state.message);
      this.setState({message: ''});
    }
  };

  render() {
    return (
      <textarea
        placeholder="Write a message ..."
        className={styles.root}
        value={this.state.message}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        {...this.props}
      />
    );
  }
}
