import {gql} from '@apollo/client';

export const BrandUserQuery = gql`
  query BrandUserQuery($advertiserId: UUIDString!) {
    brandProfileUserForAdvertiser(id: $advertiserId) {
      id
      profilePicture
      fullName
      email
      hasAcceptedInvite
      hasInvitationSent
    }
  }
`;

export const SaveEmailMutation = gql`
  mutation SaveEmailMutation($advertiserId: UUIDString!, $email: String!) {
    enrollBrandProfileUser(advertiserId: $advertiserId, email: $email) {
      ok
      errorMessage
      user {
        id
        email
        hasAcceptedInvite
      }
    }
  }
`;

export const DeleteBrandUserMutation = gql`
  mutation DeleteBrandUserMutation($userId: UUIDString!) {
    removeBrandProfileUser(userId: $userId) {
      ok
    }
  }
`;

export const InviteBrandUserMutation = gql`
  mutation InviteBrandUserMutation($advertiserId: UUIDString!, $userId: UUIDString!) {
    sendBrandProfileInvitation(advertiserId: $advertiserId, userId: $userId) {
      ok
    }
  }
`;
