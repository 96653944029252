/* eslint-disable */
// This file does not go through babel. So we are stuck with es5 here.

module.exports = {
  red: '#FF3D33',
  yellow: 'rgb(244, 195, 9)',
  green: '#4ED966',
  blue: 'rgb(23, 174, 201)',
  white: 'rgb(255, 255, 255)',
  black: '#000000',
  grey05: '#A1A4B1',
  black5: '#F2F2F2',
  black10: '#E6E7E8', // popular
  black20: '#D1D3D4', // popular
  black30: '#BCBEC0',
  black40: '#A7A9AC', // popular
  black50: '#939598',
  black60: '#808285',
  black70: '#6D6E71',
  black80: '#58595B', // popular
  black90: '#414042', // popular
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
  light: 300,
  roman: 400,
  bold: 700,
  gold: 'rgb(166, 122, 46)',
  gold5: '#FAF7F4',
  gold70: '#CDBA97',
  goldLight: '#EDE4D5',
  influenceMYellow: '#E6B029',
  facebookBlue: '#3b5998',
  bigScreen: '1540px',
  purple: '#9B51E0',
  purpleLight: '#EBDCF9',

  // variables
  gridRowSpacing: '16px',
  gridGutter: '12px',
  mainContentWidth: '1130px',
  smallScreen: '1440px',
  transitionTimeBase: '0.2s',
  boxShadowBase: '0 1px 5px rgba(0, 0, 0, 0.1)',
  topNavHeight: '56px',
  secondaryNavHeight: '43px',
};
