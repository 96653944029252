import React from 'react';
import {gql} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {makeStyles} from '@material-ui/styles';

import {
  ProfilePicture,
  RelativeDate,
  MediaPreview,
  MediaContainer,
  PostTypeChip,
} from 'components/Widgets';
import PostStats from 'components/Widgets/PostStats/PostStats';

import {Gig, Influencer} from 'services/fragments';
import {CampaignPostType} from 'consts/campaignPostType';

import {InfluencerBox, CampaignBox} from '..';
import {isDeleted, latestMedia, hasMedia} from '../../../consts/gigHelper';
import {black40, black50, black60, nearWhite, whisper} from '../../../consts/brand.integration';

interface Props {
  gig: Gig;
  influencer: Influencer;
  url: string;
  campaign: Campaign;
  feature: string;
  showEngagement: boolean;
  showCampaign: boolean;
}

const GigMedia: React.FC<Props> = ({
  gig,
  influencer,
  url,
  campaign,
  feature,
  showCampaign,
  showEngagement,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onCampaignSelected = () => {
    const advertiser = gig.post?.campaign?.advertiser?.domain;
    const campaignId = gig.post?.campaign?.id;
    history.push({pathname: `/brands/${advertiser}/${campaignId}`});
  };

  const onInfluencerSelected = () => history.push({pathname: `/influencers/${influencer.id}`});

  const onGigSelected = () => history.push({pathname: url});

  return (
    <Card className={classes.root}>
      {/* TODO: Setting the stage to add an actions menu with most common/used actions */}
      {/* <CardHeader
        avatar={
          <>
            {feature === 'influencer' && (
              <InfluencerBox
                influencer={influencer}
                insightStatus={gig.insightStatus ?? 'not_set'}
              />
            )}
            {feature === 'campaign' && <CampaignBox campaign={campaign} />}
          </>
        }
        action={
          <IconButton aria-label="options">
            <Icon icon="mdi:dots-vertical" />
          </IconButton>
        }
      /> */}
      {showCampaign && gig.post && (
        <CardActionArea onClick={onCampaignSelected}>
          <Box
            style={{display: 'flex', padding: '.5rem', alignItems: 'center', background: whisper}}
          >
            <ProfilePicture
              square
              src={gig.post.campaign?.advertiser?.profilePicture}
              size={20}
              className={classes.footerCampaignLogo}
            />
            <Typography variant="caption" color="secondary" style={{marginLeft: '.5rem'}}>
              {gig.post.campaign?.name}
            </Typography>
          </Box>
        </CardActionArea>
      )}
      {feature === 'influencer' && (
        <CardActionArea onClick={onInfluencerSelected}>
          <InfluencerBox influencer={influencer} insightStatus={gig.insightStatus ?? 'not_set'} />
        </CardActionArea>
      )}
      {feature === 'campaign' && (
        <CardActionArea onClick={onCampaignSelected}>
          <CampaignBox campaign={campaign} />
        </CardActionArea>
      )}
      {hasMedia(gig) && (
        <CardActionArea onClick={onGigSelected}>
          <MediaContainer width={345} height={345}>
            <MediaPreview
              media={latestMedia(gig)}
              width={345}
              height={345}
              isDeleted={isDeleted(gig)}
            />
            <PostTypeChip
              type={gig.post?.postType as PostType}
              style={{
                position: 'absolute',
                top: '16px',
                left: '16px',
                backgroundColor: `${nearWhite}CC`, // CC = 80% alpha
                zIndex: 10,
              }}
            />
          </MediaContainer>

          <Grid container className={classes.infoWrapper} direction="column" spacing={1}>
            <Grid item className={classes.dateFromNow}>
              {gig.isPosted ? 'Posted ' : 'Submitted '}
              <RelativeDate date={gig.instagramContent?.posted || gig.created} />
            </Grid>
            <Grid item>
              {showEngagement &&
                (gig.instagramContent && gig.isPosted ? (
                  <PostStats gig={gig} />
                ) : (
                  !gig.isPosted &&
                  gig.post?.postType != CampaignPostType.content && (
                    <Typography variant="caption" color="secondary">
                      Not posted yet
                    </Typography>
                  )
                ))}
            </Grid>
          </Grid>
        </CardActionArea>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    width: 345,
    margin: 4,
  },
  infoWrapper: {
    padding: '.7rem .7rem',
  },
  dateFromNow: {
    fontSize: '12px',
    fontWeight: 500,
    color: `${black50}`,
    flexShrink: 0,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  mediaStats: {
    display: 'flex',
    flexDirection: 'row',
  },
  mediaStatsItem: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  mediaStatsItemText: {
    fontSize: '12px',
    fontWeight: 500,
    color: `${black40}`,
    lineHeight: '1',
    marginLeft: '4px',
  },
  emptyPlaceholder: {
    fontSize: '14px',
    color: `${black60}`,
  },
  emptySubText: {
    composes: 'emptyPlaceholder',
    color: `${black50}`,
    fontSize: '12px',
  },
  campaignWrapper: {
    marginBottom: '14px',
  },
  'campaignWrapper img': {
    borderRadius: '50px',
    height: '37px',
    float: 'left',
    marginRight: '10px',
  },
  'campaignWrapper p': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '12px',
  },
  advertiserName: {
    fontWeight: 'bold', // replace with actual value if $bold is a variable
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
  },
  overlayText: {
    backgroundColor: 'white', // replace with actual value if $white is a variable
    padding: '10px',
    borderRadius: '4px',
  },
  footerCampaign: {
    display: 'flex',
    flexShrink: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: '4px 0 4px 12px',
  },
  footerCampaignLogo: {
    flex: '0 0 20px',
  },
  footerCampaignName: {
    fontSize: '12px',
    fontWeight: 600,
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    position: 'relative',
    cursor: 'pointer',
  },
  videoIcon: {
    fontSize: '18px',
    color: 'black', // replace with actual value if $black20 is a variable
  },
});

GigMedia.fragments = {
  gig: gql`
    fragment GigMediaFields on Gig {
      __typename
      influencer {
        id
        fullName
        profilePicture
        ...socialPlatformsFields
      }
      id
      isPosted
      isVerified
      created
      isPassedReviewPeriod
      insightStatus
      submission {
        created
        media {
          ...mediaResultFields
        }
      }
      ...platformsFields
      post {
        id
        postType
        campaign {
          id
          name
          requireInsights
          advertiser {
            id
            name
            domain
            profilePicture
          }
        }
      }
    }
    ${Influencer.socialPlatformsFragment}
    ${Gig.platformsFragment}
    ${Gig.mediaPreview}
  `,
};

export default GigMedia;
