import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './style.css';

const generalRequirements = [
  {
    title: 'Campaign needs a name',
    test: campaign => campaign.name !== null,
  },
  {
    title: 'Campaign needs at least one photo',
    test: campaign => campaign.photos.coverPhoto != null,
  },
  {
    title: 'Campaign needs to target at least one region',
    test: campaign => campaign.targeting.regions.length > 0,
  },
  {
    title: 'Every campaign post needs a brief',
    test: campaign => campaign.posts.every(post => post.brief && post.brief.length > 0),
  },
  {
    title: 'Every campaign post needs an opened date',
    test: campaign => campaign.posts.every(post => post.opened),
  },
  {
    title: 'Every campaign post needs a deadline',
    test: campaign => campaign.posts.every(post => post.deadline),
  },
];

const reachRequirements = [
  {
    title: 'Minimum campaign reach is 1.000.000',
    test: campaign => campaign.target.targetOverall >= 1000000,
  },
];

const assetsRequirements = [
  {
    title: 'Minimum number of assets is 20',
    test: campaign => campaign.target.targetOverall >= 20,
  },
];

const requirementItem = (requirement, campaign) => {
  const complete = requirement.test(campaign);

  const requirementClasses = classNames({
    [styles.item]: true,
    [styles.complete]: complete,
    [styles.incomplete]: !complete,
  });

  return (
    <div key={requirement.title} className={requirementClasses}>
      {requirement.title}
    </div>
  );
};

const CampaignChecklist = ({campaign}) => {
  let requirements = generalRequirements;

  if (campaign.rewardModel === 'assets') {
    requirements = requirements.concat(assetsRequirements);
  }

  if (campaign.rewardModel === 'reach') {
    requirements = requirements.concat(reachRequirements);
  }

  return (
    <div className={styles.root}>
      <div className={styles.heading}>Launch Checklist</div>
      {requirements.map(requirement => requirementItem(requirement, campaign))}
    </div>
  );
};

CampaignChecklist.propTypes = {
  campaign: PropTypes.object,
};

export default CampaignChecklist;
