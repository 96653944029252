export const parseDealId = (value: string) => {
  const regex = /^(?:https:\/\/app\.hubspot\.com\/contacts\/\d+\/deal\/)?(\d+)/;
  const match = value.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    if (!value.match(regex)) {
      return '';
    }
  }
  return value;
};
