import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import {LoaderDots} from '..';
import styles from './style.css';
import {usePrevious} from '../../../hooks/usePrevious';

interface Props {
  block: boolean;
  text: string | Record<string, unknown>;
  className: string;
  dangerOutline: boolean;
  disabled: boolean;
  facebookBlue: boolean;
  gray: boolean;
  grayOutline: boolean;
  inline: boolean;
  large: boolean;
  lightGold: boolean;
  outline: boolean;
  pending: boolean;
  small: boolean;
  transparent: boolean;
  type: string | any;
  white: boolean;
  danger: boolean;
  success: boolean;
  skipSuccess: boolean;
  failure: boolean;
  noMargin: boolean;
  onSuccessText: string;
  onClick: (event: any) => void;
}

const Button: React.FC<Partial<Props>> = ({
  onClick: propsOnClick = () => {
    // default onClick
  },
  ...props
}) => {
  const [text, setText] = useState(props.text);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const oldText = usePrevious(text);
  const oldSuccess = usePrevious(success);
  const oldFailure = usePrevious(failure);

  useEffect(() => {
    if (props.text !== oldText) {
      setText(props.text);
    }

    if ((!oldSuccess && props.success) || (!oldFailure && props.failure)) {
      onFormSubmit(props.success);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text, props.success, props.failure]);

  const onClick = (event: any) => {
    if (props.type !== 'submit') {
      event.preventDefault();
    }
    return propsOnClick(event);
  };

  const onFormSubmit = (result: boolean) => {
    setSuccess(result);
    setFailure(!result);
    setText(result ? props.onSuccessText || props.text : 'failed');

    setTimeout(() => {
      setSuccess(false);
      setFailure(false);
      setText(props.text);
    }, 2000);
  };

  const className = classNames({
    [styles.root]: true,
    [styles.success]: success && !props.skipSuccess,
    [styles.failure]: failure,
    [styles.submitButton]: props.type === 'submit',
    [props.className]: props.className,
    [styles.outline]: props.outline || props.dangerOutline || props.grayOutline,
    [styles.dangerOutline]: props.dangerOutline,
    [styles.grayOutline]: props.grayOutline,
    [styles.inline]: props.inline,
    [styles.block]: props.block,
    [styles.large]: props.large,
    [styles.small]: props.small,
    [styles.gray]: props.gray,
    [styles.white]: props.white,
    [styles.lightGold]: props.lightGold,
    [styles.transparent]: props.transparent,
    [styles.facebookBlue]: props.facebookBlue,
    [styles.noMargin]: props.noMargin,
    [styles.danger]: props.danger,
  });

  return (
    <button type={props.type} disabled={props.disabled} className={className} onClick={onClick}>
      {props.pending ? <LoaderDots /> : text}
    </button>
  );
};

export default Button;
