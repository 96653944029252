import React from 'react';

import {MenuPopover, ProfilePicture, Select} from 'components/Widgets';

import styles from './style.css';
import DisableUserButton from './DisableUserButton';
import ImpersonateUserButton from './ImpersonateUserButton';

interface Props {
  users: Array<User>;
  roles: Array<Role>;
  onUpdateUserRole: (userId: User['id'], role: Role['name']) => void;
}

const rolesToOptions = (roles: Role[]) => roles.map(role => ({name: role.name, value: role.name}));

const AdminListAdvertiserUsers: React.FC<Props> = ({users, roles, onUpdateUserRole}) => (
  <div className={styles.root}>
    <table className={styles.table} aria-label="Users">
      <tbody>
        {users.map(user => (
          <tr key={user.id}>
            <td className={styles.cell}>
              <div>
                <ProfilePicture src={user.profilePicture ?? undefined} size={40} />
              </div>
            </td>

            <td className={styles.cell}>
              {user.fullName || <span className={styles.empty}>No name</span>}
            </td>

            <td data-testid="user-email" className={styles.cell}>
              {user.email}
            </td>

            <td className={styles.cell}>
              <Select
                options={rolesToOptions(roles)}
                value={user.roleName}
                onChange={(role: Role['name']) => onUpdateUserRole(user.id, role)}
              />
            </td>

            <td className={styles.cell}>
              <MenuPopover
                items={[
                  <ImpersonateUserButton
                    key={`impersonate-user-${user.id}`}
                    fullName={user.fullName!}
                    userId={user.id!}
                    role={user.roleName!}
                  />,
                  <DisableUserButton
                    key={`disable-user-${user.id}`}
                    fullName={user.fullName!}
                    email={user.email!}
                  />,
                ]}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default AdminListAdvertiserUsers;
