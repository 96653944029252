import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {TextInput, LoaderDots} from '../';
import styles from './style.css';

const TextInputWithImage = ({
  value = '',
  prefix,
  onBlur,
  onChange,
  onFocus,
  label,
  placeholder,
  imageUrl,
  pending,
}) => (
  <div className={styles.field}>
    <div className={classNames((imageUrl || pending) && styles.lessWidth)}>
      <TextInput
        autoComplete="off"
        prefix={prefix}
        type="text"
        label={label}
        placeholder={placeholder}
        value={value}
        pending={false}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>

    <div className={styles.photoWrapper}>
      {pending ? (
        <LoaderDots />
      ) : (
        imageUrl && <img className={styles.photo} src={imageUrl} alt="Company logo" />
      )}
    </div>
  </div>
);

TextInputWithImage.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  pending: PropTypes.bool,
  prefix: PropTypes.string,
};

export default TextInputWithImage;
