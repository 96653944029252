import React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Icon} from '@iconify/react';
import clsx from 'clsx';

import {Tooltip} from 'components/Widgets';

import {black80, bold, sizeCaption, sizeLarge, sizeBody1} from 'consts/brand.integration';
import {numberWithCommas} from 'consts/utilities';
import {PlatformsConfig} from 'consts/platforms';

interface Props {
  followers?: number | null | undefined;
  account?: {followers: number} | null | undefined;
  instagramFollowers?: number | null;
  tiktokFollowers?: number | null;
  youtubeSubscriberCount?: number | null;
  twitchFollowers?: number | null;
  engagementRate?: string | null;
  tooltip?: string;
  color?: string;
  className?: string;
  icon?: string;
  size?: 'small' | 'large';
}

interface StyleProps {
  color?: string;
  size?: 'small' | 'large';
}

const DataItem: React.FC<Props> = ({
  followers,
  engagementRate,
  tooltip,
  icon,
  color,
  className,
  size,
}) => {
  const classes = useStyles({color, size});
  if (followers === undefined && !engagementRate) {
    return null;
  }
  const value = followers !== undefined ? numberWithCommas(followers) : engagementRate;
  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip overlay={<span>{tooltip}</span>} mouseEnterDelay={0.3} placement="bottom">
        {value && <Icon icon={icon} />}
        <span>{value}</span>
      </Tooltip>
    </div>
  );
};

const FollowerData: React.FC<Props> = ({
  instagramFollowers,
  tiktokFollowers,
  youtubeSubscriberCount,
  twitchFollowers,
  engagementRate,
  color,
  className,
  size,
}) => {
  const dataItems = [
    {
      followers: instagramFollowers,
      icon: PlatformsConfig.instagram.iconMono,
      tooltip: 'Instagram Followers',
    },
    {
      followers: tiktokFollowers,
      icon: PlatformsConfig.tiktok.iconMono,
      tooltip: 'TikTok Followers',
    },
    {
      followers: youtubeSubscriberCount,
      icon: PlatformsConfig.youtube.iconMono,
      tooltip: 'YouTube Subscribers',
    },
    {
      followers: twitchFollowers,
      icon: PlatformsConfig.twitch.iconMono,
      tooltip: 'Twitch Followers',
    },
    {
      engagementRate: engagementRate,
      icon: 'material-symbols:show-chart-rounded',
      tooltip: 'Engagement Rate',
    },
  ];
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      {dataItems.map((item, index) => (
        <DataItem
          key={index}
          followers={item.followers}
          engagementRate={item.engagementRate}
          icon={item.icon}
          tooltip={item.tooltip}
          size={size}
          color={color}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles<StyleProps>(() =>
  createStyles({
    root: (props: StyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      color: props.color || black80,
      fontWeight: bold,
      fontSize: props.size === 'large' ? sizeBody1 : sizeCaption,
      marginRight: props.size === 'large' ? '10px' : '5px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& svg': {
        height: props.size === 'large' ? '24px' : sizeLarge,
        width: props.size === 'large' ? '24px' : sizeLarge,
        color: props.color || black80,
        marginRight: '3px',
      },
      '&:not(:first-child)': {
        borderLeft: `1px solid ${black80}`,
        paddingLeft: props.size === 'large' ? '10px' : '5px',
        maxHeight: '15px',
      },
    }),
  })
);

export default FollowerData;
