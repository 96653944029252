import React from 'react';
import {Grid} from '@material-ui/core';

import {Definition, RequiresPermission} from '../../../../components/Widgets';
import {isEngagementLimitExceeded} from '../../../../consts/influencerHelper';

interface InfluencerRewardsProps {
  influencer: Influencer;
}
const RewardsEngagementAndScore: React.FC<InfluencerRewardsProps> = ({influencer}) => (
  <Grid container spacing={2}>
    {influencer.isSignedUp && (
      <Grid item xs={6}>
        <RequiresPermission permission="manage_influencers">
          <Definition
            title="Total rewards"
            left
            definition={influencer.totalRewards?.formattedValue}
          />
        </RequiresPermission>
      </Grid>
    )}
    {influencer.instagramAccount?.engagement && (
      <Grid item xs={6}>
        <Definition
          title={
            <span>
              Median organic
              <br />
              engagement
            </span>
          }
          right
          red={isEngagementLimitExceeded(influencer.instagramAccount.engagement.value)}
          definition={influencer.instagramAccount.engagement.formattedValue}
        />
      </Grid>
    )}
  </Grid>
);

export default RewardsEngagementAndScore;
