import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';

import styles from './style.css';

const CollapsibleWell = ({isOpen, maxHeight, children, content}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [amount, setAmount] = useState(0);
  const translateY = isOpen || isAnimating ? amount * maxHeight - maxHeight : 0;

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
            onUpdate={({opacity}) => setAmount(opacity)}
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            <div className={styles.root}>
              <div className={styles.border} style={{opacity: amount}} />
              <div
                className={styles.filterWell}
                style={{
                  opacity: amount,
                  transform: `translateY(${translateY}px)`,
                  transformOrigin: 'top',
                  willChange: 'transform',
                  zIndex: isOpen ? 3 : null,
                }}
              >
                <div className={styles.content}>{content}</div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        style={{
          transform: `translateY(${translateY}px)`,
          transformOrigin: 'top',
          willChange: 'transform',
          overflow: 'hidden',
          marginBottom: `${translateY}px`,
        }}
      >
        {children}
      </div>
    </>
  );
};

CollapsibleWell.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  maxHeight: PropTypes.number,
  content: PropTypes.node,
};

const FilterWell = ({isOpen, children, options, maxHeight = 280}) => {
  const Content = <div style={{maxHeight: isOpen ? 'none' : '140px'}}>{options}</div>;

  return (
    <CollapsibleWell isOpen={isOpen} maxHeight={maxHeight} content={Content}>
      {children}
    </CollapsibleWell>
  );
};

FilterWell.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.node.isRequired,
  maxHeight: PropTypes.number,
};

export default FilterWell;
