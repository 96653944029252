import React, {useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button, TextField} from '@material-ui/core';

import {Prompt, LoaderDots} from 'components/Widgets';

import {MaterialDesignIcon} from '../../../../../../components/Icons';

interface Props {
  offerId: string;
}

const MarkAsPaidButton: React.FC<Props> = ({offerId}) => {
  const [markAsPaid, {loading, data}] = useMutation(MarkAsPaidMutation);
  const [reference, setReference] = useState('');

  if (loading) return <LoaderDots />;

  if (data?.markAsPaid?.ok) {
    return <MaterialDesignIcon icon="done" />;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleSubmit = () => {
    if (reference.trim() !== '') {
      markAsPaid({variables: {offerId, reference}});
    }
  };

  return (
    <Prompt
      title="Mark as Paid"
      body={
        <>
          In order to manually mark this offer as paid, please provide a reason.
          <TextField
            placeholder="Reference"
            value={reference}
            style={{width: '100%', margin: '10px 0'}}
            onChange={handleInputChange}
          />
        </>
      }
      confirmText="Mark as Paid"
      control={<Button variant="outlined">Mark as Paid</Button>}
      disabled={reference.trim() === ''}
      onSubmit={handleSubmit}
    />
  );
};

const MarkAsPaidMutation = gql`
  mutation MarkAsPaid($offerId: UUIDString!, $reference: String!) {
    markAsPaid(offerId: $offerId, reference: $reference) {
      ok
      offer {
        id
      }
    }
  }
`;

export default MarkAsPaidButton;
