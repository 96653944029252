import React from 'react';
import {Card, Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import InfluencerTable, {
  InfluencerTableProps,
} from '../../Campaign/components/CampaignPerformance/InfluencerData/InfluencerTable';

interface Props {
  metrics: InfluencerTableProps['rows'];
}

const InfluencerMetrics: React.FC<Props> = ({metrics}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Creators
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container aria-label="Creator Metrics" className={classes.spacing}>
        <Grid item xs={12}>
          <Card>
            <InfluencerTable rows={metrics} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default InfluencerMetrics;
