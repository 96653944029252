import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {Link, useRouteMatch, withRouter} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';

import AdvertiserTabs from 'scenes/Advertiser/AdvertiserTabs';

import {LoaderDots, RequiresPermission, RowWrap} from '../../../components/Widgets';
import {CampaignCard} from '../../../components/Campaign';
import {campaignUrl, newCampaignUrl} from '../../../consts/urls';
import {useStyles} from './styles';

const CampaignList: React.FC = () => {
  const classes = useStyles();
  const {params} = useRouteMatch();
  const {loading, error, data} = useQuery(CampaignListQuery, {
    variables: {
      domain: params.advertiser,
    },
  });

  if (loading) {
    return <LoaderDots />;
  }

  if (error) {
    return <pre>{error.message}</pre>;
  }

  const {campaignsForAdvertiser, advertiser} = data;

  return (
    <div>
      <div className={classes.nav}>
        <AdvertiserTabs params={params} />
      </div>

      <div className={classes.content}>
        <div className={classes.header}>
          {!advertiser.archived && (
            <RequiresPermission permission="edit_campaign">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                component={Link}
                to={`${newCampaignUrl(advertiser)}`}
                className={classes.newCampaignBtn}
              >
                New campaign
              </Button>
            </RequiresPermission>
          )}
        </div>

        <RowWrap>
          {campaignsForAdvertiser.edges
            .map(edge => edge.node)
            .map(campaign => (
              <Link key={campaign.id} to={campaignUrl(campaign)}>
                <CampaignCard campaign={campaign} advertiser={advertiser} />
              </Link>
            ))}

          {campaignsForAdvertiser.length === 0 && <div className={classes.empty}>No campaigns</div>}
        </RowWrap>
      </div>
    </div>
  );
};

const CampaignListQuery = gql`
  query CampaignList($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      archived
      domain
      profilePicture
    }

    campaignsForAdvertiser(domain: $domain) {
      edges {
        node {
          id
          ...CampaignCardCampaign
        }
      }
    }
  }
  ${CampaignCard.fragments.campaign}
`;

export default withRouter(CampaignList);
