import React from 'react';

const CornerLeftDown = ({color = 'black', ariaLabel = ''}) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.83317 10L6.49984 13.3333L3.1665 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8333 2.66675H9.16667C8.45942 2.66675 7.78115 2.9477 7.28105 3.4478C6.78095 3.94789 6.5 4.62617 6.5 5.33341V13.3334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CornerLeftDown;
