import compose from 'lodash/flowRight';
import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import * as qs from 'query-string';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';
import {usePagination} from '@material-ui/lab';

import styles from './style.css';
import {InsightsSwitchView} from '../../../components/Widgets/InsightSwitch';
import {LoaderDots} from '../../../components/Widgets';
import {IFilters, IInsights} from './types';
import {InsightsQuery} from './graphqlQueries';
import RegionsFilter from '../../../components/Widgets/RegionsFilter';

const Filters: React.FC<IFilters> = ({history, location}) => {
  const params = qs.parse(location.search);
  const handleSwitch = (check: boolean) =>
    history.replace({
      ...location,
      search: qs.stringify({...params, mine: check}),
    });

  const handleCountryChange = (region: string | unknown) => {
    history.replace({
      ...location,
      search: qs.stringify({...params, region}),
    });
  };

  return (
    <div className={styles.filterRoot}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={styles.filters}>
          <div className={styles.campaignSwitchFilter}>
            <InsightsSwitchView mine={params?.mine === 'true'} setSwitch={handleSwitch} />
          </div>
          <div className={styles.regionFilter}>
            <label
              id="regions-label"
              htmlFor="regions-select"
              aria-hidden
              className={styles.hiddenLabel}
            >
              Regions
            </label>
            <RegionsFilter
              id="regions-select"
              ariaLabelledby="regions-label"
              showExtended
              filters={params}
              handleChange={e => {
                handleCountryChange(e.target.value ?? null);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={8} />
      </Grid>
    </div>
  );
};

const Insights: React.FC<IInsights> = ({children, ...props}) => {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);
  const {insightId: matchInsightId} = props.match.params;

  const [offset, setOffset] = useState(1);
  const {data, loading, refetch} = useQuery(InsightsQuery, {
    variables: {
      mine: params.mine === 'true',
      region: params.region,
      offset: offset - 1,
      limit: 1,
    },
  });

  const goToFirstPage = () => {
    setOffset(1);
  };

  const {items} = usePagination({
    count: data?.insights?.insightsCount,
    onChange: (event: React.ChangeEvent<unknown>, page: number) => {
      setOffset(page);
    },
    showFirstButton: true,
    showLastButton: true,
    page: offset,
  });

  const pushInsight = async () => {
    if (data.insights.edges.length < 1) {
      refetch();
    }
    history.push({
      pathname: `/insights/${data.insights.edges.length > 0 ? data.insights.edges[0].node.id : ''}`,
      search: location.search,
    });
  };

  const insightId =
    data?.insights && data?.insights.edges.length > 0 ? data?.insights.edges[0].node.id : undefined;

  useEffect(() => {
    if (!loading && data?.insights && data.insights.insightsCount > 0 && !matchInsightId) {
      pushInsight();
    } else if (!loading && insightId !== matchInsightId) {
      pushInsight();
    }
  });

  if (loading) {
    return <LoaderDots />;
  } else if (!params.insightId && data?.insights.insightsCount < 1) {
    return (
      <div>
        <div>
          <Typography variant="h1" style={{lineHeight: '36px'}}>
            Insights
          </Typography>
        </div>
        <div>
          <Filters history={history} location={location} />
        </div>
        <div className={styles.rootNoNew}>
          <Typography variant="h1">Wow! You did it.</Typography>
          <Typography variant="h3" component="h2">
            No new insights.
          </Typography>
          <div className={styles.emojiWrapper}>
            <span role="img" aria-label="party-popper-emoji" className={styles.emoji}>
              🎉
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div>
          <Typography variant="h1" style={{lineHeight: '36px'}}>
            Insights
          </Typography>
        </div>
        <div>
          <Filters {...{history, location, insights: data.insights}} />
        </div>
      </div>
      {children &&
        React.cloneElement(children, {
          insightsCount: data.insights.insightsCount,
          insightPosition: offset + 1,
          items,
          goToFirstPage: goToFirstPage,
        })}
    </div>
  );
};

export default compose(withRouter)(Insights);
