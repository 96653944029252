import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '36px',
      marginBottom: '36px',
    },
    button: {
      height: '40px',
      border: '1px solid #000',
      borderRadius: 0,
      padding: '12px 16px',
      marginTop: '4px',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    root: {
      marginRight: '32px',
      '& .MuiOutlinedInput-input': {
        height: '48px',
        width: '448px',
        adding: '12px 16px',
        boxSizing: 'border-box',
        borderRadius: '2px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#A1A4B1',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid black',
        },
      },
    },
  })
);
