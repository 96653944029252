import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {industryFilterStyle} from '../../../scenes/Admin/Campaigns/style';
import IndustrySelector from '../../../scenes/Advertiser/AdvertiserSettings/components/IndustrySelector';
import {placeholderIndustryStyle} from '../../../scenes/Advertiser/AdvertiserSettings/components/IndustrySelector/styles';
import {IIndustryFilter} from './types';

const IndustryFilter: React.FC<IIndustryFilter> = ({
  industriesData,
  filters,
  onIndustryChanged,
}) => {
  return (
    <IndustrySelector
      id="brand-industry-input"
      industries={industriesData?.industries}
      selectedIndustries={filters.advertiserIndustries}
      isAutoSizableMenu
      styles={{
        control: (base: any) => ({...base, ...industryFilterStyle.button}),
        valueContainer: (base: any) => ({...base, padding: 0}),
        placeholder: (base: any) => ({
          ...base,
          ...placeholderIndustryStyle,
          marginLeft: '4px',
          color: '#000',
          fontSize: 13,
        }),
      }}
      additionalComponents={{
        DropdownIndicator: () => <ArrowDropDownIcon fontSize="small" />,
      }}
      placeholder="Industry"
      onChange={onIndustryChanged}
    />
  );
};

export default IndustryFilter;
