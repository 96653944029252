import React from 'react';
import {makeStyles} from '@material-ui/styles';

interface Props {
  icon: string;
  className?: string;
}

const MaterialDesignIcon: React.FC<Props> = ({icon, className}) => {
  const classes = useStyles();
  return <i className={`${classes.icon} ${className} material-icons`}>{icon}</i>;
};

const useStyles = makeStyles({
  icon: {
    fontVariant: 'normal',
  },
});

export default MaterialDesignIcon;
