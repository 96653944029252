import type {
  CampaignInstagramPaidAdPerformanceResponse,
  CampaignTikTokPostPaidAdPerformanceResponse,
} from 'hooks/useDataServiceApi/types';

import {shouldDisplayMetric} from '../helper';

export const filterMetrics = (
  ads:
    | CampaignTikTokPostPaidAdPerformanceResponse
    | CampaignInstagramPaidAdPerformanceResponse
    | null,
  enabledMetrics: string[],
  platform: 'tiktok' | 'instagram'
) =>
  ads?.map(apiResponse => ({
    ...(shouldDisplayMetric(enabledMetrics, 'impression_total')
      ? {impression_cumulative_total: apiResponse.impression_cumulative_total}
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'engagement_total')
      ? {engagement_cumulative_total: apiResponse.engagement_cumulative_total}
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'video_play_cumulative_total') &&
    platform === 'instagram'
      ? {
          video_view_3_second_cumulative_total: (
            apiResponse as CampaignInstagramPaidAdPerformanceResponse[number]
          ).video_view_3_second_cumulative_total,
        }
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'video_play_cumulative_total') && platform === 'tiktok'
      ? {video_view_2_second_cumulative_total: apiResponse.video_view_2_second_cumulative_total}
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'impression_total')
      ? {
          engagement_rate_by_impressions_average:
            apiResponse.engagement_rate_by_impressions_average,
        }
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'click_through_rate_average')
      ? {click_through_rate_lifetime_average: apiResponse.click_through_rate_lifetime_average}
      : {}),

    ...(shouldDisplayMetric(enabledMetrics, 'view_through_rate_average')
      ? {view_through_rate_lifetime_average: apiResponse.view_through_rate_lifetime_average}
      : {}),
  })) ?? [];
