import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {useStyles} from './styles';

interface Props {
  id: string;
  value: string;
  isReadOnly?: boolean;
  onChange: (text: string) => void;
}

const SelectedItemChip: React.FC<Props> = ({id, value, isReadOnly, onChange}) => {
  const classes = useStyles();

  return (
    <div key={id} className={classes.chip}>
      <Typography id={id} variant="caption" component="span" className={classes.chipLabel}>
        {value}
      </Typography>
      {!isReadOnly && (
        <IconButton
          aria-label={`remove ${value}`}
          aria-labelledby={id}
          size="small"
          className={classes.crossButton}
          onClick={() => onChange(id)}
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

export default SelectedItemChip;
