import {numberMillified} from '../../../consts/utilities';

export type InfluencerFilterType = {
  gender: 'all' | 'female' | 'male';
  hasFacebookPage: boolean;
  hasTiktokAccount: boolean;
  hasYoutubeChannel: boolean;
  hasTwitchChannel: boolean;
  minFollowers: number;
  maxFollowers: number;
  minEngagement: number;
  maxEngagement: number;
  minAge: number;
  maxAge: number;
  minParticipatingCampaignCount: number;
  maxParticipatingCampaignCount: number;
  interestIds: string[];
  hasNoInterests: boolean;
  sortOrder: 'asc' | 'desc';
  sortBy: 'user_created' | 'followers' | 'participating_campaign_count';
};

export const initialState = Object.freeze<InfluencerFilterType>({
  gender: 'all',
  hasFacebookPage: false,
  hasTiktokAccount: false,
  hasYoutubeChannel: false,
  hasTwitchChannel: false,
  minFollowers: 1000,
  maxFollowers: 1000000,
  minEngagement: 0,
  maxEngagement: 20,
  minAge: 18,
  maxAge: 36,
  minParticipatingCampaignCount: 0,
  maxParticipatingCampaignCount: 100,
  interestIds: [],
  hasNoInterests: false,
  sortOrder: 'desc',
  sortBy: 'user_created',
});

export const valueToKNotation = (value: number) => {
  if (value === 1000000) return '1M+';
  else return numberMillified(value);
};

export const percentageFormatter = (value: number) => {
  if (value === 20) return '20%+';
  else return `${value}%`;
};

export const ageFormatter = (value: number) => {
  if (value === 36) return '36+';
  else return value;
};

export const participatingCampaignCountFormatter = (value: number) => {
  if (value === 100) return '100+';
  else return value;
};
