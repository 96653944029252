/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import {createBrowserHistory} from 'history';

type User = {
  id: string;
  fullName: string;
  email: string;
  roleName: string;
};

class ErrorReporting {
  static useLogRocket: boolean;
  static sentrySampleRate: number;
  static useSentryReplay: boolean;

  static init(): void {
    this.useLogRocket = window.localStorage.getItem('USE_LOGROCKET') === 'true';
    this.sentrySampleRate = Number(window.localStorage.getItem('SENTRY_SAMPLE_RATE')) ?? 0;
    this.useSentryReplay = window.localStorage.getItem('USE_SENTRY_REPLAY') === 'true';

    if (__DEV__ || !process.env.SENTRY_DSN) {
      return;
    }

    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.ENVIRONMENT,
      release: process.env.BUILD_VERSION,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history: createBrowserHistory(),
        }),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: this.sentrySampleRate,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: this.useSentryReplay ? 0.1 : 0,
      replaysOnErrorSampleRate: this.useSentryReplay ? 1.0 : 0,
      ignoreErrors: [
        /^Network request failed with status 401 - "UNAUTHORIZED"$/,
        "ObservableQuery with this id doesn't exist",
        'Network request failed with status 200',
        'Schema must be an instance of GraphQLSchema',
        /(Method webWidget:on.open does not exist.)/,
      ],
    });

    window.addEventListener('unhandledrejection', error => {
      this.captureError(error.reason, {}, 'UnhandledPromiseRejection');
    });
  }

  static setUserContext(user: User): void {
    if (__DEV__) {
      return;
    }

    if (this.useLogRocket) {
      LogRocket.init('takumi/takumi-web');

      LogRocket.getSessionURL(sessionURL => {
        Sentry.withScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }

    Sentry.setUser({
      id: user.id,
      username: user.fullName,
      email: user.email,
    });
    Sentry.withScope((scope): void => {
      scope.setExtra('Role Name', user.roleName);
    });

    if (this.useLogRocket) {
      LogRocket.identify(user.id, {
        name: user.fullName,
        email: user.email,
        roleName: user.roleName,
      });
    }
  }

  static captureError(
    error: Error,
    extra: Record<string, string> = {},
    logger = 'ErrorReporting'
  ): void {
    if (__DEV__) {
      console.error('ErrorReporting.captureError');
      console.error(error);
      return;
    }

    if (!(error instanceof Error)) {
      return;
    }

    Sentry.withScope((scope): void => {
      scope.setTag('logger', logger);
      Object.entries(extra).forEach(([key, value]) => scope.setTag(key, value));
      Sentry.captureException(error);
    });
  }

  static captureMessage(
    message: string,
    extra: Record<string, string> = {},
    logger = 'ErrorReporting'
  ): void {
    if (__DEV__) {
      console.error('ErrorReporting.captureMessage', message);
      return;
    }

    Sentry.withScope((scope): void => {
      scope.setTag('logger', logger);
      Object.entries(extra).forEach(([key, value]) => scope.setTag(key, value));
      Sentry.captureMessage(message);
    });
  }
}

export default ErrorReporting;
