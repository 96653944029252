import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ISO6391 from 'iso-639-1';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import Select from 'react-select';

import {Modal, LoaderDots, Button, Checkbox, RangeSlider} from 'components/Widgets';
import {
  Glasses,
  NoGlasses,
  Curled,
  Straight,
  Male,
  Female,
  Other,
} from 'components/Icons/InfoIcons';

import brandStyles from 'consts/brand';
import {capitalize, singularOrPlural, range} from 'consts/utilities';

import {BigPicker, BigPickerGroup} from './BigPicker';
import styles from './style.css';

const Tag = ({onClick, active, name}) => (
  <div className={classNames(styles.tag, active && styles.activeTag)} onClick={onClick}>
    {name}
  </div>
);
Tag.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  name: PropTypes.string,
};

class SelfTagModal extends React.Component {
  removeTag = tag => {
    const {value, onChange} = this.props;
    onChange({...value, tagIds: (value.tagIds || []).filter(t => t !== tag)});
  };
  addTag = tag => {
    const {value, onChange} = this.props;
    onChange({...value, tagIds: [...(value.tagIds || []), tag]});
  };
  tagActive = tag => {
    const {value} = this.props;
    return value.tagIds && value.tagIds.indexOf(tag) !== -1;
  };

  componentDidMount() {
    this.languageOptions = ISO6391.getLanguages(ISO6391.getAllCodes()).map(({code, name}) => ({
      value: code,
      label: name,
    }));
  }

  resetButtonVisible = () => {
    const {value} = this.props;

    return (
      Object.keys(value).filter(
        key => key !== 'tagIds' && key !== 'hairColourCategory' && value[key] !== null
      ).length > 0 ||
      (value.tagIds && value.tagIds.length > 0) ||
      (value.hairColourCategory && value.hairColourCategory.length > 0)
    );
  };

  activeFilterCount = () => {
    const {value} = this.props;
    if (!value) return 0;
    const flatCount = Object.keys(value).filter(
      key =>
        key !== 'tagIds' &&
        key !== 'hairColourCategory' &&
        key !== 'languages' &&
        value[key] !== undefined &&
        value[key] !== null
    ).length;
    const tagCount = value.tagIds ? value.tagIds.length : 0;
    const hairColorCount = value.hairColourCategory ? value.hairColourCategory.length : 0;
    const languageCount = value.languages ? value.languages.length : 0;
    return flatCount + tagCount + hairColorCount + languageCount;
  };

  resetValue = () => this.props.onChange({});

  renderCategory = category => {
    return (
      <div key={category.id}>
        <div className={styles.categoryName}>{category.name}</div>
        <div className={styles.tags}>
          {category.tags.map(({id, name}) => (
            <Tag
              key={id}
              name={name}
              active={this.tagActive(id)}
              onClick={() => {
                if (this.tagActive(id)) this.removeTag(id);
                else this.addTag(id);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  renderCategories = () => {
    const {tags} = this.props;
    return (
      <div className={styles.categories}>
        {this.renderChildrenCategory()}
        {tags.map(category => this.renderCategory(category))}
        {!this.resetButtonVisible() && (
          <div className={styles.explainer}>
            Only influencers who match{' '}
            <i>
              <b>all</b>
            </i>{' '}
            the active tags are targeted.
          </div>
        )}
      </div>
    );
  };

  onChildrenAgesChange = val => {
    const {value, onChange} = this.props;
    const childrenAges = range(val[0], val[1]);
    onChange({...value, childrenAges});
  };
  deserializeChildrenAges = val => (val ? [val[0], val[val.length - 1]] : [0, 20]);

  renderExtraChildrenOptions = () => {
    const {value, onChange} = this.props;
    return (
      <div className={styles.extraChildrenOptions}>
        <RangeSlider
          max={20}
          min={0}
          title="Age of children"
          value={this.deserializeChildrenAges(value.childrenAges)}
          renderValueLabel={([min, max]) => {
            const maxLabel = max === 20 ? '20+' : max;
            return `${min}-${maxLabel} years old`;
          }}
          onChange={this.onChildrenAgesChange}
        />
        <div className={styles.childGenderPicker}>
          <BigPickerGroup
            value={value.childGender}
            nullable
            onChange={childGender => onChange({...value, childGender})}
          >
            <BigPicker id="male" title="Male" icon={Male} horizontal />
            <BigPicker id="female" title="Female" icon={Female} horizontal />
            <BigPicker id="other" title="Other" icon={Other} horizontal />
          </BigPickerGroup>
        </div>
      </div>
    );
  };

  onHasChildrenChange = e => {
    const {value, onChange} = this.props;
    if (e.target.checked) onChange({...value, hasBornChild: e.target.checked || undefined});
    else {
      onChange({
        ...value,
        hasBornChild: undefined,
        childrenAges: undefined,
        childGender: undefined,
      });
    }
  };

  renderChildrenCategory = () => {
    const {value, onChange} = this.props;
    return (
      <div>
        <div className={styles.categoryName}>Children</div>
        <Checkbox
          checked={value.hasBornChild || false}
          label="Influencer has children"
          description="More options appear if checked"
          onChange={this.onHasChildrenChange}
        />
        {value.hasBornChild && this.renderExtraChildrenOptions()}
        <Checkbox
          checked={value.hasUnbornChild || false}
          label="Influencer is expecting a child"
          description="Influencer or partner is pregnant"
          onChange={e => onChange({...value, hasUnbornChild: e.target.checked || undefined})}
        />
      </div>
    );
  };

  renderContents = () => {
    const {loading, value, onChange} = this.props;
    const eyeColors = this.props.eyeColors?.map(obj => ({
      value: obj.id,
      label: capitalize(obj.name),
    }));

    const hairColors = this.props.hairColors
      ?.map(c => c.category)
      .filter((e, i, a) => a.indexOf(e) === i)
      .map(e => ({value: e, label: capitalize(e)}));

    if (loading) return <LoaderDots />;
    return (
      <div>
        <div className={styles.columns}>
          <div className={styles.column}>
            <BigPickerGroup
              value={value.hasGlasses}
              nullable
              onChange={hasGlasses => onChange({...value, hasGlasses})}
            >
              <BigPicker id={true} title="Glasses" icon={Glasses} />
              <BigPicker id={false} title="No Glasses" icon={NoGlasses} />
            </BigPickerGroup>
            <Select
              isClearable
              placeholder="Eye colors"
              options={eyeColors}
              value={eyeColors.find(color => color.value === value.eyeColour) || null}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: brandStyles.gold70,
                  primary75: brandStyles.gold70,
                  primary50: brandStyles.gold70,
                  primary: brandStyles.gold,
                },
              })}
              styles={{
                multiValueRemove: styles => ({
                  ...styles,
                  ':hover': {
                    color: brandStyles.white,
                    backgroundColor: brandStyles.gold,
                  },
                }),
              }}
              onChange={(color, action) => {
                if (action === 'clear') onChange({...value, eyeColour: null});
                else onChange({...value, eyeColour: color?.value});
              }}
            />
          </div>
          <div className={styles.column}>
            <BigPickerGroup
              value={value.hairType}
              nullable
              onChange={hairType => onChange({...value, hairType})}
            >
              <BigPicker
                id="0e7fb575-4184-48b9-b3b7-8e054c6f1e9a"
                title="Straight"
                icon={Straight}
              />
              <BigPicker id="ea769c27-a210-4fb3-8341-a0c3174b2124" title="Curly" icon={Curled} />
            </BigPickerGroup>
            <Select
              isClearable
              placeholder="Hair colors"
              options={hairColors}
              value={
                value.hairColourCategory
                  ? value.hairColourCategory.map(color => hairColors.find(c => c.value === color))
                  : null
              }
              isMulti
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: brandStyles.gold70,
                  primary75: brandStyles.gold70,
                  primary50: brandStyles.gold70,
                  primary: brandStyles.gold,
                },
              })}
              styles={{
                multiValueRemove: styles => ({
                  ...styles,
                  ':hover': {
                    color: brandStyles.white,
                    backgroundColor: brandStyles.gold,
                  },
                }),
              }}
              onChange={val => onChange({...value, hairColourCategory: val.map(s => s.value)})}
            />
          </div>
        </div>
        <Select
          isClearable
          placeholder="Languages"
          options={this.languageOptions}
          isMulti
          value={
            value.languages
              ? value.languages.map(code => ({value: code, label: ISO6391.getName(code)}))
              : null
          }
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: brandStyles.gold70,
              primary75: brandStyles.gold70,
              primary50: brandStyles.gold70,
              primary: brandStyles.gold,
            },
          })}
          styles={{
            multiValueRemove: styles => ({
              ...styles,
              ':hover': {
                color: brandStyles.white,
                backgroundColor: brandStyles.gold,
              },
            }),
          }}
          onChange={val => onChange({...value, languages: val.map(s => s.value)})}
        />
        {this.renderCategories()}
        <div className={styles.resetButton}>
          {this.resetButtonVisible() && (
            <Button small grayOutline text="Reset" onClick={this.resetValue} />
          )}
        </div>
      </div>
    );
  };

  render() {
    const {right = false} = this.props;
    const filterCount = this.activeFilterCount();
    const label =
      filterCount === 0
        ? 'Self tags'
        : `${filterCount} ${singularOrPlural('Self tag', filterCount)}`;

    return (
      <Modal
        id="self-tags"
        title="Self tags"
        modalToggler={
          <div className={classNames([styles.root, right && styles.right])}>
            <div className={styles.button}>{label}</div>
          </div>
        }
      >
        {this.renderContents()}
      </Modal>
    );
  }
}

SelfTagModal.propTypes = {
  loading: PropTypes.bool,
  tags: PropTypes.array,
  value: PropTypes.shape({
    tagIds: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  eyeColors: PropTypes.arrayOf(PropTypes.object),
  hairColors: PropTypes.arrayOf(PropTypes.object),
  right: PropTypes.bool,
};

const SelfTagQuery = gql`
  query SelfTagQuery {
    influencerTags {
      id
      name
      description
      tags {
        id
        name
      }
    }
    influencerHairColours {
      id
      name
      category
    }
    influencerEyeColours {
      id
      name
    }
  }
`;

export default graphql(SelfTagQuery, {
  props: ({data: {loading, influencerTags, influencerHairColours, influencerEyeColours}}) => ({
    loading,
    tags: influencerTags,
    hairColors: influencerHairColours,
    eyeColors: influencerEyeColours,
  }),
})(SelfTagModal);
