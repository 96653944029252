export const filters = {
  ACTIVE: 'active',
  REQUESTED: 'requested',
  AWAITING_RESPONSE: 'awaiting-response',
  REVOKED_OR_REJECTED: 'revoked-or-rejected',
  HISTORY: 'history',
  EXPIRED: 'expired',
};

export const filtersList = [
  filters.ACTIVE,
  filters.REQUESTED,
  filters.AWAITING_RESPONSE,
  filters.REVOKED_OR_REJECTED,
  filters.HISTORY,
  filters.EXPIRED,
];
