import {Box, Grid, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';

import UserCard from './UserCard';
import {black20, nearWhite} from '../../../../../consts/brand.integration';

interface Props {
  historyItem: HistoryInterface;
  gig: Gig;
  feedback?: string;
  reason?: string;
  explanation?: string;
}

const CardWithFeedback: React.FC<Props> = ({historyItem, feedback, reason, explanation}) => {
  const classes = useStyles();

  const renderExplanation = () => {
    if (feedback || reason || explanation) {
      return (
        <Grid item>
          <Paper variant="outlined" className={classes.feedback}>
            {feedback?.trim().length && (
              <>
                <Typography variant="subtitle1">Feedback:</Typography>
                <Typography variant="body2" className={classes.copy}>
                  "{feedback}"
                </Typography>
              </>
            )}
            {reason?.trim().length && (
              <>
                <Typography variant="subtitle1">Reason:</Typography>
                <Typography variant="body2" className={classes.copy}>
                  "{reason}"
                </Typography>
              </>
            )}
            {explanation?.trim().length && (
              <Box marginTop="1rem">
                <Typography variant="subtitle1">Explanation:</Typography>
                <Typography variant="body2" className={classes.copy}>
                  "{explanation}"
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      );
    }
    return <></>;
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
      {renderExplanation()}
    </Grid>
  );
};

const useStyles = makeStyles({
  feedback: {
    margin: '0 1rem',
    padding: '1rem',
    backgroundColor: nearWhite,
    border: `1px dashed ${black20}`,
  },
  copy: {
    padding: '.2rem 1rem',
  },
});

export default CardWithFeedback;
