import React, {useState, useEffect, useCallback} from 'react';
import {Avatar, Button, Grid, TableCell, TextField, Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {useMutation} from '@apollo/client';

import {getPostTypeFormatted} from 'consts/campaignPostType';

import {FormattedAddress, Prompt, TableZebraRow} from '../../../../../components/Widgets';
import defaultAvatar from '../../../../../scenes/App/components/SideBarMenu/img/defaultAvatar.png';
import {socialIcons} from '../../../../../consts/icons';
import {TRACKING_SERVICE} from '../../../../../consts/urls';
import {
  MarkDispatchedMutation,
  MarkNotDispatchedMutation,
} from '../../../CampaignShipping/graphqlQueries';

interface Props {
  influencerOffer: Offer;
  classes: Record<string, string>;
  ariaLabel: string;
}

interface PromptConfig {
  title: string;
  body: React.ReactNode;
  confirmText: string;
  control: React.ReactElement;
  onSubmit: () => Promise<void>;
  disabled?: boolean;
  fullWidth?: boolean;
}

const ShippingTableRow = ({influencerOffer, ariaLabel, classes}: Props) => {
  const [trackingCode, setTrackingCode] = useState(influencerOffer.trackingCode || null);
  const [isInTransit, setIsInTransit] = useState(influencerOffer.inTransit);

  const [markDispatched, {loading: markDispatchedLoading}] = useMutation(MarkDispatchedMutation);
  const [markNotDispatched, {loading: markNotDispatchedLoading}] =
    useMutation(MarkNotDispatchedMutation);

  const productDispatched = useCallback(async () => {
    let trackingCodeToSend = trackingCode;
    if (!trackingCodeToSend || trackingCodeToSend === '') {
      trackingCodeToSend = null;
    }
    const {data} = await markDispatched({
      variables: {id: influencerOffer.id, trackingCode: trackingCodeToSend},
    });
    if (data) {
      setTrackingCode(data.markDispatched.offer.trackingCode);
      setIsInTransit(data.markDispatched.offer.inTransit);
    }
  }, [markDispatched, trackingCode, influencerOffer.id]);

  useEffect(() => {
    setTrackingCode(influencerOffer.trackingCode || null);
    setIsInTransit(influencerOffer.inTransit);
  }, [influencerOffer.trackingCode, influencerOffer.inTransit]);

  const productNotDispatched = useCallback(async () => {
    const {data} = await markNotDispatched({variables: {id: influencerOffer.id}});
    if (data) {
      setIsInTransit(data.markNotDispatched.offer.inTransit);
    }
    setTrackingCode(null);
  }, [markNotDispatched, influencerOffer.id]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingCode(event.target.value);
  }, []);

  const startDispatch = productDispatched;

  const loading = markDispatchedLoading || markNotDispatchedLoading;

  const promptConfigurations: Record<string, PromptConfig> = {
    shipment: {
      title: 'Mark Item as Shipped',
      body: (
        <>
          <Typography variant="body2" paragraph>
            If you have a tracking code, enter it below and click "Confirm Shipment".
            <br />
            If you don't have a code, just click "Confirm Shipment".
          </Typography>
          <TextField
            id="tracking-code"
            value={trackingCode || ''}
            placeholder="Tracking Code ID"
            fullWidth
            onChange={handleChange}
          />
        </>
      ),
      confirmText: 'Confirm Shipment',
      control: (
        <Button
          variant="contained"
          color="primary"
          className={classes.ctaUpdateButton}
          onClick={startDispatch}
        >
          Mark as Shipped <Icon icon="mdi:check-bold" />
        </Button>
      ),
      onSubmit: productDispatched,
      disabled: loading,
    },
    revert: {
      title: 'Cancel Shipment Status',
      body: "Are you sure you want to revert the status of this package to 'Not Shipped'?",
      confirmText: 'Confirm',
      control: (
        <Button variant="outlined" className={classes.ctaIconButton}>
          <Icon icon="mdi:close-thick" />
        </Button>
      ),
      onSubmit: productNotDispatched,
      disabled: loading,
    },
    update: {
      title: 'Update Tracking Code',
      body: (
        <TextField
          id="tracking-code"
          placeholder="Have you got a tracking code?"
          value={trackingCode || ''}
          fullWidth
          onChange={handleChange}
        />
      ),
      confirmText: 'Update Shipment',
      control: (
        <Button
          variant="contained"
          className={classes.ctaUpdateButton}
          color="primary"
          onClick={startDispatch}
        >
          Update <Icon icon="mdi:pencil" />
        </Button>
      ),
      onSubmit: productDispatched,
      disabled: loading,
    },
  };

  let actionButtonOne: PromptConfig | null = null;
  let actionButtonTwo: PromptConfig | null = null;
  if (!isInTransit) {
    actionButtonOne = promptConfigurations.shipment;
  } else {
    actionButtonOne = promptConfigurations.update;
    actionButtonTwo = promptConfigurations.revert;
  }

  const trackingLink =
    influencerOffer.trackingCode &&
    `${TRACKING_SERVICE}${encodeURIComponent(influencerOffer.trackingCode)}`;

  return (
    <TableZebraRow aria-label={ariaLabel}>
      <TableCell component="th" scope="influencer">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <Avatar src={influencerOffer.influencer?.profilePicture ?? defaultAvatar} />
          </Grid>
          <Grid item>
            <a
              href={`/influencers/${influencerOffer.influencer?.id}`}
              className={classes.tableHeaderBold}
              aria-label="Influencer Profile Link"
            >
              {influencerOffer.influencer?.tiktokAccount
                ? influencerOffer.influencer?.tiktokAccount.username ||
                  influencerOffer.influencer.instagramAccount?.username
                : influencerOffer.influencer?.fullName}
            </a>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">
        {!influencerOffer.addressMissing ? (
          <FormattedAddress
            className={classes.address}
            address={{
              name: influencerOffer.influencer?.fullName,
              ...influencerOffer.influencer?.address,
            }}
          />
        ) : (
          'Waiting for confirmation'
        )}
      </TableCell>
      <TableCell align="left">
        {influencerOffer.deliverables?.map(deliverable => (
          <React.Fragment key={deliverable?.id}>
            {deliverable?.gig && deliverable.gig.id ? (
              <a
                href={`/gigs/${deliverable.gig.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.deliverable}
              >
                <Icon
                  icon={
                    deliverable.post?.postType === 'tiktok'
                      ? socialIcons.tiktok
                      : socialIcons.instagram
                  }
                  width={20}
                />
                <strong>
                  {deliverable.post?.postType && getPostTypeFormatted(deliverable.post.postType)}
                </strong>
              </a>
            ) : (
              <span className={classes.deliverable}>
                <Icon icon={socialIcons[deliverable?.post?.postType ?? '']} width={20} />
                {deliverable?.post?.postType && getPostTypeFormatted(deliverable.post.postType)}
              </span>
            )}
          </React.Fragment>
        ))}
      </TableCell>
      <TableCell align="center">
        {influencerOffer.inTransit ? (
          <Icon icon="mdi:check-circle" className={classes.shippedIcon} width={24} />
        ) : (
          <Icon icon="mdi:dots-horizontal-circle" className={classes.pendingIcon} width={24} />
        )}
      </TableCell>
      <TableCell align="center">
        {trackingCode && trackingCode.length > 1 ? (
          <a
            href={trackingLink}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.tableHeaderBold}
          >
            {influencerOffer.trackingCode}
          </a>
        ) : (
          <span className={classes.tableHeaderBold}>-</span>
        )}
      </TableCell>
      <TableCell align="center" className={classes.trackingCode}>
        {actionButtonOne && (
          <Prompt
            title={actionButtonOne.title}
            body={actionButtonOne.body}
            confirmText={actionButtonOne.confirmText}
            control={actionButtonOne.control}
            disabled={actionButtonOne.disabled || false}
            fullWidth={actionButtonOne.fullWidth || true}
            onSubmit={actionButtonOne.onSubmit}
          />
        )}
        {'  '}
        {actionButtonTwo && (
          <Prompt
            title={actionButtonTwo.title}
            body={actionButtonTwo.body}
            confirmText={actionButtonTwo.confirmText}
            control={actionButtonTwo.control}
            disabled={actionButtonTwo.disabled || false}
            fullWidth={actionButtonTwo.fullWidth || true}
            onSubmit={actionButtonTwo.onSubmit}
          />
        )}
      </TableCell>
    </TableZebraRow>
  );
};

export default ShippingTableRow;
