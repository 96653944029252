import compose from 'lodash/flowRight';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FetchResult, gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import * as qs from 'query-string';

import {Button, Prompt} from 'components/Widgets';

import {singularOrPlural} from 'consts/utilities';

import styles from './style.css';
import {CampaignParticipantsQuery} from '../../queries';

type PromoteButtonProps = {
  /* eslint-disable @typescript-eslint/ban-types */
  /**
   * The following types are copied from the result of the HOC. I'm hoping that
   * by converting this to a functional component we'll be able to improve these
   * by using the result of `useQuery` and `useMutation`.
   */
  promoteSelectedToCandidate: Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  promoteSelectedToAccepted: Promise<FetchResult<{}, Record<string, any>, Record<string, any>>>;
  /* eslint-enable @typescript-eslint/ban-types */
  brandMatch: boolean;
  count: number;
} & RouteComponentProps;

const PromoteButton: React.FC<PromoteButtonProps> = ({
  location,
  promoteSelectedToCandidate,
  promoteSelectedToAccepted,
  count,
  brandMatch,
}) => {
  const params = qs.parse(location.search);
  switch (params.state) {
    case 'requested': {
      if (brandMatch) {
        return (
          <div className={styles.promote}>
            <Prompt
              title={`Send ${count} ${singularOrPlural('influencer', count)} to brand?`}
              body={`Are you sure you want to send ${count} ${singularOrPlural(
                'influencer',
                count
              )} to the brand to review`}
              confirmText={`Send ${singularOrPlural('influencer', count)} to brand`}
              control={<Button text="Send influencers to brand&hellip;" disabled={count === 0} />}
              onSubmit={promoteSelectedToCandidate}
            />
          </div>
        );
      }

      return (
        <div className={styles.promote}>
          <Prompt
            title={`Add ${count} ${singularOrPlural('influencer', count)} to campaign?`}
            body={
              <div>
                <p>Are you sure you want to add these influencers to the campaign?</p>
                <p>The influencers will be notified that they've been accepted</p>
              </div>
            }
            confirmText={`Add ${singularOrPlural('influencer', count)} to campaign`}
            control={<Button text="Add influencers to campaign&hellip;" disabled={count === 0} />}
            onSubmit={promoteSelectedToAccepted}
          />
        </div>
      );
    }
    case 'approved_by_brand':
      return (
        <div className={styles.promote}>
          <Prompt
            title={`Add ${count} ${singularOrPlural('influencer', count)} to campaign?`}
            body={
              <div>
                <p>Are you sure you want to add these influencers to the campaign?</p>
                <p>The influencers will be notified that they've been accepted</p>
              </div>
            }
            confirmText={`Add ${singularOrPlural('influencer', count)} to campaign`}
            control={<Button text="Add influencers to campaign&hellip;" disabled={count === 0} />}
            onSubmit={promoteSelectedToAccepted}
          />
        </div>
      );
    default:
      return null;
  }
};

const PromoteSelectedToCandidateMutation = gql`
  mutation PromoteSelectedToCandidateMutation($campaignId: UUIDString!) {
    promoteSelectedToCandidate(campaignId: $campaignId) {
      ok
      offers {
        id
        state
        isSelected
      }
      campaign {
        id
        participation {
          type
          count
        }
      }
    }
  }
`;

const PromoteSelectedToAcceptedMutation = gql`
  mutation PromoteSelectedToAcceptedMutation($campaignId: UUIDString!) {
    promoteSelectedToAccepted(campaignId: $campaignId) {
      ok
      offers {
        id
        state
        isSelected
      }
      campaign {
        id
        participation {
          type
          count
        }
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(PromoteSelectedToCandidateMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
      },
    }) => ({
      promoteSelectedToCandidate: () =>
        mutate({
          variables: {campaignId: params.campaign},
          refetchQueries: ['OffersCandidatesQuery', 'BrandMatchQuery'],
        }),
    }),
  }),
  graphql(PromoteSelectedToAcceptedMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
      },
    }) => ({
      promoteSelectedToAccepted: () =>
        mutate({
          variables: {campaignId: params.campaign},
          refetchQueries: [
            {
              query: CampaignParticipantsQuery,
              variables: {campaignId: params.campaign, state: 'accepted'},
            },
            'OffersApprovedByBrandQuery',
          ],
        }),
    }),
  })
)(PromoteButton);
