import React from 'react';

const CalendarIcon: React.FC = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3333 2.00002H11.6667V0.666687H10.3333V2.00002H3.66666V0.666687H2.33333V2.00002H1.66666C0.933328 2.00002 0.333328 2.60002 0.333328 3.33335V14C0.333328 14.7334 0.933328 15.3334 1.66666 15.3334H12.3333C13.0667 15.3334 13.6667 14.7334 13.6667 14V3.33335C13.6667 2.60002 13.0667 2.00002 12.3333 2.00002ZM12.3333 14H1.66666V6.66669H12.3333V14ZM12.3333 5.33335H1.66666V3.33335H12.3333V5.33335Z"
      fill="black"
    />
  </svg>
);

export default CalendarIcon;
