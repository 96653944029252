// eslint-disable-next-line import/named
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {black, fontFamily, purple} from '../../../../../../consts/brand.integration';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '32px',
    },
    caption: {
      fontSize: 14,
      fontFamily: fontFamily,
      color: black,
    },
    collapsed: {
      display: 'none',
    },
    highlight: {
      color: purple,
    },
    expandButton: {
      color: black,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    treeButton: {
      fontSize: 14,
      color: black,
      backgroundColor: 'transparent',
      border: '0',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      display: 'inline-flex',
      cursor: 'pointer',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: `${theme.palette['stats-general']}20`,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        verticalAlign: 'middle',
        marginLeft: '16px',
        marginRight: '8px',
      },
    },
    treeButtonChecked: {
      backgroundColor: `${theme.palette['stats-general']}20`,
    },
  })
);
