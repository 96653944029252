import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {ProfilePicture, FormattedDate} from 'components/Widgets';

import {sanitizeWhitespace} from 'consts/utilities';

import styles from './style.css';

const formatEventTitle = event => {
  const formattedUser = sanitizeWhitespace(event.user?.fullName, 'System');

  switch (event.type) {
    case 'signup':
      return 'Signed up';
    case 'activate':
      return `Activated by ${formattedUser}`;
    case 'comment':
      return `Comment by ${formattedUser}`;
    case 'disable':
      return `Disabled by ${formattedUser}`;
    case 'verify':
      return `Verified by ${formattedUser}`;
    case 'unverify':
      return `Unverified by ${formattedUser}`;

    // gig
    case 'gig-reported':
      return 'Gig reported';
    case 'gig-report':
      return 'Gig reported';
    case 'gig-reject':
      return 'Gig rejected';
    case 'gig-dismiss_report':
      return 'Gig report dismissed';
    case 'gig-request_resubmission':
      return 'Gig resubmission requested';
    case 'gig-review':
      return 'Gig reviewed';
    case 'gig-approve':
      return 'Gig approved';
    case 'gig-submit':
      return 'Gig submitted';

    case 'send-instagram-direct-message':
      return `Sent Instagram DM TAKUMI PN by ${formattedUser}`;
    case 'send-email':
      return 'Sent email';
    case 'review':
      return 'Reviewed';

    // offer
    case 'send_push_notification':
      return 'Notified';
    case 'reserve':
      return 'Reserved';
    case 'revoke':
      return 'Revoked';
    case 'reject':
      return 'Rejected';

    default:
      return event.type;
  }
};

const InfluencerEvent = ({event}) => (
  <div key={event.created + event.type} className={styles.event}>
    <ProfilePicture className={styles.profilePicture} src={event.user?.profilePicture} size={40} />

    <div>
      <div className={styles.summary}>
        {formatEventTitle(event)}
        <span className={styles.date}>
          <FormattedDate date={event.created} />
        </span>
      </div>

      <p className={classNames([styles.eventText, styles[`text-${event.type}`]])}>{event.text}</p>
    </div>
  </div>
);

InfluencerEvent.propTypes = {
  event: PropTypes.object,
};

export default InfluencerEvent;
