import React from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Prompt} from 'components/Widgets';

interface Props {
  offer: Offer;
  campaign: Campaign;
  disabled?: boolean;
}

const ForceReserveButton: React.FC<Props> = ({offer, campaign, disabled}) => {
  const [forceReserve] = useMutation(ForceReserveMutation);

  const buttonText = campaign.applyFirst ? 'Force interested' : 'Force reserve';
  const body = campaign.applyFirst
    ? 'Are you sure you want to force this influencer to be interested?'
    : 'Are you sure you want to force this influencer to be reserved?';

  return (
    <Prompt
      title={buttonText}
      body={body}
      confirmText={buttonText}
      control={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="mdi:bell" />}
          disabled={disabled}
          style={{margin: '3px'}}
        >
          {buttonText}
        </Button>
      }
      onSubmit={() => forceReserve({variables: {id: offer.id}})}
    />
  );
};

const ForceReserveMutation = gql`
  mutation ForceReserveMutation($id: UUIDString!) {
    forceReserveOffer(id: $id) {
      ok
      offer {
        id
        state
        formattedState
      }
    }
  }
`;

export default ForceReserveButton;
