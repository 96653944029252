import React from 'react';
import {Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import PaidSummaryMetrics from '../../Campaign/components/CampaignPerformance/PaidSummaryData/PaidSummaryMetrics';
import {PerformanceData} from '../../Campaign/components/CampaignPerformance/types';

interface Props {
  metrics: PerformanceData[];
}

const PaidMediaMetrics: React.FC<Props> = ({metrics}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Paid Media Summary
      </Typography>
      <Divider className={classes.spacing} />
      <Grid
        container
        spacing={3}
        aria-label="Aggregate Paid Media Performance"
        className={classes.spacing}
      >
        <PaidSummaryMetrics summaryMetrics={metrics} modelName="paid_performance" />
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default PaidMediaMetrics;
