import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import Highlighter from 'react-highlight-words';

import {useStyles} from '../TreeSelector/style';
import {TreeNode, TreeViewEntity} from '../../../types';
import {ENTER_KEY_CODE} from '../../../../../../consts/variables';

type PropsTypes = {
  tree: TreeNode<TreeViewEntity> | null;
  searchString?: string;
  searchPredicate?: (node: TreeNode<TreeViewEntity>) => boolean;
  onSelectionChanged: (node: TreeNode<TreeViewEntity>, checked: boolean) => void;
  onExpansionChanged: (node: TreeNode<TreeViewEntity>, expanded: boolean) => void;
  setIsMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const TreeSelector: React.FC<PropsTypes> = ({
  tree,
  searchString = '',
  searchPredicate,
  onSelectionChanged,
  onExpansionChanged,
  setIsMenuOpened,
}: PropsTypes) => {
  const classes = useStyles();
  const currentSearchPredicate = searchPredicate ?? (() => true);
  if (!tree) {
    return null;
  }

  const renderTree = (node: TreeNode<TreeViewEntity>, isRoot = false) => {
    const isNodeVisible = currentSearchPredicate(node);
    const visibleChildren = node.children.filter(
      child => isRoot || isNodeMatchesSearch(child, currentSearchPredicate)
    );
    const shouldExpandNode =
      !!visibleChildren.length && (node.expanded || !!searchPredicate || isRoot);

    if (!isNodeVisible && !visibleChildren.length) {
      return null;
    }

    return (
      <div key={node.id}>
        {!isRoot && (
          <div className={classes.container}>
            {node.selectable ? (
              <button
                className={
                  node.checked
                    ? `${classes.treeButton} ${classes.treeButtonChecked}`
                    : classes.treeButton
                }
                style={{paddingLeft: '40px'}}
                aria-label="parent industry"
                type="button"
                onClick={() => onSelectionChanged(node, !node.checked)}
                onKeyDown={event => {
                  if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
                    event.preventDefault();
                    onSelectionChanged(node, !node.checked);
                    setIsMenuOpened(false);
                    document.getElementById('industryControlId')?.focus();
                  }
                }}
              >
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchString]}
                  autoEscape
                  highlightTag="span"
                  textToHighlight={node.label}
                  className={classes.caption}
                />
                {node.checked && <CheckIcon />}
              </button>
            ) : (
              <button
                className={classes.treeButton}
                aria-label="sub industry"
                type="button"
                onClick={() => onExpansionChanged(node, !node.expanded)}
                onKeyDown={event => {
                  if (event.key === ENTER_KEY_CODE && !event.shiftKey) {
                    event.preventDefault();
                    onExpansionChanged(node, !node.expanded);
                  }
                }}
              >
                {node.expanded ? <ChevronRightIcon /> : <ExpandMoreIcon />}
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchString]}
                  autoEscape
                  highlightTag="span"
                  textToHighlight={node.label}
                  className={classes.caption}
                />
                {node.checked && <CheckIcon />}
              </button>
            )}
          </div>
        )}
        {shouldExpandNode && <div>{visibleChildren.map(child => renderTree(child, false))}</div>}
      </div>
    );
  };

  return renderTree(tree, true);
};

export default React.memo(TreeSelector);

function isNodeMatchesSearch(
  node: TreeNode<TreeViewEntity>,
  searchPredicate: (node: TreeNode<TreeViewEntity>) => boolean
): boolean {
  if (searchPredicate(node)) {
    return true;
  }
  return node.children.some(child => isNodeMatchesSearch(child, searchPredicate));
}
