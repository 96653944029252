import React, {useRef} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Button, DialogContentText, FormControl, Input, InputLabel} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {Gig} from 'services/fragments';

import {Modal} from '../../../Widgets';
import {ModalElement} from '../../../Widgets/Modal';
import {getSocialPlatformNameByPostType} from '../../../../utils/gigUtils';

interface Props {
  gig: Gig;
}

const GigLinkToPlatform: React.FC<Props> = ({gig}) => {
  const [linkGig] = useMutation(LinkGigMutation, {refetchQueries: ['PostQuery', 'GigQuery']});
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema: yup.object({
      url: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    linkGig({variables: {id: gig.id, url: formik.values.url}});
    modal.current?.close();
  };

  const renderForm = () => (
    <DialogContentText>
      <FormControl fullWidth>
        <InputLabel htmlFor="url">URL</InputLabel>
        <Input
          id="url"
          name="url"
          type="text"
          value={formik.values.url}
          aria-describedby={'url'}
          error={formik.touched.url}
          required
          onChange={formik.handleChange}
        />
      </FormControl>
    </DialogContentText>
  );

  return (
    <Modal
      ref={modal}
      id="link-to-platform"
      title={`Link to ${getSocialPlatformNameByPostType(gig.post?.postType!)}`}
      modalToggler={
        <Button variant="text" fullWidth>
          {`Link to ${getSocialPlatformNameByPostType(gig.post?.postType!)}`}
        </Button>
      }
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
    >
      {renderForm()}
    </Modal>
  );
};

const LinkGigMutation = gql`
  mutation LinkGigMutation($id: UUIDString!, $url: String) {
    linkGig(id: $id, url: $url) {
      ok
      gig {
        id
        state
        instagramContent {
          __typename
          id
          created
          followers
          posted
          media {
            ...mediaResultFields
          }
          ... on InstagramPost {
            link
            caption
            mentions
            hashtags
            likes
            comments
            videoViews
            engagement {
              formattedValue
            }
          }
        }
      }
    }
  }
  ${Gig.mediaPreview}
`;

export default GigLinkToPlatform;
