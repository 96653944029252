import {useQuery} from '@apollo/client';

import {AdvertiserConfig} from 'scenes/Advertiser/AdvertiserAccessSettings/types';

import {EditProfileQuery} from '../scenes/EditProfile/graphqlQuery';
import useAdvertiserAccess from '../scenes/Advertiser/hooks/useAdvertiserAccess';
import {UserRoles} from '../consts/roles';

const useHomePage = (skip = false): string => {
  const {data: userData} = useQuery(EditProfileQuery, {skip: skip});
  const advertiserAccess: AdvertiserConfig = useAdvertiserAccess(skip);
  const defaultHomePage = '/brands';
  const emptyResult = '';

  if (!userData) {
    return emptyResult;
  }

  if (userData.user?.roleName === UserRoles.Advertiser) {
    if (!advertiserAccess) {
      return emptyResult;
    }
    if (advertiserAccess.brandCampaignsPage) {
      return '/campaigns';
    } else if (advertiserAccess.dashboardPage) {
      return '/dashboard';
    } else {
      return '/user';
    }
  }

  return defaultHomePage;
};

export default useHomePage;
