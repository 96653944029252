import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';

export const ColumnFilter = ({children}) => <div className={styles.column}>{children}</div>;

ColumnFilter.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ColumnHeader = ({header}) => <div className={styles.columnHeader}>{header}</div>;

ColumnHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export {default as CheckboxFilter} from './CheckboxFilter';
export {default as DateFilter} from './DateFilter';
export {default as FilterWell} from './FilterWell';
export {default as RadioButtonFilter} from './RadioButtonFilter';
export {default as RangeFilter} from './RangeFilter';
export {default as ResetButtonFilter} from './ResetButtonFilter';
