import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

import {DocumentTitle} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';
import {resetPassword} from 'services/api';

import {Nav, RecoverPasswordEmailForm} from '../components';
import styles from './style.css';

class RecoverPassword extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      pending: false,
    };
  }

  onSubmit = async ({email}) => {
    if (email) {
      try {
        this.setState({pending: true});
        const response = await resetPassword(email);
        this.setState({message: response.data.message});
      } catch (error) {
        this.setState({message: error.message});
      } finally {
        this.setState({pending: false});
      }
    }
  };

  render() {
    const {message} = this.state;

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle('Password recovery')} />
        <Nav />

        <div className={styles.center}>
          <RecoverPasswordEmailForm pending={this.state.pending} onSubmit={this.onSubmit} />
          {message && <p>{message}</p>}
        </div>
      </div>
    );
  }
}

export default RecoverPassword;
