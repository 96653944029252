import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {Platforms, PlatformsConfig} from 'consts/platforms';
import {numberMillified} from 'consts/utilities';

import PlatformWithLabel, {PlatformWithLabelSize} from '../PlatformWithLabel/PlatformWithLabel';

interface Props {
  influencer: Influencer;
  size?: PlatformWithLabelSize;
  showFollowers?: boolean;
}

const InfluencerHandles: React.FC<Props> = ({influencer, size, showFollowers}) => {
  const classes = useStyles();

  const makeToolTipFor = (platform: Platforms) => {
    const pltfrm = PlatformsConfig[platform].name;
    let handle = '';
    let followers = 0;

    if (platform === Platforms.Instagram) {
      handle = influencer.instagramAccount?.username ?? '';
      followers = influencer.instagramAccount?.followers ?? 0;
    } else if (platform === Platforms.TikTok) {
      handle = influencer.tiktokAccount?.username ?? '';
      followers = influencer.tiktokAccount?.followers ?? 0;
    } else if (platform === Platforms.YouTube) {
      handle = influencer.youtubeChannel?.customUrl?.split('/').pop() ?? '';
      followers = influencer.youtubeChannel?.subscriberCount ?? 0;
    } else if (platform === Platforms.Twitch) {
      handle = influencer.twitchChannel?.url?.split('/').pop() ?? '';
      followers = influencer.twitchChannel?.followers ?? 0;
    } else if (platform === Platforms.Snapchat) {
      handle = influencer.snapchatAccount?.displayName ?? '';
      followers = 0;
    }

    return `${pltfrm} | ${handle}${
      showFollowers
        ? ` | ${numberMillified(followers)} ${
            platform === Platforms.YouTube ? 'subscribers' : 'followers'
          }`
        : ''
    }`;
  };

  return (
    <Grid container direction="row">
      {influencer.instagramAccount?.username && (
        <Grid item className={classes.platform}>
          <PlatformWithLabel
            platform="instagram"
            tooltip={makeToolTipFor(Platforms.Instagram)}
            label={showFollowers && numberMillified(influencer.instagramAccount.followers ?? 0)}
            size={size}
          />
        </Grid>
      )}
      {influencer.tiktokAccount?.username && (
        <Grid item className={classes.platform}>
          <PlatformWithLabel
            platform="tiktok"
            tooltip={makeToolTipFor(Platforms.TikTok)}
            label={showFollowers && numberMillified(influencer.tiktokAccount.followers ?? 0)}
            size={size}
          />
        </Grid>
      )}
      {influencer.youtubeChannel && (
        <Grid item className={classes.platform}>
          <PlatformWithLabel
            platform="youtube"
            tooltip={makeToolTipFor(Platforms.YouTube)}
            label={showFollowers && numberMillified(influencer.youtubeChannel.subscriberCount ?? 0)}
            size={size}
          />
        </Grid>
      )}
      {influencer.twitchChannel && (
        <Grid item className={classes.platform}>
          <PlatformWithLabel
            platform="twitch"
            tooltip={makeToolTipFor(Platforms.Twitch)}
            label={showFollowers && numberMillified(influencer.twitchChannel.followers ?? 0)}
            size={size}
          />
        </Grid>
      )}
      {influencer.snapchatAccount && (
        <Grid item className={classes.platform}>
          <PlatformWithLabel
            platform="snapchat"
            tooltip={makeToolTipFor(Platforms.Snapchat)}
            label={showFollowers && numberMillified(0)}
            size={size}
          />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles({
  platform: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      margin: '0 0 0 .5rem',
      padding: '0 0 0 .5rem',
      borderLeft: `1px solid lightgray`,
    },
  },
});

export default InfluencerHandles;
