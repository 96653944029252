import compose from 'lodash/flowRight';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import {createStyles, Grid, makeStyles} from '@material-ui/core';

import {MaterialDesignIcon} from 'components/Icons';
import {HighlightedButton} from 'components/Widgets';

import {interestsItems} from '../../../../consts/targetingValues';
import {NameValueMetaObj} from '../../../../consts/utilities';

interface Props {
  influencer: Influencer;
  updateInfluencer: (id: string, nextInterests: string[]) => void;
}

const InfluencerInterests: React.FC<Props> = ({influencer, updateInfluencer}) => {
  const classes = useStyles();

  const handleToggleInterest = (selectedIds: string[], toggledId: string) => {
    const hasInterest = selectedIds.some((id: string) => id === toggledId);
    const nextInterests = hasInterest
      ? selectedIds.filter((value: string) => value !== toggledId)
      : [...selectedIds, toggledId];

    updateInfluencer(influencer.id, nextInterests);
  };

  const isSelected = (currentInterest: NameValueMetaObj) => {
    return influencer.interests?.some(interest => interest?.id === currentInterest.value);
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      direction="row"
      spacing={1}
    >
      {interestsItems.map((item, index) => (
        <Grid key={index} item>
          <HighlightedButton
            key={item.value}
            selected={isSelected(item)}
            handleOnClick={() =>
              handleToggleInterest(
                influencer.interests?.map(interest => interest?.id),
                item.value
              )
            }
            text={[<MaterialDesignIcon key={item.meta} icon={item.meta} />, item.name]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  })
);

export default compose(
  graphql(
    gql`
      mutation updateInfluencer($id: UUIDString!, $interestIds: [UUIDString]) {
        updateInfluencer(id: $id, interestIds: $interestIds) {
          ok
          influencer {
            id
            interests {
              id
            }
          }
        }
      }
    `,
    {
      props: ({mutate}) => ({
        updateInfluencer: (id, interests) =>
          mutate({
            variables: {
              id,
              interestIds: interests,
            },
            optimisticResponse: {
              updateInfluencer: {
                ok: true,
                influencer: {
                  id,
                  interests,
                  __typename: 'Influencer',
                },
              },
            },
          }),
      }),
    }
  )
)(InfluencerInterests);
