import React from 'react';
import classNames from 'classnames';

import {useStyles} from './styles';

interface Props {
  value?: React.ReactNode;
  label?: React.ReactNode | string;
  labelId?: string;
  centered?: boolean;
  isSub?: boolean;
  noMargin?: boolean;
  withIcon?: boolean;
}

const KeyValue: React.FC<Props> = ({
  value,
  label,
  labelId,
  centered,
  isSub,
  noMargin,
  withIcon,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, {
        [classes.centered]: centered,
        [classes.noMargin]: noMargin,
        [classes.isSub]: isSub,
        [classes.withIcon]: withIcon,
      })}
    >
      <label
        id={labelId}
        className={classNames(classes.label, {
          [classes.centeredLabel]: centered,
          [classes.noMargin]: withIcon,
        })}
      >
        {label}
      </label>
      {value === undefined ? (
        <div
          className={classNames(classes.noValue, {
            [classes.centeredNoValue]: centered,
          })}
        >
          -
        </div>
      ) : (
        <div
          className={classNames(classes.value, {
            [classes.centeredValue]: centered,
          })}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default KeyValue;
