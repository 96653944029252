import {
  numberMillified,
  numberWithCommas,
  formatPercentage,
  formatCurrency,
  formatFraction,
} from '../../../../consts/utilities';

type MetricInfo = {
  [key: string]: {
    name: string;
    description: string;
    formatter?: (value: number, currencyCode?: string) => string;
  };
};

type ModelInfo = {
  [key: string]: MetricInfo;
};

export const DATA_SERVICE_MODEL_INFO: ModelInfo = {
  campaign_performance: {
    influencer_count: {
      name: 'Creators',
      description: 'The number of influencers involved in the campaign.',
      formatter: numberMillified,
    },
    post_count: {
      name: 'Posts',
      description: 'The total number of social media posts for this campaign.',
      formatter: numberMillified,
    },
    digital_content_count: {
      name: 'Assets',
      description: 'The number of digital content assets for this campaign.',
      formatter: numberMillified,
    },
    impression_total: {
      name: 'Impressions',
      description: 'The total number of impressions generated by the campaign.',
      formatter: numberMillified,
    },
    reach_total: {
      name: 'Reach',
      description:
        'The total reach of the campaign. This is the total amount of unique users that have seen each post.',
      formatter: numberMillified,
    },
    engagement_total: {
      name: 'Engagements',
      description:
        'The total number of engagements generated for the campaign by all live social media content.',
      formatter: numberMillified,
    },
    engagement_rate_by_impressions: {
      name: 'Engagement Rate',
      description: 'Engagement rate based on impressions.',
      formatter: formatPercentage,
    },
    engagement_rate_by_followers: {
      name: 'Engagement Rate',
      description: 'Engagement rate based on followers.',
      formatter: formatPercentage,
    },
    follower_total: {
      name: 'Followers',
      description: 'The total number of followers for all influencers on the campaign.',
      formatter: numberMillified,
    },
    paid_media_click_through_rate_average: {
      name: 'Paid CTR (avg)',
      description:
        'The average click-through rate (CTR) for paid media associated with this campaign.',
      formatter: formatPercentage,
    },
    paid_media_cost_per_1000_reached_average: {
      name: 'Paid CPM',
      description: 'The average cost per 1000 individuals reached.',
      formatter: formatCurrency,
    },
    paid_media_cost_per_engagement_average: {
      name: 'Paid CPE',
      description:
        'The average cost per engagement (CPE) in cents/pennies for this campaign for paid media.',
      formatter: formatCurrency,
    },
    paid_media_view_through_rate_average: {
      name: 'Paid VTR',
      description:
        'The average view-through rate (VTR) for paid media associated with this campaign.',
      formatter: formatFraction,
    },
    organic_media_cost_per_engagement_average: {
      name: 'Organic CPE',
      description:
        'The average cost per engagement (CPE) in cents/pennies for this campaign for organic media.',
      formatter: formatCurrency,
    },
    social_media_platform: {name: 'Social Platform', description: ''},
  },
  paid_performance: {
    impression_total: {
      name: 'Impressions',
      description: 'The total number of impressions generated by the campaign.',
      formatter: numberMillified,
    },
    impression_cumulative_total: {
      name: 'Impressions',
      description: 'The total number of impressions generated by the campaign.',
      formatter: numberMillified,
    },
    reach_total: {
      name: 'Reach',
      description:
        'The total reach of the campaign. This is the total amount of unique users that have seen each post.',
      formatter: numberMillified,
    },
    engagement_total: {
      name: 'Engagements',
      description:
        'Engagements are calculated as the sum of likes, shares, comments, follows, and clicks.',
      formatter: numberMillified,
    },
    engagement_cumulative_total: {
      name: 'Engagements',
      description:
        'Engagements are calculated as the sum of likes, shares, comments, follows, and clicks.',
      formatter: numberMillified,
    },
    video_play_cumulative_total: {
      name: 'Views',
      description:
        'The total number of views generated for the campaign by all live social media content.',
      formatter: numberMillified,
    },
    video_view_2_second_cumulative_total: {
      name: 'Views',
      description:
        'The total number of views generated for the campaign by all live social media content.',
      formatter: numberMillified,
    },
    video_view_3_second_cumulative_total: {
      name: 'Views',
      description:
        'The total number of views generated for the campaign by all live social media content.',
      formatter: numberMillified,
    },
    cost_per_1000_impressions_average: {
      name: 'CPM',
      description: 'Average Cost per 1000 impressions (CPM) for the ads.',
      formatter: formatCurrency,
    },
    engagement_rate_by_impressions_average: {
      name: 'ER%',
      description: 'Engagement rate percentage, by impressions',
      formatter: formatPercentage,
    },
    click_through_rate_average: {
      name: 'CTR%',
      description: 'Average click through rate (CTR) for paid ads.',
      formatter: formatPercentage,
    },
    view_through_rate_average: {
      name: 'VTR%',
      description: 'Average view through rate (VTR) for paid ads.',
      formatter: formatFraction,
    },
    click_through_rate_lifetime_average: {
      name: 'CTR%',
      description: 'Average click through rate (CTR) for paid ads.',
      formatter: formatPercentage,
    },
    view_through_rate_lifetime_average: {
      name: 'VTR%',
      description: 'Average view through rate (VTR) for paid ads.',
      formatter: formatPercentage,
    },
  },
};

export function formattedMetrics(
  metricName: string,
  metricValue: any,
  modelName: string,
  currency?: string
) {
  const metricInfo = DATA_SERVICE_MODEL_INFO[modelName][metricName];
  if (!metricInfo) return null;

  const {name, description, formatter} = metricInfo;
  const formattedValue = formatter
    ? formatter(metricValue, currency)
    : numberWithCommas(metricValue);

  return {metricDisplayName: name, metricDescription: description, formattedValue};
}

export function arrowDirection(changeValue: number): 'up' | 'down' | 'neutral' {
  return changeValue > 0 ? 'up' : changeValue < 0 ? 'down' : 'neutral';
}

export function formattedChangeMetrics(value: number): string {
  return value === 0 ? '' : `${value > 0 ? '+' : ''}${numberWithCommas(value)}`;
}

/**
 * These are lists of values that could exist in the `organicPerformanceMetrics`
 * and `paidPerformanceMetrics` (respectively) for a Campaign.
 *
 * NOTE: It's important to remember that the API allows any value and has no
 * validation for these fields. Therefore, before removing a value from one of
 * these lists, you should confirm there are no Campaigns in the API containing
 * the value in the relevant list.
 */
export const performanceSettingsValues = {
  organic: [
    'influencer_count',
    'post_count',
    'digital_content_count',
    'impression_total',
    'reach_total',
    'engagement_total',
    'engagement_rate_by_impressions',
    'follower_total',
  ],
  paid: [
    'impression_total',
    'engagement_total',
    'reach_total',
    'video_play_cumulative_total',
    'cost_per_1000_impressions_average',
    'click_through_rate_average',
    'view_through_rate_average',
  ] as const,
} as const;

export const shouldDisplayMetric = (
  enabledMetrics: string[] = [],
  requiredMetric:
    | (typeof performanceSettingsValues)['organic'][number]
    | (typeof performanceSettingsValues)['paid'][number]
    | string
) => {
  if (!enabledMetrics.length) return true;
  return enabledMetrics.includes(requiredMetric);
};
