import {makeStyles} from '@material-ui/core/styles';

import * as consts from '../../../consts/brand.integration';

// Also allowed to the class components
export const useDefaultGlobalStyles = {
  h3Link: {
    textDecoration: 'underline',
  },
  body1Link: {
    fontWeight: consts.semiBold,
    fontSize: consts.sizeBody1,
    lineHeight: consts.lineHeightBig,
    textDecoration: 'underline',
  },
  body2Link: {
    textDecoration: 'underline',
  },
  body2SemiBold: {
    fontWeight: consts.semiBold,
  },
  large: {
    fontWeight: consts.semiBold,
    fontSize: consts.sizeLarge,
    lineHeight: consts.lineHeightLargest,
  },
  largeLink: {
    fontSize: consts.sizeLarge,
    lineHeight: consts.lineHeightLargest,
    textDecoration: 'underline',
  },
  captionLink: {
    textDecoration: 'underline',
  },
  captionBoldLink: {
    textDecoration: 'underline',
    fontWeight: consts.bold,
  },
  quotation: {
    fontStyle: 'italic',
    fontSize: consts.sizeLarge,
    lineHeight: consts.lineHeightLarge,
  },
  label: {
    fontSize: consts.sizeBody2,
    fontWeight: consts.semiBold,
    lineHeight: consts.lineHeightSmall,
  },
};
// Hook
export const useGlobalStyles = makeStyles(useDefaultGlobalStyles);
