import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

import {DocumentTitle, LoaderDots} from 'components/Widgets';

import App404 from 'scenes/App404/App404';
import {getPageTitle} from 'consts/utilities';

import {Report, ReportGigs} from './components';
import styles from './style.css';
import {Gig} from '../../../services/fragments';

class CampaignReport extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    error: PropTypes.object,
  };

  render() {
    const {report, error} = this.props;

    if (!report) {
      return (
        <div className={styles.loaderWrapper}>
          <LoaderDots />
        </div>
      );
    }

    if (error) {
      return <App404 />;
    }

    const title = `${report.campaign.advertiser.name} - ${report.campaign.name} - Report`;

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle(title)} />

        <Report campaign={report.campaign} />

        <h2 className={styles.heading}>Posts</h2>
        {report.campaign.posts.map((post, i) => (
          <ReportGigs key={post.id} index={i + 1} post={post} />
        ))}
      </div>
    );
  }
}

const mapStatetoProps = (state, {match: {params}}) => ({
  token: params.reportTokenId,
});

const CampaignPostsQuery = gql`
  query ReportQuery($token: UUIDString!) {
    report(token: $token) {
      campaign {
        id
        name
        rewardModel
        reportToken
        reportSummary
        totalSubmissions
        engagements
        engagement {
          formattedValue
        }
        comments
        likes
        reach
        price {
          formattedValue
        }
        hashtags {
          value
          count
        }
        emojis {
          value
          count
        }
        commentSentiment {
          formattedValue
        }
        captionSentiment {
          formattedValue
        }
        costPerEngagement {
          formattedValue
        }
        advertiser {
          id
          profilePicture
          name
        }
        posts {
          id
          opened
          deadline
          hashtags
          mention
          brief {
            type
            ... on BriefHeading {
              value
            }
            ... on BriefSubHeading {
              value
            }
            ... on BriefParagraph {
              value
            }
            ... on BriefImportant {
              value
            }
            ... on BriefDosAndDonts {
              dos
              donts
            }
            ... on BriefOrderedList {
              items
            }
            ... on BriefUnorderedList {
              items
            }
          }
          postType
          engagement {
            formattedValue
          }
          engagements
          supportsStats
          comments
          likes
          reach
          videoViews
          videoEngagement {
            formattedValue
          }
          gigs {
            id
            instagramContent {
              __typename
              id
              created
              posted
              followers
              media {
                ...mediaResultFields
              }
              ... on InstagramPost {
                caption
                hashtags
                mentions
                link
                engagement {
                  formattedValue
                }
                likes
                comments
                videoViews
              }
            }
            influencer {
              id
              profilePicture
              username
              followers
            }
          }
        }
      }
    }
  }
  ${Gig.mediaPreview}
`;

export default compose(
  withRouter,
  connect(mapStatetoProps),
  graphql(CampaignPostsQuery, {
    props: ({data: {loading, report}}) => ({
      loading,
      report,
    }),
  })
)(CampaignReport);
