import PubSub from 'pubsub-js';

import {getMessageForError} from './graphql';
import ErrorReporting from './error-reporting';
import {NetworkError, GraphqlError} from './types';

type CallbackParams = {
  message: string;
  type: string;
  timeout: number;
};

type Callback = ({message, type, timeout}: CallbackParams) => void;
class NotificationService {
  send(message: string): void {
    PubSub.publish('notifications', {
      message,
      type: 'info',
      timeout: 5000,
    });
  }

  onFormError(error: Error): void {
    const message =
      window.location.hostname === 'admin.takumi.com'
        ? error.message
        : error.stack
        ? error.stack
        : error.message;
    PubSub.publish('notifications', {
      message,
      type: 'error',
      source: 'form',
    });
  }

  onNetworkError(error: NetworkError): void {
    const message = error.networkError.statusCode
      ? `Network error: Network request failed with status ${error.networkError.statusCode}`
      : 'Unable to connect to network';

    PubSub.publish('notifications', {
      message,
      type: 'error',
      source: 'network',
    });

    ErrorReporting.captureError(error, {action: 'NetworkError'});
  }

  onGraphQLError(error: GraphqlError, context?: any) {
    const contextStr = JSON.stringify(context, (k, v) => (v === undefined ? '?undefined?' : v), 2);
    const msgSimple = getMessageForError(error);
    const msgDetailed = `${getMessageForError(error)}\noperation:\n${contextStr}`;
    const message = window.location.hostname === 'admin.takumi.com' ? msgSimple : msgDetailed;
    PubSub.publish('notifications', {
      message,
      type: 'error',
      source: 'graphql',
    });
  }

  onPromiseError() {
    PubSub.publish('notifications', {
      message: 'An unexpected error occurred. Please contact support for more info',
      type: 'error',
      source: 'promise',
    });
  }

  subscribe(cb: Callback): string {
    return PubSub.subscribe('notifications', (_: string, notification: CallbackParams) =>
      cb(notification)
    );
  }

  unsubscribe(value: string | Callback): void {
    PubSub.unsubscribe(value);
  }
}

const service = new NotificationService();

export default service;
