import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import {useStyles} from './styles';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  contentText: string;
  renderTitleIcon: () => React.ReactNode;
}

const NotifyDialog: React.FC<Props> = ({
  isOpened,
  onClose,
  title,
  contentText,
  renderTitleIcon,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpened}
      aria-labelledby="notify-dialog-title"
      classes={{root: classes.dialog}}
      onClose={onClose}
    >
      <DialogTitle disableTypography id="notify-dialog-title" classes={{root: classes.dialogTitle}}>
        {renderTitleIcon()}
        <Typography variant="h3" className={classes.typography}>
          {title}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            size="small"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        <DialogContentText className={classes.dialogContentText}>{contentText}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default NotifyDialog;
