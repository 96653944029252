import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import brandStyles from 'consts/brand';

import styles from './BigPicker.css';

const renderIcon = (Icon, enabled, small) => (
  <Icon size={small ? 30 : 50} color={enabled ? brandStyles.gold : brandStyles.black70} />
);

export const BigPicker = ({enabled, onSelected, title, id, icon, horizontal = false}) => {
  return (
    <div
      className={classNames(
        styles.root,
        horizontal && styles.horizontal,
        enabled && styles.enabled
      )}
      onClick={() => onSelected(id)}
    >
      <div>{renderIcon(icon, enabled, horizontal)}</div>
      <div className={classNames(styles.title, enabled && styles.enabledTitle)}>{title}</div>
    </div>
  );
};
BigPicker.propTypes = {
  enabled: PropTypes.bool,
  onSelected: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.func,
  huge: PropTypes.bool,
  horizontal: PropTypes.bool,
};

export class BigPickerGroup extends React.Component {
  onChange = newValue => {
    const {value, onChange, nullable} = this.props;
    if (newValue === value && nullable) onChange(null);
    else onChange(newValue);
  };

  injectProps = child => {
    if (!child) return null;
    const {value} = this.props;
    const enabled = value !== null && child.props && child.props.id === value;
    return React.cloneElement(child, {
      enabled,
      onSelected: this.onChange,
    });
  };

  render() {
    const {children} = this.props;
    return <div className={styles.tagGroup}>{React.Children.map(children, this.injectProps)}</div>;
  }
}

BigPickerGroup.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  nullable: PropTypes.bool,
};
