import React from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {Grid, LinearProgress as MuiLinearProgress, Typography} from '@material-ui/core';

import {purple, purpleBackground} from '../../../consts/brand.integration';

interface Props {
  title: string;
  value: number;
  total?: number;
}

const ProgressBar: React.FC<Props> = ({title, value = 0, total = 100}) => {
  const toPercent = (a: number, b: number) => Math.min(Math.round((a / b) * 100), 100);
  const validTotal = total > 0 ? total : 100;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color="secondary">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color="secondary" align="right">
          {`${toPercent(value, validTotal)}%`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={toPercent(value, validTotal)}
          color="primary"
        />
      </Grid>
    </Grid>
  );
};

const LinearProgress = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '.7rem',
      borderRadius: 5,
      border: `1px solid ${purpleBackground}`,
      backgroundColor: purpleBackground,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: purple,
    },
  })
)(MuiLinearProgress);

export default ProgressBar;
