import React from 'react';
import {useHistory} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment';

import {constructImgixUrl} from '../../../components/Widgets/Imgix';
import {capitalize, numberWithCommas} from '../../../consts/utilities';
import {campaignUrlFromDashboard} from '../../../consts/urls';
import {ENTER_KEY_CODE} from '../../../consts/variables';
import {formatEngagementRate} from '../utils';
import {Campaign, VisibleMetrics} from '../types';
import {notApplicable} from '../consts';
import {useStyles} from './styles';

const getStyleForImageWrapper = (url: string) => ({
  background: `url(${constructImgixUrl(url, 72, 72, 'crop')})`,
  backgroundSize: 'cover, cover',
});

interface Props {
  campaigns: Array<Campaign>;
  visibleColumns: VisibleMetrics;
}

const CampaignsTable: React.FC<Props> = ({campaigns, visibleColumns}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const goToCampaign = (campaign: Campaign) => history.push(campaignUrlFromDashboard(campaign));

  return (
    <Table className={classes.table} aria-label="campaigns table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.headCell}>
            <Typography variant="caption" className={classes.headCellTypography}>
              Campaign
            </Typography>
          </TableCell>
          <TableCell className={classes.headCell}>
            <Typography variant="caption" className={classes.headCellTypography}>
              Brand
            </Typography>
          </TableCell>
          {visibleColumns?.budget && (
            <TableCell className={classes.headCell}>
              <Typography variant="caption" className={classes.headCellTypography}>
                Budget
              </Typography>
            </TableCell>
          )}
          {visibleColumns?.type && (
            <>
              <TableCell className={classes.headCell}>
                <Typography variant="caption" className={classes.headCellTypography}>
                  Type
                </Typography>
              </TableCell>
              <TableCell className={classes.headCell}>
                <Typography variant="caption" className={classes.headCellTypography}>
                  Reach
                </Typography>
              </TableCell>
              <TableCell className={classes.headCell}>
                <Typography variant="caption" className={classes.headCellTypography}>
                  Assets
                </Typography>
              </TableCell>
            </>
          )}
          {visibleColumns?.engagementRate && (
            <TableCell className={classes.headCell}>
              <Typography variant="caption" className={classes.headCellTypography}>
                Total ER
              </Typography>
            </TableCell>
          )}
          <TableCell className={classes.headCell}>
            <Typography variant="caption" className={classes.headCellTypography}>
              Creators
            </Typography>
          </TableCell>
          <TableCell className={classes.headCell}>
            <Typography variant="caption" className={classes.headCellTypography}>
              Start Date
            </Typography>
          </TableCell>
          <TableCell className={classes.headCell}>
            <Typography variant="caption" className={classes.headCellTypography}>
              Status
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {campaigns.map(campaign => (
          <TableRow
            key={campaign.id}
            role="link"
            tabIndex={0}
            className={classes.bodyRow}
            onClick={() => goToCampaign(campaign)}
            onKeyDown={(event: KeyboardEvent) =>
              event.key === ENTER_KEY_CODE && goToCampaign(campaign)
            }
          >
            <TableCell
              className={classNames(classes.tableCell, classes.tableCellWithImage)}
              component="th"
              scope="row"
            >
              <div
                role="img"
                aria-label={campaign.accessibleData.name}
                className={classes.imageWrapper}
                style={getStyleForImageWrapper(campaign?.photos?.coverPhoto?.url)}
              />
              <Typography
                variant="body2"
                className={classNames(classes.longName, classes.campaignName)}
              >
                {campaign.accessibleData.name || notApplicable}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2" className={classes.longName}>
                {campaign.advertiser.name || notApplicable}
              </Typography>
            </TableCell>
            {visibleColumns?.budget && (
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">{campaign.accessibleData.budget}</Typography>
              </TableCell>
            )}
            {visibleColumns?.type && (
              <>
                <TableCell className={classes.tableCell}>
                  <Typography variant="body2">
                    {capitalize(campaign.accessibleData.type)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography variant="body2">
                    {numberWithCommas(campaign.accessibleData.reach) || notApplicable}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography variant="body2">
                    {numberWithCommas(campaign.accessibleData.assets) || notApplicable}
                  </Typography>
                </TableCell>
              </>
            )}
            {visibleColumns?.engagementRate && (
              <TableCell className={classes.tableCell}>
                <Typography variant="body2">
                  {formatEngagementRate(campaign.accessibleData.engagementRate)}
                </Typography>
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">{campaign.totalCreators}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {campaign.started ? moment(campaign.started).format('YYYY/MM/DD') : notApplicable}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">{capitalize(campaign.state)}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CampaignsTable;
