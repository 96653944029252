import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {gql} from '@apollo/client';
import {graphql, withApollo} from '@apollo/client/react/hoc';

import {Form, FormSubmitButton, FormTooltipInput} from 'components/Widgets/Forms';
import {LoaderDots} from 'components/Widgets';

import styles from './style.css';

const CampaignPreview = ({user, campaignId, preview}) => {
  if (!user) return <LoaderDots />;
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Send a campaign preview push notification to a TAKUMI user’s app
      </div>

      <Form
        initialState={{username: user.settings.instagramUsername}}
        className={styles.form}
        onSubmit={({username}) => preview(campaignId, username || user.settings.instagramUsername)}
      >
        <FormTooltipInput name="username" placeholder="Instagram username" />

        <div className={styles.button}>
          <FormSubmitButton inline text="Send" />
        </div>
      </Form>
    </div>
  );
};

CampaignPreview.propTypes = {
  campaignId: PropTypes.string.isRequired,
  user: PropTypes.object,
  preview: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match: {params}}) => ({
  campaignId: params.campaign,
});

const UserSettingsQuery = gql`
  query userSettings {
    currentUser {
      id
      settings {
        instagramUsername
      }
    }
  }
`;

const PreviewMutation = gql`
  mutation preview($username: String!, $id: UUIDString!) {
    previewCampaign(id: $id, username: $username) {
      ok
    }
  }
`;

export default withRouter(
  withApollo(
    compose(
      connect(mapStateToProps),
      graphql(UserSettingsQuery, {
        props: ({data}) => ({user: data.currentUser}),
      }),
      graphql(PreviewMutation, {
        props: ({mutate}) => ({
          preview: (id, username) => mutate({variables: {username, id}}),
        }),
      })
    )(CampaignPreview)
  )
);
