import React from 'react';
import {ButtonGroup} from '@material-ui/core';

import {useStyles} from './styles';
import {CampaignSwitch} from './types';
import ItemFilter from '../../../components/Widgets/ItemFilter';

export const CampaignsViewSwitch: React.FC<CampaignSwitch> = ({selectedViewType, setViewType}) => {
  const classes = useStyles();
  return (
    <>
      <ButtonGroup>
        {['Tile', 'List'].map((state, index) => {
          const checked = selectedViewType === state;
          return (
            <ItemFilter
              key={index}
              title={state}
              className={checked ? classes.checked : classes.button}
              handleClick={setViewType}
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default CampaignsViewSwitch;
