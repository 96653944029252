import React from 'react';
import {Button, ButtonGroup, makeStyles} from '@material-ui/core';

import {white, black40} from '../../../../../../consts/brand.integration';

type LineDataItem = {
  dataKey: keyof LineVisibility;
  yAxisId: string;
  color: string;
  name: string;
  hide: boolean;
};

type LineVisibility = {
  engagement_total: boolean;
  engagement_rate_by_impressions: boolean;
  reach_total: boolean;
  impression_total: boolean;
};

interface Props {
  linesVisibility: LineVisibility;
  handleLegendClick: (dataKey: keyof LineVisibility) => void;
  lineData: LineDataItem[];
}

const LegendButton: React.FC<{
  entry: LineDataItem;
  isVisible: boolean;
  handleClick: () => void;
}> = ({entry, isVisible, handleClick}) => {
  const classes = useStyles({isVisible, color: entry.color});
  return (
    <Button className={entry.hide ? classes.hidden : classes.legendButton} onClick={handleClick}>
      <span className={classes.item}>&bull;</span> {entry.name}
    </Button>
  );
};

const ChartLegend: React.FC<Props> = ({linesVisibility, handleLegendClick, lineData}) => {
  const classes = useStyles({});
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="Performance Metrics Legend"
      className={classes.buttonGroup}
    >
      {lineData.map((entry, index) => (
        <LegendButton
          key={`item-${index}`}
          entry={entry}
          isVisible={linesVisibility[entry.dataKey] || false}
          handleClick={() => handleLegendClick(entry.dataKey)}
        />
      ))}
    </ButtonGroup>
  );
};

const useStyles = makeStyles({
  legendButton: (props: {isVisible?: boolean; color?: string}) => ({
    background: white,
    border: 'none',
    borderBottom: props.isVisible ? `2px solid ${props.color}` : `2px solid ${white}`,
    color: props.isVisible ? props.color : black40,
    padding: '0.3rem 0',
    margin: '0.5rem 1.5rem',
  }),
  buttonGroup: {
    flexWrap: 'wrap',
  },
  item: {
    fontSize: '1.5rem',
    marginRight: '3px',
  },
  hidden: {
    display: 'none',
  },
});

export default ChartLegend;
