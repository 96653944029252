export const socialIcons: {[key: string]: string} = {
  content_only: 'ic:baseline-photo',
  tiktok: 'ic:baseline-tiktok',
  instagram: 'mdi:instagram',
  standard: 'mdi:instagram',
  video: 'mdi:instagram',
  reel: 'mdi:instagram',
  story: 'mdi:instagram',
  youtube: 'mdi:youtube',
  tiktok_video: 'ic:baseline-tiktok',
  instagram_photo: 'mdi:instagram',
  instagram_video: 'mdi:instagram',
  instagram_reel: 'mdi:instagram',
  instagram_story: 'mdi:instagram',
  youtube_short: 'simple-icons:youtubeshorts',
  youtube_video: 'mdi:youtube',
};
