import {createStyles, makeStyles} from '@material-ui/core/styles';

import {black20, black40, black60, yellow} from '../../../../../consts/brand';
import {
  red,
  semiBold,
  sizeBody2,
  sizeCaption,
  white,
} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    influencerName: {
      fontSize: '28px',
      lineHeight: '34px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    privateProfile: {
      fontSize: sizeCaption,
      fontVariant: 'small-caps',
      fontWeight: semiBold,
      textTransform: 'lowercase',
      marginRight: '5px',
      padding: '3px 5px',
      color: white,
      background: red,
      borderRadius: '3px',
    },
    notSignedUp: {
      fontSize: sizeCaption,
      fontVariant: 'small-caps',
      fontWeight: semiBold,
      textTransform: 'lowercase',
      color: white,
      marginRight: '5px',
      padding: '3px 5px',
      background: yellow,
      borderRadius: '3px',
    },
    influencerNumbers: {
      display: 'flex',
      marginTop: '10px',
      marginBottom: '10px',
    },
    influencerNumbersInner: {
      fontSize: sizeBody2,
      marginRight: '20px',
    },
    influencerBio: {
      color: black40,
      fontSize: sizeBody2,
      marginTop: '10px',
      lineHeight: '1.5em',
    },
    empty: {
      color: black20,
    },
    lastActive: {
      fontSize: sizeCaption,
      color: black60,
      whiteSpace: 'nowrap',
    },
  })
);
