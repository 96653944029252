import React, {useState, useEffect, useRef} from 'react';
import {cloneDeep, differenceWith, fromPairs, isEqual, keys, toPairs} from 'lodash';
import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router';
import {Range} from 'react-date-range';
import moment from 'moment';
import {usePagination} from '@material-ui/lab';
import {Grid} from '@material-ui/core';
import * as qs from 'query-string';

import Chart from './Chart';
import CampaignsTable from './CampaignsTable';
import {useStyles} from './styles';
import {processChartData, processCampaigns, getVisibleMetrics} from './utils';
import {DashboardCampaignsQuery} from './graphqlQueries';
import {LoaderDots} from '../../components/Widgets';
import {PaginationContainer} from './PaginationContainer';
import Filters from './Filters';
import {filtersVariables} from '../../consts/campaignsFilters';
import {ICampaignFilters} from '../Admin/Campaigns/types';
import {NONE_OPTION} from '../../consts/variables';
import {IndustryTreeQuery} from '../Advertiser/AdvertiserSettings/graphqlQuery';
import {getIndustryById} from '../../utils/campaignsFilter';
import useAdvertiserPageAccess from '../../hooks/useAdvertiserPageAccess';
import App404 from '../App404/App404';
import styles from '../App404/style.css';
import CampaignsViewSwitch from '../../components/Widgets/CampaignsViewSwitch';
import CampaignsTiles from './CampaignsTiles';

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = qs.parse(history.location.search, {
    parseBooleans: true,
  });
  const initialFilters = useRef<ICampaignFilters>(cloneDeep(filtersVariables));

  const [campaignView, setCampaignView] = useState('List');
  const [order, setOrder] = useState('desc');
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [filters, setFilters] = useState<ICampaignFilters>(initialFilters.current);
  const {data: industriesData} = useQuery(IndustryTreeQuery);

  const {loading, data, refetch} = useQuery(DashboardCampaignsQuery, {
    variables: {
      order,
      offset,
      limit,
      ...filters,
      startDate: filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : undefined,
      endDate: filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : undefined,
      advertiserIndustriesIds: filters?.advertiserIndustries.map(industry => industry.id),
      rewardModel: filters?.rewardModel === NONE_OPTION ? undefined : filters.rewardModel,
      mine: !filters?.mine ? undefined : filters.mine,
      archived: !filters?.archived ? undefined : filters.archived,
      shippingRequired: !filters?.shippingRequired ? undefined : filters.shippingRequired,
      brandSafety: !filters?.brandSafety ? undefined : filters.brandSafety,
      brandMatch: !filters?.brandMatch ? undefined : filters.brandMatch,
    },
  });

  const {items} = usePagination({
    count: Math.ceil(data?.campaigns?.totalCampaignsData?.totalCampaigns / limit),
    onChange: (event: React.ChangeEvent<unknown>, page: number) => {
      setOffset(page * limit - limit);
    },
    showFirstButton: true,
    showLastButton: true,
    page: (offset + limit) / limit,
  });

  const updateDeeplinking = () => {
    const changed = fromPairs(
      differenceWith(toPairs(filters), toPairs(initialFilters.current), isEqual)
    );
    history.replace({
      ...history.location,
      search: qs.stringify({
        ...changed,
        advertiserIndustries: [],
      }),
    });
  };

  useEffect(() => {
    if (keys(searchParams).length) {
      setFilters({...filters, ...searchParams});
    }
  }, []);

  useEffect(() => {
    setOffset(0);
    updateDeeplinking();
    refetch();
  }, [order, filters, refetch]);

  if (useAdvertiserPageAccess('dashboardPage')) {
    return (
      <div className={styles.component404}>
        <App404 />
      </div>
    );
  }

  const handleOrderChange = (nextOrder: string) => setOrder(nextOrder);

  const handleRangeChange = ({startDate, endDate}: Range) => {
    setFilters({...filters, startDate, endDate});
  };

  const onIndustryChanged = (industryId: string) => {
    const industry = getIndustryById(industriesData?.industries, industryId);

    if (industry) {
      if (!filters.advertiserIndustries.includes(industry)) {
        filters.advertiserIndustries.push(industry);
      } else {
        filters.advertiserIndustries.splice(filters.advertiserIndustries.indexOf(industry), 1);
      }

      setFilters({...filters});
    }
  };

  const accessibleData = processChartData(data);
  const visibleMetrics = getVisibleMetrics(accessibleData);
  const isChartVisible = visibleMetrics.impressions || visibleMetrics.engagementRate;

  const renderChart = () => {
    if (loading) return <LoaderDots />;

    if (!isChartVisible) return null;

    return (
      <Chart
        data={accessibleData}
        summary={data?.campaigns?.totalCampaignsData}
        visibleMetrics={visibleMetrics}
        count={data?.campaigns?.edges.length}
      />
    );
  };
  const handleCampaignViewSwitch = () => {
    campaignView === 'Tile' ? setCampaignView('List') : setCampaignView('Tile');
  };

  return (
    <div className={classes.root}>
      <Filters
        filters={filters}
        setFilters={setFilters}
        industriesData={industriesData}
        industries={industriesData?.industries}
        summary={data?.campaigns?.totalCampaignsData}
        advertiserNames={data?.campaigns?.totalCampaignsData?.advertiserNames}
        visibleMetrics={visibleMetrics}
        order={order}
        changeOrder={handleOrderChange}
        loading={loading}
        onIndustryChanged={onIndustryChanged}
        onRangeChange={handleRangeChange}
      />
      {renderChart()}
      <Grid container alignItems="center" justifyContent="center" direction="row">
        <Grid item xs={1} className={classes.campaignsSwitch}>
          {data?.campaigns?.totalCampaignsData?.totalCampaigns !== 0 && (
            <CampaignsViewSwitch
              selectedViewType={campaignView}
              setViewType={handleCampaignViewSwitch}
            />
          )}
        </Grid>
        <Grid item xs={11}>
          <PaginationContainer
            setOffset={setOffset}
            setLimit={setLimit}
            offset={offset}
            limit={limit}
            items={items}
            allCampaignsCount={data?.campaigns?.totalCampaignsData?.totalCampaigns}
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoaderDots />
      ) : (
        <>
          {data?.campaigns?.totalCampaignsData?.totalCampaigns !== 0 &&
            (campaignView === 'List' ? (
              <CampaignsTable campaigns={processCampaigns(data)} visibleColumns={visibleMetrics} />
            ) : (
              <CampaignsTiles campaigns={processCampaigns(data)} />
            ))}
        </>
      )}
      <Grid container alignItems="center" justifyContent="center" direction="row">
        <Grid item xs={1} />
        <Grid item xs={11}>
          <PaginationContainer
            setOffset={setOffset}
            setLimit={setLimit}
            offset={offset}
            limit={limit}
            items={items}
            allCampaignsCount={data?.campaigns?.totalCampaignsData?.totalCampaigns}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
