import PropTypes from 'prop-types';
import React from 'react';

import uniqueId from 'services/uniqueId';

import styles from './style.css';

const Toggle = ({
  onChange,
  checked,
  label,
  checkedDescription,
  uncheckedDescription,
  id = uniqueId(),
}) => (
  <div className={styles.root}>
    <div>
      <label className={styles.title} htmlFor={id}>
        {label}
      </label>
      <label className={styles.description} htmlFor={id}>
        {checked ? checkedDescription : uncheckedDescription}
      </label>
    </div>

    <div className={styles.toggle}>
      <input
        className={styles.input}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={event => onChange(event.target.checked)}
      />
      <label className={styles.label} htmlFor={id}>
        <i className={styles.icon} />
      </label>
    </div>
  </div>
);

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  checkedDescription: PropTypes.string,
  uncheckedDescription: PropTypes.string,
};

export default Toggle;
