import {Platforms} from 'consts/platforms';

export const hasSocialPlatformLinked = (platform: string, influencer: Influencer) => {
  switch (platform.toLocaleLowerCase()) {
    case Platforms.Instagram:
      return (
        influencer.instagramAccount?.username?.length! > 0 && influencer.instagramAccount?.active
      );
    case Platforms.TikTok:
      return influencer.tiktokAccount?.username?.length! > 0;
    case Platforms.YouTube:
      return influencer.youtubeChannel?.customUrl!.length! > 0;
    case Platforms.Twitch:
      return influencer.twitchChannel?.url!.length! > 0;
    case Platforms.Snapchat:
      return influencer.snapchatAccount?.snapchatUsername!.length! > 0;
    case Platforms.Content:
    case 'content':
      return true;
    default:
      return false;
  }
};
