import {createStyles, makeStyles} from '@material-ui/core/styles';

import {semiBold, transparentPurple} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
      marginTop: 24,
    },
    tableCell: {
      borderBottom: 'none',
      padding: '16px 16px 0 0',
      height: 80,
      verticalAlign: 'top',
    },
    tableCellWithImage: {
      display: 'flex',
      padding: '4px 16px 4px 4px',
    },
    imageWrapper: {
      display: 'flex',
      width: '100%',
      flex: '0 0 72px',
      marginRight: 16,
    },
    headCell: {
      height: 48,
      borderBottom: 'none',
      paddingLeft: 0,
    },
    headCellTypography: {
      fontWeight: semiBold,
      whiteSpace: 'nowrap',
    },
    bodyRow: {
      '&:hover': {
        backgroundColor: transparentPurple,
        cursor: 'pointer',
      },
      '&:focus': {
        backgroundColor: transparentPurple,
      },
    },
    longName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      height: 'fit-content',
    },
    campaignName: {
      paddingTop: 12,
    },
  })
);
