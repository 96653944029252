import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import {gql} from '@apollo/client';
import {Typography} from '@material-ui/core';

import {
  DocumentTitle,
  AutosavingControl,
  Button,
  Checkbox,
  LoaderDots,
  Prompt,
  TextInput,
} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

import NotificationList from './NotificationList';
import CampaignPlaylistModal from '../CampaignPlaylistModal';
import styles from './style.css';
import {CampaignHeader} from '../components';

class CampaignPlaylist extends PureComponent {
  static propTypes = {
    campaign: PropTypes.object,
    playlist: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        campaign: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateMessage: PropTypes.func.isRequired,
    notifyAllTargetsInCampaign: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  state = {
    isDragging: false,
    reNotify: true,
  };

  updatePushNotificationMessage = pushNotificationMessage => {
    this.props.updateMessage(pushNotificationMessage);
  };

  setIsDragging = isDragging => {
    this.setState({isDragging});
  };

  render() {
    const {campaign} = this.props;

    if (!campaign) {
      return <LoaderDots />;
    }

    const title = `${campaign.advertiser.name} - ${campaign.name} - Notifications`;

    return (
      <div className={styles.root}>
        <DocumentTitle title={getPageTitle(title)} />
        <CampaignHeader title="Notify" campaignId={campaign.id} />
        <div className={styles.playlistWrapper}>
          <div className={styles.heading}>
            {!campaign.public && (
              <CampaignPlaylistModal
                notifyAllTargetsInCampaign={this.props.notifyAllTargetsInCampaign}
                campaign={campaign}
              />
            )}
          </div>

          <div className={styles.messageBox}>
            <div className={styles.message}>
              <AutosavingControl
                initialState={{value: campaign.pushNotificationMessage}}
                label="Message"
                debounceInterval={1500}
                onChange={this.updatePushNotificationMessage}
              >
                <TextInput big placeholder="No message" />
              </AutosavingControl>
            </div>
            <div className={styles.notifyButton}>
              {campaign.public && (
                <Prompt
                  title="Notify all targeted influencers"
                  body={
                    <div>
                      <Typography variant="body2">
                        Are you sure you want to notify all targeted influencers?
                      </Typography>

                      <Typography variant="body2">
                        Please note, notifications can take up-to 3 hours to be sent.
                      </Typography>

                      <div className={styles.checkbox}>
                        <Checkbox
                          id="renotify"
                          label="Renotify influencers that haven't responded for 24 hours"
                          checked={this.state.reNotify}
                          onChange={event => {
                            this.setState({reNotify: event.target.checked});
                          }}
                        />
                      </div>
                    </div>
                  }
                  confirmText="Notify all"
                  control={
                    <Button key="public_notify_btn" gold outline text="Send out notifications" />
                  }
                  onSubmit={() => this.props.notifyAllTargetsInCampaign(this.state.reNotify)}
                />
              )}
            </div>
          </div>
          <NotificationList campaign={campaign} />
        </div>
      </div>
    );
  }
}

const UpdateMessageMutation = gql`
  mutation updateMessage($id: UUIDString!, $message: String!) {
    updateCampaign(id: $id, pushNotificationMessage: $message) {
      ok
      campaign {
        id
        pushNotificationMessage
        rewardModel
      }
    }
  }
`;

const NotifyAllTargetsInCampaignMutation = gql`
  mutation NotifyAllTargetsInCampaignMutation($id: UUIDString!, $notNotifiedInTheLastHours: Int) {
    notifyAllTargetsInCampaign(id: $id, notNotifiedInTheLastHours: $notNotifiedInTheLastHours) {
      ok
      campaign {
        id
        public
      }
    }
  }
`;

const CampaignQuery = gql`
  query campaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      rewardModel
      interestIds
      pushNotificationMessage
      public
      state
      advertiser {
        id
        name
      }
      posts {
        id
        opened
        deadline
        submissionDeadline
        postType
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(CampaignQuery, {
    props: ({data: {loading, campaign}}) => ({
      loading,
      campaign,
    }),
    options: ({match: {params}}) => ({
      variables: {
        campaignId: params.campaign,
      },
    }),
  }),
  graphql(UpdateMessageMutation, {
    props: ({
      ownProps: {
        match: {params},
      },
      mutate,
    }) => ({
      updateMessage: message =>
        mutate({
          variables: {
            message,
            id: params.campaign,
          },
          optimisticResponse: {
            updateCampaign: {
              ok: true,
              campaign: {
                id: params.campaign,
                pushNotificationMessage: message,
              },
            },
          },
        }),
    }),
  }),
  graphql(NotifyAllTargetsInCampaignMutation, {
    options: {
      refetchQueries: ['campaignNotificationsQuery'],
    },
    props: ({
      ownProps: {
        match: {params},
      },
      mutate,
    }) => ({
      notifyAllTargetsInCampaign: reNotify =>
        mutate({
          variables: {
            id: params.campaign,
            notNotifiedInTheLastHours: reNotify ? 24 : null,
          },
        }),
    }),
  })
)(CampaignPlaylist);
