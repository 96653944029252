import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {LoaderDots} from '../../../../components/Widgets';
import ShippingTableRow from './components/ShippingTableRow';

interface Props {
  offers: Offer | undefined;
  loading: boolean;
}

const CampaignShipping: React.FC<Props> = ({offers, loading}) => {
  const classes = useStyles();
  const influencerOffers = offers?.edges;

  return (
    <Card className={classes.root}>
      <CardContent>
        <TableContainer>
          <Table stickyHeader aria-label="shipping information table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderBold} align="left">
                  Influencer
                </TableCell>
                <TableCell className={classes.tableHeaderBold} align="left">
                  Shipping Address
                </TableCell>
                <TableCell className={classes.tableHeaderBold} align="left">
                  Deliverables
                </TableCell>
                <TableCell className={classes.tableHeaderBold} align="center">
                  Delivery
                </TableCell>
                <TableCell className={classes.tableHeaderBold} align="center">
                  Tracking Code
                </TableCell>
                <TableCell className={classes.tableHeaderBold} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} rowSpan={6} className={classes.isLoading}>
                    <LoaderDots />
                  </TableCell>
                </TableRow>
              ) : influencerOffers && influencerOffers.length > 0 ? (
                influencerOffers.map((influencerOffer: any, i: any) => (
                  <ShippingTableRow
                    key={i}
                    influencerOffer={influencerOffer.node}
                    classes={classes}
                    ariaLabel="Shipping Table Row"
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No results have been found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'table',
    tableLayout: 'fixed',
    overflowX: 'auto',
    marginTop: '20px',
  },
  isLoading: {
    margin: '0 auto',
  },
  address: {
    fontStyle: 'normal',
  },
  shippedIcon: {
    color: 'green',
  },
  pendingIcon: {
    color: 'lightgrey',
  },
  ctaUpdateButton: {
    fontSize: '12px',
    '& svg': {
      marginLeft: '5px',
    },
  },
  ctaIconButton: {
    margin: '0',
    minWidth: '0',
    padding: '6px',
  },
  deliverable: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    '& svg': {
      marginRight: '5px',
    },
  },
  trackingCode: {
    minWidth: 170,
  },
  tableHeaderBold: {
    fontWeight: 700,
  },
  promptButton: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '5px',
    },
  },
});

export default CampaignShipping;
