import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {Grid, makeStyles} from '@material-ui/core';

import {black20, black50, roman, semiBold, sizeBody1} from '../../../consts/brand.integration';

interface Props {
  name: string;
  value: Record<string, unknown> | ReactNode;
  placeholder?: string;
  big?: boolean;
  right?: boolean;
}

const KeyValueText: React.FC<Props> = ({name, value, placeholder = 'No value', big, right}) => {
  const classes = useStyles();

  const rootClass = classNames({
    [classes.root]: true,
    [classes.big]: big,
    [classes.right]: right,
  });

  return (
    <Grid item className={rootClass}>
      <span className={classes.name}>{name}</span>
      <span className={classes.value}>{value}</span>
      {!value && <span className={classes.placeholder}>{placeholder}</span>}
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    marginRight: '18px',
    fontSize: '12px',
    lineHeight: '1.2',
    fontWeight: roman,
  },
  name: {
    marginRight: '8px',
    fontWeight: roman,
    color: black50,
    whiteSpace: 'nowrap',
  },
  value: {
    display: 'inline-block',
    fontWeight: semiBold,
  },
  placeholder: {
    fontWeight: semiBold,
    color: black20,
  },
  right: {
    margin: '0 0 0 15px',
  },
  big: {
    fontSize: sizeBody1,
  },
});

export default KeyValueText;
