import {makeStyles} from '@material-ui/core/styles';

import {black, white} from '../../../../../../../consts/brand.integration';

export const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      padding: '32px',
      maxWidth: 'fit-content',
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: '32px',
  },
  typography: {
    marginLeft: '18px',
  },
  dialogContent: {
    padding: 0,
    marginBottom: '48px',
  },
  dialogContentText: {
    margin: 0,
    color: black,
  },
  dialogActions: {
    padding: 0,
  },
  containedButton: {
    height: '40px',
    borderRadius: 0,
    padding: '12px 16px',
    color: white,
    backgroundColor: black,
    '&:hover': {
      backgroundColor: black,
    },
  },
  outlinedButton: {
    height: '40px',
    border: '1px solid black',
    borderRadius: 0,
    padding: '12px 16px',
    color: black,
    backgroundColor: white,
  },
});
