import React, {useEffect, useState} from 'react';
import moment, {Moment} from 'moment';

import {LONG_DATE_TIME_FORMAT} from '../../../consts/variables';
import TimeService from '../../../services/time';

interface Props {
  date?: string | number;
  skipAGO?: boolean;
}

const RelativeDate: React.FC<Props> = ({date, skipAGO = false}) => {
  const [now, setNow] = useState<number>(new Date().getTime());

  useEffect(() => {
    const timer = TimeService.subscribe(() => setNow(new Date().getTime()));
    return () => {
      TimeService.unsubscribe(timer);
    };
  }, []);

  const d: Moment = moment(date);
  if (!d.isValid()) {
    return <span>-</span>;
  }

  return (
    <span key={now} title={d.format(LONG_DATE_TIME_FORMAT)}>
      {d.fromNow(skipAGO)}
    </span>
  );
};

export default RelativeDate;
