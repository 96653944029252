import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Grid, Typography} from '@material-ui/core';

import Balances from './components/Balances/Balances';
import ProcessingStatus from './components/ProcessingStatus/ProcessingStatus';
import QueuedFunds from './components/QueuedFunds/QueuedFunds';
import RecentlyPaidOut from './components/RecentlyPaidOut/RecentlyPaidOut';
import UpcomingPayments from './components/UpcomingPayments/UpcomingPayments';

interface CardProps {
  label: string;
  children: ReactNode | ReactNode[];
}

const CustomCard: React.FC<CardProps> = ({label, children}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {label}
        </Typography>
        <div className={classes.cardContent}>{children}</div>
      </CardContent>
    </Card>
  );
};

const Statistics: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid item md={6} xs={12}>
        <Grid container item spacing={4} alignContent="flex-start">
          <Grid item xs={12}>
            <CustomCard label="Payment processing status">
              <ProcessingStatus />
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <CustomCard label="Balances">
              <Balances />
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <CustomCard label="Queued up payments">
              <QueuedFunds />
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <CustomCard label="Recently paid out">
              <RecentlyPaidOut />
            </CustomCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <CustomCard label="Upcoming payments">
          <UpcomingPayments />
        </CustomCard>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  card: {
    minWidth: 200,
    minHeight: 200,
  },
  cardContent: {
    marginTop: 20,
  },
});

export default Statistics;
