import React, {useEffect, useRef, useState} from 'react';
import {Grid, Typography, makeStyles} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {gql, useLazyQuery, useMutation} from '@apollo/client';

import {InfluencerFilter, SearchInput, LoaderDots} from 'components/Widgets';

import CampaignTargetingResults from '../CampaignTargetingResults';
import {initialState as initialFilterProps} from '../../../components/Widgets/InfluencerFilter/helper';
import {INFLUENCER_FILTER_STATE} from '../../../consts/variables';
import {withHookPagination} from '../../../services/graphql';
import {parseAccountStateForQuery} from '../../Admin/Influencer/Search/helper';
import {NotifyInfluencerSearchQuery} from './queries';

const initialState = {
  state: INFLUENCER_FILTER_STATE.Reviewed.value,
  showFilters: false,
  filterProps: {...initialFilterProps},
};

interface Props {
  campaign: Campaign;
}

const CampaignPlaylistTargetingSearch: React.FC<Props> = ({campaign}) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [accountState, setAccountState] = useState<string>(initialState.state);
  const [filters, setFilters] = useState(initialState.filterProps);
  const searchTimerRef = useRef<NodeJS.Timeout | null>(null);

  const onSearchChange = (event: Record<any, any>) => setSearchString(event.target.value);

  const [fetchInfluencers, {loading, data, fetchMore, variables}] = useLazyQuery(
    NotifyInfluencerSearchQuery,
    {
      variables: {
        searchString,
        state: parseAccountStateForQuery(accountState),
        ...filters,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [makeOffer] = useMutation(MakeOfferMutation, {
    refetchQueries: ['campaignNotificationsQuery'],
  });

  const loadMore = withHookPagination(
    NotifyInfluencerSearchQuery,
    'influencerSearch',
    data,
    variables,
    fetchMore
  );

  useEffect(() => {
    return () => {
      if (searchTimerRef.current) {
        clearTimeout(searchTimerRef.current);
        searchTimerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    debounceFetchInfluencers();
  }, [accountState, filters, searchString]);

  // Run delayed fetch results
  const debounceFetchInfluencers = () => {
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
      searchTimerRef.current = null;
    }
    searchTimerRef.current = setTimeout(() => {
      fetchInfluencers();
    }, 1000);
  };

  const handleMakeOffer = async (
    influencerId: string,
    selectedDeliverables: OfferDeliverable[] = []
  ) => {
    await makeOffer({
      variables: {
        campaignId: campaign.id,
        influencerId,
        deliverablePostIds: selectedDeliverables?.map(deliverable => deliverable?.id),
      },
      update: (proxy, {data}) => {
        if (data?.makeOffer?.ok) {
          let previousData: Record<any, any> | null;
          try {
            previousData = proxy.readQuery({
              query: NotifyInfluencerSearchQuery,
              variables,
            });
          } catch (e) {
            return;
          }
          if (previousData) {
            proxy.writeQuery({
              query: NotifyInfluencerSearchQuery,
              variables,
              data: {
                ...previousData,
                influencerSearch: {
                  ...previousData.influencerSearch,
                  edges: previousData.influencerSearch.edges.filter(
                    edge => edge.node.id !== influencerId
                  ),
                },
              },
            });
          }
        }
      },
    });
  };

  return (
    <Grid container className={classes.root} direction="column" spacing={4}>
      <Grid item xs={12}>
        <SearchInput
          searchString={searchString}
          placeholder="Search Influencers"
          onChange={onSearchChange}
        />
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={accountState}
          exclusive
          onChange={(event, value) => setAccountState(value)}
        >
          {[INFLUENCER_FILTER_STATE.Reviewed, INFLUENCER_FILTER_STATE.Verified].map(item => (
            <ToggleButton key={item.name} value={item.value} aria-label={item.name}>
              <Typography variant="button">{item.name}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <InfluencerFilter onChange={setFilters} />
      </Grid>
      <Grid item xs={12}>
        {!data && <LoaderDots />}
        <CampaignTargetingResults
          campaign={campaign}
          loading={loading}
          loadMore={loadMore}
          influencers={data?.influencerSearch}
          onMakeOffer={handleMakeOffer}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
    backgroundColor: 'white',
    flexDirection: 'column',
  },
});

const MakeOfferMutation = gql`
  mutation makeOffer(
    $campaignId: UUIDString!
    $influencerId: UUIDString!
    $deliverablePostIds: [UUIDString]!
  ) {
    makeOffer(
      campaignId: $campaignId
      influencerId: $influencerId
      deliverablePostIds: $deliverablePostIds
    ) {
      ok
    }
  }
`;

export default CampaignPlaylistTargetingSearch;
