import {alpha, createStyles, makeStyles} from '@material-ui/core';

import {
  bold,
  red,
  sizeBody1,
  sizeBody2,
  sizeCaption,
  white,
  black20,
  black,
  black80,
  purple,
} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: sizeCaption,
      fontWeight: bold,
      padding: '3px 13px',
      lineHeight: 2,
      textTransform: 'capitalize',
      transition: 'all 0.2s',
      cursor: 'pointer',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      '& i': {
        fontSize: sizeBody1,
        marginRight: '4px',
      },
    },
    big: {
      fontSize: sizeBody2,
      fontWeight: bold,
      padding: '2px 16px',
    },
    transparent: {
      color: black20,
      backgroundColor: 'transparent',
      '&:hover': {
        color: white,
        backgroundColor: black80,
      },
      '&.selected': {
        color: white,
        backgroundColor: black,
      },
    },
    danger: {
      '&:hover:, .danger.selected': {
        color: `${red} !important`,
        backgroundColor: 'transparent !important',
      },
    },
    default: {
      color: purple,
      backgroundColor: alpha(purple, 0.2),
      '&:hover': {
        color: white,
        backgroundColor: purple,
      },
    },
    selected: {
      color: white,
      backgroundColor: purple,
      '&:hover': {
        color: white,
        backgroundColor: alpha(purple, 0.5),
      },
    },
  })
);
