import PropTypes from 'prop-types';
import React from 'react';
import className from 'classnames';

import styles from './style.css';

const Item = ({title, children, fullHeight}) => (
  <div className={className(styles.root, fullHeight && styles.fullHeight)}>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
);

Item.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
};

export default Item;
