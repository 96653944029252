import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: '100%',
      height: '48px',
      marginRight: theme.spacing(2),
    },
    inputText: {
      color: theme.palette['text-03'],
      fontSize: '14px',
    },
    icon: {
      marginRight: '10px',
      color: theme.palette['ui-07'],
    },
  })
);
