import {gql} from '@apollo/client';

export const Campaign = {
  targetFragment: gql`
    fragment targetFields on Campaign {
      id
      target {
        id
        targetInstagram
        acceptedInstagram
        deliveredInstagram
        targetTiktok
        acceptedTiktok
        deliveredTiktok
        targetYoutube
        acceptedYoutube
        deliveredYoutube
        targetTwitch
        acceptedTwitch
        deliveredTwitch
        targetContent
        acceptedContent
        deliveredContent
        targetOverall
        acceptedOverall
        deliveredOverall
      }
    }
  `,
};

export const Gig = {
  platformsFragment: gql`
    fragment platformsFields on Gig {
      id
      instagramContent {
        __typename
        id
        created
        followers
        modified
        posted
        media {
          __typename
        }
        ... on InstagramPost {
          deleted
          link
          caption
          mentions
          hashtags
          videoViews
          plays
          comments
          likes
          shares
          scraped
          engagementRateByFollowers {
            formattedValue
          }
        }
      }
      tiktokPost {
        id
        ... on TiktokPost {
          __typename
          id
          link
          videoViews
          comments
          likes
          shares
          scraped
          videoDuration
          posted
          engagementByFollowers {
            formattedValue
          }
          engagementByImpressions {
            formattedValue
          }
        }
      }
      youtubeVideo {
        id
        ... on YoutubeVideo {
          __typename
          id
          viewCount
          commentCount
          likeCount
          engagementRateBySubscribers
          engagementRateByImpressions
        }
      }
      twitchVideo {
        id
        ... on TwitchVideo {
          __typename
          id
          streamId
          twitchVideoId
          videoUrl
          viewCount
          streamAverageViewers
          streamMaxViewers
        }
      }
    }
  `,
  mediaPreview: gql`
    fragment mediaResultFields on MediaResult {
      __typename
      ... on Image {
        id
        url
      }
      ... on Video {
        id
        url
        thumbnail
        transcoded
        previewUrl
        previewThumbnailUrl
      }
      ... on Gallery {
        items {
          __typename
          id
          ... on Image {
            url
          }
          ... on Video {
            url
            thumbnail
            transcoded
            previewUrl
            previewThumbnailUrl
          }
        }
      }
    }
  `,
};

export const Influencer = {
  socialPlatformsFragment: gql`
    fragment socialPlatformsFields on Influencer {
      id
      instagramAccount {
        id
        active
        username
        followers
        boosted
        biography
        engagement {
          value
          formattedValue
        }
        estimatedImpressions
        estimatedEngagementsPerPost
      }
      tiktokAccount {
        id
        followers
        username
        engagementRate90days {
          value
          formattedValue
        }
      }
      youtubeChannel {
        id
        customUrl
        subscriberCount
      }
      twitchChannel {
        id
        url
        followers
      }
    }
  `,
  socialPlatformsForInfluencerSearchFragment: gql`
    fragment socialPlatformsForInfluencerSearch on InfluencerSearchResult {
      id
      instagramAccount {
        id
        active
        username
        followers
        boosted
        engagement {
          value
          formattedValue
        }
      }
      tiktokAccount {
        id
        followers
        username
        engagementRate90days {
          value
          formattedValue
        }
      }
      youtubeChannel {
        id
        customUrl
        subscriberCount
      }
      twitchChannel {
        id
        url
        followers
      }
    }
  `,
};

export const AudienceInsightFragment = gql`
  fragment AudienceInsightFragment on InstagramAudienceInsight {
    id
    created
    totalRegionFollowers
    genderSplit {
      id
      name
      followerCount
      followerPercentage {
        value
      }
    }
    ageSplit {
      id
      name
      followerCount
      followerPercentage {
        value
      }
    }
    regionInsights(maxResults: 3) {
      id
      region {
        id
        name
        countryCode
      }
      followerPercentage {
        formattedValue
      }
    }
  }
`;
