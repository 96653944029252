import printJS from 'print-js';

export const printPdf = (elementId: string, documentTitle: string) => {
  return printJS({
    printable: elementId,
    type: 'html',
    documentTitle,
    style: `
    .MuiCollapse-hidden {
      display: none !important;
    }
    .ce-inline-toolbar, .ce-inline-toolbar * {
        display: none !important;
    }`,
  });
};
