import React from 'react';
import {createStyles, Grid, makeStyles} from '@material-ui/core';

import {bold, purple, sizeCaption, white} from '../../../../../../consts/brand.integration';

interface Props {
  tags: string[] | undefined;
  title: string;
}

const TagList: React.FC<Props> = ({tags, title}) => {
  const classes = useStyles();

  if (!tags) return null;

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        {title}
      </Grid>
      <Grid item container direction="row" spacing={1}>
        {tags.map((tag, index) => (
          <Grid key={index} item>
            <div className={classes.tag}>{tag}</div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    tag: {
      color: white,
      padding: '3px 13px',
      backgroundColor: purple,
      fontSize: sizeCaption,
      fontWeight: bold,
    },
  })
);

export default TagList;
