import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {MuiThemeProvider} from '@material-ui/core/styles';

import {DocumentTitle, GlobalLoader, ImpersonationBar} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';
import {theme} from 'services/themer';
import {theme as muiTheme} from 'consts/theme';

import {NotificationBar} from './components';
import withDragDropContext from '../../../services/drag-drop-context';
import styles from './style.css';

type Props = {
  children: ReactNode | ReactNode[];
};

const App: React.FC<Props> = ({children}: Props) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className={classNames([`theme-${theme}`, styles.app])}>
        <DocumentTitle title={getPageTitle()} />
        <div className={styles.content}>{children}</div>
        <GlobalLoader />
        <ImpersonationBar />
        <NotificationBar />
      </div>
    </MuiThemeProvider>
  );
};

export default withDragDropContext(App);
