import React from 'react';

import {Tooltip} from 'components/Widgets';
import {MaterialDesignIcon} from 'components/Icons';

import styles from './style.css';

interface Props {
  open: boolean;
  hashtags: Array<{
    value: string;
    count: number;
  }>;
  emojis: Array<{
    value: string;
    count: number;
  }>;
  commentSentiment: {
    formattedValue: string;
  };
  captionSentiment: {
    formattedValue: string;
  };
  toggle: () => void;
}

/**
 * @note
 * Note: This is currently only showing for older campaigns,
 * where captionSentiment and commentSentiment are available.
 *
 */
const PostAnalysis: React.FC<Props> = ({
  open,
  hashtags,
  emojis,
  commentSentiment,
  captionSentiment,
  toggle,
}) => {
  if (captionSentiment == null || commentSentiment == null) return null;

  return (
    <div className={styles.root}>
      {open ? (
        <div className={styles.content}>
          {toggle && (
            <div onClick={toggle}>
              <MaterialDesignIcon icon="close" className={styles.closeButton} />
            </div>
          )}

          <div>
            <div className={styles.heading}>Emojis</div>
            <div className={styles.description}>
              Emojis participants included in captions, most common first.
            </div>
            <div className={styles.emojis}>
              {emojis.map(emoji => (
                <Tooltip key={emoji.value} placement="bottom" overlay={`${emoji.count} times`}>
                  <div className={styles.emoji}>{emoji.value}</div>
                </Tooltip>
              ))}
            </div>
            <div className={styles.heading}>Hashtags</div>
            <div className={styles.description}>
              Keywords used to connect posts thematically. Instagram allows users to navigate posts
              connected by hashtags.
            </div>
            <div className={styles.hashtags}>
              {hashtags.map(hashtag => (
                <Tooltip key={hashtag.value} placement="bottom" overlay={`${hashtag.count} times`}>
                  <div className={styles.hashtag}>#{hashtag.value}</div>
                </Tooltip>
              ))}
            </div>
            <div className={styles.heading}>Sentiment</div>
            <div className={styles.description}>
              Sentiment Analysis is the process of determining whether a piece of writing is
              positive, negative or neutral. This helps you benchmark and understand how people feel
              about your brand and product.
            </div>
            <div className={styles.sentiment}>
              <div className={styles.percentageGroup}>
                <div className={styles.percentage}>{captionSentiment.formattedValue}</div>
                <div className={styles.percentageTitle}>Captions</div>
              </div>
              <div className={styles.percentageGroup}>
                <div className={styles.percentage}>{commentSentiment.formattedValue}</div>
                <div className={styles.percentageTitle}>Comments</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.closed} onClick={toggle}>
          <div className={styles.expand}>Expand analysis</div>
          <MaterialDesignIcon className={styles.chevron} icon="keyboard_arrow_down" />
        </div>
      )}
    </div>
  );
};

export default PostAnalysis;
