import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.css';

const HighlightFirstNumber = ({sentence}) => {
  const splitSentence = sentence.split(' ');
  if (!Number.isInteger(parseInt(sentence.charAt(0), 10))) {
    return <span>{sentence}</span>;
  }
  return (
    <span>
      <span className={styles.highlightFirstLetter}>{splitSentence[0]} </span>
      <span className={styles.leftOvers}>
        {' '}
        {splitSentence.splice(1, splitSentence.length).join(' ')}
      </span>
    </span>
  );
};

HighlightFirstNumber.propTypes = {
  sentence: PropTypes.string.isRequired,
};

export default HighlightFirstNumber;
