import {PlatformsConfig} from 'consts/platforms';
import {PostType} from 'consts/postTypes';
import ErrorReporting from 'services/error-reporting';

export const getSocialPlatformNameByPostType = (postType: string) => {
  switch (postType.toLowerCase()) {
    case PostType.InstagramStandard:
    case PostType.InstagramVideo:
    case PostType.InstagramStory:
    case PostType.InstagramReel: {
      return PlatformsConfig.instagram.name;
    }
    case PostType.Tiktok: {
      return PlatformsConfig.tiktok.name;
    }
    case PostType.Youtube:
    case PostType.YoutubeShort:
    case PostType.YoutubeVideo: {
      return PlatformsConfig.youtube.name;
    }
    case PostType.Twitch: {
      return PlatformsConfig.twitch.name;
    }
    case PostType.Snapchat: {
      return PlatformsConfig.snapchat.name;
    }
    case PostType.Content: {
      return PlatformsConfig.content_only.name;
    }
    default: {
      ErrorReporting.captureMessage(`Unknown post type: ${postType}`);
      return 'UNKNOWN PLATFORM';
    }
  }
};
