import React from 'react';
import {makeStyles} from '@material-ui/core';

import {DocumentTitle, LogoWithText} from 'components/Widgets';

import {getPageTitle} from 'consts/utilities';

const Maintenance: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <DocumentTitle title={getPageTitle('In Maintenance')} />
      <LogoWithText
        header={
          <>
            <p>We're sorry, but TAKUMI is down for scheduled maintenance</p>
            <p>Please come back in a few moments.</p>
          </>
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  main: {
    marginTop: '200px',
    margin: 'auto',
  },
});

export default Maintenance;
