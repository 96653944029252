import React, {useState, useEffect} from 'react';

import {AdvertiserIndustryEntity, TreeNode, TreeViewEntity} from '../../../types';
import TreeSelector from '../TreeSelector';

type PropsTypes = {
  entities: Array<TreeViewEntity>;
  checkedIds: Array<string>;
  searchString?: string;
  onCheckedChanged: (entity: TreeViewEntity, isChecked: boolean) => void;
  setIsMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const EntitiesTree: React.FC<PropsTypes> = ({
  entities,
  checkedIds,
  searchString = '',
  onCheckedChanged,
  setIsMenuOpened,
}: PropsTypes) => {
  const [entitiesTree, setEntitiesTree] = useState<TreeNode<TreeViewEntity> | null>(null);
  useEffect(() => {
    if (entities) {
      setEntitiesTree(initTree(entities, checkedIds));
    }
  }, [checkedIds, entities]);

  if (!entities && !entitiesTree) {
    return null;
  }

  function onEntitiesTreeExpansionChanged(node: TreeNode<TreeViewEntity>, expanded: boolean) {
    modifyEntitiesTree(node => (node.expanded = expanded), node.value);
  }

  function onEntitiesTreeCheckingChanged(node: TreeNode<TreeViewEntity>, isChecked: boolean) {
    onCheckedChanged(node.value, isChecked);
    modifyEntitiesTree(node => (node.checked = isChecked), node.value);
  }

  function modifyEntitiesTree(
    modifier: (node: TreeNode<TreeViewEntity>) => void,
    entity: TreeViewEntity | null = null
  ) {
    traverseTree(entitiesTree, modifier, entity);
    setEntitiesTree({...entitiesTree} as TreeNode<TreeViewEntity>);
  }

  function searchByString(node: TreeNode<TreeViewEntity>): boolean {
    return (node.label || '').toLowerCase().includes((searchString || '').toLowerCase());
  }

  return (
    <TreeSelector
      tree={entitiesTree}
      searchString={searchString}
      searchPredicate={searchString.length >= 3 ? searchByString : undefined}
      setIsMenuOpened={setIsMenuOpened}
      onExpansionChanged={(node, expanded) => onEntitiesTreeExpansionChanged(node, expanded)}
      onSelectionChanged={(node, isChecked) => onEntitiesTreeCheckingChanged(node, isChecked)}
    />
  );
};

export default React.memo(EntitiesTree);

function initTree(data: ReadonlyArray<TreeViewEntity>, checkedIds: Array<string>) {
  const traverseParent = (data: TreeViewEntity): TreeNode<TreeViewEntity> => {
    const treeNode: TreeNode<TreeViewEntity> = {
      id: data.id,
      children: [],
      label: data.title,
      value: data,
      expanded: false,
      selectable: false,
      disabled: false,
      checked: checkedIds.some(entityId => data.children.some(child => child.id === entityId)),
    };

    treeNode.children = (data.children ?? []).map(child => traverseChild(child));
    return treeNode;
  };

  const traverseChild = (data: AdvertiserIndustryEntity): TreeNode<TreeViewEntity> => {
    const treeNode: TreeNode<TreeViewEntity> = {
      id: data.id,
      children: [],
      label: data.title,
      value: {children: [], id: data.id, title: data.title},
      expanded: false,
      selectable: true,
      disabled: !data.active,
      checked: checkedIds.some(entityId => data.id === entityId),
    };
    return treeNode;
  };

  const rootTreeNode: TreeNode<TreeViewEntity> = {
    id: 'root',
    children: [],
    label: '',
    value: {children: [], id: 'root', title: ''},
    expanded: false,
    selectable: false,
    disabled: false,
    checked: false,
  };
  rootTreeNode.children = data.map(child => traverseParent(child));

  return rootTreeNode;
}

function traverseTree(
  node: TreeNode<TreeViewEntity> | null,
  visitor: (node: TreeNode<TreeViewEntity>) => void,
  nodeValue: TreeViewEntity | null = null
) {
  if (!node) {
    return;
  }

  if (!nodeValue || nodeValue === node.value) {
    visitor(node);
  }

  if (!nodeValue || nodeValue !== node.value) {
    node.children.forEach(child => traverseTree(child, visitor, nodeValue));
  }
}
