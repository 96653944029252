import React, {useState} from 'react';
import {Popover, IconButton, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Icon} from '@iconify/react';

import {Tooltip} from '../../../../../components/Widgets';
import ArrowUpIcon from '../ArrowUpIcon';
import ArrowDownIcon from '../ArrowDownIcon';
import ArrowNeutralIcon from '../ArrowNeutralIcon';
import {useStyles} from './styles';

interface Props {
  title: string;
  description?: string;
  value: string | number | null;
  formattedValue?: string | null;
  plannedValue?: string | number;
  formattedPlannedValue?: string;
  className?: string;
  arrow?: string;
  placement?: string;
  large?: boolean;
  changedMetric?: string | number;
}

const MetricItem: React.FC<Props> = ({
  placement = 'top',
  title,
  description,
  value,
  formattedValue,
  plannedValue,
  formattedPlannedValue,
  changedMetric,
  className,
  arrow,
  large = false,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'metric-description' : undefined;

  return (
    <div className={classNames(classes.metric, className)}>
      <Typography className={classes.title} variant={large ? 'body1' : 'body2'}>
        {title}
        {description && (
          <IconButton
            aria-describedby={id}
            className={classes.descriptionIcon}
            onClick={handleClick}
          >
            <Icon icon="mdi:information-outline" width={16} />
          </IconButton>
        )}
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div className={classes.description}>
          <Typography variant="caption">{description}</Typography>
        </div>
      </Popover>
      <div className={classes.valueWrapper}>
        {formattedValue ? (
          <Tooltip overlay={<span>{value}</span>} mouseEnterDelay={0.2} placement={placement}>
            <Typography className={classes.value} variant={large ? 'h2' : 'h3'} component="span">
              {formattedValue}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.value} variant={large ? 'h2' : 'h3'} component="span">
            {value}
          </Typography>
        )}
        {arrow && (
          <Tooltip
            overlay={<span>{`${changedMetric ? changedMetric : 'No change'} today`}</span>}
            mouseEnterDelay={0.2}
            placement="right"
          >
            <div className={classes.dailyChange}>
              {arrow === 'up' && <ArrowUpIcon />}
              {arrow === 'down' && <ArrowDownIcon />}
              {arrow === 'neutral' && <ArrowNeutralIcon />}
              <Typography component="span" variant="caption">
                {changedMetric}
              </Typography>
            </div>
          </Tooltip>
        )}
      </div>
      {plannedValue && (
        <Tooltip
          overlay={<span>{`Planned: ${plannedValue}`}</span>}
          mouseEnterDelay={0.2}
          placement="bottom"
        >
          <Typography className={classes.plannedValue} variant="body1">
            {formattedPlannedValue}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

export default MetricItem;
