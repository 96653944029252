import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {FormControl, InputLabel} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';

import {black} from '../../../consts/brand.integration';

export interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  searchString?: string;
}

const SearchInput: React.FC<Props> = ({onChange, searchString, placeholder}) => {
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="search-input" style={{display: 'none'}}>
          Search campaigns
        </InputLabel>
        <SearchField
          id="search-input"
          variant="outlined"
          placeholder={placeholder}
          value={searchString ?? ''}
          InputProps={{
            'aria-required': false,
            'aria-labelledby': 'search-input',
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchString && (
              <InputAdornment position="end" onClick={e => onChange(e)}>
                <ClearIcon aria-label={'clear-search'} style={{cursor: 'pointer'}} />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
      </FormControl>
    </div>
  );
};

const SearchField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      height: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: black,
      },
    },
  },
})(TextField);

export default SearchInput;
