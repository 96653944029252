import {createStyles, makeStyles} from '@material-ui/core/styles';

import {theme} from '../../../consts/theme';
import {mainContentWidth, white, black, semiBold, roman} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: mainContentWidth,
      margin: '3rem auto 1rem auto',
    },
    fieldset: {
      border: 0,
    },
    legend: theme.typography.h4,
    radioGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '24px',
      borderTop: `1px solid ${black}`,
      margin: '20px 0 64px',
    },
    radioButtonItem: {
      flex: '1 0 50%',
    },
    radioButtonContainer: {
      marginLeft: 0,
    },
    radioButton: {
      border: `2px solid ${theme.palette['ui-05']}`,
      height: 20,
      width: 20,
      flex: '0 0 20px',
      borderRadius: '50%',
    },
    checkedRadioButton: {
      border: `2px solid ${black}`,
      height: 20,
      width: 20,
      flex: '0 0 20px',
      borderRadius: '50%',
      '&:after': {
        backgroundColor: black,
      },
    },
    label: {
      fontWeight: semiBold,
      paddingLeft: '16px',
    },
    description: {
      fontWeight: roman,
      color: black,
      fontSize: '12px',
      lineHeight: '18px',
    },
    formActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '16px',
    },
    actionButton: {
      height: 40,
      borderRadius: 0,
      padding: '12px 16px',
    },
    cancelBtn: {
      border: `1px solid ${black}`,
      marginRight: '24px',
      color: black,
      backgroundColor: white,
      '&:hover': {
        backgroundColor: white,
      },
    },
    nextBtn: {
      color: white,
      backgroundColor: black,
      '&:hover': {
        backgroundColor: black,
      },
    },
  })
);
