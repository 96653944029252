import React from 'react';
import {makeStyles} from '@material-ui/styles';

import {RowWrap} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import {PlaylistTarget} from '../components';
import {black40, gold70, white} from '../../../consts/brand.integration';
import InfiniteScroller from '../../../components/Widgets/InfiniteScroller/InfiniteScroller';

interface Props {
  campaign: Campaign;
  influencers: Record<string, any>;
  loading: boolean;
  loadMore: () => Promise<void>;
  onMakeOffer: (influencerId: string, selectedDeliverables?: Record<string, any>) => void;
}

const CampaignTargetingResults: React.FC<Props> = ({
  campaign,
  influencers,
  loadMore,
  loading,
  onMakeOffer,
}) => {
  const classes = useStyles();

  const handleSendNotification = (
    influencerId: string,
    selectedDeliverables?: Record<string, any>
  ) => {
    onMakeOffer(influencerId, selectedDeliverables);
  };

  const targetInfluencers = influencers?.edges.map(edge => edge.node);

  return (
    <div className={classes.resultsWrapper}>
      {influencers && influencers.count > 1 && (
        <div className={classes.influencerCount}>
          {numberWithCommas(influencers.count)} influencers
        </div>
      )}

      {targetInfluencers && (
        <InfiniteScroller
          loading={loading}
          loadMore={loadMore}
          hasMore={influencers.pageInfo.hasNextPage}
        >
          <RowWrap>
            {targetInfluencers.map(influencer => (
              <PlaylistTarget
                key={influencer.id}
                campaign={campaign}
                influencer={influencer}
                onNotify={selectedDeliverables =>
                  selectedDeliverables?.length
                    ? handleSendNotification(influencer.id, selectedDeliverables)
                    : handleSendNotification(influencer.id)
                }
              />
            ))}
          </RowWrap>
        </InfiniteScroller>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  resultsWrapper: {
    position: 'relative',
    marginBottom: '15px',
    overflow: 'auto',
    marginLeft: '-25px',
    marginRight: '-25px',
    padding: '0 25px',
  },
  influencerCount: {
    textAlign: 'left',
    fontSize: '14px',
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
    color: black40,
    fontWeight: 500,
  },
  removeOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: gold70,
    borderRadius: '8px',
    color: white,
    padding: '100px',
    fontSize: '22px',
    textAlign: 'center',
  },
  trashCan: {
    fontSize: '80px',
    marginTop: '-100px',
    marginBottom: '15px',
  },
});

export default CampaignTargetingResults;
