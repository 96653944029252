import React from 'react';
import {ButtonGroup} from '@material-ui/core';

import {useStyles} from './styles';
import {InsightSwitchType} from './types';
import ItemFilter from '../../../components/Widgets/ItemFilter';

export const InsightsSwitchView: React.FC<InsightSwitchType> = ({mine, setSwitch}) => {
  const classes = useStyles();
  return (
    <>
      <ButtonGroup>
        {['All', 'Only My Campaigns'].map((state, index) => {
          const checked = state !== 'All';
          return (
            <ItemFilter
              key={index}
              title={state}
              className={
                (!checked && !mine) || (checked && mine) ? classes.checked : classes.button
              }
              handleClick={() => {
                setSwitch(checked);
              }}
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default InsightsSwitchView;
