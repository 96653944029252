import {Grid, Typography} from '@material-ui/core';
import React from 'react';

import {DocumentTitle} from 'components/Widgets';

import {getPageTitle} from '../../../consts/utilities';
import {LoginFormContainer} from '../components';
import {useStyles} from './styles';

const Login: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <DocumentTitle title={getPageTitle('Login')} />

      <Grid container alignItems="stretch">
        <Grid
          item
          container
          xs={12}
          lg={6}
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Typography variant="h1" className={classes.mainHeader}>
            TAKUMI
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={6}
          justifyContent="center"
          alignItems="center"
          className={classes.loginFormGrid}
        >
          <LoginFormContainer />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
