import React, {PureComponent} from 'react';
import {gql} from '@apollo/client';
import {Query} from '@apollo/client/react/components';

import client from '../client';

const hasOwn = Object.prototype.hasOwnProperty;

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function withHookPagination(query, field, data, variables, fetchMore) {
  return withPagination(query, field)({data: {...data, variables, fetchMore}}).loadMore;
}

export function withPagination(query, field) {
  return props => {
    const {data} = props;
    const {fetchMore} = data;

    return {
      ...props,
      loadMore: () => {
        return fetchMore({
          query,
          variables: {
            ...data.variables,
            cursor: data[field].pageInfo.endCursor,
          },
          updateQuery: (previousResult, {fetchMoreResult}) => {
            const newEdges = fetchMoreResult[field].edges;
            const pageInfo = fetchMoreResult[field].pageInfo;

            if (
              !fetchMoreResult ||
              previousResult[field].pageInfo.endCursor === pageInfo.endCursor
            ) {
              return previousResult;
            }

            return {
              // Put the new items at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              ...previousResult,
              [field]: {
                ...previousResult[field],
                __typename: data[field].__typename,
                edges: [...previousResult[field].edges, ...newEdges],
                pageInfo,
              },
            };
          },
        });
      },
    };
  };
}

export const withDebouncedProps =
  (propNames = [], debounce = 300) =>
  WrappedComponent =>
    class WithDebouncedProps extends PureComponent {
      static displayName = `withDebouncedProps(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      constructor(props) {
        super(props);
        this.state = this.generateNextState(props, {debouncing: false});
      }

      generateNextState(props, state) {
        return propNames.reduce(
          (nextState, propName) => ({
            ...nextState,
            [`${propName}Debounced`]: props[propName],
          }),
          state
        );
      }

      UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        if (shallowEqual(nextProps, prevProps)) {
          return;
        }

        clearTimeout(this.timeout);

        if (!this.state.debouncing) {
          this.setState({debouncing: true});
        }

        this.timeout = setTimeout(
          () =>
            this.setState({
              ...this.generateNextState(nextProps, this.state),
              debouncing: false,
            }),
          debounce
        );
      }

      componentWillUnmount() {
        clearTimeout(this.timeout);
      }

      render() {
        return <WrappedComponent {...this.props} {...this.state} />;
      }
    };

export function logProps(msg = 'logProps') {
  return function ppHOC(WrappedComponent) {
    return props => {
      console.log(msg, props); // eslint-disable-line
      return <WrappedComponent {...props} />;
    };
  };
}

export function requiresPermission(permission) {
  const query = gql`
    query RequiresPermissionQuery($permission: String!) {
      hasPermission(permission: $permission)
    }
  `;

  return async function (nextState, callback) {
    try {
      const {data} = await client.query({query, variables: {permission}});

      if (!data.hasPermission) {
        window.location.replace(`/404`);
      }
      callback();
    } catch (error) {
      callback(error);
    }
  };
}

export function getMessageForError(error) {
  if (error.graphQLErrors) {
    return error.graphQLErrors
      .map(e => {
        let message = e.message;

        if (e.errors) {
          message += '\n' + e.errors.join('\n');
        }

        return message;
      })
      .join('');
  }

  return error.message;
}

export const PaginatedQuery = ({query, variables, children, field, ...props}) => {
  if (!field) {
    field = query.definitions
      .filter(x => x.operation == 'query')[0]
      .selectionSet.selections.filter(x => x.kind == 'Field')[0].alias.value;
  }

  const loadNextPage = withPagination(query, field);

  return (
    <Query query={query} variables={variables} notifyOnNetworkStatusChange {...props}>
      {({data, fetchMore, ...renderProps}) =>
        children({
          ...renderProps,
          data,
          loadMore: loadNextPage({data: {...data, fetchMore, variables}}).loadMore,
        })
      }
    </Query>
  );
};
