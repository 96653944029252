import PropTypes from 'prop-types';
import React from 'react';

import {LoaderDots} from '../';
import styles from './style.css';

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};

export default class ImageLoader extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {status: props.src ? Status.LOADING : Status.PENDING};
  }

  componentDidMount() {
    if (this.state.status === Status.LOADING) {
      this.createLoader();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({
        status: nextProps.src ? Status.LOADING : Status.PENDING,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.status === Status.LOADING && !this.img) {
      this.createLoader();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  getClassName() {
    let className = `imageloader ${this.state.status}`;
    if (this.props.className) className = `${className} ${this.props.className}`;
    return className;
  }

  createLoader() {
    this.destroyLoader(); // We can only have one loader at a time.

    this.img = new Image();
    this.img.onload = this.handleLoad;
    this.img.onerror = this.handleError;
    this.img.src = this.props.src;
  }

  destroyLoader() {
    if (this.img) {
      this.img.onload = null;
      this.img.onerror = null;
      this.img = null;
    }
  }

  handleLoad = event => {
    this.destroyLoader();
    this.setState({status: Status.LOADED});

    if (this.props.onLoad) this.props.onLoad(event);
  };

  handleError = error => {
    this.destroyLoader();
    this.setState({status: Status.FAILED});

    if (this.props.onError) this.props.onError(error);
  };

  renderImg = () => {
    const {alt, src, srcSet, title} = this.props;
    return <img alt={alt} src={src} srcSet={srcSet} title={title} />;
  };

  render() {
    if (typeof window === 'undefined') {
      return this.renderImg(); // When rendering on the server
    }

    const wrapperProps = {
      className: this.getClassName(),
    };

    if (this.props.style) {
      wrapperProps.style = this.props.style;
    }

    switch (this.state.status) {
      case Status.LOADING:
        return (
          <div className={styles.loader}>
            <LoaderDots />
          </div>
        );

      case Status.LOADED:
        return this.renderImg();

      case Status.FAILED:
        return this.props.children || null;

      default:
        return null;
    }
  }
}
