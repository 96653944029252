import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';

import {
  Form,
  FormCheckbox,
  FormCheckboxGroup,
  FormRadio,
  FormRegionPicker,
  FormExponentialRangeSlider,
  FormSubmitButton,
  SelfTagModalInput,
} from 'components/Widgets/Forms';
import {LoaderDots} from 'components/Widgets';

import App404 from 'scenes/App404/App404';
import {interestsItems} from 'consts/targetingValues';
import {marketFromSlug, numberMillified} from 'consts/utilities';

import {TargetingEstimate} from './components';
import {serialize, deserialize} from './services';
import styles from './style.css';
import {CampaignHeader} from '../components';

const MAX_FOLLOWERS_CUTOFF = 1000000;

class Targeting extends PureComponent {
  static propTypes = {
    params: PropTypes.shape({
      campaign: PropTypes.string,
    }),
    loading: PropTypes.bool,
    campaign: PropTypes.shape({
      targeting: PropTypes.shape({
        regions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
        ages: PropTypes.arrayOf(PropTypes.number),
        interests: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          })
        ),
        gender: PropTypes.string,
        maxFollowers: PropTypes.number,
        minFollowers: PropTypes.number,
        absoluteMinFollowers: PropTypes.number,
        verifiedOnly: PropTypes.bool,
      }),
    }),
    regions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    targetCampaign: PropTypes.func,
    followerEstimate: PropTypes.object,
    influencerEstimate: PropTypes.object,
    getTargetingEstimate: PropTypes.func,
    initialMinFollowers: PropTypes.number,
    initialMaxFollowers: PropTypes.number,
  };

  valueToKNotation = value => {
    if (value >= MAX_FOLLOWERS_CUTOFF) return '1M+';
    return numberMillified(value);
  };

  getTargetingEstimates = debounce(model => {
    this.props.getTargetingEstimate(
      serialize(model, this.props.campaign.targeting.absoluteMinFollowers, MAX_FOLLOWERS_CUTOFF)
    );
  }, 400);

  createMinMaxLabelRenderer =
    (formatter = x => x) =>
    value => {
      if (!value && value !== 0) return '';
      if (typeof value == 'number') return formatter(value);
      const min = formatter(value[0]);
      const max = formatter(value[1]);
      if (min === max) return max;
      return `${min} – ${max}`;
    };

  saveCampaign = model => {
    const serialized = serialize(
      model,
      this.props.campaign.targeting.absoluteMinFollowers,
      MAX_FOLLOWERS_CUTOFF
    );
    return this.props.targetCampaign(serialized);
  };

  render() {
    const {loading, campaign, regions, influencerEstimate, followerEstimate} = this.props;

    if (loading) {
      return <LoaderDots />;
    } else if (!campaign || Object.keys(campaign).length === 0) {
      return <App404 />;
    }

    const initialState = deserialize(campaign.targeting, MAX_FOLLOWERS_CUTOFF);

    return (
      <>
        <CampaignHeader title="Targeting" campaignId={campaign.id} />
        <div className={styles.root}>
          <div className={styles.targetingForm}>
            <Form
              initialState={initialState}
              onSubmit={this.saveCampaign}
              onChange={this.getTargetingEstimates}
            >
              <div className={styles.section}>
                <h3 className={styles.subHeading}>Regions</h3>
                <FormRegionPicker name="regions" availableRegions={regions} />
              </div>

              <div className={styles.section}>
                <h3 className={styles.subHeading}>Followers</h3>
                <div className={styles.followersInput}>
                  <FormExponentialRangeSlider
                    min={campaign.targeting.absoluteMinFollowers}
                    max={MAX_FOLLOWERS_CUTOFF}
                    base={10}
                    minExp={2}
                    maxExp={6}
                    step={1000}
                    renderValueLabel={this.createMinMaxLabelRenderer(this.valueToKNotation)}
                    name="followers"
                  />
                </div>
              </div>

              <div className={styles.section}>
                <h3 className={styles.subHeading}>State</h3>
                <FormCheckbox name="verifiedOnly" label="Verified only" />
              </div>

              <div className={styles.selfTagSection}>
                <h3 className={styles.subHeading}>Self tags</h3>
                <SelfTagModalInput name="information" />
              </div>

              <div className={styles.section}>
                <h3 className={styles.subHeading}>Gender</h3>
                <FormRadio name="gender" label="All" value="all" />
                <FormRadio name="gender" label="Male" value="male" />
                <FormRadio name="gender" label="Female" value="female" />
              </div>

              <div className={styles.section}>
                <h3 className={styles.subHeading}>Ages</h3>
                <FormCheckboxGroup name="ages">
                  <FormCheckbox label="18-24" value="18-24" />
                  <FormCheckbox label="25-30" value="25-30" />
                  <FormCheckbox label="31-35" value="31-35" />
                  <FormCheckbox label="36+" value="36+" />
                </FormCheckboxGroup>
              </div>

              <div className={styles.section}>
                <h3 className={styles.subHeading}>Interests</h3>
                <FormCheckboxGroup name="interests">
                  {interestsItems.map(interestItem => (
                    <FormCheckbox
                      key={interestItem.value}
                      label={interestItem.name}
                      value={interestItem.value}
                    />
                  ))}
                </FormCheckboxGroup>
              </div>

              <FormSubmitButton text="Save" onSuccessText="Saved" />
            </Form>
          </div>
          <div className={styles.targetingEstimate}>
            <div className={styles.box}>
              <h3 className={styles.boxTitle}>Targeting Estimate</h3>
              <p className={styles.boxSubTitle}>{marketFromSlug(campaign.marketSlug)}</p>
              <div className={styles.spacer} />
              <h3 className={styles.boxTitle}>Influencers</h3>
              <div className={styles.row}>
                <TargetingEstimate targetingEstimate={influencerEstimate} />
              </div>
              <div className={styles.spacer} />
              <h3 className={styles.boxTitle}>Followers</h3>
              <div className={styles.row}>
                <TargetingEstimate
                  targetingEstimate={followerEstimate}
                  numberFormatter={numberMillified}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const TargetingQuery = gql`
  query TargetingQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      marketSlug
      minFollowers
      targeting {
        regions {
          id
          name
        }
        interests {
          id
        }
        ages
        gender
        absoluteMinFollowers
        maxFollowers
        minFollowers
        verifiedOnly
        hasGlasses
        languages
        selfTags {
          id
        }
        eyeColours {
          id
        }
        childrenTargeting {
          id
          ages
          childGender
          hasBornChild
          hasUnbornChild
        }
        hairColourCategories
        hairTypes {
          id
        }
      }
    }
  }
`;

const RegionsQuery = gql`
  query RegionsQuery($marketSlug: String!) {
    regions(market: $marketSlug) {
      id
      name
    }
  }
`;

const TargetingEstimateQuery = gql`
  query TargetingEstimateQuery(
    $campaignId: UUIDString!
    $regions: [UUIDString]!
    $gender: GenderType
    $ages: [Int]
    $interests: [UUIDString]
    $maxFollowers: Int
    $minFollowers: Int
    $information: InformationParams
  ) {
    followerEstimate(
      campaignId: $campaignId
      regions: $regions
      gender: $gender
      ages: $ages
      interests: $interests
      maxFollowers: $maxFollowers
      minFollowers: $minFollowers
      information: $information
    ) {
      verified
      eligible
      total
    }
    influencerEstimate(
      campaignId: $campaignId
      regions: $regions
      gender: $gender
      ages: $ages
      interests: $interests
      maxFollowers: $maxFollowers
      minFollowers: $minFollowers
      information: $information
    ) {
      verified
      eligible
      total
    }
  }
`;

const TargetingMutation = gql`
  mutation TargetingMutation(
    $campaignId: UUIDString!
    $regions: [UUIDString]
    $gender: String
    $ages: [Int]
    $interests: [UUIDString]
    $maxFollowers: Int
    $minFollowers: Int
    $verifiedOnly: Boolean
    $information: InformationParams
  ) {
    targetCampaign(
      id: $campaignId
      regions: $regions
      gender: $gender
      ages: $ages
      interestIds: $interests
      maxFollowers: $maxFollowers
      minFollowers: $minFollowers
      verifiedOnly: $verifiedOnly
      information: $information
    ) {
      campaign {
        id
        targeting {
          regions {
            id
            name
          }
          interests {
            id
          }
          ages
          gender
          maxFollowers
          minFollowers
          hasGlasses
          languages
          childrenTargeting {
            id
            ages
            childGender
            hasBornChild
            hasUnbornChild
          }
          selfTags {
            id
          }
          eyeColours {
            id
          }
          hairColourCategories
          hairTypes {
            id
          }
        }
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(TargetingQuery, {
    options: ({match: {params}}) => ({
      variables: {
        campaignId: params.campaign,
      },
    }),
    props: ({data: {campaign = {}, loading}}) => ({campaign, loading}),
  }),
  graphql(RegionsQuery, {
    skip: ({campaign: {marketSlug}}) => !marketSlug,
    options: ({campaign}) => ({
      variables: {
        marketSlug: campaign.marketSlug,
      },
    }),
    props: ({data: {regions}}) => ({regions}),
  }),
  graphql(TargetingEstimateQuery, {
    skip: ({campaign: {targeting}}) => !targeting,
    props: ({
      ownProps: {
        match: {params},
      },
      data: {refetch, followerEstimate, influencerEstimate},
    }) => ({
      followerEstimate,
      influencerEstimate,
      getTargetingEstimate: model => refetch({campaignId: params.campaign, ...model}),
    }),
    options: ({match: {params}, campaign: {targeting = {}}}) => ({
      variables: {
        campaignId: params.campaign,
        regions: targeting.regions?.map(region => region.id),
        interests: targeting.interests?.map(interest => interest.id),
        ages: targeting.ages,
        gender: targeting.gender,
        maxFollowers: targeting.maxFollowers,
        minFollowers: targeting.minFollowers,
        information: deserialize(targeting).information,
      },
    }),
  }),
  graphql(TargetingMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
      },
    }) => ({
      targetCampaign: model => mutate({variables: {campaignId: params.campaign, ...model}}),
    }),
  })
)(Targeting);
