import React, {useEffect, useRef, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {REPORT_OPTIONS} from '../../../consts/variables';
import {black40} from '../../../consts/brand';
import {LoaderDots, Modal} from '../../../components/Widgets';
import {ModalElement} from '../../Widgets/Modal';

interface Props {
  gig: Gig;
}

const GigReport: React.FC<Props> = ({gig}) => {
  const classes = useStyles();
  const [otherSelected, setOtherSelected] = useState(false);
  const [reportGig, {loading}] = useMutation(ReportMutation);
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      reason: '',
      otherReason: '',
    },
    validationSchema: yup.object({
      reason: yup.string().required(),
      otherReason: yup.lazy(() => {
        return otherSelected ? yup.string().required() : yup.string().notRequired();
      }),
    }),
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    if (formik.values.reason) {
      setOtherSelected(formik.values.reason === 'other');
    }
  }, [formik.values.reason]);

  const handleSubmit = () => {
    const reason = otherSelected ? formik.values.otherReason : formik.values.reason;
    reportGig({variables: {id: gig.id, reason: reason}});
    modal.current?.close();
  };

  const renderForm = () => (
    <FormControl component="fieldset" fullWidth>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <RadioGroup
            aria-label="reason"
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
          >
            {REPORT_OPTIONS.map((option: string, index: number) => (
              <FormControlLabel
                key={`option-${index}`}
                id={`option-${index}`}
                value={option}
                control={<Radio color="primary" />}
                label={option}
              />
            ))}
            <FormControlLabel
              key={`option-other`}
              id={`option-other`}
              value="other"
              control={<Radio color="primary" />}
              label="Other reason"
              checked={otherSelected}
            />
          </RadioGroup>
        </Grid>
        <Grid item>
          {otherSelected && (
            <TextField
              id="other-reason"
              name="otherReason"
              label="Reason"
              value={formik.values.otherReason}
              aria-describedby={'otherReason'}
              error={formik.touched.otherReason}
              variant="outlined"
              minRows={4}
              maxRows={10}
              fullWidth
              multiline
              required
              onChange={formik.handleChange}
            />
          )}
        </Grid>
      </Grid>
    </FormControl>
  );

  return (
    <Modal
      ref={modal}
      id="gig-report"
      title="Report"
      modalToggler={
        <Button className={classes.toggler} variant="text" fullWidth>
          Report
        </Button>
      }
      footer={
        <Typography variant="caption" className={classes.footer}>
          Reports are reviewed and handled by TAKUMI
        </Typography>
      }
      loading={loading}
      submitTitle="Submit"
      fullWidth
      maxWidth="xs"
      showClose
      disabled={!formik.values.reason && !formik.values.otherReason}
      onSubmit={formik.submitForm}
    >
      {loading ? <LoaderDots /> : renderForm()}
    </Modal>
  );
};

const useStyles = makeStyles({
  toggler: {
    color: 'red',
  },
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: black40,
  },
});

const ReportMutation = gql`
  mutation ReportGig($id: UUIDString!, $reason: String!) {
    reportGig(id: $id, reason: $reason) {
      ok
      gig {
        id
        state
        reportReason
        canBeReported
      }
    }
  }
`;

export default GigReport;
