import React from 'react';
import {Grid} from '@material-ui/core';

import {NoMetricData} from 'components/Widgets/Alerts';

import {
  formattedMetrics,
  formattedChangeMetrics,
  arrowDirection,
  shouldDisplayMetric,
} from '../helper';
import {numberWithCommas} from '../../../../../consts/utilities';
import MetricItem from '../../../../Dashboard/CampaignDetails/components/MetricItem';

type ApiResponse = {
  influencer_count: number;
  digital_content_count: number;
  impression_total: number;
  reach_total: number;
  engagement_total: number;
  engagement_rate_by_impressions: number;
  organic_currency_code?: string;
  impression_change: number;
  reach_change: number;
  engagement_change: number;
  engagement_rate_by_impressions_percent_change_average: number;
};

interface Props {
  summaryMetrics: ApiResponse[];
  modelName: string;
  currency?: string;
  enabledMetrics?: string[];
}

const dailyChangeMetrics = {
  impression_total: 'impression_change',
  reach_total: 'reach_change',
  engagement_total: 'engagement_change',
  engagement_rate_by_impressions: 'engagement_rate_by_impressions_percent_change_average',
};

const SummaryMetrics: React.FC<Props> = ({summaryMetrics, enabledMetrics, modelName, currency}) => {
  const currentMetrics = summaryMetrics[0];
  const metricsToShow = Object.entries(currentMetrics).filter(([name]) =>
    shouldDisplayMetric(enabledMetrics ?? [], name)
  );
  const justifyContent =
    // eslint-disable-next-line no-nested-ternary
    metricsToShow.length === 1
      ? 'flex-start'
      : metricsToShow.length >= 4
      ? 'space-between'
      : 'space-evenly';

  if (metricsToShow.length === 0)
    return (
      <Grid item xs={12}>
        <NoMetricData type="summary" />
      </Grid>
    );

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent={justifyContent}>
          {metricsToShow.map(([metricName, metricValue], index) => {
            const formattedData = formattedMetrics(metricName, metricValue, modelName, currency);
            if (!formattedData) return null;

            const metric = currentMetrics[dailyChangeMetrics[metricName]];
            const arrow = arrowDirection(metric);
            const change = formattedChangeMetrics(metric);

            return (
              <Grid
                key={index}
                item
                xs={6}
                sm={4}
                md={2}
                aria-label={`Performance Metric - ${formattedData.metricDisplayName}`}
              >
                <MetricItem
                  title={formattedData.metricDisplayName}
                  description={formattedData.metricDescription}
                  value={numberWithCommas(metricValue)}
                  formattedValue={formattedData.formattedValue}
                  arrow={arrow}
                  changedMetric={change}
                  placement="bottom"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryMetrics;
