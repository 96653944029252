import UUID from 'uuid-v4';
import _inRange from 'lodash/fp/inRange';
import _isNumber from 'lodash/fp/isNumber';

export const isRequired = val => {
  return val !== undefined && val !== null && val !== '';
};

export const isUUID = val => val === undefined || UUID.isUUID(val);

export const maxLength = length => val => val && val.length <= length;

export const minLength = length => val => val && val.length >= length;

export const inLengthRange = (min, max) => val => val.length >= min && val.length <= max;

export const inRange = (min, max) => val => _inRange(min, max, val);

export const isNumber = val => _isNumber(val);

export const isEmail = val =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    val
  );

export const isInstagramUsername = val =>
  /^([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/.test(val);
