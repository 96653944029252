import {theme} from 'consts/theme';
import {numberMillified, formatPercentage} from 'consts/utilities';

import {ChangeableKeys, DataPoint} from './types';

type LineDataItem = {
  dataKey: ChangeableKeys;
  yAxisId: string;
  color: any;
  name: string;
  orientation?: string;
  tickFormatter?: (value: number) => string;
  calculateMaxAxisDomain?: (data: DataPoint[]) => number;
};

export const lineData: LineDataItem[] = [
  {
    dataKey: 'engagement_total',
    yAxisId: 'engagement',
    color: theme.palette['stats-general'],
    name: 'Engagements',
    tickFormatter: numberMillified,
    orientation: 'left',
  },
  {
    dataKey: 'engagement_rate_by_impressions',
    yAxisId: 'engagementRate',
    color: theme.palette['stats-er'],
    name: 'Engagement Rate',
    tickFormatter: formatPercentage,
    orientation: 'right',
    /**
     * Engagement rate is typically a low percentage, so we want to ensure that
     * any changes (i.e. from 1% to 2%) can be easily seen, which would not be
     * possible if the domain is 0-100.
     */
    calculateMaxAxisDomain: data =>
      Math.min(
        Math.max(
          ...data.map(({engagement_rate_by_impressions}) => engagement_rate_by_impressions)
        ) + 5, // Add +5 for padding so that it doesn't hit the top of the chart
        100
      ),
  },
  {
    dataKey: 'reach_total',
    yAxisId: 'reach',
    color: theme.palette['stats-reach'],
    name: 'Reach',
    tickFormatter: numberMillified,
    orientation: 'left',
  },
  {
    dataKey: 'impression_total',
    yAxisId: 'impressions',
    color: theme.palette['stats-impressions'],
    name: 'Impressions',
    tickFormatter: numberMillified,
    orientation: 'left',
  },
];
