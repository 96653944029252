import {gql, useQuery} from '@apollo/client';

const useRequiresPermission = (permission: string): boolean | undefined => {
  const {loading, data} = useQuery(RequiresPermissionQuery, {
    variables: {
      permission,
      options: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  if (loading) {
    return undefined;
  }

  return data ? Boolean(data.hasPermission) : false;
};

const RequiresPermissionQuery = gql`
  query RequiresPermissionQuery($permission: String!) {
    hasPermission(permission: $permission)
  }
`;

export default useRequiresPermission;
