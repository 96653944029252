import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {Box} from '@material-ui/core';

import {AdvertiserConfig} from '../../../../scenes/Advertiser/AdvertiserAccessSettings/types';
import ErrorReporting from '../../../../services/error-reporting';
import {RequiresPermission} from '../../../../components/Widgets';
import {
  ADVERTISER_SIDE_BAR_ACCESS_FIELD,
  ADVERTISER_SIDE_BAR_ROUTES,
  DEFAULT_CAMPAIGN_NAME,
  INTERACTIVE_INSIGHTS_ROUTE,
  SIDE_BAR_ROUTES,
} from './constants';
import {InsightsRoute} from '../../AppWrapper/components';
import {HeaderLogo} from '../index';
import {useStyles} from './styles';
import {ProfileMenu} from './ProfileMenu';
import useAdvertiserAccess from '../../../Advertiser/hooks/useAdvertiserAccess';
import {Advertiser, User, Campaign} from './types';
import useHomePage from '../../../../hooks/useHomePage';
import {UserRoles} from '../../../../consts/roles';
import {mainContentWidth} from '../../../../consts/brand.integration';
import NotificationsWidget from './NotificationsWidget';

interface Props {
  hasAdvertiser?: {
    advertiser?: string;
  };
  hasCampaign?: {
    campaign?: string;
  };
  window?: () => Window;
  children: React.ReactNode;
}

const SideBarMenu: React.FC<Props> = props => {
  const {window, hasAdvertiser, hasCampaign, children} = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const advertiserAccess: AdvertiserConfig | null = useAdvertiserAccess();
  const homePage = useHomePage();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const pathsForTitle = ['/campaigns', '/dashboard', '/users'];

  const variables = {
    advertiserDomain: hasAdvertiser?.advertiser ?? '',
    hasAdvertiser: Boolean(hasAdvertiser),
    campaignId: hasCampaign?.campaign ?? DEFAULT_CAMPAIGN_NAME,
    hasCampaign: Boolean(hasCampaign),
  };

  const {loading, data} = useQuery(TopNavigationQuery, {variables});

  const routes =
    data?.user?.roleName === UserRoles.Advertiser ? ADVERTISER_SIDE_BAR_ROUTES : SIDE_BAR_ROUTES;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (data?.user) {
      ErrorReporting.setUserContext({
        id: data.user.id,
        fullName: data.user.fullName,
        email: data.user.email,
        roleName: data.user.roleName,
      });
    }
  }, [data]);

  const getMenuItemAccess = (roleName: string, name: string) => {
    if (roleName === UserRoles.Advertiser) {
      const accessField = ADVERTISER_SIDE_BAR_ACCESS_FIELD[name];
      return advertiserAccess?.[accessField] ?? false;
    }
    return true;
  };

  if (loading) {
    return null;
  }

  const menuHamburger = (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      className={classes.menuHamburgerButton}
      onClick={handleDrawerToggle}
    >
      <MenuIcon />
    </IconButton>
  );

  const menuCloseButton = (
    <IconButton color="inherit" aria-label="close drawer" edge="start" onClick={handleDrawerToggle}>
      <CloseIcon />
    </IconButton>
  );

  const menuHeaderLogo = (user: User) => (
    <div className={classes.sideBarLogo}>
      <Link aria-label="home link" to={homePage} className={classes.menuHeaderLogo}>
        {user && <HeaderLogo height={22} logoUrl={user.theme?.logoUrl} />}
      </Link>
    </div>
  );

  const menuTopBar = (user: User) => (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {menuHamburger}
        <Typography component={'span'} className={classes.title}>
          {menuHeaderLogo(user)}
        </Typography>
        <NotificationsNoneIcon />
      </Toolbar>
    </AppBar>
  );

  const dynamicHeader = (name: string, link?: string) => (
    <div className={classes.header}>
      <IconButton className={classes.backButton} aria-label="Back" onClick={() => history.goBack()}>
        <ArrowBackRoundedIcon />
      </IconButton>
      <Typography variant="h1">{link ? <Link to={link}>{name}</Link> : name}</Typography>
    </div>
  );

  const appPageHeader = (path: string) => {
    if (!pathsForTitle.includes(path)) return null;
    if (data?.user?.roleName === UserRoles.Advertiser) {
      if (
        (!advertiserAccess?.dashboardPage && location.pathname === '/dashboard') ||
        (!advertiserAccess?.brandCampaignsPage && location.pathname === '/campaigns')
      ) {
        return null;
      }
    }

    const title = path.slice(1);
    return (
      <div className={classes.header}>
        <Typography variant="h1" className={classes.staticHeader}>
          {title}
        </Typography>
      </div>
    );
  };

  const getHeader = (advertiser: Advertiser, campaign: Campaign) => {
    if (advertiser) {
      return dynamicHeader(advertiser.name, `/brands/${isBrandPage()}`);
    }

    if (campaign) {
      return dynamicHeader(campaign.name);
    }

    return appPageHeader(location.pathname);
  };

  const appHeader = (advertiser: Advertiser, campaign: Campaign) => {
    return <div className={classes.appHeaderWrapper}>{getHeader(advertiser, campaign)}</div>;
  };

  const tabletDrawer = (user: User) => (
    <div>
      {menuHamburger}
      <NotificationsWidget userId={data?.user?.id} />
      <ProfileMenu user={user} mobileOpen={mobileOpen} />
    </div>
  );

  const isBrandPage = () => {
    const splitPathname = location.pathname.split('/');
    return splitPathname[1] === 'brands' && splitPathname[2] ? splitPathname[2] : null;
  };

  const desktopDrawer = (user: User) => (
    <div className={classes.listWrapper}>
      <div>
        {menuHeaderLogo(user)}
        {mobileOpen && menuCloseButton}
        <List>
          {Object.keys(routes).map(key => {
            if (key === INTERACTIVE_INSIGHTS_ROUTE) {
              return (
                <RequiresPermission
                  key={`${key}-${routes[key].name}`}
                  permission={routes[key].permissions}
                >
                  <ListItem key={key} className={classes.menuListItem} disableGutters>
                    <InsightsRoute />
                  </ListItem>
                </RequiresPermission>
              );
            }
            return (
              <RequiresPermission
                key={`${key}-${routes[key].name}`}
                permission={routes[key].permissions}
                hasAccess={getMenuItemAccess(data?.user?.roleName, key)}
              >
                <ListItem key={key} className={classes.menuListItem} disableGutters>
                  <NavLink
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    to={`/${key}`}
                  >
                    {routes[key].name}
                  </NavLink>
                </ListItem>
              </RequiresPermission>
            );
          })}
          <NavLink className={classes.link} activeClassName={classes.activeLink} to={`/faq`}>
            FAQ
          </NavLink>
        </List>
      </div>
      <Hidden smDown implementation="css">
        <NotificationsWidget userId={data?.user?.id} />
      </Hidden>
      <ProfileMenu user={user} mobileOpen={mobileOpen} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (data) {
    const {user, advertiser, campaign} = data;

    return (
      <div className={classes.root}>
        <CssBaseline />
        {menuTopBar(user)}
        <nav className={classes.drawer} aria-label="side bar navigation">
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              classes={{
                paper: classes.desktopDrawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              onClose={handleDrawerToggle}
            >
              {desktopDrawer(user)}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.desktopDrawerPaper,
              }}
              variant="permanent"
              open
            >
              {desktopDrawer(user)}
            </Drawer>
          </Hidden>
          <Hidden only={['xs', 'xl', 'lg', 'md']} implementation="css">
            <Drawer
              classes={{
                paper: classes.tabletDrawerPaper,
              }}
              variant="permanent"
              open
            >
              {tabletDrawer(user)}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Box paddingTop="20px" maxWidth={mainContentWidth} margin="auto">
            {appHeader(advertiser, campaign)}
            {children}
          </Box>
        </main>
      </div>
    );
  }
  return null;
};

const TopNavigationQuery = gql`
  query TopNavigationQuery(
    $advertiserDomain: String!
    $hasAdvertiser: Boolean!
    $campaignId: UUIDString!
    $hasCampaign: Boolean!
  ) {
    user: currentUser {
      id
      fullName
      roleName
      email
      profilePicture
      theme {
        id
        logoUrl
      }
    }
    advertiser(domain: $advertiserDomain) @include(if: $hasAdvertiser) {
      id
      name
      profilePicture
      archived
      influencerCooldown
    }
    campaign(id: $campaignId) @include(if: $hasCampaign) {
      id
      name
    }
  }
`;

export default SideBarMenu;
