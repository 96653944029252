import {Grid, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';

import UserCard from './UserCard';
import {black20, nearWhite} from '../../../../../consts/brand.integration';
import {GigCaption} from '../../../../../components/Campaign';

interface Props {
  historyItem: HistoryInterface;
}

const CardUpdateCaption: React.FC<Props> = ({historyItem}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <UserCard user={historyItem?.user} />
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={classes.caption}>
          <Typography variant="subtitle1">Caption:</Typography>
          <Typography variant="body2">
            "<GigCaption {...historyItem.submission} />"
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  caption: {
    margin: '0 1rem',
    padding: '1rem',
    backgroundColor: nearWhite,
    border: `1px dashed ${black20}`,
  },
});

export default CardUpdateCaption;
