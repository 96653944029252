import {createStyles, makeStyles} from '@material-ui/core/styles';

import {mainContentWidth} from '../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: mainContentWidth,
      margin: '0 auto',
      padding: 0,
    },
    root: {
      maxWidth: mainContentWidth,
    },
  })
);
