import {createStyles, makeStyles} from '@material-ui/core/styles';

import {white, black, roman, grey05} from '../../../../../../consts/brand.integration';
import {theme} from '../../../../../../consts/theme';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '24px',
    },
    prompt: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: '32px',
      border: `1px solid ${theme.palette['ui-05']}`,
      marginBottom: '24px',
    },
    addPromptChoiceButton: {
      color: black,
      fontSize: '12px',
      padding: 0,
      marginTop: '8px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    removePromptChoiceButton: {
      color: black,
      marginLeft: '24px',
      padding: '8px 0 0 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    removePromptButton: {
      position: 'absolute',
      top: '-24px',
      right: '-24px',
      color: black,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    typeButton: {
      backgroundColor: white,
      color: black,
      height: '32px',
      borderRadius: 0,
      border: `1px solid ${black}`,
      fontSize: '13px',
      fontWeight: roman,
      '&:hover': {
        backgroundColor: white,
      },
    },
    checked: {
      backgroundColor: black,
      color: `${white} !important`,
      borderRadius: 0,
      fontSize: '13px',
      fontWeight: roman,
      '&:hover': {
        backgroundColor: black,
      },
      '&:disabled': {
        color: `${white}90 !important`,
      },
    },
    addPromptBtn: {
      height: 40,
      borderRadius: 0,
      padding: '12px 16px',
      border: `1px solid ${black}`,
      color: black,
      backgroundColor: white,
      '&:hover': {
        backgroundColor: white,
      },
    },
    inputWrapperStyle: {
      width: '100%',
    },
    textInput: {
      border: `1px solid ${theme.palette['ui-05']}`,
      borderRadius: '2px',
      margin: '8px 0 0',
      paddingLeft: '16px',
      height: '48px',
      fontSize: '14px',
      '&:focus': {
        borderBottomColor: theme.palette['ui-05'],
      },
    },
    promptChoice: {
      display: 'flex',
      paddingBottom: '16px',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    subtitle: {
      marginTop: '38px',
      color: black,
    },
    checkboxWrapper: {
      display: 'flex',
      marginTop: '12px',
    },
    checkbox: {
      color: grey05,
      padding: 0,
      '&.Mui-checked': {
        color: black,
      },
      '&:hover': {
        backgroundColor: white,
      },
    },
    checkboxLabel: {
      marginLeft: '12px',
      color: black,
    },
    hiddenLabel: {
      display: 'none',
    },
  })
);
