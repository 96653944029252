import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import {withRouter} from 'react-router-dom';

import {Section, LoaderDots} from 'components/Widgets';
import {Form, FormTextArea, FormSubmitButton} from 'components/Widgets/Forms';

import styles from './style.css';
import {CampaignHeader} from '../components';

const CampaignDetailedReport = ({data: {loading, campaign}, setReportSummary}) => {
  const onSubmit = data => {
    return setReportSummary({...data, campaignId: campaign.id});
  };

  if (loading) {
    return (
      <div className={styles.emptyWrapper}>
        <LoaderDots />
      </div>
    );
  }
  const initialState = {summary: campaign.reportSummary || ''};

  return (
    <>
      <CampaignHeader title="Report" campaignId={campaign.id} />
      <Form initialState={initialState} onSubmit={onSubmit}>
        <Section title="Campaign summary">
          <div className={styles.rowWrapper}>
            <div className={styles.item}>
              <FormTextArea name="summary" />
              <small className={styles.description}>
                The summary is an optional campaign summary that will be visible in the campaign
                report the clients have access to
              </small>
            </div>
          </div>
        </Section>
        <div className={styles.buttons}>
          <div className={styles.saveButton}>
            <FormSubmitButton text="Save" />
          </div>
        </div>
      </Form>
    </>
  );
};

CampaignDetailedReport.propTypes = {
  data: PropTypes.shape({
    campaign: PropTypes.object,
    loading: PropTypes.bool,
  }),
  setReportSummary: PropTypes.func.isRequired,
};

const CampaignDetailedReportQuery = gql`
  query CampaignDetailedReportQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      reportSummary
    }
  }
`;

const SetReportSummaryMutation = gql`
  mutation SetReportSummary($campaignId: UUIDString!, $summary: String) {
    setReportSummary(id: $campaignId, summary: $summary) {
      ok
      campaign {
        id
        reportSummary
      }
    }
  }
`;
export default compose(
  withRouter,
  graphql(CampaignDetailedReportQuery, {
    options: ({match: {params}}) => ({
      variables: {
        campaignId: params.campaign,
      },
    }),
  }),
  graphql(SetReportSummaryMutation, {
    props: ({mutate}) => ({
      setReportSummary: data =>
        mutate({
          variables: data,
        }),
    }),
  })
)(CampaignDetailedReport);
