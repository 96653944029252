import React from 'react';
import {Card, CardContent, CardHeader, Grid, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useQuery} from '@apollo/client';
import {Alert} from '@material-ui/lab';

import {
  LoaderDots,
  Media,
  MediaContainer,
  ProfilePicture,
  PostTypeChip,
} from '../../../../../components/Widgets';
import {GigCaption} from '../../../../../components/Campaign';
import {black20, nearWhite} from '../../../../../consts/brand.integration';
import {GigQuery} from '../../queries';

const MEDIA_SIZE = 457;

interface Props {
  gigId: Gig;
}

const Submission: React.FC<Props> = ({gigId}) => {
  const classes = useStyles();
  const {loading, error, data} = useQuery<{gig: Gig}>(GigQuery, {
    variables: {
      gigId,
    },
  });

  return (
    <Card data-testid="unreviewed-submission">
      <CardHeader
        title={
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Submission</Typography>
            </Grid>
            {data?.gig && (
              <Grid item justifyContent="flex-end" data-testid="submission-postype">
                <PostTypeChip type={data.gig.post.postType} />
              </Grid>
            )}
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="column" spacing={4}>
          {error && <Alert severity="error">{error.message}</Alert>}
          {loading || !data ? (
            <Grid item xs style={{margin: 'auto'}}>
              <LoaderDots />
            </Grid>
          ) : (
            <>
              <Grid
                item
                container
                direction="row"
                xs={12}
                alignItems="center"
                className={classes.profileContainer}
                data-testid="submission-profile"
              >
                <Grid item>
                  <ProfilePicture src={data.gig?.influencer?.profilePicture} />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" className={classes.profileName}>
                    {data.gig.influencer.fullName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{margin: 'auto'}}>
                <MediaContainer
                  width={MEDIA_SIZE}
                  height={MEDIA_SIZE}
                  data-testid="submission-media"
                >
                  <Media
                    media={data.gig.submission?.media}
                    width={MEDIA_SIZE}
                    height={MEDIA_SIZE}
                  />
                </MediaContainer>
              </Grid>
              <Grid item>
                <Paper
                  variant="outlined"
                  className={classes.captionContainer}
                  data-testid="submission-caption"
                >
                  <Typography variant="body2" className={classes.caption}>
                    <GigCaption {...data.gig.submission} />
                  </Typography>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  profileContainer: {
    paddingLeft: '2.5rem !important',
  },
  profileName: {
    paddingLeft: '1rem',
  },
  captionContainer: {
    margin: '0 1rem',
    padding: '1rem',
    backgroundColor: nearWhite,
    border: `1px dashed ${black20}`,
  },
  caption: {
    padding: '0rem 1rem',
  },
});

export default Submission;
