import {createStyles, makeStyles} from '@material-ui/core';

import {
  black10,
  black50,
  black60,
  black90,
  blue,
  bold,
  green,
  purple,
  red,
  sizeCaption,
  sizeH4,
  transitionTimeBase,
  white,
} from '../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      fontWeight: bold,
      fontSize: sizeCaption,
      lineHeight: 2,
      alignItems: 'flex-end',
      textTransform: 'lowercase',
      fontVariant: 'small-caps',
      padding: '2px 8px',
      border: `1px solid ${black50}`,
      cursor: 'pointer',
      '& i': {
        marginRight: '5px',
        fontSize: sizeH4,
        lineHeight: '26px',
      },
      '& p': {
        marginLeft: '5px',
        maxWidth: '140px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    verified: {
      color: green,
      borderColor: green,
    },
    unverified: {
      color: black60,
    },
    loading: {
      borderColor: black10,
    },
    cooldown: {
      color: blue,
      borderColor: blue,
    },
    disabled: {
      color: red,
      borderColor: red,
    },
    inputWrapper: {
      display: 'flex',
      margin: '10px 0 10px 0',
    },
    input: {
      backgroundColor: black90,
      color: white,
      padding: '4px',
      border: `1px solid ${black60}`,
      width: '140px',
    },
    inputButton: {
      position: 'relative',
      top: '4px',
      left: '6px',
      cursor: 'pointer',
    },
    optionsWrapper: {
      padding: '4px 0',
      '& ul': {
        listStyle: 'none',
        '& li': {
          marginBottom: '10px',
          cursor: 'pointer',
        },
        '& li:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    stateName: {
      display: 'flex',
      fontSize: sizeCaption,
      fontWeight: bold,
      transition: `color ${transitionTimeBase}`,
      '& i': {
        marginRight: '10px',
        marginTop: '-1px',
        transition: `color ${transitionTimeBase}`,
        fontSize: sizeH4,
      },
    },
    stateDescription: {
      marginLeft: '28px',
      maxWidth: '166px',
      fontSize: sizeCaption,
      lineHeight: '1.1',
      fontWeight: bold,
      color: black60,
    },
    stateOptionWrapper: {
      padding: '4px',
      border: '1px solid transparent',
      transition: `border-color ${transitionTimeBase}`,
      '&:hover': {
        borderColor: black60,
      },
    },
    verified__hover: {
      '&:hover': {
        color: green,
      },
    },
    unverified__hover: {
      '&:hover': {
        color: black60,
      },
    },
    disabled__hover: {
      '&:hover': {
        color: red,
      },
    },
    cooldown__hover: {
      '&:hover': {
        color: blue,
      },
    },
    blockOptionsWrapper: {
      marginTop: '15px',
    },
    blockOption: {
      color: white,
      transition: 'all 0.2s',
      '&:hover': {
        color: purple,
      },
    },
  })
);
