import moment from 'moment';

import {ChangeKeys, ChangeableKeys, DataPoint} from './types';
import {CALENDAR_DATE_FORMAT} from '../../../../../consts/variables';

function formatDate(dateString: string, format: string): string {
  return moment(dateString).format(format);
}

export const formattedDate = (dateString: string) => formatDate(dateString, CALENDAR_DATE_FORMAT);

const calculateChangeForKeys = (data: DataPoint[], keys: ChangeableKeys[]) => {
  return data.map((item, index) => {
    if (index === 0) return {...item};

    const changes: Partial<ChangeKeys> = {};
    for (const key of keys) {
      const changeKey = `${key}_change` as keyof ChangeKeys;
      changes[changeKey] = item[key] - data[index - 1][key];
    }

    return {...item, ...changes};
  });
};

export const calculateDailyChange = (data: DataPoint[]) => {
  const keysToCalculateChangeFor: ChangeableKeys[] = [
    'engagement_total',
    'engagement_rate_by_impressions',
    'reach_total',
    'impression_total',
  ];

  return calculateChangeForKeys(data, keysToCalculateChangeFor);
};

export const hasValues = (
  metric: ChangeableKeys,
  data: ReturnType<typeof calculateDailyChange>
): boolean => {
  return data.some(item => item[metric] != null && item[metric] !== 0);
};
