export const performanceCoverSheet = [
  [
    'For documentation on all worksheet metrics, please visit: https://data-api-docs.development.takumi.com/#/',
    '',
    'https://data-api-docs.development.takumi.com/#/',
  ],
  [''],
  [
    'Summary:',
    'This model captures the headline numbers of performance of different campaigns including impressions, reach, engagements, and engagement rates.',
    'https://data-api-docs.development.takumi.com/#/campaign_performance',
  ],
  [
    'Platforms:',
    'This model records different campaigns performance, for each social media platform.',
    'https://data-api-docs.development.takumi.com/#/campaign_performance_by_platform',
  ],
  [
    'Creators:',
    'This model records different campaigns performance, for each influencer.',
    'https://data-api-docs.development.takumi.com/#/campaign_performance_by_influencer',
  ],
  [
    'Gigs:',
    'This model records different campaigns performance, for each post. Note that the initial build of these records is built not based on the data at the time the campaign was live, but based on the data as it was when the records were build. Effectively this means the data in this model may be different to the `campaign_performance_*history` models.',
    'https://data-api-docs.development.takumi.com/#/campaign_performance_by_gig',
  ],
  [
    'Paid Media Summary:',
    'Latest metrics and insights for all ads, grouped by the TAKUMI campaign. All ads within a single TAKUMI campaign will be included, even if they are part of several different advertising campaigns, across multiple platforms.',
    'https://data-api-docs.development.takumi.com/#/paid_ad_performance_by_campaign',
  ],
  [
    'Paid Media (Instagram):',
    'Latest metrics and insights for paid Instagram ads (posts, stories & reels).',
    'https://data-api-docs.development.takumi.com/#/paid_ad_performance_instagram_by_ad',
  ],
  [
    'Paid Media (TikTok):',
    'Latest metrics and insights for paid TikTok ads.',
    'https://data-api-docs.development.takumi.com/#/paid_ad_performance_tiktok_post_by_ad',
  ],
  [
    'Paid Media Adverts:',
    'Latest metrics and insights for paid ads on all platforms.',
    'https://data-api-docs.development.takumi.com/#/paid_ad_performance_by_ad',
  ],
];
