import PropTypes from 'prop-types';
import React from 'react';

import {MaterialDesignIcon} from 'components/Icons';

import {interestsItems} from 'consts/targetingValues';

import {Tooltip} from '../';

const InterestIcon = ({interestId, className}) => {
  const matchedItem = interestsItems.find(item => item.value === interestId);
  if (!matchedItem) {
    return null;
  }
  return (
    <Tooltip
      key={interestId}
      overlay={<span>{matchedItem.name}</span>}
      mouseEnterDelay={0.2}
      placement="bottom"
    >
      <MaterialDesignIcon icon={matchedItem.meta} className={className} />
    </Tooltip>
  );
};

InterestIcon.propTypes = {
  interestId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InterestIcon;
