import React from 'react';

import {coloursClientWebApp} from 'consts/brand.integration';
import {InfluenscoreChartProps} from './InfluenscoreChart';
import RadialBarChart from './RadialBarChart';

type InfluenscoreChartStaticProps = {
  size: number;
  fontSize?: number;
} & InfluenscoreChartProps;

export const InfluenscoreChartStatic = ({
  values: {overall, cpf, cpr, cpm, cpe},
  size,
  fontSize = 12,
}: InfluenscoreChartStaticProps) => {
  return (
    <RadialBarChart
      size={size}
      centerText={overall?.toFixed(1)}
      fontSize={fontSize}
      rings={[
        {value: (cpr ?? 0) / 10, color: coloursClientWebApp.vividSkyBlue},
        {value: (cpe ?? 0) / 10, color: coloursClientWebApp.sunglow},
        {value: (cpm ?? 0) / 10, color: coloursClientWebApp.malachite},
        {value: (cpf ?? 0) / 10, color: coloursClientWebApp.ruddy},
      ]}
    />
  );
};
