import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {CampaignGig} from 'components/Campaign';

const SubmittedGig = ({history, location}) => {
  const navigate = pathname => {
    history.replace({...location, pathname});
  };

  return (
    <CampaignGig goBack={() => navigate('/gigs/')} goToGig={gigId => navigate(`/gigs/${gigId}`)} />
  );
};

SubmittedGig.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SubmittedGig);
