import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {Box} from '@material-ui/core';

import styles from './style.css';

interface Props {
  width: number;
  height?: number;
  children: ReactNode | ReactNode[];
  lightBackground?: boolean;
}

const MediaContainer: React.FC<Props> = ({height, width, children, lightBackground, ...props}) => (
  <Box
    className={classNames([styles.root, lightBackground && styles.lightBackground])}
    style={{flex: `0 0 ${width}px`, height: `${height}px`, width: `${width}px`}}
    {...props}
  >
    {children}
  </Box>
);

export default MediaContainer;
