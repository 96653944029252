import React from 'react';
import {Box, Button, Grid, makeStyles, Tooltip, Typography} from '@material-ui/core';
import {Lock} from '@material-ui/icons';

interface Props {
  first?: boolean;
  title: string;
  details?: any;
  customClassName?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  disabledReason?: string;
  overrideDisabled?: () => void;
  disabledOverrideLabel?: string;
}

const Section: React.FC<Props> = ({
  first,
  title,
  details,
  customClassName,
  children,
  disabled,
  disabledReason,
  overrideDisabled,
}) => {
  const classes = useStyles();
  return (
    <section className={first ? `${classes.root} ${classes.first}` : classes.root}>
      <Grid container className={customClassName}>
        <Grid item container>
          <Grid item xs>
            <Typography variant="h4" component="h2" className={classes.title}>
              {title} {details?.label}
            </Typography>
          </Grid>
          {disabled && (
            <Grid item>
              <Box alignSelf="flex-end">
                {overrideDisabled ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Lock />}
                    onClick={overrideDisabled}
                  >
                    Change
                  </Button>
                ) : (
                  <Tooltip title={disabledReason ? disabledReason : ''} arrow>
                    <Lock />
                  </Tooltip>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        &nbsp;
        {details && (
          <Grid item className={classes.description}>
            <Typography variant="caption">{details.description}</Typography>
          </Grid>
        )}
      </Grid>
      <hr />
      {children}
    </section>
  );
};

const useStyles = makeStyles({
  root: {
    marginTop: 60,
  },
  first: {
    marginTop: 0,
  },
  title: {
    marginBottom: 10,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 15,
  },
});

export default Section;
