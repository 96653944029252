import PropTypes from 'prop-types';
import React from 'react';

import {Row, Column} from 'components/Widgets';
import {
  Form,
  FormSelect,
  FormSubmitButton,
  FormTextInput,
  FormEmailInput,
} from 'components/Widgets/Forms';

import {isRequired} from 'services/validators';

const rolesToOptions = roles => roles.map(role => ({name: role.name, value: role.name}));

const initialState = {
  email: '',
  name: '',
  role: '',
};

const InviteUserForm = ({submitInvite, roles}) => (
  <Form initialState={initialState} onSubmit={submitInvite}>
    <Row>
      <Column>
        <FormEmailInput name="email" />
      </Column>
      <Column>
        <FormTextInput
          name="name"
          placeholder="Name"
          validators={{isRequired}}
          show="touched"
          messages={{isRequired: 'You need to provide a name'}}
        />
      </Column>
      <Column>
        <FormSelect
          name="role"
          options={rolesToOptions(roles)}
          canBeEmpty
          validators={{isRequired}}
          show="touched"
          messages={{isRequired: 'You need to provide a role'}}
        />
      </Column>
      <Column>
        <FormSubmitButton text="Invite" />
      </Column>
    </Row>
  </Form>
);

InviteUserForm.propTypes = {
  submitInvite: PropTypes.func.isRequired,
  roles: PropTypes.array,
};

export default InviteUserForm;
