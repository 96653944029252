import React from 'react';
import PropTypes from 'prop-types';

import {Form, FormEmailInput, FormSubmitButton} from 'components/Widgets/Forms';

const RecoverPasswordEmailForm = ({onSubmit}) => (
  <Form onSubmit={onSubmit}>
    <FormEmailInput name="email" />
    <FormSubmitButton block style={{width: 351.11}} text="Send verification email" />
  </Form>
);

RecoverPasswordEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RecoverPasswordEmailForm;
