import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import {graphql} from '@apollo/client/react/hoc';
import {Button} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {Prompt} from 'components/Widgets';

const ForceApproveButton = ({offer, forceApprove}) => {
  return (
    <Prompt
      title="Force approve"
      body="Are you sure you want to force approve this candidate on behalf of the client?"
      confirmText="Force approve"
      control={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="carbon:checkmark" />}
          style={{margin: '0 5px'}}
        >
          Force Approve
        </Button>
      }
      onSubmit={() => forceApprove({id: offer.id})}
    />
  );
};

ForceApproveButton.propTypes = {
  offer: PropTypes.object.isRequired,
  forceApprove: PropTypes.func.isRequired,
};

const ForceApproveMutation = gql`
  mutation ForceApprove($id: UUIDString!) {
    approveCandidate(offerId: $id) {
      ok
      offer {
        id
        state
        formattedState
      }
    }
  }
`;

export default compose(
  graphql(ForceApproveMutation, {
    props: ({mutate}) => ({
      forceApprove: variables => mutate({variables}),
    }),
  })
)(ForceApproveButton);
