import React from 'react';
import {Divider, makeStyles, Grid, Typography} from '@material-ui/core';

import PlatformCard from './PlatformCard';

const modelName = 'paid_performance';

const platforms: Record<string, {name: string; icon: string}> = {
  instagram: {
    name: 'Instagram',
    icon: 'skill-icons:instagram',
  },
  tiktok: {
    name: 'TikTok',
    icon: 'logos:tiktok-icon',
  },
};

type Props = {
  title: string;
  data: Record<keyof typeof platforms, Record<string, number>>;
};

const PaidPlatformMetrics: React.FC<Props> = ({title, data}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3}>
        {Object.entries(data).map(([platform, metrics], index) => (
          <PlatformCard
            key={index}
            platformName={platforms[platform].name}
            platformIcon={platforms[platform].icon}
            metrics={metrics}
            modelName={modelName}
          />
        ))}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default PaidPlatformMetrics;
