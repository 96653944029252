import {makeStyles} from '@material-ui/styles';

import {theme} from '../../../consts/theme';

export const useStyles = makeStyles({
  checked: {
    backgroundColor: theme.palette['text-01'],
    color: theme.palette['text-inverse'],
    border: `1px solid ${theme.palette['text-01']}`,
    borderRadius: 0,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette['text-01'],
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
  button: {
    backgroundColor: theme.palette['text-inverse'],
    border: `1px solid ${theme.palette['text-01']}`,
    color: theme.palette['text-01'],
    borderRadius: 0,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette['text-inverse'],
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
});
