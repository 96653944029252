import React from 'react';
import {
  Divider,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {TableZebraRow, Tooltip} from '../../../../../components/Widgets';
import {numberWithCommas} from '../../../../../consts/utilities';
import {formattedMetrics} from '../helper';

const PlatformCard: React.FC<{
  platformName: string;
  platformIcon: string;
  metrics: Record<string, number>;
  modelName: string;
}> = ({platformName, platformIcon, metrics, modelName}) => (
  <Grid item xs={12} sm={12} md={6}>
    <Card>
      <CardContent>
        <Typography variant="h4" paragraph>
          <Icon icon={platformIcon} width={24} />
          &nbsp; {platformName}
        </Typography>
        <Divider />
        <TableContainer>
          <Table aria-label="Platform Data">
            <TableBody>
              {Object.entries(metrics).map(([metricName, metricValue], index) => {
                const formattedData = formattedMetrics(metricName, metricValue, modelName);
                if (!formattedData) return null;

                return (
                  <TableZebraRow key={`metric-${index}`}>
                    <TableCell component="th" scope="row" align="left">
                      <strong>{formattedData.metricDisplayName}</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip overlay={numberWithCommas(metricValue)} placement="left">
                        <Typography variant="body1" component="span">
                          {formattedData.formattedValue}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableZebraRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Grid>
);

export default PlatformCard;
