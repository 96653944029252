import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.css';

const MediaPlaceholder = () => {
  return <div className={styles.root} />;
};

MediaPlaceholder.propTypes = {
  state: PropTypes.string,
  className: PropTypes.string,
  reason: PropTypes.string,
};

export default MediaPlaceholder;
