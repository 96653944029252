import {createStyles, makeStyles} from '@material-ui/core/styles';

import {semiBold} from '../../../../../consts/brand.integration';

export const useStyles = makeStyles(() =>
  createStyles({
    highlight: {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      height: 160,
      width: 160,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    infoIcon: {
      width: 12,
      height: 12,
      marginLeft: '8px',
    },
    value: {
      lineHeight: '24px',
      paddingTop: '8px',
    },
    underChart: {
      fontWeight: semiBold,
      lineHeight: '14px',
    },
  })
);
