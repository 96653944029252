import React, {useRef} from 'react';
import {gql, useMutation} from '@apollo/client';
import {DialogContentText, Button} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Icon} from '@iconify/react';

import {Modal} from '../../../../../components/Widgets';
import {ModalElement} from '../../../../../components/Widgets/Modal';
import PaymentTermsSelect from '../../../components/PaymentTermsSelect';

interface Props {
  offer: Offer;
  disabled?: boolean;
}

const UpdatePaymentTermsButton: React.FC<Props> = ({offer, disabled}) => {
  const [updatePaymentTerms, {loading}] = useMutation(UpdateOfferPaymentTermsMutation);
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      paymentTerms: offer.paymentTerms,
    },
    validationSchema: yup.object({
      paymentTerms: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    await updatePaymentTerms({variables: {id: offer.id, paymentTerms: formik.values.paymentTerms}});
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="update-paymentTerms"
      title="Update Payment Terms"
      modalToggler={
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Icon icon="mdi:credit-card-outline" />}
          disabled={loading || disabled}
        >
          Update Terms
        </Button>
      }
      submitTitle="Update"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
    >
      <DialogContentText>
        <PaymentTermsSelect value={formik.values.paymentTerms} onChange={formik.handleChange} />
      </DialogContentText>
    </Modal>
  );
};

const UpdateOfferPaymentTermsMutation = gql`
  mutation UpdateOfferPaymentTermsMutation($id: UUIDString!, $paymentTerms: PaymentTerms!) {
    updateOfferPaymentTerms(id: $id, paymentTerms: $paymentTerms) {
      ok
      offer {
        id
        paymentTerms
      }
    }
  }
`;

export default UpdatePaymentTermsButton;
