import {gql, useQuery} from '@apollo/client';
import {FormControl, FormLabel, MenuItem, Select, Typography} from '@material-ui/core';
import React, {ChangeEvent} from 'react';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<{value: unknown}>) => void;
  label?: string;
  disabled?: boolean;
}

const PaymentTermsSelect: React.FC<Props> = ({value, onChange, label, disabled}) => {
  const {loading, data} = useQuery(PaymentTerms);

  if (loading) {
    return <></>;
  }

  return (
    <FormControl variant="outlined" fullWidth>
      {label && (
        <FormLabel id="owner-label" htmlFor="owner-select">
          <Typography variant="body2">{label}</Typography>
        </FormLabel>
      )}
      <Select
        labelId="paymentTerms-select"
        name="paymentTerms"
        value={value}
        aria-describedby="paymentTerms"
        disabled={disabled}
        onChange={onChange}
      >
        {data.__type?.enumValues?.map((term: {name: string}) => (
          <MenuItem key={term.name} value={term.name}>
            {term.name.replace('_', ' ')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PaymentTerms = gql`
  query IntrospectionQuery {
    __type(name: "PaymentTerms") {
      name
      enumValues {
        name
      }
    }
  }
`;

export default PaymentTermsSelect;
