import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainHeader: {
      WebkitTextStroke: '1px white',
      fontSize: 144,
      [theme.breakpoints.down('xs')]: {
        fontSize: 120,
        margin: '0 12px',
      },
    },
    grid: {
      backgroundColor: theme.palette['ui-07'],
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: '50%',
      },
    },
    loginFormGrid: {
      height: '100%',
    },
  })
);
