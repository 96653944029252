import PropTypes from 'prop-types';
import React from 'react';

const RightChevron = ({color = '#FFFFFF'}) => (
  <svg
    width="26px"
    height="62px"
    viewBox="0 0 26 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="Gig-–-Reported"
        transform="translate(-864.000000, -378.000000)"
        stroke={color}
        strokeWidth="4"
      >
        <path
          d="M887.352286,381 L867,408.68464 L887.352286,436.590655"
          id="Right"
          transform="translate(877.176143, 408.795327) rotate(-180.000000) translate(-877.176143, -408.795327) "
        />
      </g>
    </g>
  </svg>
);

RightChevron.propTypes = {
  color: PropTypes.string,
};

export default RightChevron;
